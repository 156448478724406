/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Query:{
		getAddress:{
			by:"AddressUniqueWhere",
			filter:"AddressWhere"
		},
		listAddress:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		paginateAddress:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		getCountry:{
			by:"CountryUniqueWhere",
			filter:"CountryWhere"
		},
		listCountry:{
			filter:"CountryWhere",
			orderBy:"CountryOrderBy"
		},
		paginateCountry:{
			filter:"CountryWhere",
			orderBy:"CountryOrderBy"
		},
		getChannel:{
			by:"ChannelUniqueWhere",
			filter:"ChannelWhere"
		},
		listChannel:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		paginateChannel:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		getCustomer:{
			by:"CustomerUniqueWhere",
			filter:"CustomerWhere"
		},
		listCustomer:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		paginateCustomer:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		getDeliveryMethod:{
			by:"DeliveryMethodUniqueWhere",
			filter:"DeliveryMethodWhere"
		},
		listDeliveryMethod:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		paginateDeliveryMethod:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		getOrder:{
			by:"OrderUniqueWhere",
			filter:"OrderWhere"
		},
		listOrder:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		paginateOrder:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		getOrderPayment:{
			by:"OrderPaymentUniqueWhere",
			filter:"OrderPaymentWhere"
		},
		listOrderPayment:{
			filter:"OrderPaymentWhere",
			orderBy:"OrderPaymentOrderBy"
		},
		paginateOrderPayment:{
			filter:"OrderPaymentWhere",
			orderBy:"OrderPaymentOrderBy"
		},
		getOrderDelivery:{
			by:"OrderDeliveryUniqueWhere",
			filter:"OrderDeliveryWhere"
		},
		listOrderDelivery:{
			filter:"OrderDeliveryWhere",
			orderBy:"OrderDeliveryOrderBy"
		},
		paginateOrderDelivery:{
			filter:"OrderDeliveryWhere",
			orderBy:"OrderDeliveryOrderBy"
		},
		getOrderItem:{
			by:"OrderItemUniqueWhere",
			filter:"OrderItemWhere"
		},
		listOrderItem:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		paginateOrderItem:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		getPaymentMethod:{
			by:"PaymentMethodUniqueWhere",
			filter:"PaymentMethodWhere"
		},
		listPaymentMethod:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		paginatePaymentMethod:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		getVatRate:{
			by:"VatRateUniqueWhere",
			filter:"VatRateWhere"
		},
		listVatRate:{
			filter:"VatRateWhere",
			orderBy:"VatRateOrderBy"
		},
		paginateVatRate:{
			filter:"VatRateWhere",
			orderBy:"VatRateOrderBy"
		},
		getProduct:{
			by:"ProductUniqueWhere",
			filter:"ProductWhere"
		},
		listProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		getProductStock:{
			by:"ProductStockUniqueWhere",
			filter:"ProductStockWhere"
		},
		listProductStock:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		paginateProductStock:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		getProductPacking:{
			by:"ProductPackingUniqueWhere",
			filter:"ProductPackingWhere"
		},
		listProductPacking:{
			filter:"ProductPackingWhere",
			orderBy:"ProductPackingOrderBy"
		},
		paginateProductPacking:{
			filter:"ProductPackingWhere",
			orderBy:"ProductPackingOrderBy"
		},
		getProductRecipe:{
			by:"ProductRecipeUniqueWhere",
			filter:"ProductRecipeWhere"
		},
		listProductRecipe:{
			filter:"ProductRecipeWhere",
			orderBy:"ProductRecipeOrderBy"
		},
		paginateProductRecipe:{
			filter:"ProductRecipeWhere",
			orderBy:"ProductRecipeOrderBy"
		},
		getProductCategory:{
			by:"ProductCategoryUniqueWhere",
			filter:"ProductCategoryWhere"
		},
		listProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		paginateProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		getStore:{
			by:"StoreUniqueWhere",
			filter:"StoreWhere"
		},
		listStore:{
			filter:"StoreWhere",
			orderBy:"StoreOrderBy"
		},
		paginateStore:{
			filter:"StoreWhere",
			orderBy:"StoreOrderBy"
		},
		getTag:{
			by:"TagUniqueWhere",
			filter:"TagWhere"
		},
		listTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		getImage:{
			by:"ImageUniqueWhere",
			filter:"ImageWhere"
		},
		listImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		paginateImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		getBusinessCategory:{
			by:"BusinessCategoryUniqueWhere",
			filter:"BusinessCategoryWhere"
		},
		listBusinessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		},
		paginateBusinessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		},
		getProductList:{
			by:"ProductListUniqueWhere",
			filter:"ProductListWhere"
		},
		listProductList:{
			filter:"ProductListWhere",
			orderBy:"ProductListOrderBy"
		},
		paginateProductList:{
			filter:"ProductListWhere",
			orderBy:"ProductListOrderBy"
		},
		getProductListItem:{
			by:"ProductListItemUniqueWhere",
			filter:"ProductListItemWhere"
		},
		listProductListItem:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		},
		paginateProductListItem:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		},
		getProductCategoryList:{
			by:"ProductCategoryListUniqueWhere",
			filter:"ProductCategoryListWhere"
		},
		listProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		paginateProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		getProductCategoryListItem:{
			by:"ProductCategoryListItemUniqueWhere",
			filter:"ProductCategoryListItemWhere"
		},
		listProductCategoryListItem:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		paginateProductCategoryListItem:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		getBusinessCategoryLocale:{
			by:"BusinessCategoryLocaleUniqueWhere",
			filter:"BusinessCategoryLocaleWhere"
		},
		listBusinessCategoryLocale:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		paginateBusinessCategoryLocale:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		getLocale:{
			by:"LocaleUniqueWhere",
			filter:"LocaleWhere"
		},
		listLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		paginateLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		getProductCategoryListLocale:{
			by:"ProductCategoryListLocaleUniqueWhere",
			filter:"ProductCategoryListLocaleWhere"
		},
		listProductCategoryListLocale:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		paginateProductCategoryListLocale:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		getCustomerName:{
			by:"CustomerNameUniqueWhere",
			filter:"CustomerNameWhere"
		},
		listCustomerName:{
			filter:"CustomerNameWhere",
			orderBy:"CustomerNameOrderBy"
		},
		paginateCustomerName:{
			filter:"CustomerNameWhere",
			orderBy:"CustomerNameOrderBy"
		},
		getProductIngredientItem:{
			by:"ProductIngredientItemUniqueWhere",
			filter:"ProductIngredientItemWhere"
		},
		listProductIngredientItem:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		paginateProductIngredientItem:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		getProductIngredientItemQuantity:{
			by:"ProductIngredientItemQuantityUniqueWhere",
			filter:"ProductIngredientItemQuantityWhere"
		},
		listProductIngredientItemQuantity:{
			filter:"ProductIngredientItemQuantityWhere",
			orderBy:"ProductIngredientItemQuantityOrderBy"
		},
		paginateProductIngredientItemQuantity:{
			filter:"ProductIngredientItemQuantityWhere",
			orderBy:"ProductIngredientItemQuantityOrderBy"
		},
		getProductIngredientItemQuantityUnit:{
			by:"ProductIngredientItemQuantityUnitUniqueWhere",
			filter:"ProductIngredientItemQuantityUnitWhere"
		},
		listProductIngredientItemQuantityUnit:{
			filter:"ProductIngredientItemQuantityUnitWhere",
			orderBy:"ProductIngredientItemQuantityUnitOrderBy"
		},
		paginateProductIngredientItemQuantityUnit:{
			filter:"ProductIngredientItemQuantityUnitWhere",
			orderBy:"ProductIngredientItemQuantityUnitOrderBy"
		},
		getProductIngredient:{
			by:"ProductIngredientUniqueWhere",
			filter:"ProductIngredientWhere"
		},
		listProductIngredient:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		paginateProductIngredient:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		getProductIngredientRating:{
			by:"ProductIngredientRatingUniqueWhere",
			filter:"ProductIngredientRatingWhere"
		},
		listProductIngredientRating:{
			filter:"ProductIngredientRatingWhere",
			orderBy:"ProductIngredientRatingOrderBy"
		},
		paginateProductIngredientRating:{
			filter:"ProductIngredientRatingWhere",
			orderBy:"ProductIngredientRatingOrderBy"
		},
		getProductIngredientLocale:{
			by:"ProductIngredientLocaleUniqueWhere",
			filter:"ProductIngredientLocaleWhere"
		},
		listProductIngredientLocale:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		paginateProductIngredientLocale:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		getOrderRecurrence:{
			by:"OrderRecurrenceUniqueWhere",
			filter:"OrderRecurrenceWhere"
		},
		listOrderRecurrence:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		},
		paginateOrderRecurrence:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		},
		getOrderRecurrenceLocale:{
			by:"OrderRecurrenceLocaleUniqueWhere",
			filter:"OrderRecurrenceLocaleWhere"
		},
		listOrderRecurrenceLocale:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		paginateOrderRecurrenceLocale:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		getStaff:{
			by:"StaffUniqueWhere",
			filter:"StaffWhere"
		},
		listStaff:{
			filter:"StaffWhere",
			orderBy:"StaffOrderBy"
		},
		paginateStaff:{
			filter:"StaffWhere",
			orderBy:"StaffOrderBy"
		},
		getBlog:{
			by:"BlogUniqueWhere",
			filter:"BlogWhere"
		},
		listBlog:{
			filter:"BlogWhere",
			orderBy:"BlogOrderBy"
		},
		paginateBlog:{
			filter:"BlogWhere",
			orderBy:"BlogOrderBy"
		},
		getBlogLocale:{
			by:"BlogLocaleUniqueWhere",
			filter:"BlogLocaleWhere"
		},
		listBlogLocale:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		paginateBlogLocale:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		getBlogPost:{
			by:"BlogPostUniqueWhere",
			filter:"BlogPostWhere"
		},
		listBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		paginateBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		getBlogPostLocale:{
			by:"BlogPostLocaleUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		listBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		paginateBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		getColorPallete:{
			by:"ColorPalleteUniqueWhere",
			filter:"ColorPalleteWhere"
		},
		listColorPallete:{
			filter:"ColorPalleteWhere",
			orderBy:"ColorPalleteOrderBy"
		},
		paginateColorPallete:{
			filter:"ColorPalleteWhere",
			orderBy:"ColorPalleteOrderBy"
		},
		getContent:{
			by:"ContentUniqueWhere",
			filter:"ContentWhere"
		},
		listContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		paginateContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		getContentBlock:{
			by:"ContentBlockUniqueWhere",
			filter:"ContentBlockWhere"
		},
		listContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		paginateContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		getContentReference:{
			by:"ContentReferenceUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		listContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		paginateContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		getBlogPostList:{
			by:"BlogPostListUniqueWhere",
			filter:"BlogPostListWhere"
		},
		listBlogPostList:{
			filter:"BlogPostListWhere",
			orderBy:"BlogPostListOrderBy"
		},
		paginateBlogPostList:{
			filter:"BlogPostListWhere",
			orderBy:"BlogPostListOrderBy"
		},
		getBlogPostListItem:{
			by:"BlogPostListItemUniqueWhere",
			filter:"BlogPostListItemWhere"
		},
		listBlogPostListItem:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		},
		paginateBlogPostListItem:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		},
		getLinkList:{
			by:"LinkListUniqueWhere",
			filter:"LinkListWhere"
		},
		listLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		paginateLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		getLinkListItem:{
			by:"LinkListItemUniqueWhere",
			filter:"LinkListItemWhere"
		},
		listLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		paginateLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		getCover:{
			by:"CoverUniqueWhere",
			filter:"CoverWhere"
		},
		listCover:{
			filter:"CoverWhere",
			orderBy:"CoverOrderBy"
		},
		paginateCover:{
			filter:"CoverWhere",
			orderBy:"CoverOrderBy"
		},
		getLink:{
			by:"LinkUniqueWhere",
			filter:"LinkWhere"
		},
		listLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		paginateLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		getLinkable:{
			by:"LinkableUniqueWhere",
			filter:"LinkableWhere"
		},
		listLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		paginateLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		getMedium:{
			by:"MediumUniqueWhere",
			filter:"MediumWhere"
		},
		listMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		paginateMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		getVideo:{
			by:"VideoUniqueWhere",
			filter:"VideoWhere"
		},
		listVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		paginateVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		getSeo:{
			by:"SeoUniqueWhere",
			filter:"SeoWhere"
		},
		listSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		paginateSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		getTranslationDomain:{
			by:"TranslationDomainUniqueWhere",
			filter:"TranslationDomainWhere"
		},
		listTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		paginateTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		getTranslationCatalogue:{
			by:"TranslationCatalogueUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		listTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		paginateTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		getTranslationKey:{
			by:"TranslationKeyUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		listTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		paginateTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		getTranslationValue:{
			by:"TranslationValueUniqueWhere",
			filter:"TranslationValueWhere"
		},
		listTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		paginateTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		getTranslationCataloguesIdentifier:{
			by:"TranslationCataloguesIdentifierUniqueWhere",
			filter:"TranslationCataloguesIdentifierWhere"
		},
		listTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		paginateTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		getSocialsAndApps:{
			by:"SocialsAndAppsUniqueWhere",
			filter:"SocialsAndAppsWhere"
		},
		listSocialsAndApps:{
			filter:"SocialsAndAppsWhere",
			orderBy:"SocialsAndAppsOrderBy"
		},
		paginateSocialsAndApps:{
			filter:"SocialsAndAppsWhere",
			orderBy:"SocialsAndAppsOrderBy"
		},
		getProductGrid:{
			by:"ProductGridUniqueWhere",
			filter:"ProductGridWhere"
		},
		listProductGrid:{
			filter:"ProductGridWhere",
			orderBy:"ProductGridOrderBy"
		},
		paginateProductGrid:{
			filter:"ProductGridWhere",
			orderBy:"ProductGridOrderBy"
		},
		getProductGridItem:{
			by:"ProductGridItemUniqueWhere",
			filter:"ProductGridItemWhere"
		},
		listProductGridItem:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		},
		paginateProductGridItem:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		},
		getGallery:{
			by:"GalleryUniqueWhere",
			filter:"GalleryWhere"
		},
		listGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		paginateGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		getGalleryItem:{
			by:"GalleryItemUniqueWhere",
			filter:"GalleryItemWhere"
		},
		listGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		paginateGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		getHero:{
			by:"HeroUniqueWhere",
			filter:"HeroWhere"
		},
		listHero:{
			filter:"HeroWhere",
			orderBy:"HeroOrderBy"
		},
		paginateHero:{
			filter:"HeroWhere",
			orderBy:"HeroOrderBy"
		},
		getPage:{
			by:"PageUniqueWhere",
			filter:"PageWhere"
		},
		listPage:{
			filter:"PageWhere",
			orderBy:"PageOrderBy"
		},
		paginatePage:{
			filter:"PageWhere",
			orderBy:"PageOrderBy"
		},
		getPageLocale:{
			by:"PageLocaleUniqueWhere",
			filter:"PageLocaleWhere"
		},
		listPageLocale:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		paginatePageLocale:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		getDeadlineTemplate:{
			by:"DeadlineTemplateUniqueWhere",
			filter:"DeadlineTemplateWhere"
		},
		listDeadlineTemplate:{
			filter:"DeadlineTemplateWhere",
			orderBy:"DeadlineTemplateOrderBy"
		},
		paginateDeadlineTemplate:{
			filter:"DeadlineTemplateWhere",
			orderBy:"DeadlineTemplateOrderBy"
		},
		getEmployeeQueue:{
			by:"EmployeeQueueUniqueWhere",
			filter:"EmployeeQueueWhere"
		},
		listEmployeeQueue:{
			filter:"EmployeeQueueWhere",
			orderBy:"EmployeeQueueOrderBy"
		},
		paginateEmployeeQueue:{
			filter:"EmployeeQueueWhere",
			orderBy:"EmployeeQueueOrderBy"
		},
		validateCreateEmployeeQueue:{
			data:"EmployeeQueueCreateInput"
		},
		validateUpdateEmployeeQueue:{
			by:"EmployeeQueueUniqueWhere",
			data:"EmployeeQueueUpdateInput"
		},
		getTimetableDay:{
			by:"TimetableDayUniqueWhere",
			filter:"TimetableDayWhere"
		},
		listTimetableDay:{
			filter:"TimetableDayWhere",
			orderBy:"TimetableDayOrderBy"
		},
		paginateTimetableDay:{
			filter:"TimetableDayWhere",
			orderBy:"TimetableDayOrderBy"
		},
		getTimetableEmployeeRole:{
			by:"TimetableEmployeeRoleUniqueWhere",
			filter:"TimetableEmployeeRoleWhere"
		},
		listTimetableEmployeeRole:{
			filter:"TimetableEmployeeRoleWhere",
			orderBy:"TimetableEmployeeRoleOrderBy"
		},
		paginateTimetableEmployeeRole:{
			filter:"TimetableEmployeeRoleWhere",
			orderBy:"TimetableEmployeeRoleOrderBy"
		},
		getTimetableShift:{
			by:"TimetableShiftUniqueWhere",
			filter:"TimetableShiftWhere"
		},
		listTimetableShift:{
			filter:"TimetableShiftWhere",
			orderBy:"TimetableShiftOrderBy"
		},
		paginateTimetableShift:{
			filter:"TimetableShiftWhere",
			orderBy:"TimetableShiftOrderBy"
		},
		validateUpdateTimetableShift:{
			by:"TimetableShiftUniqueWhere",
			data:"TimetableShiftUpdateInput"
		},
		getTimetableShiftGroup:{
			by:"TimetableShiftGroupUniqueWhere",
			filter:"TimetableShiftGroupWhere"
		},
		listTimetableShiftGroup:{
			filter:"TimetableShiftGroupWhere",
			orderBy:"TimetableShiftGroupOrderBy"
		},
		paginateTimetableShiftGroup:{
			filter:"TimetableShiftGroupWhere",
			orderBy:"TimetableShiftGroupOrderBy"
		},
		getTimetableShiftPosition:{
			by:"TimetableShiftPositionUniqueWhere",
			filter:"TimetableShiftPositionWhere"
		},
		listTimetableShiftPosition:{
			filter:"TimetableShiftPositionWhere",
			orderBy:"TimetableShiftPositionOrderBy"
		},
		paginateTimetableShiftPosition:{
			filter:"TimetableShiftPositionWhere",
			orderBy:"TimetableShiftPositionOrderBy"
		},
		getTimetableTemplate:{
			by:"TimetableTemplateUniqueWhere",
			filter:"TimetableTemplateWhere"
		},
		listTimetableTemplate:{
			filter:"TimetableTemplateWhere",
			orderBy:"TimetableTemplateOrderBy"
		},
		paginateTimetableTemplate:{
			filter:"TimetableTemplateWhere",
			orderBy:"TimetableTemplateOrderBy"
		},
		getEmployeeQueueItem:{
			by:"EmployeeQueueItemUniqueWhere",
			filter:"EmployeeQueueItemWhere"
		},
		listEmployeeQueueItem:{
			filter:"EmployeeQueueItemWhere",
			orderBy:"EmployeeQueueItemOrderBy"
		},
		paginateEmployeeQueueItem:{
			filter:"EmployeeQueueItemWhere",
			orderBy:"EmployeeQueueItemOrderBy"
		},
		validateCreateEmployeeQueueItem:{
			data:"EmployeeQueueItemCreateInput"
		},
		validateUpdateEmployeeQueueItem:{
			by:"EmployeeQueueItemUniqueWhere",
			data:"EmployeeQueueItemUpdateInput"
		},
		getProductBanner:{
			by:"ProductBannerUniqueWhere",
			filter:"ProductBannerWhere"
		},
		listProductBanner:{
			filter:"ProductBannerWhere",
			orderBy:"ProductBannerOrderBy"
		},
		paginateProductBanner:{
			filter:"ProductBannerWhere",
			orderBy:"ProductBannerOrderBy"
		},
		getProductBannerItem:{
			by:"ProductBannerItemUniqueWhere",
			filter:"ProductBannerItemWhere"
		},
		listProductBannerItem:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		},
		paginateProductBannerItem:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		},
		getProductBannerList:{
			by:"ProductBannerListUniqueWhere",
			filter:"ProductBannerListWhere"
		},
		listProductBannerList:{
			filter:"ProductBannerListWhere",
			orderBy:"ProductBannerListOrderBy"
		},
		paginateProductBannerList:{
			filter:"ProductBannerListWhere",
			orderBy:"ProductBannerListOrderBy"
		},
		getShiftsProfile:{
			by:"ShiftsProfileUniqueWhere",
			filter:"ShiftsProfileWhere"
		},
		listShiftsProfile:{
			filter:"ShiftsProfileWhere",
			orderBy:"ShiftsProfileOrderBy"
		},
		paginateShiftsProfile:{
			filter:"ShiftsProfileWhere",
			orderBy:"ShiftsProfileOrderBy"
		},
		validateUpdateShiftsProfile:{
			by:"ShiftsProfileUniqueWhere",
			data:"ShiftsProfileUpdateInput"
		},
		getProductIngredientCategory:{
			by:"ProductIngredientCategoryUniqueWhere",
			filter:"ProductIngredientCategoryWhere"
		},
		listProductIngredientCategory:{
			filter:"ProductIngredientCategoryWhere",
			orderBy:"ProductIngredientCategoryOrderBy"
		},
		paginateProductIngredientCategory:{
			filter:"ProductIngredientCategoryWhere",
			orderBy:"ProductIngredientCategoryOrderBy"
		},
		getProductIngredientCategoryLocale:{
			by:"ProductIngredientCategoryLocaleUniqueWhere",
			filter:"ProductIngredientCategoryLocaleWhere"
		},
		listProductIngredientCategoryLocale:{
			filter:"ProductIngredientCategoryLocaleWhere",
			orderBy:"ProductIngredientCategoryLocaleOrderBy"
		},
		paginateProductIngredientCategoryLocale:{
			filter:"ProductIngredientCategoryLocaleWhere",
			orderBy:"ProductIngredientCategoryLocaleOrderBy"
		},
		getDeliveryRoute:{
			by:"DeliveryRouteUniqueWhere",
			filter:"DeliveryRouteWhere"
		},
		listDeliveryRoute:{
			filter:"DeliveryRouteWhere",
			orderBy:"DeliveryRouteOrderBy"
		},
		paginateDeliveryRoute:{
			filter:"DeliveryRouteWhere",
			orderBy:"DeliveryRouteOrderBy"
		},
		getDeliveryWaypoint:{
			by:"DeliveryWaypointUniqueWhere",
			filter:"DeliveryWaypointWhere"
		},
		listDeliveryWaypoint:{
			filter:"DeliveryWaypointWhere",
			orderBy:"DeliveryWaypointOrderBy"
		},
		paginateDeliveryWaypoint:{
			filter:"DeliveryWaypointWhere",
			orderBy:"DeliveryWaypointOrderBy"
		},
		getAllergen:{
			by:"AllergenUniqueWhere",
			filter:"AllergenWhere"
		},
		listAllergen:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		paginateAllergen:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		getAllergenLocale:{
			by:"AllergenLocaleUniqueWhere",
			filter:"AllergenLocaleWhere"
		},
		listAllergenLocale:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		paginateAllergenLocale:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		getWeekHours:{
			by:"WeekHoursUniqueWhere",
			filter:"WeekHoursWhere"
		},
		listWeekHours:{
			filter:"WeekHoursWhere",
			orderBy:"WeekHoursOrderBy"
		},
		paginateWeekHours:{
			filter:"WeekHoursWhere",
			orderBy:"WeekHoursOrderBy"
		},
		getPreparedIngredientQuantity:{
			by:"PreparedIngredientQuantityUniqueWhere",
			filter:"PreparedIngredientQuantityWhere"
		},
		listPreparedIngredientQuantity:{
			filter:"PreparedIngredientQuantityWhere",
			orderBy:"PreparedIngredientQuantityOrderBy"
		},
		paginatePreparedIngredientQuantity:{
			filter:"PreparedIngredientQuantityWhere",
			orderBy:"PreparedIngredientQuantityOrderBy"
		},
		validateCreatePreparedIngredientQuantity:{
			data:"PreparedIngredientQuantityCreateInput"
		},
		validateUpdatePreparedIngredientQuantity:{
			by:"PreparedIngredientQuantityUniqueWhere",
			data:"PreparedIngredientQuantityUpdateInput"
		},
		getPreparedRecipeQuantity:{
			by:"PreparedRecipeQuantityUniqueWhere",
			filter:"PreparedRecipeQuantityWhere"
		},
		listPreparedRecipeQuantity:{
			filter:"PreparedRecipeQuantityWhere",
			orderBy:"PreparedRecipeQuantityOrderBy"
		},
		paginatePreparedRecipeQuantity:{
			filter:"PreparedRecipeQuantityWhere",
			orderBy:"PreparedRecipeQuantityOrderBy"
		},
		validateCreatePreparedRecipeQuantity:{
			data:"PreparedRecipeQuantityCreateInput"
		},
		validateUpdatePreparedRecipeQuantity:{
			by:"PreparedRecipeQuantityUniqueWhere",
			data:"PreparedRecipeQuantityUpdateInput"
		},
		getPreparedPackingQuantity:{
			by:"PreparedPackingQuantityUniqueWhere",
			filter:"PreparedPackingQuantityWhere"
		},
		listPreparedPackingQuantity:{
			filter:"PreparedPackingQuantityWhere",
			orderBy:"PreparedPackingQuantityOrderBy"
		},
		paginatePreparedPackingQuantity:{
			filter:"PreparedPackingQuantityWhere",
			orderBy:"PreparedPackingQuantityOrderBy"
		},
		validateCreatePreparedPackingQuantity:{
			data:"PreparedPackingQuantityCreateInput"
		},
		validateUpdatePreparedPackingQuantity:{
			by:"PreparedPackingQuantityUniqueWhere",
			data:"PreparedPackingQuantityUpdateInput"
		},
		getDeliveryZone:{
			by:"DeliveryZoneUniqueWhere",
			filter:"DeliveryZoneWhere"
		},
		listDeliveryZone:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		paginateDeliveryZone:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		getDeliveryTimelinePreset:{
			by:"DeliveryTimelinePresetUniqueWhere",
			filter:"DeliveryTimelinePresetWhere"
		},
		listDeliveryTimelinePreset:{
			filter:"DeliveryTimelinePresetWhere",
			orderBy:"DeliveryTimelinePresetOrderBy"
		},
		paginateDeliveryTimelinePreset:{
			filter:"DeliveryTimelinePresetWhere",
			orderBy:"DeliveryTimelinePresetOrderBy"
		},
		getProductPackingLocale:{
			by:"ProductPackingLocaleUniqueWhere",
			filter:"ProductPackingLocaleWhere"
		},
		listProductPackingLocale:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		paginateProductPackingLocale:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		getProductLocale:{
			by:"ProductLocaleUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		listProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		paginateProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		getProductRecipeLocale:{
			by:"ProductRecipeLocaleUniqueWhere",
			filter:"ProductRecipeLocaleWhere"
		},
		listProductRecipeLocale:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		paginateProductRecipeLocale:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		getSuppliesPurchase:{
			by:"SuppliesPurchaseUniqueWhere",
			filter:"SuppliesPurchaseWhere"
		},
		listSuppliesPurchase:{
			filter:"SuppliesPurchaseWhere",
			orderBy:"SuppliesPurchaseOrderBy"
		},
		paginateSuppliesPurchase:{
			filter:"SuppliesPurchaseWhere",
			orderBy:"SuppliesPurchaseOrderBy"
		},
		validateCreateSuppliesPurchase:{
			data:"SuppliesPurchaseCreateInput"
		},
		validateUpdateSuppliesPurchase:{
			by:"SuppliesPurchaseUniqueWhere",
			data:"SuppliesPurchaseUpdateInput"
		},
		getLogRecord:{
			by:"LogRecordUniqueWhere",
			filter:"LogRecordWhere"
		},
		listLogRecord:{
			filter:"LogRecordWhere",
			orderBy:"LogRecordOrderBy"
		},
		paginateLogRecord:{
			filter:"LogRecordWhere",
			orderBy:"LogRecordOrderBy"
		},
		getSuppliesPurchaseItem:{
			by:"SuppliesPurchaseItemUniqueWhere",
			filter:"SuppliesPurchaseItemWhere"
		},
		listSuppliesPurchaseItem:{
			filter:"SuppliesPurchaseItemWhere",
			orderBy:"SuppliesPurchaseItemOrderBy"
		},
		paginateSuppliesPurchaseItem:{
			filter:"SuppliesPurchaseItemWhere",
			orderBy:"SuppliesPurchaseItemOrderBy"
		},
		validateCreateSuppliesPurchaseItem:{
			data:"SuppliesPurchaseItemCreateInput"
		},
		validateUpdateSuppliesPurchaseItem:{
			by:"SuppliesPurchaseItemUniqueWhere",
			data:"SuppliesPurchaseItemUpdateInput"
		},
		getVirtualProduct:{
			by:"VirtualProductUniqueWhere",
			filter:"VirtualProductWhere"
		},
		listVirtualProduct:{
			filter:"VirtualProductWhere",
			orderBy:"VirtualProductOrderBy"
		},
		paginateVirtualProduct:{
			filter:"VirtualProductWhere",
			orderBy:"VirtualProductOrderBy"
		},
		getProductMetadata:{
			by:"ProductMetadataUniqueWhere",
			filter:"ProductMetadataWhere"
		},
		listProductMetadata:{
			filter:"ProductMetadataWhere",
			orderBy:"ProductMetadataOrderBy"
		},
		paginateProductMetadata:{
			filter:"ProductMetadataWhere",
			orderBy:"ProductMetadataOrderBy"
		},
		getProductGridCategory:{
			by:"ProductGridCategoryUniqueWhere",
			filter:"ProductGridCategoryWhere"
		},
		listProductGridCategory:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		},
		paginateProductGridCategory:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		},
		getUnassignedShiftInfo:{
			by:"UnassignedShiftInfoUniqueWhere",
			filter:"UnassignedShiftInfoWhere"
		},
		listUnassignedShiftInfo:{
			filter:"UnassignedShiftInfoWhere",
			orderBy:"UnassignedShiftInfoOrderBy"
		},
		paginateUnassignedShiftInfo:{
			filter:"UnassignedShiftInfoWhere",
			orderBy:"UnassignedShiftInfoOrderBy"
		},
		validateCreateUnassignedShiftInfo:{
			data:"UnassignedShiftInfoCreateInput"
		},
		validateUpdateUnassignedShiftInfo:{
			by:"UnassignedShiftInfoUniqueWhere",
			data:"UnassignedShiftInfoUpdateInput"
		},
		getFreshingContainer:{
			by:"FreshingContainerUniqueWhere",
			filter:"FreshingContainerWhere"
		},
		listFreshingContainer:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		},
		paginateFreshingContainer:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		},
		getTextItem:{
			by:"TextItemUniqueWhere",
			filter:"TextItemWhere"
		},
		listTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		paginateTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		getTextList:{
			by:"TextListUniqueWhere",
			filter:"TextListWhere"
		},
		listTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		paginateTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		getDeliveryRegions:{
			by:"DeliveryRegionsUniqueWhere",
			filter:"DeliveryRegionsWhere"
		},
		listDeliveryRegions:{
			filter:"DeliveryRegionsWhere",
			orderBy:"DeliveryRegionsOrderBy"
		},
		paginateDeliveryRegions:{
			filter:"DeliveryRegionsWhere",
			orderBy:"DeliveryRegionsOrderBy"
		},
		getDeliveryRegionsItem:{
			by:"DeliveryRegionsItemUniqueWhere",
			filter:"DeliveryRegionsItemWhere"
		},
		listDeliveryRegionsItem:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		},
		paginateDeliveryRegionsItem:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		},
		getImageList:{
			by:"ImageListUniqueWhere",
			filter:"ImageListWhere"
		},
		listImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		paginateImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		getImageListItem:{
			by:"ImageListItemUniqueWhere",
			filter:"ImageListItemWhere"
		},
		listImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		paginateImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		getTimetablesEmployeePrivileges:{
			by:"TimetablesEmployeePrivilegesUniqueWhere",
			filter:"TimetablesEmployeePrivilegesWhere"
		},
		listTimetablesEmployeePrivileges:{
			filter:"TimetablesEmployeePrivilegesWhere",
			orderBy:"TimetablesEmployeePrivilegesOrderBy"
		},
		paginateTimetablesEmployeePrivileges:{
			filter:"TimetablesEmployeePrivilegesWhere",
			orderBy:"TimetablesEmployeePrivilegesOrderBy"
		},
		getTimetableDayNote:{
			by:"TimetableDayNoteUniqueWhere",
			filter:"TimetableDayNoteWhere"
		},
		listTimetableDayNote:{
			filter:"TimetableDayNoteWhere",
			orderBy:"TimetableDayNoteOrderBy"
		},
		paginateTimetableDayNote:{
			filter:"TimetableDayNoteWhere",
			orderBy:"TimetableDayNoteOrderBy"
		},
		getDeliveryMethodLocale:{
			by:"DeliveryMethodLocaleUniqueWhere",
			filter:"DeliveryMethodLocaleWhere"
		},
		listDeliveryMethodLocale:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		paginateDeliveryMethodLocale:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		getIngredientSupplier:{
			by:"IngredientSupplierUniqueWhere",
			filter:"IngredientSupplierWhere"
		},
		listIngredientSupplier:{
			filter:"IngredientSupplierWhere",
			orderBy:"IngredientSupplierOrderBy"
		},
		paginateIngredientSupplier:{
			filter:"IngredientSupplierWhere",
			orderBy:"IngredientSupplierOrderBy"
		},
		getIngredientSupplierItem:{
			by:"IngredientSupplierItemUniqueWhere",
			filter:"IngredientSupplierItemWhere"
		},
		listIngredientSupplierItem:{
			filter:"IngredientSupplierItemWhere",
			orderBy:"IngredientSupplierItemOrderBy"
		},
		paginateIngredientSupplierItem:{
			filter:"IngredientSupplierItemWhere",
			orderBy:"IngredientSupplierItemOrderBy"
		},
		getIngredientSupplierList:{
			by:"IngredientSupplierListUniqueWhere",
			filter:"IngredientSupplierListWhere"
		},
		listIngredientSupplierList:{
			filter:"IngredientSupplierListWhere",
			orderBy:"IngredientSupplierListOrderBy"
		},
		paginateIngredientSupplierList:{
			filter:"IngredientSupplierListWhere",
			orderBy:"IngredientSupplierListOrderBy"
		},
		getIngredientSupplierLocale:{
			by:"IngredientSupplierLocaleUniqueWhere",
			filter:"IngredientSupplierLocaleWhere"
		},
		listIngredientSupplierLocale:{
			filter:"IngredientSupplierLocaleWhere",
			orderBy:"IngredientSupplierLocaleOrderBy"
		},
		paginateIngredientSupplierLocale:{
			filter:"IngredientSupplierLocaleWhere",
			orderBy:"IngredientSupplierLocaleOrderBy"
		},
		getRedirect:{
			by:"RedirectUniqueWhere",
			filter:"RedirectWhere"
		},
		listRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		paginateRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		getVenue:{
			by:"VenueUniqueWhere",
			filter:"VenueWhere"
		},
		listVenue:{
			filter:"VenueWhere",
			orderBy:"VenueOrderBy"
		},
		paginateVenue:{
			filter:"VenueWhere",
			orderBy:"VenueOrderBy"
		},
		getWorkspace:{
			by:"WorkspaceUniqueWhere",
			filter:"WorkspaceWhere"
		},
		listWorkspace:{
			filter:"WorkspaceWhere",
			orderBy:"WorkspaceOrderBy"
		},
		paginateWorkspace:{
			filter:"WorkspaceWhere",
			orderBy:"WorkspaceOrderBy"
		},
		getVenueEmployeeRole:{
			by:"VenueEmployeeRoleUniqueWhere",
			filter:"VenueEmployeeRoleWhere"
		},
		listVenueEmployeeRole:{
			filter:"VenueEmployeeRoleWhere",
			orderBy:"VenueEmployeeRoleOrderBy"
		},
		paginateVenueEmployeeRole:{
			filter:"VenueEmployeeRoleWhere",
			orderBy:"VenueEmployeeRoleOrderBy"
		},
		getIdentificationAttempt:{
			by:"IdentificationAttemptUniqueWhere",
			filter:"IdentificationAttemptWhere"
		},
		listIdentificationAttempt:{
			filter:"IdentificationAttemptWhere",
			orderBy:"IdentificationAttemptOrderBy"
		},
		paginateIdentificationAttempt:{
			filter:"IdentificationAttemptWhere",
			orderBy:"IdentificationAttemptOrderBy"
		},
		getIdentificationCode:{
			by:"IdentificationCodeUniqueWhere",
			filter:"IdentificationCodeWhere"
		},
		listIdentificationCode:{
			filter:"IdentificationCodeWhere",
			orderBy:"IdentificationCodeOrderBy"
		},
		paginateIdentificationCode:{
			filter:"IdentificationCodeWhere",
			orderBy:"IdentificationCodeOrderBy"
		},
		getIdentificationCodeLocale:{
			by:"IdentificationCodeLocaleUniqueWhere",
			filter:"IdentificationCodeLocaleWhere"
		},
		listIdentificationCodeLocale:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		},
		paginateIdentificationCodeLocale:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		},
		getDeviceIdentifier:{
			by:"DeviceIdentifierUniqueWhere",
			filter:"DeviceIdentifierWhere"
		},
		listDeviceIdentifier:{
			filter:"DeviceIdentifierWhere",
			orderBy:"DeviceIdentifierOrderBy"
		},
		paginateDeviceIdentifier:{
			filter:"DeviceIdentifierWhere",
			orderBy:"DeviceIdentifierOrderBy"
		},
		validateCreateDeviceIdentifier:{
			data:"DeviceIdentifierCreateInput"
		},
		validateUpdateDeviceIdentifier:{
			by:"DeviceIdentifierUniqueWhere",
			data:"DeviceIdentifierUpdateInput"
		},
		getDeviceIdentifierMetadata:{
			by:"DeviceIdentifierMetadataUniqueWhere",
			filter:"DeviceIdentifierMetadataWhere"
		},
		listDeviceIdentifierMetadata:{
			filter:"DeviceIdentifierMetadataWhere",
			orderBy:"DeviceIdentifierMetadataOrderBy"
		},
		paginateDeviceIdentifierMetadata:{
			filter:"DeviceIdentifierMetadataWhere",
			orderBy:"DeviceIdentifierMetadataOrderBy"
		},
		getFulfillmentNote:{
			by:"FulfillmentNoteUniqueWhere",
			filter:"FulfillmentNoteWhere"
		},
		listFulfillmentNote:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		paginateFulfillmentNote:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		getCustomerMetadata:{
			by:"CustomerMetadataUniqueWhere",
			filter:"CustomerMetadataWhere"
		},
		listCustomerMetadata:{
			filter:"CustomerMetadataWhere",
			orderBy:"CustomerMetadataOrderBy"
		},
		paginateCustomerMetadata:{
			filter:"CustomerMetadataWhere",
			orderBy:"CustomerMetadataOrderBy"
		},
		getSubscriptionBox:{
			by:"SubscriptionBoxUniqueWhere",
			filter:"SubscriptionBoxWhere"
		},
		listSubscriptionBox:{
			filter:"SubscriptionBoxWhere",
			orderBy:"SubscriptionBoxOrderBy"
		},
		paginateSubscriptionBox:{
			filter:"SubscriptionBoxWhere",
			orderBy:"SubscriptionBoxOrderBy"
		},
		getSubscriptionBoxLocale:{
			by:"SubscriptionBoxLocaleUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		listSubscriptionBoxLocale:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		},
		paginateSubscriptionBoxLocale:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		},
		getTimetableDayMetadata:{
			by:"TimetableDayMetadataUniqueWhere",
			filter:"TimetableDayMetadataWhere"
		},
		listTimetableDayMetadata:{
			filter:"TimetableDayMetadataWhere",
			orderBy:"TimetableDayMetadataOrderBy"
		},
		paginateTimetableDayMetadata:{
			filter:"TimetableDayMetadataWhere",
			orderBy:"TimetableDayMetadataOrderBy"
		},
		getGridTileItem:{
			by:"GridTileItemUniqueWhere",
			filter:"GridTileItemWhere"
		},
		listGridTileItem:{
			filter:"GridTileItemWhere",
			orderBy:"GridTileItemOrderBy"
		},
		paginateGridTileItem:{
			filter:"GridTileItemWhere",
			orderBy:"GridTileItemOrderBy"
		},
		getGridTiles:{
			by:"GridTilesUniqueWhere",
			filter:"GridTilesWhere"
		},
		listGridTiles:{
			filter:"GridTilesWhere",
			orderBy:"GridTilesOrderBy"
		},
		paginateGridTiles:{
			filter:"GridTilesWhere",
			orderBy:"GridTilesOrderBy"
		},
		getPosition:{
			by:"PositionUniqueWhere",
			filter:"PositionWhere"
		},
		listPosition:{
			filter:"PositionWhere",
			orderBy:"PositionOrderBy"
		},
		paginatePosition:{
			filter:"PositionWhere",
			orderBy:"PositionOrderBy"
		},
		getStatsWidget:{
			by:"StatsWidgetUniqueWhere",
			filter:"StatsWidgetWhere"
		},
		listStatsWidget:{
			filter:"StatsWidgetWhere",
			orderBy:"StatsWidgetOrderBy"
		},
		paginateStatsWidget:{
			filter:"StatsWidgetWhere",
			orderBy:"StatsWidgetOrderBy"
		},
		getStatsWidgetsLayout:{
			by:"StatsWidgetsLayoutUniqueWhere",
			filter:"StatsWidgetsLayoutWhere"
		},
		listStatsWidgetsLayout:{
			filter:"StatsWidgetsLayoutWhere",
			orderBy:"StatsWidgetsLayoutOrderBy"
		},
		paginateStatsWidgetsLayout:{
			filter:"StatsWidgetsLayoutWhere",
			orderBy:"StatsWidgetsLayoutOrderBy"
		},
		getStatsPage:{
			by:"StatsPageUniqueWhere",
			filter:"StatsPageWhere"
		},
		listStatsPage:{
			filter:"StatsPageWhere",
			orderBy:"StatsPageOrderBy"
		},
		paginateStatsPage:{
			filter:"StatsPageWhere",
			orderBy:"StatsPageOrderBy"
		},
		getUnassignReason:{
			by:"UnassignReasonUniqueWhere",
			filter:"UnassignReasonWhere"
		},
		listUnassignReason:{
			filter:"UnassignReasonWhere",
			orderBy:"UnassignReasonOrderBy"
		},
		paginateUnassignReason:{
			filter:"UnassignReasonWhere",
			orderBy:"UnassignReasonOrderBy"
		},
		getOrderPaymentStatsByDay:{
			by:"OrderPaymentStatsByDayUniqueWhere",
			filter:"OrderPaymentStatsByDayWhere"
		},
		listOrderPaymentStatsByDay:{
			filter:"OrderPaymentStatsByDayWhere",
			orderBy:"OrderPaymentStatsByDayOrderBy"
		},
		paginateOrderPaymentStatsByDay:{
			filter:"OrderPaymentStatsByDayWhere",
			orderBy:"OrderPaymentStatsByDayOrderBy"
		}
	},
	Address:{
		customer:{
			filter:"CustomerWhere"
		},
		country:{
			filter:"CountryWhere"
		},
		deliveryZone:{
			filter:"DeliveryZoneWhere"
		},
		billingAddressOfOrders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		deliveryAddressOfOrderDeliveries:{
			filter:"OrderDeliveryWhere",
			orderBy:"OrderDeliveryOrderBy"
		},
		defaultDeliveryAddressOfCustomer:{
			filter:"CustomerWhere"
		},
		billingAddressOfOrdersBySeq:{
			by:"AddressBillingAddressOfOrdersBySeqUniqueWhere",
			filter:"OrderWhere"
		},
		billingAddressOfOrdersByDoubleshotLegacyId:{
			by:"AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere",
			filter:"OrderWhere"
		},
		billingAddressOfOrdersByDoubleShotLegacyNumber:{
			by:"AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere",
			filter:"OrderWhere"
		},
		billingAddressOfOrdersByDelivery:{
			by:"AddressBillingAddressOfOrdersByDeliveryUniqueWhere",
			filter:"OrderWhere"
		},
		billingAddressOfOrdersByItems:{
			by:"AddressBillingAddressOfOrdersByItemsUniqueWhere",
			filter:"OrderWhere"
		},
		billingAddressOfOrdersByPayments:{
			by:"AddressBillingAddressOfOrdersByPaymentsUniqueWhere",
			filter:"OrderWhere"
		},
		deliveryAddressOfOrderDeliveriesByTrackingCode:{
			by:"AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere",
			filter:"OrderDeliveryWhere"
		},
		deliveryAddressOfOrderDeliveriesByOrder:{
			by:"AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere",
			filter:"OrderDeliveryWhere"
		},
		deliveryAddressOfOrderDeliveriesByDeliveryWaypoint:{
			by:"AddressDeliveryAddressOfOrderDeliveriesByDeliveryWaypointUniqueWhere",
			filter:"OrderDeliveryWhere"
		},
		paginateBillingAddressOfOrders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		paginateDeliveryAddressOfOrderDeliveries:{
			filter:"OrderDeliveryWhere",
			orderBy:"OrderDeliveryOrderBy"
		}
	},
	UUID: "String",
	Customer:{
		addresses:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		defaultBillingAddress:{
			filter:"AddressWhere"
		},
		tags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		orders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		photo:{
			filter:"ImageWhere"
		},
		name:{
			filter:"CustomerNameWhere"
		},
		channels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		defaultDeliveryAddress:{
			filter:"AddressWhere"
		},
		meta:{
			filter:"CustomerMetadataWhere"
		},
		addressesByBillingAddressOfOrders:{
			by:"CustomerAddressesByBillingAddressOfOrdersUniqueWhere",
			filter:"AddressWhere"
		},
		addressesByDeliveryAddressOfOrderDeliveries:{
			by:"CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere",
			filter:"AddressWhere"
		},
		addressesByDefaultDeliveryAddressOfCustomer:{
			by:"CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere",
			filter:"AddressWhere"
		},
		ordersBySeq:{
			by:"CustomerOrdersBySeqUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByDoubleshotLegacyId:{
			by:"CustomerOrdersByDoubleshotLegacyIdUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByDoubleShotLegacyNumber:{
			by:"CustomerOrdersByDoubleShotLegacyNumberUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByDelivery:{
			by:"CustomerOrdersByDeliveryUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByItems:{
			by:"CustomerOrdersByItemsUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByPayments:{
			by:"CustomerOrdersByPaymentsUniqueWhere",
			filter:"OrderWhere"
		},
		paginateAddresses:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		paginateTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateOrders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		paginateChannels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		}
	},
	AddressWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		firstName:"StringCondition",
		lastName:"StringCondition",
		addressLine2:"StringCondition",
		email:"StringCondition",
		phone:"StringCondition",
		companyName:"StringCondition",
		companyIdentifier:"StringCondition",
		vatIdentifier:"StringCondition",
		externalIdentifier:"StringCondition",
		hidden:"BooleanCondition",
		customer:"CustomerWhere",
		internalNote:"StringCondition",
		country:"CountryWhere",
		deletedAt:"DateTimeCondition",
		note:"StringCondition",
		googlePlaceId:"StringCondition",
		raw:"StringCondition",
		formatted:"StringCondition",
		geocodeGeneratedAt:"DateTimeCondition",
		geocodeResponse:"StringCondition",
		deliveryZone:"DeliveryZoneWhere",
		geocodeValid:"BooleanCondition",
		fullName:"StringCondition",
		fakturoidSubjectId:"StringCondition",
		addressLine1:"StringCondition",
		city:"StringCondition",
		postalCode:"StringCondition",
		createdAt:"DateTimeCondition",
		billingAddressOfOrders:"OrderWhere",
		deliveryAddressOfOrderDeliveries:"OrderDeliveryWhere",
		defaultDeliveryAddressOfCustomer:"CustomerWhere",
		and:"AddressWhere",
		or:"AddressWhere",
		not:"AddressWhere"
	},
	UUIDCondition:{
		and:"UUIDCondition",
		or:"UUIDCondition",
		not:"UUIDCondition",
		eq:"UUID",
		notEq:"UUID",
		in:"UUID",
		notIn:"UUID",
		lt:"UUID",
		lte:"UUID",
		gt:"UUID",
		gte:"UUID"
	},
	StringCondition:{
		and:"StringCondition",
		or:"StringCondition",
		not:"StringCondition"
	},
	BooleanCondition:{
		and:"BooleanCondition",
		or:"BooleanCondition",
		not:"BooleanCondition"
	},
	CustomerWhere:{
		id:"UUIDCondition",
		addresses:"AddressWhere",
		defaultBillingAddress:"AddressWhere",
		tags:"TagWhere",
		orders:"OrderWhere",
		photo:"ImageWhere",
		phone:"StringCondition",
		name:"CustomerNameWhere",
		internalNote:"StringCondition",
		welcomeMessage:"StringCondition",
		familiarity:"CustomerFamiliarityCondition",
		channels:"ChannelWhere",
		disabledAt:"DateTimeCondition",
		email:"StringCondition",
		createdAt:"DateTimeCondition",
		defaultDeliveryAddress:"AddressWhere",
		prefersPackingsWithoutCardboard:"BooleanCondition",
		doubleshotLegacyId:"StringCondition",
		meta:"CustomerMetadataWhere",
		doubleshotLastUpdatedAt:"DateTimeCondition",
		and:"CustomerWhere",
		or:"CustomerWhere",
		not:"CustomerWhere"
	},
	TagWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		customers:"CustomerWhere",
		products:"ProductWhere",
		categories:"ProductCategoryWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		addTagsOnRegistration:"ChannelWhere",
		deliveryZones:"DeliveryZoneWhere",
		publicInChannels:"ChannelWhere",
		fulfillmentNotes:"FulfillmentNoteWhere",
		and:"TagWhere",
		or:"TagWhere",
		not:"TagWhere"
	},
	ProductWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		priceCents:"IntCondition",
		notDiscountable:"BooleanCondition",
		packing:"ProductPackingWhere",
		recipe:"ProductRecipeWhere",
		tags:"TagWhere",
		categories:"ProductCategoryWhere",
		vatRate:"VatRateWhere",
		stocks:"ProductStockWhere",
		image:"ImageWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		availability:"ProductAvailabilityCondition",
		order:"FloatCondition",
		orderItems:"OrderItemWhere",
		maximumQuantityPerOrder:"IntCondition",
		deliveryZones:"DeliveryZoneWhere",
		locales:"ProductLocaleWhere",
		archivedAt:"DateTimeCondition",
		virtualProduct:"VirtualProductWhere",
		meta:"ProductMetadataWhere",
		page:"PageWhere",
		fulfillmentNotes:"FulfillmentNoteWhere",
		hasSubscription:"BooleanCondition",
		pointOfSaleTileColor:"StringCondition",
		and:"ProductWhere",
		or:"ProductWhere",
		not:"ProductWhere"
	},
	IntCondition:{
		and:"IntCondition",
		or:"IntCondition",
		not:"IntCondition"
	},
	ProductPackingWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		image:"ImageWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		volumeMl:"IntCondition",
		icon:"ImageWhere",
		shortName:"StringCondition",
		locales:"ProductPackingLocaleWhere",
		preparedPackingQuantities:"PreparedPackingQuantityWhere",
		isPackedInCardboard:"BooleanCondition",
		and:"ProductPackingWhere",
		or:"ProductPackingWhere",
		not:"ProductPackingWhere"
	},
	ImageWhere:{
		id:"UUIDCondition",
		url:"StringCondition",
		width:"IntCondition",
		height:"IntCondition",
		size:"IntCondition",
		type:"StringCondition",
		alt:"StringCondition",
		and:"ImageWhere",
		or:"ImageWhere",
		not:"ImageWhere"
	},
	ProductPackingLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		shortName:"StringCondition",
		description:"StringCondition",
		root:"ProductPackingWhere",
		locale:"LocaleWhere",
		and:"ProductPackingLocaleWhere",
		or:"ProductPackingLocaleWhere",
		not:"ProductPackingLocaleWhere"
	},
	LocaleWhere:{
		id:"UUIDCondition",
		businessCategories:"BusinessCategoryLocaleWhere",
		productCategoryLists:"ProductCategoryListLocaleWhere",
		productIngredients:"ProductIngredientLocaleWhere",
		orderRecurrences:"OrderRecurrenceLocaleWhere",
		blogs:"BlogLocaleWhere",
		blogPosts:"BlogPostLocaleWhere",
		identifier:"TranslationCataloguesIdentifierWhere",
		pages:"PageLocaleWhere",
		productIngredientCategories:"ProductIngredientCategoryLocaleWhere",
		allergens:"AllergenLocaleWhere",
		productPackings:"ProductPackingLocaleWhere",
		products:"ProductLocaleWhere",
		productRecipes:"ProductRecipeLocaleWhere",
		deliveryMethods:"DeliveryMethodLocaleWhere",
		ingredientSuppliers:"IngredientSupplierLocaleWhere",
		identificationCodes:"IdentificationCodeLocaleWhere",
		subscriptionBoxes:"SubscriptionBoxLocaleWhere",
		and:"LocaleWhere",
		or:"LocaleWhere",
		not:"LocaleWhere"
	},
	BusinessCategoryLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"BusinessCategoryWhere",
		locale:"LocaleWhere",
		title:"StringCondition",
		and:"BusinessCategoryLocaleWhere",
		or:"BusinessCategoryLocaleWhere",
		not:"BusinessCategoryLocaleWhere"
	},
	BusinessCategoryWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		visibleToNewCustomers:"BooleanCondition",
		locales:"BusinessCategoryLocaleWhere",
		internalName:"StringCondition",
		recurrences:"OrderRecurrenceWhere",
		specificProducts:"ProductListWhere",
		seasonalProductCategories:"ProductCategoryListWhere",
		specificProductsProductCategories:"ProductCategoryListWhere",
		slug:"StringCondition",
		and:"BusinessCategoryWhere",
		or:"BusinessCategoryWhere",
		not:"BusinessCategoryWhere"
	},
	OrderRecurrenceWhere:{
		id:"UUIDCondition",
		internalName:"StringCondition",
		locales:"OrderRecurrenceLocaleWhere",
		order:"IntCondition",
		businessCategory:"BusinessCategoryWhere",
		and:"OrderRecurrenceWhere",
		or:"OrderRecurrenceWhere",
		not:"OrderRecurrenceWhere"
	},
	OrderRecurrenceLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"OrderRecurrenceWhere",
		locale:"LocaleWhere",
		and:"OrderRecurrenceLocaleWhere",
		or:"OrderRecurrenceLocaleWhere",
		not:"OrderRecurrenceLocaleWhere"
	},
	ProductListWhere:{
		id:"UUIDCondition",
		items:"ProductListItemWhere",
		businessCategory:"BusinessCategoryWhere",
		and:"ProductListWhere",
		or:"ProductListWhere",
		not:"ProductListWhere"
	},
	ProductListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ProductListWhere",
		product:"ProductWhere",
		defaultQuantityForOrder:"IntCondition",
		and:"ProductListItemWhere",
		or:"ProductListItemWhere",
		not:"ProductListItemWhere"
	},
	ProductCategoryListWhere:{
		id:"UUIDCondition",
		items:"ProductCategoryListItemWhere",
		locales:"ProductCategoryListLocaleWhere",
		seasonalProductCategoriesOfBusinessCategory:"BusinessCategoryWhere",
		specificProductsProductCategoriesOfBusinessCategory:"BusinessCategoryWhere",
		and:"ProductCategoryListWhere",
		or:"ProductCategoryListWhere",
		not:"ProductCategoryListWhere"
	},
	ProductCategoryListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ProductCategoryListWhere",
		productCategory:"ProductCategoryWhere",
		showRecipeImages:"BooleanCondition",
		showPackingImages:"BooleanCondition",
		showAllAvailableProducts:"BooleanCondition",
		and:"ProductCategoryListItemWhere",
		or:"ProductCategoryListItemWhere",
		not:"ProductCategoryListItemWhere"
	},
	ProductCategoryWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		products:"ProductWhere",
		tags:"TagWhere",
		parent:"ProductCategoryWhere",
		children:"ProductCategoryWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		color:"StringCondition",
		image:"ImageWhere",
		order:"FloatCondition",
		and:"ProductCategoryWhere",
		or:"ProductCategoryWhere",
		not:"ProductCategoryWhere"
	},
	FloatCondition:{
		and:"FloatCondition",
		or:"FloatCondition",
		not:"FloatCondition"
	},
	ProductCategoryListLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"ProductCategoryListWhere",
		locale:"LocaleWhere",
		and:"ProductCategoryListLocaleWhere",
		or:"ProductCategoryListLocaleWhere",
		not:"ProductCategoryListLocaleWhere"
	},
	ProductIngredientLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"ProductIngredientWhere",
		locale:"LocaleWhere",
		and:"ProductIngredientLocaleWhere",
		or:"ProductIngredientLocaleWhere",
		not:"ProductIngredientLocaleWhere"
	},
	ProductIngredientWhere:{
		id:"UUIDCondition",
		internalNote:"StringCondition",
		locales:"ProductIngredientLocaleWhere",
		internalName:"StringCondition",
		categories:"ProductIngredientCategoryWhere",
		allergens:"AllergenWhere",
		needsPrepping:"BooleanCondition",
		allowCustomerRating:"BooleanCondition",
		preferredQuantityUnit:"ProductIngredientItemQuantityUnitWhere",
		freshingContainer:"FreshingContainerWhere",
		image:"ImageWhere",
		suppliers:"IngredientSupplierListWhere",
		incrementQuantityNumber:"FloatCondition",
		and:"ProductIngredientWhere",
		or:"ProductIngredientWhere",
		not:"ProductIngredientWhere"
	},
	ProductIngredientCategoryWhere:{
		id:"UUIDCondition",
		internalName:"StringCondition",
		internalNote:"StringCondition",
		ingredients:"ProductIngredientWhere",
		locales:"ProductIngredientCategoryLocaleWhere",
		order:"IntCondition",
		and:"ProductIngredientCategoryWhere",
		or:"ProductIngredientCategoryWhere",
		not:"ProductIngredientCategoryWhere"
	},
	ProductIngredientCategoryLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"ProductIngredientCategoryWhere",
		locale:"LocaleWhere",
		and:"ProductIngredientCategoryLocaleWhere",
		or:"ProductIngredientCategoryLocaleWhere",
		not:"ProductIngredientCategoryLocaleWhere"
	},
	AllergenWhere:{
		id:"UUIDCondition",
		internalName:"StringCondition",
		locales:"AllergenLocaleWhere",
		ingredients:"ProductIngredientWhere",
		code:"IntCondition",
		and:"AllergenWhere",
		or:"AllergenWhere",
		not:"AllergenWhere"
	},
	AllergenLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"AllergenWhere",
		locale:"LocaleWhere",
		and:"AllergenLocaleWhere",
		or:"AllergenLocaleWhere",
		not:"AllergenLocaleWhere"
	},
	ProductIngredientItemQuantityUnitWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		and:"ProductIngredientItemQuantityUnitWhere",
		or:"ProductIngredientItemQuantityUnitWhere",
		not:"ProductIngredientItemQuantityUnitWhere"
	},
	FreshingContainerWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		volumeMl:"IntCondition",
		workspace:"WorkspaceWhere",
		and:"FreshingContainerWhere",
		or:"FreshingContainerWhere",
		not:"FreshingContainerWhere"
	},
	WorkspaceWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		orderDeadlineTime:"StringCondition",
		ordersLoadingDurationHours:"IntCondition",
		freshingContainerVolumeMl:"IntCondition",
		freshingPrepareOrderByOffsetMinutes:"IntCondition",
		checkoutUrlTemplate:"StringCondition",
		orderUrlTemplate:"StringCondition",
		paymentUrlTemplate:"StringCondition",
		customerProfileUrlTemplate:"StringCondition",
		userProfileUrlTemplate:"StringCondition",
		messengerUrl:"StringCondition",
		messengerIosUrl:"StringCondition",
		messengerAndroidUrl:"StringCondition",
		freshingContainers:"FreshingContainerWhere",
		openingHours:"WeekHoursWhere",
		workingHours:"WeekHoursWhere",
		defaultFreshingContainer:"FreshingContainerWhere",
		creditsProduct:"VirtualProductWhere",
		discountVatRate:"VatRateWhere",
		name:"StringCondition",
		businessAddress:"StringCondition",
		and:"WorkspaceWhere",
		or:"WorkspaceWhere",
		not:"WorkspaceWhere"
	},
	OneCondition:{
		and:"OneCondition",
		or:"OneCondition",
		not:"OneCondition",
		eq:"One",
		notEq:"One",
		in:"One",
		notIn:"One",
		lt:"One",
		lte:"One",
		gt:"One",
		gte:"One"
	},
	One: true,
	WeekHoursWhere:{
		id:"UUIDCondition",
		mon:"StringCondition",
		tue:"StringCondition",
		wed:"StringCondition",
		thu:"StringCondition",
		fri:"StringCondition",
		sat:"StringCondition",
		sun:"StringCondition",
		and:"WeekHoursWhere",
		or:"WeekHoursWhere",
		not:"WeekHoursWhere"
	},
	VirtualProductWhere:{
		id:"UUIDCondition",
		type:"VirtualProductTypeCondition",
		product:"ProductWhere",
		voucherCreditCents:"IntCondition",
		physicalRepresentation:"VirtualProductPhysicalRepresentationTypeCondition",
		recipientEmail:"BooleanCondition",
		and:"VirtualProductWhere",
		or:"VirtualProductWhere",
		not:"VirtualProductWhere"
	},
	VirtualProductTypeCondition:{
		and:"VirtualProductTypeCondition",
		or:"VirtualProductTypeCondition",
		not:"VirtualProductTypeCondition",
		eq:"VirtualProductType",
		notEq:"VirtualProductType",
		in:"VirtualProductType",
		notIn:"VirtualProductType",
		lt:"VirtualProductType",
		lte:"VirtualProductType",
		gt:"VirtualProductType",
		gte:"VirtualProductType"
	},
	VirtualProductType: true,
	VirtualProductPhysicalRepresentationTypeCondition:{
		and:"VirtualProductPhysicalRepresentationTypeCondition",
		or:"VirtualProductPhysicalRepresentationTypeCondition",
		not:"VirtualProductPhysicalRepresentationTypeCondition",
		eq:"VirtualProductPhysicalRepresentationType",
		notEq:"VirtualProductPhysicalRepresentationType",
		in:"VirtualProductPhysicalRepresentationType",
		notIn:"VirtualProductPhysicalRepresentationType",
		lt:"VirtualProductPhysicalRepresentationType",
		lte:"VirtualProductPhysicalRepresentationType",
		gt:"VirtualProductPhysicalRepresentationType",
		gte:"VirtualProductPhysicalRepresentationType"
	},
	VirtualProductPhysicalRepresentationType: true,
	VatRateWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		ratePermille:"IntCondition",
		description:"StringCondition",
		internalNote:"StringCondition",
		preset:"VatRatePresetCondition",
		and:"VatRateWhere",
		or:"VatRateWhere",
		not:"VatRateWhere"
	},
	VatRatePresetCondition:{
		and:"VatRatePresetCondition",
		or:"VatRatePresetCondition",
		not:"VatRatePresetCondition",
		eq:"VatRatePreset",
		notEq:"VatRatePreset",
		in:"VatRatePreset",
		notIn:"VatRatePreset",
		lt:"VatRatePreset",
		lte:"VatRatePreset",
		gt:"VatRatePreset",
		gte:"VatRatePreset"
	},
	VatRatePreset: true,
	IngredientSupplierListWhere:{
		id:"UUIDCondition",
		items:"IngredientSupplierItemWhere",
		and:"IngredientSupplierListWhere",
		or:"IngredientSupplierListWhere",
		not:"IngredientSupplierListWhere"
	},
	IngredientSupplierItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"IngredientSupplierListWhere",
		supplier:"IngredientSupplierWhere",
		and:"IngredientSupplierItemWhere",
		or:"IngredientSupplierItemWhere",
		not:"IngredientSupplierItemWhere"
	},
	IngredientSupplierWhere:{
		id:"UUIDCondition",
		internalName:"StringCondition",
		internalNote:"StringCondition",
		locales:"IngredientSupplierLocaleWhere",
		and:"IngredientSupplierWhere",
		or:"IngredientSupplierWhere",
		not:"IngredientSupplierWhere"
	},
	IngredientSupplierLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"IngredientSupplierWhere",
		locale:"LocaleWhere",
		and:"IngredientSupplierLocaleWhere",
		or:"IngredientSupplierLocaleWhere",
		not:"IngredientSupplierLocaleWhere"
	},
	BlogLocaleWhere:{
		id:"UUIDCondition",
		pageName:"StringCondition",
		title:"StringCondition",
		lead:"StringCondition",
		root:"BlogWhere",
		locale:"LocaleWhere",
		and:"BlogLocaleWhere",
		or:"BlogLocaleWhere",
		not:"BlogLocaleWhere"
	},
	BlogWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"BlogLocaleWhere",
		and:"BlogWhere",
		or:"BlogWhere",
		not:"BlogWhere"
	},
	BlogPostLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		availableForWeb:"BooleanCondition",
		availableForMobile:"BooleanCondition",
		root:"BlogPostWhere",
		locale:"LocaleWhere",
		cover:"CoverWhere",
		content:"ContentWhere",
		link:"LinkableWhere",
		seo:"SeoWhere",
		products:"ProductWhere",
		and:"BlogPostLocaleWhere",
		or:"BlogPostLocaleWhere",
		not:"BlogPostLocaleWhere"
	},
	BlogPostWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		locales:"BlogPostLocaleWhere",
		and:"BlogPostWhere",
		or:"BlogPostWhere",
		not:"BlogPostWhere"
	},
	CoverWhere:{
		id:"UUIDCondition",
		primaryTitle:"StringCondition",
		secondaryTitle:"StringCondition",
		medium:"MediumWhere",
		buttonColors:"ColorPalleteWhere",
		and:"CoverWhere",
		or:"CoverWhere",
		not:"CoverWhere"
	},
	MediumWhere:{
		id:"UUIDCondition",
		type:"MediumTypeCondition",
		colorTheme:"ColorThemeCondition",
		image:"ImageWhere",
		video:"VideoWhere",
		and:"MediumWhere",
		or:"MediumWhere",
		not:"MediumWhere"
	},
	MediumTypeCondition:{
		and:"MediumTypeCondition",
		or:"MediumTypeCondition",
		not:"MediumTypeCondition",
		eq:"MediumType",
		notEq:"MediumType",
		in:"MediumType",
		notIn:"MediumType",
		lt:"MediumType",
		lte:"MediumType",
		gt:"MediumType",
		gte:"MediumType"
	},
	MediumType: true,
	ColorThemeCondition:{
		and:"ColorThemeCondition",
		or:"ColorThemeCondition",
		not:"ColorThemeCondition",
		eq:"ColorTheme",
		notEq:"ColorTheme",
		in:"ColorTheme",
		notIn:"ColorTheme",
		lt:"ColorTheme",
		lte:"ColorTheme",
		gt:"ColorTheme",
		gte:"ColorTheme"
	},
	ColorTheme: true,
	VideoWhere:{
		id:"UUIDCondition",
		src:"StringCondition",
		width:"IntCondition",
		height:"IntCondition",
		size:"IntCondition",
		type:"StringCondition",
		duration:"FloatCondition",
		poster:"ImageWhere",
		and:"VideoWhere",
		or:"VideoWhere",
		not:"VideoWhere"
	},
	ColorPalleteWhere:{
		id:"UUIDCondition",
		background:"StringCondition",
		text:"StringCondition",
		and:"ColorPalleteWhere",
		or:"ColorPalleteWhere",
		not:"ColorPalleteWhere"
	},
	ContentWhere:{
		id:"UUIDCondition",
		blocks:"ContentBlockWhere",
		and:"ContentWhere",
		or:"ContentWhere",
		not:"ContentWhere"
	},
	ContentBlockWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		json:"StringCondition",
		content:"ContentWhere",
		references:"ContentReferenceWhere",
		and:"ContentBlockWhere",
		or:"ContentBlockWhere",
		not:"ContentBlockWhere"
	},
	ContentReferenceWhere:{
		id:"UUIDCondition",
		type:"ContentReferenceTypeCondition",
		primaryText:"StringCondition",
		secondaryText:"StringCondition",
		jsonContent:"StringCondition",
		block:"ContentBlockWhere",
		colorPallete:"ColorPalleteWhere",
		medium:"MediumWhere",
		link:"LinkWhere",
		blogPosts:"BlogPostListWhere",
		links:"LinkListWhere",
		products:"ProductListWhere",
		contentSize:"ContentSizeCondition",
		misc:"StringCondition",
		hero:"HeroWhere",
		gallery:"GalleryWhere",
		socialsAndApps:"SocialsAndAppsWhere",
		productGrid:"ProductGridWhere",
		productBanners:"ProductBannerListWhere",
		deliveryRegions:"DeliveryRegionsWhere",
		textList:"TextListWhere",
		gridTiles:"GridTilesWhere",
		and:"ContentReferenceWhere",
		or:"ContentReferenceWhere",
		not:"ContentReferenceWhere"
	},
	ContentReferenceTypeCondition:{
		and:"ContentReferenceTypeCondition",
		or:"ContentReferenceTypeCondition",
		not:"ContentReferenceTypeCondition",
		eq:"ContentReferenceType",
		notEq:"ContentReferenceType",
		in:"ContentReferenceType",
		notIn:"ContentReferenceType",
		lt:"ContentReferenceType",
		lte:"ContentReferenceType",
		gt:"ContentReferenceType",
		gte:"ContentReferenceType"
	},
	ContentReferenceType: true,
	LinkWhere:{
		id:"UUIDCondition",
		type:"LinkTypeCondition",
		title:"StringCondition",
		externalLink:"StringCondition",
		internalLink:"LinkableWhere",
		and:"LinkWhere",
		or:"LinkWhere",
		not:"LinkWhere"
	},
	LinkTypeCondition:{
		and:"LinkTypeCondition",
		or:"LinkTypeCondition",
		not:"LinkTypeCondition",
		eq:"LinkType",
		notEq:"LinkType",
		in:"LinkType",
		notIn:"LinkType",
		lt:"LinkType",
		lte:"LinkType",
		gt:"LinkType",
		gte:"LinkType"
	},
	LinkType: true,
	LinkableWhere:{
		id:"UUIDCondition",
		url:"StringCondition",
		blogPost:"BlogPostLocaleWhere",
		page:"PageLocaleWhere",
		redirect:"RedirectWhere",
		and:"LinkableWhere",
		or:"LinkableWhere",
		not:"LinkableWhere"
	},
	PageLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		link:"LinkableWhere",
		root:"PageWhere",
		locale:"LocaleWhere",
		seo:"SeoWhere",
		content:"ContentWhere",
		cover:"CoverWhere",
		theme:"PageThemeCondition",
		and:"PageLocaleWhere",
		or:"PageLocaleWhere",
		not:"PageLocaleWhere"
	},
	PageWhere:{
		id:"UUIDCondition",
		locales:"PageLocaleWhere",
		and:"PageWhere",
		or:"PageWhere",
		not:"PageWhere"
	},
	SeoWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		description:"StringCondition",
		ogTitle:"StringCondition",
		ogDescription:"StringCondition",
		ogImage:"ImageWhere",
		and:"SeoWhere",
		or:"SeoWhere",
		not:"SeoWhere"
	},
	PageThemeCondition:{
		and:"PageThemeCondition",
		or:"PageThemeCondition",
		not:"PageThemeCondition",
		eq:"PageTheme",
		notEq:"PageTheme",
		in:"PageTheme",
		notIn:"PageTheme",
		lt:"PageTheme",
		lte:"PageTheme",
		gt:"PageTheme",
		gte:"PageTheme"
	},
	PageTheme: true,
	RedirectWhere:{
		id:"UUIDCondition",
		link:"LinkableWhere",
		target:"LinkWhere",
		and:"RedirectWhere",
		or:"RedirectWhere",
		not:"RedirectWhere"
	},
	BlogPostListWhere:{
		id:"UUIDCondition",
		items:"BlogPostListItemWhere",
		and:"BlogPostListWhere",
		or:"BlogPostListWhere",
		not:"BlogPostListWhere"
	},
	BlogPostListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		caption:"StringCondition",
		list:"BlogPostListWhere",
		blogPost:"BlogPostLocaleWhere",
		and:"BlogPostListItemWhere",
		or:"BlogPostListItemWhere",
		not:"BlogPostListItemWhere"
	},
	LinkListWhere:{
		id:"UUIDCondition",
		items:"LinkListItemWhere",
		and:"LinkListWhere",
		or:"LinkListWhere",
		not:"LinkListWhere"
	},
	LinkListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		isPrimary:"BooleanCondition",
		list:"LinkListWhere",
		link:"LinkWhere",
		and:"LinkListItemWhere",
		or:"LinkListItemWhere",
		not:"LinkListItemWhere"
	},
	ContentSizeCondition:{
		and:"ContentSizeCondition",
		or:"ContentSizeCondition",
		not:"ContentSizeCondition",
		eq:"ContentSize",
		notEq:"ContentSize",
		in:"ContentSize",
		notIn:"ContentSize",
		lt:"ContentSize",
		lte:"ContentSize",
		gt:"ContentSize",
		gte:"ContentSize"
	},
	ContentSize: true,
	HeroWhere:{
		id:"UUIDCondition",
		showLogo:"BooleanCondition",
		middleContent:"StringCondition",
		medium:"MediumWhere",
		and:"HeroWhere",
		or:"HeroWhere",
		not:"HeroWhere"
	},
	GalleryWhere:{
		id:"UUIDCondition",
		items:"GalleryItemWhere",
		and:"GalleryWhere",
		or:"GalleryWhere",
		not:"GalleryWhere"
	},
	GalleryItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		caption:"StringCondition",
		list:"GalleryWhere",
		medium:"MediumWhere",
		and:"GalleryItemWhere",
		or:"GalleryItemWhere",
		not:"GalleryItemWhere"
	},
	SocialsAndAppsWhere:{
		id:"UUIDCondition",
		instagram:"BooleanCondition",
		appStore:"BooleanCondition",
		googlePlay:"BooleanCondition",
		and:"SocialsAndAppsWhere",
		or:"SocialsAndAppsWhere",
		not:"SocialsAndAppsWhere"
	},
	ProductGridWhere:{
		id:"UUIDCondition",
		isCarousel:"BooleanCondition",
		categories:"ProductGridCategoryWhere",
		type:"ProductGridTypeCondition",
		and:"ProductGridWhere",
		or:"ProductGridWhere",
		not:"ProductGridWhere"
	},
	ProductGridCategoryWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		title:"StringCondition",
		root:"ProductGridWhere",
		items:"ProductGridItemWhere",
		images:"ImageListWhere",
		and:"ProductGridCategoryWhere",
		or:"ProductGridCategoryWhere",
		not:"ProductGridCategoryWhere"
	},
	ProductGridItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		title:"StringCondition",
		lead:"StringCondition",
		product:"ProductWhere",
		medium:"MediumWhere",
		link:"LinkWhere",
		category:"ProductGridCategoryWhere",
		description:"StringCondition",
		and:"ProductGridItemWhere",
		or:"ProductGridItemWhere",
		not:"ProductGridItemWhere"
	},
	ImageListWhere:{
		id:"UUIDCondition",
		items:"ImageListItemWhere",
		and:"ImageListWhere",
		or:"ImageListWhere",
		not:"ImageListWhere"
	},
	ImageListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ImageListWhere",
		image:"ImageWhere",
		and:"ImageListItemWhere",
		or:"ImageListItemWhere",
		not:"ImageListItemWhere"
	},
	ProductGridTypeCondition:{
		and:"ProductGridTypeCondition",
		or:"ProductGridTypeCondition",
		not:"ProductGridTypeCondition",
		eq:"ProductGridType",
		notEq:"ProductGridType",
		in:"ProductGridType",
		notIn:"ProductGridType",
		lt:"ProductGridType",
		lte:"ProductGridType",
		gt:"ProductGridType",
		gte:"ProductGridType"
	},
	ProductGridType: true,
	ProductBannerListWhere:{
		id:"UUIDCondition",
		items:"ProductBannerItemWhere",
		and:"ProductBannerListWhere",
		or:"ProductBannerListWhere",
		not:"ProductBannerListWhere"
	},
	ProductBannerItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ProductBannerListWhere",
		banner:"ProductBannerWhere",
		size:"ProductBannerItemSizeCondition",
		and:"ProductBannerItemWhere",
		or:"ProductBannerItemWhere",
		not:"ProductBannerItemWhere"
	},
	ProductBannerWhere:{
		id:"UUIDCondition",
		beforeTitle:"StringCondition",
		title:"StringCondition",
		label:"StringCondition",
		button:"LinkWhere",
		backgroundImage:"ImageWhere",
		productImage:"ImageWhere",
		product:"ProductWhere",
		and:"ProductBannerWhere",
		or:"ProductBannerWhere",
		not:"ProductBannerWhere"
	},
	ProductBannerItemSizeCondition:{
		and:"ProductBannerItemSizeCondition",
		or:"ProductBannerItemSizeCondition",
		not:"ProductBannerItemSizeCondition",
		eq:"ProductBannerItemSize",
		notEq:"ProductBannerItemSize",
		in:"ProductBannerItemSize",
		notIn:"ProductBannerItemSize",
		lt:"ProductBannerItemSize",
		lte:"ProductBannerItemSize",
		gt:"ProductBannerItemSize",
		gte:"ProductBannerItemSize"
	},
	ProductBannerItemSize: true,
	DeliveryRegionsWhere:{
		id:"UUIDCondition",
		items:"DeliveryRegionsItemWhere",
		and:"DeliveryRegionsWhere",
		or:"DeliveryRegionsWhere",
		not:"DeliveryRegionsWhere"
	},
	DeliveryRegionsItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		title:"StringCondition",
		offerLabel:"StringCondition",
		list:"DeliveryRegionsWhere",
		image:"ImageWhere",
		textList:"TextListWhere",
		gallery:"ImageListWhere",
		and:"DeliveryRegionsItemWhere",
		or:"DeliveryRegionsItemWhere",
		not:"DeliveryRegionsItemWhere"
	},
	TextListWhere:{
		id:"UUIDCondition",
		items:"TextItemWhere",
		and:"TextListWhere",
		or:"TextListWhere",
		not:"TextListWhere"
	},
	TextItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		text:"StringCondition",
		list:"TextListWhere",
		title:"StringCondition",
		and:"TextItemWhere",
		or:"TextItemWhere",
		not:"TextItemWhere"
	},
	GridTilesWhere:{
		id:"UUIDCondition",
		items:"GridTileItemWhere",
		and:"GridTilesWhere",
		or:"GridTilesWhere",
		not:"GridTilesWhere"
	},
	GridTileItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		text:"StringCondition",
		list:"GridTilesWhere",
		medium:"MediumWhere",
		link:"LinkWhere",
		and:"GridTileItemWhere",
		or:"GridTileItemWhere",
		not:"GridTileItemWhere"
	},
	TranslationCataloguesIdentifierWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		catalogue:"TranslationCatalogueWhere",
		and:"TranslationCataloguesIdentifierWhere",
		or:"TranslationCataloguesIdentifierWhere",
		not:"TranslationCataloguesIdentifierWhere"
	},
	TranslationCatalogueWhere:{
		id:"UUIDCondition",
		domain:"TranslationDomainWhere",
		fallback:"TranslationCatalogueWhere",
		values:"TranslationValueWhere",
		identifier:"TranslationCataloguesIdentifierWhere",
		and:"TranslationCatalogueWhere",
		or:"TranslationCatalogueWhere",
		not:"TranslationCatalogueWhere"
	},
	TranslationDomainWhere:{
		id:"UUIDCondition",
		identifier:"StringCondition",
		name:"StringCondition",
		catalogues:"TranslationCatalogueWhere",
		keys:"TranslationKeyWhere",
		and:"TranslationDomainWhere",
		or:"TranslationDomainWhere",
		not:"TranslationDomainWhere"
	},
	TranslationKeyWhere:{
		id:"UUIDCondition",
		identifier:"StringCondition",
		contentType:"TranslationContentTypeCondition",
		note:"StringCondition",
		domain:"TranslationDomainWhere",
		values:"TranslationValueWhere",
		and:"TranslationKeyWhere",
		or:"TranslationKeyWhere",
		not:"TranslationKeyWhere"
	},
	TranslationContentTypeCondition:{
		and:"TranslationContentTypeCondition",
		or:"TranslationContentTypeCondition",
		not:"TranslationContentTypeCondition",
		eq:"TranslationContentType",
		notEq:"TranslationContentType",
		in:"TranslationContentType",
		notIn:"TranslationContentType",
		lt:"TranslationContentType",
		lte:"TranslationContentType",
		gt:"TranslationContentType",
		gte:"TranslationContentType"
	},
	TranslationContentType: true,
	TranslationValueWhere:{
		id:"UUIDCondition",
		value:"StringCondition",
		catalogue:"TranslationCatalogueWhere",
		key:"TranslationKeyWhere",
		and:"TranslationValueWhere",
		or:"TranslationValueWhere",
		not:"TranslationValueWhere"
	},
	ProductLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		description:"StringCondition",
		root:"ProductWhere",
		locale:"LocaleWhere",
		featureList:"TextListWhere",
		printableName:"StringCondition",
		firstSticker:"StringCondition",
		secondSticker:"StringCondition",
		and:"ProductLocaleWhere",
		or:"ProductLocaleWhere",
		not:"ProductLocaleWhere"
	},
	ProductRecipeLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"ProductRecipeWhere",
		locale:"LocaleWhere",
		description:"StringCondition",
		and:"ProductRecipeLocaleWhere",
		or:"ProductRecipeLocaleWhere",
		not:"ProductRecipeLocaleWhere"
	},
	ProductRecipeWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		products:"ProductWhere",
		internalNote:"StringCondition",
		color:"StringCondition",
		image:"ImageWhere",
		icon:"ImageWhere",
		ingredients:"ProductIngredientItemWhere",
		needsPrepping:"BooleanCondition",
		locales:"ProductRecipeLocaleWhere",
		gutTuning:"FloatCondition",
		freshingContainer:"FreshingContainerWhere",
		description:"StringCondition",
		and:"ProductRecipeWhere",
		or:"ProductRecipeWhere",
		not:"ProductRecipeWhere"
	},
	ProductIngredientItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		internalOrder:"IntCondition",
		recipe:"ProductRecipeWhere",
		ingredient:"ProductIngredientWhere",
		quantity:"ProductIngredientItemQuantityWhere",
		and:"ProductIngredientItemWhere",
		or:"ProductIngredientItemWhere",
		not:"ProductIngredientItemWhere"
	},
	ProductIngredientItemQuantityWhere:{
		id:"UUIDCondition",
		unit:"ProductIngredientItemQuantityUnitWhere",
		value:"FloatCondition",
		and:"ProductIngredientItemQuantityWhere",
		or:"ProductIngredientItemQuantityWhere",
		not:"ProductIngredientItemQuantityWhere"
	},
	DeliveryMethodLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		description:"StringCondition",
		trackingCodeUrlTemplate:"StringCondition",
		processingTitle:"StringCondition",
		processingText:"StringCondition",
		processingIconUrl:"StringCondition",
		dispatchedTitle:"StringCondition",
		dispatchedText:"StringCondition",
		dispatchedIconUrl:"StringCondition",
		fulfilledTitle:"StringCondition",
		fulfilledText:"StringCondition",
		fulfilledIconUrl:"StringCondition",
		locale:"LocaleWhere",
		root:"DeliveryMethodWhere",
		and:"DeliveryMethodLocaleWhere",
		or:"DeliveryMethodLocaleWhere",
		not:"DeliveryMethodLocaleWhere"
	},
	DeliveryMethodWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		enabled:"BooleanCondition",
		paymentMethods:"PaymentMethodWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		requireDeliveryAddress:"BooleanCondition",
		suitableForDeliveryZones:"DeliveryZoneWhere",
		enableDeliveryTracking:"BooleanCondition",
		locales:"DeliveryMethodLocaleWhere",
		timelinePreset:"DeliveryTimelinePresetWhere",
		and:"DeliveryMethodWhere",
		or:"DeliveryMethodWhere",
		not:"DeliveryMethodWhere"
	},
	PaymentMethodWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		enabled:"BooleanCondition",
		deliveryMethods:"DeliveryMethodWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		blockingDispatch:"BooleanCondition",
		type:"PaymentMethodTypeCondition",
		reduceGratuityByPercentage:"IntCondition",
		and:"PaymentMethodWhere",
		or:"PaymentMethodWhere",
		not:"PaymentMethodWhere"
	},
	PaymentMethodTypeCondition:{
		and:"PaymentMethodTypeCondition",
		or:"PaymentMethodTypeCondition",
		not:"PaymentMethodTypeCondition",
		eq:"PaymentMethodType",
		notEq:"PaymentMethodType",
		in:"PaymentMethodType",
		notIn:"PaymentMethodType",
		lt:"PaymentMethodType",
		lte:"PaymentMethodType",
		gt:"PaymentMethodType",
		gte:"PaymentMethodType"
	},
	PaymentMethodType: true,
	DeliveryZoneWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		description:"StringCondition",
		internalNote:"StringCondition",
		type:"DeliveryZoneTypeCondition",
		addresses:"AddressWhere",
		suitableDeliveryMethods:"DeliveryMethodWhere",
		deliveryZonesOfTags:"TagWhere",
		deliveryZonesOfProducts:"ProductWhere",
		and:"DeliveryZoneWhere",
		or:"DeliveryZoneWhere",
		not:"DeliveryZoneWhere"
	},
	DeliveryZoneTypeCondition:{
		and:"DeliveryZoneTypeCondition",
		or:"DeliveryZoneTypeCondition",
		not:"DeliveryZoneTypeCondition",
		eq:"DeliveryZoneType",
		notEq:"DeliveryZoneType",
		in:"DeliveryZoneType",
		notIn:"DeliveryZoneType",
		lt:"DeliveryZoneType",
		lte:"DeliveryZoneType",
		gt:"DeliveryZoneType",
		gte:"DeliveryZoneType"
	},
	DeliveryZoneType: true,
	DeliveryTimelinePresetWhere:{
		id:"UUIDCondition",
		type:"DeliveryTimelineTypeCondition",
		duration:"StringCondition",
		window:"StringCondition",
		deliveryMethod:"DeliveryMethodWhere",
		and:"DeliveryTimelinePresetWhere",
		or:"DeliveryTimelinePresetWhere",
		not:"DeliveryTimelinePresetWhere"
	},
	DeliveryTimelineTypeCondition:{
		and:"DeliveryTimelineTypeCondition",
		or:"DeliveryTimelineTypeCondition",
		not:"DeliveryTimelineTypeCondition",
		eq:"DeliveryTimelineType",
		notEq:"DeliveryTimelineType",
		in:"DeliveryTimelineType",
		notIn:"DeliveryTimelineType",
		lt:"DeliveryTimelineType",
		lte:"DeliveryTimelineType",
		gt:"DeliveryTimelineType",
		gte:"DeliveryTimelineType"
	},
	DeliveryTimelineType: true,
	IdentificationCodeLocaleWhere:{
		id:"UUIDCondition",
		successMessage:"StringCondition",
		root:"IdentificationCodeWhere",
		locale:"LocaleWhere",
		and:"IdentificationCodeLocaleWhere",
		or:"IdentificationCodeLocaleWhere",
		not:"IdentificationCodeLocaleWhere"
	},
	IdentificationCodeWhere:{
		id:"UUIDCondition",
		code:"StringCondition",
		createdAt:"DateTimeCondition",
		attempts:"IdentificationAttemptWhere",
		locales:"IdentificationCodeLocaleWhere",
		internalName:"StringCondition",
		and:"IdentificationCodeWhere",
		or:"IdentificationCodeWhere",
		not:"IdentificationCodeWhere"
	},
	DateTimeCondition:{
		and:"DateTimeCondition",
		or:"DateTimeCondition",
		not:"DateTimeCondition",
		eq:"DateTime",
		notEq:"DateTime",
		in:"DateTime",
		notIn:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		gt:"DateTime",
		gte:"DateTime"
	},
	DateTime: "String",
	IdentificationAttemptWhere:{
		id:"UUIDCondition",
		createdAt:"DateTimeCondition",
		code:"IdentificationCodeWhere",
		and:"IdentificationAttemptWhere",
		or:"IdentificationAttemptWhere",
		not:"IdentificationAttemptWhere"
	},
	SubscriptionBoxLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		stickerText:"StringCondition",
		descriptionText:"StringCondition",
		root:"SubscriptionBoxWhere",
		locale:"LocaleWhere",
		secondaryTitle:"StringCondition",
		texts:"TextListWhere",
		secondaryTexts:"TextListWhere",
		and:"SubscriptionBoxLocaleWhere",
		or:"SubscriptionBoxLocaleWhere",
		not:"SubscriptionBoxLocaleWhere"
	},
	SubscriptionBoxWhere:{
		id:"UUIDCondition",
		locales:"SubscriptionBoxLocaleWhere",
		unique:"OneCondition",
		and:"SubscriptionBoxWhere",
		or:"SubscriptionBoxWhere",
		not:"SubscriptionBoxWhere"
	},
	PreparedPackingQuantityWhere:{
		id:"UUIDCondition",
		date:"DateCondition",
		quantity:"FloatCondition",
		recipe:"ProductRecipeWhere",
		packing:"ProductPackingWhere",
		packedInCardboard:"BooleanCondition",
		and:"PreparedPackingQuantityWhere",
		or:"PreparedPackingQuantityWhere",
		not:"PreparedPackingQuantityWhere"
	},
	DateCondition:{
		and:"DateCondition",
		or:"DateCondition",
		not:"DateCondition",
		eq:"Date",
		notEq:"Date",
		in:"Date",
		notIn:"Date",
		lt:"Date",
		lte:"Date",
		gt:"Date",
		gte:"Date"
	},
	Date: "String",
	ProductStockWhere:{
		id:"UUIDCondition",
		quantity:"IntCondition",
		product:"ProductWhere",
		store:"StoreWhere",
		and:"ProductStockWhere",
		or:"ProductStockWhere",
		not:"ProductStockWhere"
	},
	StoreWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		description:"StringCondition",
		internalNote:"StringCondition",
		and:"StoreWhere",
		or:"StoreWhere",
		not:"StoreWhere"
	},
	ProductAvailabilityCondition:{
		and:"ProductAvailabilityCondition",
		or:"ProductAvailabilityCondition",
		not:"ProductAvailabilityCondition",
		eq:"ProductAvailability",
		notEq:"ProductAvailability",
		in:"ProductAvailability",
		notIn:"ProductAvailability",
		lt:"ProductAvailability",
		lte:"ProductAvailability",
		gt:"ProductAvailability",
		gte:"ProductAvailability"
	},
	ProductAvailability: true,
	OrderItemWhere:{
		id:"UUIDCondition",
		unitPriceCents:"IntCondition",
		quantity:"IntCondition",
		vatRate:"VatRateWhere",
		order:"OrderWhere",
		pickedAt:"DateTimeCondition",
		note:"StringCondition",
		fulfillmentNotes:"FulfillmentNoteWhere",
		product:"ProductWhere",
		and:"OrderItemWhere",
		or:"OrderItemWhere",
		not:"OrderItemWhere"
	},
	OrderWhere:{
		id:"UUIDCondition",
		state:"OrderStateCondition",
		fulfilledAt:"DateTimeCondition",
		canceledAt:"DateTimeCondition",
		note:"StringCondition",
		customer:"CustomerWhere",
		channel:"ChannelWhere",
		delivery:"OrderDeliveryWhere",
		items:"OrderItemWhere",
		internalNote:"StringCondition",
		createdAt:"DateTimeCondition",
		billingAddress:"AddressWhere",
		payments:"OrderPaymentWhere",
		seq:"IntCondition",
		doubleshotLegacyId:"StringCondition",
		venue:"VenueWhere",
		fulfillmentNote:"StringCondition",
		fulfillmentNotes:"FulfillmentNoteWhere",
		doubleshotTotalWeightGrams:"IntCondition",
		doubleShotLegacyNumber:"StringCondition",
		doubleshotLastUpdatedAt:"DateTimeCondition",
		doubleshotAdminUrl:"StringCondition",
		and:"OrderWhere",
		or:"OrderWhere",
		not:"OrderWhere"
	},
	OrderStateCondition:{
		and:"OrderStateCondition",
		or:"OrderStateCondition",
		not:"OrderStateCondition",
		eq:"OrderState",
		notEq:"OrderState",
		in:"OrderState",
		notIn:"OrderState",
		lt:"OrderState",
		lte:"OrderState",
		gt:"OrderState",
		gte:"OrderState"
	},
	OrderState: true,
	ChannelWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		disabled:"BooleanCondition",
		customers:"CustomerWhere",
		code:"ChannelCodeCondition",
		addTagsOnRegistration:"TagWhere",
		publicTags:"TagWhere",
		venue:"VenueWhere",
		and:"ChannelWhere",
		or:"ChannelWhere",
		not:"ChannelWhere"
	},
	ChannelCodeCondition:{
		and:"ChannelCodeCondition",
		or:"ChannelCodeCondition",
		not:"ChannelCodeCondition",
		eq:"ChannelCode",
		notEq:"ChannelCode",
		in:"ChannelCode",
		notIn:"ChannelCode",
		lt:"ChannelCode",
		lte:"ChannelCode",
		gt:"ChannelCode",
		gte:"ChannelCode"
	},
	ChannelCode: true,
	VenueWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		deadlineTemplate:"DeadlineTemplateWhere",
		timetableDays:"TimetableDayWhere",
		timetableTemplates:"TimetableTemplateWhere",
		staffMembers:"StaffWhere",
		employeeRoles:"VenueEmployeeRoleWhere",
		orders:"OrderWhere",
		fulfillmentNotes:"FulfillmentNoteWhere",
		enabledUnassignReasons:"UnassignReasonWhere",
		channels:"ChannelWhere",
		address:"StringCondition",
		pointOfSalePngBase64ReceiptLogo:"StringCondition",
		pointOfSaleInitialView:"PointOfSaleInitialViewCondition",
		pointOfSalePreferPrintReceipt:"BooleanCondition",
		and:"VenueWhere",
		or:"VenueWhere",
		not:"VenueWhere"
	},
	DeadlineTemplateWhere:{
		id:"UUIDCondition",
		startDate:"DateCondition",
		period:"IntCondition",
		cutoff:"IntCondition",
		closed:"BooleanCondition",
		venue:"VenueWhere",
		and:"DeadlineTemplateWhere",
		or:"DeadlineTemplateWhere",
		not:"DeadlineTemplateWhere"
	},
	TimetableDayWhere:{
		id:"UUIDCondition",
		date:"DateCondition",
		type:"TimetableDayTypeCondition",
		shiftGroups:"TimetableShiftGroupWhere",
		tipsCents:"IntCondition",
		notes:"TimetableDayNoteWhere",
		venue:"VenueWhere",
		meta:"TimetableDayMetadataWhere",
		and:"TimetableDayWhere",
		or:"TimetableDayWhere",
		not:"TimetableDayWhere"
	},
	TimetableDayTypeCondition:{
		and:"TimetableDayTypeCondition",
		or:"TimetableDayTypeCondition",
		not:"TimetableDayTypeCondition",
		eq:"TimetableDayType",
		notEq:"TimetableDayType",
		in:"TimetableDayType",
		notIn:"TimetableDayType",
		lt:"TimetableDayType",
		lte:"TimetableDayType",
		gt:"TimetableDayType",
		gte:"TimetableDayType"
	},
	TimetableDayType: true,
	TimetableShiftGroupWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		shifts:"TimetableShiftWhere",
		day:"TimetableDayWhere",
		type:"TimetableEmployeeRoleWhere",
		position:"TimetableShiftPositionWhere",
		queue:"EmployeeQueueWhere",
		generatedByTemplate:"TimetableTemplateWhere",
		and:"TimetableShiftGroupWhere",
		or:"TimetableShiftGroupWhere",
		not:"TimetableShiftGroupWhere"
	},
	TimetableShiftWhere:{
		id:"UUIDCondition",
		locked:"BooleanCondition",
		order:"IntCondition",
		assigned:"DateCondition",
		group:"TimetableShiftGroupWhere",
		shiftsProfile:"ShiftsProfileWhere",
		queueItem:"EmployeeQueueItemWhere",
		isOvertime:"BooleanCondition",
		and:"TimetableShiftWhere",
		or:"TimetableShiftWhere",
		not:"TimetableShiftWhere"
	},
	ShiftsProfileWhere:{
		id:"UUIDCondition",
		queueItems:"EmployeeQueueItemWhere",
		staff:"StaffWhere",
		roles:"TimetableEmployeeRoleWhere",
		shifts:"TimetableShiftWhere",
		allowSelfUnassigning:"BooleanCondition",
		and:"ShiftsProfileWhere",
		or:"ShiftsProfileWhere",
		not:"ShiftsProfileWhere"
	},
	EmployeeQueueItemWhere:{
		id:"UUIDCondition",
		start:"StringCondition",
		end:"StringCondition",
		queue:"EmployeeQueueWhere",
		shiftsProfile:"ShiftsProfileWhere",
		shift:"TimetableShiftWhere",
		unassignInfo:"UnassignedShiftInfoWhere",
		and:"EmployeeQueueItemWhere",
		or:"EmployeeQueueItemWhere",
		not:"EmployeeQueueItemWhere"
	},
	EmployeeQueueWhere:{
		id:"UUIDCondition",
		shiftGroup:"TimetableShiftGroupWhere",
		items:"EmployeeQueueItemWhere",
		contemberBugFix:"BooleanCondition",
		and:"EmployeeQueueWhere",
		or:"EmployeeQueueWhere",
		not:"EmployeeQueueWhere"
	},
	UnassignedShiftInfoWhere:{
		id:"UUIDCondition",
		hoursMissed:"IntCondition",
		queueItem:"EmployeeQueueItemWhere",
		legacyReason:"UnassignedInfoReasonCondition",
		reason:"UnassignReasonWhere",
		and:"UnassignedShiftInfoWhere",
		or:"UnassignedShiftInfoWhere",
		not:"UnassignedShiftInfoWhere"
	},
	UnassignedInfoReasonCondition:{
		and:"UnassignedInfoReasonCondition",
		or:"UnassignedInfoReasonCondition",
		not:"UnassignedInfoReasonCondition",
		eq:"UnassignedInfoReason",
		notEq:"UnassignedInfoReason",
		in:"UnassignedInfoReason",
		notIn:"UnassignedInfoReason",
		lt:"UnassignedInfoReason",
		lte:"UnassignedInfoReason",
		gt:"UnassignedInfoReason",
		gte:"UnassignedInfoReason"
	},
	UnassignedInfoReason: true,
	UnassignReasonWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		name:"StringCondition",
		venues:"VenueWhere",
		and:"UnassignReasonWhere",
		or:"UnassignReasonWhere",
		not:"UnassignReasonWhere"
	},
	StaffWhere:{
		id:"UUIDCondition",
		shiftsProfile:"ShiftsProfileWhere",
		photo:"ImageWhere",
		createdAt:"DateTimeCondition",
		venues:"VenueWhere",
		and:"StaffWhere",
		or:"StaffWhere",
		not:"StaffWhere"
	},
	TimetableEmployeeRoleWhere:{
		id:"UUIDCondition",
		shiftGroups:"TimetableShiftGroupWhere",
		shiftsProfiles:"ShiftsProfileWhere",
		name:"StringCondition",
		timetableTemplates:"TimetableTemplateWhere",
		color:"StringCondition",
		and:"TimetableEmployeeRoleWhere",
		or:"TimetableEmployeeRoleWhere",
		not:"TimetableEmployeeRoleWhere"
	},
	TimetableTemplateWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		startDate:"DateCondition",
		period:"IntCondition",
		slots:"IntCondition",
		position:"TimetableShiftPositionWhere",
		internalName:"StringCondition",
		venue:"VenueWhere",
		employeeRole:"TimetableEmployeeRoleWhere",
		workdaysOnly:"BooleanCondition",
		monday:"BooleanCondition",
		tuesday:"BooleanCondition",
		wednesday:"BooleanCondition",
		thursday:"BooleanCondition",
		friday:"BooleanCondition",
		saturday:"BooleanCondition",
		sunday:"BooleanCondition",
		type:"TemplateTypeCondition",
		and:"TimetableTemplateWhere",
		or:"TimetableTemplateWhere",
		not:"TimetableTemplateWhere"
	},
	TimetableShiftPositionWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		start:"StringCondition",
		end:"StringCondition",
		shiftGroups:"TimetableShiftGroupWhere",
		timetableTemplates:"TimetableTemplateWhere",
		isDynamic:"BooleanCondition",
		and:"TimetableShiftPositionWhere",
		or:"TimetableShiftPositionWhere",
		not:"TimetableShiftPositionWhere"
	},
	TemplateTypeCondition:{
		and:"TemplateTypeCondition",
		or:"TemplateTypeCondition",
		not:"TemplateTypeCondition",
		eq:"TemplateType",
		notEq:"TemplateType",
		in:"TemplateType",
		notIn:"TemplateType",
		lt:"TemplateType",
		lte:"TemplateType",
		gt:"TemplateType",
		gte:"TemplateType"
	},
	TemplateType: true,
	TimetableDayNoteWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		note:"StringCondition",
		day:"TimetableDayWhere",
		role:"TimetableEmployeeRoleWhere",
		and:"TimetableDayNoteWhere",
		or:"TimetableDayNoteWhere",
		not:"TimetableDayNoteWhere"
	},
	TimetableDayMetadataWhere:{
		id:"UUIDCondition",
		numberOfWorkers:"IntCondition",
		tipsTotalCents:"IntCondition",
		totalMinutes:"IntCondition",
		tipsCentsPerMinute:"FloatCondition",
		day:"TimetableDayWhere",
		and:"TimetableDayMetadataWhere",
		or:"TimetableDayMetadataWhere",
		not:"TimetableDayMetadataWhere"
	},
	VenueEmployeeRoleWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		employeePrivileges:"TimetablesEmployeePrivilegesWhere",
		venue:"VenueWhere",
		role:"TimetableEmployeeRoleWhere",
		getsTipsShare:"BooleanCondition",
		notesEnabled:"BooleanCondition",
		and:"VenueEmployeeRoleWhere",
		or:"VenueEmployeeRoleWhere",
		not:"VenueEmployeeRoleWhere"
	},
	TimetablesEmployeePrivilegesWhere:{
		id:"UUIDCondition",
		venueEmployeeRole:"VenueEmployeeRoleWhere",
		read:"BooleanCondition",
		unassign:"BooleanCondition",
		assign:"BooleanCondition",
		and:"TimetablesEmployeePrivilegesWhere",
		or:"TimetablesEmployeePrivilegesWhere",
		not:"TimetablesEmployeePrivilegesWhere"
	},
	FulfillmentNoteWhere:{
		id:"UUIDCondition",
		note:"StringCondition",
		products:"ProductWhere",
		tags:"TagWhere",
		venues:"VenueWhere",
		orderItems:"OrderItemWhere",
		orders:"OrderWhere",
		highlight:"BooleanCondition",
		createdAt:"DateTimeCondition",
		emoji:"StringCondition",
		and:"FulfillmentNoteWhere",
		or:"FulfillmentNoteWhere",
		not:"FulfillmentNoteWhere"
	},
	PointOfSaleInitialViewCondition:{
		and:"PointOfSaleInitialViewCondition",
		or:"PointOfSaleInitialViewCondition",
		not:"PointOfSaleInitialViewCondition",
		eq:"PointOfSaleInitialView",
		notEq:"PointOfSaleInitialView",
		in:"PointOfSaleInitialView",
		notIn:"PointOfSaleInitialView",
		lt:"PointOfSaleInitialView",
		lte:"PointOfSaleInitialView",
		gt:"PointOfSaleInitialView",
		gte:"PointOfSaleInitialView"
	},
	PointOfSaleInitialView: true,
	OrderDeliveryWhere:{
		id:"UUIDCondition",
		priceCents:"IntCondition",
		deliveredAt:"DateTimeCondition",
		method:"DeliveryMethodWhere",
		vatRate:"VatRateWhere",
		order:"OrderWhere",
		expectedAt:"DateTimeCondition",
		state:"OrderDeliveryStateCondition",
		loadingDurationHours:"IntCondition",
		dispatchAt:"DateTimeCondition",
		duration:"StringCondition",
		window:"StringCondition",
		expectedEndAt:"DateTimeCondition",
		processingAt:"DateTimeCondition",
		deliveringAt:"DateTimeCondition",
		failedAt:"DateTimeCondition",
		internalNote:"StringCondition",
		deliveryWaypoint:"DeliveryWaypointWhere",
		prepareAt:"DateTimeCondition",
		pickedAt:"DateTimeCondition",
		address:"AddressWhere",
		trackingCode:"StringCondition",
		and:"OrderDeliveryWhere",
		or:"OrderDeliveryWhere",
		not:"OrderDeliveryWhere"
	},
	OrderDeliveryStateCondition:{
		and:"OrderDeliveryStateCondition",
		or:"OrderDeliveryStateCondition",
		not:"OrderDeliveryStateCondition",
		eq:"OrderDeliveryState",
		notEq:"OrderDeliveryState",
		in:"OrderDeliveryState",
		notIn:"OrderDeliveryState",
		lt:"OrderDeliveryState",
		lte:"OrderDeliveryState",
		gt:"OrderDeliveryState",
		gte:"OrderDeliveryState"
	},
	OrderDeliveryState: true,
	DeliveryWaypointWhere:{
		id:"UUIDCondition",
		estimatedArrival:"DateTimeCondition",
		note:"StringCondition",
		route:"DeliveryRouteWhere",
		sortingOrder:"IntCondition",
		delivery:"OrderDeliveryWhere",
		estimatedDistance:"IntCondition",
		estimatedDuration:"IntCondition",
		originDeparture:"DateTimeCondition",
		originAddress:"StringCondition",
		transitMode:"StringCondition",
		and:"DeliveryWaypointWhere",
		or:"DeliveryWaypointWhere",
		not:"DeliveryWaypointWhere"
	},
	DeliveryRouteWhere:{
		id:"UUIDCondition",
		createdAt:"DateTimeCondition",
		waypoints:"DeliveryWaypointWhere",
		dispatchAt:"StringCondition",
		and:"DeliveryRouteWhere",
		or:"DeliveryRouteWhere",
		not:"DeliveryRouteWhere"
	},
	OrderPaymentWhere:{
		id:"UUIDCondition",
		data:"JsonCondition",
		paymentCents:"IntCondition",
		method:"PaymentMethodWhere",
		failedAt:"DateTimeCondition",
		state:"OrderPaymentStateCondition",
		approvedAt:"DateTimeCondition",
		order:"OrderWhere",
		walletType:"StringCondition",
		createdAt:"DateTimeCondition",
		publicReceiptUrl:"StringCondition",
		priceCents:"IntCondition",
		vatRate:"VatRateWhere",
		otherCurrencyPriceCents:"StringCondition",
		and:"OrderPaymentWhere",
		or:"OrderPaymentWhere",
		not:"OrderPaymentWhere"
	},
	JsonCondition:{
		and:"JsonCondition",
		or:"JsonCondition",
		not:"JsonCondition"
	},
	OrderPaymentStateCondition:{
		and:"OrderPaymentStateCondition",
		or:"OrderPaymentStateCondition",
		not:"OrderPaymentStateCondition",
		eq:"OrderPaymentState",
		notEq:"OrderPaymentState",
		in:"OrderPaymentState",
		notIn:"OrderPaymentState",
		lt:"OrderPaymentState",
		lte:"OrderPaymentState",
		gt:"OrderPaymentState",
		gte:"OrderPaymentState"
	},
	OrderPaymentState: true,
	ProductMetadataWhere:{
		id:"UUIDCondition",
		archived:"BooleanCondition",
		available:"BooleanCondition",
		availableQuantity:"IntCondition",
		product:"ProductWhere",
		and:"ProductMetadataWhere",
		or:"ProductMetadataWhere",
		not:"ProductMetadataWhere"
	},
	CustomerNameWhere:{
		id:"UUIDCondition",
		nominative:"StringCondition",
		vocative:"StringCondition",
		customer:"CustomerWhere",
		and:"CustomerNameWhere",
		or:"CustomerNameWhere",
		not:"CustomerNameWhere"
	},
	CustomerFamiliarityCondition:{
		and:"CustomerFamiliarityCondition",
		or:"CustomerFamiliarityCondition",
		not:"CustomerFamiliarityCondition",
		eq:"CustomerFamiliarity",
		notEq:"CustomerFamiliarity",
		in:"CustomerFamiliarity",
		notIn:"CustomerFamiliarity",
		lt:"CustomerFamiliarity",
		lte:"CustomerFamiliarity",
		gt:"CustomerFamiliarity",
		gte:"CustomerFamiliarity"
	},
	CustomerFamiliarity: true,
	CustomerMetadataWhere:{
		id:"UUIDCondition",
		isBusiness:"BooleanCondition",
		name:"StringCondition",
		fallbackName:"StringCondition",
		remainingCreditsCents:"IntCondition",
		ordersTotalCents:"IntCondition",
		customerProfileUrl:"StringCondition",
		customer:"CustomerWhere",
		and:"CustomerMetadataWhere",
		or:"CustomerMetadataWhere",
		not:"CustomerMetadataWhere"
	},
	CountryWhere:{
		id:"UUIDCondition",
		code:"StringCondition",
		order:"IntCondition",
		and:"CountryWhere",
		or:"CountryWhere",
		not:"CountryWhere"
	},
	AddressOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		firstName:"OrderDirection",
		lastName:"OrderDirection",
		addressLine2:"OrderDirection",
		email:"OrderDirection",
		phone:"OrderDirection",
		companyName:"OrderDirection",
		companyIdentifier:"OrderDirection",
		vatIdentifier:"OrderDirection",
		externalIdentifier:"OrderDirection",
		hidden:"OrderDirection",
		customer:"CustomerOrderBy",
		internalNote:"OrderDirection",
		country:"CountryOrderBy",
		deletedAt:"OrderDirection",
		note:"OrderDirection",
		googlePlaceId:"OrderDirection",
		raw:"OrderDirection",
		formatted:"OrderDirection",
		geocodeGeneratedAt:"OrderDirection",
		geocodeResponse:"OrderDirection",
		deliveryZone:"DeliveryZoneOrderBy",
		geocodeValid:"OrderDirection",
		fullName:"OrderDirection",
		fakturoidSubjectId:"OrderDirection",
		addressLine1:"OrderDirection",
		city:"OrderDirection",
		postalCode:"OrderDirection",
		createdAt:"OrderDirection",
		defaultDeliveryAddressOfCustomer:"CustomerOrderBy"
	},
	OrderDirection: true,
	CustomerOrderBy:{
		id:"OrderDirection",
		defaultBillingAddress:"AddressOrderBy",
		photo:"ImageOrderBy",
		phone:"OrderDirection",
		name:"CustomerNameOrderBy",
		internalNote:"OrderDirection",
		welcomeMessage:"OrderDirection",
		familiarity:"OrderDirection",
		disabledAt:"OrderDirection",
		email:"OrderDirection",
		createdAt:"OrderDirection",
		defaultDeliveryAddress:"AddressOrderBy",
		prefersPackingsWithoutCardboard:"OrderDirection",
		doubleshotLegacyId:"OrderDirection",
		meta:"CustomerMetadataOrderBy",
		doubleshotLastUpdatedAt:"OrderDirection"
	},
	ImageOrderBy:{
		id:"OrderDirection",
		url:"OrderDirection",
		width:"OrderDirection",
		height:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection",
		alt:"OrderDirection"
	},
	CustomerNameOrderBy:{
		id:"OrderDirection",
		nominative:"OrderDirection",
		vocative:"OrderDirection",
		customer:"CustomerOrderBy"
	},
	CustomerMetadataOrderBy:{
		id:"OrderDirection",
		isBusiness:"OrderDirection",
		name:"OrderDirection",
		fallbackName:"OrderDirection",
		remainingCreditsCents:"OrderDirection",
		ordersTotalCents:"OrderDirection",
		customerProfileUrl:"OrderDirection",
		customer:"CustomerOrderBy"
	},
	CountryOrderBy:{
		id:"OrderDirection",
		code:"OrderDirection",
		order:"OrderDirection"
	},
	DeliveryZoneOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		type:"OrderDirection"
	},
	Tag:{
		customers:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		products:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		categories:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		addTagsOnRegistration:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		deliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		publicInChannels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		fulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		paginateCustomers:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		paginateProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateCategories:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		paginateAddTagsOnRegistration:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		paginateDeliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		paginatePublicInChannels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		paginateFulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		}
	},
	Product:{
		packing:{
			filter:"ProductPackingWhere"
		},
		recipe:{
			filter:"ProductRecipeWhere"
		},
		tags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		categories:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		vatRate:{
			filter:"VatRateWhere"
		},
		stocks:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		image:{
			filter:"ImageWhere"
		},
		orderItems:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		deliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		locales:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		virtualProduct:{
			filter:"VirtualProductWhere"
		},
		meta:{
			filter:"ProductMetadataWhere"
		},
		page:{
			filter:"PageWhere"
		},
		fulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		stocksByStore:{
			by:"ProductStocksByStoreUniqueWhere",
			filter:"ProductStockWhere"
		},
		localesByLocale:{
			by:"ProductLocalesByLocaleUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		localesByFeatureList:{
			by:"ProductLocalesByFeatureListUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		paginateTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateCategories:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		paginateStocks:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		paginateOrderItems:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		paginateDeliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		paginateLocales:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		paginateFulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		}
	},
	ProductPacking:{
		image:{
			filter:"ImageWhere"
		},
		icon:{
			filter:"ImageWhere"
		},
		locales:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		preparedPackingQuantities:{
			filter:"PreparedPackingQuantityWhere",
			orderBy:"PreparedPackingQuantityOrderBy"
		},
		localesByLocale:{
			by:"ProductPackingLocalesByLocaleUniqueWhere",
			filter:"ProductPackingLocaleWhere"
		},
		paginateLocales:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		paginatePreparedPackingQuantities:{
			filter:"PreparedPackingQuantityWhere",
			orderBy:"PreparedPackingQuantityOrderBy"
		}
	},
	ProductPackingLocale:{
		root:{
			filter:"ProductPackingWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	Locale:{
		businessCategories:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		productCategoryLists:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		productIngredients:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		orderRecurrences:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		blogs:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		blogPosts:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		identifier:{
			filter:"TranslationCataloguesIdentifierWhere"
		},
		pages:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		productIngredientCategories:{
			filter:"ProductIngredientCategoryLocaleWhere",
			orderBy:"ProductIngredientCategoryLocaleOrderBy"
		},
		allergens:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		productPackings:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		products:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		productRecipes:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		deliveryMethods:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		ingredientSuppliers:{
			filter:"IngredientSupplierLocaleWhere",
			orderBy:"IngredientSupplierLocaleOrderBy"
		},
		identificationCodes:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		},
		subscriptionBoxes:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		},
		businessCategoriesByRoot:{
			by:"LocaleBusinessCategoriesByRootUniqueWhere",
			filter:"BusinessCategoryLocaleWhere"
		},
		productCategoryListsByRoot:{
			by:"LocaleProductCategoryListsByRootUniqueWhere",
			filter:"ProductCategoryListLocaleWhere"
		},
		productIngredientsByRoot:{
			by:"LocaleProductIngredientsByRootUniqueWhere",
			filter:"ProductIngredientLocaleWhere"
		},
		orderRecurrencesByRoot:{
			by:"LocaleOrderRecurrencesByRootUniqueWhere",
			filter:"OrderRecurrenceLocaleWhere"
		},
		blogsByRoot:{
			by:"LocaleBlogsByRootUniqueWhere",
			filter:"BlogLocaleWhere"
		},
		blogPostsByRoot:{
			by:"LocaleBlogPostsByRootUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostsByCover:{
			by:"LocaleBlogPostsByCoverUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostsByContent:{
			by:"LocaleBlogPostsByContentUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostsByLink:{
			by:"LocaleBlogPostsByLinkUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostsBySeo:{
			by:"LocaleBlogPostsBySeoUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		pagesByRoot:{
			by:"LocalePagesByRootUniqueWhere",
			filter:"PageLocaleWhere"
		},
		pagesByLink:{
			by:"LocalePagesByLinkUniqueWhere",
			filter:"PageLocaleWhere"
		},
		pagesBySeo:{
			by:"LocalePagesBySeoUniqueWhere",
			filter:"PageLocaleWhere"
		},
		pagesByContent:{
			by:"LocalePagesByContentUniqueWhere",
			filter:"PageLocaleWhere"
		},
		pagesByCover:{
			by:"LocalePagesByCoverUniqueWhere",
			filter:"PageLocaleWhere"
		},
		productIngredientCategoriesByRoot:{
			by:"LocaleProductIngredientCategoriesByRootUniqueWhere",
			filter:"ProductIngredientCategoryLocaleWhere"
		},
		allergensByRoot:{
			by:"LocaleAllergensByRootUniqueWhere",
			filter:"AllergenLocaleWhere"
		},
		productPackingsByRoot:{
			by:"LocaleProductPackingsByRootUniqueWhere",
			filter:"ProductPackingLocaleWhere"
		},
		productsByRoot:{
			by:"LocaleProductsByRootUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		productsByFeatureList:{
			by:"LocaleProductsByFeatureListUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		productRecipesByRoot:{
			by:"LocaleProductRecipesByRootUniqueWhere",
			filter:"ProductRecipeLocaleWhere"
		},
		deliveryMethodsByRoot:{
			by:"LocaleDeliveryMethodsByRootUniqueWhere",
			filter:"DeliveryMethodLocaleWhere"
		},
		ingredientSuppliersByRoot:{
			by:"LocaleIngredientSuppliersByRootUniqueWhere",
			filter:"IngredientSupplierLocaleWhere"
		},
		identificationCodesByRoot:{
			by:"LocaleIdentificationCodesByRootUniqueWhere",
			filter:"IdentificationCodeLocaleWhere"
		},
		subscriptionBoxesByRoot:{
			by:"LocaleSubscriptionBoxesByRootUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		subscriptionBoxesByTexts:{
			by:"LocaleSubscriptionBoxesByTextsUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		subscriptionBoxesBySecondaryTexts:{
			by:"LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		paginateBusinessCategories:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		paginateProductCategoryLists:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		paginateProductIngredients:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		paginateOrderRecurrences:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		paginateBlogs:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		paginateBlogPosts:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		paginatePages:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		paginateProductIngredientCategories:{
			filter:"ProductIngredientCategoryLocaleWhere",
			orderBy:"ProductIngredientCategoryLocaleOrderBy"
		},
		paginateAllergens:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		paginateProductPackings:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		paginateProducts:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		paginateProductRecipes:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		paginateDeliveryMethods:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		paginateIngredientSuppliers:{
			filter:"IngredientSupplierLocaleWhere",
			orderBy:"IngredientSupplierLocaleOrderBy"
		},
		paginateIdentificationCodes:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		},
		paginateSubscriptionBoxes:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		}
	},
	BusinessCategoryLocale:{
		root:{
			filter:"BusinessCategoryWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	BusinessCategory:{
		locales:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		recurrences:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		},
		specificProducts:{
			filter:"ProductListWhere"
		},
		seasonalProductCategories:{
			filter:"ProductCategoryListWhere"
		},
		specificProductsProductCategories:{
			filter:"ProductCategoryListWhere"
		},
		localesByLocale:{
			by:"BusinessCategoryLocalesByLocaleUniqueWhere",
			filter:"BusinessCategoryLocaleWhere"
		},
		paginateLocales:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		paginateRecurrences:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		}
	},
	BusinessCategoryLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"BusinessCategoryOrderBy",
		locale:"LocaleOrderBy",
		title:"OrderDirection"
	},
	BusinessCategoryOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		visibleToNewCustomers:"OrderDirection",
		internalName:"OrderDirection",
		specificProducts:"ProductListOrderBy",
		seasonalProductCategories:"ProductCategoryListOrderBy",
		specificProductsProductCategories:"ProductCategoryListOrderBy",
		slug:"OrderDirection"
	},
	ProductListOrderBy:{
		id:"OrderDirection",
		businessCategory:"BusinessCategoryOrderBy"
	},
	ProductCategoryListOrderBy:{
		id:"OrderDirection",
		seasonalProductCategoriesOfBusinessCategory:"BusinessCategoryOrderBy",
		specificProductsProductCategoriesOfBusinessCategory:"BusinessCategoryOrderBy"
	},
	LocaleOrderBy:{
		id:"OrderDirection",
		identifier:"TranslationCataloguesIdentifierOrderBy"
	},
	TranslationCataloguesIdentifierOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection"
	},
	OrderRecurrence:{
		locales:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		businessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		},
		localesByLocale:{
			by:"OrderRecurrenceLocalesByLocaleUniqueWhere",
			filter:"OrderRecurrenceLocaleWhere"
		},
		paginateLocales:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		paginateBusinessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		}
	},
	OrderRecurrenceLocale:{
		root:{
			filter:"OrderRecurrenceWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	OrderRecurrenceLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"OrderRecurrenceOrderBy",
		locale:"LocaleOrderBy"
	},
	OrderRecurrenceOrderBy:{
		id:"OrderDirection",
		internalName:"OrderDirection",
		order:"OrderDirection"
	},
	OrderRecurrenceLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	LocaleUniqueWhere:{
		id:"UUID",
		businessCategories:"BusinessCategoryLocaleUniqueWhere",
		productCategoryLists:"ProductCategoryListLocaleUniqueWhere",
		productIngredients:"ProductIngredientLocaleUniqueWhere",
		orderRecurrences:"OrderRecurrenceLocaleUniqueWhere",
		blogs:"BlogLocaleUniqueWhere",
		blogPosts:"BlogPostLocaleUniqueWhere",
		identifier:"TranslationCataloguesIdentifierUniqueWhere",
		pages:"PageLocaleUniqueWhere",
		productIngredientCategories:"ProductIngredientCategoryLocaleUniqueWhere",
		allergens:"AllergenLocaleUniqueWhere",
		productPackings:"ProductPackingLocaleUniqueWhere",
		products:"ProductLocaleUniqueWhere",
		productRecipes:"ProductRecipeLocaleUniqueWhere",
		deliveryMethods:"DeliveryMethodLocaleUniqueWhere",
		ingredientSuppliers:"IngredientSupplierLocaleUniqueWhere",
		identificationCodes:"IdentificationCodeLocaleUniqueWhere",
		subscriptionBoxes:"SubscriptionBoxLocaleUniqueWhere"
	},
	BusinessCategoryLocaleUniqueWhere:{
		id:"UUID",
		root:"BusinessCategoryUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	BusinessCategoryUniqueWhere:{
		id:"UUID",
		locales:"BusinessCategoryLocaleUniqueWhere",
		specificProducts:"ProductListUniqueWhere",
		seasonalProductCategories:"ProductCategoryListUniqueWhere",
		specificProductsProductCategories:"ProductCategoryListUniqueWhere"
	},
	ProductListUniqueWhere:{
		id:"UUID",
		items:"ProductListItemUniqueWhere",
		businessCategory:"BusinessCategoryUniqueWhere"
	},
	ProductListItemUniqueWhere:{
		id:"UUID"
	},
	ProductCategoryListUniqueWhere:{
		id:"UUID",
		items:"ProductCategoryListItemUniqueWhere",
		locales:"ProductCategoryListLocaleUniqueWhere",
		seasonalProductCategoriesOfBusinessCategory:"BusinessCategoryUniqueWhere",
		specificProductsProductCategoriesOfBusinessCategory:"BusinessCategoryUniqueWhere"
	},
	ProductCategoryListItemUniqueWhere:{
		id:"UUID"
	},
	ProductCategoryListLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductCategoryListUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	ProductIngredientLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductIngredientUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	ProductIngredientUniqueWhere:{
		id:"UUID",
		locales:"ProductIngredientLocaleUniqueWhere",
		suppliers:"IngredientSupplierListUniqueWhere"
	},
	IngredientSupplierListUniqueWhere:{
		id:"UUID",
		items:"IngredientSupplierItemUniqueWhere"
	},
	IngredientSupplierItemUniqueWhere:{
		id:"UUID"
	},
	OrderRecurrenceLocaleUniqueWhere:{
		id:"UUID",
		root:"OrderRecurrenceUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	OrderRecurrenceUniqueWhere:{
		id:"UUID",
		locales:"OrderRecurrenceLocaleUniqueWhere"
	},
	BlogLocaleUniqueWhere:{
		id:"UUID",
		root:"BlogUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	BlogUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"BlogLocaleUniqueWhere"
	},
	BlogPostLocaleUniqueWhere:{
		id:"UUID",
		root:"BlogPostUniqueWhere",
		locale:"LocaleUniqueWhere",
		cover:"CoverUniqueWhere",
		content:"ContentUniqueWhere",
		link:"LinkableUniqueWhere",
		seo:"SeoUniqueWhere"
	},
	BlogPostUniqueWhere:{
		id:"UUID",
		locales:"BlogPostLocaleUniqueWhere"
	},
	CoverUniqueWhere:{
		id:"UUID"
	},
	ContentUniqueWhere:{
		id:"UUID",
		blocks:"ContentBlockUniqueWhere"
	},
	ContentBlockUniqueWhere:{
		id:"UUID",
		references:"ContentReferenceUniqueWhere"
	},
	ContentReferenceUniqueWhere:{
		id:"UUID",
		products:"ProductListUniqueWhere",
		productBanners:"ProductBannerListUniqueWhere",
		deliveryRegions:"DeliveryRegionsUniqueWhere"
	},
	ProductBannerListUniqueWhere:{
		id:"UUID",
		items:"ProductBannerItemUniqueWhere"
	},
	ProductBannerItemUniqueWhere:{
		id:"UUID"
	},
	DeliveryRegionsUniqueWhere:{
		id:"UUID",
		items:"DeliveryRegionsItemUniqueWhere"
	},
	DeliveryRegionsItemUniqueWhere:{
		id:"UUID",
		image:"ImageUniqueWhere",
		textList:"TextListUniqueWhere",
		gallery:"ImageListUniqueWhere"
	},
	ImageUniqueWhere:{
		id:"UUID"
	},
	TextListUniqueWhere:{
		id:"UUID",
		items:"TextItemUniqueWhere"
	},
	TextItemUniqueWhere:{
		id:"UUID"
	},
	ImageListUniqueWhere:{
		id:"UUID",
		items:"ImageListItemUniqueWhere"
	},
	ImageListItemUniqueWhere:{
		id:"UUID",
		image:"ImageUniqueWhere"
	},
	LinkableUniqueWhere:{
		id:"UUID",
		blogPost:"BlogPostLocaleUniqueWhere",
		page:"PageLocaleUniqueWhere",
		redirect:"RedirectUniqueWhere"
	},
	PageLocaleUniqueWhere:{
		id:"UUID",
		root:"PageUniqueWhere",
		locale:"LocaleUniqueWhere",
		link:"LinkableUniqueWhere",
		seo:"SeoUniqueWhere",
		content:"ContentUniqueWhere",
		cover:"CoverUniqueWhere"
	},
	PageUniqueWhere:{
		id:"UUID",
		locales:"PageLocaleUniqueWhere"
	},
	SeoUniqueWhere:{
		id:"UUID",
		ogImage:"ImageUniqueWhere"
	},
	RedirectUniqueWhere:{
		id:"UUID",
		link:"LinkableUniqueWhere"
	},
	TranslationCataloguesIdentifierUniqueWhere:{
		id:"UUID",
		catalogue:"TranslationCatalogueUniqueWhere"
	},
	TranslationCatalogueUniqueWhere:{
		id:"UUID",
		domain:"TranslationDomainUniqueWhere",
		identifier:"TranslationCataloguesIdentifierUniqueWhere",
		values:"TranslationValueUniqueWhere"
	},
	TranslationDomainUniqueWhere:{
		id:"UUID",
		catalogues:"TranslationCatalogueUniqueWhere",
		keys:"TranslationKeyUniqueWhere"
	},
	TranslationKeyUniqueWhere:{
		id:"UUID",
		domain:"TranslationDomainUniqueWhere",
		values:"TranslationValueUniqueWhere"
	},
	TranslationValueUniqueWhere:{
		id:"UUID",
		catalogue:"TranslationCatalogueUniqueWhere",
		key:"TranslationKeyUniqueWhere"
	},
	ProductIngredientCategoryLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductIngredientCategoryUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	ProductIngredientCategoryUniqueWhere:{
		id:"UUID",
		locales:"ProductIngredientCategoryLocaleUniqueWhere"
	},
	AllergenLocaleUniqueWhere:{
		id:"UUID",
		root:"AllergenUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	AllergenUniqueWhere:{
		id:"UUID",
		locales:"AllergenLocaleUniqueWhere"
	},
	ProductPackingLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductPackingUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	ProductPackingUniqueWhere:{
		id:"UUID",
		locales:"ProductPackingLocaleUniqueWhere",
		preparedPackingQuantities:"PreparedPackingQuantityUniqueWhere"
	},
	PreparedPackingQuantityUniqueWhere:{
		id:"UUID",
		date:"Date",
		recipe:"ProductRecipeUniqueWhere",
		packing:"ProductPackingUniqueWhere"
	},
	ProductRecipeUniqueWhere:{
		id:"UUID",
		products:"ProductUniqueWhere",
		ingredients:"ProductIngredientItemUniqueWhere",
		locales:"ProductRecipeLocaleUniqueWhere"
	},
	ProductUniqueWhere:{
		id:"UUID",
		stocks:"ProductStockUniqueWhere",
		orderItems:"OrderItemUniqueWhere",
		locales:"ProductLocaleUniqueWhere",
		virtualProduct:"VirtualProductUniqueWhere",
		meta:"ProductMetadataUniqueWhere",
		page:"PageUniqueWhere"
	},
	ProductStockUniqueWhere:{
		id:"UUID",
		product:"ProductUniqueWhere",
		store:"StoreUniqueWhere"
	},
	StoreUniqueWhere:{
		id:"UUID"
	},
	OrderItemUniqueWhere:{
		id:"UUID"
	},
	ProductLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductUniqueWhere",
		locale:"LocaleUniqueWhere",
		featureList:"TextListUniqueWhere"
	},
	VirtualProductUniqueWhere:{
		id:"UUID",
		product:"ProductUniqueWhere"
	},
	ProductMetadataUniqueWhere:{
		id:"UUID",
		product:"ProductUniqueWhere"
	},
	ProductIngredientItemUniqueWhere:{
		id:"UUID",
		quantity:"ProductIngredientItemQuantityUniqueWhere"
	},
	ProductIngredientItemQuantityUniqueWhere:{
		id:"UUID"
	},
	ProductRecipeLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductRecipeUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	DeliveryMethodLocaleUniqueWhere:{
		id:"UUID",
		locale:"LocaleUniqueWhere",
		root:"DeliveryMethodUniqueWhere"
	},
	DeliveryMethodUniqueWhere:{
		id:"UUID",
		locales:"DeliveryMethodLocaleUniqueWhere",
		timelinePreset:"DeliveryTimelinePresetUniqueWhere"
	},
	DeliveryTimelinePresetUniqueWhere:{
		id:"UUID",
		deliveryMethod:"DeliveryMethodUniqueWhere"
	},
	IngredientSupplierLocaleUniqueWhere:{
		id:"UUID",
		root:"IngredientSupplierUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	IngredientSupplierUniqueWhere:{
		id:"UUID",
		locales:"IngredientSupplierLocaleUniqueWhere"
	},
	IdentificationCodeLocaleUniqueWhere:{
		id:"UUID",
		root:"IdentificationCodeUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	IdentificationCodeUniqueWhere:{
		id:"UUID",
		attempts:"IdentificationAttemptUniqueWhere",
		locales:"IdentificationCodeLocaleUniqueWhere"
	},
	IdentificationAttemptUniqueWhere:{
		id:"UUID"
	},
	SubscriptionBoxLocaleUniqueWhere:{
		id:"UUID",
		root:"SubscriptionBoxUniqueWhere",
		locale:"LocaleUniqueWhere",
		texts:"TextListUniqueWhere",
		secondaryTexts:"TextListUniqueWhere"
	},
	SubscriptionBoxUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"SubscriptionBoxLocaleUniqueWhere"
	},
	ProductList:{
		items:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		},
		businessCategory:{
			filter:"BusinessCategoryWhere"
		},
		paginateItems:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		}
	},
	ProductListItem:{
		list:{
			filter:"ProductListWhere"
		},
		product:{
			filter:"ProductWhere"
		}
	},
	ProductListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ProductListOrderBy",
		product:"ProductOrderBy",
		defaultQuantityForOrder:"OrderDirection"
	},
	ProductOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		priceCents:"OrderDirection",
		notDiscountable:"OrderDirection",
		packing:"ProductPackingOrderBy",
		recipe:"ProductRecipeOrderBy",
		vatRate:"VatRateOrderBy",
		image:"ImageOrderBy",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		availability:"OrderDirection",
		order:"OrderDirection",
		maximumQuantityPerOrder:"OrderDirection",
		archivedAt:"OrderDirection",
		virtualProduct:"VirtualProductOrderBy",
		meta:"ProductMetadataOrderBy",
		page:"PageOrderBy",
		hasSubscription:"OrderDirection",
		pointOfSaleTileColor:"OrderDirection"
	},
	ProductPackingOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		image:"ImageOrderBy",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		volumeMl:"OrderDirection",
		icon:"ImageOrderBy",
		shortName:"OrderDirection",
		isPackedInCardboard:"OrderDirection"
	},
	ProductRecipeOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		internalNote:"OrderDirection",
		color:"OrderDirection",
		image:"ImageOrderBy",
		icon:"ImageOrderBy",
		needsPrepping:"OrderDirection",
		gutTuning:"OrderDirection",
		freshingContainer:"FreshingContainerOrderBy",
		description:"OrderDirection"
	},
	FreshingContainerOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		volumeMl:"OrderDirection",
		workspace:"WorkspaceOrderBy"
	},
	WorkspaceOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection",
		orderDeadlineTime:"OrderDirection",
		ordersLoadingDurationHours:"OrderDirection",
		freshingContainerVolumeMl:"OrderDirection",
		freshingPrepareOrderByOffsetMinutes:"OrderDirection",
		checkoutUrlTemplate:"OrderDirection",
		orderUrlTemplate:"OrderDirection",
		paymentUrlTemplate:"OrderDirection",
		customerProfileUrlTemplate:"OrderDirection",
		userProfileUrlTemplate:"OrderDirection",
		messengerUrl:"OrderDirection",
		messengerIosUrl:"OrderDirection",
		messengerAndroidUrl:"OrderDirection",
		openingHours:"WeekHoursOrderBy",
		workingHours:"WeekHoursOrderBy",
		defaultFreshingContainer:"FreshingContainerOrderBy",
		creditsProduct:"VirtualProductOrderBy",
		discountVatRate:"VatRateOrderBy",
		name:"OrderDirection",
		businessAddress:"OrderDirection"
	},
	WeekHoursOrderBy:{
		id:"OrderDirection",
		mon:"OrderDirection",
		tue:"OrderDirection",
		wed:"OrderDirection",
		thu:"OrderDirection",
		fri:"OrderDirection",
		sat:"OrderDirection",
		sun:"OrderDirection"
	},
	VirtualProductOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		product:"ProductOrderBy",
		voucherCreditCents:"OrderDirection",
		physicalRepresentation:"OrderDirection",
		recipientEmail:"OrderDirection"
	},
	VatRateOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		ratePermille:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		preset:"OrderDirection"
	},
	ProductMetadataOrderBy:{
		id:"OrderDirection",
		archived:"OrderDirection",
		available:"OrderDirection",
		availableQuantity:"OrderDirection",
		product:"ProductOrderBy"
	},
	PageOrderBy:{
		id:"OrderDirection"
	},
	ProductCategoryList:{
		items:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		locales:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		seasonalProductCategoriesOfBusinessCategory:{
			filter:"BusinessCategoryWhere"
		},
		specificProductsProductCategoriesOfBusinessCategory:{
			filter:"BusinessCategoryWhere"
		},
		localesByLocale:{
			by:"ProductCategoryListLocalesByLocaleUniqueWhere",
			filter:"ProductCategoryListLocaleWhere"
		},
		paginateItems:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		paginateLocales:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		}
	},
	ProductCategoryListItem:{
		list:{
			filter:"ProductCategoryListWhere"
		},
		productCategory:{
			filter:"ProductCategoryWhere"
		}
	},
	ProductCategory:{
		products:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		tags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		parent:{
			filter:"ProductCategoryWhere"
		},
		children:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		image:{
			filter:"ImageWhere"
		},
		childrenByCode:{
			by:"ProductCategoryChildrenByCodeUniqueWhere",
			filter:"ProductCategoryWhere"
		},
		childrenByChildren:{
			by:"ProductCategoryChildrenByChildrenUniqueWhere",
			filter:"ProductCategoryWhere"
		},
		paginateProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateChildren:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		}
	},
	TagOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection"
	},
	ProductCategoryOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		parent:"ProductCategoryOrderBy",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		color:"OrderDirection",
		image:"ImageOrderBy",
		order:"OrderDirection"
	},
	ProductCategoryChildrenByCodeUniqueWhere:{

	},
	ProductCategoryChildrenByChildrenUniqueWhere:{
		children:"ProductCategoryUniqueWhere"
	},
	ProductCategoryUniqueWhere:{
		id:"UUID",
		children:"ProductCategoryUniqueWhere"
	},
	ProductCategoryListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ProductCategoryListOrderBy",
		productCategory:"ProductCategoryOrderBy",
		showRecipeImages:"OrderDirection",
		showPackingImages:"OrderDirection",
		showAllAvailableProducts:"OrderDirection"
	},
	ProductCategoryListLocale:{
		root:{
			filter:"ProductCategoryListWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	ProductCategoryListLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"ProductCategoryListOrderBy",
		locale:"LocaleOrderBy"
	},
	ProductCategoryListLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	BusinessCategoryLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	ProductIngredientLocale:{
		root:{
			filter:"ProductIngredientWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	ProductIngredient:{
		locales:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		categories:{
			filter:"ProductIngredientCategoryWhere",
			orderBy:"ProductIngredientCategoryOrderBy"
		},
		allergens:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		preferredQuantityUnit:{
			filter:"ProductIngredientItemQuantityUnitWhere"
		},
		freshingContainer:{
			filter:"FreshingContainerWhere"
		},
		image:{
			filter:"ImageWhere"
		},
		suppliers:{
			filter:"IngredientSupplierListWhere"
		},
		localesByLocale:{
			by:"ProductIngredientLocalesByLocaleUniqueWhere",
			filter:"ProductIngredientLocaleWhere"
		},
		paginateLocales:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		paginateCategories:{
			filter:"ProductIngredientCategoryWhere",
			orderBy:"ProductIngredientCategoryOrderBy"
		},
		paginateAllergens:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		}
	},
	ProductIngredientLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"ProductIngredientOrderBy",
		locale:"LocaleOrderBy"
	},
	ProductIngredientOrderBy:{
		id:"OrderDirection",
		internalNote:"OrderDirection",
		internalName:"OrderDirection",
		needsPrepping:"OrderDirection",
		allowCustomerRating:"OrderDirection",
		preferredQuantityUnit:"ProductIngredientItemQuantityUnitOrderBy",
		freshingContainer:"FreshingContainerOrderBy",
		image:"ImageOrderBy",
		suppliers:"IngredientSupplierListOrderBy",
		incrementQuantityNumber:"OrderDirection"
	},
	ProductIngredientItemQuantityUnitOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection"
	},
	IngredientSupplierListOrderBy:{
		id:"OrderDirection"
	},
	ProductIngredientCategory:{
		ingredients:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		locales:{
			filter:"ProductIngredientCategoryLocaleWhere",
			orderBy:"ProductIngredientCategoryLocaleOrderBy"
		},
		localesByLocale:{
			by:"ProductIngredientCategoryLocalesByLocaleUniqueWhere",
			filter:"ProductIngredientCategoryLocaleWhere"
		},
		paginateIngredients:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		paginateLocales:{
			filter:"ProductIngredientCategoryLocaleWhere",
			orderBy:"ProductIngredientCategoryLocaleOrderBy"
		}
	},
	ProductIngredientCategoryLocale:{
		root:{
			filter:"ProductIngredientCategoryWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	ProductIngredientCategoryLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"ProductIngredientCategoryOrderBy",
		locale:"LocaleOrderBy"
	},
	ProductIngredientCategoryOrderBy:{
		id:"OrderDirection",
		internalName:"OrderDirection",
		internalNote:"OrderDirection",
		order:"OrderDirection"
	},
	ProductIngredientCategoryLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	Allergen:{
		locales:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		ingredients:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		localesByLocale:{
			by:"AllergenLocalesByLocaleUniqueWhere",
			filter:"AllergenLocaleWhere"
		},
		paginateLocales:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		paginateIngredients:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		}
	},
	AllergenLocale:{
		root:{
			filter:"AllergenWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	AllergenLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"AllergenOrderBy",
		locale:"LocaleOrderBy"
	},
	AllergenOrderBy:{
		id:"OrderDirection",
		internalName:"OrderDirection",
		code:"OrderDirection"
	},
	AllergenLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	FreshingContainer:{
		workspace:{
			filter:"WorkspaceWhere"
		}
	},
	Workspace:{
		freshingContainers:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		},
		openingHours:{
			filter:"WeekHoursWhere"
		},
		workingHours:{
			filter:"WeekHoursWhere"
		},
		defaultFreshingContainer:{
			filter:"FreshingContainerWhere"
		},
		creditsProduct:{
			filter:"VirtualProductWhere"
		},
		discountVatRate:{
			filter:"VatRateWhere"
		},
		paginateFreshingContainers:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		}
	},
	VirtualProduct:{
		product:{
			filter:"ProductWhere"
		}
	},
	IngredientSupplierList:{
		items:{
			filter:"IngredientSupplierItemWhere",
			orderBy:"IngredientSupplierItemOrderBy"
		},
		paginateItems:{
			filter:"IngredientSupplierItemWhere",
			orderBy:"IngredientSupplierItemOrderBy"
		}
	},
	IngredientSupplierItem:{
		list:{
			filter:"IngredientSupplierListWhere"
		},
		supplier:{
			filter:"IngredientSupplierWhere"
		}
	},
	IngredientSupplier:{
		locales:{
			filter:"IngredientSupplierLocaleWhere",
			orderBy:"IngredientSupplierLocaleOrderBy"
		},
		localesByLocale:{
			by:"IngredientSupplierLocalesByLocaleUniqueWhere",
			filter:"IngredientSupplierLocaleWhere"
		},
		paginateLocales:{
			filter:"IngredientSupplierLocaleWhere",
			orderBy:"IngredientSupplierLocaleOrderBy"
		}
	},
	IngredientSupplierLocale:{
		root:{
			filter:"IngredientSupplierWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	IngredientSupplierLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"IngredientSupplierOrderBy",
		locale:"LocaleOrderBy"
	},
	IngredientSupplierOrderBy:{
		id:"OrderDirection",
		internalName:"OrderDirection",
		internalNote:"OrderDirection"
	},
	IngredientSupplierLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	IngredientSupplierItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"IngredientSupplierListOrderBy",
		supplier:"IngredientSupplierOrderBy"
	},
	ProductIngredientLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	BlogLocale:{
		root:{
			filter:"BlogWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	Blog:{
		locales:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		localesByLocale:{
			by:"BlogLocalesByLocaleUniqueWhere",
			filter:"BlogLocaleWhere"
		},
		paginateLocales:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		}
	},
	BlogLocaleOrderBy:{
		id:"OrderDirection",
		pageName:"OrderDirection",
		title:"OrderDirection",
		lead:"OrderDirection",
		root:"BlogOrderBy",
		locale:"LocaleOrderBy"
	},
	BlogOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection"
	},
	BlogLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	BlogPostLocale:{
		root:{
			filter:"BlogPostWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		cover:{
			filter:"CoverWhere"
		},
		content:{
			filter:"ContentWhere"
		},
		link:{
			filter:"LinkableWhere"
		},
		seo:{
			filter:"SeoWhere"
		},
		products:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		}
	},
	BlogPost:{
		locales:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		localesByLocale:{
			by:"BlogPostLocalesByLocaleUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		localesByCover:{
			by:"BlogPostLocalesByCoverUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		localesByContent:{
			by:"BlogPostLocalesByContentUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		localesByLink:{
			by:"BlogPostLocalesByLinkUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		localesBySeo:{
			by:"BlogPostLocalesBySeoUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		paginateLocales:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		}
	},
	BlogPostLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		availableForWeb:"OrderDirection",
		availableForMobile:"OrderDirection",
		root:"BlogPostOrderBy",
		locale:"LocaleOrderBy",
		cover:"CoverOrderBy",
		content:"ContentOrderBy",
		link:"LinkableOrderBy",
		seo:"SeoOrderBy"
	},
	BlogPostOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection"
	},
	CoverOrderBy:{
		id:"OrderDirection",
		primaryTitle:"OrderDirection",
		secondaryTitle:"OrderDirection",
		medium:"MediumOrderBy",
		buttonColors:"ColorPalleteOrderBy"
	},
	MediumOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		colorTheme:"OrderDirection",
		image:"ImageOrderBy",
		video:"VideoOrderBy"
	},
	VideoOrderBy:{
		id:"OrderDirection",
		src:"OrderDirection",
		width:"OrderDirection",
		height:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection",
		duration:"OrderDirection",
		poster:"ImageOrderBy"
	},
	ColorPalleteOrderBy:{
		id:"OrderDirection",
		background:"OrderDirection",
		text:"OrderDirection"
	},
	ContentOrderBy:{
		id:"OrderDirection"
	},
	LinkableOrderBy:{
		id:"OrderDirection",
		url:"OrderDirection",
		blogPost:"BlogPostLocaleOrderBy",
		page:"PageLocaleOrderBy",
		redirect:"RedirectOrderBy"
	},
	PageLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		link:"LinkableOrderBy",
		root:"PageOrderBy",
		locale:"LocaleOrderBy",
		seo:"SeoOrderBy",
		content:"ContentOrderBy",
		cover:"CoverOrderBy",
		theme:"OrderDirection"
	},
	SeoOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		description:"OrderDirection",
		ogTitle:"OrderDirection",
		ogDescription:"OrderDirection",
		ogImage:"ImageOrderBy"
	},
	RedirectOrderBy:{
		id:"OrderDirection",
		link:"LinkableOrderBy",
		target:"LinkOrderBy"
	},
	LinkOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		title:"OrderDirection",
		externalLink:"OrderDirection",
		internalLink:"LinkableOrderBy"
	},
	BlogPostLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	BlogPostLocalesByCoverUniqueWhere:{
		cover:"CoverUniqueWhere"
	},
	BlogPostLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	BlogPostLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	BlogPostLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	Cover:{
		medium:{
			filter:"MediumWhere"
		},
		buttonColors:{
			filter:"ColorPalleteWhere"
		}
	},
	Medium:{
		image:{
			filter:"ImageWhere"
		},
		video:{
			filter:"VideoWhere"
		}
	},
	Video:{
		poster:{
			filter:"ImageWhere"
		}
	},
	Content:{
		blocks:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		blocksByReferences:{
			by:"ContentBlocksByReferencesUniqueWhere",
			filter:"ContentBlockWhere"
		},
		paginateBlocks:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		}
	},
	ContentBlock:{
		content:{
			filter:"ContentWhere"
		},
		references:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		referencesByProducts:{
			by:"ContentBlockReferencesByProductsUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByProductBanners:{
			by:"ContentBlockReferencesByProductBannersUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByDeliveryRegions:{
			by:"ContentBlockReferencesByDeliveryRegionsUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		paginateReferences:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		}
	},
	ContentReference:{
		block:{
			filter:"ContentBlockWhere"
		},
		colorPallete:{
			filter:"ColorPalleteWhere"
		},
		medium:{
			filter:"MediumWhere"
		},
		link:{
			filter:"LinkWhere"
		},
		blogPosts:{
			filter:"BlogPostListWhere"
		},
		links:{
			filter:"LinkListWhere"
		},
		products:{
			filter:"ProductListWhere"
		},
		hero:{
			filter:"HeroWhere"
		},
		gallery:{
			filter:"GalleryWhere"
		},
		socialsAndApps:{
			filter:"SocialsAndAppsWhere"
		},
		productGrid:{
			filter:"ProductGridWhere"
		},
		productBanners:{
			filter:"ProductBannerListWhere"
		},
		deliveryRegions:{
			filter:"DeliveryRegionsWhere"
		},
		textList:{
			filter:"TextListWhere"
		},
		gridTiles:{
			filter:"GridTilesWhere"
		}
	},
	Link:{
		internalLink:{
			filter:"LinkableWhere"
		}
	},
	Linkable:{
		blogPost:{
			filter:"BlogPostLocaleWhere"
		},
		page:{
			filter:"PageLocaleWhere"
		},
		redirect:{
			filter:"RedirectWhere"
		}
	},
	PageLocale:{
		link:{
			filter:"LinkableWhere"
		},
		root:{
			filter:"PageWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		seo:{
			filter:"SeoWhere"
		},
		content:{
			filter:"ContentWhere"
		},
		cover:{
			filter:"CoverWhere"
		}
	},
	Page:{
		locales:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		localesByLocale:{
			by:"PageLocalesByLocaleUniqueWhere",
			filter:"PageLocaleWhere"
		},
		localesByLink:{
			by:"PageLocalesByLinkUniqueWhere",
			filter:"PageLocaleWhere"
		},
		localesBySeo:{
			by:"PageLocalesBySeoUniqueWhere",
			filter:"PageLocaleWhere"
		},
		localesByContent:{
			by:"PageLocalesByContentUniqueWhere",
			filter:"PageLocaleWhere"
		},
		localesByCover:{
			by:"PageLocalesByCoverUniqueWhere",
			filter:"PageLocaleWhere"
		},
		paginateLocales:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		}
	},
	PageLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	PageLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	PageLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	PageLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	PageLocalesByCoverUniqueWhere:{
		cover:"CoverUniqueWhere"
	},
	Seo:{
		ogImage:{
			filter:"ImageWhere"
		}
	},
	Redirect:{
		link:{
			filter:"LinkableWhere"
		},
		target:{
			filter:"LinkWhere"
		}
	},
	BlogPostList:{
		items:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		},
		paginateItems:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		}
	},
	BlogPostListItem:{
		list:{
			filter:"BlogPostListWhere"
		},
		blogPost:{
			filter:"BlogPostLocaleWhere"
		}
	},
	BlogPostListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		caption:"OrderDirection",
		list:"BlogPostListOrderBy",
		blogPost:"BlogPostLocaleOrderBy"
	},
	BlogPostListOrderBy:{
		id:"OrderDirection"
	},
	LinkList:{
		items:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		paginateItems:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		}
	},
	LinkListItem:{
		list:{
			filter:"LinkListWhere"
		},
		link:{
			filter:"LinkWhere"
		}
	},
	LinkListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		isPrimary:"OrderDirection",
		list:"LinkListOrderBy",
		link:"LinkOrderBy"
	},
	LinkListOrderBy:{
		id:"OrderDirection"
	},
	Hero:{
		medium:{
			filter:"MediumWhere"
		}
	},
	Gallery:{
		items:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		paginateItems:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		}
	},
	GalleryItem:{
		list:{
			filter:"GalleryWhere"
		},
		medium:{
			filter:"MediumWhere"
		}
	},
	GalleryItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		caption:"OrderDirection",
		list:"GalleryOrderBy",
		medium:"MediumOrderBy"
	},
	GalleryOrderBy:{
		id:"OrderDirection"
	},
	ProductGrid:{
		categories:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		},
		categoriesByItems:{
			by:"ProductGridCategoriesByItemsUniqueWhere",
			filter:"ProductGridCategoryWhere"
		},
		categoriesByImages:{
			by:"ProductGridCategoriesByImagesUniqueWhere",
			filter:"ProductGridCategoryWhere"
		},
		paginateCategories:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		}
	},
	ProductGridCategory:{
		root:{
			filter:"ProductGridWhere"
		},
		items:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		},
		images:{
			filter:"ImageListWhere"
		},
		paginateItems:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		}
	},
	ProductGridItem:{
		product:{
			filter:"ProductWhere"
		},
		medium:{
			filter:"MediumWhere"
		},
		link:{
			filter:"LinkWhere"
		},
		category:{
			filter:"ProductGridCategoryWhere"
		}
	},
	ProductGridItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		lead:"OrderDirection",
		product:"ProductOrderBy",
		medium:"MediumOrderBy",
		link:"LinkOrderBy",
		category:"ProductGridCategoryOrderBy",
		description:"OrderDirection"
	},
	ProductGridCategoryOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		root:"ProductGridOrderBy",
		images:"ImageListOrderBy"
	},
	ProductGridOrderBy:{
		id:"OrderDirection",
		isCarousel:"OrderDirection",
		type:"OrderDirection"
	},
	ImageListOrderBy:{
		id:"OrderDirection"
	},
	ImageList:{
		items:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		itemsByImage:{
			by:"ImageListItemsByImageUniqueWhere",
			filter:"ImageListItemWhere"
		},
		paginateItems:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		}
	},
	ImageListItem:{
		list:{
			filter:"ImageListWhere"
		},
		image:{
			filter:"ImageWhere"
		}
	},
	ImageListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ImageListOrderBy",
		image:"ImageOrderBy"
	},
	ImageListItemsByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	ProductGridCategoriesByItemsUniqueWhere:{
		items:"ProductGridItemUniqueWhere"
	},
	ProductGridItemUniqueWhere:{
		id:"UUID"
	},
	ProductGridCategoriesByImagesUniqueWhere:{
		images:"ImageListUniqueWhere"
	},
	ProductBannerList:{
		items:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		},
		paginateItems:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		}
	},
	ProductBannerItem:{
		list:{
			filter:"ProductBannerListWhere"
		},
		banner:{
			filter:"ProductBannerWhere"
		}
	},
	ProductBanner:{
		button:{
			filter:"LinkWhere"
		},
		backgroundImage:{
			filter:"ImageWhere"
		},
		productImage:{
			filter:"ImageWhere"
		},
		product:{
			filter:"ProductWhere"
		}
	},
	ProductBannerItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ProductBannerListOrderBy",
		banner:"ProductBannerOrderBy",
		size:"OrderDirection"
	},
	ProductBannerListOrderBy:{
		id:"OrderDirection"
	},
	ProductBannerOrderBy:{
		id:"OrderDirection",
		beforeTitle:"OrderDirection",
		title:"OrderDirection",
		label:"OrderDirection",
		button:"LinkOrderBy",
		backgroundImage:"ImageOrderBy",
		productImage:"ImageOrderBy",
		product:"ProductOrderBy"
	},
	DeliveryRegions:{
		items:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		},
		itemsByImage:{
			by:"DeliveryRegionsItemsByImageUniqueWhere",
			filter:"DeliveryRegionsItemWhere"
		},
		itemsByTextList:{
			by:"DeliveryRegionsItemsByTextListUniqueWhere",
			filter:"DeliveryRegionsItemWhere"
		},
		itemsByGallery:{
			by:"DeliveryRegionsItemsByGalleryUniqueWhere",
			filter:"DeliveryRegionsItemWhere"
		},
		paginateItems:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		}
	},
	DeliveryRegionsItem:{
		list:{
			filter:"DeliveryRegionsWhere"
		},
		image:{
			filter:"ImageWhere"
		},
		textList:{
			filter:"TextListWhere"
		},
		gallery:{
			filter:"ImageListWhere"
		}
	},
	TextList:{
		items:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		paginateItems:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		}
	},
	TextItem:{
		list:{
			filter:"TextListWhere"
		}
	},
	TextItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		text:"OrderDirection",
		list:"TextListOrderBy",
		title:"OrderDirection"
	},
	TextListOrderBy:{
		id:"OrderDirection"
	},
	DeliveryRegionsItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		offerLabel:"OrderDirection",
		list:"DeliveryRegionsOrderBy",
		image:"ImageOrderBy",
		textList:"TextListOrderBy",
		gallery:"ImageListOrderBy"
	},
	DeliveryRegionsOrderBy:{
		id:"OrderDirection"
	},
	DeliveryRegionsItemsByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	DeliveryRegionsItemsByTextListUniqueWhere:{
		textList:"TextListUniqueWhere"
	},
	DeliveryRegionsItemsByGalleryUniqueWhere:{
		gallery:"ImageListUniqueWhere"
	},
	GridTiles:{
		items:{
			filter:"GridTileItemWhere",
			orderBy:"GridTileItemOrderBy"
		},
		itemsByLink:{
			by:"GridTilesItemsByLinkUniqueWhere",
			filter:"GridTileItemWhere"
		},
		paginateItems:{
			filter:"GridTileItemWhere",
			orderBy:"GridTileItemOrderBy"
		}
	},
	GridTileItem:{
		list:{
			filter:"GridTilesWhere"
		},
		medium:{
			filter:"MediumWhere"
		},
		link:{
			filter:"LinkWhere"
		}
	},
	GridTileItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		text:"OrderDirection",
		list:"GridTilesOrderBy",
		medium:"MediumOrderBy",
		link:"LinkOrderBy"
	},
	GridTilesOrderBy:{
		id:"OrderDirection"
	},
	GridTilesItemsByLinkUniqueWhere:{
		link:"LinkUniqueWhere"
	},
	LinkUniqueWhere:{
		id:"UUID"
	},
	ContentReferenceOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		primaryText:"OrderDirection",
		secondaryText:"OrderDirection",
		jsonContent:"OrderDirection",
		block:"ContentBlockOrderBy",
		colorPallete:"ColorPalleteOrderBy",
		medium:"MediumOrderBy",
		link:"LinkOrderBy",
		blogPosts:"BlogPostListOrderBy",
		links:"LinkListOrderBy",
		products:"ProductListOrderBy",
		contentSize:"OrderDirection",
		misc:"OrderDirection",
		hero:"HeroOrderBy",
		gallery:"GalleryOrderBy",
		socialsAndApps:"SocialsAndAppsOrderBy",
		productGrid:"ProductGridOrderBy",
		productBanners:"ProductBannerListOrderBy",
		deliveryRegions:"DeliveryRegionsOrderBy",
		textList:"TextListOrderBy",
		gridTiles:"GridTilesOrderBy"
	},
	ContentBlockOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		json:"OrderDirection",
		content:"ContentOrderBy"
	},
	HeroOrderBy:{
		id:"OrderDirection",
		showLogo:"OrderDirection",
		middleContent:"OrderDirection",
		medium:"MediumOrderBy"
	},
	SocialsAndAppsOrderBy:{
		id:"OrderDirection",
		instagram:"OrderDirection",
		appStore:"OrderDirection",
		googlePlay:"OrderDirection"
	},
	ContentBlockReferencesByProductsUniqueWhere:{
		products:"ProductListUniqueWhere"
	},
	ContentBlockReferencesByProductBannersUniqueWhere:{
		productBanners:"ProductBannerListUniqueWhere"
	},
	ContentBlockReferencesByDeliveryRegionsUniqueWhere:{
		deliveryRegions:"DeliveryRegionsUniqueWhere"
	},
	ContentBlocksByReferencesUniqueWhere:{
		references:"ContentReferenceUniqueWhere"
	},
	TranslationCataloguesIdentifier:{
		catalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		catalogueByDomain:{
			by:"TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		catalogueByValues:{
			by:"TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		paginateCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		}
	},
	TranslationCatalogue:{
		domain:{
			filter:"TranslationDomainWhere"
		},
		fallback:{
			filter:"TranslationCatalogueWhere"
		},
		values:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		identifier:{
			filter:"TranslationCataloguesIdentifierWhere"
		},
		valuesByKey:{
			by:"TranslationCatalogueValuesByKeyUniqueWhere",
			filter:"TranslationValueWhere"
		},
		paginateValues:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		}
	},
	TranslationDomain:{
		catalogues:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		keys:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		cataloguesByIdentifier:{
			by:"TranslationDomainCataloguesByIdentifierUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		cataloguesByValues:{
			by:"TranslationDomainCataloguesByValuesUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		keysByIdentifier:{
			by:"TranslationDomainKeysByIdentifierUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		keysByValues:{
			by:"TranslationDomainKeysByValuesUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		paginateCatalogues:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		paginateKeys:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		}
	},
	TranslationCatalogueOrderBy:{
		id:"OrderDirection",
		domain:"TranslationDomainOrderBy",
		fallback:"TranslationCatalogueOrderBy",
		identifier:"TranslationCataloguesIdentifierOrderBy"
	},
	TranslationDomainOrderBy:{
		id:"OrderDirection",
		identifier:"OrderDirection",
		name:"OrderDirection"
	},
	TranslationKey:{
		domain:{
			filter:"TranslationDomainWhere"
		},
		values:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		valuesByCatalogue:{
			by:"TranslationKeyValuesByCatalogueUniqueWhere",
			filter:"TranslationValueWhere"
		},
		paginateValues:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		}
	},
	TranslationValue:{
		catalogue:{
			filter:"TranslationCatalogueWhere"
		},
		key:{
			filter:"TranslationKeyWhere"
		}
	},
	TranslationValueOrderBy:{
		id:"OrderDirection",
		value:"OrderDirection",
		catalogue:"TranslationCatalogueOrderBy",
		key:"TranslationKeyOrderBy"
	},
	TranslationKeyOrderBy:{
		id:"OrderDirection",
		identifier:"OrderDirection",
		contentType:"OrderDirection",
		note:"OrderDirection",
		domain:"TranslationDomainOrderBy"
	},
	TranslationKeyValuesByCatalogueUniqueWhere:{
		catalogue:"TranslationCatalogueUniqueWhere"
	},
	TranslationDomainCataloguesByIdentifierUniqueWhere:{
		identifier:"TranslationCataloguesIdentifierUniqueWhere"
	},
	TranslationDomainCataloguesByValuesUniqueWhere:{
		values:"TranslationValueUniqueWhere"
	},
	TranslationDomainKeysByIdentifierUniqueWhere:{

	},
	TranslationDomainKeysByValuesUniqueWhere:{
		values:"TranslationValueUniqueWhere"
	},
	TranslationCatalogueValuesByKeyUniqueWhere:{
		key:"TranslationKeyUniqueWhere"
	},
	TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere:{
		domain:"TranslationDomainUniqueWhere"
	},
	TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere:{
		values:"TranslationValueUniqueWhere"
	},
	ProductPackingLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		shortName:"OrderDirection",
		description:"OrderDirection",
		root:"ProductPackingOrderBy",
		locale:"LocaleOrderBy"
	},
	ProductLocale:{
		root:{
			filter:"ProductWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		featureList:{
			filter:"TextListWhere"
		}
	},
	ProductLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		description:"OrderDirection",
		root:"ProductOrderBy",
		locale:"LocaleOrderBy",
		featureList:"TextListOrderBy",
		printableName:"OrderDirection",
		firstSticker:"OrderDirection",
		secondSticker:"OrderDirection"
	},
	ProductRecipeLocale:{
		root:{
			filter:"ProductRecipeWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	ProductRecipe:{
		products:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		image:{
			filter:"ImageWhere"
		},
		icon:{
			filter:"ImageWhere"
		},
		ingredients:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		locales:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		freshingContainer:{
			filter:"FreshingContainerWhere"
		},
		productsByCode:{
			by:"ProductRecipeProductsByCodeUniqueWhere",
			filter:"ProductWhere"
		},
		productsByStocks:{
			by:"ProductRecipeProductsByStocksUniqueWhere",
			filter:"ProductWhere"
		},
		productsByOrderItems:{
			by:"ProductRecipeProductsByOrderItemsUniqueWhere",
			filter:"ProductWhere"
		},
		productsByLocales:{
			by:"ProductRecipeProductsByLocalesUniqueWhere",
			filter:"ProductWhere"
		},
		productsByVirtualProduct:{
			by:"ProductRecipeProductsByVirtualProductUniqueWhere",
			filter:"ProductWhere"
		},
		productsByMeta:{
			by:"ProductRecipeProductsByMetaUniqueWhere",
			filter:"ProductWhere"
		},
		productsByPage:{
			by:"ProductRecipeProductsByPageUniqueWhere",
			filter:"ProductWhere"
		},
		ingredientsByQuantity:{
			by:"ProductRecipeIngredientsByQuantityUniqueWhere",
			filter:"ProductIngredientItemWhere"
		},
		localesByLocale:{
			by:"ProductRecipeLocalesByLocaleUniqueWhere",
			filter:"ProductRecipeLocaleWhere"
		},
		paginateProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateIngredients:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		paginateLocales:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		}
	},
	ProductIngredientItem:{
		recipe:{
			filter:"ProductRecipeWhere"
		},
		ingredient:{
			filter:"ProductIngredientWhere"
		},
		quantity:{
			filter:"ProductIngredientItemQuantityWhere"
		}
	},
	ProductIngredientItemQuantity:{
		unit:{
			filter:"ProductIngredientItemQuantityUnitWhere"
		}
	},
	ProductIngredientItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		internalOrder:"OrderDirection",
		recipe:"ProductRecipeOrderBy",
		ingredient:"ProductIngredientOrderBy",
		quantity:"ProductIngredientItemQuantityOrderBy"
	},
	ProductIngredientItemQuantityOrderBy:{
		id:"OrderDirection",
		unit:"ProductIngredientItemQuantityUnitOrderBy",
		value:"OrderDirection"
	},
	ProductRecipeLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"ProductRecipeOrderBy",
		locale:"LocaleOrderBy",
		description:"OrderDirection"
	},
	ProductRecipeProductsByCodeUniqueWhere:{

	},
	ProductRecipeProductsByStocksUniqueWhere:{
		stocks:"ProductStockUniqueWhere"
	},
	ProductRecipeProductsByOrderItemsUniqueWhere:{
		orderItems:"OrderItemUniqueWhere"
	},
	ProductRecipeProductsByLocalesUniqueWhere:{
		locales:"ProductLocaleUniqueWhere"
	},
	ProductRecipeProductsByVirtualProductUniqueWhere:{
		virtualProduct:"VirtualProductUniqueWhere"
	},
	ProductRecipeProductsByMetaUniqueWhere:{
		meta:"ProductMetadataUniqueWhere"
	},
	ProductRecipeProductsByPageUniqueWhere:{
		page:"PageUniqueWhere"
	},
	ProductRecipeIngredientsByQuantityUniqueWhere:{
		quantity:"ProductIngredientItemQuantityUniqueWhere"
	},
	ProductRecipeLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	DeliveryMethodLocale:{
		locale:{
			filter:"LocaleWhere"
		},
		root:{
			filter:"DeliveryMethodWhere"
		}
	},
	DeliveryMethod:{
		paymentMethods:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		suitableForDeliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		locales:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		timelinePreset:{
			filter:"DeliveryTimelinePresetWhere"
		},
		localesByLocale:{
			by:"DeliveryMethodLocalesByLocaleUniqueWhere",
			filter:"DeliveryMethodLocaleWhere"
		},
		paginatePaymentMethods:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		paginateSuitableForDeliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		paginateLocales:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		}
	},
	PaymentMethod:{
		deliveryMethods:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		paginateDeliveryMethods:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		}
	},
	DeliveryMethodOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		enabled:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		requireDeliveryAddress:"OrderDirection",
		enableDeliveryTracking:"OrderDirection",
		timelinePreset:"DeliveryTimelinePresetOrderBy"
	},
	DeliveryTimelinePresetOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		duration:"OrderDirection",
		window:"OrderDirection",
		deliveryMethod:"DeliveryMethodOrderBy"
	},
	PaymentMethodOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		enabled:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		blockingDispatch:"OrderDirection",
		type:"OrderDirection",
		reduceGratuityByPercentage:"OrderDirection"
	},
	DeliveryZone:{
		addresses:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		suitableDeliveryMethods:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		deliveryZonesOfTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		deliveryZonesOfProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		addressesByBillingAddressOfOrders:{
			by:"DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere",
			filter:"AddressWhere"
		},
		addressesByDeliveryAddressOfOrderDeliveries:{
			by:"DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere",
			filter:"AddressWhere"
		},
		addressesByDefaultDeliveryAddressOfCustomer:{
			by:"DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere",
			filter:"AddressWhere"
		},
		paginateAddresses:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		paginateSuitableDeliveryMethods:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		paginateDeliveryZonesOfTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateDeliveryZonesOfProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		}
	},
	DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere:{
		billingAddressOfOrders:"OrderUniqueWhere"
	},
	OrderUniqueWhere:{
		id:"UUID",
		delivery:"OrderDeliveryUniqueWhere",
		items:"OrderItemUniqueWhere",
		payments:"OrderPaymentUniqueWhere"
	},
	OrderDeliveryUniqueWhere:{
		id:"UUID",
		order:"OrderUniqueWhere",
		deliveryWaypoint:"DeliveryWaypointUniqueWhere"
	},
	DeliveryWaypointUniqueWhere:{
		id:"UUID",
		delivery:"OrderDeliveryUniqueWhere"
	},
	OrderPaymentUniqueWhere:{
		id:"UUID"
	},
	DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere:{
		deliveryAddressOfOrderDeliveries:"OrderDeliveryUniqueWhere"
	},
	DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere:{
		defaultDeliveryAddressOfCustomer:"CustomerUniqueWhere"
	},
	CustomerUniqueWhere:{
		id:"UUID",
		addresses:"AddressUniqueWhere",
		defaultBillingAddress:"AddressUniqueWhere",
		orders:"OrderUniqueWhere",
		name:"CustomerNameUniqueWhere",
		defaultDeliveryAddress:"AddressUniqueWhere",
		meta:"CustomerMetadataUniqueWhere"
	},
	AddressUniqueWhere:{
		id:"UUID",
		billingAddressOfOrders:"OrderUniqueWhere",
		deliveryAddressOfOrderDeliveries:"OrderDeliveryUniqueWhere",
		defaultDeliveryAddressOfCustomer:"CustomerUniqueWhere"
	},
	CustomerNameUniqueWhere:{
		id:"UUID",
		customer:"CustomerUniqueWhere"
	},
	CustomerMetadataUniqueWhere:{
		id:"UUID",
		customer:"CustomerUniqueWhere"
	},
	DeliveryMethodLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		description:"OrderDirection",
		trackingCodeUrlTemplate:"OrderDirection",
		processingTitle:"OrderDirection",
		processingText:"OrderDirection",
		processingIconUrl:"OrderDirection",
		dispatchedTitle:"OrderDirection",
		dispatchedText:"OrderDirection",
		dispatchedIconUrl:"OrderDirection",
		fulfilledTitle:"OrderDirection",
		fulfilledText:"OrderDirection",
		fulfilledIconUrl:"OrderDirection",
		locale:"LocaleOrderBy",
		root:"DeliveryMethodOrderBy"
	},
	DeliveryTimelinePreset:{
		deliveryMethod:{
			filter:"DeliveryMethodWhere"
		}
	},
	DeliveryMethodLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	IdentificationCodeLocale:{
		root:{
			filter:"IdentificationCodeWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	IdentificationCode:{
		attempts:{
			filter:"IdentificationAttemptWhere",
			orderBy:"IdentificationAttemptOrderBy"
		},
		locales:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		},
		localesByLocale:{
			by:"IdentificationCodeLocalesByLocaleUniqueWhere",
			filter:"IdentificationCodeLocaleWhere"
		},
		paginateAttempts:{
			filter:"IdentificationAttemptWhere",
			orderBy:"IdentificationAttemptOrderBy"
		},
		paginateLocales:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		}
	},
	IdentificationAttempt:{
		code:{
			filter:"IdentificationCodeWhere"
		}
	},
	IdentificationAttemptOrderBy:{
		id:"OrderDirection",
		createdAt:"OrderDirection",
		code:"IdentificationCodeOrderBy"
	},
	IdentificationCodeOrderBy:{
		id:"OrderDirection",
		code:"OrderDirection",
		createdAt:"OrderDirection",
		internalName:"OrderDirection"
	},
	IdentificationCodeLocaleOrderBy:{
		id:"OrderDirection",
		successMessage:"OrderDirection",
		root:"IdentificationCodeOrderBy",
		locale:"LocaleOrderBy"
	},
	IdentificationCodeLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	SubscriptionBoxLocale:{
		root:{
			filter:"SubscriptionBoxWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		texts:{
			filter:"TextListWhere"
		},
		secondaryTexts:{
			filter:"TextListWhere"
		}
	},
	SubscriptionBox:{
		locales:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		},
		localesByLocale:{
			by:"SubscriptionBoxLocalesByLocaleUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		localesByTexts:{
			by:"SubscriptionBoxLocalesByTextsUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		localesBySecondaryTexts:{
			by:"SubscriptionBoxLocalesBySecondaryTextsUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		paginateLocales:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		}
	},
	SubscriptionBoxLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		stickerText:"OrderDirection",
		descriptionText:"OrderDirection",
		root:"SubscriptionBoxOrderBy",
		locale:"LocaleOrderBy",
		secondaryTitle:"OrderDirection",
		texts:"TextListOrderBy",
		secondaryTexts:"TextListOrderBy"
	},
	SubscriptionBoxOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection"
	},
	SubscriptionBoxLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	SubscriptionBoxLocalesByTextsUniqueWhere:{
		texts:"TextListUniqueWhere"
	},
	SubscriptionBoxLocalesBySecondaryTextsUniqueWhere:{
		secondaryTexts:"TextListUniqueWhere"
	},
	LocaleBusinessCategoriesByRootUniqueWhere:{
		root:"BusinessCategoryUniqueWhere"
	},
	LocaleProductCategoryListsByRootUniqueWhere:{
		root:"ProductCategoryListUniqueWhere"
	},
	LocaleProductIngredientsByRootUniqueWhere:{
		root:"ProductIngredientUniqueWhere"
	},
	LocaleOrderRecurrencesByRootUniqueWhere:{
		root:"OrderRecurrenceUniqueWhere"
	},
	LocaleBlogsByRootUniqueWhere:{
		root:"BlogUniqueWhere"
	},
	LocaleBlogPostsByRootUniqueWhere:{
		root:"BlogPostUniqueWhere"
	},
	LocaleBlogPostsByCoverUniqueWhere:{
		cover:"CoverUniqueWhere"
	},
	LocaleBlogPostsByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocaleBlogPostsByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocaleBlogPostsBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocalePagesByRootUniqueWhere:{
		root:"PageUniqueWhere"
	},
	LocalePagesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocalePagesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocalePagesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocalePagesByCoverUniqueWhere:{
		cover:"CoverUniqueWhere"
	},
	LocaleProductIngredientCategoriesByRootUniqueWhere:{
		root:"ProductIngredientCategoryUniqueWhere"
	},
	LocaleAllergensByRootUniqueWhere:{
		root:"AllergenUniqueWhere"
	},
	LocaleProductPackingsByRootUniqueWhere:{
		root:"ProductPackingUniqueWhere"
	},
	LocaleProductsByRootUniqueWhere:{
		root:"ProductUniqueWhere"
	},
	LocaleProductsByFeatureListUniqueWhere:{
		featureList:"TextListUniqueWhere"
	},
	LocaleProductRecipesByRootUniqueWhere:{
		root:"ProductRecipeUniqueWhere"
	},
	LocaleDeliveryMethodsByRootUniqueWhere:{
		root:"DeliveryMethodUniqueWhere"
	},
	LocaleIngredientSuppliersByRootUniqueWhere:{
		root:"IngredientSupplierUniqueWhere"
	},
	LocaleIdentificationCodesByRootUniqueWhere:{
		root:"IdentificationCodeUniqueWhere"
	},
	LocaleSubscriptionBoxesByRootUniqueWhere:{
		root:"SubscriptionBoxUniqueWhere"
	},
	LocaleSubscriptionBoxesByTextsUniqueWhere:{
		texts:"TextListUniqueWhere"
	},
	LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere:{
		secondaryTexts:"TextListUniqueWhere"
	},
	PreparedPackingQuantity:{
		recipe:{
			filter:"ProductRecipeWhere"
		},
		packing:{
			filter:"ProductPackingWhere"
		}
	},
	PreparedPackingQuantityOrderBy:{
		id:"OrderDirection",
		date:"OrderDirection",
		quantity:"OrderDirection",
		recipe:"ProductRecipeOrderBy",
		packing:"ProductPackingOrderBy",
		packedInCardboard:"OrderDirection"
	},
	ProductPackingLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	ProductStock:{
		product:{
			filter:"ProductWhere"
		},
		store:{
			filter:"StoreWhere"
		}
	},
	ProductStockOrderBy:{
		id:"OrderDirection",
		quantity:"OrderDirection",
		product:"ProductOrderBy",
		store:"StoreOrderBy"
	},
	StoreOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection"
	},
	OrderItem:{
		vatRate:{
			filter:"VatRateWhere"
		},
		order:{
			filter:"OrderWhere"
		},
		fulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		product:{
			filter:"ProductWhere"
		},
		paginateFulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		}
	},
	Order:{
		customer:{
			filter:"CustomerWhere"
		},
		channel:{
			filter:"ChannelWhere"
		},
		delivery:{
			filter:"OrderDeliveryWhere"
		},
		items:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		billingAddress:{
			filter:"AddressWhere"
		},
		payments:{
			filter:"OrderPaymentWhere",
			orderBy:"OrderPaymentOrderBy"
		},
		venue:{
			filter:"VenueWhere"
		},
		fulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		paginateItems:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		paginatePayments:{
			filter:"OrderPaymentWhere",
			orderBy:"OrderPaymentOrderBy"
		},
		paginateFulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		}
	},
	Channel:{
		customers:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		addTagsOnRegistration:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		publicTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		venue:{
			filter:"VenueWhere"
		},
		paginateCustomers:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		paginateAddTagsOnRegistration:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginatePublicTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		}
	},
	Venue:{
		deadlineTemplate:{
			filter:"DeadlineTemplateWhere"
		},
		timetableDays:{
			filter:"TimetableDayWhere",
			orderBy:"TimetableDayOrderBy"
		},
		timetableTemplates:{
			filter:"TimetableTemplateWhere",
			orderBy:"TimetableTemplateOrderBy"
		},
		staffMembers:{
			filter:"StaffWhere",
			orderBy:"StaffOrderBy"
		},
		employeeRoles:{
			filter:"VenueEmployeeRoleWhere",
			orderBy:"VenueEmployeeRoleOrderBy"
		},
		orders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		fulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		enabledUnassignReasons:{
			filter:"UnassignReasonWhere",
			orderBy:"UnassignReasonOrderBy"
		},
		channels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		timetableDaysByDate:{
			by:"VenueTimetableDaysByDateUniqueWhere",
			filter:"TimetableDayWhere"
		},
		timetableDaysByShiftGroups:{
			by:"VenueTimetableDaysByShiftGroupsUniqueWhere",
			filter:"TimetableDayWhere"
		},
		timetableDaysByNotes:{
			by:"VenueTimetableDaysByNotesUniqueWhere",
			filter:"TimetableDayWhere"
		},
		timetableDaysByMeta:{
			by:"VenueTimetableDaysByMetaUniqueWhere",
			filter:"TimetableDayWhere"
		},
		employeeRolesByRole:{
			by:"VenueEmployeeRolesByRoleUniqueWhere",
			filter:"VenueEmployeeRoleWhere"
		},
		employeeRolesByEmployeePrivileges:{
			by:"VenueEmployeeRolesByEmployeePrivilegesUniqueWhere",
			filter:"VenueEmployeeRoleWhere"
		},
		ordersBySeq:{
			by:"VenueOrdersBySeqUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByDoubleshotLegacyId:{
			by:"VenueOrdersByDoubleshotLegacyIdUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByDoubleShotLegacyNumber:{
			by:"VenueOrdersByDoubleShotLegacyNumberUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByDelivery:{
			by:"VenueOrdersByDeliveryUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByItems:{
			by:"VenueOrdersByItemsUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByPayments:{
			by:"VenueOrdersByPaymentsUniqueWhere",
			filter:"OrderWhere"
		},
		channelsByCode:{
			by:"VenueChannelsByCodeUniqueWhere",
			filter:"ChannelWhere"
		},
		paginateTimetableDays:{
			filter:"TimetableDayWhere",
			orderBy:"TimetableDayOrderBy"
		},
		paginateTimetableTemplates:{
			filter:"TimetableTemplateWhere",
			orderBy:"TimetableTemplateOrderBy"
		},
		paginateStaffMembers:{
			filter:"StaffWhere",
			orderBy:"StaffOrderBy"
		},
		paginateEmployeeRoles:{
			filter:"VenueEmployeeRoleWhere",
			orderBy:"VenueEmployeeRoleOrderBy"
		},
		paginateOrders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		paginateFulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		paginateEnabledUnassignReasons:{
			filter:"UnassignReasonWhere",
			orderBy:"UnassignReasonOrderBy"
		},
		paginateChannels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		}
	},
	DeadlineTemplate:{
		venue:{
			filter:"VenueWhere"
		}
	},
	TimetableDay:{
		shiftGroups:{
			filter:"TimetableShiftGroupWhere",
			orderBy:"TimetableShiftGroupOrderBy"
		},
		notes:{
			filter:"TimetableDayNoteWhere",
			orderBy:"TimetableDayNoteOrderBy"
		},
		venue:{
			filter:"VenueWhere"
		},
		meta:{
			filter:"TimetableDayMetadataWhere"
		},
		shiftGroupsByShifts:{
			by:"TimetableDayShiftGroupsByShiftsUniqueWhere",
			filter:"TimetableShiftGroupWhere"
		},
		shiftGroupsByQueue:{
			by:"TimetableDayShiftGroupsByQueueUniqueWhere",
			filter:"TimetableShiftGroupWhere"
		},
		paginateShiftGroups:{
			filter:"TimetableShiftGroupWhere",
			orderBy:"TimetableShiftGroupOrderBy"
		},
		paginateNotes:{
			filter:"TimetableDayNoteWhere",
			orderBy:"TimetableDayNoteOrderBy"
		}
	},
	TimetableShiftGroup:{
		shifts:{
			filter:"TimetableShiftWhere",
			orderBy:"TimetableShiftOrderBy"
		},
		day:{
			filter:"TimetableDayWhere"
		},
		type:{
			filter:"TimetableEmployeeRoleWhere"
		},
		position:{
			filter:"TimetableShiftPositionWhere"
		},
		queue:{
			filter:"EmployeeQueueWhere"
		},
		generatedByTemplate:{
			filter:"TimetableTemplateWhere"
		},
		shiftsByQueueItem:{
			by:"TimetableShiftGroupShiftsByQueueItemUniqueWhere",
			filter:"TimetableShiftWhere"
		},
		paginateShifts:{
			filter:"TimetableShiftWhere",
			orderBy:"TimetableShiftOrderBy"
		}
	},
	TimetableShift:{
		group:{
			filter:"TimetableShiftGroupWhere"
		},
		shiftsProfile:{
			filter:"ShiftsProfileWhere"
		},
		queueItem:{
			filter:"EmployeeQueueItemWhere"
		}
	},
	ShiftsProfile:{
		queueItems:{
			filter:"EmployeeQueueItemWhere",
			orderBy:"EmployeeQueueItemOrderBy"
		},
		staff:{
			filter:"StaffWhere"
		},
		roles:{
			filter:"TimetableEmployeeRoleWhere",
			orderBy:"TimetableEmployeeRoleOrderBy"
		},
		shifts:{
			filter:"TimetableShiftWhere",
			orderBy:"TimetableShiftOrderBy"
		},
		queueItemsByQueue:{
			by:"ShiftsProfileQueueItemsByQueueUniqueWhere",
			filter:"EmployeeQueueItemWhere"
		},
		queueItemsByShift:{
			by:"ShiftsProfileQueueItemsByShiftUniqueWhere",
			filter:"EmployeeQueueItemWhere"
		},
		queueItemsByUnassignInfo:{
			by:"ShiftsProfileQueueItemsByUnassignInfoUniqueWhere",
			filter:"EmployeeQueueItemWhere"
		},
		shiftsByQueueItem:{
			by:"ShiftsProfileShiftsByQueueItemUniqueWhere",
			filter:"TimetableShiftWhere"
		},
		paginateQueueItems:{
			filter:"EmployeeQueueItemWhere",
			orderBy:"EmployeeQueueItemOrderBy"
		},
		paginateRoles:{
			filter:"TimetableEmployeeRoleWhere",
			orderBy:"TimetableEmployeeRoleOrderBy"
		},
		paginateShifts:{
			filter:"TimetableShiftWhere",
			orderBy:"TimetableShiftOrderBy"
		}
	},
	EmployeeQueueItem:{
		queue:{
			filter:"EmployeeQueueWhere"
		},
		shiftsProfile:{
			filter:"ShiftsProfileWhere"
		},
		shift:{
			filter:"TimetableShiftWhere"
		},
		unassignInfo:{
			filter:"UnassignedShiftInfoWhere"
		}
	},
	EmployeeQueue:{
		shiftGroup:{
			filter:"TimetableShiftGroupWhere"
		},
		items:{
			filter:"EmployeeQueueItemWhere",
			orderBy:"EmployeeQueueItemOrderBy"
		},
		itemsByShiftsProfile:{
			by:"EmployeeQueueItemsByShiftsProfileUniqueWhere",
			filter:"EmployeeQueueItemWhere"
		},
		itemsByShift:{
			by:"EmployeeQueueItemsByShiftUniqueWhere",
			filter:"EmployeeQueueItemWhere"
		},
		itemsByUnassignInfo:{
			by:"EmployeeQueueItemsByUnassignInfoUniqueWhere",
			filter:"EmployeeQueueItemWhere"
		},
		paginateItems:{
			filter:"EmployeeQueueItemWhere",
			orderBy:"EmployeeQueueItemOrderBy"
		}
	},
	EmployeeQueueItemOrderBy:{
		id:"OrderDirection",
		start:"OrderDirection",
		end:"OrderDirection",
		queue:"EmployeeQueueOrderBy",
		shiftsProfile:"ShiftsProfileOrderBy",
		shift:"TimetableShiftOrderBy",
		unassignInfo:"UnassignedShiftInfoOrderBy"
	},
	EmployeeQueueOrderBy:{
		id:"OrderDirection",
		shiftGroup:"TimetableShiftGroupOrderBy",
		contemberBugFix:"OrderDirection"
	},
	TimetableShiftGroupOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		day:"TimetableDayOrderBy",
		type:"TimetableEmployeeRoleOrderBy",
		position:"TimetableShiftPositionOrderBy",
		queue:"EmployeeQueueOrderBy",
		generatedByTemplate:"TimetableTemplateOrderBy"
	},
	TimetableDayOrderBy:{
		id:"OrderDirection",
		date:"OrderDirection",
		type:"OrderDirection",
		tipsCents:"OrderDirection",
		venue:"VenueOrderBy",
		meta:"TimetableDayMetadataOrderBy"
	},
	VenueOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		deadlineTemplate:"DeadlineTemplateOrderBy",
		address:"OrderDirection",
		pointOfSalePngBase64ReceiptLogo:"OrderDirection",
		pointOfSaleInitialView:"OrderDirection",
		pointOfSalePreferPrintReceipt:"OrderDirection"
	},
	DeadlineTemplateOrderBy:{
		id:"OrderDirection",
		startDate:"OrderDirection",
		period:"OrderDirection",
		cutoff:"OrderDirection",
		closed:"OrderDirection",
		venue:"VenueOrderBy"
	},
	TimetableDayMetadataOrderBy:{
		id:"OrderDirection",
		numberOfWorkers:"OrderDirection",
		tipsTotalCents:"OrderDirection",
		totalMinutes:"OrderDirection",
		tipsCentsPerMinute:"OrderDirection",
		day:"TimetableDayOrderBy"
	},
	TimetableEmployeeRoleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		color:"OrderDirection"
	},
	TimetableShiftPositionOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		start:"OrderDirection",
		end:"OrderDirection",
		isDynamic:"OrderDirection"
	},
	TimetableTemplateOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		startDate:"OrderDirection",
		period:"OrderDirection",
		slots:"OrderDirection",
		position:"TimetableShiftPositionOrderBy",
		internalName:"OrderDirection",
		venue:"VenueOrderBy",
		employeeRole:"TimetableEmployeeRoleOrderBy",
		workdaysOnly:"OrderDirection",
		monday:"OrderDirection",
		tuesday:"OrderDirection",
		wednesday:"OrderDirection",
		thursday:"OrderDirection",
		friday:"OrderDirection",
		saturday:"OrderDirection",
		sunday:"OrderDirection",
		type:"OrderDirection"
	},
	ShiftsProfileOrderBy:{
		id:"OrderDirection",
		staff:"StaffOrderBy",
		allowSelfUnassigning:"OrderDirection"
	},
	StaffOrderBy:{
		id:"OrderDirection",
		shiftsProfile:"ShiftsProfileOrderBy",
		photo:"ImageOrderBy",
		createdAt:"OrderDirection"
	},
	TimetableShiftOrderBy:{
		id:"OrderDirection",
		locked:"OrderDirection",
		order:"OrderDirection",
		assigned:"OrderDirection",
		group:"TimetableShiftGroupOrderBy",
		shiftsProfile:"ShiftsProfileOrderBy",
		queueItem:"EmployeeQueueItemOrderBy",
		isOvertime:"OrderDirection"
	},
	UnassignedShiftInfoOrderBy:{
		id:"OrderDirection",
		hoursMissed:"OrderDirection",
		queueItem:"EmployeeQueueItemOrderBy",
		legacyReason:"OrderDirection",
		reason:"UnassignReasonOrderBy"
	},
	UnassignReasonOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		name:"OrderDirection"
	},
	EmployeeQueueItemsByShiftsProfileUniqueWhere:{
		shiftsProfile:"ShiftsProfileUniqueWhere"
	},
	ShiftsProfileUniqueWhere:{
		id:"UUID",
		queueItems:"EmployeeQueueItemUniqueWhere",
		staff:"StaffUniqueWhere",
		shifts:"TimetableShiftUniqueWhere"
	},
	EmployeeQueueItemUniqueWhere:{
		id:"UUID",
		shiftsProfile:"ShiftsProfileUniqueWhere",
		queue:"EmployeeQueueUniqueWhere",
		shift:"TimetableShiftUniqueWhere",
		unassignInfo:"UnassignedShiftInfoUniqueWhere"
	},
	EmployeeQueueUniqueWhere:{
		id:"UUID",
		shiftGroup:"TimetableShiftGroupUniqueWhere",
		items:"EmployeeQueueItemUniqueWhere"
	},
	TimetableShiftGroupUniqueWhere:{
		id:"UUID",
		shifts:"TimetableShiftUniqueWhere",
		queue:"EmployeeQueueUniqueWhere"
	},
	TimetableShiftUniqueWhere:{
		id:"UUID",
		queueItem:"EmployeeQueueItemUniqueWhere"
	},
	UnassignedShiftInfoUniqueWhere:{
		id:"UUID",
		queueItem:"EmployeeQueueItemUniqueWhere"
	},
	StaffUniqueWhere:{
		id:"UUID",
		shiftsProfile:"ShiftsProfileUniqueWhere"
	},
	EmployeeQueueItemsByShiftUniqueWhere:{
		shift:"TimetableShiftUniqueWhere"
	},
	EmployeeQueueItemsByUnassignInfoUniqueWhere:{
		unassignInfo:"UnassignedShiftInfoUniqueWhere"
	},
	UnassignedShiftInfo:{
		queueItem:{
			filter:"EmployeeQueueItemWhere"
		},
		reason:{
			filter:"UnassignReasonWhere"
		}
	},
	UnassignReason:{
		venues:{
			filter:"VenueWhere",
			orderBy:"VenueOrderBy"
		},
		paginateVenues:{
			filter:"VenueWhere",
			orderBy:"VenueOrderBy"
		}
	},
	Staff:{
		shiftsProfile:{
			filter:"ShiftsProfileWhere"
		},
		photo:{
			filter:"ImageWhere"
		},
		venues:{
			filter:"VenueWhere",
			orderBy:"VenueOrderBy"
		},
		paginateVenues:{
			filter:"VenueWhere",
			orderBy:"VenueOrderBy"
		}
	},
	TimetableEmployeeRole:{
		shiftGroups:{
			filter:"TimetableShiftGroupWhere",
			orderBy:"TimetableShiftGroupOrderBy"
		},
		shiftsProfiles:{
			filter:"ShiftsProfileWhere",
			orderBy:"ShiftsProfileOrderBy"
		},
		timetableTemplates:{
			filter:"TimetableTemplateWhere",
			orderBy:"TimetableTemplateOrderBy"
		},
		shiftGroupsByShifts:{
			by:"TimetableEmployeeRoleShiftGroupsByShiftsUniqueWhere",
			filter:"TimetableShiftGroupWhere"
		},
		shiftGroupsByQueue:{
			by:"TimetableEmployeeRoleShiftGroupsByQueueUniqueWhere",
			filter:"TimetableShiftGroupWhere"
		},
		paginateShiftGroups:{
			filter:"TimetableShiftGroupWhere",
			orderBy:"TimetableShiftGroupOrderBy"
		},
		paginateShiftsProfiles:{
			filter:"ShiftsProfileWhere",
			orderBy:"ShiftsProfileOrderBy"
		},
		paginateTimetableTemplates:{
			filter:"TimetableTemplateWhere",
			orderBy:"TimetableTemplateOrderBy"
		}
	},
	TimetableTemplate:{
		position:{
			filter:"TimetableShiftPositionWhere"
		},
		venue:{
			filter:"VenueWhere"
		},
		employeeRole:{
			filter:"TimetableEmployeeRoleWhere"
		}
	},
	TimetableShiftPosition:{
		shiftGroups:{
			filter:"TimetableShiftGroupWhere",
			orderBy:"TimetableShiftGroupOrderBy"
		},
		timetableTemplates:{
			filter:"TimetableTemplateWhere",
			orderBy:"TimetableTemplateOrderBy"
		},
		shiftGroupsByShifts:{
			by:"TimetableShiftPositionShiftGroupsByShiftsUniqueWhere",
			filter:"TimetableShiftGroupWhere"
		},
		shiftGroupsByQueue:{
			by:"TimetableShiftPositionShiftGroupsByQueueUniqueWhere",
			filter:"TimetableShiftGroupWhere"
		},
		paginateShiftGroups:{
			filter:"TimetableShiftGroupWhere",
			orderBy:"TimetableShiftGroupOrderBy"
		},
		paginateTimetableTemplates:{
			filter:"TimetableTemplateWhere",
			orderBy:"TimetableTemplateOrderBy"
		}
	},
	TimetableShiftPositionShiftGroupsByShiftsUniqueWhere:{
		shifts:"TimetableShiftUniqueWhere"
	},
	TimetableShiftPositionShiftGroupsByQueueUniqueWhere:{
		queue:"EmployeeQueueUniqueWhere"
	},
	TimetableEmployeeRoleShiftGroupsByShiftsUniqueWhere:{
		shifts:"TimetableShiftUniqueWhere"
	},
	TimetableEmployeeRoleShiftGroupsByQueueUniqueWhere:{
		queue:"EmployeeQueueUniqueWhere"
	},
	ShiftsProfileQueueItemsByQueueUniqueWhere:{
		queue:"EmployeeQueueUniqueWhere"
	},
	ShiftsProfileQueueItemsByShiftUniqueWhere:{
		shift:"TimetableShiftUniqueWhere"
	},
	ShiftsProfileQueueItemsByUnassignInfoUniqueWhere:{
		unassignInfo:"UnassignedShiftInfoUniqueWhere"
	},
	ShiftsProfileShiftsByQueueItemUniqueWhere:{
		queueItem:"EmployeeQueueItemUniqueWhere"
	},
	TimetableShiftGroupShiftsByQueueItemUniqueWhere:{
		queueItem:"EmployeeQueueItemUniqueWhere"
	},
	TimetableDayNote:{
		day:{
			filter:"TimetableDayWhere"
		},
		role:{
			filter:"TimetableEmployeeRoleWhere"
		}
	},
	TimetableDayNoteOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		note:"OrderDirection",
		day:"TimetableDayOrderBy",
		role:"TimetableEmployeeRoleOrderBy"
	},
	TimetableDayMetadata:{
		day:{
			filter:"TimetableDayWhere"
		}
	},
	TimetableDayShiftGroupsByShiftsUniqueWhere:{
		shifts:"TimetableShiftUniqueWhere"
	},
	TimetableDayShiftGroupsByQueueUniqueWhere:{
		queue:"EmployeeQueueUniqueWhere"
	},
	VenueEmployeeRole:{
		employeePrivileges:{
			filter:"TimetablesEmployeePrivilegesWhere"
		},
		venue:{
			filter:"VenueWhere"
		},
		role:{
			filter:"TimetableEmployeeRoleWhere"
		}
	},
	TimetablesEmployeePrivileges:{
		venueEmployeeRole:{
			filter:"VenueEmployeeRoleWhere"
		}
	},
	VenueEmployeeRoleOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		employeePrivileges:"TimetablesEmployeePrivilegesOrderBy",
		venue:"VenueOrderBy",
		role:"TimetableEmployeeRoleOrderBy",
		getsTipsShare:"OrderDirection",
		notesEnabled:"OrderDirection"
	},
	TimetablesEmployeePrivilegesOrderBy:{
		id:"OrderDirection",
		venueEmployeeRole:"VenueEmployeeRoleOrderBy",
		read:"OrderDirection",
		unassign:"OrderDirection",
		assign:"OrderDirection"
	},
	OrderOrderBy:{
		id:"OrderDirection",
		state:"OrderDirection",
		fulfilledAt:"OrderDirection",
		canceledAt:"OrderDirection",
		note:"OrderDirection",
		customer:"CustomerOrderBy",
		channel:"ChannelOrderBy",
		delivery:"OrderDeliveryOrderBy",
		internalNote:"OrderDirection",
		createdAt:"OrderDirection",
		billingAddress:"AddressOrderBy",
		seq:"OrderDirection",
		doubleshotLegacyId:"OrderDirection",
		venue:"VenueOrderBy",
		fulfillmentNote:"OrderDirection",
		doubleshotTotalWeightGrams:"OrderDirection",
		doubleShotLegacyNumber:"OrderDirection",
		doubleshotLastUpdatedAt:"OrderDirection",
		doubleshotAdminUrl:"OrderDirection"
	},
	ChannelOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		disabled:"OrderDirection",
		code:"OrderDirection",
		venue:"VenueOrderBy"
	},
	OrderDeliveryOrderBy:{
		id:"OrderDirection",
		priceCents:"OrderDirection",
		deliveredAt:"OrderDirection",
		method:"DeliveryMethodOrderBy",
		vatRate:"VatRateOrderBy",
		order:"OrderOrderBy",
		expectedAt:"OrderDirection",
		state:"OrderDirection",
		loadingDurationHours:"OrderDirection",
		dispatchAt:"OrderDirection",
		duration:"OrderDirection",
		window:"OrderDirection",
		expectedEndAt:"OrderDirection",
		processingAt:"OrderDirection",
		deliveringAt:"OrderDirection",
		failedAt:"OrderDirection",
		internalNote:"OrderDirection",
		deliveryWaypoint:"DeliveryWaypointOrderBy",
		prepareAt:"OrderDirection",
		pickedAt:"OrderDirection",
		address:"AddressOrderBy",
		trackingCode:"OrderDirection"
	},
	DeliveryWaypointOrderBy:{
		id:"OrderDirection",
		estimatedArrival:"OrderDirection",
		note:"OrderDirection",
		route:"DeliveryRouteOrderBy",
		sortingOrder:"OrderDirection",
		delivery:"OrderDeliveryOrderBy",
		estimatedDistance:"OrderDirection",
		estimatedDuration:"OrderDirection",
		originDeparture:"OrderDirection",
		originAddress:"OrderDirection",
		transitMode:"OrderDirection"
	},
	DeliveryRouteOrderBy:{
		id:"OrderDirection",
		createdAt:"OrderDirection",
		dispatchAt:"OrderDirection"
	},
	FulfillmentNote:{
		products:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		tags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		venues:{
			filter:"VenueWhere",
			orderBy:"VenueOrderBy"
		},
		orderItems:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		orders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		paginateProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateVenues:{
			filter:"VenueWhere",
			orderBy:"VenueOrderBy"
		},
		paginateOrderItems:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		paginateOrders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		}
	},
	OrderItemOrderBy:{
		id:"OrderDirection",
		unitPriceCents:"OrderDirection",
		quantity:"OrderDirection",
		vatRate:"VatRateOrderBy",
		order:"OrderOrderBy",
		pickedAt:"OrderDirection",
		note:"OrderDirection",
		product:"ProductOrderBy"
	},
	FulfillmentNoteOrderBy:{
		id:"OrderDirection",
		note:"OrderDirection",
		highlight:"OrderDirection",
		createdAt:"OrderDirection",
		emoji:"OrderDirection"
	},
	VenueTimetableDaysByDateUniqueWhere:{
		date:"Date"
	},
	VenueTimetableDaysByShiftGroupsUniqueWhere:{
		shiftGroups:"TimetableShiftGroupUniqueWhere"
	},
	VenueTimetableDaysByNotesUniqueWhere:{
		notes:"TimetableDayNoteUniqueWhere"
	},
	TimetableDayNoteUniqueWhere:{
		id:"UUID"
	},
	VenueTimetableDaysByMetaUniqueWhere:{
		meta:"TimetableDayMetadataUniqueWhere"
	},
	TimetableDayMetadataUniqueWhere:{
		id:"UUID",
		day:"TimetableDayUniqueWhere"
	},
	TimetableDayUniqueWhere:{
		id:"UUID",
		venue:"VenueUniqueWhere",
		date:"Date",
		shiftGroups:"TimetableShiftGroupUniqueWhere",
		notes:"TimetableDayNoteUniqueWhere",
		meta:"TimetableDayMetadataUniqueWhere"
	},
	VenueUniqueWhere:{
		id:"UUID",
		deadlineTemplate:"DeadlineTemplateUniqueWhere",
		timetableDays:"TimetableDayUniqueWhere",
		timetableTemplates:"TimetableTemplateUniqueWhere",
		employeeRoles:"VenueEmployeeRoleUniqueWhere",
		orders:"OrderUniqueWhere",
		channels:"ChannelUniqueWhere"
	},
	DeadlineTemplateUniqueWhere:{
		id:"UUID",
		venue:"VenueUniqueWhere"
	},
	TimetableTemplateUniqueWhere:{
		id:"UUID"
	},
	VenueEmployeeRoleUniqueWhere:{
		id:"UUID",
		venue:"VenueUniqueWhere",
		role:"TimetableEmployeeRoleUniqueWhere",
		employeePrivileges:"TimetablesEmployeePrivilegesUniqueWhere"
	},
	TimetableEmployeeRoleUniqueWhere:{
		id:"UUID",
		shiftGroups:"TimetableShiftGroupUniqueWhere",
		timetableTemplates:"TimetableTemplateUniqueWhere"
	},
	TimetablesEmployeePrivilegesUniqueWhere:{
		id:"UUID",
		venueEmployeeRole:"VenueEmployeeRoleUniqueWhere"
	},
	ChannelUniqueWhere:{
		id:"UUID",
		code:"ChannelCode"
	},
	VenueEmployeeRolesByRoleUniqueWhere:{
		role:"TimetableEmployeeRoleUniqueWhere"
	},
	VenueEmployeeRolesByEmployeePrivilegesUniqueWhere:{
		employeePrivileges:"TimetablesEmployeePrivilegesUniqueWhere"
	},
	VenueOrdersBySeqUniqueWhere:{

	},
	VenueOrdersByDoubleshotLegacyIdUniqueWhere:{

	},
	VenueOrdersByDoubleShotLegacyNumberUniqueWhere:{

	},
	VenueOrdersByDeliveryUniqueWhere:{
		delivery:"OrderDeliveryUniqueWhere"
	},
	VenueOrdersByItemsUniqueWhere:{
		items:"OrderItemUniqueWhere"
	},
	VenueOrdersByPaymentsUniqueWhere:{
		payments:"OrderPaymentUniqueWhere"
	},
	VenueChannelsByCodeUniqueWhere:{
		code:"ChannelCode"
	},
	OrderDelivery:{
		method:{
			filter:"DeliveryMethodWhere"
		},
		vatRate:{
			filter:"VatRateWhere"
		},
		order:{
			filter:"OrderWhere"
		},
		deliveryWaypoint:{
			filter:"DeliveryWaypointWhere"
		},
		address:{
			filter:"AddressWhere"
		}
	},
	DeliveryWaypoint:{
		route:{
			filter:"DeliveryRouteWhere"
		},
		delivery:{
			filter:"OrderDeliveryWhere"
		}
	},
	DeliveryRoute:{
		waypoints:{
			filter:"DeliveryWaypointWhere",
			orderBy:"DeliveryWaypointOrderBy"
		},
		waypointsByDelivery:{
			by:"DeliveryRouteWaypointsByDeliveryUniqueWhere",
			filter:"DeliveryWaypointWhere"
		},
		paginateWaypoints:{
			filter:"DeliveryWaypointWhere",
			orderBy:"DeliveryWaypointOrderBy"
		}
	},
	DeliveryRouteWaypointsByDeliveryUniqueWhere:{
		delivery:"OrderDeliveryUniqueWhere"
	},
	OrderPayment:{
		method:{
			filter:"PaymentMethodWhere"
		},
		order:{
			filter:"OrderWhere"
		},
		vatRate:{
			filter:"VatRateWhere"
		}
	},
	Json: "String",
	OrderPaymentOrderBy:{
		id:"OrderDirection",
		data:"OrderDirection",
		paymentCents:"OrderDirection",
		method:"PaymentMethodOrderBy",
		failedAt:"OrderDirection",
		state:"OrderDirection",
		approvedAt:"OrderDirection",
		order:"OrderOrderBy",
		walletType:"OrderDirection",
		createdAt:"OrderDirection",
		publicReceiptUrl:"OrderDirection",
		priceCents:"OrderDirection",
		vatRate:"VatRateOrderBy",
		otherCurrencyPriceCents:"OrderDirection"
	},
	ProductMetadata:{
		product:{
			filter:"ProductWhere"
		}
	},
	ProductStocksByStoreUniqueWhere:{
		store:"StoreUniqueWhere"
	},
	ProductLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	ProductLocalesByFeatureListUniqueWhere:{
		featureList:"TextListUniqueWhere"
	},
	CustomerName:{
		customer:{
			filter:"CustomerWhere"
		}
	},
	CustomerMetadata:{
		customer:{
			filter:"CustomerWhere"
		}
	},
	CustomerAddressesByBillingAddressOfOrdersUniqueWhere:{
		billingAddressOfOrders:"OrderUniqueWhere"
	},
	CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere:{
		deliveryAddressOfOrderDeliveries:"OrderDeliveryUniqueWhere"
	},
	CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere:{
		defaultDeliveryAddressOfCustomer:"CustomerUniqueWhere"
	},
	CustomerOrdersBySeqUniqueWhere:{

	},
	CustomerOrdersByDoubleshotLegacyIdUniqueWhere:{

	},
	CustomerOrdersByDoubleShotLegacyNumberUniqueWhere:{

	},
	CustomerOrdersByDeliveryUniqueWhere:{
		delivery:"OrderDeliveryUniqueWhere"
	},
	CustomerOrdersByItemsUniqueWhere:{
		items:"OrderItemUniqueWhere"
	},
	CustomerOrdersByPaymentsUniqueWhere:{
		payments:"OrderPaymentUniqueWhere"
	},
	AddressBillingAddressOfOrdersBySeqUniqueWhere:{

	},
	AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere:{

	},
	AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere:{

	},
	AddressBillingAddressOfOrdersByDeliveryUniqueWhere:{
		delivery:"OrderDeliveryUniqueWhere"
	},
	AddressBillingAddressOfOrdersByItemsUniqueWhere:{
		items:"OrderItemUniqueWhere"
	},
	AddressBillingAddressOfOrdersByPaymentsUniqueWhere:{
		payments:"OrderPaymentUniqueWhere"
	},
	AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere:{

	},
	AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere:{
		order:"OrderUniqueWhere"
	},
	AddressDeliveryAddressOfOrderDeliveriesByDeliveryWaypointUniqueWhere:{
		deliveryWaypoint:"DeliveryWaypointUniqueWhere"
	},
	CountryUniqueWhere:{
		id:"UUID"
	},
	PaymentMethodUniqueWhere:{
		id:"UUID"
	},
	VatRateUniqueWhere:{
		id:"UUID",
		preset:"VatRatePreset"
	},
	TagUniqueWhere:{
		id:"UUID"
	},
	ProductIngredientItemQuantityUnitUniqueWhere:{
		id:"UUID"
	},
	ProductIngredientRating:{
		icon:{
			filter:"ImageWhere"
		}
	},
	ProductIngredientRatingUniqueWhere:{
		id:"UUID"
	},
	ProductIngredientRatingWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		name:"StringCondition",
		icon:"ImageWhere",
		and:"ProductIngredientRatingWhere",
		or:"ProductIngredientRatingWhere",
		not:"ProductIngredientRatingWhere"
	},
	ProductIngredientRatingOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		name:"OrderDirection",
		icon:"ImageOrderBy"
	},
	ColorPalleteUniqueWhere:{
		id:"UUID"
	},
	BlogPostListUniqueWhere:{
		id:"UUID",
		items:"BlogPostListItemUniqueWhere"
	},
	BlogPostListItemUniqueWhere:{
		id:"UUID"
	},
	LinkListUniqueWhere:{
		id:"UUID",
		items:"LinkListItemUniqueWhere"
	},
	LinkListItemUniqueWhere:{
		id:"UUID"
	},
	MediumUniqueWhere:{
		id:"UUID"
	},
	VideoUniqueWhere:{
		id:"UUID"
	},
	SocialsAndAppsUniqueWhere:{
		id:"UUID"
	},
	ProductGridUniqueWhere:{
		id:"UUID",
		categories:"ProductGridCategoryUniqueWhere"
	},
	ProductGridCategoryUniqueWhere:{
		id:"UUID",
		items:"ProductGridItemUniqueWhere",
		images:"ImageListUniqueWhere"
	},
	GalleryUniqueWhere:{
		id:"UUID",
		items:"GalleryItemUniqueWhere"
	},
	GalleryItemUniqueWhere:{
		id:"UUID"
	},
	HeroUniqueWhere:{
		id:"UUID"
	},
	EmployeeQueueCreateInput:{
		items:"EmployeeQueueCreateItemsEntityRelationInput"
	},
	EmployeeQueueCreateItemsEntityRelationInput:{
		connect:"EmployeeQueueItemUniqueWhere",
		create:"EmployeeQueueItemWithoutQueueCreateInput"
	},
	EmployeeQueueItemWithoutQueueCreateInput:{
		shiftsProfile:"EmployeeQueueItemCreateShiftsProfileEntityRelationInput",
		unassignInfo:"EmployeeQueueItemCreateUnassignInfoEntityRelationInput"
	},
	EmployeeQueueItemCreateShiftsProfileEntityRelationInput:{
		connect:"ShiftsProfileUniqueWhere"
	},
	EmployeeQueueItemCreateUnassignInfoEntityRelationInput:{
		connect:"UnassignedShiftInfoUniqueWhere",
		create:"UnassignedShiftInfoWithoutQueueItemCreateInput"
	},
	UnassignedShiftInfoWithoutQueueItemCreateInput:{
		legacyReason:"UnassignedInfoReason",
		reason:"UnassignedShiftInfoCreateReasonEntityRelationInput"
	},
	UnassignedShiftInfoCreateReasonEntityRelationInput:{
		connect:"UnassignReasonUniqueWhere"
	},
	UnassignReasonUniqueWhere:{
		id:"UUID"
	},
	EmployeeQueueUpdateInput:{
		items:"EmployeeQueueUpdateItemsEntityRelationInput"
	},
	EmployeeQueueUpdateItemsEntityRelationInput:{
		create:"EmployeeQueueItemWithoutQueueCreateInput",
		update:"EmployeeQueueUpdateItemsRelationInput",
		upsert:"EmployeeQueueUpsertItemsRelationInput",
		connect:"EmployeeQueueItemUniqueWhere",
		disconnect:"EmployeeQueueItemUniqueWhere",
		delete:"EmployeeQueueItemUniqueWhere"
	},
	EmployeeQueueUpdateItemsRelationInput:{
		by:"EmployeeQueueItemUniqueWhere",
		data:"EmployeeQueueItemWithoutQueueUpdateInput"
	},
	EmployeeQueueItemWithoutQueueUpdateInput:{
		shiftsProfile:"EmployeeQueueItemUpdateShiftsProfileEntityRelationInput",
		shift:"EmployeeQueueItemUpdateShiftEntityRelationInput",
		unassignInfo:"EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"
	},
	EmployeeQueueItemUpdateShiftsProfileEntityRelationInput:{
		update:"ShiftsProfileWithoutQueueItemsUpdateInput",
		connect:"ShiftsProfileUniqueWhere"
	},
	ShiftsProfileWithoutQueueItemsUpdateInput:{
		shifts:"ShiftsProfileUpdateShiftsEntityRelationInput"
	},
	ShiftsProfileUpdateShiftsEntityRelationInput:{
		update:"ShiftsProfileUpdateShiftsRelationInput",
		connect:"TimetableShiftUniqueWhere",
		disconnect:"TimetableShiftUniqueWhere"
	},
	ShiftsProfileUpdateShiftsRelationInput:{
		by:"TimetableShiftUniqueWhere",
		data:"TimetableShiftWithoutShiftsProfileUpdateInput"
	},
	TimetableShiftWithoutShiftsProfileUpdateInput:{
		assigned:"Date",
		group:"TimetableShiftUpdateGroupEntityRelationInput",
		queueItem:"TimetableShiftUpdateQueueItemEntityRelationInput"
	},
	TimetableShiftUpdateGroupEntityRelationInput:{
		connect:"TimetableShiftGroupUniqueWhere"
	},
	TimetableShiftUpdateQueueItemEntityRelationInput:{
		create:"EmployeeQueueItemWithoutShiftCreateInput",
		update:"EmployeeQueueItemWithoutShiftUpdateInput",
		upsert:"TimetableShiftUpsertQueueItemRelationInput",
		connect:"EmployeeQueueItemUniqueWhere"
	},
	EmployeeQueueItemWithoutShiftCreateInput:{
		queue:"EmployeeQueueItemCreateQueueEntityRelationInput",
		shiftsProfile:"EmployeeQueueItemCreateShiftsProfileEntityRelationInput",
		unassignInfo:"EmployeeQueueItemCreateUnassignInfoEntityRelationInput"
	},
	EmployeeQueueItemCreateQueueEntityRelationInput:{
		connect:"EmployeeQueueUniqueWhere",
		create:"EmployeeQueueWithoutItemsCreateInput"
	},
	EmployeeQueueWithoutItemsCreateInput:{

	},
	EmployeeQueueItemWithoutShiftUpdateInput:{
		queue:"EmployeeQueueItemUpdateQueueEntityRelationInput",
		shiftsProfile:"EmployeeQueueItemUpdateShiftsProfileEntityRelationInput",
		unassignInfo:"EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"
	},
	EmployeeQueueItemUpdateQueueEntityRelationInput:{
		create:"EmployeeQueueWithoutItemsCreateInput",
		update:"EmployeeQueueWithoutItemsUpdateInput",
		upsert:"EmployeeQueueItemUpsertQueueRelationInput",
		connect:"EmployeeQueueUniqueWhere"
	},
	EmployeeQueueWithoutItemsUpdateInput:{

	},
	EmployeeQueueItemUpsertQueueRelationInput:{
		update:"EmployeeQueueWithoutItemsUpdateInput",
		create:"EmployeeQueueWithoutItemsCreateInput"
	},
	EmployeeQueueItemUpdateUnassignInfoEntityRelationInput:{
		create:"UnassignedShiftInfoWithoutQueueItemCreateInput",
		update:"UnassignedShiftInfoWithoutQueueItemUpdateInput",
		upsert:"EmployeeQueueItemUpsertUnassignInfoRelationInput",
		connect:"UnassignedShiftInfoUniqueWhere"
	},
	UnassignedShiftInfoWithoutQueueItemUpdateInput:{
		legacyReason:"UnassignedInfoReason",
		reason:"UnassignedShiftInfoUpdateReasonEntityRelationInput"
	},
	UnassignedShiftInfoUpdateReasonEntityRelationInput:{
		connect:"UnassignReasonUniqueWhere"
	},
	EmployeeQueueItemUpsertUnassignInfoRelationInput:{
		update:"UnassignedShiftInfoWithoutQueueItemUpdateInput",
		create:"UnassignedShiftInfoWithoutQueueItemCreateInput"
	},
	TimetableShiftUpsertQueueItemRelationInput:{
		update:"EmployeeQueueItemWithoutShiftUpdateInput",
		create:"EmployeeQueueItemWithoutShiftCreateInput"
	},
	EmployeeQueueItemUpdateShiftEntityRelationInput:{
		update:"TimetableShiftWithoutQueueItemUpdateInput",
		connect:"TimetableShiftUniqueWhere"
	},
	TimetableShiftWithoutQueueItemUpdateInput:{
		assigned:"Date",
		group:"TimetableShiftUpdateGroupEntityRelationInput",
		shiftsProfile:"TimetableShiftUpdateShiftsProfileEntityRelationInput"
	},
	TimetableShiftUpdateShiftsProfileEntityRelationInput:{
		update:"ShiftsProfileWithoutShiftsUpdateInput",
		connect:"ShiftsProfileUniqueWhere"
	},
	ShiftsProfileWithoutShiftsUpdateInput:{
		queueItems:"ShiftsProfileUpdateQueueItemsEntityRelationInput"
	},
	ShiftsProfileUpdateQueueItemsEntityRelationInput:{
		create:"EmployeeQueueItemWithoutShiftsProfileCreateInput",
		update:"ShiftsProfileUpdateQueueItemsRelationInput",
		upsert:"ShiftsProfileUpsertQueueItemsRelationInput",
		connect:"EmployeeQueueItemUniqueWhere",
		disconnect:"EmployeeQueueItemUniqueWhere",
		delete:"EmployeeQueueItemUniqueWhere"
	},
	EmployeeQueueItemWithoutShiftsProfileCreateInput:{
		queue:"EmployeeQueueItemCreateQueueEntityRelationInput",
		unassignInfo:"EmployeeQueueItemCreateUnassignInfoEntityRelationInput"
	},
	ShiftsProfileUpdateQueueItemsRelationInput:{
		by:"EmployeeQueueItemUniqueWhere",
		data:"EmployeeQueueItemWithoutShiftsProfileUpdateInput"
	},
	EmployeeQueueItemWithoutShiftsProfileUpdateInput:{
		queue:"EmployeeQueueItemUpdateQueueEntityRelationInput",
		shift:"EmployeeQueueItemUpdateShiftEntityRelationInput",
		unassignInfo:"EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"
	},
	ShiftsProfileUpsertQueueItemsRelationInput:{
		by:"EmployeeQueueItemUniqueWhere",
		update:"EmployeeQueueItemWithoutShiftsProfileUpdateInput",
		create:"EmployeeQueueItemWithoutShiftsProfileCreateInput"
	},
	EmployeeQueueUpsertItemsRelationInput:{
		by:"EmployeeQueueItemUniqueWhere",
		update:"EmployeeQueueItemWithoutQueueUpdateInput",
		create:"EmployeeQueueItemWithoutQueueCreateInput"
	},
	TimetableShiftUpdateInput:{
		assigned:"Date",
		group:"TimetableShiftUpdateGroupEntityRelationInput",
		shiftsProfile:"TimetableShiftUpdateShiftsProfileEntityRelationInput",
		queueItem:"TimetableShiftUpdateQueueItemEntityRelationInput"
	},
	TimetableShiftPositionUniqueWhere:{
		id:"UUID",
		shiftGroups:"TimetableShiftGroupUniqueWhere",
		timetableTemplates:"TimetableTemplateUniqueWhere"
	},
	EmployeeQueueItemCreateInput:{
		queue:"EmployeeQueueItemCreateQueueEntityRelationInput",
		shiftsProfile:"EmployeeQueueItemCreateShiftsProfileEntityRelationInput",
		unassignInfo:"EmployeeQueueItemCreateUnassignInfoEntityRelationInput"
	},
	EmployeeQueueItemUpdateInput:{
		queue:"EmployeeQueueItemUpdateQueueEntityRelationInput",
		shiftsProfile:"EmployeeQueueItemUpdateShiftsProfileEntityRelationInput",
		shift:"EmployeeQueueItemUpdateShiftEntityRelationInput",
		unassignInfo:"EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"
	},
	ProductBannerUniqueWhere:{
		id:"UUID",
		button:"LinkUniqueWhere"
	},
	ShiftsProfileUpdateInput:{
		queueItems:"ShiftsProfileUpdateQueueItemsEntityRelationInput",
		shifts:"ShiftsProfileUpdateShiftsEntityRelationInput"
	},
	DeliveryRouteUniqueWhere:{
		id:"UUID",
		waypoints:"DeliveryWaypointUniqueWhere"
	},
	WeekHoursUniqueWhere:{
		id:"UUID"
	},
	PreparedIngredientQuantity:{
		ingredient:{
			filter:"ProductIngredientWhere"
		},
		unit:{
			filter:"ProductIngredientItemQuantityUnitWhere"
		}
	},
	PreparedIngredientQuantityUniqueWhere:{
		id:"UUID",
		date:"Date",
		ingredient:"ProductIngredientUniqueWhere",
		unit:"ProductIngredientItemQuantityUnitUniqueWhere"
	},
	PreparedIngredientQuantityWhere:{
		id:"UUIDCondition",
		date:"DateCondition",
		quantity:"FloatCondition",
		usedQuantity:"FloatCondition",
		ingredient:"ProductIngredientWhere",
		unit:"ProductIngredientItemQuantityUnitWhere",
		and:"PreparedIngredientQuantityWhere",
		or:"PreparedIngredientQuantityWhere",
		not:"PreparedIngredientQuantityWhere"
	},
	PreparedIngredientQuantityOrderBy:{
		id:"OrderDirection",
		date:"OrderDirection",
		quantity:"OrderDirection",
		usedQuantity:"OrderDirection",
		ingredient:"ProductIngredientOrderBy",
		unit:"ProductIngredientItemQuantityUnitOrderBy"
	},
	PreparedIngredientQuantityCreateInput:{
		date:"Date",
		ingredient:"PreparedIngredientQuantityCreateIngredientEntityRelationInput",
		unit:"PreparedIngredientQuantityCreateUnitEntityRelationInput"
	},
	PreparedIngredientQuantityCreateIngredientEntityRelationInput:{
		connect:"ProductIngredientUniqueWhere"
	},
	PreparedIngredientQuantityCreateUnitEntityRelationInput:{
		connect:"ProductIngredientItemQuantityUnitUniqueWhere"
	},
	PreparedIngredientQuantityUpdateInput:{
		date:"Date",
		ingredient:"PreparedIngredientQuantityUpdateIngredientEntityRelationInput",
		unit:"PreparedIngredientQuantityUpdateUnitEntityRelationInput"
	},
	PreparedIngredientQuantityUpdateIngredientEntityRelationInput:{
		connect:"ProductIngredientUniqueWhere"
	},
	PreparedIngredientQuantityUpdateUnitEntityRelationInput:{
		connect:"ProductIngredientItemQuantityUnitUniqueWhere"
	},
	PreparedRecipeQuantity:{
		recipe:{
			filter:"ProductRecipeWhere"
		}
	},
	PreparedRecipeQuantityUniqueWhere:{
		id:"UUID",
		date:"Date",
		recipe:"ProductRecipeUniqueWhere"
	},
	PreparedRecipeQuantityWhere:{
		id:"UUIDCondition",
		date:"DateCondition",
		quantity:"FloatCondition",
		recipe:"ProductRecipeWhere",
		usedQuantity:"FloatCondition",
		and:"PreparedRecipeQuantityWhere",
		or:"PreparedRecipeQuantityWhere",
		not:"PreparedRecipeQuantityWhere"
	},
	PreparedRecipeQuantityOrderBy:{
		id:"OrderDirection",
		date:"OrderDirection",
		quantity:"OrderDirection",
		recipe:"ProductRecipeOrderBy",
		usedQuantity:"OrderDirection"
	},
	PreparedRecipeQuantityCreateInput:{
		date:"Date",
		recipe:"PreparedRecipeQuantityCreateRecipeEntityRelationInput"
	},
	PreparedRecipeQuantityCreateRecipeEntityRelationInput:{
		connect:"ProductRecipeUniqueWhere"
	},
	PreparedRecipeQuantityUpdateInput:{
		date:"Date",
		recipe:"PreparedRecipeQuantityUpdateRecipeEntityRelationInput"
	},
	PreparedRecipeQuantityUpdateRecipeEntityRelationInput:{
		connect:"ProductRecipeUniqueWhere"
	},
	PreparedPackingQuantityCreateInput:{
		date:"Date",
		recipe:"PreparedPackingQuantityCreateRecipeEntityRelationInput",
		packing:"PreparedPackingQuantityCreatePackingEntityRelationInput"
	},
	PreparedPackingQuantityCreateRecipeEntityRelationInput:{
		connect:"ProductRecipeUniqueWhere"
	},
	PreparedPackingQuantityCreatePackingEntityRelationInput:{
		connect:"ProductPackingUniqueWhere"
	},
	PreparedPackingQuantityUpdateInput:{
		date:"Date",
		recipe:"PreparedPackingQuantityUpdateRecipeEntityRelationInput",
		packing:"PreparedPackingQuantityUpdatePackingEntityRelationInput"
	},
	PreparedPackingQuantityUpdateRecipeEntityRelationInput:{
		connect:"ProductRecipeUniqueWhere"
	},
	PreparedPackingQuantityUpdatePackingEntityRelationInput:{
		connect:"ProductPackingUniqueWhere"
	},
	DeliveryZoneUniqueWhere:{
		id:"UUID",
		addresses:"AddressUniqueWhere"
	},
	SuppliesPurchase:{
		items:{
			filter:"SuppliesPurchaseItemWhere",
			orderBy:"SuppliesPurchaseItemOrderBy"
		},
		supplier:{
			filter:"IngredientSupplierWhere"
		},
		paginateItems:{
			filter:"SuppliesPurchaseItemWhere",
			orderBy:"SuppliesPurchaseItemOrderBy"
		}
	},
	SuppliesPurchaseItem:{
		list:{
			filter:"SuppliesPurchaseWhere"
		},
		ingredient:{
			filter:"ProductIngredientWhere"
		},
		preferredUnit:{
			filter:"ProductIngredientItemQuantityUnitWhere"
		},
		purchasedBy:{
			filter:"StaffWhere"
		}
	},
	SuppliesPurchaseWhere:{
		id:"UUIDCondition",
		createdAt:"DateTimeCondition",
		items:"SuppliesPurchaseItemWhere",
		supplier:"IngredientSupplierWhere",
		requestedDeliveryDate:"DateCondition",
		and:"SuppliesPurchaseWhere",
		or:"SuppliesPurchaseWhere",
		not:"SuppliesPurchaseWhere"
	},
	SuppliesPurchaseItemWhere:{
		id:"UUIDCondition",
		amount:"IntCondition",
		list:"SuppliesPurchaseWhere",
		ingredient:"ProductIngredientWhere",
		pricePaidCents:"IntCondition",
		preferredUnit:"ProductIngredientItemQuantityUnitWhere",
		deletedAt:"DateTimeCondition",
		purchasedAt:"DateTimeCondition",
		purchasedBy:"StaffWhere",
		and:"SuppliesPurchaseItemWhere",
		or:"SuppliesPurchaseItemWhere",
		not:"SuppliesPurchaseItemWhere"
	},
	SuppliesPurchaseItemOrderBy:{
		id:"OrderDirection",
		amount:"OrderDirection",
		list:"SuppliesPurchaseOrderBy",
		ingredient:"ProductIngredientOrderBy",
		pricePaidCents:"OrderDirection",
		preferredUnit:"ProductIngredientItemQuantityUnitOrderBy",
		deletedAt:"OrderDirection",
		purchasedAt:"OrderDirection",
		purchasedBy:"StaffOrderBy"
	},
	SuppliesPurchaseOrderBy:{
		id:"OrderDirection",
		createdAt:"OrderDirection",
		supplier:"IngredientSupplierOrderBy",
		requestedDeliveryDate:"OrderDirection"
	},
	SuppliesPurchaseUniqueWhere:{
		id:"UUID",
		createdAt:"DateTime",
		supplier:"IngredientSupplierUniqueWhere",
		items:"SuppliesPurchaseItemUniqueWhere"
	},
	SuppliesPurchaseItemUniqueWhere:{
		id:"UUID"
	},
	SuppliesPurchaseCreateInput:{
		createdAt:"DateTime",
		items:"SuppliesPurchaseCreateItemsEntityRelationInput",
		supplier:"SuppliesPurchaseCreateSupplierEntityRelationInput",
		requestedDeliveryDate:"Date"
	},
	SuppliesPurchaseCreateItemsEntityRelationInput:{
		connect:"SuppliesPurchaseItemUniqueWhere",
		create:"SuppliesPurchaseItemWithoutListCreateInput"
	},
	SuppliesPurchaseItemWithoutListCreateInput:{
		ingredient:"SuppliesPurchaseItemCreateIngredientEntityRelationInput",
		preferredUnit:"SuppliesPurchaseItemCreatePreferredUnitEntityRelationInput",
		deletedAt:"DateTime",
		purchasedAt:"DateTime",
		purchasedBy:"SuppliesPurchaseItemCreatePurchasedByEntityRelationInput"
	},
	SuppliesPurchaseItemCreateIngredientEntityRelationInput:{
		connect:"ProductIngredientUniqueWhere"
	},
	SuppliesPurchaseItemCreatePreferredUnitEntityRelationInput:{
		connect:"ProductIngredientItemQuantityUnitUniqueWhere"
	},
	SuppliesPurchaseItemCreatePurchasedByEntityRelationInput:{
		connect:"StaffUniqueWhere"
	},
	SuppliesPurchaseCreateSupplierEntityRelationInput:{
		connect:"IngredientSupplierUniqueWhere"
	},
	SuppliesPurchaseUpdateInput:{
		createdAt:"DateTime",
		items:"SuppliesPurchaseUpdateItemsEntityRelationInput",
		supplier:"SuppliesPurchaseUpdateSupplierEntityRelationInput",
		requestedDeliveryDate:"Date"
	},
	SuppliesPurchaseUpdateItemsEntityRelationInput:{
		create:"SuppliesPurchaseItemWithoutListCreateInput",
		update:"SuppliesPurchaseUpdateItemsRelationInput",
		upsert:"SuppliesPurchaseUpsertItemsRelationInput",
		connect:"SuppliesPurchaseItemUniqueWhere",
		disconnect:"SuppliesPurchaseItemUniqueWhere"
	},
	SuppliesPurchaseUpdateItemsRelationInput:{
		by:"SuppliesPurchaseItemUniqueWhere",
		data:"SuppliesPurchaseItemWithoutListUpdateInput"
	},
	SuppliesPurchaseItemWithoutListUpdateInput:{
		ingredient:"SuppliesPurchaseItemUpdateIngredientEntityRelationInput",
		preferredUnit:"SuppliesPurchaseItemUpdatePreferredUnitEntityRelationInput",
		deletedAt:"DateTime",
		purchasedAt:"DateTime",
		purchasedBy:"SuppliesPurchaseItemUpdatePurchasedByEntityRelationInput"
	},
	SuppliesPurchaseItemUpdateIngredientEntityRelationInput:{
		connect:"ProductIngredientUniqueWhere"
	},
	SuppliesPurchaseItemUpdatePreferredUnitEntityRelationInput:{
		connect:"ProductIngredientItemQuantityUnitUniqueWhere"
	},
	SuppliesPurchaseItemUpdatePurchasedByEntityRelationInput:{
		connect:"StaffUniqueWhere"
	},
	SuppliesPurchaseUpsertItemsRelationInput:{
		by:"SuppliesPurchaseItemUniqueWhere",
		update:"SuppliesPurchaseItemWithoutListUpdateInput",
		create:"SuppliesPurchaseItemWithoutListCreateInput"
	},
	SuppliesPurchaseUpdateSupplierEntityRelationInput:{
		connect:"IngredientSupplierUniqueWhere"
	},
	LogRecordUniqueWhere:{
		id:"UUID"
	},
	LogRecordWhere:{
		id:"UUIDCondition",
		level:"IntCondition",
		type:"StringCondition",
		message:"StringCondition",
		data:"JsonCondition",
		createdAt:"DateTimeCondition",
		shouldBeResolved:"BooleanCondition",
		resolvedAt:"DateTimeCondition",
		and:"LogRecordWhere",
		or:"LogRecordWhere",
		not:"LogRecordWhere"
	},
	LogRecordOrderBy:{
		id:"OrderDirection",
		level:"OrderDirection",
		type:"OrderDirection",
		message:"OrderDirection",
		data:"OrderDirection",
		createdAt:"OrderDirection",
		shouldBeResolved:"OrderDirection",
		resolvedAt:"OrderDirection"
	},
	SuppliesPurchaseItemCreateInput:{
		list:"SuppliesPurchaseItemCreateListEntityRelationInput",
		ingredient:"SuppliesPurchaseItemCreateIngredientEntityRelationInput",
		preferredUnit:"SuppliesPurchaseItemCreatePreferredUnitEntityRelationInput",
		deletedAt:"DateTime",
		purchasedAt:"DateTime",
		purchasedBy:"SuppliesPurchaseItemCreatePurchasedByEntityRelationInput"
	},
	SuppliesPurchaseItemCreateListEntityRelationInput:{
		connect:"SuppliesPurchaseUniqueWhere",
		create:"SuppliesPurchaseWithoutItemsCreateInput"
	},
	SuppliesPurchaseWithoutItemsCreateInput:{
		createdAt:"DateTime",
		supplier:"SuppliesPurchaseCreateSupplierEntityRelationInput",
		requestedDeliveryDate:"Date"
	},
	SuppliesPurchaseItemUpdateInput:{
		list:"SuppliesPurchaseItemUpdateListEntityRelationInput",
		ingredient:"SuppliesPurchaseItemUpdateIngredientEntityRelationInput",
		preferredUnit:"SuppliesPurchaseItemUpdatePreferredUnitEntityRelationInput",
		deletedAt:"DateTime",
		purchasedAt:"DateTime",
		purchasedBy:"SuppliesPurchaseItemUpdatePurchasedByEntityRelationInput"
	},
	SuppliesPurchaseItemUpdateListEntityRelationInput:{
		create:"SuppliesPurchaseWithoutItemsCreateInput",
		update:"SuppliesPurchaseWithoutItemsUpdateInput",
		upsert:"SuppliesPurchaseItemUpsertListRelationInput",
		connect:"SuppliesPurchaseUniqueWhere"
	},
	SuppliesPurchaseWithoutItemsUpdateInput:{
		createdAt:"DateTime",
		supplier:"SuppliesPurchaseUpdateSupplierEntityRelationInput",
		requestedDeliveryDate:"Date"
	},
	SuppliesPurchaseItemUpsertListRelationInput:{
		update:"SuppliesPurchaseWithoutItemsUpdateInput",
		create:"SuppliesPurchaseWithoutItemsCreateInput"
	},
	UnassignedShiftInfoCreateInput:{
		queueItem:"UnassignedShiftInfoCreateQueueItemEntityRelationInput",
		legacyReason:"UnassignedInfoReason",
		reason:"UnassignedShiftInfoCreateReasonEntityRelationInput"
	},
	UnassignedShiftInfoCreateQueueItemEntityRelationInput:{
		connect:"EmployeeQueueItemUniqueWhere",
		create:"EmployeeQueueItemWithoutUnassignInfoCreateInput"
	},
	EmployeeQueueItemWithoutUnassignInfoCreateInput:{
		queue:"EmployeeQueueItemCreateQueueEntityRelationInput",
		shiftsProfile:"EmployeeQueueItemCreateShiftsProfileEntityRelationInput"
	},
	UnassignedShiftInfoUpdateInput:{
		queueItem:"UnassignedShiftInfoUpdateQueueItemEntityRelationInput",
		legacyReason:"UnassignedInfoReason",
		reason:"UnassignedShiftInfoUpdateReasonEntityRelationInput"
	},
	UnassignedShiftInfoUpdateQueueItemEntityRelationInput:{
		create:"EmployeeQueueItemWithoutUnassignInfoCreateInput",
		update:"EmployeeQueueItemWithoutUnassignInfoUpdateInput",
		upsert:"UnassignedShiftInfoUpsertQueueItemRelationInput",
		connect:"EmployeeQueueItemUniqueWhere"
	},
	EmployeeQueueItemWithoutUnassignInfoUpdateInput:{
		queue:"EmployeeQueueItemUpdateQueueEntityRelationInput",
		shiftsProfile:"EmployeeQueueItemUpdateShiftsProfileEntityRelationInput",
		shift:"EmployeeQueueItemUpdateShiftEntityRelationInput"
	},
	UnassignedShiftInfoUpsertQueueItemRelationInput:{
		update:"EmployeeQueueItemWithoutUnassignInfoUpdateInput",
		create:"EmployeeQueueItemWithoutUnassignInfoCreateInput"
	},
	FreshingContainerUniqueWhere:{
		id:"UUID"
	},
	WorkspaceUniqueWhere:{
		id:"UUID",
		unique:"One",
		freshingContainers:"FreshingContainerUniqueWhere",
		openingHours:"WeekHoursUniqueWhere",
		workingHours:"WeekHoursUniqueWhere",
		creditsProduct:"VirtualProductUniqueWhere",
		discountVatRate:"VatRateUniqueWhere"
	},
	DeviceIdentifier:{
		meta:{
			filter:"DeviceIdentifierMetadataWhere"
		}
	},
	DeviceIdentifierMetadata:{
		deviceIdentifier:{
			filter:"DeviceIdentifierWhere"
		}
	},
	DeviceIdentifierWhere:{
		id:"UUIDCondition",
		internalName:"StringCondition",
		updatedAt:"DateTimeCondition",
		platform:"StringCondition",
		resolution:"StringCondition",
		devicePixelRatio:"FloatCondition",
		identifier:"StringCondition",
		meta:"DeviceIdentifierMetadataWhere",
		applicationVersionIdentifier:"StringCondition",
		lastPointOfSaleSynchronizationErrorRawDetails:"StringCondition",
		and:"DeviceIdentifierWhere",
		or:"DeviceIdentifierWhere",
		not:"DeviceIdentifierWhere"
	},
	DeviceIdentifierMetadataWhere:{
		id:"UUIDCondition",
		prettyIdentifier:"StringCondition",
		deviceIdentifier:"DeviceIdentifierWhere",
		and:"DeviceIdentifierMetadataWhere",
		or:"DeviceIdentifierMetadataWhere",
		not:"DeviceIdentifierMetadataWhere"
	},
	DeviceIdentifierUniqueWhere:{
		id:"UUID",
		meta:"DeviceIdentifierMetadataUniqueWhere"
	},
	DeviceIdentifierMetadataUniqueWhere:{
		id:"UUID",
		deviceIdentifier:"DeviceIdentifierUniqueWhere"
	},
	DeviceIdentifierOrderBy:{
		id:"OrderDirection",
		internalName:"OrderDirection",
		updatedAt:"OrderDirection",
		platform:"OrderDirection",
		resolution:"OrderDirection",
		devicePixelRatio:"OrderDirection",
		identifier:"OrderDirection",
		meta:"DeviceIdentifierMetadataOrderBy",
		applicationVersionIdentifier:"OrderDirection",
		lastPointOfSaleSynchronizationErrorRawDetails:"OrderDirection"
	},
	DeviceIdentifierMetadataOrderBy:{
		id:"OrderDirection",
		prettyIdentifier:"OrderDirection",
		deviceIdentifier:"DeviceIdentifierOrderBy"
	},
	DeviceIdentifierCreateInput:{
		updatedAt:"DateTime"
	},
	DeviceIdentifierUpdateInput:{
		updatedAt:"DateTime"
	},
	FulfillmentNoteUniqueWhere:{
		id:"UUID"
	},
	GridTileItemUniqueWhere:{
		id:"UUID",
		link:"LinkUniqueWhere"
	},
	GridTilesUniqueWhere:{
		id:"UUID",
		items:"GridTileItemUniqueWhere"
	},
	PositionUniqueWhere:{
		id:"UUID"
	},
	PositionWhere:{
		id:"UUIDCondition",
		x:"IntCondition",
		y:"IntCondition",
		and:"PositionWhere",
		or:"PositionWhere",
		not:"PositionWhere"
	},
	PositionOrderBy:{
		id:"OrderDirection",
		x:"OrderDirection",
		y:"OrderDirection"
	},
	StatsWidget:{
		layout:{
			filter:"StatsWidgetsLayoutWhere"
		},
		icon:{
			filter:"ImageWhere"
		},
		channels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		productCategory:{
			filter:"ProductCategoryWhere"
		},
		paymentMethods:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		paginateChannels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		paginatePaymentMethods:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		}
	},
	StatsEnabledWidgetTypeType: true,
	StatsWidgetTimeFrame: true,
	StatsWidgetsLayout:{
		items:{
			filter:"StatsWidgetWhere",
			orderBy:"StatsWidgetOrderBy"
		},
		paginateItems:{
			filter:"StatsWidgetWhere",
			orderBy:"StatsWidgetOrderBy"
		}
	},
	StatsWidgetWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		size:"IntCondition",
		type:"StatsEnabledWidgetTypeTypeCondition",
		link:"StringCondition",
		title:"StringCondition",
		timeFrame:"StatsWidgetTimeFrameCondition",
		layout:"StatsWidgetsLayoutWhere",
		icon:"ImageWhere",
		channels:"ChannelWhere",
		productCategory:"ProductCategoryWhere",
		paymentMethods:"PaymentMethodWhere",
		hoursFrom:"StringCondition",
		hoursTo:"StringCondition",
		and:"StatsWidgetWhere",
		or:"StatsWidgetWhere",
		not:"StatsWidgetWhere"
	},
	StatsEnabledWidgetTypeTypeCondition:{
		and:"StatsEnabledWidgetTypeTypeCondition",
		or:"StatsEnabledWidgetTypeTypeCondition",
		not:"StatsEnabledWidgetTypeTypeCondition",
		eq:"StatsEnabledWidgetTypeType",
		notEq:"StatsEnabledWidgetTypeType",
		in:"StatsEnabledWidgetTypeType",
		notIn:"StatsEnabledWidgetTypeType",
		lt:"StatsEnabledWidgetTypeType",
		lte:"StatsEnabledWidgetTypeType",
		gt:"StatsEnabledWidgetTypeType",
		gte:"StatsEnabledWidgetTypeType"
	},
	StatsWidgetTimeFrameCondition:{
		and:"StatsWidgetTimeFrameCondition",
		or:"StatsWidgetTimeFrameCondition",
		not:"StatsWidgetTimeFrameCondition",
		eq:"StatsWidgetTimeFrame",
		notEq:"StatsWidgetTimeFrame",
		in:"StatsWidgetTimeFrame",
		notIn:"StatsWidgetTimeFrame",
		lt:"StatsWidgetTimeFrame",
		lte:"StatsWidgetTimeFrame",
		gt:"StatsWidgetTimeFrame",
		gte:"StatsWidgetTimeFrame"
	},
	StatsWidgetsLayoutWhere:{
		id:"UUIDCondition",
		items:"StatsWidgetWhere",
		and:"StatsWidgetsLayoutWhere",
		or:"StatsWidgetsLayoutWhere",
		not:"StatsWidgetsLayoutWhere"
	},
	StatsWidgetOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection",
		link:"OrderDirection",
		title:"OrderDirection",
		timeFrame:"OrderDirection",
		layout:"StatsWidgetsLayoutOrderBy",
		icon:"ImageOrderBy",
		productCategory:"ProductCategoryOrderBy",
		hoursFrom:"OrderDirection",
		hoursTo:"OrderDirection"
	},
	StatsWidgetsLayoutOrderBy:{
		id:"OrderDirection"
	},
	StatsWidgetUniqueWhere:{
		id:"UUID"
	},
	StatsWidgetsLayoutUniqueWhere:{
		id:"UUID",
		items:"StatsWidgetUniqueWhere"
	},
	StatsPage:{
		widgets:{
			filter:"StatsWidgetsLayoutWhere"
		}
	},
	StatsPageType: true,
	StatsPageUniqueWhere:{
		id:"UUID",
		type:"StatsPageType",
		widgets:"StatsWidgetsLayoutUniqueWhere"
	},
	StatsPageWhere:{
		id:"UUIDCondition",
		type:"StatsPageTypeCondition",
		enabled:"BooleanCondition",
		widgets:"StatsWidgetsLayoutWhere",
		and:"StatsPageWhere",
		or:"StatsPageWhere",
		not:"StatsPageWhere"
	},
	StatsPageTypeCondition:{
		and:"StatsPageTypeCondition",
		or:"StatsPageTypeCondition",
		not:"StatsPageTypeCondition",
		eq:"StatsPageType",
		notEq:"StatsPageType",
		in:"StatsPageType",
		notIn:"StatsPageType",
		lt:"StatsPageType",
		lte:"StatsPageType",
		gt:"StatsPageType",
		gte:"StatsPageType"
	},
	StatsPageOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		enabled:"OrderDirection",
		widgets:"StatsWidgetsLayoutOrderBy"
	},
	OrderPaymentStatsByDay:{
		channel:{
			filter:"ChannelWhere"
		},
		paymentMethod:{
			filter:"PaymentMethodWhere"
		}
	},
	OrderPaymentStatsByDayUniqueWhere:{

	},
	OrderPaymentStatsByDayWhere:{
		id:"StringCondition",
		day:"DateCondition",
		totalGratuityCents:"IntCondition",
		reducedGratuityCents:"IntCondition",
		channel:"ChannelWhere",
		paymentMethod:"PaymentMethodWhere",
		and:"OrderPaymentStatsByDayWhere",
		or:"OrderPaymentStatsByDayWhere",
		not:"OrderPaymentStatsByDayWhere"
	},
	OrderPaymentStatsByDayOrderBy:{
		id:"OrderDirection",
		day:"OrderDirection",
		totalGratuityCents:"OrderDirection",
		reducedGratuityCents:"OrderDirection",
		channel:"ChannelOrderBy",
		paymentMethod:"PaymentMethodOrderBy"
	},
	QueryTransaction:{
		getAddress:{
			by:"AddressUniqueWhere",
			filter:"AddressWhere"
		},
		listAddress:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		paginateAddress:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		getCountry:{
			by:"CountryUniqueWhere",
			filter:"CountryWhere"
		},
		listCountry:{
			filter:"CountryWhere",
			orderBy:"CountryOrderBy"
		},
		paginateCountry:{
			filter:"CountryWhere",
			orderBy:"CountryOrderBy"
		},
		getChannel:{
			by:"ChannelUniqueWhere",
			filter:"ChannelWhere"
		},
		listChannel:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		paginateChannel:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		getCustomer:{
			by:"CustomerUniqueWhere",
			filter:"CustomerWhere"
		},
		listCustomer:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		paginateCustomer:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		getDeliveryMethod:{
			by:"DeliveryMethodUniqueWhere",
			filter:"DeliveryMethodWhere"
		},
		listDeliveryMethod:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		paginateDeliveryMethod:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		getOrder:{
			by:"OrderUniqueWhere",
			filter:"OrderWhere"
		},
		listOrder:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		paginateOrder:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		getOrderPayment:{
			by:"OrderPaymentUniqueWhere",
			filter:"OrderPaymentWhere"
		},
		listOrderPayment:{
			filter:"OrderPaymentWhere",
			orderBy:"OrderPaymentOrderBy"
		},
		paginateOrderPayment:{
			filter:"OrderPaymentWhere",
			orderBy:"OrderPaymentOrderBy"
		},
		getOrderDelivery:{
			by:"OrderDeliveryUniqueWhere",
			filter:"OrderDeliveryWhere"
		},
		listOrderDelivery:{
			filter:"OrderDeliveryWhere",
			orderBy:"OrderDeliveryOrderBy"
		},
		paginateOrderDelivery:{
			filter:"OrderDeliveryWhere",
			orderBy:"OrderDeliveryOrderBy"
		},
		getOrderItem:{
			by:"OrderItemUniqueWhere",
			filter:"OrderItemWhere"
		},
		listOrderItem:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		paginateOrderItem:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		getPaymentMethod:{
			by:"PaymentMethodUniqueWhere",
			filter:"PaymentMethodWhere"
		},
		listPaymentMethod:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		paginatePaymentMethod:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		getVatRate:{
			by:"VatRateUniqueWhere",
			filter:"VatRateWhere"
		},
		listVatRate:{
			filter:"VatRateWhere",
			orderBy:"VatRateOrderBy"
		},
		paginateVatRate:{
			filter:"VatRateWhere",
			orderBy:"VatRateOrderBy"
		},
		getProduct:{
			by:"ProductUniqueWhere",
			filter:"ProductWhere"
		},
		listProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		getProductStock:{
			by:"ProductStockUniqueWhere",
			filter:"ProductStockWhere"
		},
		listProductStock:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		paginateProductStock:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		getProductPacking:{
			by:"ProductPackingUniqueWhere",
			filter:"ProductPackingWhere"
		},
		listProductPacking:{
			filter:"ProductPackingWhere",
			orderBy:"ProductPackingOrderBy"
		},
		paginateProductPacking:{
			filter:"ProductPackingWhere",
			orderBy:"ProductPackingOrderBy"
		},
		getProductRecipe:{
			by:"ProductRecipeUniqueWhere",
			filter:"ProductRecipeWhere"
		},
		listProductRecipe:{
			filter:"ProductRecipeWhere",
			orderBy:"ProductRecipeOrderBy"
		},
		paginateProductRecipe:{
			filter:"ProductRecipeWhere",
			orderBy:"ProductRecipeOrderBy"
		},
		getProductCategory:{
			by:"ProductCategoryUniqueWhere",
			filter:"ProductCategoryWhere"
		},
		listProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		paginateProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		getStore:{
			by:"StoreUniqueWhere",
			filter:"StoreWhere"
		},
		listStore:{
			filter:"StoreWhere",
			orderBy:"StoreOrderBy"
		},
		paginateStore:{
			filter:"StoreWhere",
			orderBy:"StoreOrderBy"
		},
		getTag:{
			by:"TagUniqueWhere",
			filter:"TagWhere"
		},
		listTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		getImage:{
			by:"ImageUniqueWhere",
			filter:"ImageWhere"
		},
		listImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		paginateImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		getBusinessCategory:{
			by:"BusinessCategoryUniqueWhere",
			filter:"BusinessCategoryWhere"
		},
		listBusinessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		},
		paginateBusinessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		},
		getProductList:{
			by:"ProductListUniqueWhere",
			filter:"ProductListWhere"
		},
		listProductList:{
			filter:"ProductListWhere",
			orderBy:"ProductListOrderBy"
		},
		paginateProductList:{
			filter:"ProductListWhere",
			orderBy:"ProductListOrderBy"
		},
		getProductListItem:{
			by:"ProductListItemUniqueWhere",
			filter:"ProductListItemWhere"
		},
		listProductListItem:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		},
		paginateProductListItem:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		},
		getProductCategoryList:{
			by:"ProductCategoryListUniqueWhere",
			filter:"ProductCategoryListWhere"
		},
		listProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		paginateProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		getProductCategoryListItem:{
			by:"ProductCategoryListItemUniqueWhere",
			filter:"ProductCategoryListItemWhere"
		},
		listProductCategoryListItem:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		paginateProductCategoryListItem:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		getBusinessCategoryLocale:{
			by:"BusinessCategoryLocaleUniqueWhere",
			filter:"BusinessCategoryLocaleWhere"
		},
		listBusinessCategoryLocale:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		paginateBusinessCategoryLocale:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		getLocale:{
			by:"LocaleUniqueWhere",
			filter:"LocaleWhere"
		},
		listLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		paginateLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		getProductCategoryListLocale:{
			by:"ProductCategoryListLocaleUniqueWhere",
			filter:"ProductCategoryListLocaleWhere"
		},
		listProductCategoryListLocale:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		paginateProductCategoryListLocale:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		getCustomerName:{
			by:"CustomerNameUniqueWhere",
			filter:"CustomerNameWhere"
		},
		listCustomerName:{
			filter:"CustomerNameWhere",
			orderBy:"CustomerNameOrderBy"
		},
		paginateCustomerName:{
			filter:"CustomerNameWhere",
			orderBy:"CustomerNameOrderBy"
		},
		getProductIngredientItem:{
			by:"ProductIngredientItemUniqueWhere",
			filter:"ProductIngredientItemWhere"
		},
		listProductIngredientItem:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		paginateProductIngredientItem:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		getProductIngredientItemQuantity:{
			by:"ProductIngredientItemQuantityUniqueWhere",
			filter:"ProductIngredientItemQuantityWhere"
		},
		listProductIngredientItemQuantity:{
			filter:"ProductIngredientItemQuantityWhere",
			orderBy:"ProductIngredientItemQuantityOrderBy"
		},
		paginateProductIngredientItemQuantity:{
			filter:"ProductIngredientItemQuantityWhere",
			orderBy:"ProductIngredientItemQuantityOrderBy"
		},
		getProductIngredientItemQuantityUnit:{
			by:"ProductIngredientItemQuantityUnitUniqueWhere",
			filter:"ProductIngredientItemQuantityUnitWhere"
		},
		listProductIngredientItemQuantityUnit:{
			filter:"ProductIngredientItemQuantityUnitWhere",
			orderBy:"ProductIngredientItemQuantityUnitOrderBy"
		},
		paginateProductIngredientItemQuantityUnit:{
			filter:"ProductIngredientItemQuantityUnitWhere",
			orderBy:"ProductIngredientItemQuantityUnitOrderBy"
		},
		getProductIngredient:{
			by:"ProductIngredientUniqueWhere",
			filter:"ProductIngredientWhere"
		},
		listProductIngredient:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		paginateProductIngredient:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		getProductIngredientRating:{
			by:"ProductIngredientRatingUniqueWhere",
			filter:"ProductIngredientRatingWhere"
		},
		listProductIngredientRating:{
			filter:"ProductIngredientRatingWhere",
			orderBy:"ProductIngredientRatingOrderBy"
		},
		paginateProductIngredientRating:{
			filter:"ProductIngredientRatingWhere",
			orderBy:"ProductIngredientRatingOrderBy"
		},
		getProductIngredientLocale:{
			by:"ProductIngredientLocaleUniqueWhere",
			filter:"ProductIngredientLocaleWhere"
		},
		listProductIngredientLocale:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		paginateProductIngredientLocale:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		getOrderRecurrence:{
			by:"OrderRecurrenceUniqueWhere",
			filter:"OrderRecurrenceWhere"
		},
		listOrderRecurrence:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		},
		paginateOrderRecurrence:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		},
		getOrderRecurrenceLocale:{
			by:"OrderRecurrenceLocaleUniqueWhere",
			filter:"OrderRecurrenceLocaleWhere"
		},
		listOrderRecurrenceLocale:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		paginateOrderRecurrenceLocale:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		getStaff:{
			by:"StaffUniqueWhere",
			filter:"StaffWhere"
		},
		listStaff:{
			filter:"StaffWhere",
			orderBy:"StaffOrderBy"
		},
		paginateStaff:{
			filter:"StaffWhere",
			orderBy:"StaffOrderBy"
		},
		getBlog:{
			by:"BlogUniqueWhere",
			filter:"BlogWhere"
		},
		listBlog:{
			filter:"BlogWhere",
			orderBy:"BlogOrderBy"
		},
		paginateBlog:{
			filter:"BlogWhere",
			orderBy:"BlogOrderBy"
		},
		getBlogLocale:{
			by:"BlogLocaleUniqueWhere",
			filter:"BlogLocaleWhere"
		},
		listBlogLocale:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		paginateBlogLocale:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		getBlogPost:{
			by:"BlogPostUniqueWhere",
			filter:"BlogPostWhere"
		},
		listBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		paginateBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		getBlogPostLocale:{
			by:"BlogPostLocaleUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		listBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		paginateBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		getColorPallete:{
			by:"ColorPalleteUniqueWhere",
			filter:"ColorPalleteWhere"
		},
		listColorPallete:{
			filter:"ColorPalleteWhere",
			orderBy:"ColorPalleteOrderBy"
		},
		paginateColorPallete:{
			filter:"ColorPalleteWhere",
			orderBy:"ColorPalleteOrderBy"
		},
		getContent:{
			by:"ContentUniqueWhere",
			filter:"ContentWhere"
		},
		listContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		paginateContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		getContentBlock:{
			by:"ContentBlockUniqueWhere",
			filter:"ContentBlockWhere"
		},
		listContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		paginateContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		getContentReference:{
			by:"ContentReferenceUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		listContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		paginateContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		getBlogPostList:{
			by:"BlogPostListUniqueWhere",
			filter:"BlogPostListWhere"
		},
		listBlogPostList:{
			filter:"BlogPostListWhere",
			orderBy:"BlogPostListOrderBy"
		},
		paginateBlogPostList:{
			filter:"BlogPostListWhere",
			orderBy:"BlogPostListOrderBy"
		},
		getBlogPostListItem:{
			by:"BlogPostListItemUniqueWhere",
			filter:"BlogPostListItemWhere"
		},
		listBlogPostListItem:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		},
		paginateBlogPostListItem:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		},
		getLinkList:{
			by:"LinkListUniqueWhere",
			filter:"LinkListWhere"
		},
		listLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		paginateLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		getLinkListItem:{
			by:"LinkListItemUniqueWhere",
			filter:"LinkListItemWhere"
		},
		listLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		paginateLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		getCover:{
			by:"CoverUniqueWhere",
			filter:"CoverWhere"
		},
		listCover:{
			filter:"CoverWhere",
			orderBy:"CoverOrderBy"
		},
		paginateCover:{
			filter:"CoverWhere",
			orderBy:"CoverOrderBy"
		},
		getLink:{
			by:"LinkUniqueWhere",
			filter:"LinkWhere"
		},
		listLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		paginateLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		getLinkable:{
			by:"LinkableUniqueWhere",
			filter:"LinkableWhere"
		},
		listLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		paginateLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		getMedium:{
			by:"MediumUniqueWhere",
			filter:"MediumWhere"
		},
		listMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		paginateMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		getVideo:{
			by:"VideoUniqueWhere",
			filter:"VideoWhere"
		},
		listVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		paginateVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		getSeo:{
			by:"SeoUniqueWhere",
			filter:"SeoWhere"
		},
		listSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		paginateSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		getTranslationDomain:{
			by:"TranslationDomainUniqueWhere",
			filter:"TranslationDomainWhere"
		},
		listTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		paginateTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		getTranslationCatalogue:{
			by:"TranslationCatalogueUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		listTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		paginateTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		getTranslationKey:{
			by:"TranslationKeyUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		listTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		paginateTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		getTranslationValue:{
			by:"TranslationValueUniqueWhere",
			filter:"TranslationValueWhere"
		},
		listTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		paginateTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		getTranslationCataloguesIdentifier:{
			by:"TranslationCataloguesIdentifierUniqueWhere",
			filter:"TranslationCataloguesIdentifierWhere"
		},
		listTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		paginateTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		getSocialsAndApps:{
			by:"SocialsAndAppsUniqueWhere",
			filter:"SocialsAndAppsWhere"
		},
		listSocialsAndApps:{
			filter:"SocialsAndAppsWhere",
			orderBy:"SocialsAndAppsOrderBy"
		},
		paginateSocialsAndApps:{
			filter:"SocialsAndAppsWhere",
			orderBy:"SocialsAndAppsOrderBy"
		},
		getProductGrid:{
			by:"ProductGridUniqueWhere",
			filter:"ProductGridWhere"
		},
		listProductGrid:{
			filter:"ProductGridWhere",
			orderBy:"ProductGridOrderBy"
		},
		paginateProductGrid:{
			filter:"ProductGridWhere",
			orderBy:"ProductGridOrderBy"
		},
		getProductGridItem:{
			by:"ProductGridItemUniqueWhere",
			filter:"ProductGridItemWhere"
		},
		listProductGridItem:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		},
		paginateProductGridItem:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		},
		getGallery:{
			by:"GalleryUniqueWhere",
			filter:"GalleryWhere"
		},
		listGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		paginateGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		getGalleryItem:{
			by:"GalleryItemUniqueWhere",
			filter:"GalleryItemWhere"
		},
		listGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		paginateGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		getHero:{
			by:"HeroUniqueWhere",
			filter:"HeroWhere"
		},
		listHero:{
			filter:"HeroWhere",
			orderBy:"HeroOrderBy"
		},
		paginateHero:{
			filter:"HeroWhere",
			orderBy:"HeroOrderBy"
		},
		getPage:{
			by:"PageUniqueWhere",
			filter:"PageWhere"
		},
		listPage:{
			filter:"PageWhere",
			orderBy:"PageOrderBy"
		},
		paginatePage:{
			filter:"PageWhere",
			orderBy:"PageOrderBy"
		},
		getPageLocale:{
			by:"PageLocaleUniqueWhere",
			filter:"PageLocaleWhere"
		},
		listPageLocale:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		paginatePageLocale:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		getDeadlineTemplate:{
			by:"DeadlineTemplateUniqueWhere",
			filter:"DeadlineTemplateWhere"
		},
		listDeadlineTemplate:{
			filter:"DeadlineTemplateWhere",
			orderBy:"DeadlineTemplateOrderBy"
		},
		paginateDeadlineTemplate:{
			filter:"DeadlineTemplateWhere",
			orderBy:"DeadlineTemplateOrderBy"
		},
		getEmployeeQueue:{
			by:"EmployeeQueueUniqueWhere",
			filter:"EmployeeQueueWhere"
		},
		listEmployeeQueue:{
			filter:"EmployeeQueueWhere",
			orderBy:"EmployeeQueueOrderBy"
		},
		paginateEmployeeQueue:{
			filter:"EmployeeQueueWhere",
			orderBy:"EmployeeQueueOrderBy"
		},
		validateCreateEmployeeQueue:{
			data:"EmployeeQueueCreateInput"
		},
		validateUpdateEmployeeQueue:{
			by:"EmployeeQueueUniqueWhere",
			data:"EmployeeQueueUpdateInput"
		},
		getTimetableDay:{
			by:"TimetableDayUniqueWhere",
			filter:"TimetableDayWhere"
		},
		listTimetableDay:{
			filter:"TimetableDayWhere",
			orderBy:"TimetableDayOrderBy"
		},
		paginateTimetableDay:{
			filter:"TimetableDayWhere",
			orderBy:"TimetableDayOrderBy"
		},
		getTimetableEmployeeRole:{
			by:"TimetableEmployeeRoleUniqueWhere",
			filter:"TimetableEmployeeRoleWhere"
		},
		listTimetableEmployeeRole:{
			filter:"TimetableEmployeeRoleWhere",
			orderBy:"TimetableEmployeeRoleOrderBy"
		},
		paginateTimetableEmployeeRole:{
			filter:"TimetableEmployeeRoleWhere",
			orderBy:"TimetableEmployeeRoleOrderBy"
		},
		getTimetableShift:{
			by:"TimetableShiftUniqueWhere",
			filter:"TimetableShiftWhere"
		},
		listTimetableShift:{
			filter:"TimetableShiftWhere",
			orderBy:"TimetableShiftOrderBy"
		},
		paginateTimetableShift:{
			filter:"TimetableShiftWhere",
			orderBy:"TimetableShiftOrderBy"
		},
		validateUpdateTimetableShift:{
			by:"TimetableShiftUniqueWhere",
			data:"TimetableShiftUpdateInput"
		},
		getTimetableShiftGroup:{
			by:"TimetableShiftGroupUniqueWhere",
			filter:"TimetableShiftGroupWhere"
		},
		listTimetableShiftGroup:{
			filter:"TimetableShiftGroupWhere",
			orderBy:"TimetableShiftGroupOrderBy"
		},
		paginateTimetableShiftGroup:{
			filter:"TimetableShiftGroupWhere",
			orderBy:"TimetableShiftGroupOrderBy"
		},
		getTimetableShiftPosition:{
			by:"TimetableShiftPositionUniqueWhere",
			filter:"TimetableShiftPositionWhere"
		},
		listTimetableShiftPosition:{
			filter:"TimetableShiftPositionWhere",
			orderBy:"TimetableShiftPositionOrderBy"
		},
		paginateTimetableShiftPosition:{
			filter:"TimetableShiftPositionWhere",
			orderBy:"TimetableShiftPositionOrderBy"
		},
		getTimetableTemplate:{
			by:"TimetableTemplateUniqueWhere",
			filter:"TimetableTemplateWhere"
		},
		listTimetableTemplate:{
			filter:"TimetableTemplateWhere",
			orderBy:"TimetableTemplateOrderBy"
		},
		paginateTimetableTemplate:{
			filter:"TimetableTemplateWhere",
			orderBy:"TimetableTemplateOrderBy"
		},
		getEmployeeQueueItem:{
			by:"EmployeeQueueItemUniqueWhere",
			filter:"EmployeeQueueItemWhere"
		},
		listEmployeeQueueItem:{
			filter:"EmployeeQueueItemWhere",
			orderBy:"EmployeeQueueItemOrderBy"
		},
		paginateEmployeeQueueItem:{
			filter:"EmployeeQueueItemWhere",
			orderBy:"EmployeeQueueItemOrderBy"
		},
		validateCreateEmployeeQueueItem:{
			data:"EmployeeQueueItemCreateInput"
		},
		validateUpdateEmployeeQueueItem:{
			by:"EmployeeQueueItemUniqueWhere",
			data:"EmployeeQueueItemUpdateInput"
		},
		getProductBanner:{
			by:"ProductBannerUniqueWhere",
			filter:"ProductBannerWhere"
		},
		listProductBanner:{
			filter:"ProductBannerWhere",
			orderBy:"ProductBannerOrderBy"
		},
		paginateProductBanner:{
			filter:"ProductBannerWhere",
			orderBy:"ProductBannerOrderBy"
		},
		getProductBannerItem:{
			by:"ProductBannerItemUniqueWhere",
			filter:"ProductBannerItemWhere"
		},
		listProductBannerItem:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		},
		paginateProductBannerItem:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		},
		getProductBannerList:{
			by:"ProductBannerListUniqueWhere",
			filter:"ProductBannerListWhere"
		},
		listProductBannerList:{
			filter:"ProductBannerListWhere",
			orderBy:"ProductBannerListOrderBy"
		},
		paginateProductBannerList:{
			filter:"ProductBannerListWhere",
			orderBy:"ProductBannerListOrderBy"
		},
		getShiftsProfile:{
			by:"ShiftsProfileUniqueWhere",
			filter:"ShiftsProfileWhere"
		},
		listShiftsProfile:{
			filter:"ShiftsProfileWhere",
			orderBy:"ShiftsProfileOrderBy"
		},
		paginateShiftsProfile:{
			filter:"ShiftsProfileWhere",
			orderBy:"ShiftsProfileOrderBy"
		},
		validateUpdateShiftsProfile:{
			by:"ShiftsProfileUniqueWhere",
			data:"ShiftsProfileUpdateInput"
		},
		getProductIngredientCategory:{
			by:"ProductIngredientCategoryUniqueWhere",
			filter:"ProductIngredientCategoryWhere"
		},
		listProductIngredientCategory:{
			filter:"ProductIngredientCategoryWhere",
			orderBy:"ProductIngredientCategoryOrderBy"
		},
		paginateProductIngredientCategory:{
			filter:"ProductIngredientCategoryWhere",
			orderBy:"ProductIngredientCategoryOrderBy"
		},
		getProductIngredientCategoryLocale:{
			by:"ProductIngredientCategoryLocaleUniqueWhere",
			filter:"ProductIngredientCategoryLocaleWhere"
		},
		listProductIngredientCategoryLocale:{
			filter:"ProductIngredientCategoryLocaleWhere",
			orderBy:"ProductIngredientCategoryLocaleOrderBy"
		},
		paginateProductIngredientCategoryLocale:{
			filter:"ProductIngredientCategoryLocaleWhere",
			orderBy:"ProductIngredientCategoryLocaleOrderBy"
		},
		getDeliveryRoute:{
			by:"DeliveryRouteUniqueWhere",
			filter:"DeliveryRouteWhere"
		},
		listDeliveryRoute:{
			filter:"DeliveryRouteWhere",
			orderBy:"DeliveryRouteOrderBy"
		},
		paginateDeliveryRoute:{
			filter:"DeliveryRouteWhere",
			orderBy:"DeliveryRouteOrderBy"
		},
		getDeliveryWaypoint:{
			by:"DeliveryWaypointUniqueWhere",
			filter:"DeliveryWaypointWhere"
		},
		listDeliveryWaypoint:{
			filter:"DeliveryWaypointWhere",
			orderBy:"DeliveryWaypointOrderBy"
		},
		paginateDeliveryWaypoint:{
			filter:"DeliveryWaypointWhere",
			orderBy:"DeliveryWaypointOrderBy"
		},
		getAllergen:{
			by:"AllergenUniqueWhere",
			filter:"AllergenWhere"
		},
		listAllergen:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		paginateAllergen:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		getAllergenLocale:{
			by:"AllergenLocaleUniqueWhere",
			filter:"AllergenLocaleWhere"
		},
		listAllergenLocale:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		paginateAllergenLocale:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		getWeekHours:{
			by:"WeekHoursUniqueWhere",
			filter:"WeekHoursWhere"
		},
		listWeekHours:{
			filter:"WeekHoursWhere",
			orderBy:"WeekHoursOrderBy"
		},
		paginateWeekHours:{
			filter:"WeekHoursWhere",
			orderBy:"WeekHoursOrderBy"
		},
		getPreparedIngredientQuantity:{
			by:"PreparedIngredientQuantityUniqueWhere",
			filter:"PreparedIngredientQuantityWhere"
		},
		listPreparedIngredientQuantity:{
			filter:"PreparedIngredientQuantityWhere",
			orderBy:"PreparedIngredientQuantityOrderBy"
		},
		paginatePreparedIngredientQuantity:{
			filter:"PreparedIngredientQuantityWhere",
			orderBy:"PreparedIngredientQuantityOrderBy"
		},
		validateCreatePreparedIngredientQuantity:{
			data:"PreparedIngredientQuantityCreateInput"
		},
		validateUpdatePreparedIngredientQuantity:{
			by:"PreparedIngredientQuantityUniqueWhere",
			data:"PreparedIngredientQuantityUpdateInput"
		},
		getPreparedRecipeQuantity:{
			by:"PreparedRecipeQuantityUniqueWhere",
			filter:"PreparedRecipeQuantityWhere"
		},
		listPreparedRecipeQuantity:{
			filter:"PreparedRecipeQuantityWhere",
			orderBy:"PreparedRecipeQuantityOrderBy"
		},
		paginatePreparedRecipeQuantity:{
			filter:"PreparedRecipeQuantityWhere",
			orderBy:"PreparedRecipeQuantityOrderBy"
		},
		validateCreatePreparedRecipeQuantity:{
			data:"PreparedRecipeQuantityCreateInput"
		},
		validateUpdatePreparedRecipeQuantity:{
			by:"PreparedRecipeQuantityUniqueWhere",
			data:"PreparedRecipeQuantityUpdateInput"
		},
		getPreparedPackingQuantity:{
			by:"PreparedPackingQuantityUniqueWhere",
			filter:"PreparedPackingQuantityWhere"
		},
		listPreparedPackingQuantity:{
			filter:"PreparedPackingQuantityWhere",
			orderBy:"PreparedPackingQuantityOrderBy"
		},
		paginatePreparedPackingQuantity:{
			filter:"PreparedPackingQuantityWhere",
			orderBy:"PreparedPackingQuantityOrderBy"
		},
		validateCreatePreparedPackingQuantity:{
			data:"PreparedPackingQuantityCreateInput"
		},
		validateUpdatePreparedPackingQuantity:{
			by:"PreparedPackingQuantityUniqueWhere",
			data:"PreparedPackingQuantityUpdateInput"
		},
		getDeliveryZone:{
			by:"DeliveryZoneUniqueWhere",
			filter:"DeliveryZoneWhere"
		},
		listDeliveryZone:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		paginateDeliveryZone:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		getDeliveryTimelinePreset:{
			by:"DeliveryTimelinePresetUniqueWhere",
			filter:"DeliveryTimelinePresetWhere"
		},
		listDeliveryTimelinePreset:{
			filter:"DeliveryTimelinePresetWhere",
			orderBy:"DeliveryTimelinePresetOrderBy"
		},
		paginateDeliveryTimelinePreset:{
			filter:"DeliveryTimelinePresetWhere",
			orderBy:"DeliveryTimelinePresetOrderBy"
		},
		getProductPackingLocale:{
			by:"ProductPackingLocaleUniqueWhere",
			filter:"ProductPackingLocaleWhere"
		},
		listProductPackingLocale:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		paginateProductPackingLocale:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		getProductLocale:{
			by:"ProductLocaleUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		listProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		paginateProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		getProductRecipeLocale:{
			by:"ProductRecipeLocaleUniqueWhere",
			filter:"ProductRecipeLocaleWhere"
		},
		listProductRecipeLocale:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		paginateProductRecipeLocale:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		getSuppliesPurchase:{
			by:"SuppliesPurchaseUniqueWhere",
			filter:"SuppliesPurchaseWhere"
		},
		listSuppliesPurchase:{
			filter:"SuppliesPurchaseWhere",
			orderBy:"SuppliesPurchaseOrderBy"
		},
		paginateSuppliesPurchase:{
			filter:"SuppliesPurchaseWhere",
			orderBy:"SuppliesPurchaseOrderBy"
		},
		validateCreateSuppliesPurchase:{
			data:"SuppliesPurchaseCreateInput"
		},
		validateUpdateSuppliesPurchase:{
			by:"SuppliesPurchaseUniqueWhere",
			data:"SuppliesPurchaseUpdateInput"
		},
		getLogRecord:{
			by:"LogRecordUniqueWhere",
			filter:"LogRecordWhere"
		},
		listLogRecord:{
			filter:"LogRecordWhere",
			orderBy:"LogRecordOrderBy"
		},
		paginateLogRecord:{
			filter:"LogRecordWhere",
			orderBy:"LogRecordOrderBy"
		},
		getSuppliesPurchaseItem:{
			by:"SuppliesPurchaseItemUniqueWhere",
			filter:"SuppliesPurchaseItemWhere"
		},
		listSuppliesPurchaseItem:{
			filter:"SuppliesPurchaseItemWhere",
			orderBy:"SuppliesPurchaseItemOrderBy"
		},
		paginateSuppliesPurchaseItem:{
			filter:"SuppliesPurchaseItemWhere",
			orderBy:"SuppliesPurchaseItemOrderBy"
		},
		validateCreateSuppliesPurchaseItem:{
			data:"SuppliesPurchaseItemCreateInput"
		},
		validateUpdateSuppliesPurchaseItem:{
			by:"SuppliesPurchaseItemUniqueWhere",
			data:"SuppliesPurchaseItemUpdateInput"
		},
		getVirtualProduct:{
			by:"VirtualProductUniqueWhere",
			filter:"VirtualProductWhere"
		},
		listVirtualProduct:{
			filter:"VirtualProductWhere",
			orderBy:"VirtualProductOrderBy"
		},
		paginateVirtualProduct:{
			filter:"VirtualProductWhere",
			orderBy:"VirtualProductOrderBy"
		},
		getProductMetadata:{
			by:"ProductMetadataUniqueWhere",
			filter:"ProductMetadataWhere"
		},
		listProductMetadata:{
			filter:"ProductMetadataWhere",
			orderBy:"ProductMetadataOrderBy"
		},
		paginateProductMetadata:{
			filter:"ProductMetadataWhere",
			orderBy:"ProductMetadataOrderBy"
		},
		getProductGridCategory:{
			by:"ProductGridCategoryUniqueWhere",
			filter:"ProductGridCategoryWhere"
		},
		listProductGridCategory:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		},
		paginateProductGridCategory:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		},
		getUnassignedShiftInfo:{
			by:"UnassignedShiftInfoUniqueWhere",
			filter:"UnassignedShiftInfoWhere"
		},
		listUnassignedShiftInfo:{
			filter:"UnassignedShiftInfoWhere",
			orderBy:"UnassignedShiftInfoOrderBy"
		},
		paginateUnassignedShiftInfo:{
			filter:"UnassignedShiftInfoWhere",
			orderBy:"UnassignedShiftInfoOrderBy"
		},
		validateCreateUnassignedShiftInfo:{
			data:"UnassignedShiftInfoCreateInput"
		},
		validateUpdateUnassignedShiftInfo:{
			by:"UnassignedShiftInfoUniqueWhere",
			data:"UnassignedShiftInfoUpdateInput"
		},
		getFreshingContainer:{
			by:"FreshingContainerUniqueWhere",
			filter:"FreshingContainerWhere"
		},
		listFreshingContainer:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		},
		paginateFreshingContainer:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		},
		getTextItem:{
			by:"TextItemUniqueWhere",
			filter:"TextItemWhere"
		},
		listTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		paginateTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		getTextList:{
			by:"TextListUniqueWhere",
			filter:"TextListWhere"
		},
		listTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		paginateTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		getDeliveryRegions:{
			by:"DeliveryRegionsUniqueWhere",
			filter:"DeliveryRegionsWhere"
		},
		listDeliveryRegions:{
			filter:"DeliveryRegionsWhere",
			orderBy:"DeliveryRegionsOrderBy"
		},
		paginateDeliveryRegions:{
			filter:"DeliveryRegionsWhere",
			orderBy:"DeliveryRegionsOrderBy"
		},
		getDeliveryRegionsItem:{
			by:"DeliveryRegionsItemUniqueWhere",
			filter:"DeliveryRegionsItemWhere"
		},
		listDeliveryRegionsItem:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		},
		paginateDeliveryRegionsItem:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		},
		getImageList:{
			by:"ImageListUniqueWhere",
			filter:"ImageListWhere"
		},
		listImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		paginateImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		getImageListItem:{
			by:"ImageListItemUniqueWhere",
			filter:"ImageListItemWhere"
		},
		listImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		paginateImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		getTimetablesEmployeePrivileges:{
			by:"TimetablesEmployeePrivilegesUniqueWhere",
			filter:"TimetablesEmployeePrivilegesWhere"
		},
		listTimetablesEmployeePrivileges:{
			filter:"TimetablesEmployeePrivilegesWhere",
			orderBy:"TimetablesEmployeePrivilegesOrderBy"
		},
		paginateTimetablesEmployeePrivileges:{
			filter:"TimetablesEmployeePrivilegesWhere",
			orderBy:"TimetablesEmployeePrivilegesOrderBy"
		},
		getTimetableDayNote:{
			by:"TimetableDayNoteUniqueWhere",
			filter:"TimetableDayNoteWhere"
		},
		listTimetableDayNote:{
			filter:"TimetableDayNoteWhere",
			orderBy:"TimetableDayNoteOrderBy"
		},
		paginateTimetableDayNote:{
			filter:"TimetableDayNoteWhere",
			orderBy:"TimetableDayNoteOrderBy"
		},
		getDeliveryMethodLocale:{
			by:"DeliveryMethodLocaleUniqueWhere",
			filter:"DeliveryMethodLocaleWhere"
		},
		listDeliveryMethodLocale:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		paginateDeliveryMethodLocale:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		getIngredientSupplier:{
			by:"IngredientSupplierUniqueWhere",
			filter:"IngredientSupplierWhere"
		},
		listIngredientSupplier:{
			filter:"IngredientSupplierWhere",
			orderBy:"IngredientSupplierOrderBy"
		},
		paginateIngredientSupplier:{
			filter:"IngredientSupplierWhere",
			orderBy:"IngredientSupplierOrderBy"
		},
		getIngredientSupplierItem:{
			by:"IngredientSupplierItemUniqueWhere",
			filter:"IngredientSupplierItemWhere"
		},
		listIngredientSupplierItem:{
			filter:"IngredientSupplierItemWhere",
			orderBy:"IngredientSupplierItemOrderBy"
		},
		paginateIngredientSupplierItem:{
			filter:"IngredientSupplierItemWhere",
			orderBy:"IngredientSupplierItemOrderBy"
		},
		getIngredientSupplierList:{
			by:"IngredientSupplierListUniqueWhere",
			filter:"IngredientSupplierListWhere"
		},
		listIngredientSupplierList:{
			filter:"IngredientSupplierListWhere",
			orderBy:"IngredientSupplierListOrderBy"
		},
		paginateIngredientSupplierList:{
			filter:"IngredientSupplierListWhere",
			orderBy:"IngredientSupplierListOrderBy"
		},
		getIngredientSupplierLocale:{
			by:"IngredientSupplierLocaleUniqueWhere",
			filter:"IngredientSupplierLocaleWhere"
		},
		listIngredientSupplierLocale:{
			filter:"IngredientSupplierLocaleWhere",
			orderBy:"IngredientSupplierLocaleOrderBy"
		},
		paginateIngredientSupplierLocale:{
			filter:"IngredientSupplierLocaleWhere",
			orderBy:"IngredientSupplierLocaleOrderBy"
		},
		getRedirect:{
			by:"RedirectUniqueWhere",
			filter:"RedirectWhere"
		},
		listRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		paginateRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		getVenue:{
			by:"VenueUniqueWhere",
			filter:"VenueWhere"
		},
		listVenue:{
			filter:"VenueWhere",
			orderBy:"VenueOrderBy"
		},
		paginateVenue:{
			filter:"VenueWhere",
			orderBy:"VenueOrderBy"
		},
		getWorkspace:{
			by:"WorkspaceUniqueWhere",
			filter:"WorkspaceWhere"
		},
		listWorkspace:{
			filter:"WorkspaceWhere",
			orderBy:"WorkspaceOrderBy"
		},
		paginateWorkspace:{
			filter:"WorkspaceWhere",
			orderBy:"WorkspaceOrderBy"
		},
		getVenueEmployeeRole:{
			by:"VenueEmployeeRoleUniqueWhere",
			filter:"VenueEmployeeRoleWhere"
		},
		listVenueEmployeeRole:{
			filter:"VenueEmployeeRoleWhere",
			orderBy:"VenueEmployeeRoleOrderBy"
		},
		paginateVenueEmployeeRole:{
			filter:"VenueEmployeeRoleWhere",
			orderBy:"VenueEmployeeRoleOrderBy"
		},
		getIdentificationAttempt:{
			by:"IdentificationAttemptUniqueWhere",
			filter:"IdentificationAttemptWhere"
		},
		listIdentificationAttempt:{
			filter:"IdentificationAttemptWhere",
			orderBy:"IdentificationAttemptOrderBy"
		},
		paginateIdentificationAttempt:{
			filter:"IdentificationAttemptWhere",
			orderBy:"IdentificationAttemptOrderBy"
		},
		getIdentificationCode:{
			by:"IdentificationCodeUniqueWhere",
			filter:"IdentificationCodeWhere"
		},
		listIdentificationCode:{
			filter:"IdentificationCodeWhere",
			orderBy:"IdentificationCodeOrderBy"
		},
		paginateIdentificationCode:{
			filter:"IdentificationCodeWhere",
			orderBy:"IdentificationCodeOrderBy"
		},
		getIdentificationCodeLocale:{
			by:"IdentificationCodeLocaleUniqueWhere",
			filter:"IdentificationCodeLocaleWhere"
		},
		listIdentificationCodeLocale:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		},
		paginateIdentificationCodeLocale:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		},
		getDeviceIdentifier:{
			by:"DeviceIdentifierUniqueWhere",
			filter:"DeviceIdentifierWhere"
		},
		listDeviceIdentifier:{
			filter:"DeviceIdentifierWhere",
			orderBy:"DeviceIdentifierOrderBy"
		},
		paginateDeviceIdentifier:{
			filter:"DeviceIdentifierWhere",
			orderBy:"DeviceIdentifierOrderBy"
		},
		validateCreateDeviceIdentifier:{
			data:"DeviceIdentifierCreateInput"
		},
		validateUpdateDeviceIdentifier:{
			by:"DeviceIdentifierUniqueWhere",
			data:"DeviceIdentifierUpdateInput"
		},
		getDeviceIdentifierMetadata:{
			by:"DeviceIdentifierMetadataUniqueWhere",
			filter:"DeviceIdentifierMetadataWhere"
		},
		listDeviceIdentifierMetadata:{
			filter:"DeviceIdentifierMetadataWhere",
			orderBy:"DeviceIdentifierMetadataOrderBy"
		},
		paginateDeviceIdentifierMetadata:{
			filter:"DeviceIdentifierMetadataWhere",
			orderBy:"DeviceIdentifierMetadataOrderBy"
		},
		getFulfillmentNote:{
			by:"FulfillmentNoteUniqueWhere",
			filter:"FulfillmentNoteWhere"
		},
		listFulfillmentNote:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		paginateFulfillmentNote:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		getCustomerMetadata:{
			by:"CustomerMetadataUniqueWhere",
			filter:"CustomerMetadataWhere"
		},
		listCustomerMetadata:{
			filter:"CustomerMetadataWhere",
			orderBy:"CustomerMetadataOrderBy"
		},
		paginateCustomerMetadata:{
			filter:"CustomerMetadataWhere",
			orderBy:"CustomerMetadataOrderBy"
		},
		getSubscriptionBox:{
			by:"SubscriptionBoxUniqueWhere",
			filter:"SubscriptionBoxWhere"
		},
		listSubscriptionBox:{
			filter:"SubscriptionBoxWhere",
			orderBy:"SubscriptionBoxOrderBy"
		},
		paginateSubscriptionBox:{
			filter:"SubscriptionBoxWhere",
			orderBy:"SubscriptionBoxOrderBy"
		},
		getSubscriptionBoxLocale:{
			by:"SubscriptionBoxLocaleUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		listSubscriptionBoxLocale:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		},
		paginateSubscriptionBoxLocale:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		},
		getTimetableDayMetadata:{
			by:"TimetableDayMetadataUniqueWhere",
			filter:"TimetableDayMetadataWhere"
		},
		listTimetableDayMetadata:{
			filter:"TimetableDayMetadataWhere",
			orderBy:"TimetableDayMetadataOrderBy"
		},
		paginateTimetableDayMetadata:{
			filter:"TimetableDayMetadataWhere",
			orderBy:"TimetableDayMetadataOrderBy"
		},
		getGridTileItem:{
			by:"GridTileItemUniqueWhere",
			filter:"GridTileItemWhere"
		},
		listGridTileItem:{
			filter:"GridTileItemWhere",
			orderBy:"GridTileItemOrderBy"
		},
		paginateGridTileItem:{
			filter:"GridTileItemWhere",
			orderBy:"GridTileItemOrderBy"
		},
		getGridTiles:{
			by:"GridTilesUniqueWhere",
			filter:"GridTilesWhere"
		},
		listGridTiles:{
			filter:"GridTilesWhere",
			orderBy:"GridTilesOrderBy"
		},
		paginateGridTiles:{
			filter:"GridTilesWhere",
			orderBy:"GridTilesOrderBy"
		},
		getPosition:{
			by:"PositionUniqueWhere",
			filter:"PositionWhere"
		},
		listPosition:{
			filter:"PositionWhere",
			orderBy:"PositionOrderBy"
		},
		paginatePosition:{
			filter:"PositionWhere",
			orderBy:"PositionOrderBy"
		},
		getStatsWidget:{
			by:"StatsWidgetUniqueWhere",
			filter:"StatsWidgetWhere"
		},
		listStatsWidget:{
			filter:"StatsWidgetWhere",
			orderBy:"StatsWidgetOrderBy"
		},
		paginateStatsWidget:{
			filter:"StatsWidgetWhere",
			orderBy:"StatsWidgetOrderBy"
		},
		getStatsWidgetsLayout:{
			by:"StatsWidgetsLayoutUniqueWhere",
			filter:"StatsWidgetsLayoutWhere"
		},
		listStatsWidgetsLayout:{
			filter:"StatsWidgetsLayoutWhere",
			orderBy:"StatsWidgetsLayoutOrderBy"
		},
		paginateStatsWidgetsLayout:{
			filter:"StatsWidgetsLayoutWhere",
			orderBy:"StatsWidgetsLayoutOrderBy"
		},
		getStatsPage:{
			by:"StatsPageUniqueWhere",
			filter:"StatsPageWhere"
		},
		listStatsPage:{
			filter:"StatsPageWhere",
			orderBy:"StatsPageOrderBy"
		},
		paginateStatsPage:{
			filter:"StatsPageWhere",
			orderBy:"StatsPageOrderBy"
		},
		getUnassignReason:{
			by:"UnassignReasonUniqueWhere",
			filter:"UnassignReasonWhere"
		},
		listUnassignReason:{
			filter:"UnassignReasonWhere",
			orderBy:"UnassignReasonOrderBy"
		},
		paginateUnassignReason:{
			filter:"UnassignReasonWhere",
			orderBy:"UnassignReasonOrderBy"
		},
		getOrderPaymentStatsByDay:{
			by:"OrderPaymentStatsByDayUniqueWhere",
			filter:"OrderPaymentStatsByDayWhere"
		},
		listOrderPaymentStatsByDay:{
			filter:"OrderPaymentStatsByDayWhere",
			orderBy:"OrderPaymentStatsByDayOrderBy"
		},
		paginateOrderPaymentStatsByDay:{
			filter:"OrderPaymentStatsByDayWhere",
			orderBy:"OrderPaymentStatsByDayOrderBy"
		}
	},
	Mutation:{
		createEmployeeQueue:{
			data:"EmployeeQueueCreateInput"
		},
		updateEmployeeQueue:{
			by:"EmployeeQueueUniqueWhere",
			filter:"EmployeeQueueWhere",
			data:"EmployeeQueueUpdateInput"
		},
		upsertEmployeeQueue:{
			by:"EmployeeQueueUniqueWhere",
			filter:"EmployeeQueueWhere",
			update:"EmployeeQueueUpdateInput",
			create:"EmployeeQueueCreateInput"
		},
		updateTimetableShift:{
			by:"TimetableShiftUniqueWhere",
			filter:"TimetableShiftWhere",
			data:"TimetableShiftUpdateInput"
		},
		createEmployeeQueueItem:{
			data:"EmployeeQueueItemCreateInput"
		},
		deleteEmployeeQueueItem:{
			by:"EmployeeQueueItemUniqueWhere",
			filter:"EmployeeQueueItemWhere"
		},
		updateEmployeeQueueItem:{
			by:"EmployeeQueueItemUniqueWhere",
			filter:"EmployeeQueueItemWhere",
			data:"EmployeeQueueItemUpdateInput"
		},
		upsertEmployeeQueueItem:{
			by:"EmployeeQueueItemUniqueWhere",
			filter:"EmployeeQueueItemWhere",
			update:"EmployeeQueueItemUpdateInput",
			create:"EmployeeQueueItemCreateInput"
		},
		updateShiftsProfile:{
			by:"ShiftsProfileUniqueWhere",
			filter:"ShiftsProfileWhere",
			data:"ShiftsProfileUpdateInput"
		},
		createPreparedIngredientQuantity:{
			data:"PreparedIngredientQuantityCreateInput"
		},
		deletePreparedIngredientQuantity:{
			by:"PreparedIngredientQuantityUniqueWhere",
			filter:"PreparedIngredientQuantityWhere"
		},
		updatePreparedIngredientQuantity:{
			by:"PreparedIngredientQuantityUniqueWhere",
			filter:"PreparedIngredientQuantityWhere",
			data:"PreparedIngredientQuantityUpdateInput"
		},
		upsertPreparedIngredientQuantity:{
			by:"PreparedIngredientQuantityUniqueWhere",
			filter:"PreparedIngredientQuantityWhere",
			update:"PreparedIngredientQuantityUpdateInput",
			create:"PreparedIngredientQuantityCreateInput"
		},
		createPreparedRecipeQuantity:{
			data:"PreparedRecipeQuantityCreateInput"
		},
		deletePreparedRecipeQuantity:{
			by:"PreparedRecipeQuantityUniqueWhere",
			filter:"PreparedRecipeQuantityWhere"
		},
		updatePreparedRecipeQuantity:{
			by:"PreparedRecipeQuantityUniqueWhere",
			filter:"PreparedRecipeQuantityWhere",
			data:"PreparedRecipeQuantityUpdateInput"
		},
		upsertPreparedRecipeQuantity:{
			by:"PreparedRecipeQuantityUniqueWhere",
			filter:"PreparedRecipeQuantityWhere",
			update:"PreparedRecipeQuantityUpdateInput",
			create:"PreparedRecipeQuantityCreateInput"
		},
		createPreparedPackingQuantity:{
			data:"PreparedPackingQuantityCreateInput"
		},
		deletePreparedPackingQuantity:{
			by:"PreparedPackingQuantityUniqueWhere",
			filter:"PreparedPackingQuantityWhere"
		},
		updatePreparedPackingQuantity:{
			by:"PreparedPackingQuantityUniqueWhere",
			filter:"PreparedPackingQuantityWhere",
			data:"PreparedPackingQuantityUpdateInput"
		},
		upsertPreparedPackingQuantity:{
			by:"PreparedPackingQuantityUniqueWhere",
			filter:"PreparedPackingQuantityWhere",
			update:"PreparedPackingQuantityUpdateInput",
			create:"PreparedPackingQuantityCreateInput"
		},
		createSuppliesPurchase:{
			data:"SuppliesPurchaseCreateInput"
		},
		updateSuppliesPurchase:{
			by:"SuppliesPurchaseUniqueWhere",
			filter:"SuppliesPurchaseWhere",
			data:"SuppliesPurchaseUpdateInput"
		},
		upsertSuppliesPurchase:{
			by:"SuppliesPurchaseUniqueWhere",
			filter:"SuppliesPurchaseWhere",
			update:"SuppliesPurchaseUpdateInput",
			create:"SuppliesPurchaseCreateInput"
		},
		createSuppliesPurchaseItem:{
			data:"SuppliesPurchaseItemCreateInput"
		},
		updateSuppliesPurchaseItem:{
			by:"SuppliesPurchaseItemUniqueWhere",
			filter:"SuppliesPurchaseItemWhere",
			data:"SuppliesPurchaseItemUpdateInput"
		},
		upsertSuppliesPurchaseItem:{
			by:"SuppliesPurchaseItemUniqueWhere",
			filter:"SuppliesPurchaseItemWhere",
			update:"SuppliesPurchaseItemUpdateInput",
			create:"SuppliesPurchaseItemCreateInput"
		},
		createUnassignedShiftInfo:{
			data:"UnassignedShiftInfoCreateInput"
		},
		deleteUnassignedShiftInfo:{
			by:"UnassignedShiftInfoUniqueWhere",
			filter:"UnassignedShiftInfoWhere"
		},
		updateUnassignedShiftInfo:{
			by:"UnassignedShiftInfoUniqueWhere",
			filter:"UnassignedShiftInfoWhere",
			data:"UnassignedShiftInfoUpdateInput"
		},
		upsertUnassignedShiftInfo:{
			by:"UnassignedShiftInfoUniqueWhere",
			filter:"UnassignedShiftInfoWhere",
			update:"UnassignedShiftInfoUpdateInput",
			create:"UnassignedShiftInfoCreateInput"
		},
		createDeviceIdentifier:{
			data:"DeviceIdentifierCreateInput"
		},
		updateDeviceIdentifier:{
			by:"DeviceIdentifierUniqueWhere",
			filter:"DeviceIdentifierWhere",
			data:"DeviceIdentifierUpdateInput"
		},
		upsertDeviceIdentifier:{
			by:"DeviceIdentifierUniqueWhere",
			filter:"DeviceIdentifierWhere",
			update:"DeviceIdentifierUpdateInput",
			create:"DeviceIdentifierCreateInput"
		},
		transaction:{
			options:"MutationTransactionOptions"
		}
	},
	_MutationErrorType: true,
	MutationTransaction:{
		createEmployeeQueue:{
			data:"EmployeeQueueCreateInput"
		},
		updateEmployeeQueue:{
			by:"EmployeeQueueUniqueWhere",
			filter:"EmployeeQueueWhere",
			data:"EmployeeQueueUpdateInput"
		},
		upsertEmployeeQueue:{
			by:"EmployeeQueueUniqueWhere",
			filter:"EmployeeQueueWhere",
			update:"EmployeeQueueUpdateInput",
			create:"EmployeeQueueCreateInput"
		},
		updateTimetableShift:{
			by:"TimetableShiftUniqueWhere",
			filter:"TimetableShiftWhere",
			data:"TimetableShiftUpdateInput"
		},
		createEmployeeQueueItem:{
			data:"EmployeeQueueItemCreateInput"
		},
		deleteEmployeeQueueItem:{
			by:"EmployeeQueueItemUniqueWhere",
			filter:"EmployeeQueueItemWhere"
		},
		updateEmployeeQueueItem:{
			by:"EmployeeQueueItemUniqueWhere",
			filter:"EmployeeQueueItemWhere",
			data:"EmployeeQueueItemUpdateInput"
		},
		upsertEmployeeQueueItem:{
			by:"EmployeeQueueItemUniqueWhere",
			filter:"EmployeeQueueItemWhere",
			update:"EmployeeQueueItemUpdateInput",
			create:"EmployeeQueueItemCreateInput"
		},
		updateShiftsProfile:{
			by:"ShiftsProfileUniqueWhere",
			filter:"ShiftsProfileWhere",
			data:"ShiftsProfileUpdateInput"
		},
		createPreparedIngredientQuantity:{
			data:"PreparedIngredientQuantityCreateInput"
		},
		deletePreparedIngredientQuantity:{
			by:"PreparedIngredientQuantityUniqueWhere",
			filter:"PreparedIngredientQuantityWhere"
		},
		updatePreparedIngredientQuantity:{
			by:"PreparedIngredientQuantityUniqueWhere",
			filter:"PreparedIngredientQuantityWhere",
			data:"PreparedIngredientQuantityUpdateInput"
		},
		upsertPreparedIngredientQuantity:{
			by:"PreparedIngredientQuantityUniqueWhere",
			filter:"PreparedIngredientQuantityWhere",
			update:"PreparedIngredientQuantityUpdateInput",
			create:"PreparedIngredientQuantityCreateInput"
		},
		createPreparedRecipeQuantity:{
			data:"PreparedRecipeQuantityCreateInput"
		},
		deletePreparedRecipeQuantity:{
			by:"PreparedRecipeQuantityUniqueWhere",
			filter:"PreparedRecipeQuantityWhere"
		},
		updatePreparedRecipeQuantity:{
			by:"PreparedRecipeQuantityUniqueWhere",
			filter:"PreparedRecipeQuantityWhere",
			data:"PreparedRecipeQuantityUpdateInput"
		},
		upsertPreparedRecipeQuantity:{
			by:"PreparedRecipeQuantityUniqueWhere",
			filter:"PreparedRecipeQuantityWhere",
			update:"PreparedRecipeQuantityUpdateInput",
			create:"PreparedRecipeQuantityCreateInput"
		},
		createPreparedPackingQuantity:{
			data:"PreparedPackingQuantityCreateInput"
		},
		deletePreparedPackingQuantity:{
			by:"PreparedPackingQuantityUniqueWhere",
			filter:"PreparedPackingQuantityWhere"
		},
		updatePreparedPackingQuantity:{
			by:"PreparedPackingQuantityUniqueWhere",
			filter:"PreparedPackingQuantityWhere",
			data:"PreparedPackingQuantityUpdateInput"
		},
		upsertPreparedPackingQuantity:{
			by:"PreparedPackingQuantityUniqueWhere",
			filter:"PreparedPackingQuantityWhere",
			update:"PreparedPackingQuantityUpdateInput",
			create:"PreparedPackingQuantityCreateInput"
		},
		createSuppliesPurchase:{
			data:"SuppliesPurchaseCreateInput"
		},
		updateSuppliesPurchase:{
			by:"SuppliesPurchaseUniqueWhere",
			filter:"SuppliesPurchaseWhere",
			data:"SuppliesPurchaseUpdateInput"
		},
		upsertSuppliesPurchase:{
			by:"SuppliesPurchaseUniqueWhere",
			filter:"SuppliesPurchaseWhere",
			update:"SuppliesPurchaseUpdateInput",
			create:"SuppliesPurchaseCreateInput"
		},
		createSuppliesPurchaseItem:{
			data:"SuppliesPurchaseItemCreateInput"
		},
		updateSuppliesPurchaseItem:{
			by:"SuppliesPurchaseItemUniqueWhere",
			filter:"SuppliesPurchaseItemWhere",
			data:"SuppliesPurchaseItemUpdateInput"
		},
		upsertSuppliesPurchaseItem:{
			by:"SuppliesPurchaseItemUniqueWhere",
			filter:"SuppliesPurchaseItemWhere",
			update:"SuppliesPurchaseItemUpdateInput",
			create:"SuppliesPurchaseItemCreateInput"
		},
		createUnassignedShiftInfo:{
			data:"UnassignedShiftInfoCreateInput"
		},
		deleteUnassignedShiftInfo:{
			by:"UnassignedShiftInfoUniqueWhere",
			filter:"UnassignedShiftInfoWhere"
		},
		updateUnassignedShiftInfo:{
			by:"UnassignedShiftInfoUniqueWhere",
			filter:"UnassignedShiftInfoWhere",
			data:"UnassignedShiftInfoUpdateInput"
		},
		upsertUnassignedShiftInfo:{
			by:"UnassignedShiftInfoUniqueWhere",
			filter:"UnassignedShiftInfoWhere",
			update:"UnassignedShiftInfoUpdateInput",
			create:"UnassignedShiftInfoCreateInput"
		},
		createDeviceIdentifier:{
			data:"DeviceIdentifierCreateInput"
		},
		updateDeviceIdentifier:{
			by:"DeviceIdentifierUniqueWhere",
			filter:"DeviceIdentifierWhere",
			data:"DeviceIdentifierUpdateInput"
		},
		upsertDeviceIdentifier:{
			by:"DeviceIdentifierUniqueWhere",
			filter:"DeviceIdentifierWhere",
			update:"DeviceIdentifierUpdateInput",
			create:"DeviceIdentifierCreateInput"
		}
	},
	MutationTransactionOptions:{

	},
	_OnDeleteBehaviour: true,
	_RelationSide: true,
	_OrderByDirection: true
}

export const ReturnTypes: Record<string,any> = {
	Query:{
		getAddress:"Address",
		listAddress:"Address",
		paginateAddress:"AddressConnection",
		getCountry:"Country",
		listCountry:"Country",
		paginateCountry:"CountryConnection",
		getChannel:"Channel",
		listChannel:"Channel",
		paginateChannel:"ChannelConnection",
		getCustomer:"Customer",
		listCustomer:"Customer",
		paginateCustomer:"CustomerConnection",
		getDeliveryMethod:"DeliveryMethod",
		listDeliveryMethod:"DeliveryMethod",
		paginateDeliveryMethod:"DeliveryMethodConnection",
		getOrder:"Order",
		listOrder:"Order",
		paginateOrder:"OrderConnection",
		getOrderPayment:"OrderPayment",
		listOrderPayment:"OrderPayment",
		paginateOrderPayment:"OrderPaymentConnection",
		getOrderDelivery:"OrderDelivery",
		listOrderDelivery:"OrderDelivery",
		paginateOrderDelivery:"OrderDeliveryConnection",
		getOrderItem:"OrderItem",
		listOrderItem:"OrderItem",
		paginateOrderItem:"OrderItemConnection",
		getPaymentMethod:"PaymentMethod",
		listPaymentMethod:"PaymentMethod",
		paginatePaymentMethod:"PaymentMethodConnection",
		getVatRate:"VatRate",
		listVatRate:"VatRate",
		paginateVatRate:"VatRateConnection",
		getProduct:"Product",
		listProduct:"Product",
		paginateProduct:"ProductConnection",
		getProductStock:"ProductStock",
		listProductStock:"ProductStock",
		paginateProductStock:"ProductStockConnection",
		getProductPacking:"ProductPacking",
		listProductPacking:"ProductPacking",
		paginateProductPacking:"ProductPackingConnection",
		getProductRecipe:"ProductRecipe",
		listProductRecipe:"ProductRecipe",
		paginateProductRecipe:"ProductRecipeConnection",
		getProductCategory:"ProductCategory",
		listProductCategory:"ProductCategory",
		paginateProductCategory:"ProductCategoryConnection",
		getStore:"Store",
		listStore:"Store",
		paginateStore:"StoreConnection",
		getTag:"Tag",
		listTag:"Tag",
		paginateTag:"TagConnection",
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		getBusinessCategory:"BusinessCategory",
		listBusinessCategory:"BusinessCategory",
		paginateBusinessCategory:"BusinessCategoryConnection",
		getProductList:"ProductList",
		listProductList:"ProductList",
		paginateProductList:"ProductListConnection",
		getProductListItem:"ProductListItem",
		listProductListItem:"ProductListItem",
		paginateProductListItem:"ProductListItemConnection",
		getProductCategoryList:"ProductCategoryList",
		listProductCategoryList:"ProductCategoryList",
		paginateProductCategoryList:"ProductCategoryListConnection",
		getProductCategoryListItem:"ProductCategoryListItem",
		listProductCategoryListItem:"ProductCategoryListItem",
		paginateProductCategoryListItem:"ProductCategoryListItemConnection",
		getBusinessCategoryLocale:"BusinessCategoryLocale",
		listBusinessCategoryLocale:"BusinessCategoryLocale",
		paginateBusinessCategoryLocale:"BusinessCategoryLocaleConnection",
		getLocale:"Locale",
		listLocale:"Locale",
		paginateLocale:"LocaleConnection",
		getProductCategoryListLocale:"ProductCategoryListLocale",
		listProductCategoryListLocale:"ProductCategoryListLocale",
		paginateProductCategoryListLocale:"ProductCategoryListLocaleConnection",
		getCustomerName:"CustomerName",
		listCustomerName:"CustomerName",
		paginateCustomerName:"CustomerNameConnection",
		getProductIngredientItem:"ProductIngredientItem",
		listProductIngredientItem:"ProductIngredientItem",
		paginateProductIngredientItem:"ProductIngredientItemConnection",
		getProductIngredientItemQuantity:"ProductIngredientItemQuantity",
		listProductIngredientItemQuantity:"ProductIngredientItemQuantity",
		paginateProductIngredientItemQuantity:"ProductIngredientItemQuantityConnection",
		getProductIngredientItemQuantityUnit:"ProductIngredientItemQuantityUnit",
		listProductIngredientItemQuantityUnit:"ProductIngredientItemQuantityUnit",
		paginateProductIngredientItemQuantityUnit:"ProductIngredientItemQuantityUnitConnection",
		getProductIngredient:"ProductIngredient",
		listProductIngredient:"ProductIngredient",
		paginateProductIngredient:"ProductIngredientConnection",
		getProductIngredientRating:"ProductIngredientRating",
		listProductIngredientRating:"ProductIngredientRating",
		paginateProductIngredientRating:"ProductIngredientRatingConnection",
		getProductIngredientLocale:"ProductIngredientLocale",
		listProductIngredientLocale:"ProductIngredientLocale",
		paginateProductIngredientLocale:"ProductIngredientLocaleConnection",
		getOrderRecurrence:"OrderRecurrence",
		listOrderRecurrence:"OrderRecurrence",
		paginateOrderRecurrence:"OrderRecurrenceConnection",
		getOrderRecurrenceLocale:"OrderRecurrenceLocale",
		listOrderRecurrenceLocale:"OrderRecurrenceLocale",
		paginateOrderRecurrenceLocale:"OrderRecurrenceLocaleConnection",
		getStaff:"Staff",
		listStaff:"Staff",
		paginateStaff:"StaffConnection",
		getBlog:"Blog",
		listBlog:"Blog",
		paginateBlog:"BlogConnection",
		getBlogLocale:"BlogLocale",
		listBlogLocale:"BlogLocale",
		paginateBlogLocale:"BlogLocaleConnection",
		getBlogPost:"BlogPost",
		listBlogPost:"BlogPost",
		paginateBlogPost:"BlogPostConnection",
		getBlogPostLocale:"BlogPostLocale",
		listBlogPostLocale:"BlogPostLocale",
		paginateBlogPostLocale:"BlogPostLocaleConnection",
		getColorPallete:"ColorPallete",
		listColorPallete:"ColorPallete",
		paginateColorPallete:"ColorPalleteConnection",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		getContentReference:"ContentReference",
		listContentReference:"ContentReference",
		paginateContentReference:"ContentReferenceConnection",
		getBlogPostList:"BlogPostList",
		listBlogPostList:"BlogPostList",
		paginateBlogPostList:"BlogPostListConnection",
		getBlogPostListItem:"BlogPostListItem",
		listBlogPostListItem:"BlogPostListItem",
		paginateBlogPostListItem:"BlogPostListItemConnection",
		getLinkList:"LinkList",
		listLinkList:"LinkList",
		paginateLinkList:"LinkListConnection",
		getLinkListItem:"LinkListItem",
		listLinkListItem:"LinkListItem",
		paginateLinkListItem:"LinkListItemConnection",
		getCover:"Cover",
		listCover:"Cover",
		paginateCover:"CoverConnection",
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		getMedium:"Medium",
		listMedium:"Medium",
		paginateMedium:"MediumConnection",
		getVideo:"Video",
		listVideo:"Video",
		paginateVideo:"VideoConnection",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		getTranslationDomain:"TranslationDomain",
		listTranslationDomain:"TranslationDomain",
		paginateTranslationDomain:"TranslationDomainConnection",
		getTranslationCatalogue:"TranslationCatalogue",
		listTranslationCatalogue:"TranslationCatalogue",
		paginateTranslationCatalogue:"TranslationCatalogueConnection",
		getTranslationKey:"TranslationKey",
		listTranslationKey:"TranslationKey",
		paginateTranslationKey:"TranslationKeyConnection",
		getTranslationValue:"TranslationValue",
		listTranslationValue:"TranslationValue",
		paginateTranslationValue:"TranslationValueConnection",
		getTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		listTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		paginateTranslationCataloguesIdentifier:"TranslationCataloguesIdentifierConnection",
		getSocialsAndApps:"SocialsAndApps",
		listSocialsAndApps:"SocialsAndApps",
		paginateSocialsAndApps:"SocialsAndAppsConnection",
		getProductGrid:"ProductGrid",
		listProductGrid:"ProductGrid",
		paginateProductGrid:"ProductGridConnection",
		getProductGridItem:"ProductGridItem",
		listProductGridItem:"ProductGridItem",
		paginateProductGridItem:"ProductGridItemConnection",
		getGallery:"Gallery",
		listGallery:"Gallery",
		paginateGallery:"GalleryConnection",
		getGalleryItem:"GalleryItem",
		listGalleryItem:"GalleryItem",
		paginateGalleryItem:"GalleryItemConnection",
		getHero:"Hero",
		listHero:"Hero",
		paginateHero:"HeroConnection",
		getPage:"Page",
		listPage:"Page",
		paginatePage:"PageConnection",
		getPageLocale:"PageLocale",
		listPageLocale:"PageLocale",
		paginatePageLocale:"PageLocaleConnection",
		getDeadlineTemplate:"DeadlineTemplate",
		listDeadlineTemplate:"DeadlineTemplate",
		paginateDeadlineTemplate:"DeadlineTemplateConnection",
		getEmployeeQueue:"EmployeeQueue",
		listEmployeeQueue:"EmployeeQueue",
		paginateEmployeeQueue:"EmployeeQueueConnection",
		validateCreateEmployeeQueue:"_ValidationResult",
		validateUpdateEmployeeQueue:"_ValidationResult",
		getTimetableDay:"TimetableDay",
		listTimetableDay:"TimetableDay",
		paginateTimetableDay:"TimetableDayConnection",
		getTimetableEmployeeRole:"TimetableEmployeeRole",
		listTimetableEmployeeRole:"TimetableEmployeeRole",
		paginateTimetableEmployeeRole:"TimetableEmployeeRoleConnection",
		getTimetableShift:"TimetableShift",
		listTimetableShift:"TimetableShift",
		paginateTimetableShift:"TimetableShiftConnection",
		validateUpdateTimetableShift:"_ValidationResult",
		getTimetableShiftGroup:"TimetableShiftGroup",
		listTimetableShiftGroup:"TimetableShiftGroup",
		paginateTimetableShiftGroup:"TimetableShiftGroupConnection",
		getTimetableShiftPosition:"TimetableShiftPosition",
		listTimetableShiftPosition:"TimetableShiftPosition",
		paginateTimetableShiftPosition:"TimetableShiftPositionConnection",
		getTimetableTemplate:"TimetableTemplate",
		listTimetableTemplate:"TimetableTemplate",
		paginateTimetableTemplate:"TimetableTemplateConnection",
		getEmployeeQueueItem:"EmployeeQueueItem",
		listEmployeeQueueItem:"EmployeeQueueItem",
		paginateEmployeeQueueItem:"EmployeeQueueItemConnection",
		validateCreateEmployeeQueueItem:"_ValidationResult",
		validateUpdateEmployeeQueueItem:"_ValidationResult",
		getProductBanner:"ProductBanner",
		listProductBanner:"ProductBanner",
		paginateProductBanner:"ProductBannerConnection",
		getProductBannerItem:"ProductBannerItem",
		listProductBannerItem:"ProductBannerItem",
		paginateProductBannerItem:"ProductBannerItemConnection",
		getProductBannerList:"ProductBannerList",
		listProductBannerList:"ProductBannerList",
		paginateProductBannerList:"ProductBannerListConnection",
		getShiftsProfile:"ShiftsProfile",
		listShiftsProfile:"ShiftsProfile",
		paginateShiftsProfile:"ShiftsProfileConnection",
		validateUpdateShiftsProfile:"_ValidationResult",
		getProductIngredientCategory:"ProductIngredientCategory",
		listProductIngredientCategory:"ProductIngredientCategory",
		paginateProductIngredientCategory:"ProductIngredientCategoryConnection",
		getProductIngredientCategoryLocale:"ProductIngredientCategoryLocale",
		listProductIngredientCategoryLocale:"ProductIngredientCategoryLocale",
		paginateProductIngredientCategoryLocale:"ProductIngredientCategoryLocaleConnection",
		getDeliveryRoute:"DeliveryRoute",
		listDeliveryRoute:"DeliveryRoute",
		paginateDeliveryRoute:"DeliveryRouteConnection",
		getDeliveryWaypoint:"DeliveryWaypoint",
		listDeliveryWaypoint:"DeliveryWaypoint",
		paginateDeliveryWaypoint:"DeliveryWaypointConnection",
		getAllergen:"Allergen",
		listAllergen:"Allergen",
		paginateAllergen:"AllergenConnection",
		getAllergenLocale:"AllergenLocale",
		listAllergenLocale:"AllergenLocale",
		paginateAllergenLocale:"AllergenLocaleConnection",
		getWeekHours:"WeekHours",
		listWeekHours:"WeekHours",
		paginateWeekHours:"WeekHoursConnection",
		getPreparedIngredientQuantity:"PreparedIngredientQuantity",
		listPreparedIngredientQuantity:"PreparedIngredientQuantity",
		paginatePreparedIngredientQuantity:"PreparedIngredientQuantityConnection",
		validateCreatePreparedIngredientQuantity:"_ValidationResult",
		validateUpdatePreparedIngredientQuantity:"_ValidationResult",
		getPreparedRecipeQuantity:"PreparedRecipeQuantity",
		listPreparedRecipeQuantity:"PreparedRecipeQuantity",
		paginatePreparedRecipeQuantity:"PreparedRecipeQuantityConnection",
		validateCreatePreparedRecipeQuantity:"_ValidationResult",
		validateUpdatePreparedRecipeQuantity:"_ValidationResult",
		getPreparedPackingQuantity:"PreparedPackingQuantity",
		listPreparedPackingQuantity:"PreparedPackingQuantity",
		paginatePreparedPackingQuantity:"PreparedPackingQuantityConnection",
		validateCreatePreparedPackingQuantity:"_ValidationResult",
		validateUpdatePreparedPackingQuantity:"_ValidationResult",
		getDeliveryZone:"DeliveryZone",
		listDeliveryZone:"DeliveryZone",
		paginateDeliveryZone:"DeliveryZoneConnection",
		getDeliveryTimelinePreset:"DeliveryTimelinePreset",
		listDeliveryTimelinePreset:"DeliveryTimelinePreset",
		paginateDeliveryTimelinePreset:"DeliveryTimelinePresetConnection",
		getProductPackingLocale:"ProductPackingLocale",
		listProductPackingLocale:"ProductPackingLocale",
		paginateProductPackingLocale:"ProductPackingLocaleConnection",
		getProductLocale:"ProductLocale",
		listProductLocale:"ProductLocale",
		paginateProductLocale:"ProductLocaleConnection",
		getProductRecipeLocale:"ProductRecipeLocale",
		listProductRecipeLocale:"ProductRecipeLocale",
		paginateProductRecipeLocale:"ProductRecipeLocaleConnection",
		getSuppliesPurchase:"SuppliesPurchase",
		listSuppliesPurchase:"SuppliesPurchase",
		paginateSuppliesPurchase:"SuppliesPurchaseConnection",
		validateCreateSuppliesPurchase:"_ValidationResult",
		validateUpdateSuppliesPurchase:"_ValidationResult",
		getLogRecord:"LogRecord",
		listLogRecord:"LogRecord",
		paginateLogRecord:"LogRecordConnection",
		getSuppliesPurchaseItem:"SuppliesPurchaseItem",
		listSuppliesPurchaseItem:"SuppliesPurchaseItem",
		paginateSuppliesPurchaseItem:"SuppliesPurchaseItemConnection",
		validateCreateSuppliesPurchaseItem:"_ValidationResult",
		validateUpdateSuppliesPurchaseItem:"_ValidationResult",
		getVirtualProduct:"VirtualProduct",
		listVirtualProduct:"VirtualProduct",
		paginateVirtualProduct:"VirtualProductConnection",
		getProductMetadata:"ProductMetadata",
		listProductMetadata:"ProductMetadata",
		paginateProductMetadata:"ProductMetadataConnection",
		getProductGridCategory:"ProductGridCategory",
		listProductGridCategory:"ProductGridCategory",
		paginateProductGridCategory:"ProductGridCategoryConnection",
		getUnassignedShiftInfo:"UnassignedShiftInfo",
		listUnassignedShiftInfo:"UnassignedShiftInfo",
		paginateUnassignedShiftInfo:"UnassignedShiftInfoConnection",
		validateCreateUnassignedShiftInfo:"_ValidationResult",
		validateUpdateUnassignedShiftInfo:"_ValidationResult",
		getFreshingContainer:"FreshingContainer",
		listFreshingContainer:"FreshingContainer",
		paginateFreshingContainer:"FreshingContainerConnection",
		getTextItem:"TextItem",
		listTextItem:"TextItem",
		paginateTextItem:"TextItemConnection",
		getTextList:"TextList",
		listTextList:"TextList",
		paginateTextList:"TextListConnection",
		getDeliveryRegions:"DeliveryRegions",
		listDeliveryRegions:"DeliveryRegions",
		paginateDeliveryRegions:"DeliveryRegionsConnection",
		getDeliveryRegionsItem:"DeliveryRegionsItem",
		listDeliveryRegionsItem:"DeliveryRegionsItem",
		paginateDeliveryRegionsItem:"DeliveryRegionsItemConnection",
		getImageList:"ImageList",
		listImageList:"ImageList",
		paginateImageList:"ImageListConnection",
		getImageListItem:"ImageListItem",
		listImageListItem:"ImageListItem",
		paginateImageListItem:"ImageListItemConnection",
		getTimetablesEmployeePrivileges:"TimetablesEmployeePrivileges",
		listTimetablesEmployeePrivileges:"TimetablesEmployeePrivileges",
		paginateTimetablesEmployeePrivileges:"TimetablesEmployeePrivilegesConnection",
		getTimetableDayNote:"TimetableDayNote",
		listTimetableDayNote:"TimetableDayNote",
		paginateTimetableDayNote:"TimetableDayNoteConnection",
		getDeliveryMethodLocale:"DeliveryMethodLocale",
		listDeliveryMethodLocale:"DeliveryMethodLocale",
		paginateDeliveryMethodLocale:"DeliveryMethodLocaleConnection",
		getIngredientSupplier:"IngredientSupplier",
		listIngredientSupplier:"IngredientSupplier",
		paginateIngredientSupplier:"IngredientSupplierConnection",
		getIngredientSupplierItem:"IngredientSupplierItem",
		listIngredientSupplierItem:"IngredientSupplierItem",
		paginateIngredientSupplierItem:"IngredientSupplierItemConnection",
		getIngredientSupplierList:"IngredientSupplierList",
		listIngredientSupplierList:"IngredientSupplierList",
		paginateIngredientSupplierList:"IngredientSupplierListConnection",
		getIngredientSupplierLocale:"IngredientSupplierLocale",
		listIngredientSupplierLocale:"IngredientSupplierLocale",
		paginateIngredientSupplierLocale:"IngredientSupplierLocaleConnection",
		getRedirect:"Redirect",
		listRedirect:"Redirect",
		paginateRedirect:"RedirectConnection",
		getVenue:"Venue",
		listVenue:"Venue",
		paginateVenue:"VenueConnection",
		getWorkspace:"Workspace",
		listWorkspace:"Workspace",
		paginateWorkspace:"WorkspaceConnection",
		getVenueEmployeeRole:"VenueEmployeeRole",
		listVenueEmployeeRole:"VenueEmployeeRole",
		paginateVenueEmployeeRole:"VenueEmployeeRoleConnection",
		getIdentificationAttempt:"IdentificationAttempt",
		listIdentificationAttempt:"IdentificationAttempt",
		paginateIdentificationAttempt:"IdentificationAttemptConnection",
		getIdentificationCode:"IdentificationCode",
		listIdentificationCode:"IdentificationCode",
		paginateIdentificationCode:"IdentificationCodeConnection",
		getIdentificationCodeLocale:"IdentificationCodeLocale",
		listIdentificationCodeLocale:"IdentificationCodeLocale",
		paginateIdentificationCodeLocale:"IdentificationCodeLocaleConnection",
		getDeviceIdentifier:"DeviceIdentifier",
		listDeviceIdentifier:"DeviceIdentifier",
		paginateDeviceIdentifier:"DeviceIdentifierConnection",
		validateCreateDeviceIdentifier:"_ValidationResult",
		validateUpdateDeviceIdentifier:"_ValidationResult",
		getDeviceIdentifierMetadata:"DeviceIdentifierMetadata",
		listDeviceIdentifierMetadata:"DeviceIdentifierMetadata",
		paginateDeviceIdentifierMetadata:"DeviceIdentifierMetadataConnection",
		getFulfillmentNote:"FulfillmentNote",
		listFulfillmentNote:"FulfillmentNote",
		paginateFulfillmentNote:"FulfillmentNoteConnection",
		getCustomerMetadata:"CustomerMetadata",
		listCustomerMetadata:"CustomerMetadata",
		paginateCustomerMetadata:"CustomerMetadataConnection",
		getSubscriptionBox:"SubscriptionBox",
		listSubscriptionBox:"SubscriptionBox",
		paginateSubscriptionBox:"SubscriptionBoxConnection",
		getSubscriptionBoxLocale:"SubscriptionBoxLocale",
		listSubscriptionBoxLocale:"SubscriptionBoxLocale",
		paginateSubscriptionBoxLocale:"SubscriptionBoxLocaleConnection",
		getTimetableDayMetadata:"TimetableDayMetadata",
		listTimetableDayMetadata:"TimetableDayMetadata",
		paginateTimetableDayMetadata:"TimetableDayMetadataConnection",
		getGridTileItem:"GridTileItem",
		listGridTileItem:"GridTileItem",
		paginateGridTileItem:"GridTileItemConnection",
		getGridTiles:"GridTiles",
		listGridTiles:"GridTiles",
		paginateGridTiles:"GridTilesConnection",
		getPosition:"Position",
		listPosition:"Position",
		paginatePosition:"PositionConnection",
		getStatsWidget:"StatsWidget",
		listStatsWidget:"StatsWidget",
		paginateStatsWidget:"StatsWidgetConnection",
		getStatsWidgetsLayout:"StatsWidgetsLayout",
		listStatsWidgetsLayout:"StatsWidgetsLayout",
		paginateStatsWidgetsLayout:"StatsWidgetsLayoutConnection",
		getStatsPage:"StatsPage",
		listStatsPage:"StatsPage",
		paginateStatsPage:"StatsPageConnection",
		getUnassignReason:"UnassignReason",
		listUnassignReason:"UnassignReason",
		paginateUnassignReason:"UnassignReasonConnection",
		getOrderPaymentStatsByDay:"OrderPaymentStatsByDay",
		listOrderPaymentStatsByDay:"OrderPaymentStatsByDay",
		paginateOrderPaymentStatsByDay:"OrderPaymentStatsByDayConnection",
		transaction:"QueryTransaction",
		_info:"Info",
		schema:"_Schema"
	},
	Address:{
		_meta:"AddressMeta",
		id:"UUID",
		name:"String",
		firstName:"String",
		lastName:"String",
		addressLine2:"String",
		email:"String",
		phone:"String",
		companyName:"String",
		companyIdentifier:"String",
		vatIdentifier:"String",
		externalIdentifier:"String",
		hidden:"Boolean",
		customer:"Customer",
		internalNote:"String",
		country:"Country",
		deletedAt:"DateTime",
		note:"String",
		googlePlaceId:"String",
		raw:"String",
		formatted:"String",
		geocodeGeneratedAt:"DateTime",
		geocodeResponse:"String",
		deliveryZone:"DeliveryZone",
		geocodeValid:"Boolean",
		fullName:"String",
		fakturoidSubjectId:"String",
		addressLine1:"String",
		city:"String",
		postalCode:"String",
		createdAt:"DateTime",
		billingAddressOfOrders:"Order",
		deliveryAddressOfOrderDeliveries:"OrderDelivery",
		defaultDeliveryAddressOfCustomer:"Customer",
		billingAddressOfOrdersBySeq:"Order",
		billingAddressOfOrdersByDoubleshotLegacyId:"Order",
		billingAddressOfOrdersByDoubleShotLegacyNumber:"Order",
		billingAddressOfOrdersByDelivery:"Order",
		billingAddressOfOrdersByItems:"Order",
		billingAddressOfOrdersByPayments:"Order",
		deliveryAddressOfOrderDeliveriesByTrackingCode:"OrderDelivery",
		deliveryAddressOfOrderDeliveriesByOrder:"OrderDelivery",
		deliveryAddressOfOrderDeliveriesByDeliveryWaypoint:"OrderDelivery",
		paginateBillingAddressOfOrders:"OrderConnection",
		paginateDeliveryAddressOfOrderDeliveries:"OrderDeliveryConnection"
	},
	AddressMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		firstName:"FieldMeta",
		lastName:"FieldMeta",
		addressLine2:"FieldMeta",
		email:"FieldMeta",
		phone:"FieldMeta",
		companyName:"FieldMeta",
		companyIdentifier:"FieldMeta",
		vatIdentifier:"FieldMeta",
		externalIdentifier:"FieldMeta",
		hidden:"FieldMeta",
		customer:"FieldMeta",
		internalNote:"FieldMeta",
		country:"FieldMeta",
		deletedAt:"FieldMeta",
		note:"FieldMeta",
		googlePlaceId:"FieldMeta",
		raw:"FieldMeta",
		formatted:"FieldMeta",
		geocodeGeneratedAt:"FieldMeta",
		geocodeResponse:"FieldMeta",
		deliveryZone:"FieldMeta",
		geocodeValid:"FieldMeta",
		fullName:"FieldMeta",
		fakturoidSubjectId:"FieldMeta",
		addressLine1:"FieldMeta",
		city:"FieldMeta",
		postalCode:"FieldMeta",
		createdAt:"FieldMeta",
		billingAddressOfOrders:"FieldMeta",
		deliveryAddressOfOrderDeliveries:"FieldMeta",
		defaultDeliveryAddressOfCustomer:"FieldMeta"
	},
	FieldMeta:{
		readable:"Boolean",
		updatable:"Boolean"
	},
	Customer:{
		_meta:"CustomerMeta",
		id:"UUID",
		addresses:"Address",
		defaultBillingAddress:"Address",
		tags:"Tag",
		orders:"Order",
		photo:"Image",
		phone:"String",
		name:"CustomerName",
		internalNote:"String",
		welcomeMessage:"String",
		familiarity:"CustomerFamiliarity",
		channels:"Channel",
		disabledAt:"DateTime",
		email:"String",
		createdAt:"DateTime",
		defaultDeliveryAddress:"Address",
		prefersPackingsWithoutCardboard:"Boolean",
		doubleshotLegacyId:"String",
		meta:"CustomerMetadata",
		doubleshotLastUpdatedAt:"DateTime",
		addressesByBillingAddressOfOrders:"Address",
		addressesByDeliveryAddressOfOrderDeliveries:"Address",
		addressesByDefaultDeliveryAddressOfCustomer:"Address",
		ordersBySeq:"Order",
		ordersByDoubleshotLegacyId:"Order",
		ordersByDoubleShotLegacyNumber:"Order",
		ordersByDelivery:"Order",
		ordersByItems:"Order",
		ordersByPayments:"Order",
		paginateAddresses:"AddressConnection",
		paginateTags:"TagConnection",
		paginateOrders:"OrderConnection",
		paginateChannels:"ChannelConnection"
	},
	CustomerMeta:{
		id:"FieldMeta",
		addresses:"FieldMeta",
		defaultBillingAddress:"FieldMeta",
		tags:"FieldMeta",
		orders:"FieldMeta",
		photo:"FieldMeta",
		phone:"FieldMeta",
		name:"FieldMeta",
		internalNote:"FieldMeta",
		welcomeMessage:"FieldMeta",
		familiarity:"FieldMeta",
		channels:"FieldMeta",
		disabledAt:"FieldMeta",
		email:"FieldMeta",
		createdAt:"FieldMeta",
		defaultDeliveryAddress:"FieldMeta",
		prefersPackingsWithoutCardboard:"FieldMeta",
		doubleshotLegacyId:"FieldMeta",
		meta:"FieldMeta",
		doubleshotLastUpdatedAt:"FieldMeta"
	},
	Tag:{
		_meta:"TagMeta",
		id:"UUID",
		name:"String",
		code:"String",
		customers:"Customer",
		products:"Product",
		categories:"ProductCategory",
		description:"String",
		internalNote:"String",
		addTagsOnRegistration:"Channel",
		deliveryZones:"DeliveryZone",
		publicInChannels:"Channel",
		fulfillmentNotes:"FulfillmentNote",
		paginateCustomers:"CustomerConnection",
		paginateProducts:"ProductConnection",
		paginateCategories:"ProductCategoryConnection",
		paginateAddTagsOnRegistration:"ChannelConnection",
		paginateDeliveryZones:"DeliveryZoneConnection",
		paginatePublicInChannels:"ChannelConnection",
		paginateFulfillmentNotes:"FulfillmentNoteConnection"
	},
	TagMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		customers:"FieldMeta",
		products:"FieldMeta",
		categories:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		addTagsOnRegistration:"FieldMeta",
		deliveryZones:"FieldMeta",
		publicInChannels:"FieldMeta",
		fulfillmentNotes:"FieldMeta"
	},
	Product:{
		_meta:"ProductMeta",
		id:"UUID",
		name:"String",
		code:"String",
		priceCents:"Int",
		notDiscountable:"Boolean",
		packing:"ProductPacking",
		recipe:"ProductRecipe",
		tags:"Tag",
		categories:"ProductCategory",
		vatRate:"VatRate",
		stocks:"ProductStock",
		image:"Image",
		description:"String",
		internalNote:"String",
		availability:"ProductAvailability",
		order:"Float",
		orderItems:"OrderItem",
		maximumQuantityPerOrder:"Int",
		deliveryZones:"DeliveryZone",
		locales:"ProductLocale",
		archivedAt:"DateTime",
		virtualProduct:"VirtualProduct",
		meta:"ProductMetadata",
		page:"Page",
		fulfillmentNotes:"FulfillmentNote",
		hasSubscription:"Boolean",
		pointOfSaleTileColor:"String",
		stocksByStore:"ProductStock",
		localesByLocale:"ProductLocale",
		localesByFeatureList:"ProductLocale",
		paginateTags:"TagConnection",
		paginateCategories:"ProductCategoryConnection",
		paginateStocks:"ProductStockConnection",
		paginateOrderItems:"OrderItemConnection",
		paginateDeliveryZones:"DeliveryZoneConnection",
		paginateLocales:"ProductLocaleConnection",
		paginateFulfillmentNotes:"FulfillmentNoteConnection"
	},
	ProductMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		priceCents:"FieldMeta",
		notDiscountable:"FieldMeta",
		packing:"FieldMeta",
		recipe:"FieldMeta",
		tags:"FieldMeta",
		categories:"FieldMeta",
		vatRate:"FieldMeta",
		stocks:"FieldMeta",
		image:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		availability:"FieldMeta",
		order:"FieldMeta",
		orderItems:"FieldMeta",
		maximumQuantityPerOrder:"FieldMeta",
		deliveryZones:"FieldMeta",
		locales:"FieldMeta",
		archivedAt:"FieldMeta",
		virtualProduct:"FieldMeta",
		meta:"FieldMeta",
		page:"FieldMeta",
		fulfillmentNotes:"FieldMeta",
		hasSubscription:"FieldMeta",
		pointOfSaleTileColor:"FieldMeta"
	},
	ProductPacking:{
		_meta:"ProductPackingMeta",
		id:"UUID",
		name:"String",
		image:"Image",
		description:"String",
		internalNote:"String",
		volumeMl:"Int",
		icon:"Image",
		shortName:"String",
		locales:"ProductPackingLocale",
		preparedPackingQuantities:"PreparedPackingQuantity",
		isPackedInCardboard:"Boolean",
		localesByLocale:"ProductPackingLocale",
		paginateLocales:"ProductPackingLocaleConnection",
		paginatePreparedPackingQuantities:"PreparedPackingQuantityConnection"
	},
	ProductPackingMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		image:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		volumeMl:"FieldMeta",
		icon:"FieldMeta",
		shortName:"FieldMeta",
		locales:"FieldMeta",
		preparedPackingQuantities:"FieldMeta",
		isPackedInCardboard:"FieldMeta"
	},
	Image:{
		_meta:"ImageMeta",
		id:"UUID",
		url:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		alt:"String"
	},
	ImageMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		width:"FieldMeta",
		height:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		alt:"FieldMeta"
	},
	ProductPackingLocale:{
		_meta:"ProductPackingLocaleMeta",
		id:"UUID",
		name:"String",
		shortName:"String",
		description:"String",
		root:"ProductPacking",
		locale:"Locale"
	},
	ProductPackingLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		shortName:"FieldMeta",
		description:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	Locale:{
		_meta:"LocaleMeta",
		id:"UUID",
		businessCategories:"BusinessCategoryLocale",
		productCategoryLists:"ProductCategoryListLocale",
		productIngredients:"ProductIngredientLocale",
		orderRecurrences:"OrderRecurrenceLocale",
		blogs:"BlogLocale",
		blogPosts:"BlogPostLocale",
		identifier:"TranslationCataloguesIdentifier",
		pages:"PageLocale",
		productIngredientCategories:"ProductIngredientCategoryLocale",
		allergens:"AllergenLocale",
		productPackings:"ProductPackingLocale",
		products:"ProductLocale",
		productRecipes:"ProductRecipeLocale",
		deliveryMethods:"DeliveryMethodLocale",
		ingredientSuppliers:"IngredientSupplierLocale",
		identificationCodes:"IdentificationCodeLocale",
		subscriptionBoxes:"SubscriptionBoxLocale",
		businessCategoriesByRoot:"BusinessCategoryLocale",
		productCategoryListsByRoot:"ProductCategoryListLocale",
		productIngredientsByRoot:"ProductIngredientLocale",
		orderRecurrencesByRoot:"OrderRecurrenceLocale",
		blogsByRoot:"BlogLocale",
		blogPostsByRoot:"BlogPostLocale",
		blogPostsByCover:"BlogPostLocale",
		blogPostsByContent:"BlogPostLocale",
		blogPostsByLink:"BlogPostLocale",
		blogPostsBySeo:"BlogPostLocale",
		pagesByRoot:"PageLocale",
		pagesByLink:"PageLocale",
		pagesBySeo:"PageLocale",
		pagesByContent:"PageLocale",
		pagesByCover:"PageLocale",
		productIngredientCategoriesByRoot:"ProductIngredientCategoryLocale",
		allergensByRoot:"AllergenLocale",
		productPackingsByRoot:"ProductPackingLocale",
		productsByRoot:"ProductLocale",
		productsByFeatureList:"ProductLocale",
		productRecipesByRoot:"ProductRecipeLocale",
		deliveryMethodsByRoot:"DeliveryMethodLocale",
		ingredientSuppliersByRoot:"IngredientSupplierLocale",
		identificationCodesByRoot:"IdentificationCodeLocale",
		subscriptionBoxesByRoot:"SubscriptionBoxLocale",
		subscriptionBoxesByTexts:"SubscriptionBoxLocale",
		subscriptionBoxesBySecondaryTexts:"SubscriptionBoxLocale",
		paginateBusinessCategories:"BusinessCategoryLocaleConnection",
		paginateProductCategoryLists:"ProductCategoryListLocaleConnection",
		paginateProductIngredients:"ProductIngredientLocaleConnection",
		paginateOrderRecurrences:"OrderRecurrenceLocaleConnection",
		paginateBlogs:"BlogLocaleConnection",
		paginateBlogPosts:"BlogPostLocaleConnection",
		paginatePages:"PageLocaleConnection",
		paginateProductIngredientCategories:"ProductIngredientCategoryLocaleConnection",
		paginateAllergens:"AllergenLocaleConnection",
		paginateProductPackings:"ProductPackingLocaleConnection",
		paginateProducts:"ProductLocaleConnection",
		paginateProductRecipes:"ProductRecipeLocaleConnection",
		paginateDeliveryMethods:"DeliveryMethodLocaleConnection",
		paginateIngredientSuppliers:"IngredientSupplierLocaleConnection",
		paginateIdentificationCodes:"IdentificationCodeLocaleConnection",
		paginateSubscriptionBoxes:"SubscriptionBoxLocaleConnection"
	},
	LocaleMeta:{
		id:"FieldMeta",
		businessCategories:"FieldMeta",
		productCategoryLists:"FieldMeta",
		productIngredients:"FieldMeta",
		orderRecurrences:"FieldMeta",
		blogs:"FieldMeta",
		blogPosts:"FieldMeta",
		identifier:"FieldMeta",
		pages:"FieldMeta",
		productIngredientCategories:"FieldMeta",
		allergens:"FieldMeta",
		productPackings:"FieldMeta",
		products:"FieldMeta",
		productRecipes:"FieldMeta",
		deliveryMethods:"FieldMeta",
		ingredientSuppliers:"FieldMeta",
		identificationCodes:"FieldMeta",
		subscriptionBoxes:"FieldMeta"
	},
	BusinessCategoryLocale:{
		_meta:"BusinessCategoryLocaleMeta",
		id:"UUID",
		name:"String",
		root:"BusinessCategory",
		locale:"Locale",
		title:"String"
	},
	BusinessCategoryLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		title:"FieldMeta"
	},
	BusinessCategory:{
		_meta:"BusinessCategoryMeta",
		id:"UUID",
		order:"Int",
		visibleToNewCustomers:"Boolean",
		locales:"BusinessCategoryLocale",
		internalName:"String",
		recurrences:"OrderRecurrence",
		specificProducts:"ProductList",
		seasonalProductCategories:"ProductCategoryList",
		specificProductsProductCategories:"ProductCategoryList",
		slug:"String",
		localesByLocale:"BusinessCategoryLocale",
		paginateLocales:"BusinessCategoryLocaleConnection",
		paginateRecurrences:"OrderRecurrenceConnection"
	},
	BusinessCategoryMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		visibleToNewCustomers:"FieldMeta",
		locales:"FieldMeta",
		internalName:"FieldMeta",
		recurrences:"FieldMeta",
		specificProducts:"FieldMeta",
		seasonalProductCategories:"FieldMeta",
		specificProductsProductCategories:"FieldMeta",
		slug:"FieldMeta"
	},
	OrderRecurrence:{
		_meta:"OrderRecurrenceMeta",
		id:"UUID",
		internalName:"String",
		locales:"OrderRecurrenceLocale",
		order:"Int",
		businessCategory:"BusinessCategory",
		localesByLocale:"OrderRecurrenceLocale",
		paginateLocales:"OrderRecurrenceLocaleConnection",
		paginateBusinessCategory:"BusinessCategoryConnection"
	},
	OrderRecurrenceMeta:{
		id:"FieldMeta",
		internalName:"FieldMeta",
		locales:"FieldMeta",
		order:"FieldMeta",
		businessCategory:"FieldMeta"
	},
	OrderRecurrenceLocale:{
		_meta:"OrderRecurrenceLocaleMeta",
		id:"UUID",
		name:"String",
		root:"OrderRecurrence",
		locale:"Locale"
	},
	OrderRecurrenceLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	OrderRecurrenceLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"OrderRecurrenceLocaleEdge"
	},
	PageInfo:{
		totalCount:"Int"
	},
	OrderRecurrenceLocaleEdge:{
		node:"OrderRecurrenceLocale"
	},
	BusinessCategoryConnection:{
		pageInfo:"PageInfo",
		edges:"BusinessCategoryEdge"
	},
	BusinessCategoryEdge:{
		node:"BusinessCategory"
	},
	ProductList:{
		_meta:"ProductListMeta",
		id:"UUID",
		items:"ProductListItem",
		businessCategory:"BusinessCategory",
		paginateItems:"ProductListItemConnection"
	},
	ProductListMeta:{
		id:"FieldMeta",
		items:"FieldMeta",
		businessCategory:"FieldMeta"
	},
	ProductListItem:{
		_meta:"ProductListItemMeta",
		id:"UUID",
		order:"Int",
		list:"ProductList",
		product:"Product",
		defaultQuantityForOrder:"Int"
	},
	ProductListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		product:"FieldMeta",
		defaultQuantityForOrder:"FieldMeta"
	},
	ProductListItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductListItemEdge"
	},
	ProductListItemEdge:{
		node:"ProductListItem"
	},
	ProductCategoryList:{
		_meta:"ProductCategoryListMeta",
		id:"UUID",
		items:"ProductCategoryListItem",
		locales:"ProductCategoryListLocale",
		seasonalProductCategoriesOfBusinessCategory:"BusinessCategory",
		specificProductsProductCategoriesOfBusinessCategory:"BusinessCategory",
		localesByLocale:"ProductCategoryListLocale",
		paginateItems:"ProductCategoryListItemConnection",
		paginateLocales:"ProductCategoryListLocaleConnection"
	},
	ProductCategoryListMeta:{
		id:"FieldMeta",
		items:"FieldMeta",
		locales:"FieldMeta",
		seasonalProductCategoriesOfBusinessCategory:"FieldMeta",
		specificProductsProductCategoriesOfBusinessCategory:"FieldMeta"
	},
	ProductCategoryListItem:{
		_meta:"ProductCategoryListItemMeta",
		id:"UUID",
		order:"Int",
		list:"ProductCategoryList",
		productCategory:"ProductCategory",
		showRecipeImages:"Boolean",
		showPackingImages:"Boolean",
		showAllAvailableProducts:"Boolean"
	},
	ProductCategoryListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		productCategory:"FieldMeta",
		showRecipeImages:"FieldMeta",
		showPackingImages:"FieldMeta",
		showAllAvailableProducts:"FieldMeta"
	},
	ProductCategory:{
		_meta:"ProductCategoryMeta",
		id:"UUID",
		name:"String",
		code:"String",
		products:"Product",
		tags:"Tag",
		parent:"ProductCategory",
		children:"ProductCategory",
		description:"String",
		internalNote:"String",
		color:"String",
		image:"Image",
		order:"Float",
		childrenByCode:"ProductCategory",
		childrenByChildren:"ProductCategory",
		paginateProducts:"ProductConnection",
		paginateTags:"TagConnection",
		paginateChildren:"ProductCategoryConnection"
	},
	ProductCategoryMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		products:"FieldMeta",
		tags:"FieldMeta",
		parent:"FieldMeta",
		children:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		color:"FieldMeta",
		image:"FieldMeta",
		order:"FieldMeta"
	},
	ProductConnection:{
		pageInfo:"PageInfo",
		edges:"ProductEdge"
	},
	ProductEdge:{
		node:"Product"
	},
	TagConnection:{
		pageInfo:"PageInfo",
		edges:"TagEdge"
	},
	TagEdge:{
		node:"Tag"
	},
	ProductCategoryConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryEdge"
	},
	ProductCategoryEdge:{
		node:"ProductCategory"
	},
	ProductCategoryListLocale:{
		_meta:"ProductCategoryListLocaleMeta",
		id:"UUID",
		name:"String",
		root:"ProductCategoryList",
		locale:"Locale"
	},
	ProductCategoryListLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	ProductCategoryListItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryListItemEdge"
	},
	ProductCategoryListItemEdge:{
		node:"ProductCategoryListItem"
	},
	ProductCategoryListLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryListLocaleEdge"
	},
	ProductCategoryListLocaleEdge:{
		node:"ProductCategoryListLocale"
	},
	BusinessCategoryLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"BusinessCategoryLocaleEdge"
	},
	BusinessCategoryLocaleEdge:{
		node:"BusinessCategoryLocale"
	},
	OrderRecurrenceConnection:{
		pageInfo:"PageInfo",
		edges:"OrderRecurrenceEdge"
	},
	OrderRecurrenceEdge:{
		node:"OrderRecurrence"
	},
	ProductIngredientLocale:{
		_meta:"ProductIngredientLocaleMeta",
		id:"UUID",
		name:"String",
		root:"ProductIngredient",
		locale:"Locale"
	},
	ProductIngredientLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	ProductIngredient:{
		_meta:"ProductIngredientMeta",
		id:"UUID",
		internalNote:"String",
		locales:"ProductIngredientLocale",
		internalName:"String",
		categories:"ProductIngredientCategory",
		allergens:"Allergen",
		needsPrepping:"Boolean",
		allowCustomerRating:"Boolean",
		preferredQuantityUnit:"ProductIngredientItemQuantityUnit",
		freshingContainer:"FreshingContainer",
		image:"Image",
		suppliers:"IngredientSupplierList",
		incrementQuantityNumber:"Float",
		localesByLocale:"ProductIngredientLocale",
		paginateLocales:"ProductIngredientLocaleConnection",
		paginateCategories:"ProductIngredientCategoryConnection",
		paginateAllergens:"AllergenConnection"
	},
	ProductIngredientMeta:{
		id:"FieldMeta",
		internalNote:"FieldMeta",
		locales:"FieldMeta",
		internalName:"FieldMeta",
		categories:"FieldMeta",
		allergens:"FieldMeta",
		needsPrepping:"FieldMeta",
		allowCustomerRating:"FieldMeta",
		preferredQuantityUnit:"FieldMeta",
		freshingContainer:"FieldMeta",
		image:"FieldMeta",
		suppliers:"FieldMeta",
		incrementQuantityNumber:"FieldMeta"
	},
	ProductIngredientCategory:{
		_meta:"ProductIngredientCategoryMeta",
		id:"UUID",
		internalName:"String",
		internalNote:"String",
		ingredients:"ProductIngredient",
		locales:"ProductIngredientCategoryLocale",
		order:"Int",
		localesByLocale:"ProductIngredientCategoryLocale",
		paginateIngredients:"ProductIngredientConnection",
		paginateLocales:"ProductIngredientCategoryLocaleConnection"
	},
	ProductIngredientCategoryMeta:{
		id:"FieldMeta",
		internalName:"FieldMeta",
		internalNote:"FieldMeta",
		ingredients:"FieldMeta",
		locales:"FieldMeta",
		order:"FieldMeta"
	},
	ProductIngredientCategoryLocale:{
		_meta:"ProductIngredientCategoryLocaleMeta",
		id:"UUID",
		name:"String",
		root:"ProductIngredientCategory",
		locale:"Locale"
	},
	ProductIngredientCategoryLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	ProductIngredientConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientEdge"
	},
	ProductIngredientEdge:{
		node:"ProductIngredient"
	},
	ProductIngredientCategoryLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientCategoryLocaleEdge"
	},
	ProductIngredientCategoryLocaleEdge:{
		node:"ProductIngredientCategoryLocale"
	},
	Allergen:{
		_meta:"AllergenMeta",
		id:"UUID",
		internalName:"String",
		locales:"AllergenLocale",
		ingredients:"ProductIngredient",
		code:"Int",
		localesByLocale:"AllergenLocale",
		paginateLocales:"AllergenLocaleConnection",
		paginateIngredients:"ProductIngredientConnection"
	},
	AllergenMeta:{
		id:"FieldMeta",
		internalName:"FieldMeta",
		locales:"FieldMeta",
		ingredients:"FieldMeta",
		code:"FieldMeta"
	},
	AllergenLocale:{
		_meta:"AllergenLocaleMeta",
		id:"UUID",
		name:"String",
		root:"Allergen",
		locale:"Locale"
	},
	AllergenLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	AllergenLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"AllergenLocaleEdge"
	},
	AllergenLocaleEdge:{
		node:"AllergenLocale"
	},
	ProductIngredientItemQuantityUnit:{
		_meta:"ProductIngredientItemQuantityUnitMeta",
		id:"UUID",
		name:"String"
	},
	ProductIngredientItemQuantityUnitMeta:{
		id:"FieldMeta",
		name:"FieldMeta"
	},
	FreshingContainer:{
		_meta:"FreshingContainerMeta",
		id:"UUID",
		order:"Int",
		volumeMl:"Int",
		workspace:"Workspace"
	},
	FreshingContainerMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		volumeMl:"FieldMeta",
		workspace:"FieldMeta"
	},
	Workspace:{
		_meta:"WorkspaceMeta",
		id:"UUID",
		unique:"One",
		orderDeadlineTime:"String",
		ordersLoadingDurationHours:"Int",
		freshingContainerVolumeMl:"Int",
		freshingPrepareOrderByOffsetMinutes:"Int",
		checkoutUrlTemplate:"String",
		orderUrlTemplate:"String",
		paymentUrlTemplate:"String",
		customerProfileUrlTemplate:"String",
		userProfileUrlTemplate:"String",
		messengerUrl:"String",
		messengerIosUrl:"String",
		messengerAndroidUrl:"String",
		freshingContainers:"FreshingContainer",
		openingHours:"WeekHours",
		workingHours:"WeekHours",
		defaultFreshingContainer:"FreshingContainer",
		creditsProduct:"VirtualProduct",
		discountVatRate:"VatRate",
		name:"String",
		businessAddress:"String",
		paginateFreshingContainers:"FreshingContainerConnection"
	},
	WorkspaceMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		orderDeadlineTime:"FieldMeta",
		ordersLoadingDurationHours:"FieldMeta",
		freshingContainerVolumeMl:"FieldMeta",
		freshingPrepareOrderByOffsetMinutes:"FieldMeta",
		checkoutUrlTemplate:"FieldMeta",
		orderUrlTemplate:"FieldMeta",
		paymentUrlTemplate:"FieldMeta",
		customerProfileUrlTemplate:"FieldMeta",
		userProfileUrlTemplate:"FieldMeta",
		messengerUrl:"FieldMeta",
		messengerIosUrl:"FieldMeta",
		messengerAndroidUrl:"FieldMeta",
		freshingContainers:"FieldMeta",
		openingHours:"FieldMeta",
		workingHours:"FieldMeta",
		defaultFreshingContainer:"FieldMeta",
		creditsProduct:"FieldMeta",
		discountVatRate:"FieldMeta",
		name:"FieldMeta",
		businessAddress:"FieldMeta"
	},
	WeekHours:{
		_meta:"WeekHoursMeta",
		id:"UUID",
		mon:"String",
		tue:"String",
		wed:"String",
		thu:"String",
		fri:"String",
		sat:"String",
		sun:"String"
	},
	WeekHoursMeta:{
		id:"FieldMeta",
		mon:"FieldMeta",
		tue:"FieldMeta",
		wed:"FieldMeta",
		thu:"FieldMeta",
		fri:"FieldMeta",
		sat:"FieldMeta",
		sun:"FieldMeta"
	},
	VirtualProduct:{
		_meta:"VirtualProductMeta",
		id:"UUID",
		type:"VirtualProductType",
		product:"Product",
		voucherCreditCents:"Int",
		physicalRepresentation:"VirtualProductPhysicalRepresentationType",
		recipientEmail:"Boolean"
	},
	VirtualProductMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		product:"FieldMeta",
		voucherCreditCents:"FieldMeta",
		physicalRepresentation:"FieldMeta",
		recipientEmail:"FieldMeta"
	},
	VatRate:{
		_meta:"VatRateMeta",
		id:"UUID",
		name:"String",
		ratePermille:"Int",
		description:"String",
		internalNote:"String",
		preset:"VatRatePreset"
	},
	VatRateMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		ratePermille:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		preset:"FieldMeta"
	},
	FreshingContainerConnection:{
		pageInfo:"PageInfo",
		edges:"FreshingContainerEdge"
	},
	FreshingContainerEdge:{
		node:"FreshingContainer"
	},
	IngredientSupplierList:{
		_meta:"IngredientSupplierListMeta",
		id:"UUID",
		items:"IngredientSupplierItem",
		paginateItems:"IngredientSupplierItemConnection"
	},
	IngredientSupplierListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	IngredientSupplierItem:{
		_meta:"IngredientSupplierItemMeta",
		id:"UUID",
		order:"Int",
		list:"IngredientSupplierList",
		supplier:"IngredientSupplier"
	},
	IngredientSupplierItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		supplier:"FieldMeta"
	},
	IngredientSupplier:{
		_meta:"IngredientSupplierMeta",
		id:"UUID",
		internalName:"String",
		internalNote:"String",
		locales:"IngredientSupplierLocale",
		localesByLocale:"IngredientSupplierLocale",
		paginateLocales:"IngredientSupplierLocaleConnection"
	},
	IngredientSupplierMeta:{
		id:"FieldMeta",
		internalName:"FieldMeta",
		internalNote:"FieldMeta",
		locales:"FieldMeta"
	},
	IngredientSupplierLocale:{
		_meta:"IngredientSupplierLocaleMeta",
		id:"UUID",
		name:"String",
		root:"IngredientSupplier",
		locale:"Locale"
	},
	IngredientSupplierLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	IngredientSupplierLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"IngredientSupplierLocaleEdge"
	},
	IngredientSupplierLocaleEdge:{
		node:"IngredientSupplierLocale"
	},
	IngredientSupplierItemConnection:{
		pageInfo:"PageInfo",
		edges:"IngredientSupplierItemEdge"
	},
	IngredientSupplierItemEdge:{
		node:"IngredientSupplierItem"
	},
	ProductIngredientLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientLocaleEdge"
	},
	ProductIngredientLocaleEdge:{
		node:"ProductIngredientLocale"
	},
	ProductIngredientCategoryConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientCategoryEdge"
	},
	ProductIngredientCategoryEdge:{
		node:"ProductIngredientCategory"
	},
	AllergenConnection:{
		pageInfo:"PageInfo",
		edges:"AllergenEdge"
	},
	AllergenEdge:{
		node:"Allergen"
	},
	BlogLocale:{
		_meta:"BlogLocaleMeta",
		id:"UUID",
		pageName:"String",
		title:"String",
		lead:"String",
		root:"Blog",
		locale:"Locale"
	},
	BlogLocaleMeta:{
		id:"FieldMeta",
		pageName:"FieldMeta",
		title:"FieldMeta",
		lead:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	Blog:{
		_meta:"BlogMeta",
		id:"UUID",
		unique:"One",
		locales:"BlogLocale",
		localesByLocale:"BlogLocale",
		paginateLocales:"BlogLocaleConnection"
	},
	BlogMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta"
	},
	BlogLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"BlogLocaleEdge"
	},
	BlogLocaleEdge:{
		node:"BlogLocale"
	},
	BlogPostLocale:{
		_meta:"BlogPostLocaleMeta",
		id:"UUID",
		title:"String",
		availableForWeb:"Boolean",
		availableForMobile:"Boolean",
		root:"BlogPost",
		locale:"Locale",
		cover:"Cover",
		content:"Content",
		link:"Linkable",
		seo:"Seo",
		products:"Product",
		paginateProducts:"ProductConnection"
	},
	BlogPostLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		availableForWeb:"FieldMeta",
		availableForMobile:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		cover:"FieldMeta",
		content:"FieldMeta",
		link:"FieldMeta",
		seo:"FieldMeta",
		products:"FieldMeta"
	},
	BlogPost:{
		_meta:"BlogPostMeta",
		id:"UUID",
		order:"Int",
		locales:"BlogPostLocale",
		localesByLocale:"BlogPostLocale",
		localesByCover:"BlogPostLocale",
		localesByContent:"BlogPostLocale",
		localesByLink:"BlogPostLocale",
		localesBySeo:"BlogPostLocale",
		paginateLocales:"BlogPostLocaleConnection"
	},
	BlogPostMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		locales:"FieldMeta"
	},
	BlogPostLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostLocaleEdge"
	},
	BlogPostLocaleEdge:{
		node:"BlogPostLocale"
	},
	Cover:{
		_meta:"CoverMeta",
		id:"UUID",
		primaryTitle:"String",
		secondaryTitle:"String",
		medium:"Medium",
		buttonColors:"ColorPallete"
	},
	CoverMeta:{
		id:"FieldMeta",
		primaryTitle:"FieldMeta",
		secondaryTitle:"FieldMeta",
		medium:"FieldMeta",
		buttonColors:"FieldMeta"
	},
	Medium:{
		_meta:"MediumMeta",
		id:"UUID",
		type:"MediumType",
		colorTheme:"ColorTheme",
		image:"Image",
		video:"Video"
	},
	MediumMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		colorTheme:"FieldMeta",
		image:"FieldMeta",
		video:"FieldMeta"
	},
	Video:{
		_meta:"VideoMeta",
		id:"UUID",
		src:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		duration:"Float",
		poster:"Image"
	},
	VideoMeta:{
		id:"FieldMeta",
		src:"FieldMeta",
		width:"FieldMeta",
		height:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		duration:"FieldMeta",
		poster:"FieldMeta"
	},
	ColorPallete:{
		_meta:"ColorPalleteMeta",
		id:"UUID",
		background:"String",
		text:"String"
	},
	ColorPalleteMeta:{
		id:"FieldMeta",
		background:"FieldMeta",
		text:"FieldMeta"
	},
	Content:{
		_meta:"ContentMeta",
		id:"UUID",
		blocks:"ContentBlock",
		blocksByReferences:"ContentBlock",
		paginateBlocks:"ContentBlockConnection"
	},
	ContentMeta:{
		id:"FieldMeta",
		blocks:"FieldMeta"
	},
	ContentBlock:{
		_meta:"ContentBlockMeta",
		id:"UUID",
		order:"Int",
		json:"String",
		content:"Content",
		references:"ContentReference",
		referencesByProducts:"ContentReference",
		referencesByProductBanners:"ContentReference",
		referencesByDeliveryRegions:"ContentReference",
		paginateReferences:"ContentReferenceConnection"
	},
	ContentBlockMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		json:"FieldMeta",
		content:"FieldMeta",
		references:"FieldMeta"
	},
	ContentReference:{
		_meta:"ContentReferenceMeta",
		id:"UUID",
		type:"ContentReferenceType",
		primaryText:"String",
		secondaryText:"String",
		jsonContent:"String",
		block:"ContentBlock",
		colorPallete:"ColorPallete",
		medium:"Medium",
		link:"Link",
		blogPosts:"BlogPostList",
		links:"LinkList",
		products:"ProductList",
		contentSize:"ContentSize",
		misc:"String",
		hero:"Hero",
		gallery:"Gallery",
		socialsAndApps:"SocialsAndApps",
		productGrid:"ProductGrid",
		productBanners:"ProductBannerList",
		deliveryRegions:"DeliveryRegions",
		textList:"TextList",
		gridTiles:"GridTiles"
	},
	ContentReferenceMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		primaryText:"FieldMeta",
		secondaryText:"FieldMeta",
		jsonContent:"FieldMeta",
		block:"FieldMeta",
		colorPallete:"FieldMeta",
		medium:"FieldMeta",
		link:"FieldMeta",
		blogPosts:"FieldMeta",
		links:"FieldMeta",
		products:"FieldMeta",
		contentSize:"FieldMeta",
		misc:"FieldMeta",
		hero:"FieldMeta",
		gallery:"FieldMeta",
		socialsAndApps:"FieldMeta",
		productGrid:"FieldMeta",
		productBanners:"FieldMeta",
		deliveryRegions:"FieldMeta",
		textList:"FieldMeta",
		gridTiles:"FieldMeta"
	},
	Link:{
		_meta:"LinkMeta",
		id:"UUID",
		type:"LinkType",
		title:"String",
		externalLink:"String",
		internalLink:"Linkable"
	},
	LinkMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		title:"FieldMeta",
		externalLink:"FieldMeta",
		internalLink:"FieldMeta"
	},
	Linkable:{
		_meta:"LinkableMeta",
		id:"UUID",
		url:"String",
		blogPost:"BlogPostLocale",
		page:"PageLocale",
		redirect:"Redirect"
	},
	LinkableMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		blogPost:"FieldMeta",
		page:"FieldMeta",
		redirect:"FieldMeta"
	},
	PageLocale:{
		_meta:"PageLocaleMeta",
		id:"UUID",
		title:"String",
		link:"Linkable",
		root:"Page",
		locale:"Locale",
		seo:"Seo",
		content:"Content",
		cover:"Cover",
		theme:"PageTheme"
	},
	PageLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		link:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		seo:"FieldMeta",
		content:"FieldMeta",
		cover:"FieldMeta",
		theme:"FieldMeta"
	},
	Page:{
		_meta:"PageMeta",
		id:"UUID",
		locales:"PageLocale",
		localesByLocale:"PageLocale",
		localesByLink:"PageLocale",
		localesBySeo:"PageLocale",
		localesByContent:"PageLocale",
		localesByCover:"PageLocale",
		paginateLocales:"PageLocaleConnection"
	},
	PageMeta:{
		id:"FieldMeta",
		locales:"FieldMeta"
	},
	PageLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"PageLocaleEdge"
	},
	PageLocaleEdge:{
		node:"PageLocale"
	},
	Seo:{
		_meta:"SeoMeta",
		id:"UUID",
		title:"String",
		description:"String",
		ogTitle:"String",
		ogDescription:"String",
		ogImage:"Image"
	},
	SeoMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		description:"FieldMeta",
		ogTitle:"FieldMeta",
		ogDescription:"FieldMeta",
		ogImage:"FieldMeta"
	},
	Redirect:{
		_meta:"RedirectMeta",
		id:"UUID",
		link:"Linkable",
		target:"Link"
	},
	RedirectMeta:{
		id:"FieldMeta",
		link:"FieldMeta",
		target:"FieldMeta"
	},
	BlogPostList:{
		_meta:"BlogPostListMeta",
		id:"UUID",
		items:"BlogPostListItem",
		paginateItems:"BlogPostListItemConnection"
	},
	BlogPostListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	BlogPostListItem:{
		_meta:"BlogPostListItemMeta",
		id:"UUID",
		order:"Int",
		caption:"String",
		list:"BlogPostList",
		blogPost:"BlogPostLocale"
	},
	BlogPostListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		caption:"FieldMeta",
		list:"FieldMeta",
		blogPost:"FieldMeta"
	},
	BlogPostListItemConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostListItemEdge"
	},
	BlogPostListItemEdge:{
		node:"BlogPostListItem"
	},
	LinkList:{
		_meta:"LinkListMeta",
		id:"UUID",
		items:"LinkListItem",
		paginateItems:"LinkListItemConnection"
	},
	LinkListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	LinkListItem:{
		_meta:"LinkListItemMeta",
		id:"UUID",
		order:"Int",
		isPrimary:"Boolean",
		list:"LinkList",
		link:"Link"
	},
	LinkListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		isPrimary:"FieldMeta",
		list:"FieldMeta",
		link:"FieldMeta"
	},
	LinkListItemConnection:{
		pageInfo:"PageInfo",
		edges:"LinkListItemEdge"
	},
	LinkListItemEdge:{
		node:"LinkListItem"
	},
	Hero:{
		_meta:"HeroMeta",
		id:"UUID",
		showLogo:"Boolean",
		middleContent:"String",
		medium:"Medium"
	},
	HeroMeta:{
		id:"FieldMeta",
		showLogo:"FieldMeta",
		middleContent:"FieldMeta",
		medium:"FieldMeta"
	},
	Gallery:{
		_meta:"GalleryMeta",
		id:"UUID",
		items:"GalleryItem",
		paginateItems:"GalleryItemConnection"
	},
	GalleryMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	GalleryItem:{
		_meta:"GalleryItemMeta",
		id:"UUID",
		order:"Int",
		caption:"String",
		list:"Gallery",
		medium:"Medium"
	},
	GalleryItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		caption:"FieldMeta",
		list:"FieldMeta",
		medium:"FieldMeta"
	},
	GalleryItemConnection:{
		pageInfo:"PageInfo",
		edges:"GalleryItemEdge"
	},
	GalleryItemEdge:{
		node:"GalleryItem"
	},
	SocialsAndApps:{
		_meta:"SocialsAndAppsMeta",
		id:"UUID",
		instagram:"Boolean",
		appStore:"Boolean",
		googlePlay:"Boolean"
	},
	SocialsAndAppsMeta:{
		id:"FieldMeta",
		instagram:"FieldMeta",
		appStore:"FieldMeta",
		googlePlay:"FieldMeta"
	},
	ProductGrid:{
		_meta:"ProductGridMeta",
		id:"UUID",
		isCarousel:"Boolean",
		categories:"ProductGridCategory",
		type:"ProductGridType",
		categoriesByItems:"ProductGridCategory",
		categoriesByImages:"ProductGridCategory",
		paginateCategories:"ProductGridCategoryConnection"
	},
	ProductGridMeta:{
		id:"FieldMeta",
		isCarousel:"FieldMeta",
		categories:"FieldMeta",
		type:"FieldMeta"
	},
	ProductGridCategory:{
		_meta:"ProductGridCategoryMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		root:"ProductGrid",
		items:"ProductGridItem",
		images:"ImageList",
		paginateItems:"ProductGridItemConnection"
	},
	ProductGridCategoryMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		root:"FieldMeta",
		items:"FieldMeta",
		images:"FieldMeta"
	},
	ProductGridItem:{
		_meta:"ProductGridItemMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		lead:"String",
		product:"Product",
		medium:"Medium",
		link:"Link",
		category:"ProductGridCategory",
		description:"String"
	},
	ProductGridItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		lead:"FieldMeta",
		product:"FieldMeta",
		medium:"FieldMeta",
		link:"FieldMeta",
		category:"FieldMeta",
		description:"FieldMeta"
	},
	ImageList:{
		_meta:"ImageListMeta",
		id:"UUID",
		items:"ImageListItem",
		itemsByImage:"ImageListItem",
		paginateItems:"ImageListItemConnection"
	},
	ImageListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	ImageListItem:{
		_meta:"ImageListItemMeta",
		id:"UUID",
		order:"Int",
		list:"ImageList",
		image:"Image"
	},
	ImageListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		image:"FieldMeta"
	},
	ImageListItemConnection:{
		pageInfo:"PageInfo",
		edges:"ImageListItemEdge"
	},
	ImageListItemEdge:{
		node:"ImageListItem"
	},
	ProductGridItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductGridItemEdge"
	},
	ProductGridItemEdge:{
		node:"ProductGridItem"
	},
	ProductGridCategoryConnection:{
		pageInfo:"PageInfo",
		edges:"ProductGridCategoryEdge"
	},
	ProductGridCategoryEdge:{
		node:"ProductGridCategory"
	},
	ProductBannerList:{
		_meta:"ProductBannerListMeta",
		id:"UUID",
		items:"ProductBannerItem",
		paginateItems:"ProductBannerItemConnection"
	},
	ProductBannerListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	ProductBannerItem:{
		_meta:"ProductBannerItemMeta",
		id:"UUID",
		order:"Int",
		list:"ProductBannerList",
		banner:"ProductBanner",
		size:"ProductBannerItemSize"
	},
	ProductBannerItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		banner:"FieldMeta",
		size:"FieldMeta"
	},
	ProductBanner:{
		_meta:"ProductBannerMeta",
		id:"UUID",
		beforeTitle:"String",
		title:"String",
		label:"String",
		button:"Link",
		backgroundImage:"Image",
		productImage:"Image",
		product:"Product"
	},
	ProductBannerMeta:{
		id:"FieldMeta",
		beforeTitle:"FieldMeta",
		title:"FieldMeta",
		label:"FieldMeta",
		button:"FieldMeta",
		backgroundImage:"FieldMeta",
		productImage:"FieldMeta",
		product:"FieldMeta"
	},
	ProductBannerItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductBannerItemEdge"
	},
	ProductBannerItemEdge:{
		node:"ProductBannerItem"
	},
	DeliveryRegions:{
		_meta:"DeliveryRegionsMeta",
		id:"UUID",
		items:"DeliveryRegionsItem",
		itemsByImage:"DeliveryRegionsItem",
		itemsByTextList:"DeliveryRegionsItem",
		itemsByGallery:"DeliveryRegionsItem",
		paginateItems:"DeliveryRegionsItemConnection"
	},
	DeliveryRegionsMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	DeliveryRegionsItem:{
		_meta:"DeliveryRegionsItemMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		offerLabel:"String",
		list:"DeliveryRegions",
		image:"Image",
		textList:"TextList",
		gallery:"ImageList"
	},
	DeliveryRegionsItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		offerLabel:"FieldMeta",
		list:"FieldMeta",
		image:"FieldMeta",
		textList:"FieldMeta",
		gallery:"FieldMeta"
	},
	TextList:{
		_meta:"TextListMeta",
		id:"UUID",
		items:"TextItem",
		paginateItems:"TextItemConnection"
	},
	TextListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	TextItem:{
		_meta:"TextItemMeta",
		id:"UUID",
		order:"Int",
		text:"String",
		list:"TextList",
		title:"String"
	},
	TextItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		text:"FieldMeta",
		list:"FieldMeta",
		title:"FieldMeta"
	},
	TextItemConnection:{
		pageInfo:"PageInfo",
		edges:"TextItemEdge"
	},
	TextItemEdge:{
		node:"TextItem"
	},
	DeliveryRegionsItemConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryRegionsItemEdge"
	},
	DeliveryRegionsItemEdge:{
		node:"DeliveryRegionsItem"
	},
	GridTiles:{
		_meta:"GridTilesMeta",
		id:"UUID",
		items:"GridTileItem",
		itemsByLink:"GridTileItem",
		paginateItems:"GridTileItemConnection"
	},
	GridTilesMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	GridTileItem:{
		_meta:"GridTileItemMeta",
		id:"UUID",
		order:"Int",
		text:"String",
		list:"GridTiles",
		medium:"Medium",
		link:"Link"
	},
	GridTileItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		text:"FieldMeta",
		list:"FieldMeta",
		medium:"FieldMeta",
		link:"FieldMeta"
	},
	GridTileItemConnection:{
		pageInfo:"PageInfo",
		edges:"GridTileItemEdge"
	},
	GridTileItemEdge:{
		node:"GridTileItem"
	},
	ContentReferenceConnection:{
		pageInfo:"PageInfo",
		edges:"ContentReferenceEdge"
	},
	ContentReferenceEdge:{
		node:"ContentReference"
	},
	ContentBlockConnection:{
		pageInfo:"PageInfo",
		edges:"ContentBlockEdge"
	},
	ContentBlockEdge:{
		node:"ContentBlock"
	},
	TranslationCataloguesIdentifier:{
		_meta:"TranslationCataloguesIdentifierMeta",
		id:"UUID",
		name:"String",
		code:"String",
		catalogue:"TranslationCatalogue",
		catalogueByDomain:"TranslationCatalogue",
		catalogueByValues:"TranslationCatalogue",
		paginateCatalogue:"TranslationCatalogueConnection"
	},
	TranslationCataloguesIdentifierMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		catalogue:"FieldMeta"
	},
	TranslationCatalogue:{
		_meta:"TranslationCatalogueMeta",
		id:"UUID",
		domain:"TranslationDomain",
		fallback:"TranslationCatalogue",
		values:"TranslationValue",
		identifier:"TranslationCataloguesIdentifier",
		valuesByKey:"TranslationValue",
		paginateValues:"TranslationValueConnection"
	},
	TranslationCatalogueMeta:{
		id:"FieldMeta",
		domain:"FieldMeta",
		fallback:"FieldMeta",
		values:"FieldMeta",
		identifier:"FieldMeta"
	},
	TranslationDomain:{
		_meta:"TranslationDomainMeta",
		id:"UUID",
		identifier:"String",
		name:"String",
		catalogues:"TranslationCatalogue",
		keys:"TranslationKey",
		cataloguesByIdentifier:"TranslationCatalogue",
		cataloguesByValues:"TranslationCatalogue",
		keysByIdentifier:"TranslationKey",
		keysByValues:"TranslationKey",
		paginateCatalogues:"TranslationCatalogueConnection",
		paginateKeys:"TranslationKeyConnection"
	},
	TranslationDomainMeta:{
		id:"FieldMeta",
		identifier:"FieldMeta",
		name:"FieldMeta",
		catalogues:"FieldMeta",
		keys:"FieldMeta"
	},
	TranslationKey:{
		_meta:"TranslationKeyMeta",
		id:"UUID",
		identifier:"String",
		contentType:"TranslationContentType",
		note:"String",
		domain:"TranslationDomain",
		values:"TranslationValue",
		valuesByCatalogue:"TranslationValue",
		paginateValues:"TranslationValueConnection"
	},
	TranslationKeyMeta:{
		id:"FieldMeta",
		identifier:"FieldMeta",
		contentType:"FieldMeta",
		note:"FieldMeta",
		domain:"FieldMeta",
		values:"FieldMeta"
	},
	TranslationValue:{
		_meta:"TranslationValueMeta",
		id:"UUID",
		value:"String",
		catalogue:"TranslationCatalogue",
		key:"TranslationKey"
	},
	TranslationValueMeta:{
		id:"FieldMeta",
		value:"FieldMeta",
		catalogue:"FieldMeta",
		key:"FieldMeta"
	},
	TranslationValueConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationValueEdge"
	},
	TranslationValueEdge:{
		node:"TranslationValue"
	},
	TranslationCatalogueConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationCatalogueEdge"
	},
	TranslationCatalogueEdge:{
		node:"TranslationCatalogue"
	},
	TranslationKeyConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationKeyEdge"
	},
	TranslationKeyEdge:{
		node:"TranslationKey"
	},
	ProductLocale:{
		_meta:"ProductLocaleMeta",
		id:"UUID",
		name:"String",
		description:"String",
		root:"Product",
		locale:"Locale",
		featureList:"TextList",
		printableName:"String",
		firstSticker:"String",
		secondSticker:"String"
	},
	ProductLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		description:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		featureList:"FieldMeta",
		printableName:"FieldMeta",
		firstSticker:"FieldMeta",
		secondSticker:"FieldMeta"
	},
	ProductRecipeLocale:{
		_meta:"ProductRecipeLocaleMeta",
		id:"UUID",
		name:"String",
		root:"ProductRecipe",
		locale:"Locale",
		description:"String"
	},
	ProductRecipeLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		description:"FieldMeta"
	},
	ProductRecipe:{
		_meta:"ProductRecipeMeta",
		id:"UUID",
		name:"String",
		products:"Product",
		internalNote:"String",
		color:"String",
		image:"Image",
		icon:"Image",
		ingredients:"ProductIngredientItem",
		needsPrepping:"Boolean",
		locales:"ProductRecipeLocale",
		gutTuning:"Float",
		freshingContainer:"FreshingContainer",
		description:"String",
		productsByCode:"Product",
		productsByStocks:"Product",
		productsByOrderItems:"Product",
		productsByLocales:"Product",
		productsByVirtualProduct:"Product",
		productsByMeta:"Product",
		productsByPage:"Product",
		ingredientsByQuantity:"ProductIngredientItem",
		localesByLocale:"ProductRecipeLocale",
		paginateProducts:"ProductConnection",
		paginateIngredients:"ProductIngredientItemConnection",
		paginateLocales:"ProductRecipeLocaleConnection"
	},
	ProductRecipeMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		products:"FieldMeta",
		internalNote:"FieldMeta",
		color:"FieldMeta",
		image:"FieldMeta",
		icon:"FieldMeta",
		ingredients:"FieldMeta",
		needsPrepping:"FieldMeta",
		locales:"FieldMeta",
		gutTuning:"FieldMeta",
		freshingContainer:"FieldMeta",
		description:"FieldMeta"
	},
	ProductIngredientItem:{
		_meta:"ProductIngredientItemMeta",
		id:"UUID",
		order:"Int",
		internalOrder:"Int",
		recipe:"ProductRecipe",
		ingredient:"ProductIngredient",
		quantity:"ProductIngredientItemQuantity"
	},
	ProductIngredientItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		internalOrder:"FieldMeta",
		recipe:"FieldMeta",
		ingredient:"FieldMeta",
		quantity:"FieldMeta"
	},
	ProductIngredientItemQuantity:{
		_meta:"ProductIngredientItemQuantityMeta",
		id:"UUID",
		unit:"ProductIngredientItemQuantityUnit",
		value:"Float"
	},
	ProductIngredientItemQuantityMeta:{
		id:"FieldMeta",
		unit:"FieldMeta",
		value:"FieldMeta"
	},
	ProductIngredientItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientItemEdge"
	},
	ProductIngredientItemEdge:{
		node:"ProductIngredientItem"
	},
	ProductRecipeLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductRecipeLocaleEdge"
	},
	ProductRecipeLocaleEdge:{
		node:"ProductRecipeLocale"
	},
	DeliveryMethodLocale:{
		_meta:"DeliveryMethodLocaleMeta",
		id:"UUID",
		name:"String",
		description:"String",
		trackingCodeUrlTemplate:"String",
		processingTitle:"String",
		processingText:"String",
		processingIconUrl:"String",
		dispatchedTitle:"String",
		dispatchedText:"String",
		dispatchedIconUrl:"String",
		fulfilledTitle:"String",
		fulfilledText:"String",
		fulfilledIconUrl:"String",
		locale:"Locale",
		root:"DeliveryMethod"
	},
	DeliveryMethodLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		description:"FieldMeta",
		trackingCodeUrlTemplate:"FieldMeta",
		processingTitle:"FieldMeta",
		processingText:"FieldMeta",
		processingIconUrl:"FieldMeta",
		dispatchedTitle:"FieldMeta",
		dispatchedText:"FieldMeta",
		dispatchedIconUrl:"FieldMeta",
		fulfilledTitle:"FieldMeta",
		fulfilledText:"FieldMeta",
		fulfilledIconUrl:"FieldMeta",
		locale:"FieldMeta",
		root:"FieldMeta"
	},
	DeliveryMethod:{
		_meta:"DeliveryMethodMeta",
		id:"UUID",
		name:"String",
		code:"String",
		enabled:"Boolean",
		paymentMethods:"PaymentMethod",
		description:"String",
		internalNote:"String",
		requireDeliveryAddress:"Boolean",
		suitableForDeliveryZones:"DeliveryZone",
		enableDeliveryTracking:"Boolean",
		locales:"DeliveryMethodLocale",
		timelinePreset:"DeliveryTimelinePreset",
		localesByLocale:"DeliveryMethodLocale",
		paginatePaymentMethods:"PaymentMethodConnection",
		paginateSuitableForDeliveryZones:"DeliveryZoneConnection",
		paginateLocales:"DeliveryMethodLocaleConnection"
	},
	DeliveryMethodMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		enabled:"FieldMeta",
		paymentMethods:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		requireDeliveryAddress:"FieldMeta",
		suitableForDeliveryZones:"FieldMeta",
		enableDeliveryTracking:"FieldMeta",
		locales:"FieldMeta",
		timelinePreset:"FieldMeta"
	},
	PaymentMethod:{
		_meta:"PaymentMethodMeta",
		id:"UUID",
		name:"String",
		code:"String",
		enabled:"Boolean",
		deliveryMethods:"DeliveryMethod",
		description:"String",
		internalNote:"String",
		blockingDispatch:"Boolean",
		type:"PaymentMethodType",
		reduceGratuityByPercentage:"Int",
		paginateDeliveryMethods:"DeliveryMethodConnection"
	},
	PaymentMethodMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		enabled:"FieldMeta",
		deliveryMethods:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		blockingDispatch:"FieldMeta",
		type:"FieldMeta",
		reduceGratuityByPercentage:"FieldMeta"
	},
	DeliveryMethodConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryMethodEdge"
	},
	DeliveryMethodEdge:{
		node:"DeliveryMethod"
	},
	DeliveryZone:{
		_meta:"DeliveryZoneMeta",
		id:"UUID",
		name:"String",
		code:"String",
		description:"String",
		internalNote:"String",
		type:"DeliveryZoneType",
		addresses:"Address",
		suitableDeliveryMethods:"DeliveryMethod",
		deliveryZonesOfTags:"Tag",
		deliveryZonesOfProducts:"Product",
		addressesByBillingAddressOfOrders:"Address",
		addressesByDeliveryAddressOfOrderDeliveries:"Address",
		addressesByDefaultDeliveryAddressOfCustomer:"Address",
		paginateAddresses:"AddressConnection",
		paginateSuitableDeliveryMethods:"DeliveryMethodConnection",
		paginateDeliveryZonesOfTags:"TagConnection",
		paginateDeliveryZonesOfProducts:"ProductConnection"
	},
	DeliveryZoneMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		type:"FieldMeta",
		addresses:"FieldMeta",
		suitableDeliveryMethods:"FieldMeta",
		deliveryZonesOfTags:"FieldMeta",
		deliveryZonesOfProducts:"FieldMeta"
	},
	AddressConnection:{
		pageInfo:"PageInfo",
		edges:"AddressEdge"
	},
	AddressEdge:{
		node:"Address"
	},
	DeliveryTimelinePreset:{
		_meta:"DeliveryTimelinePresetMeta",
		id:"UUID",
		type:"DeliveryTimelineType",
		duration:"String",
		window:"String",
		deliveryMethod:"DeliveryMethod"
	},
	DeliveryTimelinePresetMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		duration:"FieldMeta",
		window:"FieldMeta",
		deliveryMethod:"FieldMeta"
	},
	PaymentMethodConnection:{
		pageInfo:"PageInfo",
		edges:"PaymentMethodEdge"
	},
	PaymentMethodEdge:{
		node:"PaymentMethod"
	},
	DeliveryZoneConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryZoneEdge"
	},
	DeliveryZoneEdge:{
		node:"DeliveryZone"
	},
	DeliveryMethodLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryMethodLocaleEdge"
	},
	DeliveryMethodLocaleEdge:{
		node:"DeliveryMethodLocale"
	},
	IdentificationCodeLocale:{
		_meta:"IdentificationCodeLocaleMeta",
		id:"UUID",
		successMessage:"String",
		root:"IdentificationCode",
		locale:"Locale"
	},
	IdentificationCodeLocaleMeta:{
		id:"FieldMeta",
		successMessage:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	IdentificationCode:{
		_meta:"IdentificationCodeMeta",
		id:"UUID",
		code:"String",
		createdAt:"DateTime",
		attempts:"IdentificationAttempt",
		locales:"IdentificationCodeLocale",
		internalName:"String",
		localesByLocale:"IdentificationCodeLocale",
		paginateAttempts:"IdentificationAttemptConnection",
		paginateLocales:"IdentificationCodeLocaleConnection"
	},
	IdentificationCodeMeta:{
		id:"FieldMeta",
		code:"FieldMeta",
		createdAt:"FieldMeta",
		attempts:"FieldMeta",
		locales:"FieldMeta",
		internalName:"FieldMeta"
	},
	IdentificationAttempt:{
		_meta:"IdentificationAttemptMeta",
		id:"UUID",
		createdAt:"DateTime",
		code:"IdentificationCode"
	},
	IdentificationAttemptMeta:{
		id:"FieldMeta",
		createdAt:"FieldMeta",
		code:"FieldMeta"
	},
	IdentificationAttemptConnection:{
		pageInfo:"PageInfo",
		edges:"IdentificationAttemptEdge"
	},
	IdentificationAttemptEdge:{
		node:"IdentificationAttempt"
	},
	IdentificationCodeLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"IdentificationCodeLocaleEdge"
	},
	IdentificationCodeLocaleEdge:{
		node:"IdentificationCodeLocale"
	},
	SubscriptionBoxLocale:{
		_meta:"SubscriptionBoxLocaleMeta",
		id:"UUID",
		title:"String",
		stickerText:"String",
		descriptionText:"String",
		root:"SubscriptionBox",
		locale:"Locale",
		secondaryTitle:"String",
		texts:"TextList",
		secondaryTexts:"TextList"
	},
	SubscriptionBoxLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		stickerText:"FieldMeta",
		descriptionText:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		secondaryTitle:"FieldMeta",
		texts:"FieldMeta",
		secondaryTexts:"FieldMeta"
	},
	SubscriptionBox:{
		_meta:"SubscriptionBoxMeta",
		id:"UUID",
		locales:"SubscriptionBoxLocale",
		unique:"One",
		localesByLocale:"SubscriptionBoxLocale",
		localesByTexts:"SubscriptionBoxLocale",
		localesBySecondaryTexts:"SubscriptionBoxLocale",
		paginateLocales:"SubscriptionBoxLocaleConnection"
	},
	SubscriptionBoxMeta:{
		id:"FieldMeta",
		locales:"FieldMeta",
		unique:"FieldMeta"
	},
	SubscriptionBoxLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"SubscriptionBoxLocaleEdge"
	},
	SubscriptionBoxLocaleEdge:{
		node:"SubscriptionBoxLocale"
	},
	ProductPackingLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductPackingLocaleEdge"
	},
	ProductPackingLocaleEdge:{
		node:"ProductPackingLocale"
	},
	ProductLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductLocaleEdge"
	},
	ProductLocaleEdge:{
		node:"ProductLocale"
	},
	PreparedPackingQuantity:{
		_meta:"PreparedPackingQuantityMeta",
		id:"UUID",
		date:"Date",
		quantity:"Float",
		recipe:"ProductRecipe",
		packing:"ProductPacking",
		packedInCardboard:"Boolean"
	},
	PreparedPackingQuantityMeta:{
		id:"FieldMeta",
		date:"FieldMeta",
		quantity:"FieldMeta",
		recipe:"FieldMeta",
		packing:"FieldMeta",
		packedInCardboard:"FieldMeta"
	},
	PreparedPackingQuantityConnection:{
		pageInfo:"PageInfo",
		edges:"PreparedPackingQuantityEdge"
	},
	PreparedPackingQuantityEdge:{
		node:"PreparedPackingQuantity"
	},
	ProductStock:{
		_meta:"ProductStockMeta",
		id:"UUID",
		quantity:"Int",
		product:"Product",
		store:"Store"
	},
	ProductStockMeta:{
		id:"FieldMeta",
		quantity:"FieldMeta",
		product:"FieldMeta",
		store:"FieldMeta"
	},
	Store:{
		_meta:"StoreMeta",
		id:"UUID",
		name:"String",
		code:"String",
		description:"String",
		internalNote:"String"
	},
	StoreMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta"
	},
	OrderItem:{
		_meta:"OrderItemMeta",
		id:"UUID",
		unitPriceCents:"Int",
		quantity:"Int",
		vatRate:"VatRate",
		order:"Order",
		pickedAt:"DateTime",
		note:"String",
		fulfillmentNotes:"FulfillmentNote",
		product:"Product",
		paginateFulfillmentNotes:"FulfillmentNoteConnection"
	},
	OrderItemMeta:{
		id:"FieldMeta",
		unitPriceCents:"FieldMeta",
		quantity:"FieldMeta",
		vatRate:"FieldMeta",
		order:"FieldMeta",
		pickedAt:"FieldMeta",
		note:"FieldMeta",
		fulfillmentNotes:"FieldMeta",
		product:"FieldMeta"
	},
	Order:{
		_meta:"OrderMeta",
		id:"UUID",
		state:"OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		note:"String",
		customer:"Customer",
		channel:"Channel",
		delivery:"OrderDelivery",
		items:"OrderItem",
		internalNote:"String",
		createdAt:"DateTime",
		billingAddress:"Address",
		payments:"OrderPayment",
		seq:"Int",
		doubleshotLegacyId:"String",
		venue:"Venue",
		fulfillmentNote:"String",
		fulfillmentNotes:"FulfillmentNote",
		doubleshotTotalWeightGrams:"Int",
		doubleShotLegacyNumber:"String",
		doubleshotLastUpdatedAt:"DateTime",
		doubleshotAdminUrl:"String",
		paginateItems:"OrderItemConnection",
		paginatePayments:"OrderPaymentConnection",
		paginateFulfillmentNotes:"FulfillmentNoteConnection"
	},
	OrderMeta:{
		id:"FieldMeta",
		state:"FieldMeta",
		fulfilledAt:"FieldMeta",
		canceledAt:"FieldMeta",
		note:"FieldMeta",
		customer:"FieldMeta",
		channel:"FieldMeta",
		delivery:"FieldMeta",
		items:"FieldMeta",
		internalNote:"FieldMeta",
		createdAt:"FieldMeta",
		billingAddress:"FieldMeta",
		payments:"FieldMeta",
		seq:"FieldMeta",
		doubleshotLegacyId:"FieldMeta",
		venue:"FieldMeta",
		fulfillmentNote:"FieldMeta",
		fulfillmentNotes:"FieldMeta",
		doubleshotTotalWeightGrams:"FieldMeta",
		doubleShotLegacyNumber:"FieldMeta",
		doubleshotLastUpdatedAt:"FieldMeta",
		doubleshotAdminUrl:"FieldMeta"
	},
	Channel:{
		_meta:"ChannelMeta",
		id:"UUID",
		name:"String",
		disabled:"Boolean",
		customers:"Customer",
		code:"ChannelCode",
		addTagsOnRegistration:"Tag",
		publicTags:"Tag",
		venue:"Venue",
		paginateCustomers:"CustomerConnection",
		paginateAddTagsOnRegistration:"TagConnection",
		paginatePublicTags:"TagConnection"
	},
	ChannelMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		disabled:"FieldMeta",
		customers:"FieldMeta",
		code:"FieldMeta",
		addTagsOnRegistration:"FieldMeta",
		publicTags:"FieldMeta",
		venue:"FieldMeta"
	},
	Venue:{
		_meta:"VenueMeta",
		id:"UUID",
		name:"String",
		deadlineTemplate:"DeadlineTemplate",
		timetableDays:"TimetableDay",
		timetableTemplates:"TimetableTemplate",
		staffMembers:"Staff",
		employeeRoles:"VenueEmployeeRole",
		orders:"Order",
		fulfillmentNotes:"FulfillmentNote",
		enabledUnassignReasons:"UnassignReason",
		channels:"Channel",
		address:"String",
		pointOfSalePngBase64ReceiptLogo:"String",
		pointOfSaleInitialView:"PointOfSaleInitialView",
		pointOfSalePreferPrintReceipt:"Boolean",
		timetableDaysByDate:"TimetableDay",
		timetableDaysByShiftGroups:"TimetableDay",
		timetableDaysByNotes:"TimetableDay",
		timetableDaysByMeta:"TimetableDay",
		employeeRolesByRole:"VenueEmployeeRole",
		employeeRolesByEmployeePrivileges:"VenueEmployeeRole",
		ordersBySeq:"Order",
		ordersByDoubleshotLegacyId:"Order",
		ordersByDoubleShotLegacyNumber:"Order",
		ordersByDelivery:"Order",
		ordersByItems:"Order",
		ordersByPayments:"Order",
		channelsByCode:"Channel",
		paginateTimetableDays:"TimetableDayConnection",
		paginateTimetableTemplates:"TimetableTemplateConnection",
		paginateStaffMembers:"StaffConnection",
		paginateEmployeeRoles:"VenueEmployeeRoleConnection",
		paginateOrders:"OrderConnection",
		paginateFulfillmentNotes:"FulfillmentNoteConnection",
		paginateEnabledUnassignReasons:"UnassignReasonConnection",
		paginateChannels:"ChannelConnection"
	},
	VenueMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		deadlineTemplate:"FieldMeta",
		timetableDays:"FieldMeta",
		timetableTemplates:"FieldMeta",
		staffMembers:"FieldMeta",
		employeeRoles:"FieldMeta",
		orders:"FieldMeta",
		fulfillmentNotes:"FieldMeta",
		enabledUnassignReasons:"FieldMeta",
		channels:"FieldMeta",
		address:"FieldMeta",
		pointOfSalePngBase64ReceiptLogo:"FieldMeta",
		pointOfSaleInitialView:"FieldMeta",
		pointOfSalePreferPrintReceipt:"FieldMeta"
	},
	DeadlineTemplate:{
		_meta:"DeadlineTemplateMeta",
		id:"UUID",
		startDate:"Date",
		period:"Int",
		cutoff:"Int",
		closed:"Boolean",
		venue:"Venue"
	},
	DeadlineTemplateMeta:{
		id:"FieldMeta",
		startDate:"FieldMeta",
		period:"FieldMeta",
		cutoff:"FieldMeta",
		closed:"FieldMeta",
		venue:"FieldMeta"
	},
	TimetableDay:{
		_meta:"TimetableDayMeta",
		id:"UUID",
		date:"Date",
		type:"TimetableDayType",
		shiftGroups:"TimetableShiftGroup",
		tipsCents:"Int",
		notes:"TimetableDayNote",
		venue:"Venue",
		meta:"TimetableDayMetadata",
		shiftGroupsByShifts:"TimetableShiftGroup",
		shiftGroupsByQueue:"TimetableShiftGroup",
		paginateShiftGroups:"TimetableShiftGroupConnection",
		paginateNotes:"TimetableDayNoteConnection"
	},
	TimetableDayMeta:{
		id:"FieldMeta",
		date:"FieldMeta",
		type:"FieldMeta",
		shiftGroups:"FieldMeta",
		tipsCents:"FieldMeta",
		notes:"FieldMeta",
		venue:"FieldMeta",
		meta:"FieldMeta"
	},
	TimetableShiftGroup:{
		_meta:"TimetableShiftGroupMeta",
		id:"UUID",
		order:"Int",
		shifts:"TimetableShift",
		day:"TimetableDay",
		type:"TimetableEmployeeRole",
		position:"TimetableShiftPosition",
		queue:"EmployeeQueue",
		generatedByTemplate:"TimetableTemplate",
		shiftsByQueueItem:"TimetableShift",
		paginateShifts:"TimetableShiftConnection"
	},
	TimetableShiftGroupMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		shifts:"FieldMeta",
		day:"FieldMeta",
		type:"FieldMeta",
		position:"FieldMeta",
		queue:"FieldMeta",
		generatedByTemplate:"FieldMeta"
	},
	TimetableShift:{
		_meta:"TimetableShiftMeta",
		id:"UUID",
		locked:"Boolean",
		order:"Int",
		assigned:"Date",
		group:"TimetableShiftGroup",
		shiftsProfile:"ShiftsProfile",
		queueItem:"EmployeeQueueItem",
		isOvertime:"Boolean"
	},
	TimetableShiftMeta:{
		id:"FieldMeta",
		locked:"FieldMeta",
		order:"FieldMeta",
		assigned:"FieldMeta",
		group:"FieldMeta",
		shiftsProfile:"FieldMeta",
		queueItem:"FieldMeta",
		isOvertime:"FieldMeta"
	},
	ShiftsProfile:{
		_meta:"ShiftsProfileMeta",
		id:"UUID",
		queueItems:"EmployeeQueueItem",
		staff:"Staff",
		roles:"TimetableEmployeeRole",
		shifts:"TimetableShift",
		allowSelfUnassigning:"Boolean",
		queueItemsByQueue:"EmployeeQueueItem",
		queueItemsByShift:"EmployeeQueueItem",
		queueItemsByUnassignInfo:"EmployeeQueueItem",
		shiftsByQueueItem:"TimetableShift",
		paginateQueueItems:"EmployeeQueueItemConnection",
		paginateRoles:"TimetableEmployeeRoleConnection",
		paginateShifts:"TimetableShiftConnection"
	},
	ShiftsProfileMeta:{
		id:"FieldMeta",
		queueItems:"FieldMeta",
		staff:"FieldMeta",
		roles:"FieldMeta",
		shifts:"FieldMeta",
		allowSelfUnassigning:"FieldMeta"
	},
	EmployeeQueueItem:{
		_meta:"EmployeeQueueItemMeta",
		id:"UUID",
		start:"String",
		end:"String",
		queue:"EmployeeQueue",
		shiftsProfile:"ShiftsProfile",
		shift:"TimetableShift",
		unassignInfo:"UnassignedShiftInfo"
	},
	EmployeeQueueItemMeta:{
		id:"FieldMeta",
		start:"FieldMeta",
		end:"FieldMeta",
		queue:"FieldMeta",
		shiftsProfile:"FieldMeta",
		shift:"FieldMeta",
		unassignInfo:"FieldMeta"
	},
	EmployeeQueue:{
		_meta:"EmployeeQueueMeta",
		id:"UUID",
		shiftGroup:"TimetableShiftGroup",
		items:"EmployeeQueueItem",
		contemberBugFix:"Boolean",
		itemsByShiftsProfile:"EmployeeQueueItem",
		itemsByShift:"EmployeeQueueItem",
		itemsByUnassignInfo:"EmployeeQueueItem",
		paginateItems:"EmployeeQueueItemConnection"
	},
	EmployeeQueueMeta:{
		id:"FieldMeta",
		shiftGroup:"FieldMeta",
		items:"FieldMeta",
		contemberBugFix:"FieldMeta"
	},
	EmployeeQueueItemConnection:{
		pageInfo:"PageInfo",
		edges:"EmployeeQueueItemEdge"
	},
	EmployeeQueueItemEdge:{
		node:"EmployeeQueueItem"
	},
	UnassignedShiftInfo:{
		_meta:"UnassignedShiftInfoMeta",
		id:"UUID",
		hoursMissed:"Int",
		queueItem:"EmployeeQueueItem",
		legacyReason:"UnassignedInfoReason",
		reason:"UnassignReason"
	},
	UnassignedShiftInfoMeta:{
		id:"FieldMeta",
		hoursMissed:"FieldMeta",
		queueItem:"FieldMeta",
		legacyReason:"FieldMeta",
		reason:"FieldMeta"
	},
	UnassignReason:{
		_meta:"UnassignReasonMeta",
		id:"UUID",
		order:"Int",
		name:"String",
		venues:"Venue",
		paginateVenues:"VenueConnection"
	},
	UnassignReasonMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		name:"FieldMeta",
		venues:"FieldMeta"
	},
	VenueConnection:{
		pageInfo:"PageInfo",
		edges:"VenueEdge"
	},
	VenueEdge:{
		node:"Venue"
	},
	Staff:{
		_meta:"StaffMeta",
		id:"UUID",
		shiftsProfile:"ShiftsProfile",
		photo:"Image",
		createdAt:"DateTime",
		venues:"Venue",
		paginateVenues:"VenueConnection"
	},
	StaffMeta:{
		id:"FieldMeta",
		shiftsProfile:"FieldMeta",
		photo:"FieldMeta",
		createdAt:"FieldMeta",
		venues:"FieldMeta"
	},
	TimetableEmployeeRole:{
		_meta:"TimetableEmployeeRoleMeta",
		id:"UUID",
		shiftGroups:"TimetableShiftGroup",
		shiftsProfiles:"ShiftsProfile",
		name:"String",
		timetableTemplates:"TimetableTemplate",
		color:"String",
		shiftGroupsByShifts:"TimetableShiftGroup",
		shiftGroupsByQueue:"TimetableShiftGroup",
		paginateShiftGroups:"TimetableShiftGroupConnection",
		paginateShiftsProfiles:"ShiftsProfileConnection",
		paginateTimetableTemplates:"TimetableTemplateConnection"
	},
	TimetableEmployeeRoleMeta:{
		id:"FieldMeta",
		shiftGroups:"FieldMeta",
		shiftsProfiles:"FieldMeta",
		name:"FieldMeta",
		timetableTemplates:"FieldMeta",
		color:"FieldMeta"
	},
	TimetableTemplate:{
		_meta:"TimetableTemplateMeta",
		id:"UUID",
		order:"Int",
		startDate:"Date",
		period:"Int",
		slots:"Int",
		position:"TimetableShiftPosition",
		internalName:"String",
		venue:"Venue",
		employeeRole:"TimetableEmployeeRole",
		workdaysOnly:"Boolean",
		monday:"Boolean",
		tuesday:"Boolean",
		wednesday:"Boolean",
		thursday:"Boolean",
		friday:"Boolean",
		saturday:"Boolean",
		sunday:"Boolean",
		type:"TemplateType"
	},
	TimetableTemplateMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		startDate:"FieldMeta",
		period:"FieldMeta",
		slots:"FieldMeta",
		position:"FieldMeta",
		internalName:"FieldMeta",
		venue:"FieldMeta",
		employeeRole:"FieldMeta",
		workdaysOnly:"FieldMeta",
		monday:"FieldMeta",
		tuesday:"FieldMeta",
		wednesday:"FieldMeta",
		thursday:"FieldMeta",
		friday:"FieldMeta",
		saturday:"FieldMeta",
		sunday:"FieldMeta",
		type:"FieldMeta"
	},
	TimetableShiftPosition:{
		_meta:"TimetableShiftPositionMeta",
		id:"UUID",
		name:"String",
		start:"String",
		end:"String",
		shiftGroups:"TimetableShiftGroup",
		timetableTemplates:"TimetableTemplate",
		isDynamic:"Boolean",
		shiftGroupsByShifts:"TimetableShiftGroup",
		shiftGroupsByQueue:"TimetableShiftGroup",
		paginateShiftGroups:"TimetableShiftGroupConnection",
		paginateTimetableTemplates:"TimetableTemplateConnection"
	},
	TimetableShiftPositionMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		start:"FieldMeta",
		end:"FieldMeta",
		shiftGroups:"FieldMeta",
		timetableTemplates:"FieldMeta",
		isDynamic:"FieldMeta"
	},
	TimetableShiftGroupConnection:{
		pageInfo:"PageInfo",
		edges:"TimetableShiftGroupEdge"
	},
	TimetableShiftGroupEdge:{
		node:"TimetableShiftGroup"
	},
	TimetableTemplateConnection:{
		pageInfo:"PageInfo",
		edges:"TimetableTemplateEdge"
	},
	TimetableTemplateEdge:{
		node:"TimetableTemplate"
	},
	ShiftsProfileConnection:{
		pageInfo:"PageInfo",
		edges:"ShiftsProfileEdge"
	},
	ShiftsProfileEdge:{
		node:"ShiftsProfile"
	},
	TimetableEmployeeRoleConnection:{
		pageInfo:"PageInfo",
		edges:"TimetableEmployeeRoleEdge"
	},
	TimetableEmployeeRoleEdge:{
		node:"TimetableEmployeeRole"
	},
	TimetableShiftConnection:{
		pageInfo:"PageInfo",
		edges:"TimetableShiftEdge"
	},
	TimetableShiftEdge:{
		node:"TimetableShift"
	},
	TimetableDayNote:{
		_meta:"TimetableDayNoteMeta",
		id:"UUID",
		order:"Int",
		note:"String",
		day:"TimetableDay",
		role:"TimetableEmployeeRole"
	},
	TimetableDayNoteMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		note:"FieldMeta",
		day:"FieldMeta",
		role:"FieldMeta"
	},
	TimetableDayMetadata:{
		_meta:"TimetableDayMetadataMeta",
		id:"UUID",
		numberOfWorkers:"Int",
		tipsTotalCents:"Int",
		totalMinutes:"Int",
		tipsCentsPerMinute:"Float",
		day:"TimetableDay"
	},
	TimetableDayMetadataMeta:{
		id:"FieldMeta",
		numberOfWorkers:"FieldMeta",
		tipsTotalCents:"FieldMeta",
		totalMinutes:"FieldMeta",
		tipsCentsPerMinute:"FieldMeta",
		day:"FieldMeta"
	},
	TimetableDayNoteConnection:{
		pageInfo:"PageInfo",
		edges:"TimetableDayNoteEdge"
	},
	TimetableDayNoteEdge:{
		node:"TimetableDayNote"
	},
	VenueEmployeeRole:{
		_meta:"VenueEmployeeRoleMeta",
		id:"UUID",
		order:"Int",
		employeePrivileges:"TimetablesEmployeePrivileges",
		venue:"Venue",
		role:"TimetableEmployeeRole",
		getsTipsShare:"Boolean",
		notesEnabled:"Boolean"
	},
	VenueEmployeeRoleMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		employeePrivileges:"FieldMeta",
		venue:"FieldMeta",
		role:"FieldMeta",
		getsTipsShare:"FieldMeta",
		notesEnabled:"FieldMeta"
	},
	TimetablesEmployeePrivileges:{
		_meta:"TimetablesEmployeePrivilegesMeta",
		id:"UUID",
		venueEmployeeRole:"VenueEmployeeRole",
		read:"Boolean",
		unassign:"Boolean",
		assign:"Boolean"
	},
	TimetablesEmployeePrivilegesMeta:{
		id:"FieldMeta",
		venueEmployeeRole:"FieldMeta",
		read:"FieldMeta",
		unassign:"FieldMeta",
		assign:"FieldMeta"
	},
	FulfillmentNote:{
		_meta:"FulfillmentNoteMeta",
		id:"UUID",
		note:"String",
		products:"Product",
		tags:"Tag",
		venues:"Venue",
		orderItems:"OrderItem",
		orders:"Order",
		highlight:"Boolean",
		createdAt:"DateTime",
		emoji:"String",
		paginateProducts:"ProductConnection",
		paginateTags:"TagConnection",
		paginateVenues:"VenueConnection",
		paginateOrderItems:"OrderItemConnection",
		paginateOrders:"OrderConnection"
	},
	FulfillmentNoteMeta:{
		id:"FieldMeta",
		note:"FieldMeta",
		products:"FieldMeta",
		tags:"FieldMeta",
		venues:"FieldMeta",
		orderItems:"FieldMeta",
		orders:"FieldMeta",
		highlight:"FieldMeta",
		createdAt:"FieldMeta",
		emoji:"FieldMeta"
	},
	OrderItemConnection:{
		pageInfo:"PageInfo",
		edges:"OrderItemEdge"
	},
	OrderItemEdge:{
		node:"OrderItem"
	},
	OrderConnection:{
		pageInfo:"PageInfo",
		edges:"OrderEdge"
	},
	OrderEdge:{
		node:"Order"
	},
	TimetableDayConnection:{
		pageInfo:"PageInfo",
		edges:"TimetableDayEdge"
	},
	TimetableDayEdge:{
		node:"TimetableDay"
	},
	StaffConnection:{
		pageInfo:"PageInfo",
		edges:"StaffEdge"
	},
	StaffEdge:{
		node:"Staff"
	},
	VenueEmployeeRoleConnection:{
		pageInfo:"PageInfo",
		edges:"VenueEmployeeRoleEdge"
	},
	VenueEmployeeRoleEdge:{
		node:"VenueEmployeeRole"
	},
	FulfillmentNoteConnection:{
		pageInfo:"PageInfo",
		edges:"FulfillmentNoteEdge"
	},
	FulfillmentNoteEdge:{
		node:"FulfillmentNote"
	},
	UnassignReasonConnection:{
		pageInfo:"PageInfo",
		edges:"UnassignReasonEdge"
	},
	UnassignReasonEdge:{
		node:"UnassignReason"
	},
	ChannelConnection:{
		pageInfo:"PageInfo",
		edges:"ChannelEdge"
	},
	ChannelEdge:{
		node:"Channel"
	},
	CustomerConnection:{
		pageInfo:"PageInfo",
		edges:"CustomerEdge"
	},
	CustomerEdge:{
		node:"Customer"
	},
	OrderDelivery:{
		_meta:"OrderDeliveryMeta",
		id:"UUID",
		priceCents:"Int",
		deliveredAt:"DateTime",
		method:"DeliveryMethod",
		vatRate:"VatRate",
		order:"Order",
		expectedAt:"DateTime",
		state:"OrderDeliveryState",
		loadingDurationHours:"Int",
		dispatchAt:"DateTime",
		duration:"String",
		window:"String",
		expectedEndAt:"DateTime",
		processingAt:"DateTime",
		deliveringAt:"DateTime",
		failedAt:"DateTime",
		internalNote:"String",
		deliveryWaypoint:"DeliveryWaypoint",
		prepareAt:"DateTime",
		pickedAt:"DateTime",
		address:"Address",
		trackingCode:"String"
	},
	OrderDeliveryMeta:{
		id:"FieldMeta",
		priceCents:"FieldMeta",
		deliveredAt:"FieldMeta",
		method:"FieldMeta",
		vatRate:"FieldMeta",
		order:"FieldMeta",
		expectedAt:"FieldMeta",
		state:"FieldMeta",
		loadingDurationHours:"FieldMeta",
		dispatchAt:"FieldMeta",
		duration:"FieldMeta",
		window:"FieldMeta",
		expectedEndAt:"FieldMeta",
		processingAt:"FieldMeta",
		deliveringAt:"FieldMeta",
		failedAt:"FieldMeta",
		internalNote:"FieldMeta",
		deliveryWaypoint:"FieldMeta",
		prepareAt:"FieldMeta",
		pickedAt:"FieldMeta",
		address:"FieldMeta",
		trackingCode:"FieldMeta"
	},
	DeliveryWaypoint:{
		_meta:"DeliveryWaypointMeta",
		id:"UUID",
		estimatedArrival:"DateTime",
		note:"String",
		route:"DeliveryRoute",
		sortingOrder:"Int",
		delivery:"OrderDelivery",
		estimatedDistance:"Int",
		estimatedDuration:"Int",
		originDeparture:"DateTime",
		originAddress:"String",
		transitMode:"String"
	},
	DeliveryWaypointMeta:{
		id:"FieldMeta",
		estimatedArrival:"FieldMeta",
		note:"FieldMeta",
		route:"FieldMeta",
		sortingOrder:"FieldMeta",
		delivery:"FieldMeta",
		estimatedDistance:"FieldMeta",
		estimatedDuration:"FieldMeta",
		originDeparture:"FieldMeta",
		originAddress:"FieldMeta",
		transitMode:"FieldMeta"
	},
	DeliveryRoute:{
		_meta:"DeliveryRouteMeta",
		id:"UUID",
		createdAt:"DateTime",
		waypoints:"DeliveryWaypoint",
		dispatchAt:"String",
		waypointsByDelivery:"DeliveryWaypoint",
		paginateWaypoints:"DeliveryWaypointConnection"
	},
	DeliveryRouteMeta:{
		id:"FieldMeta",
		createdAt:"FieldMeta",
		waypoints:"FieldMeta",
		dispatchAt:"FieldMeta"
	},
	DeliveryWaypointConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryWaypointEdge"
	},
	DeliveryWaypointEdge:{
		node:"DeliveryWaypoint"
	},
	OrderPayment:{
		_meta:"OrderPaymentMeta",
		id:"UUID",
		data:"Json",
		paymentCents:"Int",
		method:"PaymentMethod",
		failedAt:"DateTime",
		state:"OrderPaymentState",
		approvedAt:"DateTime",
		order:"Order",
		walletType:"String",
		createdAt:"DateTime",
		publicReceiptUrl:"String",
		priceCents:"Int",
		vatRate:"VatRate",
		otherCurrencyPriceCents:"String"
	},
	OrderPaymentMeta:{
		id:"FieldMeta",
		data:"FieldMeta",
		paymentCents:"FieldMeta",
		method:"FieldMeta",
		failedAt:"FieldMeta",
		state:"FieldMeta",
		approvedAt:"FieldMeta",
		order:"FieldMeta",
		walletType:"FieldMeta",
		createdAt:"FieldMeta",
		publicReceiptUrl:"FieldMeta",
		priceCents:"FieldMeta",
		vatRate:"FieldMeta",
		otherCurrencyPriceCents:"FieldMeta"
	},
	OrderPaymentConnection:{
		pageInfo:"PageInfo",
		edges:"OrderPaymentEdge"
	},
	OrderPaymentEdge:{
		node:"OrderPayment"
	},
	ProductMetadata:{
		_meta:"ProductMetadataMeta",
		id:"UUID",
		archived:"Boolean",
		available:"Boolean",
		availableQuantity:"Int",
		product:"Product"
	},
	ProductMetadataMeta:{
		id:"FieldMeta",
		archived:"FieldMeta",
		available:"FieldMeta",
		availableQuantity:"FieldMeta",
		product:"FieldMeta"
	},
	ProductStockConnection:{
		pageInfo:"PageInfo",
		edges:"ProductStockEdge"
	},
	ProductStockEdge:{
		node:"ProductStock"
	},
	CustomerName:{
		_meta:"CustomerNameMeta",
		id:"UUID",
		nominative:"String",
		vocative:"String",
		customer:"Customer"
	},
	CustomerNameMeta:{
		id:"FieldMeta",
		nominative:"FieldMeta",
		vocative:"FieldMeta",
		customer:"FieldMeta"
	},
	CustomerMetadata:{
		_meta:"CustomerMetadataMeta",
		id:"UUID",
		isBusiness:"Boolean",
		name:"String",
		fallbackName:"String",
		remainingCreditsCents:"Int",
		ordersTotalCents:"Int",
		customerProfileUrl:"String",
		customer:"Customer"
	},
	CustomerMetadataMeta:{
		id:"FieldMeta",
		isBusiness:"FieldMeta",
		name:"FieldMeta",
		fallbackName:"FieldMeta",
		remainingCreditsCents:"FieldMeta",
		ordersTotalCents:"FieldMeta",
		customerProfileUrl:"FieldMeta",
		customer:"FieldMeta"
	},
	Country:{
		_meta:"CountryMeta",
		id:"UUID",
		code:"String",
		order:"Int"
	},
	CountryMeta:{
		id:"FieldMeta",
		code:"FieldMeta",
		order:"FieldMeta"
	},
	OrderDeliveryConnection:{
		pageInfo:"PageInfo",
		edges:"OrderDeliveryEdge"
	},
	OrderDeliveryEdge:{
		node:"OrderDelivery"
	},
	CountryConnection:{
		pageInfo:"PageInfo",
		edges:"CountryEdge"
	},
	CountryEdge:{
		node:"Country"
	},
	VatRateConnection:{
		pageInfo:"PageInfo",
		edges:"VatRateEdge"
	},
	VatRateEdge:{
		node:"VatRate"
	},
	ProductPackingConnection:{
		pageInfo:"PageInfo",
		edges:"ProductPackingEdge"
	},
	ProductPackingEdge:{
		node:"ProductPacking"
	},
	ProductRecipeConnection:{
		pageInfo:"PageInfo",
		edges:"ProductRecipeEdge"
	},
	ProductRecipeEdge:{
		node:"ProductRecipe"
	},
	StoreConnection:{
		pageInfo:"PageInfo",
		edges:"StoreEdge"
	},
	StoreEdge:{
		node:"Store"
	},
	ImageConnection:{
		pageInfo:"PageInfo",
		edges:"ImageEdge"
	},
	ImageEdge:{
		node:"Image"
	},
	ProductListConnection:{
		pageInfo:"PageInfo",
		edges:"ProductListEdge"
	},
	ProductListEdge:{
		node:"ProductList"
	},
	ProductCategoryListConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryListEdge"
	},
	ProductCategoryListEdge:{
		node:"ProductCategoryList"
	},
	LocaleConnection:{
		pageInfo:"PageInfo",
		edges:"LocaleEdge"
	},
	LocaleEdge:{
		node:"Locale"
	},
	CustomerNameConnection:{
		pageInfo:"PageInfo",
		edges:"CustomerNameEdge"
	},
	CustomerNameEdge:{
		node:"CustomerName"
	},
	ProductIngredientItemQuantityConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientItemQuantityEdge"
	},
	ProductIngredientItemQuantityEdge:{
		node:"ProductIngredientItemQuantity"
	},
	ProductIngredientItemQuantityUnitConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientItemQuantityUnitEdge"
	},
	ProductIngredientItemQuantityUnitEdge:{
		node:"ProductIngredientItemQuantityUnit"
	},
	ProductIngredientRating:{
		_meta:"ProductIngredientRatingMeta",
		id:"UUID",
		order:"Int",
		name:"String",
		icon:"Image"
	},
	ProductIngredientRatingMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		name:"FieldMeta",
		icon:"FieldMeta"
	},
	ProductIngredientRatingConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientRatingEdge"
	},
	ProductIngredientRatingEdge:{
		node:"ProductIngredientRating"
	},
	BlogConnection:{
		pageInfo:"PageInfo",
		edges:"BlogEdge"
	},
	BlogEdge:{
		node:"Blog"
	},
	BlogPostConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostEdge"
	},
	BlogPostEdge:{
		node:"BlogPost"
	},
	ColorPalleteConnection:{
		pageInfo:"PageInfo",
		edges:"ColorPalleteEdge"
	},
	ColorPalleteEdge:{
		node:"ColorPallete"
	},
	ContentConnection:{
		pageInfo:"PageInfo",
		edges:"ContentEdge"
	},
	ContentEdge:{
		node:"Content"
	},
	BlogPostListConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostListEdge"
	},
	BlogPostListEdge:{
		node:"BlogPostList"
	},
	LinkListConnection:{
		pageInfo:"PageInfo",
		edges:"LinkListEdge"
	},
	LinkListEdge:{
		node:"LinkList"
	},
	CoverConnection:{
		pageInfo:"PageInfo",
		edges:"CoverEdge"
	},
	CoverEdge:{
		node:"Cover"
	},
	LinkConnection:{
		pageInfo:"PageInfo",
		edges:"LinkEdge"
	},
	LinkEdge:{
		node:"Link"
	},
	LinkableConnection:{
		pageInfo:"PageInfo",
		edges:"LinkableEdge"
	},
	LinkableEdge:{
		node:"Linkable"
	},
	MediumConnection:{
		pageInfo:"PageInfo",
		edges:"MediumEdge"
	},
	MediumEdge:{
		node:"Medium"
	},
	VideoConnection:{
		pageInfo:"PageInfo",
		edges:"VideoEdge"
	},
	VideoEdge:{
		node:"Video"
	},
	SeoConnection:{
		pageInfo:"PageInfo",
		edges:"SeoEdge"
	},
	SeoEdge:{
		node:"Seo"
	},
	TranslationDomainConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationDomainEdge"
	},
	TranslationDomainEdge:{
		node:"TranslationDomain"
	},
	TranslationCataloguesIdentifierConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationCataloguesIdentifierEdge"
	},
	TranslationCataloguesIdentifierEdge:{
		node:"TranslationCataloguesIdentifier"
	},
	SocialsAndAppsConnection:{
		pageInfo:"PageInfo",
		edges:"SocialsAndAppsEdge"
	},
	SocialsAndAppsEdge:{
		node:"SocialsAndApps"
	},
	ProductGridConnection:{
		pageInfo:"PageInfo",
		edges:"ProductGridEdge"
	},
	ProductGridEdge:{
		node:"ProductGrid"
	},
	GalleryConnection:{
		pageInfo:"PageInfo",
		edges:"GalleryEdge"
	},
	GalleryEdge:{
		node:"Gallery"
	},
	HeroConnection:{
		pageInfo:"PageInfo",
		edges:"HeroEdge"
	},
	HeroEdge:{
		node:"Hero"
	},
	PageConnection:{
		pageInfo:"PageInfo",
		edges:"PageEdge"
	},
	PageEdge:{
		node:"Page"
	},
	DeadlineTemplateConnection:{
		pageInfo:"PageInfo",
		edges:"DeadlineTemplateEdge"
	},
	DeadlineTemplateEdge:{
		node:"DeadlineTemplate"
	},
	EmployeeQueueConnection:{
		pageInfo:"PageInfo",
		edges:"EmployeeQueueEdge"
	},
	EmployeeQueueEdge:{
		node:"EmployeeQueue"
	},
	_ValidationResult:{
		valid:"Boolean",
		errors:"_ValidationError"
	},
	_ValidationError:{
		path:"_PathFragment",
		message:"_ValidationMessage"
	},
	_PathFragment:{
		"...on _FieldPathFragment":"_FieldPathFragment",
		"...on _IndexPathFragment":"_IndexPathFragment"
	},
	_FieldPathFragment:{
		field:"String"
	},
	_IndexPathFragment:{
		index:"Int",
		alias:"String"
	},
	_ValidationMessage:{
		text:"String"
	},
	TimetableShiftPositionConnection:{
		pageInfo:"PageInfo",
		edges:"TimetableShiftPositionEdge"
	},
	TimetableShiftPositionEdge:{
		node:"TimetableShiftPosition"
	},
	ProductBannerConnection:{
		pageInfo:"PageInfo",
		edges:"ProductBannerEdge"
	},
	ProductBannerEdge:{
		node:"ProductBanner"
	},
	ProductBannerListConnection:{
		pageInfo:"PageInfo",
		edges:"ProductBannerListEdge"
	},
	ProductBannerListEdge:{
		node:"ProductBannerList"
	},
	DeliveryRouteConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryRouteEdge"
	},
	DeliveryRouteEdge:{
		node:"DeliveryRoute"
	},
	WeekHoursConnection:{
		pageInfo:"PageInfo",
		edges:"WeekHoursEdge"
	},
	WeekHoursEdge:{
		node:"WeekHours"
	},
	PreparedIngredientQuantity:{
		_meta:"PreparedIngredientQuantityMeta",
		id:"UUID",
		date:"Date",
		quantity:"Float",
		usedQuantity:"Float",
		ingredient:"ProductIngredient",
		unit:"ProductIngredientItemQuantityUnit"
	},
	PreparedIngredientQuantityMeta:{
		id:"FieldMeta",
		date:"FieldMeta",
		quantity:"FieldMeta",
		usedQuantity:"FieldMeta",
		ingredient:"FieldMeta",
		unit:"FieldMeta"
	},
	PreparedIngredientQuantityConnection:{
		pageInfo:"PageInfo",
		edges:"PreparedIngredientQuantityEdge"
	},
	PreparedIngredientQuantityEdge:{
		node:"PreparedIngredientQuantity"
	},
	PreparedRecipeQuantity:{
		_meta:"PreparedRecipeQuantityMeta",
		id:"UUID",
		date:"Date",
		quantity:"Float",
		recipe:"ProductRecipe",
		usedQuantity:"Float"
	},
	PreparedRecipeQuantityMeta:{
		id:"FieldMeta",
		date:"FieldMeta",
		quantity:"FieldMeta",
		recipe:"FieldMeta",
		usedQuantity:"FieldMeta"
	},
	PreparedRecipeQuantityConnection:{
		pageInfo:"PageInfo",
		edges:"PreparedRecipeQuantityEdge"
	},
	PreparedRecipeQuantityEdge:{
		node:"PreparedRecipeQuantity"
	},
	DeliveryTimelinePresetConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryTimelinePresetEdge"
	},
	DeliveryTimelinePresetEdge:{
		node:"DeliveryTimelinePreset"
	},
	SuppliesPurchase:{
		_meta:"SuppliesPurchaseMeta",
		id:"UUID",
		createdAt:"DateTime",
		items:"SuppliesPurchaseItem",
		supplier:"IngredientSupplier",
		requestedDeliveryDate:"Date",
		paginateItems:"SuppliesPurchaseItemConnection"
	},
	SuppliesPurchaseMeta:{
		id:"FieldMeta",
		createdAt:"FieldMeta",
		items:"FieldMeta",
		supplier:"FieldMeta",
		requestedDeliveryDate:"FieldMeta"
	},
	SuppliesPurchaseItem:{
		_meta:"SuppliesPurchaseItemMeta",
		id:"UUID",
		amount:"Int",
		list:"SuppliesPurchase",
		ingredient:"ProductIngredient",
		pricePaidCents:"Int",
		preferredUnit:"ProductIngredientItemQuantityUnit",
		deletedAt:"DateTime",
		purchasedAt:"DateTime",
		purchasedBy:"Staff"
	},
	SuppliesPurchaseItemMeta:{
		id:"FieldMeta",
		amount:"FieldMeta",
		list:"FieldMeta",
		ingredient:"FieldMeta",
		pricePaidCents:"FieldMeta",
		preferredUnit:"FieldMeta",
		deletedAt:"FieldMeta",
		purchasedAt:"FieldMeta",
		purchasedBy:"FieldMeta"
	},
	SuppliesPurchaseItemConnection:{
		pageInfo:"PageInfo",
		edges:"SuppliesPurchaseItemEdge"
	},
	SuppliesPurchaseItemEdge:{
		node:"SuppliesPurchaseItem"
	},
	SuppliesPurchaseConnection:{
		pageInfo:"PageInfo",
		edges:"SuppliesPurchaseEdge"
	},
	SuppliesPurchaseEdge:{
		node:"SuppliesPurchase"
	},
	LogRecord:{
		_meta:"LogRecordMeta",
		id:"UUID",
		level:"Int",
		type:"String",
		message:"String",
		data:"Json",
		createdAt:"DateTime",
		shouldBeResolved:"Boolean",
		resolvedAt:"DateTime"
	},
	LogRecordMeta:{
		id:"FieldMeta",
		level:"FieldMeta",
		type:"FieldMeta",
		message:"FieldMeta",
		data:"FieldMeta",
		createdAt:"FieldMeta",
		shouldBeResolved:"FieldMeta",
		resolvedAt:"FieldMeta"
	},
	LogRecordConnection:{
		pageInfo:"PageInfo",
		edges:"LogRecordEdge"
	},
	LogRecordEdge:{
		node:"LogRecord"
	},
	VirtualProductConnection:{
		pageInfo:"PageInfo",
		edges:"VirtualProductEdge"
	},
	VirtualProductEdge:{
		node:"VirtualProduct"
	},
	ProductMetadataConnection:{
		pageInfo:"PageInfo",
		edges:"ProductMetadataEdge"
	},
	ProductMetadataEdge:{
		node:"ProductMetadata"
	},
	UnassignedShiftInfoConnection:{
		pageInfo:"PageInfo",
		edges:"UnassignedShiftInfoEdge"
	},
	UnassignedShiftInfoEdge:{
		node:"UnassignedShiftInfo"
	},
	TextListConnection:{
		pageInfo:"PageInfo",
		edges:"TextListEdge"
	},
	TextListEdge:{
		node:"TextList"
	},
	DeliveryRegionsConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryRegionsEdge"
	},
	DeliveryRegionsEdge:{
		node:"DeliveryRegions"
	},
	ImageListConnection:{
		pageInfo:"PageInfo",
		edges:"ImageListEdge"
	},
	ImageListEdge:{
		node:"ImageList"
	},
	TimetablesEmployeePrivilegesConnection:{
		pageInfo:"PageInfo",
		edges:"TimetablesEmployeePrivilegesEdge"
	},
	TimetablesEmployeePrivilegesEdge:{
		node:"TimetablesEmployeePrivileges"
	},
	IngredientSupplierConnection:{
		pageInfo:"PageInfo",
		edges:"IngredientSupplierEdge"
	},
	IngredientSupplierEdge:{
		node:"IngredientSupplier"
	},
	IngredientSupplierListConnection:{
		pageInfo:"PageInfo",
		edges:"IngredientSupplierListEdge"
	},
	IngredientSupplierListEdge:{
		node:"IngredientSupplierList"
	},
	RedirectConnection:{
		pageInfo:"PageInfo",
		edges:"RedirectEdge"
	},
	RedirectEdge:{
		node:"Redirect"
	},
	WorkspaceConnection:{
		pageInfo:"PageInfo",
		edges:"WorkspaceEdge"
	},
	WorkspaceEdge:{
		node:"Workspace"
	},
	IdentificationCodeConnection:{
		pageInfo:"PageInfo",
		edges:"IdentificationCodeEdge"
	},
	IdentificationCodeEdge:{
		node:"IdentificationCode"
	},
	DeviceIdentifier:{
		_meta:"DeviceIdentifierMeta",
		id:"UUID",
		internalName:"String",
		updatedAt:"DateTime",
		platform:"String",
		resolution:"String",
		devicePixelRatio:"Float",
		identifier:"String",
		meta:"DeviceIdentifierMetadata",
		applicationVersionIdentifier:"String",
		lastPointOfSaleSynchronizationErrorRawDetails:"String"
	},
	DeviceIdentifierMeta:{
		id:"FieldMeta",
		internalName:"FieldMeta",
		updatedAt:"FieldMeta",
		platform:"FieldMeta",
		resolution:"FieldMeta",
		devicePixelRatio:"FieldMeta",
		identifier:"FieldMeta",
		meta:"FieldMeta",
		applicationVersionIdentifier:"FieldMeta",
		lastPointOfSaleSynchronizationErrorRawDetails:"FieldMeta"
	},
	DeviceIdentifierMetadata:{
		_meta:"DeviceIdentifierMetadataMeta",
		id:"UUID",
		prettyIdentifier:"String",
		deviceIdentifier:"DeviceIdentifier"
	},
	DeviceIdentifierMetadataMeta:{
		id:"FieldMeta",
		prettyIdentifier:"FieldMeta",
		deviceIdentifier:"FieldMeta"
	},
	DeviceIdentifierConnection:{
		pageInfo:"PageInfo",
		edges:"DeviceIdentifierEdge"
	},
	DeviceIdentifierEdge:{
		node:"DeviceIdentifier"
	},
	DeviceIdentifierMetadataConnection:{
		pageInfo:"PageInfo",
		edges:"DeviceIdentifierMetadataEdge"
	},
	DeviceIdentifierMetadataEdge:{
		node:"DeviceIdentifierMetadata"
	},
	CustomerMetadataConnection:{
		pageInfo:"PageInfo",
		edges:"CustomerMetadataEdge"
	},
	CustomerMetadataEdge:{
		node:"CustomerMetadata"
	},
	SubscriptionBoxConnection:{
		pageInfo:"PageInfo",
		edges:"SubscriptionBoxEdge"
	},
	SubscriptionBoxEdge:{
		node:"SubscriptionBox"
	},
	TimetableDayMetadataConnection:{
		pageInfo:"PageInfo",
		edges:"TimetableDayMetadataEdge"
	},
	TimetableDayMetadataEdge:{
		node:"TimetableDayMetadata"
	},
	GridTilesConnection:{
		pageInfo:"PageInfo",
		edges:"GridTilesEdge"
	},
	GridTilesEdge:{
		node:"GridTiles"
	},
	Position:{
		_meta:"PositionMeta",
		id:"UUID",
		x:"Int",
		y:"Int"
	},
	PositionMeta:{
		id:"FieldMeta",
		x:"FieldMeta",
		y:"FieldMeta"
	},
	PositionConnection:{
		pageInfo:"PageInfo",
		edges:"PositionEdge"
	},
	PositionEdge:{
		node:"Position"
	},
	StatsWidget:{
		_meta:"StatsWidgetMeta",
		id:"UUID",
		order:"Int",
		size:"Int",
		type:"StatsEnabledWidgetTypeType",
		link:"String",
		title:"String",
		timeFrame:"StatsWidgetTimeFrame",
		layout:"StatsWidgetsLayout",
		icon:"Image",
		channels:"Channel",
		productCategory:"ProductCategory",
		paymentMethods:"PaymentMethod",
		hoursFrom:"String",
		hoursTo:"String",
		paginateChannels:"ChannelConnection",
		paginatePaymentMethods:"PaymentMethodConnection"
	},
	StatsWidgetMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		link:"FieldMeta",
		title:"FieldMeta",
		timeFrame:"FieldMeta",
		layout:"FieldMeta",
		icon:"FieldMeta",
		channels:"FieldMeta",
		productCategory:"FieldMeta",
		paymentMethods:"FieldMeta",
		hoursFrom:"FieldMeta",
		hoursTo:"FieldMeta"
	},
	StatsWidgetsLayout:{
		_meta:"StatsWidgetsLayoutMeta",
		id:"UUID",
		items:"StatsWidget",
		paginateItems:"StatsWidgetConnection"
	},
	StatsWidgetsLayoutMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	StatsWidgetConnection:{
		pageInfo:"PageInfo",
		edges:"StatsWidgetEdge"
	},
	StatsWidgetEdge:{
		node:"StatsWidget"
	},
	StatsWidgetsLayoutConnection:{
		pageInfo:"PageInfo",
		edges:"StatsWidgetsLayoutEdge"
	},
	StatsWidgetsLayoutEdge:{
		node:"StatsWidgetsLayout"
	},
	StatsPage:{
		_meta:"StatsPageMeta",
		id:"UUID",
		type:"StatsPageType",
		enabled:"Boolean",
		widgets:"StatsWidgetsLayout"
	},
	StatsPageMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		enabled:"FieldMeta",
		widgets:"FieldMeta"
	},
	StatsPageConnection:{
		pageInfo:"PageInfo",
		edges:"StatsPageEdge"
	},
	StatsPageEdge:{
		node:"StatsPage"
	},
	OrderPaymentStatsByDay:{
		_meta:"OrderPaymentStatsByDayMeta",
		id:"String",
		day:"Date",
		totalGratuityCents:"Int",
		reducedGratuityCents:"Int",
		channel:"Channel",
		paymentMethod:"PaymentMethod"
	},
	OrderPaymentStatsByDayMeta:{
		id:"FieldMeta",
		day:"FieldMeta",
		totalGratuityCents:"FieldMeta",
		reducedGratuityCents:"FieldMeta",
		channel:"FieldMeta",
		paymentMethod:"FieldMeta"
	},
	OrderPaymentStatsByDayConnection:{
		pageInfo:"PageInfo",
		edges:"OrderPaymentStatsByDayEdge"
	},
	OrderPaymentStatsByDayEdge:{
		node:"OrderPaymentStatsByDay"
	},
	QueryTransaction:{
		getAddress:"Address",
		listAddress:"Address",
		paginateAddress:"AddressConnection",
		getCountry:"Country",
		listCountry:"Country",
		paginateCountry:"CountryConnection",
		getChannel:"Channel",
		listChannel:"Channel",
		paginateChannel:"ChannelConnection",
		getCustomer:"Customer",
		listCustomer:"Customer",
		paginateCustomer:"CustomerConnection",
		getDeliveryMethod:"DeliveryMethod",
		listDeliveryMethod:"DeliveryMethod",
		paginateDeliveryMethod:"DeliveryMethodConnection",
		getOrder:"Order",
		listOrder:"Order",
		paginateOrder:"OrderConnection",
		getOrderPayment:"OrderPayment",
		listOrderPayment:"OrderPayment",
		paginateOrderPayment:"OrderPaymentConnection",
		getOrderDelivery:"OrderDelivery",
		listOrderDelivery:"OrderDelivery",
		paginateOrderDelivery:"OrderDeliveryConnection",
		getOrderItem:"OrderItem",
		listOrderItem:"OrderItem",
		paginateOrderItem:"OrderItemConnection",
		getPaymentMethod:"PaymentMethod",
		listPaymentMethod:"PaymentMethod",
		paginatePaymentMethod:"PaymentMethodConnection",
		getVatRate:"VatRate",
		listVatRate:"VatRate",
		paginateVatRate:"VatRateConnection",
		getProduct:"Product",
		listProduct:"Product",
		paginateProduct:"ProductConnection",
		getProductStock:"ProductStock",
		listProductStock:"ProductStock",
		paginateProductStock:"ProductStockConnection",
		getProductPacking:"ProductPacking",
		listProductPacking:"ProductPacking",
		paginateProductPacking:"ProductPackingConnection",
		getProductRecipe:"ProductRecipe",
		listProductRecipe:"ProductRecipe",
		paginateProductRecipe:"ProductRecipeConnection",
		getProductCategory:"ProductCategory",
		listProductCategory:"ProductCategory",
		paginateProductCategory:"ProductCategoryConnection",
		getStore:"Store",
		listStore:"Store",
		paginateStore:"StoreConnection",
		getTag:"Tag",
		listTag:"Tag",
		paginateTag:"TagConnection",
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		getBusinessCategory:"BusinessCategory",
		listBusinessCategory:"BusinessCategory",
		paginateBusinessCategory:"BusinessCategoryConnection",
		getProductList:"ProductList",
		listProductList:"ProductList",
		paginateProductList:"ProductListConnection",
		getProductListItem:"ProductListItem",
		listProductListItem:"ProductListItem",
		paginateProductListItem:"ProductListItemConnection",
		getProductCategoryList:"ProductCategoryList",
		listProductCategoryList:"ProductCategoryList",
		paginateProductCategoryList:"ProductCategoryListConnection",
		getProductCategoryListItem:"ProductCategoryListItem",
		listProductCategoryListItem:"ProductCategoryListItem",
		paginateProductCategoryListItem:"ProductCategoryListItemConnection",
		getBusinessCategoryLocale:"BusinessCategoryLocale",
		listBusinessCategoryLocale:"BusinessCategoryLocale",
		paginateBusinessCategoryLocale:"BusinessCategoryLocaleConnection",
		getLocale:"Locale",
		listLocale:"Locale",
		paginateLocale:"LocaleConnection",
		getProductCategoryListLocale:"ProductCategoryListLocale",
		listProductCategoryListLocale:"ProductCategoryListLocale",
		paginateProductCategoryListLocale:"ProductCategoryListLocaleConnection",
		getCustomerName:"CustomerName",
		listCustomerName:"CustomerName",
		paginateCustomerName:"CustomerNameConnection",
		getProductIngredientItem:"ProductIngredientItem",
		listProductIngredientItem:"ProductIngredientItem",
		paginateProductIngredientItem:"ProductIngredientItemConnection",
		getProductIngredientItemQuantity:"ProductIngredientItemQuantity",
		listProductIngredientItemQuantity:"ProductIngredientItemQuantity",
		paginateProductIngredientItemQuantity:"ProductIngredientItemQuantityConnection",
		getProductIngredientItemQuantityUnit:"ProductIngredientItemQuantityUnit",
		listProductIngredientItemQuantityUnit:"ProductIngredientItemQuantityUnit",
		paginateProductIngredientItemQuantityUnit:"ProductIngredientItemQuantityUnitConnection",
		getProductIngredient:"ProductIngredient",
		listProductIngredient:"ProductIngredient",
		paginateProductIngredient:"ProductIngredientConnection",
		getProductIngredientRating:"ProductIngredientRating",
		listProductIngredientRating:"ProductIngredientRating",
		paginateProductIngredientRating:"ProductIngredientRatingConnection",
		getProductIngredientLocale:"ProductIngredientLocale",
		listProductIngredientLocale:"ProductIngredientLocale",
		paginateProductIngredientLocale:"ProductIngredientLocaleConnection",
		getOrderRecurrence:"OrderRecurrence",
		listOrderRecurrence:"OrderRecurrence",
		paginateOrderRecurrence:"OrderRecurrenceConnection",
		getOrderRecurrenceLocale:"OrderRecurrenceLocale",
		listOrderRecurrenceLocale:"OrderRecurrenceLocale",
		paginateOrderRecurrenceLocale:"OrderRecurrenceLocaleConnection",
		getStaff:"Staff",
		listStaff:"Staff",
		paginateStaff:"StaffConnection",
		getBlog:"Blog",
		listBlog:"Blog",
		paginateBlog:"BlogConnection",
		getBlogLocale:"BlogLocale",
		listBlogLocale:"BlogLocale",
		paginateBlogLocale:"BlogLocaleConnection",
		getBlogPost:"BlogPost",
		listBlogPost:"BlogPost",
		paginateBlogPost:"BlogPostConnection",
		getBlogPostLocale:"BlogPostLocale",
		listBlogPostLocale:"BlogPostLocale",
		paginateBlogPostLocale:"BlogPostLocaleConnection",
		getColorPallete:"ColorPallete",
		listColorPallete:"ColorPallete",
		paginateColorPallete:"ColorPalleteConnection",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		getContentReference:"ContentReference",
		listContentReference:"ContentReference",
		paginateContentReference:"ContentReferenceConnection",
		getBlogPostList:"BlogPostList",
		listBlogPostList:"BlogPostList",
		paginateBlogPostList:"BlogPostListConnection",
		getBlogPostListItem:"BlogPostListItem",
		listBlogPostListItem:"BlogPostListItem",
		paginateBlogPostListItem:"BlogPostListItemConnection",
		getLinkList:"LinkList",
		listLinkList:"LinkList",
		paginateLinkList:"LinkListConnection",
		getLinkListItem:"LinkListItem",
		listLinkListItem:"LinkListItem",
		paginateLinkListItem:"LinkListItemConnection",
		getCover:"Cover",
		listCover:"Cover",
		paginateCover:"CoverConnection",
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		getMedium:"Medium",
		listMedium:"Medium",
		paginateMedium:"MediumConnection",
		getVideo:"Video",
		listVideo:"Video",
		paginateVideo:"VideoConnection",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		getTranslationDomain:"TranslationDomain",
		listTranslationDomain:"TranslationDomain",
		paginateTranslationDomain:"TranslationDomainConnection",
		getTranslationCatalogue:"TranslationCatalogue",
		listTranslationCatalogue:"TranslationCatalogue",
		paginateTranslationCatalogue:"TranslationCatalogueConnection",
		getTranslationKey:"TranslationKey",
		listTranslationKey:"TranslationKey",
		paginateTranslationKey:"TranslationKeyConnection",
		getTranslationValue:"TranslationValue",
		listTranslationValue:"TranslationValue",
		paginateTranslationValue:"TranslationValueConnection",
		getTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		listTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		paginateTranslationCataloguesIdentifier:"TranslationCataloguesIdentifierConnection",
		getSocialsAndApps:"SocialsAndApps",
		listSocialsAndApps:"SocialsAndApps",
		paginateSocialsAndApps:"SocialsAndAppsConnection",
		getProductGrid:"ProductGrid",
		listProductGrid:"ProductGrid",
		paginateProductGrid:"ProductGridConnection",
		getProductGridItem:"ProductGridItem",
		listProductGridItem:"ProductGridItem",
		paginateProductGridItem:"ProductGridItemConnection",
		getGallery:"Gallery",
		listGallery:"Gallery",
		paginateGallery:"GalleryConnection",
		getGalleryItem:"GalleryItem",
		listGalleryItem:"GalleryItem",
		paginateGalleryItem:"GalleryItemConnection",
		getHero:"Hero",
		listHero:"Hero",
		paginateHero:"HeroConnection",
		getPage:"Page",
		listPage:"Page",
		paginatePage:"PageConnection",
		getPageLocale:"PageLocale",
		listPageLocale:"PageLocale",
		paginatePageLocale:"PageLocaleConnection",
		getDeadlineTemplate:"DeadlineTemplate",
		listDeadlineTemplate:"DeadlineTemplate",
		paginateDeadlineTemplate:"DeadlineTemplateConnection",
		getEmployeeQueue:"EmployeeQueue",
		listEmployeeQueue:"EmployeeQueue",
		paginateEmployeeQueue:"EmployeeQueueConnection",
		validateCreateEmployeeQueue:"_ValidationResult",
		validateUpdateEmployeeQueue:"_ValidationResult",
		getTimetableDay:"TimetableDay",
		listTimetableDay:"TimetableDay",
		paginateTimetableDay:"TimetableDayConnection",
		getTimetableEmployeeRole:"TimetableEmployeeRole",
		listTimetableEmployeeRole:"TimetableEmployeeRole",
		paginateTimetableEmployeeRole:"TimetableEmployeeRoleConnection",
		getTimetableShift:"TimetableShift",
		listTimetableShift:"TimetableShift",
		paginateTimetableShift:"TimetableShiftConnection",
		validateUpdateTimetableShift:"_ValidationResult",
		getTimetableShiftGroup:"TimetableShiftGroup",
		listTimetableShiftGroup:"TimetableShiftGroup",
		paginateTimetableShiftGroup:"TimetableShiftGroupConnection",
		getTimetableShiftPosition:"TimetableShiftPosition",
		listTimetableShiftPosition:"TimetableShiftPosition",
		paginateTimetableShiftPosition:"TimetableShiftPositionConnection",
		getTimetableTemplate:"TimetableTemplate",
		listTimetableTemplate:"TimetableTemplate",
		paginateTimetableTemplate:"TimetableTemplateConnection",
		getEmployeeQueueItem:"EmployeeQueueItem",
		listEmployeeQueueItem:"EmployeeQueueItem",
		paginateEmployeeQueueItem:"EmployeeQueueItemConnection",
		validateCreateEmployeeQueueItem:"_ValidationResult",
		validateUpdateEmployeeQueueItem:"_ValidationResult",
		getProductBanner:"ProductBanner",
		listProductBanner:"ProductBanner",
		paginateProductBanner:"ProductBannerConnection",
		getProductBannerItem:"ProductBannerItem",
		listProductBannerItem:"ProductBannerItem",
		paginateProductBannerItem:"ProductBannerItemConnection",
		getProductBannerList:"ProductBannerList",
		listProductBannerList:"ProductBannerList",
		paginateProductBannerList:"ProductBannerListConnection",
		getShiftsProfile:"ShiftsProfile",
		listShiftsProfile:"ShiftsProfile",
		paginateShiftsProfile:"ShiftsProfileConnection",
		validateUpdateShiftsProfile:"_ValidationResult",
		getProductIngredientCategory:"ProductIngredientCategory",
		listProductIngredientCategory:"ProductIngredientCategory",
		paginateProductIngredientCategory:"ProductIngredientCategoryConnection",
		getProductIngredientCategoryLocale:"ProductIngredientCategoryLocale",
		listProductIngredientCategoryLocale:"ProductIngredientCategoryLocale",
		paginateProductIngredientCategoryLocale:"ProductIngredientCategoryLocaleConnection",
		getDeliveryRoute:"DeliveryRoute",
		listDeliveryRoute:"DeliveryRoute",
		paginateDeliveryRoute:"DeliveryRouteConnection",
		getDeliveryWaypoint:"DeliveryWaypoint",
		listDeliveryWaypoint:"DeliveryWaypoint",
		paginateDeliveryWaypoint:"DeliveryWaypointConnection",
		getAllergen:"Allergen",
		listAllergen:"Allergen",
		paginateAllergen:"AllergenConnection",
		getAllergenLocale:"AllergenLocale",
		listAllergenLocale:"AllergenLocale",
		paginateAllergenLocale:"AllergenLocaleConnection",
		getWeekHours:"WeekHours",
		listWeekHours:"WeekHours",
		paginateWeekHours:"WeekHoursConnection",
		getPreparedIngredientQuantity:"PreparedIngredientQuantity",
		listPreparedIngredientQuantity:"PreparedIngredientQuantity",
		paginatePreparedIngredientQuantity:"PreparedIngredientQuantityConnection",
		validateCreatePreparedIngredientQuantity:"_ValidationResult",
		validateUpdatePreparedIngredientQuantity:"_ValidationResult",
		getPreparedRecipeQuantity:"PreparedRecipeQuantity",
		listPreparedRecipeQuantity:"PreparedRecipeQuantity",
		paginatePreparedRecipeQuantity:"PreparedRecipeQuantityConnection",
		validateCreatePreparedRecipeQuantity:"_ValidationResult",
		validateUpdatePreparedRecipeQuantity:"_ValidationResult",
		getPreparedPackingQuantity:"PreparedPackingQuantity",
		listPreparedPackingQuantity:"PreparedPackingQuantity",
		paginatePreparedPackingQuantity:"PreparedPackingQuantityConnection",
		validateCreatePreparedPackingQuantity:"_ValidationResult",
		validateUpdatePreparedPackingQuantity:"_ValidationResult",
		getDeliveryZone:"DeliveryZone",
		listDeliveryZone:"DeliveryZone",
		paginateDeliveryZone:"DeliveryZoneConnection",
		getDeliveryTimelinePreset:"DeliveryTimelinePreset",
		listDeliveryTimelinePreset:"DeliveryTimelinePreset",
		paginateDeliveryTimelinePreset:"DeliveryTimelinePresetConnection",
		getProductPackingLocale:"ProductPackingLocale",
		listProductPackingLocale:"ProductPackingLocale",
		paginateProductPackingLocale:"ProductPackingLocaleConnection",
		getProductLocale:"ProductLocale",
		listProductLocale:"ProductLocale",
		paginateProductLocale:"ProductLocaleConnection",
		getProductRecipeLocale:"ProductRecipeLocale",
		listProductRecipeLocale:"ProductRecipeLocale",
		paginateProductRecipeLocale:"ProductRecipeLocaleConnection",
		getSuppliesPurchase:"SuppliesPurchase",
		listSuppliesPurchase:"SuppliesPurchase",
		paginateSuppliesPurchase:"SuppliesPurchaseConnection",
		validateCreateSuppliesPurchase:"_ValidationResult",
		validateUpdateSuppliesPurchase:"_ValidationResult",
		getLogRecord:"LogRecord",
		listLogRecord:"LogRecord",
		paginateLogRecord:"LogRecordConnection",
		getSuppliesPurchaseItem:"SuppliesPurchaseItem",
		listSuppliesPurchaseItem:"SuppliesPurchaseItem",
		paginateSuppliesPurchaseItem:"SuppliesPurchaseItemConnection",
		validateCreateSuppliesPurchaseItem:"_ValidationResult",
		validateUpdateSuppliesPurchaseItem:"_ValidationResult",
		getVirtualProduct:"VirtualProduct",
		listVirtualProduct:"VirtualProduct",
		paginateVirtualProduct:"VirtualProductConnection",
		getProductMetadata:"ProductMetadata",
		listProductMetadata:"ProductMetadata",
		paginateProductMetadata:"ProductMetadataConnection",
		getProductGridCategory:"ProductGridCategory",
		listProductGridCategory:"ProductGridCategory",
		paginateProductGridCategory:"ProductGridCategoryConnection",
		getUnassignedShiftInfo:"UnassignedShiftInfo",
		listUnassignedShiftInfo:"UnassignedShiftInfo",
		paginateUnassignedShiftInfo:"UnassignedShiftInfoConnection",
		validateCreateUnassignedShiftInfo:"_ValidationResult",
		validateUpdateUnassignedShiftInfo:"_ValidationResult",
		getFreshingContainer:"FreshingContainer",
		listFreshingContainer:"FreshingContainer",
		paginateFreshingContainer:"FreshingContainerConnection",
		getTextItem:"TextItem",
		listTextItem:"TextItem",
		paginateTextItem:"TextItemConnection",
		getTextList:"TextList",
		listTextList:"TextList",
		paginateTextList:"TextListConnection",
		getDeliveryRegions:"DeliveryRegions",
		listDeliveryRegions:"DeliveryRegions",
		paginateDeliveryRegions:"DeliveryRegionsConnection",
		getDeliveryRegionsItem:"DeliveryRegionsItem",
		listDeliveryRegionsItem:"DeliveryRegionsItem",
		paginateDeliveryRegionsItem:"DeliveryRegionsItemConnection",
		getImageList:"ImageList",
		listImageList:"ImageList",
		paginateImageList:"ImageListConnection",
		getImageListItem:"ImageListItem",
		listImageListItem:"ImageListItem",
		paginateImageListItem:"ImageListItemConnection",
		getTimetablesEmployeePrivileges:"TimetablesEmployeePrivileges",
		listTimetablesEmployeePrivileges:"TimetablesEmployeePrivileges",
		paginateTimetablesEmployeePrivileges:"TimetablesEmployeePrivilegesConnection",
		getTimetableDayNote:"TimetableDayNote",
		listTimetableDayNote:"TimetableDayNote",
		paginateTimetableDayNote:"TimetableDayNoteConnection",
		getDeliveryMethodLocale:"DeliveryMethodLocale",
		listDeliveryMethodLocale:"DeliveryMethodLocale",
		paginateDeliveryMethodLocale:"DeliveryMethodLocaleConnection",
		getIngredientSupplier:"IngredientSupplier",
		listIngredientSupplier:"IngredientSupplier",
		paginateIngredientSupplier:"IngredientSupplierConnection",
		getIngredientSupplierItem:"IngredientSupplierItem",
		listIngredientSupplierItem:"IngredientSupplierItem",
		paginateIngredientSupplierItem:"IngredientSupplierItemConnection",
		getIngredientSupplierList:"IngredientSupplierList",
		listIngredientSupplierList:"IngredientSupplierList",
		paginateIngredientSupplierList:"IngredientSupplierListConnection",
		getIngredientSupplierLocale:"IngredientSupplierLocale",
		listIngredientSupplierLocale:"IngredientSupplierLocale",
		paginateIngredientSupplierLocale:"IngredientSupplierLocaleConnection",
		getRedirect:"Redirect",
		listRedirect:"Redirect",
		paginateRedirect:"RedirectConnection",
		getVenue:"Venue",
		listVenue:"Venue",
		paginateVenue:"VenueConnection",
		getWorkspace:"Workspace",
		listWorkspace:"Workspace",
		paginateWorkspace:"WorkspaceConnection",
		getVenueEmployeeRole:"VenueEmployeeRole",
		listVenueEmployeeRole:"VenueEmployeeRole",
		paginateVenueEmployeeRole:"VenueEmployeeRoleConnection",
		getIdentificationAttempt:"IdentificationAttempt",
		listIdentificationAttempt:"IdentificationAttempt",
		paginateIdentificationAttempt:"IdentificationAttemptConnection",
		getIdentificationCode:"IdentificationCode",
		listIdentificationCode:"IdentificationCode",
		paginateIdentificationCode:"IdentificationCodeConnection",
		getIdentificationCodeLocale:"IdentificationCodeLocale",
		listIdentificationCodeLocale:"IdentificationCodeLocale",
		paginateIdentificationCodeLocale:"IdentificationCodeLocaleConnection",
		getDeviceIdentifier:"DeviceIdentifier",
		listDeviceIdentifier:"DeviceIdentifier",
		paginateDeviceIdentifier:"DeviceIdentifierConnection",
		validateCreateDeviceIdentifier:"_ValidationResult",
		validateUpdateDeviceIdentifier:"_ValidationResult",
		getDeviceIdentifierMetadata:"DeviceIdentifierMetadata",
		listDeviceIdentifierMetadata:"DeviceIdentifierMetadata",
		paginateDeviceIdentifierMetadata:"DeviceIdentifierMetadataConnection",
		getFulfillmentNote:"FulfillmentNote",
		listFulfillmentNote:"FulfillmentNote",
		paginateFulfillmentNote:"FulfillmentNoteConnection",
		getCustomerMetadata:"CustomerMetadata",
		listCustomerMetadata:"CustomerMetadata",
		paginateCustomerMetadata:"CustomerMetadataConnection",
		getSubscriptionBox:"SubscriptionBox",
		listSubscriptionBox:"SubscriptionBox",
		paginateSubscriptionBox:"SubscriptionBoxConnection",
		getSubscriptionBoxLocale:"SubscriptionBoxLocale",
		listSubscriptionBoxLocale:"SubscriptionBoxLocale",
		paginateSubscriptionBoxLocale:"SubscriptionBoxLocaleConnection",
		getTimetableDayMetadata:"TimetableDayMetadata",
		listTimetableDayMetadata:"TimetableDayMetadata",
		paginateTimetableDayMetadata:"TimetableDayMetadataConnection",
		getGridTileItem:"GridTileItem",
		listGridTileItem:"GridTileItem",
		paginateGridTileItem:"GridTileItemConnection",
		getGridTiles:"GridTiles",
		listGridTiles:"GridTiles",
		paginateGridTiles:"GridTilesConnection",
		getPosition:"Position",
		listPosition:"Position",
		paginatePosition:"PositionConnection",
		getStatsWidget:"StatsWidget",
		listStatsWidget:"StatsWidget",
		paginateStatsWidget:"StatsWidgetConnection",
		getStatsWidgetsLayout:"StatsWidgetsLayout",
		listStatsWidgetsLayout:"StatsWidgetsLayout",
		paginateStatsWidgetsLayout:"StatsWidgetsLayoutConnection",
		getStatsPage:"StatsPage",
		listStatsPage:"StatsPage",
		paginateStatsPage:"StatsPageConnection",
		getUnassignReason:"UnassignReason",
		listUnassignReason:"UnassignReason",
		paginateUnassignReason:"UnassignReasonConnection",
		getOrderPaymentStatsByDay:"OrderPaymentStatsByDay",
		listOrderPaymentStatsByDay:"OrderPaymentStatsByDay",
		paginateOrderPaymentStatsByDay:"OrderPaymentStatsByDayConnection"
	},
	Info:{
		description:"String"
	},
	Mutation:{
		createEmployeeQueue:"EmployeeQueueCreateResult",
		updateEmployeeQueue:"EmployeeQueueUpdateResult",
		upsertEmployeeQueue:"EmployeeQueueUpsertResult",
		updateTimetableShift:"TimetableShiftUpdateResult",
		createEmployeeQueueItem:"EmployeeQueueItemCreateResult",
		deleteEmployeeQueueItem:"EmployeeQueueItemDeleteResult",
		updateEmployeeQueueItem:"EmployeeQueueItemUpdateResult",
		upsertEmployeeQueueItem:"EmployeeQueueItemUpsertResult",
		updateShiftsProfile:"ShiftsProfileUpdateResult",
		createPreparedIngredientQuantity:"PreparedIngredientQuantityCreateResult",
		deletePreparedIngredientQuantity:"PreparedIngredientQuantityDeleteResult",
		updatePreparedIngredientQuantity:"PreparedIngredientQuantityUpdateResult",
		upsertPreparedIngredientQuantity:"PreparedIngredientQuantityUpsertResult",
		createPreparedRecipeQuantity:"PreparedRecipeQuantityCreateResult",
		deletePreparedRecipeQuantity:"PreparedRecipeQuantityDeleteResult",
		updatePreparedRecipeQuantity:"PreparedRecipeQuantityUpdateResult",
		upsertPreparedRecipeQuantity:"PreparedRecipeQuantityUpsertResult",
		createPreparedPackingQuantity:"PreparedPackingQuantityCreateResult",
		deletePreparedPackingQuantity:"PreparedPackingQuantityDeleteResult",
		updatePreparedPackingQuantity:"PreparedPackingQuantityUpdateResult",
		upsertPreparedPackingQuantity:"PreparedPackingQuantityUpsertResult",
		createSuppliesPurchase:"SuppliesPurchaseCreateResult",
		updateSuppliesPurchase:"SuppliesPurchaseUpdateResult",
		upsertSuppliesPurchase:"SuppliesPurchaseUpsertResult",
		createSuppliesPurchaseItem:"SuppliesPurchaseItemCreateResult",
		updateSuppliesPurchaseItem:"SuppliesPurchaseItemUpdateResult",
		upsertSuppliesPurchaseItem:"SuppliesPurchaseItemUpsertResult",
		createUnassignedShiftInfo:"UnassignedShiftInfoCreateResult",
		deleteUnassignedShiftInfo:"UnassignedShiftInfoDeleteResult",
		updateUnassignedShiftInfo:"UnassignedShiftInfoUpdateResult",
		upsertUnassignedShiftInfo:"UnassignedShiftInfoUpsertResult",
		createDeviceIdentifier:"DeviceIdentifierCreateResult",
		updateDeviceIdentifier:"DeviceIdentifierUpdateResult",
		upsertDeviceIdentifier:"DeviceIdentifierUpsertResult",
		transaction:"MutationTransaction",
		query:"Query"
	},
	EmployeeQueueCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"EmployeeQueue",
		validation:"_ValidationResult"
	},
	MutationResult:{
		"...on EmployeeQueueCreateResult": "EmployeeQueueCreateResult",
		"...on EmployeeQueueUpdateResult": "EmployeeQueueUpdateResult",
		"...on EmployeeQueueUpsertResult": "EmployeeQueueUpsertResult",
		"...on TimetableShiftUpdateResult": "TimetableShiftUpdateResult",
		"...on EmployeeQueueItemCreateResult": "EmployeeQueueItemCreateResult",
		"...on EmployeeQueueItemDeleteResult": "EmployeeQueueItemDeleteResult",
		"...on EmployeeQueueItemUpdateResult": "EmployeeQueueItemUpdateResult",
		"...on EmployeeQueueItemUpsertResult": "EmployeeQueueItemUpsertResult",
		"...on ShiftsProfileUpdateResult": "ShiftsProfileUpdateResult",
		"...on PreparedIngredientQuantityCreateResult": "PreparedIngredientQuantityCreateResult",
		"...on PreparedIngredientQuantityDeleteResult": "PreparedIngredientQuantityDeleteResult",
		"...on PreparedIngredientQuantityUpdateResult": "PreparedIngredientQuantityUpdateResult",
		"...on PreparedIngredientQuantityUpsertResult": "PreparedIngredientQuantityUpsertResult",
		"...on PreparedRecipeQuantityCreateResult": "PreparedRecipeQuantityCreateResult",
		"...on PreparedRecipeQuantityDeleteResult": "PreparedRecipeQuantityDeleteResult",
		"...on PreparedRecipeQuantityUpdateResult": "PreparedRecipeQuantityUpdateResult",
		"...on PreparedRecipeQuantityUpsertResult": "PreparedRecipeQuantityUpsertResult",
		"...on PreparedPackingQuantityCreateResult": "PreparedPackingQuantityCreateResult",
		"...on PreparedPackingQuantityDeleteResult": "PreparedPackingQuantityDeleteResult",
		"...on PreparedPackingQuantityUpdateResult": "PreparedPackingQuantityUpdateResult",
		"...on PreparedPackingQuantityUpsertResult": "PreparedPackingQuantityUpsertResult",
		"...on SuppliesPurchaseCreateResult": "SuppliesPurchaseCreateResult",
		"...on SuppliesPurchaseUpdateResult": "SuppliesPurchaseUpdateResult",
		"...on SuppliesPurchaseUpsertResult": "SuppliesPurchaseUpsertResult",
		"...on SuppliesPurchaseItemCreateResult": "SuppliesPurchaseItemCreateResult",
		"...on SuppliesPurchaseItemUpdateResult": "SuppliesPurchaseItemUpdateResult",
		"...on SuppliesPurchaseItemUpsertResult": "SuppliesPurchaseItemUpsertResult",
		"...on UnassignedShiftInfoCreateResult": "UnassignedShiftInfoCreateResult",
		"...on UnassignedShiftInfoDeleteResult": "UnassignedShiftInfoDeleteResult",
		"...on UnassignedShiftInfoUpdateResult": "UnassignedShiftInfoUpdateResult",
		"...on UnassignedShiftInfoUpsertResult": "UnassignedShiftInfoUpsertResult",
		"...on DeviceIdentifierCreateResult": "DeviceIdentifierCreateResult",
		"...on DeviceIdentifierUpdateResult": "DeviceIdentifierUpdateResult",
		"...on DeviceIdentifierUpsertResult": "DeviceIdentifierUpsertResult",
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError"
	},
	_MutationError:{
		path:"_PathFragment",
		paths:"_PathFragment",
		type:"_MutationErrorType",
		message:"String"
	},
	EmployeeQueueUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"EmployeeQueue",
		validation:"_ValidationResult"
	},
	EmployeeQueueUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"EmployeeQueue",
		validation:"_ValidationResult"
	},
	TimetableShiftUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"TimetableShift",
		validation:"_ValidationResult"
	},
	EmployeeQueueItemCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"EmployeeQueueItem",
		validation:"_ValidationResult"
	},
	EmployeeQueueItemDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"EmployeeQueueItem"
	},
	EmployeeQueueItemUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"EmployeeQueueItem",
		validation:"_ValidationResult"
	},
	EmployeeQueueItemUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"EmployeeQueueItem",
		validation:"_ValidationResult"
	},
	ShiftsProfileUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ShiftsProfile",
		validation:"_ValidationResult"
	},
	PreparedIngredientQuantityCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"PreparedIngredientQuantity",
		validation:"_ValidationResult"
	},
	PreparedIngredientQuantityDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"PreparedIngredientQuantity"
	},
	PreparedIngredientQuantityUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"PreparedIngredientQuantity",
		validation:"_ValidationResult"
	},
	PreparedIngredientQuantityUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"PreparedIngredientQuantity",
		validation:"_ValidationResult"
	},
	PreparedRecipeQuantityCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"PreparedRecipeQuantity",
		validation:"_ValidationResult"
	},
	PreparedRecipeQuantityDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"PreparedRecipeQuantity"
	},
	PreparedRecipeQuantityUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"PreparedRecipeQuantity",
		validation:"_ValidationResult"
	},
	PreparedRecipeQuantityUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"PreparedRecipeQuantity",
		validation:"_ValidationResult"
	},
	PreparedPackingQuantityCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"PreparedPackingQuantity",
		validation:"_ValidationResult"
	},
	PreparedPackingQuantityDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"PreparedPackingQuantity"
	},
	PreparedPackingQuantityUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"PreparedPackingQuantity",
		validation:"_ValidationResult"
	},
	PreparedPackingQuantityUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"PreparedPackingQuantity",
		validation:"_ValidationResult"
	},
	SuppliesPurchaseCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"SuppliesPurchase",
		validation:"_ValidationResult"
	},
	SuppliesPurchaseUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"SuppliesPurchase",
		validation:"_ValidationResult"
	},
	SuppliesPurchaseUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"SuppliesPurchase",
		validation:"_ValidationResult"
	},
	SuppliesPurchaseItemCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"SuppliesPurchaseItem",
		validation:"_ValidationResult"
	},
	SuppliesPurchaseItemUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"SuppliesPurchaseItem",
		validation:"_ValidationResult"
	},
	SuppliesPurchaseItemUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"SuppliesPurchaseItem",
		validation:"_ValidationResult"
	},
	UnassignedShiftInfoCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"UnassignedShiftInfo",
		validation:"_ValidationResult"
	},
	UnassignedShiftInfoDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"UnassignedShiftInfo"
	},
	UnassignedShiftInfoUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"UnassignedShiftInfo",
		validation:"_ValidationResult"
	},
	UnassignedShiftInfoUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"UnassignedShiftInfo",
		validation:"_ValidationResult"
	},
	DeviceIdentifierCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"DeviceIdentifier",
		validation:"_ValidationResult"
	},
	DeviceIdentifierUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"DeviceIdentifier",
		validation:"_ValidationResult"
	},
	DeviceIdentifierUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"DeviceIdentifier",
		validation:"_ValidationResult"
	},
	MutationTransaction:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		validation:"_ValidationResult",
		createEmployeeQueue:"EmployeeQueueCreateResult",
		updateEmployeeQueue:"EmployeeQueueUpdateResult",
		upsertEmployeeQueue:"EmployeeQueueUpsertResult",
		updateTimetableShift:"TimetableShiftUpdateResult",
		createEmployeeQueueItem:"EmployeeQueueItemCreateResult",
		deleteEmployeeQueueItem:"EmployeeQueueItemDeleteResult",
		updateEmployeeQueueItem:"EmployeeQueueItemUpdateResult",
		upsertEmployeeQueueItem:"EmployeeQueueItemUpsertResult",
		updateShiftsProfile:"ShiftsProfileUpdateResult",
		createPreparedIngredientQuantity:"PreparedIngredientQuantityCreateResult",
		deletePreparedIngredientQuantity:"PreparedIngredientQuantityDeleteResult",
		updatePreparedIngredientQuantity:"PreparedIngredientQuantityUpdateResult",
		upsertPreparedIngredientQuantity:"PreparedIngredientQuantityUpsertResult",
		createPreparedRecipeQuantity:"PreparedRecipeQuantityCreateResult",
		deletePreparedRecipeQuantity:"PreparedRecipeQuantityDeleteResult",
		updatePreparedRecipeQuantity:"PreparedRecipeQuantityUpdateResult",
		upsertPreparedRecipeQuantity:"PreparedRecipeQuantityUpsertResult",
		createPreparedPackingQuantity:"PreparedPackingQuantityCreateResult",
		deletePreparedPackingQuantity:"PreparedPackingQuantityDeleteResult",
		updatePreparedPackingQuantity:"PreparedPackingQuantityUpdateResult",
		upsertPreparedPackingQuantity:"PreparedPackingQuantityUpsertResult",
		createSuppliesPurchase:"SuppliesPurchaseCreateResult",
		updateSuppliesPurchase:"SuppliesPurchaseUpdateResult",
		upsertSuppliesPurchase:"SuppliesPurchaseUpsertResult",
		createSuppliesPurchaseItem:"SuppliesPurchaseItemCreateResult",
		updateSuppliesPurchaseItem:"SuppliesPurchaseItemUpdateResult",
		upsertSuppliesPurchaseItem:"SuppliesPurchaseItemUpsertResult",
		createUnassignedShiftInfo:"UnassignedShiftInfoCreateResult",
		deleteUnassignedShiftInfo:"UnassignedShiftInfoDeleteResult",
		updateUnassignedShiftInfo:"UnassignedShiftInfoUpdateResult",
		upsertUnassignedShiftInfo:"UnassignedShiftInfoUpsertResult",
		createDeviceIdentifier:"DeviceIdentifierCreateResult",
		updateDeviceIdentifier:"DeviceIdentifierUpdateResult",
		upsertDeviceIdentifier:"DeviceIdentifierUpsertResult",
		query:"Query"
	},
	_Schema:{
		enums:"_Enum",
		entities:"_Entity"
	},
	_Entity:{
		name:"String",
		customPrimaryAllowed:"Boolean",
		fields:"_Field",
		unique:"_UniqueConstraint"
	},
	_UniqueConstraint:{
		fields:"String"
	},
	_Field:{
		"...on _Column": "_Column",
		"...on _Relation": "_Relation",
		name:"String",
		type:"String",
		nullable:"Boolean",
		rules:"_Rule",
		validators:"_Validator"
	},
	_Column:{
		name:"String",
		type:"String",
		enumName:"String",
		defaultValue:"Json",
		nullable:"Boolean",
		rules:"_Rule",
		validators:"_Validator"
	},
	_OrderBy:{
		path:"String",
		direction:"_OrderByDirection"
	},
	_Relation:{
		name:"String",
		type:"String",
		side:"_RelationSide",
		targetEntity:"String",
		ownedBy:"String",
		inversedBy:"String",
		nullable:"Boolean",
		onDelete:"_OnDeleteBehaviour",
		orphanRemoval:"Boolean",
		orderBy:"_OrderBy",
		rules:"_Rule",
		validators:"_Validator"
	},
	_Rule:{
		message:"_RuleMessage",
		validator:"Int"
	},
	_Validator:{
		operation:"String",
		arguments:"_Argument"
	},
	_Argument:{
		"...on _ValidatorArgument":"_ValidatorArgument",
		"...on _PathArgument":"_PathArgument",
		"...on _LiteralArgument":"_LiteralArgument"
	},
	_ValidatorArgument:{
		validator:"Int"
	},
	_PathArgument:{
		path:"String"
	},
	_LiteralArgument:{
		value:"Json"
	},
	_RuleMessage:{
		text:"String"
	},
	_Enum:{
		name:"String",
		values:"String"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}


export const HOST="Specify host"



const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, unknown> = {}) => {
  const fetchOptions = options[1] || {};
  if (fetchOptions.method && fetchOptions.method === 'GET') {
    return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  }
  return fetch(`${options[0]}`, {
    body: JSON.stringify({ query, variables }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...fetchOptions,
  })
    .then(handleFetchResponse)
    .then((response: GraphQLResponse) => {
      if (response.errors) {
        throw new GraphQLError(response);
      }
      return response.data;
    });
};




export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};






export const InternalsBuildQuery = (
  props: AllTypesPropsType,
  returns: ReturnTypesType,
  ops: Operations,
  options?: OperationOptions,
) => {
  const ibb = (k: string, o: InputValueType | VType, p = '', root = true): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt(props, returns, ops, options?.variables?.values)(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const hasVariables = root && options?.variables?.$params ? `(${options.variables?.$params})` : '';
    const keyForDirectives = o.__directives ?? '';
    return `${k} ${keyForDirectives}${hasOperationName}${hasVariables}{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false))
      .join('\n')}}`;
  };
  return ibb;
};










export const Thunder = (fn: FetchFunction) => <
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullChainConstruct(fn)(operation)(o as any, ops) as Promise<InputType<GraphQLTypes[R], Z>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder = (fn: SubscriptionFunction) => <
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullSubscriptionConstruct(fn)(operation)(o as any, ops) as SubscriptionToGraphQL<Z, GraphQLTypes[R]>;

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: OperationOptions,
) => InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, ops)(operation, o as any);
export const Selector = <T extends keyof ValueTypes>(key: T) => ZeusSelect<ValueTypes[T]>();

export const Gql = Chain(HOST);






export const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription') => (
  o: Record<any, any>,
  options?: OperationOptions,
) => {
  const builder = InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, options);
  return fn(builder(t, o), options?.variables?.values);
};






export const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (t: 'query' | 'mutation' | 'subscription') => (
  o: Record<any, any>,
  options?: OperationOptions,
) => {
  const builder = InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, options);
  return fn(builder(t, o));
};





export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | boolean
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string | undefined>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, any>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions<Z extends Record<string, unknown> = Record<string, unknown>> = {
  variables?: VariableInput<Z>;
  operationName?: string;
};

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;


export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');




const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (typeof propsP1 === 'boolean' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'object') {
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = (
  props: AllTypesPropsType,
  returns: ReturnTypesType,
  ops: Operations,
  variables?: Record<string, unknown>,
) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (a.startsWith('$') && variables?.[a.slice(1)]) {
        return a;
      }
      const checkType = ResolveFromPath(props, returns, ops)(p);
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};




export const resolverFor = <X, T extends keyof ValueTypes, Z extends keyof ValueTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any, source?: any) => any;


export type SelectionFunction<V> = <T>(t: T | V) => T;
export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;




export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : Record<string, unknown>
        : never;
    }[keyof DST] &
      {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? SRC[P] : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>[keyof MapType<SRC, R[P]>];
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
export type SubscriptionToGraphQL<Z, T> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?: string[] }) => void) => void;
  open: () => void;
};


export const useZeusVariables =
  <T extends Record<string, unknown>>(variables: T) =>
  <
    Z extends {
      [P in keyof T]: unknown;
    },
  >(
    values: Z,
  ) => {
    return {
      $params: Object.keys(variables)
        .map((k) => `$${k}: ${variables[k as keyof T]}`)
        .join(', '),
      $: <U extends keyof Z>(variable: U) => {
        return `$${String(variable)}` as unknown as Z[U];
      },
      values,
    };
  };

export type VariableInput<Z extends Record<string, unknown>> = {
  $params: ReturnType<ReturnType<typeof useZeusVariables>>['$params'];
  values: Z;
};


type ZEUS_INTERFACES = GraphQLTypes["MutationResult"] | GraphQLTypes["_Field"]
type ZEUS_UNIONS = GraphQLTypes["_PathFragment"] | GraphQLTypes["_Argument"]

export type ValueTypes = {
    ["Query"]: AliasType<{
getAddress?: [{	by: ValueTypes["AddressUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
listAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Address"]],
paginateAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AddressConnection"]],
getCountry?: [{	by: ValueTypes["CountryUniqueWhere"],	filter?: ValueTypes["CountryWhere"] | undefined | null},ValueTypes["Country"]],
listCountry?: [{	filter?: ValueTypes["CountryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CountryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Country"]],
paginateCountry?: [{	filter?: ValueTypes["CountryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CountryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CountryConnection"]],
getChannel?: [{	by: ValueTypes["ChannelUniqueWhere"],	filter?: ValueTypes["ChannelWhere"] | undefined | null},ValueTypes["Channel"]],
listChannel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
paginateChannel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
getCustomer?: [{	by: ValueTypes["CustomerUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
listCustomer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Customer"]],
paginateCustomer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerConnection"]],
getDeliveryMethod?: [{	by: ValueTypes["DeliveryMethodUniqueWhere"],	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
listDeliveryMethod?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethod"]],
paginateDeliveryMethod?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodConnection"]],
getOrder?: [{	by: ValueTypes["OrderUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
listOrder?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
paginateOrder?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
getOrderPayment?: [{	by: ValueTypes["OrderPaymentUniqueWhere"],	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null},ValueTypes["OrderPayment"]],
listOrderPayment?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPayment"]],
paginateOrderPayment?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPaymentConnection"]],
getOrderDelivery?: [{	by: ValueTypes["OrderDeliveryUniqueWhere"],	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
listOrderDelivery?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderDelivery"]],
paginateOrderDelivery?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderDeliveryConnection"]],
getOrderItem?: [{	by: ValueTypes["OrderItemUniqueWhere"],	filter?: ValueTypes["OrderItemWhere"] | undefined | null},ValueTypes["OrderItem"]],
listOrderItem?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderItem"]],
paginateOrderItem?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderItemConnection"]],
getPaymentMethod?: [{	by: ValueTypes["PaymentMethodUniqueWhere"],	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null},ValueTypes["PaymentMethod"]],
listPaymentMethod?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PaymentMethod"]],
paginatePaymentMethod?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PaymentMethodConnection"]],
getVatRate?: [{	by: ValueTypes["VatRateUniqueWhere"],	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
listVatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VatRateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VatRate"]],
paginateVatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VatRateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VatRateConnection"]],
getProduct?: [{	by: ValueTypes["ProductUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
listProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
paginateProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
getProductStock?: [{	by: ValueTypes["ProductStockUniqueWhere"],	filter?: ValueTypes["ProductStockWhere"] | undefined | null},ValueTypes["ProductStock"]],
listProductStock?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductStock"]],
paginateProductStock?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductStockConnection"]],
getProductPacking?: [{	by: ValueTypes["ProductPackingUniqueWhere"],	filter?: ValueTypes["ProductPackingWhere"] | undefined | null},ValueTypes["ProductPacking"]],
listProductPacking?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPacking"]],
paginateProductPacking?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingConnection"]],
getProductRecipe?: [{	by: ValueTypes["ProductRecipeUniqueWhere"],	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
listProductRecipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipe"]],
paginateProductRecipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeConnection"]],
getProductCategory?: [{	by: ValueTypes["ProductCategoryUniqueWhere"],	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
listProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategory"]],
paginateProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryConnection"]],
getStore?: [{	by: ValueTypes["StoreUniqueWhere"],	filter?: ValueTypes["StoreWhere"] | undefined | null},ValueTypes["Store"]],
listStore?: [{	filter?: ValueTypes["StoreWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StoreOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Store"]],
paginateStore?: [{	filter?: ValueTypes["StoreWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StoreOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StoreConnection"]],
getTag?: [{	by: ValueTypes["TagUniqueWhere"],	filter?: ValueTypes["TagWhere"] | undefined | null},ValueTypes["Tag"]],
listTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
paginateTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
getImage?: [{	by: ValueTypes["ImageUniqueWhere"],	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
listImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Image"]],
paginateImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageConnection"]],
getBusinessCategory?: [{	by: ValueTypes["BusinessCategoryUniqueWhere"],	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
listBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategory"]],
paginateBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryConnection"]],
getProductList?: [{	by: ValueTypes["ProductListUniqueWhere"],	filter?: ValueTypes["ProductListWhere"] | undefined | null},ValueTypes["ProductList"]],
listProductList?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductList"]],
paginateProductList?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductListConnection"]],
getProductListItem?: [{	by: ValueTypes["ProductListItemUniqueWhere"],	filter?: ValueTypes["ProductListItemWhere"] | undefined | null},ValueTypes["ProductListItem"]],
listProductListItem?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductListItem"]],
paginateProductListItem?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductListItemConnection"]],
getProductCategoryList?: [{	by: ValueTypes["ProductCategoryListUniqueWhere"],	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
listProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryList"]],
paginateProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListConnection"]],
getProductCategoryListItem?: [{	by: ValueTypes["ProductCategoryListItemUniqueWhere"],	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null},ValueTypes["ProductCategoryListItem"]],
listProductCategoryListItem?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListItem"]],
paginateProductCategoryListItem?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListItemConnection"]],
getBusinessCategoryLocale?: [{	by: ValueTypes["BusinessCategoryLocaleUniqueWhere"],	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null},ValueTypes["BusinessCategoryLocale"]],
listBusinessCategoryLocale?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategoryLocale"]],
paginateBusinessCategoryLocale?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryLocaleConnection"]],
getLocale?: [{	by: ValueTypes["LocaleUniqueWhere"],	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
listLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Locale"]],
paginateLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LocaleConnection"]],
getProductCategoryListLocale?: [{	by: ValueTypes["ProductCategoryListLocaleUniqueWhere"],	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null},ValueTypes["ProductCategoryListLocale"]],
listProductCategoryListLocale?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListLocale"]],
paginateProductCategoryListLocale?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListLocaleConnection"]],
getCustomerName?: [{	by: ValueTypes["CustomerNameUniqueWhere"],	filter?: ValueTypes["CustomerNameWhere"] | undefined | null},ValueTypes["CustomerName"]],
listCustomerName?: [{	filter?: ValueTypes["CustomerNameWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerNameOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerName"]],
paginateCustomerName?: [{	filter?: ValueTypes["CustomerNameWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerNameOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerNameConnection"]],
getProductIngredientItem?: [{	by: ValueTypes["ProductIngredientItemUniqueWhere"],	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null},ValueTypes["ProductIngredientItem"]],
listProductIngredientItem?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientItem"]],
paginateProductIngredientItem?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientItemConnection"]],
getProductIngredientItemQuantity?: [{	by: ValueTypes["ProductIngredientItemQuantityUniqueWhere"],	filter?: ValueTypes["ProductIngredientItemQuantityWhere"] | undefined | null},ValueTypes["ProductIngredientItemQuantity"]],
listProductIngredientItemQuantity?: [{	filter?: ValueTypes["ProductIngredientItemQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemQuantityOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientItemQuantity"]],
paginateProductIngredientItemQuantity?: [{	filter?: ValueTypes["ProductIngredientItemQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemQuantityOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientItemQuantityConnection"]],
getProductIngredientItemQuantityUnit?: [{	by: ValueTypes["ProductIngredientItemQuantityUnitUniqueWhere"],	filter?: ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null},ValueTypes["ProductIngredientItemQuantityUnit"]],
listProductIngredientItemQuantityUnit?: [{	filter?: ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemQuantityUnitOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientItemQuantityUnit"]],
paginateProductIngredientItemQuantityUnit?: [{	filter?: ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemQuantityUnitOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientItemQuantityUnitConnection"]],
getProductIngredient?: [{	by: ValueTypes["ProductIngredientUniqueWhere"],	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null},ValueTypes["ProductIngredient"]],
listProductIngredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredient"]],
paginateProductIngredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientConnection"]],
getProductIngredientRating?: [{	by: ValueTypes["ProductIngredientRatingUniqueWhere"],	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null},ValueTypes["ProductIngredientRating"]],
listProductIngredientRating?: [{	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientRatingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientRating"]],
paginateProductIngredientRating?: [{	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientRatingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientRatingConnection"]],
getProductIngredientLocale?: [{	by: ValueTypes["ProductIngredientLocaleUniqueWhere"],	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientLocale"]],
listProductIngredientLocale?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientLocale"]],
paginateProductIngredientLocale?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientLocaleConnection"]],
getOrderRecurrence?: [{	by: ValueTypes["OrderRecurrenceUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null},ValueTypes["OrderRecurrence"]],
listOrderRecurrence?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrence"]],
paginateOrderRecurrence?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceConnection"]],
getOrderRecurrenceLocale?: [{	by: ValueTypes["OrderRecurrenceLocaleUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
listOrderRecurrenceLocale?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
paginateOrderRecurrenceLocale?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceLocaleConnection"]],
getStaff?: [{	by: ValueTypes["StaffUniqueWhere"],	filter?: ValueTypes["StaffWhere"] | undefined | null},ValueTypes["Staff"]],
listStaff?: [{	filter?: ValueTypes["StaffWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StaffOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Staff"]],
paginateStaff?: [{	filter?: ValueTypes["StaffWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StaffOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StaffConnection"]],
getBlog?: [{	by: ValueTypes["BlogUniqueWhere"],	filter?: ValueTypes["BlogWhere"] | undefined | null},ValueTypes["Blog"]],
listBlog?: [{	filter?: ValueTypes["BlogWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Blog"]],
paginateBlog?: [{	filter?: ValueTypes["BlogWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogConnection"]],
getBlogLocale?: [{	by: ValueTypes["BlogLocaleUniqueWhere"],	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null},ValueTypes["BlogLocale"]],
listBlogLocale?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogLocale"]],
paginateBlogLocale?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogLocaleConnection"]],
getBlogPost?: [{	by: ValueTypes["BlogPostUniqueWhere"],	filter?: ValueTypes["BlogPostWhere"] | undefined | null},ValueTypes["BlogPost"]],
listBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPost"]],
paginateBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostConnection"]],
getBlogPostLocale?: [{	by: ValueTypes["BlogPostLocaleUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
listBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostLocale"]],
paginateBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostLocaleConnection"]],
getColorPallete?: [{	by: ValueTypes["ColorPalleteUniqueWhere"],	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null},ValueTypes["ColorPallete"]],
listColorPallete?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ColorPalleteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ColorPallete"]],
paginateColorPallete?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ColorPalleteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ColorPalleteConnection"]],
getContent?: [{	by: ValueTypes["ContentUniqueWhere"],	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
listContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Content"]],
paginateContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentConnection"]],
getContentBlock?: [{	by: ValueTypes["ContentBlockUniqueWhere"],	filter?: ValueTypes["ContentBlockWhere"] | undefined | null},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ValueTypes["ContentReferenceUniqueWhere"],	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null},ValueTypes["ContentReference"]],
listContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentReferenceConnection"]],
getBlogPostList?: [{	by: ValueTypes["BlogPostListUniqueWhere"],	filter?: ValueTypes["BlogPostListWhere"] | undefined | null},ValueTypes["BlogPostList"]],
listBlogPostList?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostList"]],
paginateBlogPostList?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostListConnection"]],
getBlogPostListItem?: [{	by: ValueTypes["BlogPostListItemUniqueWhere"],	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null},ValueTypes["BlogPostListItem"]],
listBlogPostListItem?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostListItem"]],
paginateBlogPostListItem?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostListItemConnection"]],
getLinkList?: [{	by: ValueTypes["LinkListUniqueWhere"],	filter?: ValueTypes["LinkListWhere"] | undefined | null},ValueTypes["LinkList"]],
listLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LinkList"]],
paginateLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkListConnection"]],
getLinkListItem?: [{	by: ValueTypes["LinkListItemUniqueWhere"],	filter?: ValueTypes["LinkListItemWhere"] | undefined | null},ValueTypes["LinkListItem"]],
listLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LinkListItem"]],
paginateLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkListItemConnection"]],
getCover?: [{	by: ValueTypes["CoverUniqueWhere"],	filter?: ValueTypes["CoverWhere"] | undefined | null},ValueTypes["Cover"]],
listCover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CoverOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Cover"]],
paginateCover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CoverOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CoverConnection"]],
getLink?: [{	by: ValueTypes["LinkUniqueWhere"],	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
listLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Link"]],
paginateLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkConnection"]],
getLinkable?: [{	by: ValueTypes["LinkableUniqueWhere"],	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
listLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkableConnection"]],
getMedium?: [{	by: ValueTypes["MediumUniqueWhere"],	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
listMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Medium"]],
paginateMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MediumConnection"]],
getVideo?: [{	by: ValueTypes["VideoUniqueWhere"],	filter?: ValueTypes["VideoWhere"] | undefined | null},ValueTypes["Video"]],
listVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Video"]],
paginateVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VideoConnection"]],
getSeo?: [{	by: ValueTypes["SeoUniqueWhere"],	filter?: ValueTypes["SeoWhere"] | undefined | null},ValueTypes["Seo"]],
listSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Seo"]],
paginateSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SeoConnection"]],
getTranslationDomain?: [{	by: ValueTypes["TranslationDomainUniqueWhere"],	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null},ValueTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationDomainConnection"]],
getTranslationCatalogue?: [{	by: ValueTypes["TranslationCatalogueUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCatalogueConnection"]],
getTranslationKey?: [{	by: ValueTypes["TranslationKeyUniqueWhere"],	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null},ValueTypes["TranslationKey"]],
listTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationKeyConnection"]],
getTranslationValue?: [{	by: ValueTypes["TranslationValueUniqueWhere"],	filter?: ValueTypes["TranslationValueWhere"] | undefined | null},ValueTypes["TranslationValue"]],
listTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationValueConnection"]],
getTranslationCataloguesIdentifier?: [{	by: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"],	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
listTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
paginateTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCataloguesIdentifierConnection"]],
getSocialsAndApps?: [{	by: ValueTypes["SocialsAndAppsUniqueWhere"],	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null},ValueTypes["SocialsAndApps"]],
listSocialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SocialsAndAppsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SocialsAndApps"]],
paginateSocialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SocialsAndAppsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SocialsAndAppsConnection"]],
getProductGrid?: [{	by: ValueTypes["ProductGridUniqueWhere"],	filter?: ValueTypes["ProductGridWhere"] | undefined | null},ValueTypes["ProductGrid"]],
listProductGrid?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGrid"]],
paginateProductGrid?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridConnection"]],
getProductGridItem?: [{	by: ValueTypes["ProductGridItemUniqueWhere"],	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null},ValueTypes["ProductGridItem"]],
listProductGridItem?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridItem"]],
paginateProductGridItem?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridItemConnection"]],
getGallery?: [{	by: ValueTypes["GalleryUniqueWhere"],	filter?: ValueTypes["GalleryWhere"] | undefined | null},ValueTypes["Gallery"]],
listGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Gallery"]],
paginateGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GalleryConnection"]],
getGalleryItem?: [{	by: ValueTypes["GalleryItemUniqueWhere"],	filter?: ValueTypes["GalleryItemWhere"] | undefined | null},ValueTypes["GalleryItem"]],
listGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GalleryItem"]],
paginateGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GalleryItemConnection"]],
getHero?: [{	by: ValueTypes["HeroUniqueWhere"],	filter?: ValueTypes["HeroWhere"] | undefined | null},ValueTypes["Hero"]],
listHero?: [{	filter?: ValueTypes["HeroWhere"] | undefined | null,	orderBy?: Array<ValueTypes["HeroOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Hero"]],
paginateHero?: [{	filter?: ValueTypes["HeroWhere"] | undefined | null,	orderBy?: Array<ValueTypes["HeroOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["HeroConnection"]],
getPage?: [{	by: ValueTypes["PageUniqueWhere"],	filter?: ValueTypes["PageWhere"] | undefined | null},ValueTypes["Page"]],
listPage?: [{	filter?: ValueTypes["PageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Page"]],
paginatePage?: [{	filter?: ValueTypes["PageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageConnection"]],
getPageLocale?: [{	by: ValueTypes["PageLocaleUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
listPageLocale?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PageLocale"]],
paginatePageLocale?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageLocaleConnection"]],
getDeadlineTemplate?: [{	by: ValueTypes["DeadlineTemplateUniqueWhere"],	filter?: ValueTypes["DeadlineTemplateWhere"] | undefined | null},ValueTypes["DeadlineTemplate"]],
listDeadlineTemplate?: [{	filter?: ValueTypes["DeadlineTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeadlineTemplateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeadlineTemplate"]],
paginateDeadlineTemplate?: [{	filter?: ValueTypes["DeadlineTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeadlineTemplateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeadlineTemplateConnection"]],
getEmployeeQueue?: [{	by: ValueTypes["EmployeeQueueUniqueWhere"],	filter?: ValueTypes["EmployeeQueueWhere"] | undefined | null},ValueTypes["EmployeeQueue"]],
listEmployeeQueue?: [{	filter?: ValueTypes["EmployeeQueueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["EmployeeQueueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["EmployeeQueue"]],
paginateEmployeeQueue?: [{	filter?: ValueTypes["EmployeeQueueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["EmployeeQueueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["EmployeeQueueConnection"]],
validateCreateEmployeeQueue?: [{	data: ValueTypes["EmployeeQueueCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateEmployeeQueue?: [{	by: ValueTypes["EmployeeQueueUniqueWhere"],	data: ValueTypes["EmployeeQueueUpdateInput"]},ValueTypes["_ValidationResult"]],
getTimetableDay?: [{	by: ValueTypes["TimetableDayUniqueWhere"],	filter?: ValueTypes["TimetableDayWhere"] | undefined | null},ValueTypes["TimetableDay"]],
listTimetableDay?: [{	filter?: ValueTypes["TimetableDayWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableDay"]],
paginateTimetableDay?: [{	filter?: ValueTypes["TimetableDayWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableDayConnection"]],
getTimetableEmployeeRole?: [{	by: ValueTypes["TimetableEmployeeRoleUniqueWhere"],	filter?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null},ValueTypes["TimetableEmployeeRole"]],
listTimetableEmployeeRole?: [{	filter?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableEmployeeRoleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableEmployeeRole"]],
paginateTimetableEmployeeRole?: [{	filter?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableEmployeeRoleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableEmployeeRoleConnection"]],
getTimetableShift?: [{	by: ValueTypes["TimetableShiftUniqueWhere"],	filter?: ValueTypes["TimetableShiftWhere"] | undefined | null},ValueTypes["TimetableShift"]],
listTimetableShift?: [{	filter?: ValueTypes["TimetableShiftWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableShift"]],
paginateTimetableShift?: [{	filter?: ValueTypes["TimetableShiftWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableShiftConnection"]],
validateUpdateTimetableShift?: [{	by: ValueTypes["TimetableShiftUniqueWhere"],	data: ValueTypes["TimetableShiftUpdateInput"]},ValueTypes["_ValidationResult"]],
getTimetableShiftGroup?: [{	by: ValueTypes["TimetableShiftGroupUniqueWhere"],	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null},ValueTypes["TimetableShiftGroup"]],
listTimetableShiftGroup?: [{	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftGroupOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableShiftGroup"]],
paginateTimetableShiftGroup?: [{	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftGroupOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableShiftGroupConnection"]],
getTimetableShiftPosition?: [{	by: ValueTypes["TimetableShiftPositionUniqueWhere"],	filter?: ValueTypes["TimetableShiftPositionWhere"] | undefined | null},ValueTypes["TimetableShiftPosition"]],
listTimetableShiftPosition?: [{	filter?: ValueTypes["TimetableShiftPositionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftPositionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableShiftPosition"]],
paginateTimetableShiftPosition?: [{	filter?: ValueTypes["TimetableShiftPositionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftPositionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableShiftPositionConnection"]],
getTimetableTemplate?: [{	by: ValueTypes["TimetableTemplateUniqueWhere"],	filter?: ValueTypes["TimetableTemplateWhere"] | undefined | null},ValueTypes["TimetableTemplate"]],
listTimetableTemplate?: [{	filter?: ValueTypes["TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableTemplateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableTemplate"]],
paginateTimetableTemplate?: [{	filter?: ValueTypes["TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableTemplateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableTemplateConnection"]],
getEmployeeQueueItem?: [{	by: ValueTypes["EmployeeQueueItemUniqueWhere"],	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null},ValueTypes["EmployeeQueueItem"]],
listEmployeeQueueItem?: [{	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["EmployeeQueueItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["EmployeeQueueItem"]],
paginateEmployeeQueueItem?: [{	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["EmployeeQueueItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["EmployeeQueueItemConnection"]],
validateCreateEmployeeQueueItem?: [{	data: ValueTypes["EmployeeQueueItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateEmployeeQueueItem?: [{	by: ValueTypes["EmployeeQueueItemUniqueWhere"],	data: ValueTypes["EmployeeQueueItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductBanner?: [{	by: ValueTypes["ProductBannerUniqueWhere"],	filter?: ValueTypes["ProductBannerWhere"] | undefined | null},ValueTypes["ProductBanner"]],
listProductBanner?: [{	filter?: ValueTypes["ProductBannerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBanner"]],
paginateProductBanner?: [{	filter?: ValueTypes["ProductBannerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerConnection"]],
getProductBannerItem?: [{	by: ValueTypes["ProductBannerItemUniqueWhere"],	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null},ValueTypes["ProductBannerItem"]],
listProductBannerItem?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBannerItem"]],
paginateProductBannerItem?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerItemConnection"]],
getProductBannerList?: [{	by: ValueTypes["ProductBannerListUniqueWhere"],	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null},ValueTypes["ProductBannerList"]],
listProductBannerList?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBannerList"]],
paginateProductBannerList?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerListConnection"]],
getShiftsProfile?: [{	by: ValueTypes["ShiftsProfileUniqueWhere"],	filter?: ValueTypes["ShiftsProfileWhere"] | undefined | null},ValueTypes["ShiftsProfile"]],
listShiftsProfile?: [{	filter?: ValueTypes["ShiftsProfileWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ShiftsProfileOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ShiftsProfile"]],
paginateShiftsProfile?: [{	filter?: ValueTypes["ShiftsProfileWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ShiftsProfileOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ShiftsProfileConnection"]],
validateUpdateShiftsProfile?: [{	by: ValueTypes["ShiftsProfileUniqueWhere"],	data: ValueTypes["ShiftsProfileUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductIngredientCategory?: [{	by: ValueTypes["ProductIngredientCategoryUniqueWhere"],	filter?: ValueTypes["ProductIngredientCategoryWhere"] | undefined | null},ValueTypes["ProductIngredientCategory"]],
listProductIngredientCategory?: [{	filter?: ValueTypes["ProductIngredientCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientCategory"]],
paginateProductIngredientCategory?: [{	filter?: ValueTypes["ProductIngredientCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientCategoryConnection"]],
getProductIngredientCategoryLocale?: [{	by: ValueTypes["ProductIngredientCategoryLocaleUniqueWhere"],	filter?: ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientCategoryLocale"]],
listProductIngredientCategoryLocale?: [{	filter?: ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientCategoryLocale"]],
paginateProductIngredientCategoryLocale?: [{	filter?: ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientCategoryLocaleConnection"]],
getDeliveryRoute?: [{	by: ValueTypes["DeliveryRouteUniqueWhere"],	filter?: ValueTypes["DeliveryRouteWhere"] | undefined | null},ValueTypes["DeliveryRoute"]],
listDeliveryRoute?: [{	filter?: ValueTypes["DeliveryRouteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRouteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRoute"]],
paginateDeliveryRoute?: [{	filter?: ValueTypes["DeliveryRouteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRouteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRouteConnection"]],
getDeliveryWaypoint?: [{	by: ValueTypes["DeliveryWaypointUniqueWhere"],	filter?: ValueTypes["DeliveryWaypointWhere"] | undefined | null},ValueTypes["DeliveryWaypoint"]],
listDeliveryWaypoint?: [{	filter?: ValueTypes["DeliveryWaypointWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryWaypointOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryWaypoint"]],
paginateDeliveryWaypoint?: [{	filter?: ValueTypes["DeliveryWaypointWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryWaypointOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryWaypointConnection"]],
getAllergen?: [{	by: ValueTypes["AllergenUniqueWhere"],	filter?: ValueTypes["AllergenWhere"] | undefined | null},ValueTypes["Allergen"]],
listAllergen?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Allergen"]],
paginateAllergen?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenConnection"]],
getAllergenLocale?: [{	by: ValueTypes["AllergenLocaleUniqueWhere"],	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null},ValueTypes["AllergenLocale"]],
listAllergenLocale?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AllergenLocale"]],
paginateAllergenLocale?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenLocaleConnection"]],
getWeekHours?: [{	by: ValueTypes["WeekHoursUniqueWhere"],	filter?: ValueTypes["WeekHoursWhere"] | undefined | null},ValueTypes["WeekHours"]],
listWeekHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WeekHoursOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["WeekHours"]],
paginateWeekHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WeekHoursOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["WeekHoursConnection"]],
getPreparedIngredientQuantity?: [{	by: ValueTypes["PreparedIngredientQuantityUniqueWhere"],	filter?: ValueTypes["PreparedIngredientQuantityWhere"] | undefined | null},ValueTypes["PreparedIngredientQuantity"]],
listPreparedIngredientQuantity?: [{	filter?: ValueTypes["PreparedIngredientQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PreparedIngredientQuantityOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PreparedIngredientQuantity"]],
paginatePreparedIngredientQuantity?: [{	filter?: ValueTypes["PreparedIngredientQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PreparedIngredientQuantityOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PreparedIngredientQuantityConnection"]],
validateCreatePreparedIngredientQuantity?: [{	data: ValueTypes["PreparedIngredientQuantityCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdatePreparedIngredientQuantity?: [{	by: ValueTypes["PreparedIngredientQuantityUniqueWhere"],	data: ValueTypes["PreparedIngredientQuantityUpdateInput"]},ValueTypes["_ValidationResult"]],
getPreparedRecipeQuantity?: [{	by: ValueTypes["PreparedRecipeQuantityUniqueWhere"],	filter?: ValueTypes["PreparedRecipeQuantityWhere"] | undefined | null},ValueTypes["PreparedRecipeQuantity"]],
listPreparedRecipeQuantity?: [{	filter?: ValueTypes["PreparedRecipeQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PreparedRecipeQuantityOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PreparedRecipeQuantity"]],
paginatePreparedRecipeQuantity?: [{	filter?: ValueTypes["PreparedRecipeQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PreparedRecipeQuantityOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PreparedRecipeQuantityConnection"]],
validateCreatePreparedRecipeQuantity?: [{	data: ValueTypes["PreparedRecipeQuantityCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdatePreparedRecipeQuantity?: [{	by: ValueTypes["PreparedRecipeQuantityUniqueWhere"],	data: ValueTypes["PreparedRecipeQuantityUpdateInput"]},ValueTypes["_ValidationResult"]],
getPreparedPackingQuantity?: [{	by: ValueTypes["PreparedPackingQuantityUniqueWhere"],	filter?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null},ValueTypes["PreparedPackingQuantity"]],
listPreparedPackingQuantity?: [{	filter?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PreparedPackingQuantityOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PreparedPackingQuantity"]],
paginatePreparedPackingQuantity?: [{	filter?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PreparedPackingQuantityOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PreparedPackingQuantityConnection"]],
validateCreatePreparedPackingQuantity?: [{	data: ValueTypes["PreparedPackingQuantityCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdatePreparedPackingQuantity?: [{	by: ValueTypes["PreparedPackingQuantityUniqueWhere"],	data: ValueTypes["PreparedPackingQuantityUpdateInput"]},ValueTypes["_ValidationResult"]],
getDeliveryZone?: [{	by: ValueTypes["DeliveryZoneUniqueWhere"],	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null},ValueTypes["DeliveryZone"]],
listDeliveryZone?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryZone"]],
paginateDeliveryZone?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryZoneConnection"]],
getDeliveryTimelinePreset?: [{	by: ValueTypes["DeliveryTimelinePresetUniqueWhere"],	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null},ValueTypes["DeliveryTimelinePreset"]],
listDeliveryTimelinePreset?: [{	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryTimelinePresetOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryTimelinePreset"]],
paginateDeliveryTimelinePreset?: [{	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryTimelinePresetOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryTimelinePresetConnection"]],
getProductPackingLocale?: [{	by: ValueTypes["ProductPackingLocaleUniqueWhere"],	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null},ValueTypes["ProductPackingLocale"]],
listProductPackingLocale?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPackingLocale"]],
paginateProductPackingLocale?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingLocaleConnection"]],
getProductLocale?: [{	by: ValueTypes["ProductLocaleUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
listProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductLocale"]],
paginateProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductLocaleConnection"]],
getProductRecipeLocale?: [{	by: ValueTypes["ProductRecipeLocaleUniqueWhere"],	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null},ValueTypes["ProductRecipeLocale"]],
listProductRecipeLocale?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipeLocale"]],
paginateProductRecipeLocale?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeLocaleConnection"]],
getSuppliesPurchase?: [{	by: ValueTypes["SuppliesPurchaseUniqueWhere"],	filter?: ValueTypes["SuppliesPurchaseWhere"] | undefined | null},ValueTypes["SuppliesPurchase"]],
listSuppliesPurchase?: [{	filter?: ValueTypes["SuppliesPurchaseWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SuppliesPurchaseOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SuppliesPurchase"]],
paginateSuppliesPurchase?: [{	filter?: ValueTypes["SuppliesPurchaseWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SuppliesPurchaseOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SuppliesPurchaseConnection"]],
validateCreateSuppliesPurchase?: [{	data: ValueTypes["SuppliesPurchaseCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateSuppliesPurchase?: [{	by: ValueTypes["SuppliesPurchaseUniqueWhere"],	data: ValueTypes["SuppliesPurchaseUpdateInput"]},ValueTypes["_ValidationResult"]],
getLogRecord?: [{	by: ValueTypes["LogRecordUniqueWhere"],	filter?: ValueTypes["LogRecordWhere"] | undefined | null},ValueTypes["LogRecord"]],
listLogRecord?: [{	filter?: ValueTypes["LogRecordWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LogRecordOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LogRecord"]],
paginateLogRecord?: [{	filter?: ValueTypes["LogRecordWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LogRecordOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LogRecordConnection"]],
getSuppliesPurchaseItem?: [{	by: ValueTypes["SuppliesPurchaseItemUniqueWhere"],	filter?: ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null},ValueTypes["SuppliesPurchaseItem"]],
listSuppliesPurchaseItem?: [{	filter?: ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SuppliesPurchaseItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SuppliesPurchaseItem"]],
paginateSuppliesPurchaseItem?: [{	filter?: ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SuppliesPurchaseItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SuppliesPurchaseItemConnection"]],
validateCreateSuppliesPurchaseItem?: [{	data: ValueTypes["SuppliesPurchaseItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateSuppliesPurchaseItem?: [{	by: ValueTypes["SuppliesPurchaseItemUniqueWhere"],	data: ValueTypes["SuppliesPurchaseItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getVirtualProduct?: [{	by: ValueTypes["VirtualProductUniqueWhere"],	filter?: ValueTypes["VirtualProductWhere"] | undefined | null},ValueTypes["VirtualProduct"]],
listVirtualProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VirtualProduct"]],
paginateVirtualProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VirtualProductConnection"]],
getProductMetadata?: [{	by: ValueTypes["ProductMetadataUniqueWhere"],	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null},ValueTypes["ProductMetadata"]],
listProductMetadata?: [{	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductMetadata"]],
paginateProductMetadata?: [{	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductMetadataConnection"]],
getProductGridCategory?: [{	by: ValueTypes["ProductGridCategoryUniqueWhere"],	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null},ValueTypes["ProductGridCategory"]],
listProductGridCategory?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridCategory"]],
paginateProductGridCategory?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridCategoryConnection"]],
getUnassignedShiftInfo?: [{	by: ValueTypes["UnassignedShiftInfoUniqueWhere"],	filter?: ValueTypes["UnassignedShiftInfoWhere"] | undefined | null},ValueTypes["UnassignedShiftInfo"]],
listUnassignedShiftInfo?: [{	filter?: ValueTypes["UnassignedShiftInfoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UnassignedShiftInfoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["UnassignedShiftInfo"]],
paginateUnassignedShiftInfo?: [{	filter?: ValueTypes["UnassignedShiftInfoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UnassignedShiftInfoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["UnassignedShiftInfoConnection"]],
validateCreateUnassignedShiftInfo?: [{	data: ValueTypes["UnassignedShiftInfoCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateUnassignedShiftInfo?: [{	by: ValueTypes["UnassignedShiftInfoUniqueWhere"],	data: ValueTypes["UnassignedShiftInfoUpdateInput"]},ValueTypes["_ValidationResult"]],
getFreshingContainer?: [{	by: ValueTypes["FreshingContainerUniqueWhere"],	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null},ValueTypes["FreshingContainer"]],
listFreshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FreshingContainer"]],
paginateFreshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FreshingContainerConnection"]],
getTextItem?: [{	by: ValueTypes["TextItemUniqueWhere"],	filter?: ValueTypes["TextItemWhere"] | undefined | null},ValueTypes["TextItem"]],
listTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TextItem"]],
paginateTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TextItemConnection"]],
getTextList?: [{	by: ValueTypes["TextListUniqueWhere"],	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
listTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TextList"]],
paginateTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TextListConnection"]],
getDeliveryRegions?: [{	by: ValueTypes["DeliveryRegionsUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null},ValueTypes["DeliveryRegions"]],
listDeliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRegions"]],
paginateDeliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRegionsConnection"]],
getDeliveryRegionsItem?: [{	by: ValueTypes["DeliveryRegionsItemUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["DeliveryRegionsItem"]],
listDeliveryRegionsItem?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRegionsItem"]],
paginateDeliveryRegionsItem?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRegionsItemConnection"]],
getImageList?: [{	by: ValueTypes["ImageListUniqueWhere"],	filter?: ValueTypes["ImageListWhere"] | undefined | null},ValueTypes["ImageList"]],
listImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ImageList"]],
paginateImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageListConnection"]],
getImageListItem?: [{	by: ValueTypes["ImageListItemUniqueWhere"],	filter?: ValueTypes["ImageListItemWhere"] | undefined | null},ValueTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageListItemConnection"]],
getTimetablesEmployeePrivileges?: [{	by: ValueTypes["TimetablesEmployeePrivilegesUniqueWhere"],	filter?: ValueTypes["TimetablesEmployeePrivilegesWhere"] | undefined | null},ValueTypes["TimetablesEmployeePrivileges"]],
listTimetablesEmployeePrivileges?: [{	filter?: ValueTypes["TimetablesEmployeePrivilegesWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetablesEmployeePrivilegesOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetablesEmployeePrivileges"]],
paginateTimetablesEmployeePrivileges?: [{	filter?: ValueTypes["TimetablesEmployeePrivilegesWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetablesEmployeePrivilegesOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetablesEmployeePrivilegesConnection"]],
getTimetableDayNote?: [{	by: ValueTypes["TimetableDayNoteUniqueWhere"],	filter?: ValueTypes["TimetableDayNoteWhere"] | undefined | null},ValueTypes["TimetableDayNote"]],
listTimetableDayNote?: [{	filter?: ValueTypes["TimetableDayNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableDayNote"]],
paginateTimetableDayNote?: [{	filter?: ValueTypes["TimetableDayNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableDayNoteConnection"]],
getDeliveryMethodLocale?: [{	by: ValueTypes["DeliveryMethodLocaleUniqueWhere"],	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null},ValueTypes["DeliveryMethodLocale"]],
listDeliveryMethodLocale?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethodLocale"]],
paginateDeliveryMethodLocale?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodLocaleConnection"]],
getIngredientSupplier?: [{	by: ValueTypes["IngredientSupplierUniqueWhere"],	filter?: ValueTypes["IngredientSupplierWhere"] | undefined | null},ValueTypes["IngredientSupplier"]],
listIngredientSupplier?: [{	filter?: ValueTypes["IngredientSupplierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IngredientSupplier"]],
paginateIngredientSupplier?: [{	filter?: ValueTypes["IngredientSupplierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IngredientSupplierConnection"]],
getIngredientSupplierItem?: [{	by: ValueTypes["IngredientSupplierItemUniqueWhere"],	filter?: ValueTypes["IngredientSupplierItemWhere"] | undefined | null},ValueTypes["IngredientSupplierItem"]],
listIngredientSupplierItem?: [{	filter?: ValueTypes["IngredientSupplierItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IngredientSupplierItem"]],
paginateIngredientSupplierItem?: [{	filter?: ValueTypes["IngredientSupplierItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IngredientSupplierItemConnection"]],
getIngredientSupplierList?: [{	by: ValueTypes["IngredientSupplierListUniqueWhere"],	filter?: ValueTypes["IngredientSupplierListWhere"] | undefined | null},ValueTypes["IngredientSupplierList"]],
listIngredientSupplierList?: [{	filter?: ValueTypes["IngredientSupplierListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IngredientSupplierList"]],
paginateIngredientSupplierList?: [{	filter?: ValueTypes["IngredientSupplierListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IngredientSupplierListConnection"]],
getIngredientSupplierLocale?: [{	by: ValueTypes["IngredientSupplierLocaleUniqueWhere"],	filter?: ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null},ValueTypes["IngredientSupplierLocale"]],
listIngredientSupplierLocale?: [{	filter?: ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IngredientSupplierLocale"]],
paginateIngredientSupplierLocale?: [{	filter?: ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IngredientSupplierLocaleConnection"]],
getRedirect?: [{	by: ValueTypes["RedirectUniqueWhere"],	filter?: ValueTypes["RedirectWhere"] | undefined | null},ValueTypes["Redirect"]],
listRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Redirect"]],
paginateRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["RedirectConnection"]],
getVenue?: [{	by: ValueTypes["VenueUniqueWhere"],	filter?: ValueTypes["VenueWhere"] | undefined | null},ValueTypes["Venue"]],
listVenue?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Venue"]],
paginateVenue?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VenueConnection"]],
getWorkspace?: [{	by: ValueTypes["WorkspaceUniqueWhere"],	filter?: ValueTypes["WorkspaceWhere"] | undefined | null},ValueTypes["Workspace"]],
listWorkspace?: [{	filter?: ValueTypes["WorkspaceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WorkspaceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Workspace"]],
paginateWorkspace?: [{	filter?: ValueTypes["WorkspaceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WorkspaceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["WorkspaceConnection"]],
getVenueEmployeeRole?: [{	by: ValueTypes["VenueEmployeeRoleUniqueWhere"],	filter?: ValueTypes["VenueEmployeeRoleWhere"] | undefined | null},ValueTypes["VenueEmployeeRole"]],
listVenueEmployeeRole?: [{	filter?: ValueTypes["VenueEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueEmployeeRoleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VenueEmployeeRole"]],
paginateVenueEmployeeRole?: [{	filter?: ValueTypes["VenueEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueEmployeeRoleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VenueEmployeeRoleConnection"]],
getIdentificationAttempt?: [{	by: ValueTypes["IdentificationAttemptUniqueWhere"],	filter?: ValueTypes["IdentificationAttemptWhere"] | undefined | null},ValueTypes["IdentificationAttempt"]],
listIdentificationAttempt?: [{	filter?: ValueTypes["IdentificationAttemptWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationAttemptOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IdentificationAttempt"]],
paginateIdentificationAttempt?: [{	filter?: ValueTypes["IdentificationAttemptWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationAttemptOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IdentificationAttemptConnection"]],
getIdentificationCode?: [{	by: ValueTypes["IdentificationCodeUniqueWhere"],	filter?: ValueTypes["IdentificationCodeWhere"] | undefined | null},ValueTypes["IdentificationCode"]],
listIdentificationCode?: [{	filter?: ValueTypes["IdentificationCodeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IdentificationCode"]],
paginateIdentificationCode?: [{	filter?: ValueTypes["IdentificationCodeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IdentificationCodeConnection"]],
getIdentificationCodeLocale?: [{	by: ValueTypes["IdentificationCodeLocaleUniqueWhere"],	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null},ValueTypes["IdentificationCodeLocale"]],
listIdentificationCodeLocale?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IdentificationCodeLocale"]],
paginateIdentificationCodeLocale?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IdentificationCodeLocaleConnection"]],
getDeviceIdentifier?: [{	by: ValueTypes["DeviceIdentifierUniqueWhere"],	filter?: ValueTypes["DeviceIdentifierWhere"] | undefined | null},ValueTypes["DeviceIdentifier"]],
listDeviceIdentifier?: [{	filter?: ValueTypes["DeviceIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeviceIdentifierOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeviceIdentifier"]],
paginateDeviceIdentifier?: [{	filter?: ValueTypes["DeviceIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeviceIdentifierOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeviceIdentifierConnection"]],
validateCreateDeviceIdentifier?: [{	data: ValueTypes["DeviceIdentifierCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateDeviceIdentifier?: [{	by: ValueTypes["DeviceIdentifierUniqueWhere"],	data: ValueTypes["DeviceIdentifierUpdateInput"]},ValueTypes["_ValidationResult"]],
getDeviceIdentifierMetadata?: [{	by: ValueTypes["DeviceIdentifierMetadataUniqueWhere"],	filter?: ValueTypes["DeviceIdentifierMetadataWhere"] | undefined | null},ValueTypes["DeviceIdentifierMetadata"]],
listDeviceIdentifierMetadata?: [{	filter?: ValueTypes["DeviceIdentifierMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeviceIdentifierMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeviceIdentifierMetadata"]],
paginateDeviceIdentifierMetadata?: [{	filter?: ValueTypes["DeviceIdentifierMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeviceIdentifierMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeviceIdentifierMetadataConnection"]],
getFulfillmentNote?: [{	by: ValueTypes["FulfillmentNoteUniqueWhere"],	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null},ValueTypes["FulfillmentNote"]],
listFulfillmentNote?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FulfillmentNote"]],
paginateFulfillmentNote?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FulfillmentNoteConnection"]],
getCustomerMetadata?: [{	by: ValueTypes["CustomerMetadataUniqueWhere"],	filter?: ValueTypes["CustomerMetadataWhere"] | undefined | null},ValueTypes["CustomerMetadata"]],
listCustomerMetadata?: [{	filter?: ValueTypes["CustomerMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerMetadata"]],
paginateCustomerMetadata?: [{	filter?: ValueTypes["CustomerMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerMetadataConnection"]],
getSubscriptionBox?: [{	by: ValueTypes["SubscriptionBoxUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxWhere"] | undefined | null},ValueTypes["SubscriptionBox"]],
listSubscriptionBox?: [{	filter?: ValueTypes["SubscriptionBoxWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SubscriptionBox"]],
paginateSubscriptionBox?: [{	filter?: ValueTypes["SubscriptionBoxWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SubscriptionBoxConnection"]],
getSubscriptionBoxLocale?: [{	by: ValueTypes["SubscriptionBoxLocaleUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
listSubscriptionBoxLocale?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
paginateSubscriptionBoxLocale?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SubscriptionBoxLocaleConnection"]],
getTimetableDayMetadata?: [{	by: ValueTypes["TimetableDayMetadataUniqueWhere"],	filter?: ValueTypes["TimetableDayMetadataWhere"] | undefined | null},ValueTypes["TimetableDayMetadata"]],
listTimetableDayMetadata?: [{	filter?: ValueTypes["TimetableDayMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableDayMetadata"]],
paginateTimetableDayMetadata?: [{	filter?: ValueTypes["TimetableDayMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableDayMetadataConnection"]],
getGridTileItem?: [{	by: ValueTypes["GridTileItemUniqueWhere"],	filter?: ValueTypes["GridTileItemWhere"] | undefined | null},ValueTypes["GridTileItem"]],
listGridTileItem?: [{	filter?: ValueTypes["GridTileItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTileItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GridTileItem"]],
paginateGridTileItem?: [{	filter?: ValueTypes["GridTileItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTileItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GridTileItemConnection"]],
getGridTiles?: [{	by: ValueTypes["GridTilesUniqueWhere"],	filter?: ValueTypes["GridTilesWhere"] | undefined | null},ValueTypes["GridTiles"]],
listGridTiles?: [{	filter?: ValueTypes["GridTilesWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTilesOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GridTiles"]],
paginateGridTiles?: [{	filter?: ValueTypes["GridTilesWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTilesOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GridTilesConnection"]],
getPosition?: [{	by: ValueTypes["PositionUniqueWhere"],	filter?: ValueTypes["PositionWhere"] | undefined | null},ValueTypes["Position"]],
listPosition?: [{	filter?: ValueTypes["PositionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PositionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Position"]],
paginatePosition?: [{	filter?: ValueTypes["PositionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PositionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PositionConnection"]],
getStatsWidget?: [{	by: ValueTypes["StatsWidgetUniqueWhere"],	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null},ValueTypes["StatsWidget"]],
listStatsWidget?: [{	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["StatsWidget"]],
paginateStatsWidget?: [{	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StatsWidgetConnection"]],
getStatsWidgetsLayout?: [{	by: ValueTypes["StatsWidgetsLayoutUniqueWhere"],	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null},ValueTypes["StatsWidgetsLayout"]],
listStatsWidgetsLayout?: [{	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetsLayoutOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["StatsWidgetsLayout"]],
paginateStatsWidgetsLayout?: [{	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetsLayoutOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StatsWidgetsLayoutConnection"]],
getStatsPage?: [{	by: ValueTypes["StatsPageUniqueWhere"],	filter?: ValueTypes["StatsPageWhere"] | undefined | null},ValueTypes["StatsPage"]],
listStatsPage?: [{	filter?: ValueTypes["StatsPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["StatsPage"]],
paginateStatsPage?: [{	filter?: ValueTypes["StatsPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StatsPageConnection"]],
getUnassignReason?: [{	by: ValueTypes["UnassignReasonUniqueWhere"],	filter?: ValueTypes["UnassignReasonWhere"] | undefined | null},ValueTypes["UnassignReason"]],
listUnassignReason?: [{	filter?: ValueTypes["UnassignReasonWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UnassignReasonOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["UnassignReason"]],
paginateUnassignReason?: [{	filter?: ValueTypes["UnassignReasonWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UnassignReasonOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["UnassignReasonConnection"]],
getOrderPaymentStatsByDay?: [{	by: ValueTypes["OrderPaymentStatsByDayUniqueWhere"],	filter?: ValueTypes["OrderPaymentStatsByDayWhere"] | undefined | null},ValueTypes["OrderPaymentStatsByDay"]],
listOrderPaymentStatsByDay?: [{	filter?: ValueTypes["OrderPaymentStatsByDayWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentStatsByDayOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPaymentStatsByDay"]],
paginateOrderPaymentStatsByDay?: [{	filter?: ValueTypes["OrderPaymentStatsByDayWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentStatsByDayOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPaymentStatsByDayConnection"]],
	transaction?:ValueTypes["QueryTransaction"],
	_info?:ValueTypes["Info"],
	schema?:ValueTypes["_Schema"],
		__typename?: boolean | `@${string}`
}>;
	["Address"]: AliasType<{
	_meta?:ValueTypes["AddressMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	addressLine2?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	companyName?:boolean | `@${string}`,
	companyIdentifier?:boolean | `@${string}`,
	vatIdentifier?:boolean | `@${string}`,
	externalIdentifier?:boolean | `@${string}`,
	hidden?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
	internalNote?:boolean | `@${string}`,
country?: [{	filter?: ValueTypes["CountryWhere"] | undefined | null},ValueTypes["Country"]],
	deletedAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
	googlePlaceId?:boolean | `@${string}`,
	raw?:boolean | `@${string}`,
	formatted?:boolean | `@${string}`,
	geocodeGeneratedAt?:boolean | `@${string}`,
	geocodeResponse?:boolean | `@${string}`,
deliveryZone?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null},ValueTypes["DeliveryZone"]],
	geocodeValid?:boolean | `@${string}`,
	fullName?:boolean | `@${string}`,
	fakturoidSubjectId?:boolean | `@${string}`,
	addressLine1?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	postalCode?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
billingAddressOfOrders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
deliveryAddressOfOrderDeliveries?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderDelivery"]],
defaultDeliveryAddressOfCustomer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
billingAddressOfOrdersBySeq?: [{	by: ValueTypes["AddressBillingAddressOfOrdersBySeqUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByDoubleshotLegacyId?: [{	by: ValueTypes["AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByDoubleShotLegacyNumber?: [{	by: ValueTypes["AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByDelivery?: [{	by: ValueTypes["AddressBillingAddressOfOrdersByDeliveryUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByItems?: [{	by: ValueTypes["AddressBillingAddressOfOrdersByItemsUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByPayments?: [{	by: ValueTypes["AddressBillingAddressOfOrdersByPaymentsUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
deliveryAddressOfOrderDeliveriesByTrackingCode?: [{	by: ValueTypes["AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere"],	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
deliveryAddressOfOrderDeliveriesByOrder?: [{	by: ValueTypes["AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere"],	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
deliveryAddressOfOrderDeliveriesByDeliveryWaypoint?: [{	by: ValueTypes["AddressDeliveryAddressOfOrderDeliveriesByDeliveryWaypointUniqueWhere"],	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
paginateBillingAddressOfOrders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
paginateDeliveryAddressOfOrderDeliveries?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderDeliveryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["AddressMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	firstName?:ValueTypes["FieldMeta"],
	lastName?:ValueTypes["FieldMeta"],
	addressLine2?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	phone?:ValueTypes["FieldMeta"],
	companyName?:ValueTypes["FieldMeta"],
	companyIdentifier?:ValueTypes["FieldMeta"],
	vatIdentifier?:ValueTypes["FieldMeta"],
	externalIdentifier?:ValueTypes["FieldMeta"],
	hidden?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	country?:ValueTypes["FieldMeta"],
	deletedAt?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	googlePlaceId?:ValueTypes["FieldMeta"],
	raw?:ValueTypes["FieldMeta"],
	formatted?:ValueTypes["FieldMeta"],
	geocodeGeneratedAt?:ValueTypes["FieldMeta"],
	geocodeResponse?:ValueTypes["FieldMeta"],
	deliveryZone?:ValueTypes["FieldMeta"],
	geocodeValid?:ValueTypes["FieldMeta"],
	fullName?:ValueTypes["FieldMeta"],
	fakturoidSubjectId?:ValueTypes["FieldMeta"],
	addressLine1?:ValueTypes["FieldMeta"],
	city?:ValueTypes["FieldMeta"],
	postalCode?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	billingAddressOfOrders?:ValueTypes["FieldMeta"],
	deliveryAddressOfOrderDeliveries?:ValueTypes["FieldMeta"],
	defaultDeliveryAddressOfCustomer?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FieldMeta"]: AliasType<{
	readable?:boolean | `@${string}`,
	updatable?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UUID"]:string;
	["Customer"]: AliasType<{
	_meta?:ValueTypes["CustomerMeta"],
	id?:boolean | `@${string}`,
addresses?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Address"]],
defaultBillingAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
tags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
orders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
photo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	phone?:boolean | `@${string}`,
name?: [{	filter?: ValueTypes["CustomerNameWhere"] | undefined | null},ValueTypes["CustomerName"]],
	internalNote?:boolean | `@${string}`,
	welcomeMessage?:boolean | `@${string}`,
	familiarity?:boolean | `@${string}`,
channels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
	disabledAt?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
defaultDeliveryAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
	prefersPackingsWithoutCardboard?:boolean | `@${string}`,
	doubleshotLegacyId?:boolean | `@${string}`,
meta?: [{	filter?: ValueTypes["CustomerMetadataWhere"] | undefined | null},ValueTypes["CustomerMetadata"]],
	doubleshotLastUpdatedAt?:boolean | `@${string}`,
addressesByBillingAddressOfOrders?: [{	by: ValueTypes["CustomerAddressesByBillingAddressOfOrdersUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByDeliveryAddressOfOrderDeliveries?: [{	by: ValueTypes["CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByDefaultDeliveryAddressOfCustomer?: [{	by: ValueTypes["CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
ordersBySeq?: [{	by: ValueTypes["CustomerOrdersBySeqUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDoubleshotLegacyId?: [{	by: ValueTypes["CustomerOrdersByDoubleshotLegacyIdUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDoubleShotLegacyNumber?: [{	by: ValueTypes["CustomerOrdersByDoubleShotLegacyNumberUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDelivery?: [{	by: ValueTypes["CustomerOrdersByDeliveryUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByItems?: [{	by: ValueTypes["CustomerOrdersByItemsUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByPayments?: [{	by: ValueTypes["CustomerOrdersByPaymentsUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
paginateAddresses?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AddressConnection"]],
paginateTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginateOrders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
paginateChannels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["CustomerMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	addresses?:ValueTypes["FieldMeta"],
	defaultBillingAddress?:ValueTypes["FieldMeta"],
	tags?:ValueTypes["FieldMeta"],
	orders?:ValueTypes["FieldMeta"],
	photo?:ValueTypes["FieldMeta"],
	phone?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	welcomeMessage?:ValueTypes["FieldMeta"],
	familiarity?:ValueTypes["FieldMeta"],
	channels?:ValueTypes["FieldMeta"],
	disabledAt?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	defaultDeliveryAddress?:ValueTypes["FieldMeta"],
	prefersPackingsWithoutCardboard?:ValueTypes["FieldMeta"],
	doubleshotLegacyId?:ValueTypes["FieldMeta"],
	meta?:ValueTypes["FieldMeta"],
	doubleshotLastUpdatedAt?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AddressWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	firstName?: ValueTypes["StringCondition"] | undefined | null,
	lastName?: ValueTypes["StringCondition"] | undefined | null,
	addressLine2?: ValueTypes["StringCondition"] | undefined | null,
	email?: ValueTypes["StringCondition"] | undefined | null,
	phone?: ValueTypes["StringCondition"] | undefined | null,
	companyName?: ValueTypes["StringCondition"] | undefined | null,
	companyIdentifier?: ValueTypes["StringCondition"] | undefined | null,
	vatIdentifier?: ValueTypes["StringCondition"] | undefined | null,
	externalIdentifier?: ValueTypes["StringCondition"] | undefined | null,
	hidden?: ValueTypes["BooleanCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	country?: ValueTypes["CountryWhere"] | undefined | null,
	deletedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	googlePlaceId?: ValueTypes["StringCondition"] | undefined | null,
	raw?: ValueTypes["StringCondition"] | undefined | null,
	formatted?: ValueTypes["StringCondition"] | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	geocodeResponse?: ValueTypes["StringCondition"] | undefined | null,
	deliveryZone?: ValueTypes["DeliveryZoneWhere"] | undefined | null,
	geocodeValid?: ValueTypes["BooleanCondition"] | undefined | null,
	fullName?: ValueTypes["StringCondition"] | undefined | null,
	fakturoidSubjectId?: ValueTypes["StringCondition"] | undefined | null,
	addressLine1?: ValueTypes["StringCondition"] | undefined | null,
	city?: ValueTypes["StringCondition"] | undefined | null,
	postalCode?: ValueTypes["StringCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	billingAddressOfOrders?: ValueTypes["OrderWhere"] | undefined | null,
	deliveryAddressOfOrderDeliveries?: ValueTypes["OrderDeliveryWhere"] | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["CustomerWhere"] | undefined | null,
	and?: Array<ValueTypes["AddressWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["AddressWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["AddressWhere"] | undefined | null
};
	["UUIDCondition"]: {
	and?: Array<ValueTypes["UUIDCondition"]> | undefined | null,
	or?: Array<ValueTypes["UUIDCondition"]> | undefined | null,
	not?: ValueTypes["UUIDCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["UUID"] | undefined | null,
	notEq?: ValueTypes["UUID"] | undefined | null,
	in?: Array<ValueTypes["UUID"]> | undefined | null,
	notIn?: Array<ValueTypes["UUID"]> | undefined | null,
	lt?: ValueTypes["UUID"] | undefined | null,
	lte?: ValueTypes["UUID"] | undefined | null,
	gt?: ValueTypes["UUID"] | undefined | null,
	gte?: ValueTypes["UUID"] | undefined | null
};
	["StringCondition"]: {
	and?: Array<ValueTypes["StringCondition"]> | undefined | null,
	or?: Array<ValueTypes["StringCondition"]> | undefined | null,
	not?: ValueTypes["StringCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: string | undefined | null,
	notEq?: string | undefined | null,
	in?: Array<string> | undefined | null,
	notIn?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	contains?: string | undefined | null,
	startsWith?: string | undefined | null,
	endsWith?: string | undefined | null,
	containsCI?: string | undefined | null,
	startsWithCI?: string | undefined | null,
	endsWithCI?: string | undefined | null
};
	["BooleanCondition"]: {
	and?: Array<ValueTypes["BooleanCondition"]> | undefined | null,
	or?: Array<ValueTypes["BooleanCondition"]> | undefined | null,
	not?: ValueTypes["BooleanCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: boolean | undefined | null,
	notEq?: boolean | undefined | null,
	in?: Array<boolean> | undefined | null,
	notIn?: Array<boolean> | undefined | null,
	lt?: boolean | undefined | null,
	lte?: boolean | undefined | null,
	gt?: boolean | undefined | null,
	gte?: boolean | undefined | null
};
	["CustomerWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	addresses?: ValueTypes["AddressWhere"] | undefined | null,
	defaultBillingAddress?: ValueTypes["AddressWhere"] | undefined | null,
	tags?: ValueTypes["TagWhere"] | undefined | null,
	orders?: ValueTypes["OrderWhere"] | undefined | null,
	photo?: ValueTypes["ImageWhere"] | undefined | null,
	phone?: ValueTypes["StringCondition"] | undefined | null,
	name?: ValueTypes["CustomerNameWhere"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	welcomeMessage?: ValueTypes["StringCondition"] | undefined | null,
	familiarity?: ValueTypes["CustomerFamiliarityCondition"] | undefined | null,
	channels?: ValueTypes["ChannelWhere"] | undefined | null,
	disabledAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	email?: ValueTypes["StringCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	defaultDeliveryAddress?: ValueTypes["AddressWhere"] | undefined | null,
	prefersPackingsWithoutCardboard?: ValueTypes["BooleanCondition"] | undefined | null,
	doubleshotLegacyId?: ValueTypes["StringCondition"] | undefined | null,
	meta?: ValueTypes["CustomerMetadataWhere"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	and?: Array<ValueTypes["CustomerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CustomerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CustomerWhere"] | undefined | null
};
	["TagWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	customers?: ValueTypes["CustomerWhere"] | undefined | null,
	products?: ValueTypes["ProductWhere"] | undefined | null,
	categories?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	addTagsOnRegistration?: ValueTypes["ChannelWhere"] | undefined | null,
	deliveryZones?: ValueTypes["DeliveryZoneWhere"] | undefined | null,
	publicInChannels?: ValueTypes["ChannelWhere"] | undefined | null,
	fulfillmentNotes?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,
	and?: Array<ValueTypes["TagWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TagWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TagWhere"] | undefined | null
};
	["ProductWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	priceCents?: ValueTypes["IntCondition"] | undefined | null,
	notDiscountable?: ValueTypes["BooleanCondition"] | undefined | null,
	packing?: ValueTypes["ProductPackingWhere"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeWhere"] | undefined | null,
	tags?: ValueTypes["TagWhere"] | undefined | null,
	categories?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	stocks?: ValueTypes["ProductStockWhere"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	availability?: ValueTypes["ProductAvailabilityCondition"] | undefined | null,
	order?: ValueTypes["FloatCondition"] | undefined | null,
	orderItems?: ValueTypes["OrderItemWhere"] | undefined | null,
	maximumQuantityPerOrder?: ValueTypes["IntCondition"] | undefined | null,
	deliveryZones?: ValueTypes["DeliveryZoneWhere"] | undefined | null,
	locales?: ValueTypes["ProductLocaleWhere"] | undefined | null,
	archivedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	virtualProduct?: ValueTypes["VirtualProductWhere"] | undefined | null,
	meta?: ValueTypes["ProductMetadataWhere"] | undefined | null,
	page?: ValueTypes["PageWhere"] | undefined | null,
	fulfillmentNotes?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,
	hasSubscription?: ValueTypes["BooleanCondition"] | undefined | null,
	pointOfSaleTileColor?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductWhere"] | undefined | null
};
	["IntCondition"]: {
	and?: Array<ValueTypes["IntCondition"]> | undefined | null,
	or?: Array<ValueTypes["IntCondition"]> | undefined | null,
	not?: ValueTypes["IntCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: number | undefined | null,
	notEq?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null
};
	["ProductPackingWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	volumeMl?: ValueTypes["IntCondition"] | undefined | null,
	icon?: ValueTypes["ImageWhere"] | undefined | null,
	shortName?: ValueTypes["StringCondition"] | undefined | null,
	locales?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,
	preparedPackingQuantities?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null,
	isPackedInCardboard?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductPackingWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductPackingWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductPackingWhere"] | undefined | null
};
	["ImageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	url?: ValueTypes["StringCondition"] | undefined | null,
	width?: ValueTypes["IntCondition"] | undefined | null,
	height?: ValueTypes["IntCondition"] | undefined | null,
	size?: ValueTypes["IntCondition"] | undefined | null,
	type?: ValueTypes["StringCondition"] | undefined | null,
	alt?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ImageWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ImageWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ImageWhere"] | undefined | null
};
	["ProductPackingLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	shortName?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductPackingWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductPackingLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductPackingLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null
};
	["LocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	businessCategories?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,
	productCategoryLists?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,
	productIngredients?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,
	orderRecurrences?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,
	blogs?: ValueTypes["BlogLocaleWhere"] | undefined | null,
	blogPosts?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,
	pages?: ValueTypes["PageLocaleWhere"] | undefined | null,
	productIngredientCategories?: ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null,
	allergens?: ValueTypes["AllergenLocaleWhere"] | undefined | null,
	productPackings?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,
	products?: ValueTypes["ProductLocaleWhere"] | undefined | null,
	productRecipes?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,
	deliveryMethods?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,
	ingredientSuppliers?: ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null,
	identificationCodes?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,
	subscriptionBoxes?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["LocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LocaleWhere"] | undefined | null
};
	["BusinessCategoryLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null
};
	["BusinessCategoryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	visibleToNewCustomers?: ValueTypes["BooleanCondition"] | undefined | null,
	locales?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	recurrences?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,
	specificProducts?: ValueTypes["ProductListWhere"] | undefined | null,
	seasonalProductCategories?: ValueTypes["ProductCategoryListWhere"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["ProductCategoryListWhere"] | undefined | null,
	slug?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["BusinessCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BusinessCategoryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BusinessCategoryWhere"] | undefined | null
};
	["OrderRecurrenceWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	locales?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	businessCategory?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	and?: Array<ValueTypes["OrderRecurrenceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderRecurrenceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderRecurrenceWhere"] | undefined | null
};
	["OrderRecurrenceLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null
};
	["ProductListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["ProductListItemWhere"] | undefined | null,
	businessCategory?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductListWhere"] | undefined | null
};
	["ProductListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	list?: ValueTypes["ProductListWhere"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	defaultQuantityForOrder?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductListItemWhere"] | undefined | null
};
	["ProductCategoryListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,
	locales?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductCategoryListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductCategoryListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductCategoryListWhere"] | undefined | null
};
	["ProductCategoryListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	list?: ValueTypes["ProductCategoryListWhere"] | undefined | null,
	productCategory?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	showRecipeImages?: ValueTypes["BooleanCondition"] | undefined | null,
	showPackingImages?: ValueTypes["BooleanCondition"] | undefined | null,
	showAllAvailableProducts?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductCategoryListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductCategoryListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null
};
	["ProductCategoryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	products?: ValueTypes["ProductWhere"] | undefined | null,
	tags?: ValueTypes["TagWhere"] | undefined | null,
	parent?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	children?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	color?: ValueTypes["StringCondition"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	order?: ValueTypes["FloatCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductCategoryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductCategoryWhere"] | undefined | null
};
	["FloatCondition"]: {
	and?: Array<ValueTypes["FloatCondition"]> | undefined | null,
	or?: Array<ValueTypes["FloatCondition"]> | undefined | null,
	not?: ValueTypes["FloatCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: number | undefined | null,
	notEq?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null
};
	["ProductCategoryListLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductCategoryListWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null
};
	["ProductIngredientLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductIngredientWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null
};
	["ProductIngredientWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	locales?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	categories?: ValueTypes["ProductIngredientCategoryWhere"] | undefined | null,
	allergens?: ValueTypes["AllergenWhere"] | undefined | null,
	needsPrepping?: ValueTypes["BooleanCondition"] | undefined | null,
	allowCustomerRating?: ValueTypes["BooleanCondition"] | undefined | null,
	preferredQuantityUnit?: ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null,
	freshingContainer?: ValueTypes["FreshingContainerWhere"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	suppliers?: ValueTypes["IngredientSupplierListWhere"] | undefined | null,
	incrementQuantityNumber?: ValueTypes["FloatCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientWhere"] | undefined | null
};
	["ProductIngredientCategoryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	ingredients?: ValueTypes["ProductIngredientWhere"] | undefined | null,
	locales?: ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientCategoryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientCategoryWhere"] | undefined | null
};
	["ProductIngredientCategoryLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductIngredientCategoryWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null
};
	["AllergenWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	locales?: ValueTypes["AllergenLocaleWhere"] | undefined | null,
	ingredients?: ValueTypes["ProductIngredientWhere"] | undefined | null,
	code?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["AllergenWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["AllergenWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["AllergenWhere"] | undefined | null
};
	["AllergenLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["AllergenWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["AllergenLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["AllergenLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["AllergenLocaleWhere"] | undefined | null
};
	["ProductIngredientItemQuantityUnitWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null
};
	["FreshingContainerWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	volumeMl?: ValueTypes["IntCondition"] | undefined | null,
	workspace?: ValueTypes["WorkspaceWhere"] | undefined | null,
	and?: Array<ValueTypes["FreshingContainerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["FreshingContainerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["FreshingContainerWhere"] | undefined | null
};
	["WorkspaceWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	unique?: ValueTypes["OneCondition"] | undefined | null,
	orderDeadlineTime?: ValueTypes["StringCondition"] | undefined | null,
	ordersLoadingDurationHours?: ValueTypes["IntCondition"] | undefined | null,
	freshingContainerVolumeMl?: ValueTypes["IntCondition"] | undefined | null,
	freshingPrepareOrderByOffsetMinutes?: ValueTypes["IntCondition"] | undefined | null,
	checkoutUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	orderUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	paymentUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	customerProfileUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	userProfileUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	messengerUrl?: ValueTypes["StringCondition"] | undefined | null,
	messengerIosUrl?: ValueTypes["StringCondition"] | undefined | null,
	messengerAndroidUrl?: ValueTypes["StringCondition"] | undefined | null,
	freshingContainers?: ValueTypes["FreshingContainerWhere"] | undefined | null,
	openingHours?: ValueTypes["WeekHoursWhere"] | undefined | null,
	workingHours?: ValueTypes["WeekHoursWhere"] | undefined | null,
	defaultFreshingContainer?: ValueTypes["FreshingContainerWhere"] | undefined | null,
	creditsProduct?: ValueTypes["VirtualProductWhere"] | undefined | null,
	discountVatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	businessAddress?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["WorkspaceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["WorkspaceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["WorkspaceWhere"] | undefined | null
};
	["OneCondition"]: {
	and?: Array<ValueTypes["OneCondition"]> | undefined | null,
	or?: Array<ValueTypes["OneCondition"]> | undefined | null,
	not?: ValueTypes["OneCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["One"] | undefined | null,
	notEq?: ValueTypes["One"] | undefined | null,
	in?: Array<ValueTypes["One"]> | undefined | null,
	notIn?: Array<ValueTypes["One"]> | undefined | null,
	lt?: ValueTypes["One"] | undefined | null,
	lte?: ValueTypes["One"] | undefined | null,
	gt?: ValueTypes["One"] | undefined | null,
	gte?: ValueTypes["One"] | undefined | null
};
	["One"]:One;
	["WeekHoursWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	mon?: ValueTypes["StringCondition"] | undefined | null,
	tue?: ValueTypes["StringCondition"] | undefined | null,
	wed?: ValueTypes["StringCondition"] | undefined | null,
	thu?: ValueTypes["StringCondition"] | undefined | null,
	fri?: ValueTypes["StringCondition"] | undefined | null,
	sat?: ValueTypes["StringCondition"] | undefined | null,
	sun?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["WeekHoursWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["WeekHoursWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["WeekHoursWhere"] | undefined | null
};
	["VirtualProductWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["VirtualProductTypeCondition"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	voucherCreditCents?: ValueTypes["IntCondition"] | undefined | null,
	physicalRepresentation?: ValueTypes["VirtualProductPhysicalRepresentationTypeCondition"] | undefined | null,
	recipientEmail?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["VirtualProductWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["VirtualProductWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["VirtualProductWhere"] | undefined | null
};
	["VirtualProductTypeCondition"]: {
	and?: Array<ValueTypes["VirtualProductTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["VirtualProductTypeCondition"]> | undefined | null,
	not?: ValueTypes["VirtualProductTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["VirtualProductType"] | undefined | null,
	notEq?: ValueTypes["VirtualProductType"] | undefined | null,
	in?: Array<ValueTypes["VirtualProductType"]> | undefined | null,
	notIn?: Array<ValueTypes["VirtualProductType"]> | undefined | null,
	lt?: ValueTypes["VirtualProductType"] | undefined | null,
	lte?: ValueTypes["VirtualProductType"] | undefined | null,
	gt?: ValueTypes["VirtualProductType"] | undefined | null,
	gte?: ValueTypes["VirtualProductType"] | undefined | null
};
	["VirtualProductType"]:VirtualProductType;
	["VirtualProductPhysicalRepresentationTypeCondition"]: {
	and?: Array<ValueTypes["VirtualProductPhysicalRepresentationTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["VirtualProductPhysicalRepresentationTypeCondition"]> | undefined | null,
	not?: ValueTypes["VirtualProductPhysicalRepresentationTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null,
	notEq?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null,
	in?: Array<ValueTypes["VirtualProductPhysicalRepresentationType"]> | undefined | null,
	notIn?: Array<ValueTypes["VirtualProductPhysicalRepresentationType"]> | undefined | null,
	lt?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null,
	lte?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null,
	gt?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null,
	gte?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null
};
	["VirtualProductPhysicalRepresentationType"]:VirtualProductPhysicalRepresentationType;
	["VatRateWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	ratePermille?: ValueTypes["IntCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	preset?: ValueTypes["VatRatePresetCondition"] | undefined | null,
	and?: Array<ValueTypes["VatRateWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["VatRateWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["VatRateWhere"] | undefined | null
};
	["VatRatePresetCondition"]: {
	and?: Array<ValueTypes["VatRatePresetCondition"]> | undefined | null,
	or?: Array<ValueTypes["VatRatePresetCondition"]> | undefined | null,
	not?: ValueTypes["VatRatePresetCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["VatRatePreset"] | undefined | null,
	notEq?: ValueTypes["VatRatePreset"] | undefined | null,
	in?: Array<ValueTypes["VatRatePreset"]> | undefined | null,
	notIn?: Array<ValueTypes["VatRatePreset"]> | undefined | null,
	lt?: ValueTypes["VatRatePreset"] | undefined | null,
	lte?: ValueTypes["VatRatePreset"] | undefined | null,
	gt?: ValueTypes["VatRatePreset"] | undefined | null,
	gte?: ValueTypes["VatRatePreset"] | undefined | null
};
	["VatRatePreset"]:VatRatePreset;
	["IngredientSupplierListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["IngredientSupplierItemWhere"] | undefined | null,
	and?: Array<ValueTypes["IngredientSupplierListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["IngredientSupplierListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["IngredientSupplierListWhere"] | undefined | null
};
	["IngredientSupplierItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	list?: ValueTypes["IngredientSupplierListWhere"] | undefined | null,
	supplier?: ValueTypes["IngredientSupplierWhere"] | undefined | null,
	and?: Array<ValueTypes["IngredientSupplierItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["IngredientSupplierItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["IngredientSupplierItemWhere"] | undefined | null
};
	["IngredientSupplierWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	locales?: ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["IngredientSupplierWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["IngredientSupplierWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["IngredientSupplierWhere"] | undefined | null
};
	["IngredientSupplierLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["IngredientSupplierWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null
};
	["BlogLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	pageName?: ValueTypes["StringCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	lead?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["BlogWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogLocaleWhere"] | undefined | null
};
	["BlogWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	unique?: ValueTypes["OneCondition"] | undefined | null,
	locales?: ValueTypes["BlogLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogWhere"] | undefined | null
};
	["BlogPostLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	availableForWeb?: ValueTypes["BooleanCondition"] | undefined | null,
	availableForMobile?: ValueTypes["BooleanCondition"] | undefined | null,
	root?: ValueTypes["BlogPostWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	cover?: ValueTypes["CoverWhere"] | undefined | null,
	content?: ValueTypes["ContentWhere"] | undefined | null,
	link?: ValueTypes["LinkableWhere"] | undefined | null,
	seo?: ValueTypes["SeoWhere"] | undefined | null,
	products?: ValueTypes["ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogPostLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogPostLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogPostLocaleWhere"] | undefined | null
};
	["BlogPostWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	locales?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogPostWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogPostWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogPostWhere"] | undefined | null
};
	["CoverWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	primaryTitle?: ValueTypes["StringCondition"] | undefined | null,
	secondaryTitle?: ValueTypes["StringCondition"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	buttonColors?: ValueTypes["ColorPalleteWhere"] | undefined | null,
	and?: Array<ValueTypes["CoverWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CoverWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CoverWhere"] | undefined | null
};
	["MediumWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["MediumTypeCondition"] | undefined | null,
	colorTheme?: ValueTypes["ColorThemeCondition"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	video?: ValueTypes["VideoWhere"] | undefined | null,
	and?: Array<ValueTypes["MediumWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["MediumWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["MediumWhere"] | undefined | null
};
	["MediumTypeCondition"]: {
	and?: Array<ValueTypes["MediumTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["MediumTypeCondition"]> | undefined | null,
	not?: ValueTypes["MediumTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["MediumType"] | undefined | null,
	notEq?: ValueTypes["MediumType"] | undefined | null,
	in?: Array<ValueTypes["MediumType"]> | undefined | null,
	notIn?: Array<ValueTypes["MediumType"]> | undefined | null,
	lt?: ValueTypes["MediumType"] | undefined | null,
	lte?: ValueTypes["MediumType"] | undefined | null,
	gt?: ValueTypes["MediumType"] | undefined | null,
	gte?: ValueTypes["MediumType"] | undefined | null
};
	["MediumType"]:MediumType;
	["ColorThemeCondition"]: {
	and?: Array<ValueTypes["ColorThemeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ColorThemeCondition"]> | undefined | null,
	not?: ValueTypes["ColorThemeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ColorTheme"] | undefined | null,
	notEq?: ValueTypes["ColorTheme"] | undefined | null,
	in?: Array<ValueTypes["ColorTheme"]> | undefined | null,
	notIn?: Array<ValueTypes["ColorTheme"]> | undefined | null,
	lt?: ValueTypes["ColorTheme"] | undefined | null,
	lte?: ValueTypes["ColorTheme"] | undefined | null,
	gt?: ValueTypes["ColorTheme"] | undefined | null,
	gte?: ValueTypes["ColorTheme"] | undefined | null
};
	["ColorTheme"]:ColorTheme;
	["VideoWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	src?: ValueTypes["StringCondition"] | undefined | null,
	width?: ValueTypes["IntCondition"] | undefined | null,
	height?: ValueTypes["IntCondition"] | undefined | null,
	size?: ValueTypes["IntCondition"] | undefined | null,
	type?: ValueTypes["StringCondition"] | undefined | null,
	duration?: ValueTypes["FloatCondition"] | undefined | null,
	poster?: ValueTypes["ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["VideoWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["VideoWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["VideoWhere"] | undefined | null
};
	["ColorPalleteWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	background?: ValueTypes["StringCondition"] | undefined | null,
	text?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ColorPalleteWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ColorPalleteWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ColorPalleteWhere"] | undefined | null
};
	["ContentWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	blocks?: ValueTypes["ContentBlockWhere"] | undefined | null,
	and?: Array<ValueTypes["ContentWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ContentWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ContentWhere"] | undefined | null
};
	["ContentBlockWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	json?: ValueTypes["StringCondition"] | undefined | null,
	content?: ValueTypes["ContentWhere"] | undefined | null,
	references?: ValueTypes["ContentReferenceWhere"] | undefined | null,
	and?: Array<ValueTypes["ContentBlockWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ContentBlockWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ContentBlockWhere"] | undefined | null
};
	["ContentReferenceWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["ContentReferenceTypeCondition"] | undefined | null,
	primaryText?: ValueTypes["StringCondition"] | undefined | null,
	secondaryText?: ValueTypes["StringCondition"] | undefined | null,
	jsonContent?: ValueTypes["StringCondition"] | undefined | null,
	block?: ValueTypes["ContentBlockWhere"] | undefined | null,
	colorPallete?: ValueTypes["ColorPalleteWhere"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	link?: ValueTypes["LinkWhere"] | undefined | null,
	blogPosts?: ValueTypes["BlogPostListWhere"] | undefined | null,
	links?: ValueTypes["LinkListWhere"] | undefined | null,
	products?: ValueTypes["ProductListWhere"] | undefined | null,
	contentSize?: ValueTypes["ContentSizeCondition"] | undefined | null,
	misc?: ValueTypes["StringCondition"] | undefined | null,
	hero?: ValueTypes["HeroWhere"] | undefined | null,
	gallery?: ValueTypes["GalleryWhere"] | undefined | null,
	socialsAndApps?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,
	productGrid?: ValueTypes["ProductGridWhere"] | undefined | null,
	productBanners?: ValueTypes["ProductBannerListWhere"] | undefined | null,
	deliveryRegions?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,
	textList?: ValueTypes["TextListWhere"] | undefined | null,
	gridTiles?: ValueTypes["GridTilesWhere"] | undefined | null,
	and?: Array<ValueTypes["ContentReferenceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ContentReferenceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ContentReferenceWhere"] | undefined | null
};
	["ContentReferenceTypeCondition"]: {
	and?: Array<ValueTypes["ContentReferenceTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ContentReferenceTypeCondition"]> | undefined | null,
	not?: ValueTypes["ContentReferenceTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ContentReferenceType"] | undefined | null,
	notEq?: ValueTypes["ContentReferenceType"] | undefined | null,
	in?: Array<ValueTypes["ContentReferenceType"]> | undefined | null,
	notIn?: Array<ValueTypes["ContentReferenceType"]> | undefined | null,
	lt?: ValueTypes["ContentReferenceType"] | undefined | null,
	lte?: ValueTypes["ContentReferenceType"] | undefined | null,
	gt?: ValueTypes["ContentReferenceType"] | undefined | null,
	gte?: ValueTypes["ContentReferenceType"] | undefined | null
};
	["ContentReferenceType"]:ContentReferenceType;
	["LinkWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["LinkTypeCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	externalLink?: ValueTypes["StringCondition"] | undefined | null,
	internalLink?: ValueTypes["LinkableWhere"] | undefined | null,
	and?: Array<ValueTypes["LinkWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LinkWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LinkWhere"] | undefined | null
};
	["LinkTypeCondition"]: {
	and?: Array<ValueTypes["LinkTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["LinkTypeCondition"]> | undefined | null,
	not?: ValueTypes["LinkTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["LinkType"] | undefined | null,
	notEq?: ValueTypes["LinkType"] | undefined | null,
	in?: Array<ValueTypes["LinkType"]> | undefined | null,
	notIn?: Array<ValueTypes["LinkType"]> | undefined | null,
	lt?: ValueTypes["LinkType"] | undefined | null,
	lte?: ValueTypes["LinkType"] | undefined | null,
	gt?: ValueTypes["LinkType"] | undefined | null,
	gte?: ValueTypes["LinkType"] | undefined | null
};
	["LinkType"]:LinkType;
	["LinkableWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	url?: ValueTypes["StringCondition"] | undefined | null,
	blogPost?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,
	page?: ValueTypes["PageLocaleWhere"] | undefined | null,
	redirect?: ValueTypes["RedirectWhere"] | undefined | null,
	and?: Array<ValueTypes["LinkableWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LinkableWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LinkableWhere"] | undefined | null
};
	["PageLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	link?: ValueTypes["LinkableWhere"] | undefined | null,
	root?: ValueTypes["PageWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	seo?: ValueTypes["SeoWhere"] | undefined | null,
	content?: ValueTypes["ContentWhere"] | undefined | null,
	cover?: ValueTypes["CoverWhere"] | undefined | null,
	theme?: ValueTypes["PageThemeCondition"] | undefined | null,
	and?: Array<ValueTypes["PageLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["PageLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["PageLocaleWhere"] | undefined | null
};
	["PageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	locales?: ValueTypes["PageLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["PageWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["PageWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["PageWhere"] | undefined | null
};
	["SeoWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	ogTitle?: ValueTypes["StringCondition"] | undefined | null,
	ogDescription?: ValueTypes["StringCondition"] | undefined | null,
	ogImage?: ValueTypes["ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["SeoWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["SeoWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["SeoWhere"] | undefined | null
};
	["PageThemeCondition"]: {
	and?: Array<ValueTypes["PageThemeCondition"]> | undefined | null,
	or?: Array<ValueTypes["PageThemeCondition"]> | undefined | null,
	not?: ValueTypes["PageThemeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["PageTheme"] | undefined | null,
	notEq?: ValueTypes["PageTheme"] | undefined | null,
	in?: Array<ValueTypes["PageTheme"]> | undefined | null,
	notIn?: Array<ValueTypes["PageTheme"]> | undefined | null,
	lt?: ValueTypes["PageTheme"] | undefined | null,
	lte?: ValueTypes["PageTheme"] | undefined | null,
	gt?: ValueTypes["PageTheme"] | undefined | null,
	gte?: ValueTypes["PageTheme"] | undefined | null
};
	["PageTheme"]:PageTheme;
	["RedirectWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	link?: ValueTypes["LinkableWhere"] | undefined | null,
	target?: ValueTypes["LinkWhere"] | undefined | null,
	and?: Array<ValueTypes["RedirectWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["RedirectWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["RedirectWhere"] | undefined | null
};
	["BlogPostListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["BlogPostListItemWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogPostListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogPostListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogPostListWhere"] | undefined | null
};
	["BlogPostListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	caption?: ValueTypes["StringCondition"] | undefined | null,
	list?: ValueTypes["BlogPostListWhere"] | undefined | null,
	blogPost?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogPostListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogPostListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogPostListItemWhere"] | undefined | null
};
	["LinkListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["LinkListItemWhere"] | undefined | null,
	and?: Array<ValueTypes["LinkListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LinkListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LinkListWhere"] | undefined | null
};
	["LinkListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	isPrimary?: ValueTypes["BooleanCondition"] | undefined | null,
	list?: ValueTypes["LinkListWhere"] | undefined | null,
	link?: ValueTypes["LinkWhere"] | undefined | null,
	and?: Array<ValueTypes["LinkListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LinkListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LinkListItemWhere"] | undefined | null
};
	["ContentSizeCondition"]: {
	and?: Array<ValueTypes["ContentSizeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ContentSizeCondition"]> | undefined | null,
	not?: ValueTypes["ContentSizeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ContentSize"] | undefined | null,
	notEq?: ValueTypes["ContentSize"] | undefined | null,
	in?: Array<ValueTypes["ContentSize"]> | undefined | null,
	notIn?: Array<ValueTypes["ContentSize"]> | undefined | null,
	lt?: ValueTypes["ContentSize"] | undefined | null,
	lte?: ValueTypes["ContentSize"] | undefined | null,
	gt?: ValueTypes["ContentSize"] | undefined | null,
	gte?: ValueTypes["ContentSize"] | undefined | null
};
	["ContentSize"]:ContentSize;
	["HeroWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	showLogo?: ValueTypes["BooleanCondition"] | undefined | null,
	middleContent?: ValueTypes["StringCondition"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	and?: Array<ValueTypes["HeroWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["HeroWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["HeroWhere"] | undefined | null
};
	["GalleryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["GalleryItemWhere"] | undefined | null,
	and?: Array<ValueTypes["GalleryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["GalleryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["GalleryWhere"] | undefined | null
};
	["GalleryItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	caption?: ValueTypes["StringCondition"] | undefined | null,
	list?: ValueTypes["GalleryWhere"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	and?: Array<ValueTypes["GalleryItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["GalleryItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["GalleryItemWhere"] | undefined | null
};
	["SocialsAndAppsWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	instagram?: ValueTypes["BooleanCondition"] | undefined | null,
	appStore?: ValueTypes["BooleanCondition"] | undefined | null,
	googlePlay?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["SocialsAndAppsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["SocialsAndAppsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["SocialsAndAppsWhere"] | undefined | null
};
	["ProductGridWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	isCarousel?: ValueTypes["BooleanCondition"] | undefined | null,
	categories?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,
	type?: ValueTypes["ProductGridTypeCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductGridWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductGridWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductGridWhere"] | undefined | null
};
	["ProductGridCategoryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductGridWhere"] | undefined | null,
	items?: ValueTypes["ProductGridItemWhere"] | undefined | null,
	images?: ValueTypes["ImageListWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductGridCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductGridCategoryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductGridCategoryWhere"] | undefined | null
};
	["ProductGridItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	lead?: ValueTypes["StringCondition"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	link?: ValueTypes["LinkWhere"] | undefined | null,
	category?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductGridItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductGridItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductGridItemWhere"] | undefined | null
};
	["ImageListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["ImageListItemWhere"] | undefined | null,
	and?: Array<ValueTypes["ImageListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ImageListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ImageListWhere"] | undefined | null
};
	["ImageListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	list?: ValueTypes["ImageListWhere"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["ImageListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ImageListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ImageListItemWhere"] | undefined | null
};
	["ProductGridTypeCondition"]: {
	and?: Array<ValueTypes["ProductGridTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ProductGridTypeCondition"]> | undefined | null,
	not?: ValueTypes["ProductGridTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ProductGridType"] | undefined | null,
	notEq?: ValueTypes["ProductGridType"] | undefined | null,
	in?: Array<ValueTypes["ProductGridType"]> | undefined | null,
	notIn?: Array<ValueTypes["ProductGridType"]> | undefined | null,
	lt?: ValueTypes["ProductGridType"] | undefined | null,
	lte?: ValueTypes["ProductGridType"] | undefined | null,
	gt?: ValueTypes["ProductGridType"] | undefined | null,
	gte?: ValueTypes["ProductGridType"] | undefined | null
};
	["ProductGridType"]:ProductGridType;
	["ProductBannerListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["ProductBannerItemWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductBannerListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductBannerListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductBannerListWhere"] | undefined | null
};
	["ProductBannerItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	list?: ValueTypes["ProductBannerListWhere"] | undefined | null,
	banner?: ValueTypes["ProductBannerWhere"] | undefined | null,
	size?: ValueTypes["ProductBannerItemSizeCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductBannerItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductBannerItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductBannerItemWhere"] | undefined | null
};
	["ProductBannerWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	beforeTitle?: ValueTypes["StringCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	label?: ValueTypes["StringCondition"] | undefined | null,
	button?: ValueTypes["LinkWhere"] | undefined | null,
	backgroundImage?: ValueTypes["ImageWhere"] | undefined | null,
	productImage?: ValueTypes["ImageWhere"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductBannerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductBannerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductBannerWhere"] | undefined | null
};
	["ProductBannerItemSizeCondition"]: {
	and?: Array<ValueTypes["ProductBannerItemSizeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ProductBannerItemSizeCondition"]> | undefined | null,
	not?: ValueTypes["ProductBannerItemSizeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ProductBannerItemSize"] | undefined | null,
	notEq?: ValueTypes["ProductBannerItemSize"] | undefined | null,
	in?: Array<ValueTypes["ProductBannerItemSize"]> | undefined | null,
	notIn?: Array<ValueTypes["ProductBannerItemSize"]> | undefined | null,
	lt?: ValueTypes["ProductBannerItemSize"] | undefined | null,
	lte?: ValueTypes["ProductBannerItemSize"] | undefined | null,
	gt?: ValueTypes["ProductBannerItemSize"] | undefined | null,
	gte?: ValueTypes["ProductBannerItemSize"] | undefined | null
};
	["ProductBannerItemSize"]:ProductBannerItemSize;
	["DeliveryRegionsWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryRegionsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryRegionsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryRegionsWhere"] | undefined | null
};
	["DeliveryRegionsItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	offerLabel?: ValueTypes["StringCondition"] | undefined | null,
	list?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	textList?: ValueTypes["TextListWhere"] | undefined | null,
	gallery?: ValueTypes["ImageListWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryRegionsItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryRegionsItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null
};
	["TextListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["TextItemWhere"] | undefined | null,
	and?: Array<ValueTypes["TextListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TextListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TextListWhere"] | undefined | null
};
	["TextItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	text?: ValueTypes["StringCondition"] | undefined | null,
	list?: ValueTypes["TextListWhere"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["TextItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TextItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TextItemWhere"] | undefined | null
};
	["GridTilesWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["GridTileItemWhere"] | undefined | null,
	and?: Array<ValueTypes["GridTilesWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["GridTilesWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["GridTilesWhere"] | undefined | null
};
	["GridTileItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	text?: ValueTypes["StringCondition"] | undefined | null,
	list?: ValueTypes["GridTilesWhere"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	link?: ValueTypes["LinkWhere"] | undefined | null,
	and?: Array<ValueTypes["GridTileItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["GridTileItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["GridTileItemWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	catalogue?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,
	and?: Array<ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null
};
	["TranslationCatalogueWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	domain?: ValueTypes["TranslationDomainWhere"] | undefined | null,
	fallback?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,
	values?: ValueTypes["TranslationValueWhere"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,
	and?: Array<ValueTypes["TranslationCatalogueWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TranslationCatalogueWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TranslationCatalogueWhere"] | undefined | null
};
	["TranslationDomainWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	identifier?: ValueTypes["StringCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	catalogues?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,
	keys?: ValueTypes["TranslationKeyWhere"] | undefined | null,
	and?: Array<ValueTypes["TranslationDomainWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TranslationDomainWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TranslationDomainWhere"] | undefined | null
};
	["TranslationKeyWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	identifier?: ValueTypes["StringCondition"] | undefined | null,
	contentType?: ValueTypes["TranslationContentTypeCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	domain?: ValueTypes["TranslationDomainWhere"] | undefined | null,
	values?: ValueTypes["TranslationValueWhere"] | undefined | null,
	and?: Array<ValueTypes["TranslationKeyWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TranslationKeyWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TranslationKeyWhere"] | undefined | null
};
	["TranslationContentTypeCondition"]: {
	and?: Array<ValueTypes["TranslationContentTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["TranslationContentTypeCondition"]> | undefined | null,
	not?: ValueTypes["TranslationContentTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["TranslationContentType"] | undefined | null,
	notEq?: ValueTypes["TranslationContentType"] | undefined | null,
	in?: Array<ValueTypes["TranslationContentType"]> | undefined | null,
	notIn?: Array<ValueTypes["TranslationContentType"]> | undefined | null,
	lt?: ValueTypes["TranslationContentType"] | undefined | null,
	lte?: ValueTypes["TranslationContentType"] | undefined | null,
	gt?: ValueTypes["TranslationContentType"] | undefined | null,
	gte?: ValueTypes["TranslationContentType"] | undefined | null
};
	["TranslationContentType"]:TranslationContentType;
	["TranslationValueWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	value?: ValueTypes["StringCondition"] | undefined | null,
	catalogue?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,
	key?: ValueTypes["TranslationKeyWhere"] | undefined | null,
	and?: Array<ValueTypes["TranslationValueWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TranslationValueWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TranslationValueWhere"] | undefined | null
};
	["ProductLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	featureList?: ValueTypes["TextListWhere"] | undefined | null,
	printableName?: ValueTypes["StringCondition"] | undefined | null,
	firstSticker?: ValueTypes["StringCondition"] | undefined | null,
	secondSticker?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductLocaleWhere"] | undefined | null
};
	["ProductRecipeLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductRecipeWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductRecipeLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductRecipeLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null
};
	["ProductRecipeWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	products?: ValueTypes["ProductWhere"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	color?: ValueTypes["StringCondition"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	icon?: ValueTypes["ImageWhere"] | undefined | null,
	ingredients?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,
	needsPrepping?: ValueTypes["BooleanCondition"] | undefined | null,
	locales?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,
	gutTuning?: ValueTypes["FloatCondition"] | undefined | null,
	freshingContainer?: ValueTypes["FreshingContainerWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductRecipeWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductRecipeWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductRecipeWhere"] | undefined | null
};
	["ProductIngredientItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	internalOrder?: ValueTypes["IntCondition"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeWhere"] | undefined | null,
	ingredient?: ValueTypes["ProductIngredientWhere"] | undefined | null,
	quantity?: ValueTypes["ProductIngredientItemQuantityWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientItemWhere"] | undefined | null
};
	["ProductIngredientItemQuantityWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	unit?: ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null,
	value?: ValueTypes["FloatCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientItemQuantityWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientItemQuantityWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientItemQuantityWhere"] | undefined | null
};
	["DeliveryMethodLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	trackingCodeUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	processingTitle?: ValueTypes["StringCondition"] | undefined | null,
	processingText?: ValueTypes["StringCondition"] | undefined | null,
	processingIconUrl?: ValueTypes["StringCondition"] | undefined | null,
	dispatchedTitle?: ValueTypes["StringCondition"] | undefined | null,
	dispatchedText?: ValueTypes["StringCondition"] | undefined | null,
	dispatchedIconUrl?: ValueTypes["StringCondition"] | undefined | null,
	fulfilledTitle?: ValueTypes["StringCondition"] | undefined | null,
	fulfilledText?: ValueTypes["StringCondition"] | undefined | null,
	fulfilledIconUrl?: ValueTypes["StringCondition"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	root?: ValueTypes["DeliveryMethodWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null
};
	["DeliveryMethodWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	enabled?: ValueTypes["BooleanCondition"] | undefined | null,
	paymentMethods?: ValueTypes["PaymentMethodWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	requireDeliveryAddress?: ValueTypes["BooleanCondition"] | undefined | null,
	suitableForDeliveryZones?: ValueTypes["DeliveryZoneWhere"] | undefined | null,
	enableDeliveryTracking?: ValueTypes["BooleanCondition"] | undefined | null,
	locales?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,
	timelinePreset?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryMethodWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryMethodWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryMethodWhere"] | undefined | null
};
	["PaymentMethodWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	enabled?: ValueTypes["BooleanCondition"] | undefined | null,
	deliveryMethods?: ValueTypes["DeliveryMethodWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	blockingDispatch?: ValueTypes["BooleanCondition"] | undefined | null,
	type?: ValueTypes["PaymentMethodTypeCondition"] | undefined | null,
	reduceGratuityByPercentage?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["PaymentMethodWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["PaymentMethodWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["PaymentMethodWhere"] | undefined | null
};
	["PaymentMethodTypeCondition"]: {
	and?: Array<ValueTypes["PaymentMethodTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["PaymentMethodTypeCondition"]> | undefined | null,
	not?: ValueTypes["PaymentMethodTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["PaymentMethodType"] | undefined | null,
	notEq?: ValueTypes["PaymentMethodType"] | undefined | null,
	in?: Array<ValueTypes["PaymentMethodType"]> | undefined | null,
	notIn?: Array<ValueTypes["PaymentMethodType"]> | undefined | null,
	lt?: ValueTypes["PaymentMethodType"] | undefined | null,
	lte?: ValueTypes["PaymentMethodType"] | undefined | null,
	gt?: ValueTypes["PaymentMethodType"] | undefined | null,
	gte?: ValueTypes["PaymentMethodType"] | undefined | null
};
	["PaymentMethodType"]:PaymentMethodType;
	["DeliveryZoneWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	type?: ValueTypes["DeliveryZoneTypeCondition"] | undefined | null,
	addresses?: ValueTypes["AddressWhere"] | undefined | null,
	suitableDeliveryMethods?: ValueTypes["DeliveryMethodWhere"] | undefined | null,
	deliveryZonesOfTags?: ValueTypes["TagWhere"] | undefined | null,
	deliveryZonesOfProducts?: ValueTypes["ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryZoneWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryZoneWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryZoneWhere"] | undefined | null
};
	["DeliveryZoneTypeCondition"]: {
	and?: Array<ValueTypes["DeliveryZoneTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["DeliveryZoneTypeCondition"]> | undefined | null,
	not?: ValueTypes["DeliveryZoneTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["DeliveryZoneType"] | undefined | null,
	notEq?: ValueTypes["DeliveryZoneType"] | undefined | null,
	in?: Array<ValueTypes["DeliveryZoneType"]> | undefined | null,
	notIn?: Array<ValueTypes["DeliveryZoneType"]> | undefined | null,
	lt?: ValueTypes["DeliveryZoneType"] | undefined | null,
	lte?: ValueTypes["DeliveryZoneType"] | undefined | null,
	gt?: ValueTypes["DeliveryZoneType"] | undefined | null,
	gte?: ValueTypes["DeliveryZoneType"] | undefined | null
};
	["DeliveryZoneType"]:DeliveryZoneType;
	["DeliveryTimelinePresetWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["DeliveryTimelineTypeCondition"] | undefined | null,
	duration?: ValueTypes["StringCondition"] | undefined | null,
	window?: ValueTypes["StringCondition"] | undefined | null,
	deliveryMethod?: ValueTypes["DeliveryMethodWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null
};
	["DeliveryTimelineTypeCondition"]: {
	and?: Array<ValueTypes["DeliveryTimelineTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["DeliveryTimelineTypeCondition"]> | undefined | null,
	not?: ValueTypes["DeliveryTimelineTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["DeliveryTimelineType"] | undefined | null,
	notEq?: ValueTypes["DeliveryTimelineType"] | undefined | null,
	in?: Array<ValueTypes["DeliveryTimelineType"]> | undefined | null,
	notIn?: Array<ValueTypes["DeliveryTimelineType"]> | undefined | null,
	lt?: ValueTypes["DeliveryTimelineType"] | undefined | null,
	lte?: ValueTypes["DeliveryTimelineType"] | undefined | null,
	gt?: ValueTypes["DeliveryTimelineType"] | undefined | null,
	gte?: ValueTypes["DeliveryTimelineType"] | undefined | null
};
	["DeliveryTimelineType"]:DeliveryTimelineType;
	["IdentificationCodeLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	successMessage?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["IdentificationCodeWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null
};
	["IdentificationCodeWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	attempts?: ValueTypes["IdentificationAttemptWhere"] | undefined | null,
	locales?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["IdentificationCodeWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["IdentificationCodeWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["IdentificationCodeWhere"] | undefined | null
};
	["DateTimeCondition"]: {
	and?: Array<ValueTypes["DateTimeCondition"]> | undefined | null,
	or?: Array<ValueTypes["DateTimeCondition"]> | undefined | null,
	not?: ValueTypes["DateTimeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["DateTime"] | undefined | null,
	notEq?: ValueTypes["DateTime"] | undefined | null,
	in?: Array<ValueTypes["DateTime"]> | undefined | null,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null,
	lt?: ValueTypes["DateTime"] | undefined | null,
	lte?: ValueTypes["DateTime"] | undefined | null,
	gt?: ValueTypes["DateTime"] | undefined | null,
	gte?: ValueTypes["DateTime"] | undefined | null
};
	["DateTime"]:string;
	["IdentificationAttemptWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	code?: ValueTypes["IdentificationCodeWhere"] | undefined | null,
	and?: Array<ValueTypes["IdentificationAttemptWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["IdentificationAttemptWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["IdentificationAttemptWhere"] | undefined | null
};
	["SubscriptionBoxLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	stickerText?: ValueTypes["StringCondition"] | undefined | null,
	descriptionText?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["SubscriptionBoxWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	secondaryTitle?: ValueTypes["StringCondition"] | undefined | null,
	texts?: ValueTypes["TextListWhere"] | undefined | null,
	secondaryTexts?: ValueTypes["TextListWhere"] | undefined | null,
	and?: Array<ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null
};
	["SubscriptionBoxWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	locales?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,
	unique?: ValueTypes["OneCondition"] | undefined | null,
	and?: Array<ValueTypes["SubscriptionBoxWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["SubscriptionBoxWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["SubscriptionBoxWhere"] | undefined | null
};
	["PreparedPackingQuantityWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	date?: ValueTypes["DateCondition"] | undefined | null,
	quantity?: ValueTypes["FloatCondition"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeWhere"] | undefined | null,
	packing?: ValueTypes["ProductPackingWhere"] | undefined | null,
	packedInCardboard?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["PreparedPackingQuantityWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["PreparedPackingQuantityWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null
};
	["DateCondition"]: {
	and?: Array<ValueTypes["DateCondition"]> | undefined | null,
	or?: Array<ValueTypes["DateCondition"]> | undefined | null,
	not?: ValueTypes["DateCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Date"] | undefined | null,
	notEq?: ValueTypes["Date"] | undefined | null,
	in?: Array<ValueTypes["Date"]> | undefined | null,
	notIn?: Array<ValueTypes["Date"]> | undefined | null,
	lt?: ValueTypes["Date"] | undefined | null,
	lte?: ValueTypes["Date"] | undefined | null,
	gt?: ValueTypes["Date"] | undefined | null,
	gte?: ValueTypes["Date"] | undefined | null
};
	["Date"]:unknown;
	["ProductStockWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	quantity?: ValueTypes["IntCondition"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	store?: ValueTypes["StoreWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductStockWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductStockWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductStockWhere"] | undefined | null
};
	["StoreWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["StoreWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["StoreWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["StoreWhere"] | undefined | null
};
	["ProductAvailabilityCondition"]: {
	and?: Array<ValueTypes["ProductAvailabilityCondition"]> | undefined | null,
	or?: Array<ValueTypes["ProductAvailabilityCondition"]> | undefined | null,
	not?: ValueTypes["ProductAvailabilityCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ProductAvailability"] | undefined | null,
	notEq?: ValueTypes["ProductAvailability"] | undefined | null,
	in?: Array<ValueTypes["ProductAvailability"]> | undefined | null,
	notIn?: Array<ValueTypes["ProductAvailability"]> | undefined | null,
	lt?: ValueTypes["ProductAvailability"] | undefined | null,
	lte?: ValueTypes["ProductAvailability"] | undefined | null,
	gt?: ValueTypes["ProductAvailability"] | undefined | null,
	gte?: ValueTypes["ProductAvailability"] | undefined | null
};
	["ProductAvailability"]:ProductAvailability;
	["OrderItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	unitPriceCents?: ValueTypes["IntCondition"] | undefined | null,
	quantity?: ValueTypes["IntCondition"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	order?: ValueTypes["OrderWhere"] | undefined | null,
	pickedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	fulfillmentNotes?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["OrderItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderItemWhere"] | undefined | null
};
	["OrderWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	state?: ValueTypes["OrderStateCondition"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	canceledAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	channel?: ValueTypes["ChannelWhere"] | undefined | null,
	delivery?: ValueTypes["OrderDeliveryWhere"] | undefined | null,
	items?: ValueTypes["OrderItemWhere"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	billingAddress?: ValueTypes["AddressWhere"] | undefined | null,
	payments?: ValueTypes["OrderPaymentWhere"] | undefined | null,
	seq?: ValueTypes["IntCondition"] | undefined | null,
	doubleshotLegacyId?: ValueTypes["StringCondition"] | undefined | null,
	venue?: ValueTypes["VenueWhere"] | undefined | null,
	fulfillmentNote?: ValueTypes["StringCondition"] | undefined | null,
	fulfillmentNotes?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,
	doubleshotTotalWeightGrams?: ValueTypes["IntCondition"] | undefined | null,
	doubleShotLegacyNumber?: ValueTypes["StringCondition"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	doubleshotAdminUrl?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["OrderWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderWhere"] | undefined | null
};
	["OrderStateCondition"]: {
	and?: Array<ValueTypes["OrderStateCondition"]> | undefined | null,
	or?: Array<ValueTypes["OrderStateCondition"]> | undefined | null,
	not?: ValueTypes["OrderStateCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["OrderState"] | undefined | null,
	notEq?: ValueTypes["OrderState"] | undefined | null,
	in?: Array<ValueTypes["OrderState"]> | undefined | null,
	notIn?: Array<ValueTypes["OrderState"]> | undefined | null,
	lt?: ValueTypes["OrderState"] | undefined | null,
	lte?: ValueTypes["OrderState"] | undefined | null,
	gt?: ValueTypes["OrderState"] | undefined | null,
	gte?: ValueTypes["OrderState"] | undefined | null
};
	["OrderState"]:OrderState;
	["ChannelWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	disabled?: ValueTypes["BooleanCondition"] | undefined | null,
	customers?: ValueTypes["CustomerWhere"] | undefined | null,
	code?: ValueTypes["ChannelCodeCondition"] | undefined | null,
	addTagsOnRegistration?: ValueTypes["TagWhere"] | undefined | null,
	publicTags?: ValueTypes["TagWhere"] | undefined | null,
	venue?: ValueTypes["VenueWhere"] | undefined | null,
	and?: Array<ValueTypes["ChannelWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ChannelWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ChannelWhere"] | undefined | null
};
	["ChannelCodeCondition"]: {
	and?: Array<ValueTypes["ChannelCodeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ChannelCodeCondition"]> | undefined | null,
	not?: ValueTypes["ChannelCodeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ChannelCode"] | undefined | null,
	notEq?: ValueTypes["ChannelCode"] | undefined | null,
	in?: Array<ValueTypes["ChannelCode"]> | undefined | null,
	notIn?: Array<ValueTypes["ChannelCode"]> | undefined | null,
	lt?: ValueTypes["ChannelCode"] | undefined | null,
	lte?: ValueTypes["ChannelCode"] | undefined | null,
	gt?: ValueTypes["ChannelCode"] | undefined | null,
	gte?: ValueTypes["ChannelCode"] | undefined | null
};
	["ChannelCode"]:ChannelCode;
	["VenueWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	deadlineTemplate?: ValueTypes["DeadlineTemplateWhere"] | undefined | null,
	timetableDays?: ValueTypes["TimetableDayWhere"] | undefined | null,
	timetableTemplates?: ValueTypes["TimetableTemplateWhere"] | undefined | null,
	staffMembers?: ValueTypes["StaffWhere"] | undefined | null,
	employeeRoles?: ValueTypes["VenueEmployeeRoleWhere"] | undefined | null,
	orders?: ValueTypes["OrderWhere"] | undefined | null,
	fulfillmentNotes?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,
	enabledUnassignReasons?: ValueTypes["UnassignReasonWhere"] | undefined | null,
	channels?: ValueTypes["ChannelWhere"] | undefined | null,
	address?: ValueTypes["StringCondition"] | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: ValueTypes["StringCondition"] | undefined | null,
	pointOfSaleInitialView?: ValueTypes["PointOfSaleInitialViewCondition"] | undefined | null,
	pointOfSalePreferPrintReceipt?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["VenueWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["VenueWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["VenueWhere"] | undefined | null
};
	["DeadlineTemplateWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	startDate?: ValueTypes["DateCondition"] | undefined | null,
	period?: ValueTypes["IntCondition"] | undefined | null,
	cutoff?: ValueTypes["IntCondition"] | undefined | null,
	closed?: ValueTypes["BooleanCondition"] | undefined | null,
	venue?: ValueTypes["VenueWhere"] | undefined | null,
	and?: Array<ValueTypes["DeadlineTemplateWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeadlineTemplateWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeadlineTemplateWhere"] | undefined | null
};
	["TimetableDayWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	date?: ValueTypes["DateCondition"] | undefined | null,
	type?: ValueTypes["TimetableDayTypeCondition"] | undefined | null,
	shiftGroups?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null,
	tipsCents?: ValueTypes["IntCondition"] | undefined | null,
	notes?: ValueTypes["TimetableDayNoteWhere"] | undefined | null,
	venue?: ValueTypes["VenueWhere"] | undefined | null,
	meta?: ValueTypes["TimetableDayMetadataWhere"] | undefined | null,
	and?: Array<ValueTypes["TimetableDayWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TimetableDayWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TimetableDayWhere"] | undefined | null
};
	["TimetableDayTypeCondition"]: {
	and?: Array<ValueTypes["TimetableDayTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["TimetableDayTypeCondition"]> | undefined | null,
	not?: ValueTypes["TimetableDayTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["TimetableDayType"] | undefined | null,
	notEq?: ValueTypes["TimetableDayType"] | undefined | null,
	in?: Array<ValueTypes["TimetableDayType"]> | undefined | null,
	notIn?: Array<ValueTypes["TimetableDayType"]> | undefined | null,
	lt?: ValueTypes["TimetableDayType"] | undefined | null,
	lte?: ValueTypes["TimetableDayType"] | undefined | null,
	gt?: ValueTypes["TimetableDayType"] | undefined | null,
	gte?: ValueTypes["TimetableDayType"] | undefined | null
};
	["TimetableDayType"]:TimetableDayType;
	["TimetableShiftGroupWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	shifts?: ValueTypes["TimetableShiftWhere"] | undefined | null,
	day?: ValueTypes["TimetableDayWhere"] | undefined | null,
	type?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null,
	position?: ValueTypes["TimetableShiftPositionWhere"] | undefined | null,
	queue?: ValueTypes["EmployeeQueueWhere"] | undefined | null,
	generatedByTemplate?: ValueTypes["TimetableTemplateWhere"] | undefined | null,
	and?: Array<ValueTypes["TimetableShiftGroupWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TimetableShiftGroupWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null
};
	["TimetableShiftWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	locked?: ValueTypes["BooleanCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	assigned?: ValueTypes["DateCondition"] | undefined | null,
	group?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null,
	shiftsProfile?: ValueTypes["ShiftsProfileWhere"] | undefined | null,
	queueItem?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,
	isOvertime?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["TimetableShiftWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TimetableShiftWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TimetableShiftWhere"] | undefined | null
};
	["ShiftsProfileWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	queueItems?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,
	staff?: ValueTypes["StaffWhere"] | undefined | null,
	roles?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null,
	shifts?: ValueTypes["TimetableShiftWhere"] | undefined | null,
	allowSelfUnassigning?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["ShiftsProfileWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ShiftsProfileWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ShiftsProfileWhere"] | undefined | null
};
	["EmployeeQueueItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	start?: ValueTypes["StringCondition"] | undefined | null,
	end?: ValueTypes["StringCondition"] | undefined | null,
	queue?: ValueTypes["EmployeeQueueWhere"] | undefined | null,
	shiftsProfile?: ValueTypes["ShiftsProfileWhere"] | undefined | null,
	shift?: ValueTypes["TimetableShiftWhere"] | undefined | null,
	unassignInfo?: ValueTypes["UnassignedShiftInfoWhere"] | undefined | null,
	and?: Array<ValueTypes["EmployeeQueueItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["EmployeeQueueItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null
};
	["EmployeeQueueWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	shiftGroup?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null,
	items?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,
	contemberBugFix?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["EmployeeQueueWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["EmployeeQueueWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["EmployeeQueueWhere"] | undefined | null
};
	["UnassignedShiftInfoWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	hoursMissed?: ValueTypes["IntCondition"] | undefined | null,
	queueItem?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,
	legacyReason?: ValueTypes["UnassignedInfoReasonCondition"] | undefined | null,
	reason?: ValueTypes["UnassignReasonWhere"] | undefined | null,
	and?: Array<ValueTypes["UnassignedShiftInfoWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["UnassignedShiftInfoWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["UnassignedShiftInfoWhere"] | undefined | null
};
	["UnassignedInfoReasonCondition"]: {
	and?: Array<ValueTypes["UnassignedInfoReasonCondition"]> | undefined | null,
	or?: Array<ValueTypes["UnassignedInfoReasonCondition"]> | undefined | null,
	not?: ValueTypes["UnassignedInfoReasonCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["UnassignedInfoReason"] | undefined | null,
	notEq?: ValueTypes["UnassignedInfoReason"] | undefined | null,
	in?: Array<ValueTypes["UnassignedInfoReason"]> | undefined | null,
	notIn?: Array<ValueTypes["UnassignedInfoReason"]> | undefined | null,
	lt?: ValueTypes["UnassignedInfoReason"] | undefined | null,
	lte?: ValueTypes["UnassignedInfoReason"] | undefined | null,
	gt?: ValueTypes["UnassignedInfoReason"] | undefined | null,
	gte?: ValueTypes["UnassignedInfoReason"] | undefined | null
};
	["UnassignedInfoReason"]:UnassignedInfoReason;
	["UnassignReasonWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	venues?: ValueTypes["VenueWhere"] | undefined | null,
	and?: Array<ValueTypes["UnassignReasonWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["UnassignReasonWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["UnassignReasonWhere"] | undefined | null
};
	["StaffWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	shiftsProfile?: ValueTypes["ShiftsProfileWhere"] | undefined | null,
	photo?: ValueTypes["ImageWhere"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	venues?: ValueTypes["VenueWhere"] | undefined | null,
	and?: Array<ValueTypes["StaffWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["StaffWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["StaffWhere"] | undefined | null
};
	["TimetableEmployeeRoleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	shiftGroups?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null,
	shiftsProfiles?: ValueTypes["ShiftsProfileWhere"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	timetableTemplates?: ValueTypes["TimetableTemplateWhere"] | undefined | null,
	color?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null
};
	["TimetableTemplateWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	startDate?: ValueTypes["DateCondition"] | undefined | null,
	period?: ValueTypes["IntCondition"] | undefined | null,
	slots?: ValueTypes["IntCondition"] | undefined | null,
	position?: ValueTypes["TimetableShiftPositionWhere"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	venue?: ValueTypes["VenueWhere"] | undefined | null,
	employeeRole?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null,
	workdaysOnly?: ValueTypes["BooleanCondition"] | undefined | null,
	monday?: ValueTypes["BooleanCondition"] | undefined | null,
	tuesday?: ValueTypes["BooleanCondition"] | undefined | null,
	wednesday?: ValueTypes["BooleanCondition"] | undefined | null,
	thursday?: ValueTypes["BooleanCondition"] | undefined | null,
	friday?: ValueTypes["BooleanCondition"] | undefined | null,
	saturday?: ValueTypes["BooleanCondition"] | undefined | null,
	sunday?: ValueTypes["BooleanCondition"] | undefined | null,
	type?: ValueTypes["TemplateTypeCondition"] | undefined | null,
	and?: Array<ValueTypes["TimetableTemplateWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TimetableTemplateWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TimetableTemplateWhere"] | undefined | null
};
	["TimetableShiftPositionWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	start?: ValueTypes["StringCondition"] | undefined | null,
	end?: ValueTypes["StringCondition"] | undefined | null,
	shiftGroups?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null,
	timetableTemplates?: ValueTypes["TimetableTemplateWhere"] | undefined | null,
	isDynamic?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["TimetableShiftPositionWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TimetableShiftPositionWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TimetableShiftPositionWhere"] | undefined | null
};
	["TemplateTypeCondition"]: {
	and?: Array<ValueTypes["TemplateTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["TemplateTypeCondition"]> | undefined | null,
	not?: ValueTypes["TemplateTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["TemplateType"] | undefined | null,
	notEq?: ValueTypes["TemplateType"] | undefined | null,
	in?: Array<ValueTypes["TemplateType"]> | undefined | null,
	notIn?: Array<ValueTypes["TemplateType"]> | undefined | null,
	lt?: ValueTypes["TemplateType"] | undefined | null,
	lte?: ValueTypes["TemplateType"] | undefined | null,
	gt?: ValueTypes["TemplateType"] | undefined | null,
	gte?: ValueTypes["TemplateType"] | undefined | null
};
	["TemplateType"]:TemplateType;
	["TimetableDayNoteWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	day?: ValueTypes["TimetableDayWhere"] | undefined | null,
	role?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null,
	and?: Array<ValueTypes["TimetableDayNoteWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TimetableDayNoteWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TimetableDayNoteWhere"] | undefined | null
};
	["TimetableDayMetadataWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	numberOfWorkers?: ValueTypes["IntCondition"] | undefined | null,
	tipsTotalCents?: ValueTypes["IntCondition"] | undefined | null,
	totalMinutes?: ValueTypes["IntCondition"] | undefined | null,
	tipsCentsPerMinute?: ValueTypes["FloatCondition"] | undefined | null,
	day?: ValueTypes["TimetableDayWhere"] | undefined | null,
	and?: Array<ValueTypes["TimetableDayMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TimetableDayMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TimetableDayMetadataWhere"] | undefined | null
};
	["VenueEmployeeRoleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	employeePrivileges?: ValueTypes["TimetablesEmployeePrivilegesWhere"] | undefined | null,
	venue?: ValueTypes["VenueWhere"] | undefined | null,
	role?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null,
	getsTipsShare?: ValueTypes["BooleanCondition"] | undefined | null,
	notesEnabled?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["VenueEmployeeRoleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["VenueEmployeeRoleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["VenueEmployeeRoleWhere"] | undefined | null
};
	["TimetablesEmployeePrivilegesWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	venueEmployeeRole?: ValueTypes["VenueEmployeeRoleWhere"] | undefined | null,
	read?: ValueTypes["BooleanCondition"] | undefined | null,
	unassign?: ValueTypes["BooleanCondition"] | undefined | null,
	assign?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["TimetablesEmployeePrivilegesWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TimetablesEmployeePrivilegesWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TimetablesEmployeePrivilegesWhere"] | undefined | null
};
	["FulfillmentNoteWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	products?: ValueTypes["ProductWhere"] | undefined | null,
	tags?: ValueTypes["TagWhere"] | undefined | null,
	venues?: ValueTypes["VenueWhere"] | undefined | null,
	orderItems?: ValueTypes["OrderItemWhere"] | undefined | null,
	orders?: ValueTypes["OrderWhere"] | undefined | null,
	highlight?: ValueTypes["BooleanCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	emoji?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["FulfillmentNoteWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["FulfillmentNoteWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["FulfillmentNoteWhere"] | undefined | null
};
	["PointOfSaleInitialViewCondition"]: {
	and?: Array<ValueTypes["PointOfSaleInitialViewCondition"]> | undefined | null,
	or?: Array<ValueTypes["PointOfSaleInitialViewCondition"]> | undefined | null,
	not?: ValueTypes["PointOfSaleInitialViewCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["PointOfSaleInitialView"] | undefined | null,
	notEq?: ValueTypes["PointOfSaleInitialView"] | undefined | null,
	in?: Array<ValueTypes["PointOfSaleInitialView"]> | undefined | null,
	notIn?: Array<ValueTypes["PointOfSaleInitialView"]> | undefined | null,
	lt?: ValueTypes["PointOfSaleInitialView"] | undefined | null,
	lte?: ValueTypes["PointOfSaleInitialView"] | undefined | null,
	gt?: ValueTypes["PointOfSaleInitialView"] | undefined | null,
	gte?: ValueTypes["PointOfSaleInitialView"] | undefined | null
};
	["PointOfSaleInitialView"]:PointOfSaleInitialView;
	["OrderDeliveryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	priceCents?: ValueTypes["IntCondition"] | undefined | null,
	deliveredAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	method?: ValueTypes["DeliveryMethodWhere"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	order?: ValueTypes["OrderWhere"] | undefined | null,
	expectedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	state?: ValueTypes["OrderDeliveryStateCondition"] | undefined | null,
	loadingDurationHours?: ValueTypes["IntCondition"] | undefined | null,
	dispatchAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	duration?: ValueTypes["StringCondition"] | undefined | null,
	window?: ValueTypes["StringCondition"] | undefined | null,
	expectedEndAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	processingAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	deliveringAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	failedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	deliveryWaypoint?: ValueTypes["DeliveryWaypointWhere"] | undefined | null,
	prepareAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	pickedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	address?: ValueTypes["AddressWhere"] | undefined | null,
	trackingCode?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["OrderDeliveryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderDeliveryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderDeliveryWhere"] | undefined | null
};
	["OrderDeliveryStateCondition"]: {
	and?: Array<ValueTypes["OrderDeliveryStateCondition"]> | undefined | null,
	or?: Array<ValueTypes["OrderDeliveryStateCondition"]> | undefined | null,
	not?: ValueTypes["OrderDeliveryStateCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["OrderDeliveryState"] | undefined | null,
	notEq?: ValueTypes["OrderDeliveryState"] | undefined | null,
	in?: Array<ValueTypes["OrderDeliveryState"]> | undefined | null,
	notIn?: Array<ValueTypes["OrderDeliveryState"]> | undefined | null,
	lt?: ValueTypes["OrderDeliveryState"] | undefined | null,
	lte?: ValueTypes["OrderDeliveryState"] | undefined | null,
	gt?: ValueTypes["OrderDeliveryState"] | undefined | null,
	gte?: ValueTypes["OrderDeliveryState"] | undefined | null
};
	["OrderDeliveryState"]:OrderDeliveryState;
	["DeliveryWaypointWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	estimatedArrival?: ValueTypes["DateTimeCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	route?: ValueTypes["DeliveryRouteWhere"] | undefined | null,
	sortingOrder?: ValueTypes["IntCondition"] | undefined | null,
	delivery?: ValueTypes["OrderDeliveryWhere"] | undefined | null,
	estimatedDistance?: ValueTypes["IntCondition"] | undefined | null,
	estimatedDuration?: ValueTypes["IntCondition"] | undefined | null,
	originDeparture?: ValueTypes["DateTimeCondition"] | undefined | null,
	originAddress?: ValueTypes["StringCondition"] | undefined | null,
	transitMode?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["DeliveryWaypointWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryWaypointWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryWaypointWhere"] | undefined | null
};
	["DeliveryRouteWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	waypoints?: ValueTypes["DeliveryWaypointWhere"] | undefined | null,
	dispatchAt?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["DeliveryRouteWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryRouteWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryRouteWhere"] | undefined | null
};
	["OrderPaymentWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	data?: ValueTypes["JsonCondition"] | undefined | null,
	paymentCents?: ValueTypes["IntCondition"] | undefined | null,
	method?: ValueTypes["PaymentMethodWhere"] | undefined | null,
	failedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	state?: ValueTypes["OrderPaymentStateCondition"] | undefined | null,
	approvedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	order?: ValueTypes["OrderWhere"] | undefined | null,
	walletType?: ValueTypes["StringCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	publicReceiptUrl?: ValueTypes["StringCondition"] | undefined | null,
	priceCents?: ValueTypes["IntCondition"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	otherCurrencyPriceCents?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["OrderPaymentWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderPaymentWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderPaymentWhere"] | undefined | null
};
	["JsonCondition"]: {
	and?: Array<ValueTypes["JsonCondition"]> | undefined | null,
	or?: Array<ValueTypes["JsonCondition"]> | undefined | null,
	not?: ValueTypes["JsonCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null
};
	["OrderPaymentStateCondition"]: {
	and?: Array<ValueTypes["OrderPaymentStateCondition"]> | undefined | null,
	or?: Array<ValueTypes["OrderPaymentStateCondition"]> | undefined | null,
	not?: ValueTypes["OrderPaymentStateCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["OrderPaymentState"] | undefined | null,
	notEq?: ValueTypes["OrderPaymentState"] | undefined | null,
	in?: Array<ValueTypes["OrderPaymentState"]> | undefined | null,
	notIn?: Array<ValueTypes["OrderPaymentState"]> | undefined | null,
	lt?: ValueTypes["OrderPaymentState"] | undefined | null,
	lte?: ValueTypes["OrderPaymentState"] | undefined | null,
	gt?: ValueTypes["OrderPaymentState"] | undefined | null,
	gte?: ValueTypes["OrderPaymentState"] | undefined | null
};
	["OrderPaymentState"]:OrderPaymentState;
	["ProductMetadataWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	archived?: ValueTypes["BooleanCondition"] | undefined | null,
	available?: ValueTypes["BooleanCondition"] | undefined | null,
	availableQuantity?: ValueTypes["IntCondition"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductMetadataWhere"] | undefined | null
};
	["CustomerNameWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	nominative?: ValueTypes["StringCondition"] | undefined | null,
	vocative?: ValueTypes["StringCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	and?: Array<ValueTypes["CustomerNameWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CustomerNameWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CustomerNameWhere"] | undefined | null
};
	["CustomerFamiliarityCondition"]: {
	and?: Array<ValueTypes["CustomerFamiliarityCondition"]> | undefined | null,
	or?: Array<ValueTypes["CustomerFamiliarityCondition"]> | undefined | null,
	not?: ValueTypes["CustomerFamiliarityCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["CustomerFamiliarity"] | undefined | null,
	notEq?: ValueTypes["CustomerFamiliarity"] | undefined | null,
	in?: Array<ValueTypes["CustomerFamiliarity"]> | undefined | null,
	notIn?: Array<ValueTypes["CustomerFamiliarity"]> | undefined | null,
	lt?: ValueTypes["CustomerFamiliarity"] | undefined | null,
	lte?: ValueTypes["CustomerFamiliarity"] | undefined | null,
	gt?: ValueTypes["CustomerFamiliarity"] | undefined | null,
	gte?: ValueTypes["CustomerFamiliarity"] | undefined | null
};
	["CustomerFamiliarity"]:CustomerFamiliarity;
	["CustomerMetadataWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	isBusiness?: ValueTypes["BooleanCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	fallbackName?: ValueTypes["StringCondition"] | undefined | null,
	remainingCreditsCents?: ValueTypes["IntCondition"] | undefined | null,
	ordersTotalCents?: ValueTypes["IntCondition"] | undefined | null,
	customerProfileUrl?: ValueTypes["StringCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	and?: Array<ValueTypes["CustomerMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CustomerMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CustomerMetadataWhere"] | undefined | null
};
	["CountryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["CountryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CountryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CountryWhere"] | undefined | null
};
	["AddressOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	firstName?: ValueTypes["OrderDirection"] | undefined | null,
	lastName?: ValueTypes["OrderDirection"] | undefined | null,
	addressLine2?: ValueTypes["OrderDirection"] | undefined | null,
	email?: ValueTypes["OrderDirection"] | undefined | null,
	phone?: ValueTypes["OrderDirection"] | undefined | null,
	companyName?: ValueTypes["OrderDirection"] | undefined | null,
	companyIdentifier?: ValueTypes["OrderDirection"] | undefined | null,
	vatIdentifier?: ValueTypes["OrderDirection"] | undefined | null,
	externalIdentifier?: ValueTypes["OrderDirection"] | undefined | null,
	hidden?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	country?: ValueTypes["CountryOrderBy"] | undefined | null,
	deletedAt?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	googlePlaceId?: ValueTypes["OrderDirection"] | undefined | null,
	raw?: ValueTypes["OrderDirection"] | undefined | null,
	formatted?: ValueTypes["OrderDirection"] | undefined | null,
	geocodeGeneratedAt?: ValueTypes["OrderDirection"] | undefined | null,
	geocodeResponse?: ValueTypes["OrderDirection"] | undefined | null,
	deliveryZone?: ValueTypes["DeliveryZoneOrderBy"] | undefined | null,
	geocodeValid?: ValueTypes["OrderDirection"] | undefined | null,
	fullName?: ValueTypes["OrderDirection"] | undefined | null,
	fakturoidSubjectId?: ValueTypes["OrderDirection"] | undefined | null,
	addressLine1?: ValueTypes["OrderDirection"] | undefined | null,
	city?: ValueTypes["OrderDirection"] | undefined | null,
	postalCode?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["CustomerOrderBy"] | undefined | null
};
	["OrderDirection"]:OrderDirection;
	["CustomerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	defaultBillingAddress?: ValueTypes["AddressOrderBy"] | undefined | null,
	photo?: ValueTypes["ImageOrderBy"] | undefined | null,
	phone?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["CustomerNameOrderBy"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	welcomeMessage?: ValueTypes["OrderDirection"] | undefined | null,
	familiarity?: ValueTypes["OrderDirection"] | undefined | null,
	disabledAt?: ValueTypes["OrderDirection"] | undefined | null,
	email?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	defaultDeliveryAddress?: ValueTypes["AddressOrderBy"] | undefined | null,
	prefersPackingsWithoutCardboard?: ValueTypes["OrderDirection"] | undefined | null,
	doubleshotLegacyId?: ValueTypes["OrderDirection"] | undefined | null,
	meta?: ValueTypes["CustomerMetadataOrderBy"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["OrderDirection"] | undefined | null
};
	["ImageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	url?: ValueTypes["OrderDirection"] | undefined | null,
	width?: ValueTypes["OrderDirection"] | undefined | null,
	height?: ValueTypes["OrderDirection"] | undefined | null,
	size?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	alt?: ValueTypes["OrderDirection"] | undefined | null
};
	["CustomerNameOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	nominative?: ValueTypes["OrderDirection"] | undefined | null,
	vocative?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null
};
	["CustomerMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	isBusiness?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	fallbackName?: ValueTypes["OrderDirection"] | undefined | null,
	remainingCreditsCents?: ValueTypes["OrderDirection"] | undefined | null,
	ordersTotalCents?: ValueTypes["OrderDirection"] | undefined | null,
	customerProfileUrl?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null
};
	["CountryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null
};
	["DeliveryZoneOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null
};
	["Tag"]: AliasType<{
	_meta?:ValueTypes["TagMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
customers?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Customer"]],
products?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
categories?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategory"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
addTagsOnRegistration?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
deliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryZone"]],
publicInChannels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
fulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FulfillmentNote"]],
paginateCustomers?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerConnection"]],
paginateProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateCategories?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryConnection"]],
paginateAddTagsOnRegistration?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
paginateDeliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryZoneConnection"]],
paginatePublicInChannels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FulfillmentNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TagMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	customers?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	categories?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	addTagsOnRegistration?:ValueTypes["FieldMeta"],
	deliveryZones?:ValueTypes["FieldMeta"],
	publicInChannels?:ValueTypes["FieldMeta"],
	fulfillmentNotes?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Product"]: AliasType<{
	_meta?:ValueTypes["ProductMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
	notDiscountable?:boolean | `@${string}`,
packing?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null},ValueTypes["ProductPacking"]],
recipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
tags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
categories?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategory"]],
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
stocks?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductStock"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	availability?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
orderItems?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderItem"]],
	maximumQuantityPerOrder?:boolean | `@${string}`,
deliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryZone"]],
locales?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductLocale"]],
	archivedAt?:boolean | `@${string}`,
virtualProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null},ValueTypes["VirtualProduct"]],
meta?: [{	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null},ValueTypes["ProductMetadata"]],
page?: [{	filter?: ValueTypes["PageWhere"] | undefined | null},ValueTypes["Page"]],
fulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FulfillmentNote"]],
	hasSubscription?:boolean | `@${string}`,
	pointOfSaleTileColor?:boolean | `@${string}`,
stocksByStore?: [{	by: ValueTypes["ProductStocksByStoreUniqueWhere"],	filter?: ValueTypes["ProductStockWhere"] | undefined | null},ValueTypes["ProductStock"]],
localesByLocale?: [{	by: ValueTypes["ProductLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
localesByFeatureList?: [{	by: ValueTypes["ProductLocalesByFeatureListUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
paginateTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginateCategories?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryConnection"]],
paginateStocks?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductStockConnection"]],
paginateOrderItems?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderItemConnection"]],
paginateDeliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryZoneConnection"]],
paginateLocales?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductLocaleConnection"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FulfillmentNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	priceCents?:ValueTypes["FieldMeta"],
	notDiscountable?:ValueTypes["FieldMeta"],
	packing?:ValueTypes["FieldMeta"],
	recipe?:ValueTypes["FieldMeta"],
	tags?:ValueTypes["FieldMeta"],
	categories?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	stocks?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	availability?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	orderItems?:ValueTypes["FieldMeta"],
	maximumQuantityPerOrder?:ValueTypes["FieldMeta"],
	deliveryZones?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	archivedAt?:ValueTypes["FieldMeta"],
	virtualProduct?:ValueTypes["FieldMeta"],
	meta?:ValueTypes["FieldMeta"],
	page?:ValueTypes["FieldMeta"],
	fulfillmentNotes?:ValueTypes["FieldMeta"],
	hasSubscription?:ValueTypes["FieldMeta"],
	pointOfSaleTileColor?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPacking"]: AliasType<{
	_meta?:ValueTypes["ProductPackingMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	volumeMl?:boolean | `@${string}`,
icon?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	shortName?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPackingLocale"]],
preparedPackingQuantities?: [{	filter?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PreparedPackingQuantityOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PreparedPackingQuantity"]],
	isPackedInCardboard?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["ProductPackingLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null},ValueTypes["ProductPackingLocale"]],
paginateLocales?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingLocaleConnection"]],
paginatePreparedPackingQuantities?: [{	filter?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PreparedPackingQuantityOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PreparedPackingQuantityConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	volumeMl?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
	shortName?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	preparedPackingQuantities?:ValueTypes["FieldMeta"],
	isPackedInCardboard?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Image"]: AliasType<{
	_meta?:ValueTypes["ImageMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ImageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	alt?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingLocale"]: AliasType<{
	_meta?:ValueTypes["ProductPackingLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	shortName?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null},ValueTypes["ProductPacking"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	shortName?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Locale"]: AliasType<{
	_meta?:ValueTypes["LocaleMeta"],
	id?:boolean | `@${string}`,
businessCategories?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategoryLocale"]],
productCategoryLists?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListLocale"]],
productIngredients?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientLocale"]],
orderRecurrences?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
blogs?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogLocale"]],
blogPosts?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostLocale"]],
identifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
pages?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PageLocale"]],
productIngredientCategories?: [{	filter?: ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientCategoryLocale"]],
allergens?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AllergenLocale"]],
productPackings?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPackingLocale"]],
products?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductLocale"]],
productRecipes?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipeLocale"]],
deliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethodLocale"]],
ingredientSuppliers?: [{	filter?: ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IngredientSupplierLocale"]],
identificationCodes?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IdentificationCodeLocale"]],
subscriptionBoxes?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
businessCategoriesByRoot?: [{	by: ValueTypes["LocaleBusinessCategoriesByRootUniqueWhere"],	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null},ValueTypes["BusinessCategoryLocale"]],
productCategoryListsByRoot?: [{	by: ValueTypes["LocaleProductCategoryListsByRootUniqueWhere"],	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null},ValueTypes["ProductCategoryListLocale"]],
productIngredientsByRoot?: [{	by: ValueTypes["LocaleProductIngredientsByRootUniqueWhere"],	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientLocale"]],
orderRecurrencesByRoot?: [{	by: ValueTypes["LocaleOrderRecurrencesByRootUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
blogsByRoot?: [{	by: ValueTypes["LocaleBlogsByRootUniqueWhere"],	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null},ValueTypes["BlogLocale"]],
blogPostsByRoot?: [{	by: ValueTypes["LocaleBlogPostsByRootUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
blogPostsByCover?: [{	by: ValueTypes["LocaleBlogPostsByCoverUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
blogPostsByContent?: [{	by: ValueTypes["LocaleBlogPostsByContentUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
blogPostsByLink?: [{	by: ValueTypes["LocaleBlogPostsByLinkUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
blogPostsBySeo?: [{	by: ValueTypes["LocaleBlogPostsBySeoUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
pagesByRoot?: [{	by: ValueTypes["LocalePagesByRootUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
pagesByLink?: [{	by: ValueTypes["LocalePagesByLinkUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
pagesBySeo?: [{	by: ValueTypes["LocalePagesBySeoUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
pagesByContent?: [{	by: ValueTypes["LocalePagesByContentUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
pagesByCover?: [{	by: ValueTypes["LocalePagesByCoverUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
productIngredientCategoriesByRoot?: [{	by: ValueTypes["LocaleProductIngredientCategoriesByRootUniqueWhere"],	filter?: ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientCategoryLocale"]],
allergensByRoot?: [{	by: ValueTypes["LocaleAllergensByRootUniqueWhere"],	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null},ValueTypes["AllergenLocale"]],
productPackingsByRoot?: [{	by: ValueTypes["LocaleProductPackingsByRootUniqueWhere"],	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null},ValueTypes["ProductPackingLocale"]],
productsByRoot?: [{	by: ValueTypes["LocaleProductsByRootUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
productsByFeatureList?: [{	by: ValueTypes["LocaleProductsByFeatureListUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
productRecipesByRoot?: [{	by: ValueTypes["LocaleProductRecipesByRootUniqueWhere"],	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null},ValueTypes["ProductRecipeLocale"]],
deliveryMethodsByRoot?: [{	by: ValueTypes["LocaleDeliveryMethodsByRootUniqueWhere"],	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null},ValueTypes["DeliveryMethodLocale"]],
ingredientSuppliersByRoot?: [{	by: ValueTypes["LocaleIngredientSuppliersByRootUniqueWhere"],	filter?: ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null},ValueTypes["IngredientSupplierLocale"]],
identificationCodesByRoot?: [{	by: ValueTypes["LocaleIdentificationCodesByRootUniqueWhere"],	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null},ValueTypes["IdentificationCodeLocale"]],
subscriptionBoxesByRoot?: [{	by: ValueTypes["LocaleSubscriptionBoxesByRootUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
subscriptionBoxesByTexts?: [{	by: ValueTypes["LocaleSubscriptionBoxesByTextsUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
subscriptionBoxesBySecondaryTexts?: [{	by: ValueTypes["LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
paginateBusinessCategories?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryLocaleConnection"]],
paginateProductCategoryLists?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListLocaleConnection"]],
paginateProductIngredients?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientLocaleConnection"]],
paginateOrderRecurrences?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceLocaleConnection"]],
paginateBlogs?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogLocaleConnection"]],
paginateBlogPosts?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostLocaleConnection"]],
paginatePages?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageLocaleConnection"]],
paginateProductIngredientCategories?: [{	filter?: ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientCategoryLocaleConnection"]],
paginateAllergens?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenLocaleConnection"]],
paginateProductPackings?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingLocaleConnection"]],
paginateProducts?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductLocaleConnection"]],
paginateProductRecipes?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeLocaleConnection"]],
paginateDeliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodLocaleConnection"]],
paginateIngredientSuppliers?: [{	filter?: ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IngredientSupplierLocaleConnection"]],
paginateIdentificationCodes?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IdentificationCodeLocaleConnection"]],
paginateSubscriptionBoxes?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SubscriptionBoxLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	businessCategories?:ValueTypes["FieldMeta"],
	productCategoryLists?:ValueTypes["FieldMeta"],
	productIngredients?:ValueTypes["FieldMeta"],
	orderRecurrences?:ValueTypes["FieldMeta"],
	blogs?:ValueTypes["FieldMeta"],
	blogPosts?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	pages?:ValueTypes["FieldMeta"],
	productIngredientCategories?:ValueTypes["FieldMeta"],
	allergens?:ValueTypes["FieldMeta"],
	productPackings?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	productRecipes?:ValueTypes["FieldMeta"],
	deliveryMethods?:ValueTypes["FieldMeta"],
	ingredientSuppliers?:ValueTypes["FieldMeta"],
	identificationCodes?:ValueTypes["FieldMeta"],
	subscriptionBoxes?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryLocale"]: AliasType<{
	_meta?:ValueTypes["BusinessCategoryLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategory"]: AliasType<{
	_meta?:ValueTypes["BusinessCategoryMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	visibleToNewCustomers?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategoryLocale"]],
	internalName?:boolean | `@${string}`,
recurrences?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrence"]],
specificProducts?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null},ValueTypes["ProductList"]],
seasonalProductCategories?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
specificProductsProductCategories?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
	slug?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["BusinessCategoryLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null},ValueTypes["BusinessCategoryLocale"]],
paginateLocales?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryLocaleConnection"]],
paginateRecurrences?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	visibleToNewCustomers?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	recurrences?:ValueTypes["FieldMeta"],
	specificProducts?:ValueTypes["FieldMeta"],
	seasonalProductCategories?:ValueTypes["FieldMeta"],
	specificProductsProductCategories?:ValueTypes["FieldMeta"],
	slug?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["BusinessCategoryOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null
};
	["BusinessCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	visibleToNewCustomers?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	specificProducts?: ValueTypes["ProductListOrderBy"] | undefined | null,
	seasonalProductCategories?: ValueTypes["ProductCategoryListOrderBy"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["ProductCategoryListOrderBy"] | undefined | null,
	slug?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	businessCategory?: ValueTypes["BusinessCategoryOrderBy"] | undefined | null
};
	["ProductCategoryListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryOrderBy"] | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryOrderBy"] | undefined | null
};
	["LocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierOrderBy"] | undefined | null
};
	["TranslationCataloguesIdentifierOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null
};
	["OrderRecurrence"]: AliasType<{
	_meta?:ValueTypes["OrderRecurrenceMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
	order?:boolean | `@${string}`,
businessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategory"]],
localesByLocale?: [{	by: ValueTypes["OrderRecurrenceLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
paginateLocales?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceLocaleConnection"]],
paginateBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	businessCategory?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceLocale"]: AliasType<{
	_meta?:ValueTypes["OrderRecurrenceLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null},ValueTypes["OrderRecurrence"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["OrderRecurrenceOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["OrderRecurrenceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null
};
	["OrderRecurrenceLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["LocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: ValueTypes["BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	productCategoryLists?: ValueTypes["ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	productIngredients?: ValueTypes["ProductIngredientLocaleUniqueWhere"] | undefined | null,
	orderRecurrences?: ValueTypes["OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	blogs?: ValueTypes["BlogLocaleUniqueWhere"] | undefined | null,
	blogPosts?: ValueTypes["BlogPostLocaleUniqueWhere"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null,
	pages?: ValueTypes["PageLocaleUniqueWhere"] | undefined | null,
	productIngredientCategories?: ValueTypes["ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null,
	allergens?: ValueTypes["AllergenLocaleUniqueWhere"] | undefined | null,
	productPackings?: ValueTypes["ProductPackingLocaleUniqueWhere"] | undefined | null,
	products?: ValueTypes["ProductLocaleUniqueWhere"] | undefined | null,
	productRecipes?: ValueTypes["ProductRecipeLocaleUniqueWhere"] | undefined | null,
	deliveryMethods?: ValueTypes["DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	ingredientSuppliers?: ValueTypes["IngredientSupplierLocaleUniqueWhere"] | undefined | null,
	identificationCodes?: ValueTypes["IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	subscriptionBoxes?: ValueTypes["SubscriptionBoxLocaleUniqueWhere"] | undefined | null
};
	["BusinessCategoryLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["BusinessCategoryUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["BusinessCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	slug?: string | undefined | null,
	locales?: ValueTypes["BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	specificProducts?: ValueTypes["ProductListUniqueWhere"] | undefined | null,
	seasonalProductCategories?: ValueTypes["ProductCategoryListUniqueWhere"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["ProductCategoryListUniqueWhere"] | undefined | null
};
	["ProductListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["ProductListItemUniqueWhere"] | undefined | null,
	businessCategory?: ValueTypes["BusinessCategoryUniqueWhere"] | undefined | null
};
	["ProductListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ProductCategoryListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["ProductCategoryListItemUniqueWhere"] | undefined | null,
	locales?: ValueTypes["ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryUniqueWhere"] | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryUniqueWhere"] | undefined | null
};
	["ProductCategoryListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ProductCategoryListLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductCategoryListUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductIngredientLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductIngredientUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductIngredientUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["ProductIngredientLocaleUniqueWhere"] | undefined | null,
	suppliers?: ValueTypes["IngredientSupplierListUniqueWhere"] | undefined | null
};
	["IngredientSupplierListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["IngredientSupplierItemUniqueWhere"] | undefined | null
};
	["IngredientSupplierItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["OrderRecurrenceLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["OrderRecurrenceUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["OrderRecurrenceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["OrderRecurrenceLocaleUniqueWhere"] | undefined | null
};
	["BlogLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["BlogUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["BlogUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["One"] | undefined | null,
	locales?: ValueTypes["BlogLocaleUniqueWhere"] | undefined | null
};
	["BlogPostLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["BlogPostUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null,
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null
};
	["BlogPostUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["BlogPostLocaleUniqueWhere"] | undefined | null
};
	["CoverUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ContentUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	blocks?: ValueTypes["ContentBlockUniqueWhere"] | undefined | null
};
	["ContentBlockUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	references?: ValueTypes["ContentReferenceUniqueWhere"] | undefined | null
};
	["ContentReferenceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	products?: ValueTypes["ProductListUniqueWhere"] | undefined | null,
	productBanners?: ValueTypes["ProductBannerListUniqueWhere"] | undefined | null,
	deliveryRegions?: ValueTypes["DeliveryRegionsUniqueWhere"] | undefined | null
};
	["ProductBannerListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["ProductBannerItemUniqueWhere"] | undefined | null
};
	["ProductBannerItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["DeliveryRegionsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["DeliveryRegionsItemUniqueWhere"] | undefined | null
};
	["DeliveryRegionsItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null,
	textList?: ValueTypes["TextListUniqueWhere"] | undefined | null,
	gallery?: ValueTypes["ImageListUniqueWhere"] | undefined | null
};
	["ImageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["TextListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["TextItemUniqueWhere"] | undefined | null
};
	["TextItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ImageListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["ImageListItemUniqueWhere"] | undefined | null
};
	["ImageListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null
};
	["LinkableUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	url?: string | undefined | null,
	blogPost?: ValueTypes["BlogPostLocaleUniqueWhere"] | undefined | null,
	page?: ValueTypes["PageLocaleUniqueWhere"] | undefined | null,
	redirect?: ValueTypes["RedirectUniqueWhere"] | undefined | null
};
	["PageLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["PageUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null,
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null
};
	["PageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["PageLocaleUniqueWhere"] | undefined | null
};
	["SeoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	ogImage?: ValueTypes["ImageUniqueWhere"] | undefined | null
};
	["RedirectUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	catalogue?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null
};
	["TranslationCatalogueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	domain?: ValueTypes["TranslationDomainUniqueWhere"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null,
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationDomainUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	identifier?: string | undefined | null,
	catalogues?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null,
	keys?: ValueTypes["TranslationKeyUniqueWhere"] | undefined | null
};
	["TranslationKeyUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	domain?: ValueTypes["TranslationDomainUniqueWhere"] | undefined | null,
	identifier?: string | undefined | null,
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationValueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	catalogue?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null,
	key?: ValueTypes["TranslationKeyUniqueWhere"] | undefined | null
};
	["ProductIngredientCategoryLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductIngredientCategoryUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductIngredientCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null
};
	["AllergenLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["AllergenUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["AllergenUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: number | undefined | null,
	locales?: ValueTypes["AllergenLocaleUniqueWhere"] | undefined | null
};
	["ProductPackingLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductPackingUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductPackingUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["ProductPackingLocaleUniqueWhere"] | undefined | null,
	preparedPackingQuantities?: ValueTypes["PreparedPackingQuantityUniqueWhere"] | undefined | null
};
	["PreparedPackingQuantityUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	date?: ValueTypes["Date"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeUniqueWhere"] | undefined | null,
	packing?: ValueTypes["ProductPackingUniqueWhere"] | undefined | null,
	packedInCardboard?: boolean | undefined | null
};
	["ProductRecipeUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	products?: ValueTypes["ProductUniqueWhere"] | undefined | null,
	ingredients?: ValueTypes["ProductIngredientItemUniqueWhere"] | undefined | null,
	locales?: ValueTypes["ProductRecipeLocaleUniqueWhere"] | undefined | null
};
	["ProductUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	stocks?: ValueTypes["ProductStockUniqueWhere"] | undefined | null,
	orderItems?: ValueTypes["OrderItemUniqueWhere"] | undefined | null,
	locales?: ValueTypes["ProductLocaleUniqueWhere"] | undefined | null,
	virtualProduct?: ValueTypes["VirtualProductUniqueWhere"] | undefined | null,
	meta?: ValueTypes["ProductMetadataUniqueWhere"] | undefined | null,
	page?: ValueTypes["PageUniqueWhere"] | undefined | null
};
	["ProductStockUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	product?: ValueTypes["ProductUniqueWhere"] | undefined | null,
	store?: ValueTypes["StoreUniqueWhere"] | undefined | null
};
	["StoreUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null
};
	["OrderItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ProductLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null,
	featureList?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["VirtualProductUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	product?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["ProductMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	product?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["ProductIngredientItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	quantity?: ValueTypes["ProductIngredientItemQuantityUniqueWhere"] | undefined | null
};
	["ProductIngredientItemQuantityUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ProductRecipeLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductRecipeUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["DeliveryMethodLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null,
	root?: ValueTypes["DeliveryMethodUniqueWhere"] | undefined | null
};
	["DeliveryMethodUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	locales?: ValueTypes["DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	timelinePreset?: ValueTypes["DeliveryTimelinePresetUniqueWhere"] | undefined | null
};
	["DeliveryTimelinePresetUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	deliveryMethod?: ValueTypes["DeliveryMethodUniqueWhere"] | undefined | null
};
	["IngredientSupplierLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["IngredientSupplierUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["IngredientSupplierUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["IngredientSupplierLocaleUniqueWhere"] | undefined | null
};
	["IdentificationCodeLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["IdentificationCodeUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["IdentificationCodeUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	attempts?: ValueTypes["IdentificationAttemptUniqueWhere"] | undefined | null,
	locales?: ValueTypes["IdentificationCodeLocaleUniqueWhere"] | undefined | null
};
	["IdentificationAttemptUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["SubscriptionBoxLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["SubscriptionBoxUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null,
	texts?: ValueTypes["TextListUniqueWhere"] | undefined | null,
	secondaryTexts?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["SubscriptionBoxUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["One"] | undefined | null,
	locales?: ValueTypes["SubscriptionBoxLocaleUniqueWhere"] | undefined | null
};
	["OrderRecurrenceLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderRecurrenceLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PageInfo"]: AliasType<{
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceLocaleEdge"]: AliasType<{
	node?:ValueTypes["OrderRecurrenceLocale"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BusinessCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryEdge"]: AliasType<{
	node?:ValueTypes["BusinessCategory"],
		__typename?: boolean | `@${string}`
}>;
	["ProductList"]: AliasType<{
	_meta?:ValueTypes["ProductListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductListItem"]],
businessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
paginateItems?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	businessCategory?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListItem"]: AliasType<{
	_meta?:ValueTypes["ProductListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null},ValueTypes["ProductList"]],
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
	defaultQuantityForOrder?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	defaultQuantityForOrder?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["ProductListOrderBy"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null,
	defaultQuantityForOrder?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["OrderDirection"] | undefined | null,
	notDiscountable?: ValueTypes["OrderDirection"] | undefined | null,
	packing?: ValueTypes["ProductPackingOrderBy"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeOrderBy"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	availability?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	maximumQuantityPerOrder?: ValueTypes["OrderDirection"] | undefined | null,
	archivedAt?: ValueTypes["OrderDirection"] | undefined | null,
	virtualProduct?: ValueTypes["VirtualProductOrderBy"] | undefined | null,
	meta?: ValueTypes["ProductMetadataOrderBy"] | undefined | null,
	page?: ValueTypes["PageOrderBy"] | undefined | null,
	hasSubscription?: ValueTypes["OrderDirection"] | undefined | null,
	pointOfSaleTileColor?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductPackingOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	volumeMl?: ValueTypes["OrderDirection"] | undefined | null,
	icon?: ValueTypes["ImageOrderBy"] | undefined | null,
	shortName?: ValueTypes["OrderDirection"] | undefined | null,
	isPackedInCardboard?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductRecipeOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	color?: ValueTypes["OrderDirection"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	icon?: ValueTypes["ImageOrderBy"] | undefined | null,
	needsPrepping?: ValueTypes["OrderDirection"] | undefined | null,
	gutTuning?: ValueTypes["OrderDirection"] | undefined | null,
	freshingContainer?: ValueTypes["FreshingContainerOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null
};
	["FreshingContainerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	volumeMl?: ValueTypes["OrderDirection"] | undefined | null,
	workspace?: ValueTypes["WorkspaceOrderBy"] | undefined | null
};
	["WorkspaceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	unique?: ValueTypes["OrderDirection"] | undefined | null,
	orderDeadlineTime?: ValueTypes["OrderDirection"] | undefined | null,
	ordersLoadingDurationHours?: ValueTypes["OrderDirection"] | undefined | null,
	freshingContainerVolumeMl?: ValueTypes["OrderDirection"] | undefined | null,
	freshingPrepareOrderByOffsetMinutes?: ValueTypes["OrderDirection"] | undefined | null,
	checkoutUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	orderUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	paymentUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	customerProfileUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	userProfileUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	messengerUrl?: ValueTypes["OrderDirection"] | undefined | null,
	messengerIosUrl?: ValueTypes["OrderDirection"] | undefined | null,
	messengerAndroidUrl?: ValueTypes["OrderDirection"] | undefined | null,
	openingHours?: ValueTypes["WeekHoursOrderBy"] | undefined | null,
	workingHours?: ValueTypes["WeekHoursOrderBy"] | undefined | null,
	defaultFreshingContainer?: ValueTypes["FreshingContainerOrderBy"] | undefined | null,
	creditsProduct?: ValueTypes["VirtualProductOrderBy"] | undefined | null,
	discountVatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	businessAddress?: ValueTypes["OrderDirection"] | undefined | null
};
	["WeekHoursOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	mon?: ValueTypes["OrderDirection"] | undefined | null,
	tue?: ValueTypes["OrderDirection"] | undefined | null,
	wed?: ValueTypes["OrderDirection"] | undefined | null,
	thu?: ValueTypes["OrderDirection"] | undefined | null,
	fri?: ValueTypes["OrderDirection"] | undefined | null,
	sat?: ValueTypes["OrderDirection"] | undefined | null,
	sun?: ValueTypes["OrderDirection"] | undefined | null
};
	["VirtualProductOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null,
	voucherCreditCents?: ValueTypes["OrderDirection"] | undefined | null,
	physicalRepresentation?: ValueTypes["OrderDirection"] | undefined | null,
	recipientEmail?: ValueTypes["OrderDirection"] | undefined | null
};
	["VatRateOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	ratePermille?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	preset?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	archived?: ValueTypes["OrderDirection"] | undefined | null,
	available?: ValueTypes["OrderDirection"] | undefined | null,
	availableQuantity?: ValueTypes["OrderDirection"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null
};
	["PageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListItemEdge"]: AliasType<{
	node?:ValueTypes["ProductListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryList"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListItem"]],
locales?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListLocale"]],
seasonalProductCategoriesOfBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
specificProductsProductCategoriesOfBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
localesByLocale?: [{	by: ValueTypes["ProductCategoryListLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null},ValueTypes["ProductCategoryListLocale"]],
paginateItems?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListItemConnection"]],
paginateLocales?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	seasonalProductCategoriesOfBusinessCategory?:ValueTypes["FieldMeta"],
	specificProductsProductCategoriesOfBusinessCategory?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListItem"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
productCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
	showRecipeImages?:boolean | `@${string}`,
	showPackingImages?:boolean | `@${string}`,
	showAllAvailableProducts?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	productCategory?:ValueTypes["FieldMeta"],
	showRecipeImages?:ValueTypes["FieldMeta"],
	showPackingImages?:ValueTypes["FieldMeta"],
	showAllAvailableProducts?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategory"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
products?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
tags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
parent?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
children?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategory"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	order?:boolean | `@${string}`,
childrenByCode?: [{	by: ValueTypes["ProductCategoryChildrenByCodeUniqueWhere"],	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
childrenByChildren?: [{	by: ValueTypes["ProductCategoryChildrenByChildrenUniqueWhere"],	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
paginateProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginateChildren?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	tags?:ValueTypes["FieldMeta"],
	parent?:ValueTypes["FieldMeta"],
	children?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	color?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TagOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	parent?: ValueTypes["ProductCategoryOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	color?: ValueTypes["OrderDirection"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductCategoryChildrenByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["ProductCategoryChildrenByChildrenUniqueWhere"]: {
	children?: ValueTypes["ProductCategoryUniqueWhere"] | undefined | null
};
	["ProductCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	children?: ValueTypes["ProductCategoryUniqueWhere"] | undefined | null
};
	["ProductConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductEdge"]: AliasType<{
	node?:ValueTypes["Product"],
		__typename?: boolean | `@${string}`
}>;
	["TagConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TagEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TagEdge"]: AliasType<{
	node?:ValueTypes["Tag"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryEdge"]: AliasType<{
	node?:ValueTypes["ProductCategory"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["ProductCategoryListOrderBy"] | undefined | null,
	productCategory?: ValueTypes["ProductCategoryOrderBy"] | undefined | null,
	showRecipeImages?: ValueTypes["OrderDirection"] | undefined | null,
	showPackingImages?: ValueTypes["OrderDirection"] | undefined | null,
	showAllAvailableProducts?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductCategoryListLocale"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryListLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductCategoryListOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["ProductCategoryListLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductCategoryListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListItemEdge"]: AliasType<{
	node?:ValueTypes["ProductCategoryListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryListLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductCategoryListLocale"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["BusinessCategoryLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BusinessCategoryLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryLocaleEdge"]: AliasType<{
	node?:ValueTypes["BusinessCategoryLocale"],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderRecurrenceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceEdge"]: AliasType<{
	node?:ValueTypes["OrderRecurrence"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientLocale"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null},ValueTypes["ProductIngredient"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredient"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientMeta"],
	id?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientLocale"]],
	internalName?:boolean | `@${string}`,
categories?: [{	filter?: ValueTypes["ProductIngredientCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientCategory"]],
allergens?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Allergen"]],
	needsPrepping?:boolean | `@${string}`,
	allowCustomerRating?:boolean | `@${string}`,
preferredQuantityUnit?: [{	filter?: ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null},ValueTypes["ProductIngredientItemQuantityUnit"]],
freshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null},ValueTypes["FreshingContainer"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
suppliers?: [{	filter?: ValueTypes["IngredientSupplierListWhere"] | undefined | null},ValueTypes["IngredientSupplierList"]],
	incrementQuantityNumber?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["ProductIngredientLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientLocale"]],
paginateLocales?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientLocaleConnection"]],
paginateCategories?: [{	filter?: ValueTypes["ProductIngredientCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientCategoryConnection"]],
paginateAllergens?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	categories?:ValueTypes["FieldMeta"],
	allergens?:ValueTypes["FieldMeta"],
	needsPrepping?:ValueTypes["FieldMeta"],
	allowCustomerRating?:ValueTypes["FieldMeta"],
	preferredQuantityUnit?:ValueTypes["FieldMeta"],
	freshingContainer?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	suppliers?:ValueTypes["FieldMeta"],
	incrementQuantityNumber?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductIngredientOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["ProductIngredientOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	needsPrepping?: ValueTypes["OrderDirection"] | undefined | null,
	allowCustomerRating?: ValueTypes["OrderDirection"] | undefined | null,
	preferredQuantityUnit?: ValueTypes["ProductIngredientItemQuantityUnitOrderBy"] | undefined | null,
	freshingContainer?: ValueTypes["FreshingContainerOrderBy"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	suppliers?: ValueTypes["IngredientSupplierListOrderBy"] | undefined | null,
	incrementQuantityNumber?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductIngredientItemQuantityUnitOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null
};
	["IngredientSupplierListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductIngredientCategory"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientCategoryMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
ingredients?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredient"]],
locales?: [{	filter?: ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientCategoryLocale"]],
	order?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["ProductIngredientCategoryLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientCategoryLocale"]],
paginateIngredients?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientConnection"]],
paginateLocales?: [{	filter?: ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientCategoryLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientCategoryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	ingredients?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientCategoryLocale"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientCategoryLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductIngredientCategoryWhere"] | undefined | null},ValueTypes["ProductIngredientCategory"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientCategoryLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientCategoryLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductIngredientCategoryOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["ProductIngredientCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductIngredientCategoryLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductIngredientConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredient"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientCategoryLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientCategoryLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientCategoryLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredientCategoryLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Allergen"]: AliasType<{
	_meta?:ValueTypes["AllergenMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AllergenLocale"]],
ingredients?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredient"]],
	code?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["AllergenLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null},ValueTypes["AllergenLocale"]],
paginateLocales?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenLocaleConnection"]],
paginateIngredients?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["AllergenMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	ingredients?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AllergenLocale"]: AliasType<{
	_meta?:ValueTypes["AllergenLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null},ValueTypes["Allergen"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["AllergenLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AllergenLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["AllergenOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["AllergenOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null
};
	["AllergenLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["AllergenLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AllergenLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AllergenLocaleEdge"]: AliasType<{
	node?:ValueTypes["AllergenLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemQuantityUnit"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientItemQuantityUnitMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemQuantityUnitMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FreshingContainer"]: AliasType<{
	_meta?:ValueTypes["FreshingContainerMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	volumeMl?:boolean | `@${string}`,
workspace?: [{	filter?: ValueTypes["WorkspaceWhere"] | undefined | null},ValueTypes["Workspace"]],
		__typename?: boolean | `@${string}`
}>;
	["FreshingContainerMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	volumeMl?:ValueTypes["FieldMeta"],
	workspace?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Workspace"]: AliasType<{
	_meta?:ValueTypes["WorkspaceMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
	orderDeadlineTime?:boolean | `@${string}`,
	ordersLoadingDurationHours?:boolean | `@${string}`,
	freshingContainerVolumeMl?:boolean | `@${string}`,
	freshingPrepareOrderByOffsetMinutes?:boolean | `@${string}`,
	checkoutUrlTemplate?:boolean | `@${string}`,
	orderUrlTemplate?:boolean | `@${string}`,
	paymentUrlTemplate?:boolean | `@${string}`,
	customerProfileUrlTemplate?:boolean | `@${string}`,
	userProfileUrlTemplate?:boolean | `@${string}`,
	messengerUrl?:boolean | `@${string}`,
	messengerIosUrl?:boolean | `@${string}`,
	messengerAndroidUrl?:boolean | `@${string}`,
freshingContainers?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FreshingContainer"]],
openingHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null},ValueTypes["WeekHours"]],
workingHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null},ValueTypes["WeekHours"]],
defaultFreshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null},ValueTypes["FreshingContainer"]],
creditsProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null},ValueTypes["VirtualProduct"]],
discountVatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
	name?:boolean | `@${string}`,
	businessAddress?:boolean | `@${string}`,
paginateFreshingContainers?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FreshingContainerConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	orderDeadlineTime?:ValueTypes["FieldMeta"],
	ordersLoadingDurationHours?:ValueTypes["FieldMeta"],
	freshingContainerVolumeMl?:ValueTypes["FieldMeta"],
	freshingPrepareOrderByOffsetMinutes?:ValueTypes["FieldMeta"],
	checkoutUrlTemplate?:ValueTypes["FieldMeta"],
	orderUrlTemplate?:ValueTypes["FieldMeta"],
	paymentUrlTemplate?:ValueTypes["FieldMeta"],
	customerProfileUrlTemplate?:ValueTypes["FieldMeta"],
	userProfileUrlTemplate?:ValueTypes["FieldMeta"],
	messengerUrl?:ValueTypes["FieldMeta"],
	messengerIosUrl?:ValueTypes["FieldMeta"],
	messengerAndroidUrl?:ValueTypes["FieldMeta"],
	freshingContainers?:ValueTypes["FieldMeta"],
	openingHours?:ValueTypes["FieldMeta"],
	workingHours?:ValueTypes["FieldMeta"],
	defaultFreshingContainer?:ValueTypes["FieldMeta"],
	creditsProduct?:ValueTypes["FieldMeta"],
	discountVatRate?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	businessAddress?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["WeekHours"]: AliasType<{
	_meta?:ValueTypes["WeekHoursMeta"],
	id?:boolean | `@${string}`,
	mon?:boolean | `@${string}`,
	tue?:boolean | `@${string}`,
	wed?:boolean | `@${string}`,
	thu?:boolean | `@${string}`,
	fri?:boolean | `@${string}`,
	sat?:boolean | `@${string}`,
	sun?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WeekHoursMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	mon?:ValueTypes["FieldMeta"],
	tue?:ValueTypes["FieldMeta"],
	wed?:ValueTypes["FieldMeta"],
	thu?:ValueTypes["FieldMeta"],
	fri?:ValueTypes["FieldMeta"],
	sat?:ValueTypes["FieldMeta"],
	sun?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["VirtualProduct"]: AliasType<{
	_meta?:ValueTypes["VirtualProductMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
	voucherCreditCents?:boolean | `@${string}`,
	physicalRepresentation?:boolean | `@${string}`,
	recipientEmail?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["VirtualProductMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	voucherCreditCents?:ValueTypes["FieldMeta"],
	physicalRepresentation?:ValueTypes["FieldMeta"],
	recipientEmail?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["VatRate"]: AliasType<{
	_meta?:ValueTypes["VatRateMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	ratePermille?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	preset?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["VatRateMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	ratePermille?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	preset?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FreshingContainerConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FreshingContainerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FreshingContainerEdge"]: AliasType<{
	node?:ValueTypes["FreshingContainer"],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplierList"]: AliasType<{
	_meta?:ValueTypes["IngredientSupplierListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["IngredientSupplierItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IngredientSupplierItem"]],
paginateItems?: [{	filter?: ValueTypes["IngredientSupplierItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IngredientSupplierItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplierListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplierItem"]: AliasType<{
	_meta?:ValueTypes["IngredientSupplierItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["IngredientSupplierListWhere"] | undefined | null},ValueTypes["IngredientSupplierList"]],
supplier?: [{	filter?: ValueTypes["IngredientSupplierWhere"] | undefined | null},ValueTypes["IngredientSupplier"]],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplierItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	supplier?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplier"]: AliasType<{
	_meta?:ValueTypes["IngredientSupplierMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IngredientSupplierLocale"]],
localesByLocale?: [{	by: ValueTypes["IngredientSupplierLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null},ValueTypes["IngredientSupplierLocale"]],
paginateLocales?: [{	filter?: ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IngredientSupplierLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplierMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplierLocale"]: AliasType<{
	_meta?:ValueTypes["IngredientSupplierLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["IngredientSupplierWhere"] | undefined | null},ValueTypes["IngredientSupplier"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplierLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplierLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["IngredientSupplierOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["IngredientSupplierOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null
};
	["IngredientSupplierLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["IngredientSupplierLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IngredientSupplierLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplierLocaleEdge"]: AliasType<{
	node?:ValueTypes["IngredientSupplierLocale"],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplierItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["IngredientSupplierListOrderBy"] | undefined | null,
	supplier?: ValueTypes["IngredientSupplierOrderBy"] | undefined | null
};
	["IngredientSupplierItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IngredientSupplierItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplierItemEdge"]: AliasType<{
	node?:ValueTypes["IngredientSupplierItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductIngredientLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredientLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientCategoryEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredientCategory"],
		__typename?: boolean | `@${string}`
}>;
	["AllergenConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AllergenEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AllergenEdge"]: AliasType<{
	node?:ValueTypes["Allergen"],
		__typename?: boolean | `@${string}`
}>;
	["BlogLocale"]: AliasType<{
	_meta?:ValueTypes["BlogLocaleMeta"],
	id?:boolean | `@${string}`,
	pageName?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	lead?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["BlogWhere"] | undefined | null},ValueTypes["Blog"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	pageName?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	lead?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Blog"]: AliasType<{
	_meta?:ValueTypes["BlogMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogLocale"]],
localesByLocale?: [{	by: ValueTypes["BlogLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null},ValueTypes["BlogLocale"]],
paginateLocales?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	pageName?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	lead?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["BlogOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["BlogOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	unique?: ValueTypes["OrderDirection"] | undefined | null
};
	["BlogLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["BlogLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogLocaleEdge"]: AliasType<{
	node?:ValueTypes["BlogLocale"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocale"]: AliasType<{
	_meta?:ValueTypes["BlogPostLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	availableForWeb?:boolean | `@${string}`,
	availableForMobile?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null},ValueTypes["BlogPost"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
cover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null},ValueTypes["Cover"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null},ValueTypes["Seo"]],
products?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
paginateProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	availableForWeb?:ValueTypes["FieldMeta"],
	availableForMobile?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	cover?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPost"]: AliasType<{
	_meta?:ValueTypes["BlogPostMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostLocale"]],
localesByLocale?: [{	by: ValueTypes["BlogPostLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
localesByCover?: [{	by: ValueTypes["BlogPostLocalesByCoverUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
localesByContent?: [{	by: ValueTypes["BlogPostLocalesByContentUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
localesByLink?: [{	by: ValueTypes["BlogPostLocalesByLinkUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
localesBySeo?: [{	by: ValueTypes["BlogPostLocalesBySeoUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
paginateLocales?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	availableForWeb?: ValueTypes["OrderDirection"] | undefined | null,
	availableForMobile?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["BlogPostOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	cover?: ValueTypes["CoverOrderBy"] | undefined | null,
	content?: ValueTypes["ContentOrderBy"] | undefined | null,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null
};
	["BlogPostOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null
};
	["CoverOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	primaryTitle?: ValueTypes["OrderDirection"] | undefined | null,
	secondaryTitle?: ValueTypes["OrderDirection"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null,
	buttonColors?: ValueTypes["ColorPalleteOrderBy"] | undefined | null
};
	["MediumOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	colorTheme?: ValueTypes["OrderDirection"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	video?: ValueTypes["VideoOrderBy"] | undefined | null
};
	["VideoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	src?: ValueTypes["OrderDirection"] | undefined | null,
	width?: ValueTypes["OrderDirection"] | undefined | null,
	height?: ValueTypes["OrderDirection"] | undefined | null,
	size?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	duration?: ValueTypes["OrderDirection"] | undefined | null,
	poster?: ValueTypes["ImageOrderBy"] | undefined | null
};
	["ColorPalleteOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	background?: ValueTypes["OrderDirection"] | undefined | null,
	text?: ValueTypes["OrderDirection"] | undefined | null
};
	["ContentOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["LinkableOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	url?: ValueTypes["OrderDirection"] | undefined | null,
	blogPost?: ValueTypes["BlogPostLocaleOrderBy"] | undefined | null,
	page?: ValueTypes["PageLocaleOrderBy"] | undefined | null,
	redirect?: ValueTypes["RedirectOrderBy"] | undefined | null
};
	["PageLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null,
	root?: ValueTypes["PageOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null,
	content?: ValueTypes["ContentOrderBy"] | undefined | null,
	cover?: ValueTypes["CoverOrderBy"] | undefined | null,
	theme?: ValueTypes["OrderDirection"] | undefined | null
};
	["SeoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	ogTitle?: ValueTypes["OrderDirection"] | undefined | null,
	ogDescription?: ValueTypes["OrderDirection"] | undefined | null,
	ogImage?: ValueTypes["ImageOrderBy"] | undefined | null
};
	["RedirectOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null,
	target?: ValueTypes["LinkOrderBy"] | undefined | null
};
	["LinkOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	externalLink?: ValueTypes["OrderDirection"] | undefined | null,
	internalLink?: ValueTypes["LinkableOrderBy"] | undefined | null
};
	["BlogPostLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["BlogPostLocalesByCoverUniqueWhere"]: {
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null
};
	["BlogPostLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null
};
	["BlogPostLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["BlogPostLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null
};
	["BlogPostLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocaleEdge"]: AliasType<{
	node?:ValueTypes["BlogPostLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Cover"]: AliasType<{
	_meta?:ValueTypes["CoverMeta"],
	id?:boolean | `@${string}`,
	primaryTitle?:boolean | `@${string}`,
	secondaryTitle?:boolean | `@${string}`,
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
buttonColors?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null},ValueTypes["ColorPallete"]],
		__typename?: boolean | `@${string}`
}>;
	["CoverMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	primaryTitle?:ValueTypes["FieldMeta"],
	secondaryTitle?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
	buttonColors?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Medium"]: AliasType<{
	_meta?:ValueTypes["MediumMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	colorTheme?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
video?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null},ValueTypes["Video"]],
		__typename?: boolean | `@${string}`
}>;
	["MediumMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	colorTheme?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	video?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Video"]: AliasType<{
	_meta?:ValueTypes["VideoMeta"],
	id?:boolean | `@${string}`,
	src?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
poster?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["VideoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	src?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	duration?:ValueTypes["FieldMeta"],
	poster?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ColorPallete"]: AliasType<{
	_meta?:ValueTypes["ColorPalleteMeta"],
	id?:boolean | `@${string}`,
	background?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ColorPalleteMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	background?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Content"]: AliasType<{
	_meta?:ValueTypes["ContentMeta"],
	id?:boolean | `@${string}`,
blocks?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentBlock"]],
blocksByReferences?: [{	by: ValueTypes["ContentBlocksByReferencesUniqueWhere"],	filter?: ValueTypes["ContentBlockWhere"] | undefined | null},ValueTypes["ContentBlock"]],
paginateBlocks?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentBlockConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	blocks?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlock"]: AliasType<{
	_meta?:ValueTypes["ContentBlockMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	json?:boolean | `@${string}`,
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
references?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentReference"]],
referencesByProducts?: [{	by: ValueTypes["ContentBlockReferencesByProductsUniqueWhere"],	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null},ValueTypes["ContentReference"]],
referencesByProductBanners?: [{	by: ValueTypes["ContentBlockReferencesByProductBannersUniqueWhere"],	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null},ValueTypes["ContentReference"]],
referencesByDeliveryRegions?: [{	by: ValueTypes["ContentBlockReferencesByDeliveryRegionsUniqueWhere"],	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null},ValueTypes["ContentReference"]],
paginateReferences?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentReferenceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	json?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	references?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReference"]: AliasType<{
	_meta?:ValueTypes["ContentReferenceMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	primaryText?:boolean | `@${string}`,
	secondaryText?:boolean | `@${string}`,
	jsonContent?:boolean | `@${string}`,
block?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null},ValueTypes["ContentBlock"]],
colorPallete?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null},ValueTypes["ColorPallete"]],
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
blogPosts?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null},ValueTypes["BlogPostList"]],
links?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null},ValueTypes["LinkList"]],
products?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null},ValueTypes["ProductList"]],
	contentSize?:boolean | `@${string}`,
	misc?:boolean | `@${string}`,
hero?: [{	filter?: ValueTypes["HeroWhere"] | undefined | null},ValueTypes["Hero"]],
gallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null},ValueTypes["Gallery"]],
socialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null},ValueTypes["SocialsAndApps"]],
productGrid?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null},ValueTypes["ProductGrid"]],
productBanners?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null},ValueTypes["ProductBannerList"]],
deliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null},ValueTypes["DeliveryRegions"]],
textList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
gridTiles?: [{	filter?: ValueTypes["GridTilesWhere"] | undefined | null},ValueTypes["GridTiles"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	primaryText?:ValueTypes["FieldMeta"],
	secondaryText?:ValueTypes["FieldMeta"],
	jsonContent?:ValueTypes["FieldMeta"],
	block?:ValueTypes["FieldMeta"],
	colorPallete?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	blogPosts?:ValueTypes["FieldMeta"],
	links?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	contentSize?:ValueTypes["FieldMeta"],
	misc?:ValueTypes["FieldMeta"],
	hero?:ValueTypes["FieldMeta"],
	gallery?:ValueTypes["FieldMeta"],
	socialsAndApps?:ValueTypes["FieldMeta"],
	productGrid?:ValueTypes["FieldMeta"],
	productBanners?:ValueTypes["FieldMeta"],
	deliveryRegions?:ValueTypes["FieldMeta"],
	textList?:ValueTypes["FieldMeta"],
	gridTiles?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Link"]: AliasType<{
	_meta?:ValueTypes["LinkMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	externalLink?:boolean | `@${string}`,
internalLink?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	externalLink?:ValueTypes["FieldMeta"],
	internalLink?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Linkable"]: AliasType<{
	_meta?:ValueTypes["LinkableMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
blogPost?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
page?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
redirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null},ValueTypes["Redirect"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkableMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	blogPost?:ValueTypes["FieldMeta"],
	page?:ValueTypes["FieldMeta"],
	redirect?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PageLocale"]: AliasType<{
	_meta?:ValueTypes["PageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
root?: [{	filter?: ValueTypes["PageWhere"] | undefined | null},ValueTypes["Page"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null},ValueTypes["Seo"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
cover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null},ValueTypes["Cover"]],
	theme?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PageLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	cover?:ValueTypes["FieldMeta"],
	theme?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Page"]: AliasType<{
	_meta?:ValueTypes["PageMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PageLocale"]],
localesByLocale?: [{	by: ValueTypes["PageLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
localesByLink?: [{	by: ValueTypes["PageLocalesByLinkUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
localesBySeo?: [{	by: ValueTypes["PageLocalesBySeoUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
localesByContent?: [{	by: ValueTypes["PageLocalesByContentUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
localesByCover?: [{	by: ValueTypes["PageLocalesByCoverUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
paginateLocales?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PageLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["PageLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["PageLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null
};
	["PageLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null
};
	["PageLocalesByCoverUniqueWhere"]: {
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null
};
	["PageLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PageLocaleEdge"]: AliasType<{
	node?:ValueTypes["PageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Seo"]: AliasType<{
	_meta?:ValueTypes["SeoMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	ogTitle?:boolean | `@${string}`,
	ogDescription?:boolean | `@${string}`,
ogImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["SeoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	ogTitle?:ValueTypes["FieldMeta"],
	ogDescription?:ValueTypes["FieldMeta"],
	ogImage?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Redirect"]: AliasType<{
	_meta?:ValueTypes["RedirectMeta"],
	id?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
target?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["RedirectMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	target?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostList"]: AliasType<{
	_meta?:ValueTypes["BlogPostListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostListItem"]],
paginateItems?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListItem"]: AliasType<{
	_meta?:ValueTypes["BlogPostListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	caption?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null},ValueTypes["BlogPostList"]],
blogPost?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	caption?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	blogPost?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	caption?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["BlogPostListOrderBy"] | undefined | null,
	blogPost?: ValueTypes["BlogPostLocaleOrderBy"] | undefined | null
};
	["BlogPostListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["BlogPostListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListItemEdge"]: AliasType<{
	node?:ValueTypes["BlogPostListItem"],
		__typename?: boolean | `@${string}`
}>;
	["LinkList"]: AliasType<{
	_meta?:ValueTypes["LinkListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LinkListItem"]],
paginateItems?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItem"]: AliasType<{
	_meta?:ValueTypes["LinkListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	isPrimary?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null},ValueTypes["LinkList"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	isPrimary?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	isPrimary?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["LinkListOrderBy"] | undefined | null,
	link?: ValueTypes["LinkOrderBy"] | undefined | null
};
	["LinkListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["LinkListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItemEdge"]: AliasType<{
	node?:ValueTypes["LinkListItem"],
		__typename?: boolean | `@${string}`
}>;
	["Hero"]: AliasType<{
	_meta?:ValueTypes["HeroMeta"],
	id?:boolean | `@${string}`,
	showLogo?:boolean | `@${string}`,
	middleContent?:boolean | `@${string}`,
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
		__typename?: boolean | `@${string}`
}>;
	["HeroMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	showLogo?:ValueTypes["FieldMeta"],
	middleContent?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Gallery"]: AliasType<{
	_meta?:ValueTypes["GalleryMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GalleryItem"]],
paginateItems?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GalleryItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GalleryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItem"]: AliasType<{
	_meta?:ValueTypes["GalleryItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	caption?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null},ValueTypes["Gallery"]],
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	caption?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	caption?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["GalleryOrderBy"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null
};
	["GalleryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["GalleryItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GalleryItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItemEdge"]: AliasType<{
	node?:ValueTypes["GalleryItem"],
		__typename?: boolean | `@${string}`
}>;
	["SocialsAndApps"]: AliasType<{
	_meta?:ValueTypes["SocialsAndAppsMeta"],
	id?:boolean | `@${string}`,
	instagram?:boolean | `@${string}`,
	appStore?:boolean | `@${string}`,
	googlePlay?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SocialsAndAppsMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	instagram?:ValueTypes["FieldMeta"],
	appStore?:ValueTypes["FieldMeta"],
	googlePlay?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGrid"]: AliasType<{
	_meta?:ValueTypes["ProductGridMeta"],
	id?:boolean | `@${string}`,
	isCarousel?:boolean | `@${string}`,
categories?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridCategory"]],
	type?:boolean | `@${string}`,
categoriesByItems?: [{	by: ValueTypes["ProductGridCategoriesByItemsUniqueWhere"],	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null},ValueTypes["ProductGridCategory"]],
categoriesByImages?: [{	by: ValueTypes["ProductGridCategoriesByImagesUniqueWhere"],	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null},ValueTypes["ProductGridCategory"]],
paginateCategories?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridCategoryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	isCarousel?:ValueTypes["FieldMeta"],
	categories?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridCategory"]: AliasType<{
	_meta?:ValueTypes["ProductGridCategoryMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null},ValueTypes["ProductGrid"]],
items?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridItem"]],
images?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null},ValueTypes["ImageList"]],
paginateItems?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridCategoryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	images?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridItem"]: AliasType<{
	_meta?:ValueTypes["ProductGridItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	lead?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
category?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null},ValueTypes["ProductGridCategory"]],
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductGridItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	lead?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	category?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	lead?: ValueTypes["OrderDirection"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null,
	link?: ValueTypes["LinkOrderBy"] | undefined | null,
	category?: ValueTypes["ProductGridCategoryOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductGridCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductGridOrderBy"] | undefined | null,
	images?: ValueTypes["ImageListOrderBy"] | undefined | null
};
	["ProductGridOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	isCarousel?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null
};
	["ImageListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["ImageList"]: AliasType<{
	_meta?:ValueTypes["ImageListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ImageListItem"]],
itemsByImage?: [{	by: ValueTypes["ImageListItemsByImageUniqueWhere"],	filter?: ValueTypes["ImageListItemWhere"] | undefined | null},ValueTypes["ImageListItem"]],
paginateItems?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItem"]: AliasType<{
	_meta?:ValueTypes["ImageListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null},ValueTypes["ImageList"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["ImageListOrderBy"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null
};
	["ImageListItemsByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null
};
	["ImageListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemEdge"]: AliasType<{
	node?:ValueTypes["ImageListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductGridItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridItemEdge"]: AliasType<{
	node?:ValueTypes["ProductGridItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridCategoriesByItemsUniqueWhere"]: {
	items?: ValueTypes["ProductGridItemUniqueWhere"] | undefined | null
};
	["ProductGridItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ProductGridCategoriesByImagesUniqueWhere"]: {
	images?: ValueTypes["ImageListUniqueWhere"] | undefined | null
};
	["ProductGridCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductGridCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridCategoryEdge"]: AliasType<{
	node?:ValueTypes["ProductGridCategory"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerList"]: AliasType<{
	_meta?:ValueTypes["ProductBannerListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBannerItem"]],
paginateItems?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerItem"]: AliasType<{
	_meta?:ValueTypes["ProductBannerItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null},ValueTypes["ProductBannerList"]],
banner?: [{	filter?: ValueTypes["ProductBannerWhere"] | undefined | null},ValueTypes["ProductBanner"]],
	size?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	banner?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBanner"]: AliasType<{
	_meta?:ValueTypes["ProductBannerMeta"],
	id?:boolean | `@${string}`,
	beforeTitle?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
button?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
backgroundImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
productImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	beforeTitle?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	label?:ValueTypes["FieldMeta"],
	button?:ValueTypes["FieldMeta"],
	backgroundImage?:ValueTypes["FieldMeta"],
	productImage?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["ProductBannerListOrderBy"] | undefined | null,
	banner?: ValueTypes["ProductBannerOrderBy"] | undefined | null,
	size?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductBannerListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductBannerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	beforeTitle?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	label?: ValueTypes["OrderDirection"] | undefined | null,
	button?: ValueTypes["LinkOrderBy"] | undefined | null,
	backgroundImage?: ValueTypes["ImageOrderBy"] | undefined | null,
	productImage?: ValueTypes["ImageOrderBy"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null
};
	["ProductBannerItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductBannerItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerItemEdge"]: AliasType<{
	node?:ValueTypes["ProductBannerItem"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegions"]: AliasType<{
	_meta?:ValueTypes["DeliveryRegionsMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRegionsItem"]],
itemsByImage?: [{	by: ValueTypes["DeliveryRegionsItemsByImageUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["DeliveryRegionsItem"]],
itemsByTextList?: [{	by: ValueTypes["DeliveryRegionsItemsByTextListUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["DeliveryRegionsItem"]],
itemsByGallery?: [{	by: ValueTypes["DeliveryRegionsItemsByGalleryUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["DeliveryRegionsItem"]],
paginateItems?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRegionsItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsItem"]: AliasType<{
	_meta?:ValueTypes["DeliveryRegionsItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	offerLabel?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null},ValueTypes["DeliveryRegions"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
textList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
gallery?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null},ValueTypes["ImageList"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	offerLabel?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	textList?:ValueTypes["FieldMeta"],
	gallery?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TextList"]: AliasType<{
	_meta?:ValueTypes["TextListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TextItem"]],
paginateItems?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TextItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TextListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TextItem"]: AliasType<{
	_meta?:ValueTypes["TextItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TextItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TextItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	text?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["TextListOrderBy"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null
};
	["TextListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["TextItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TextItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TextItemEdge"]: AliasType<{
	node?:ValueTypes["TextItem"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	offerLabel?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["DeliveryRegionsOrderBy"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	textList?: ValueTypes["TextListOrderBy"] | undefined | null,
	gallery?: ValueTypes["ImageListOrderBy"] | undefined | null
};
	["DeliveryRegionsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["DeliveryRegionsItemsByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null
};
	["DeliveryRegionsItemsByTextListUniqueWhere"]: {
	textList?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["DeliveryRegionsItemsByGalleryUniqueWhere"]: {
	gallery?: ValueTypes["ImageListUniqueWhere"] | undefined | null
};
	["DeliveryRegionsItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryRegionsItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsItemEdge"]: AliasType<{
	node?:ValueTypes["DeliveryRegionsItem"],
		__typename?: boolean | `@${string}`
}>;
	["GridTiles"]: AliasType<{
	_meta?:ValueTypes["GridTilesMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["GridTileItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTileItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GridTileItem"]],
itemsByLink?: [{	by: ValueTypes["GridTilesItemsByLinkUniqueWhere"],	filter?: ValueTypes["GridTileItemWhere"] | undefined | null},ValueTypes["GridTileItem"]],
paginateItems?: [{	filter?: ValueTypes["GridTileItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTileItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GridTileItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GridTilesMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GridTileItem"]: AliasType<{
	_meta?:ValueTypes["GridTileItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["GridTilesWhere"] | undefined | null},ValueTypes["GridTiles"]],
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["GridTileItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GridTileItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	text?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["GridTilesOrderBy"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null,
	link?: ValueTypes["LinkOrderBy"] | undefined | null
};
	["GridTilesOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["GridTilesItemsByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null
};
	["LinkUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["GridTileItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GridTileItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GridTileItemEdge"]: AliasType<{
	node?:ValueTypes["GridTileItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	primaryText?: ValueTypes["OrderDirection"] | undefined | null,
	secondaryText?: ValueTypes["OrderDirection"] | undefined | null,
	jsonContent?: ValueTypes["OrderDirection"] | undefined | null,
	block?: ValueTypes["ContentBlockOrderBy"] | undefined | null,
	colorPallete?: ValueTypes["ColorPalleteOrderBy"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null,
	link?: ValueTypes["LinkOrderBy"] | undefined | null,
	blogPosts?: ValueTypes["BlogPostListOrderBy"] | undefined | null,
	links?: ValueTypes["LinkListOrderBy"] | undefined | null,
	products?: ValueTypes["ProductListOrderBy"] | undefined | null,
	contentSize?: ValueTypes["OrderDirection"] | undefined | null,
	misc?: ValueTypes["OrderDirection"] | undefined | null,
	hero?: ValueTypes["HeroOrderBy"] | undefined | null,
	gallery?: ValueTypes["GalleryOrderBy"] | undefined | null,
	socialsAndApps?: ValueTypes["SocialsAndAppsOrderBy"] | undefined | null,
	productGrid?: ValueTypes["ProductGridOrderBy"] | undefined | null,
	productBanners?: ValueTypes["ProductBannerListOrderBy"] | undefined | null,
	deliveryRegions?: ValueTypes["DeliveryRegionsOrderBy"] | undefined | null,
	textList?: ValueTypes["TextListOrderBy"] | undefined | null,
	gridTiles?: ValueTypes["GridTilesOrderBy"] | undefined | null
};
	["ContentBlockOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	json?: ValueTypes["OrderDirection"] | undefined | null,
	content?: ValueTypes["ContentOrderBy"] | undefined | null
};
	["HeroOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	showLogo?: ValueTypes["OrderDirection"] | undefined | null,
	middleContent?: ValueTypes["OrderDirection"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null
};
	["SocialsAndAppsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	instagram?: ValueTypes["OrderDirection"] | undefined | null,
	appStore?: ValueTypes["OrderDirection"] | undefined | null,
	googlePlay?: ValueTypes["OrderDirection"] | undefined | null
};
	["ContentBlockReferencesByProductsUniqueWhere"]: {
	products?: ValueTypes["ProductListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByProductBannersUniqueWhere"]: {
	productBanners?: ValueTypes["ProductBannerListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByDeliveryRegionsUniqueWhere"]: {
	deliveryRegions?: ValueTypes["DeliveryRegionsUniqueWhere"] | undefined | null
};
	["ContentReferenceConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentReferenceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceEdge"]: AliasType<{
	node?:ValueTypes["ContentReference"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlocksByReferencesUniqueWhere"]: {
	references?: ValueTypes["ContentReferenceUniqueWhere"] | undefined | null
};
	["ContentBlockConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentBlockEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockEdge"]: AliasType<{
	node?:ValueTypes["ContentBlock"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifier"]: AliasType<{
	_meta?:ValueTypes["TranslationCataloguesIdentifierMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
catalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCatalogue"]],
catalogueByDomain?: [{	by: ValueTypes["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
catalogueByValues?: [{	by: ValueTypes["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
paginateCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCatalogueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	catalogue?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogue"]: AliasType<{
	_meta?:ValueTypes["TranslationCatalogueMeta"],
	id?:boolean | `@${string}`,
domain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null},ValueTypes["TranslationDomain"]],
fallback?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
values?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationValue"]],
identifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
valuesByKey?: [{	by: ValueTypes["TranslationCatalogueValuesByKeyUniqueWhere"],	filter?: ValueTypes["TranslationValueWhere"] | undefined | null},ValueTypes["TranslationValue"]],
paginateValues?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	domain?:ValueTypes["FieldMeta"],
	fallback?:ValueTypes["FieldMeta"],
	values?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomain"]: AliasType<{
	_meta?:ValueTypes["TranslationDomainMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
catalogues?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCatalogue"]],
keys?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationKey"]],
cataloguesByIdentifier?: [{	by: ValueTypes["TranslationDomainCataloguesByIdentifierUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
cataloguesByValues?: [{	by: ValueTypes["TranslationDomainCataloguesByValuesUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
keysByIdentifier?: [{	by: ValueTypes["TranslationDomainKeysByIdentifierUniqueWhere"],	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null},ValueTypes["TranslationKey"]],
keysByValues?: [{	by: ValueTypes["TranslationDomainKeysByValuesUniqueWhere"],	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null},ValueTypes["TranslationKey"]],
paginateCatalogues?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCatalogueConnection"]],
paginateKeys?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationKeyConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	catalogues?:ValueTypes["FieldMeta"],
	keys?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	domain?: ValueTypes["TranslationDomainOrderBy"] | undefined | null,
	fallback?: ValueTypes["TranslationCatalogueOrderBy"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierOrderBy"] | undefined | null
};
	["TranslationDomainOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	identifier?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null
};
	["TranslationKey"]: AliasType<{
	_meta?:ValueTypes["TranslationKeyMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
domain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null},ValueTypes["TranslationDomain"]],
values?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationValue"]],
valuesByCatalogue?: [{	by: ValueTypes["TranslationKeyValuesByCatalogueUniqueWhere"],	filter?: ValueTypes["TranslationValueWhere"] | undefined | null},ValueTypes["TranslationValue"]],
paginateValues?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	contentType?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	domain?:ValueTypes["FieldMeta"],
	values?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValue"]: AliasType<{
	_meta?:ValueTypes["TranslationValueMeta"],
	id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
catalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
key?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null},ValueTypes["TranslationKey"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	value?:ValueTypes["FieldMeta"],
	catalogue?:ValueTypes["FieldMeta"],
	key?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	value?: ValueTypes["OrderDirection"] | undefined | null,
	catalogue?: ValueTypes["TranslationCatalogueOrderBy"] | undefined | null,
	key?: ValueTypes["TranslationKeyOrderBy"] | undefined | null
};
	["TranslationKeyOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	identifier?: ValueTypes["OrderDirection"] | undefined | null,
	contentType?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	domain?: ValueTypes["TranslationDomainOrderBy"] | undefined | null
};
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
	catalogue?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null
};
	["TranslationValueConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationValueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueEdge"]: AliasType<{
	node?:ValueTypes["TranslationValue"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
	identifier?: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null
};
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
	identifier?: string | undefined | null
};
	["TranslationDomainKeysByValuesUniqueWhere"]: {
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationCatalogueConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationCatalogueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueEdge"]: AliasType<{
	node?:ValueTypes["TranslationCatalogue"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationKeyEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyEdge"]: AliasType<{
	node?:ValueTypes["TranslationKey"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
	key?: ValueTypes["TranslationKeyUniqueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
	domain?: ValueTypes["TranslationDomainUniqueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["ProductPackingLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	shortName?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductPackingOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["ProductLocale"]: AliasType<{
	_meta?:ValueTypes["ProductLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
featureList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
	printableName?:boolean | `@${string}`,
	firstSticker?:boolean | `@${string}`,
	secondSticker?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	featureList?:ValueTypes["FieldMeta"],
	printableName?:ValueTypes["FieldMeta"],
	firstSticker?:ValueTypes["FieldMeta"],
	secondSticker?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	featureList?: ValueTypes["TextListOrderBy"] | undefined | null,
	printableName?: ValueTypes["OrderDirection"] | undefined | null,
	firstSticker?: ValueTypes["OrderDirection"] | undefined | null,
	secondSticker?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductRecipeLocale"]: AliasType<{
	_meta?:ValueTypes["ProductRecipeLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipe"]: AliasType<{
	_meta?:ValueTypes["ProductRecipeMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
products?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
	internalNote?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
icon?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
ingredients?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientItem"]],
	needsPrepping?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipeLocale"]],
	gutTuning?:boolean | `@${string}`,
freshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null},ValueTypes["FreshingContainer"]],
	description?:boolean | `@${string}`,
productsByCode?: [{	by: ValueTypes["ProductRecipeProductsByCodeUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByStocks?: [{	by: ValueTypes["ProductRecipeProductsByStocksUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByOrderItems?: [{	by: ValueTypes["ProductRecipeProductsByOrderItemsUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByLocales?: [{	by: ValueTypes["ProductRecipeProductsByLocalesUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByVirtualProduct?: [{	by: ValueTypes["ProductRecipeProductsByVirtualProductUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByMeta?: [{	by: ValueTypes["ProductRecipeProductsByMetaUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByPage?: [{	by: ValueTypes["ProductRecipeProductsByPageUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
ingredientsByQuantity?: [{	by: ValueTypes["ProductRecipeIngredientsByQuantityUniqueWhere"],	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null},ValueTypes["ProductIngredientItem"]],
localesByLocale?: [{	by: ValueTypes["ProductRecipeLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null},ValueTypes["ProductRecipeLocale"]],
paginateProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateIngredients?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientItemConnection"]],
paginateLocales?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	color?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
	ingredients?:ValueTypes["FieldMeta"],
	needsPrepping?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	gutTuning?:ValueTypes["FieldMeta"],
	freshingContainer?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItem"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	internalOrder?:boolean | `@${string}`,
recipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
ingredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null},ValueTypes["ProductIngredient"]],
quantity?: [{	filter?: ValueTypes["ProductIngredientItemQuantityWhere"] | undefined | null},ValueTypes["ProductIngredientItemQuantity"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	internalOrder?:ValueTypes["FieldMeta"],
	recipe?:ValueTypes["FieldMeta"],
	ingredient?:ValueTypes["FieldMeta"],
	quantity?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemQuantity"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientItemQuantityMeta"],
	id?:boolean | `@${string}`,
unit?: [{	filter?: ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null},ValueTypes["ProductIngredientItemQuantityUnit"]],
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemQuantityMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unit?:ValueTypes["FieldMeta"],
	value?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	internalOrder?: ValueTypes["OrderDirection"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeOrderBy"] | undefined | null,
	ingredient?: ValueTypes["ProductIngredientOrderBy"] | undefined | null,
	quantity?: ValueTypes["ProductIngredientItemQuantityOrderBy"] | undefined | null
};
	["ProductIngredientItemQuantityOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	unit?: ValueTypes["ProductIngredientItemQuantityUnitOrderBy"] | undefined | null,
	value?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductRecipeLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductRecipeOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductRecipeProductsByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["ProductRecipeProductsByStocksUniqueWhere"]: {
	stocks?: ValueTypes["ProductStockUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByOrderItemsUniqueWhere"]: {
	orderItems?: ValueTypes["OrderItemUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByLocalesUniqueWhere"]: {
	locales?: ValueTypes["ProductLocaleUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByVirtualProductUniqueWhere"]: {
	virtualProduct?: ValueTypes["VirtualProductUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByMetaUniqueWhere"]: {
	meta?: ValueTypes["ProductMetadataUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByPageUniqueWhere"]: {
	page?: ValueTypes["PageUniqueWhere"] | undefined | null
};
	["ProductRecipeIngredientsByQuantityUniqueWhere"]: {
	quantity?: ValueTypes["ProductIngredientItemQuantityUniqueWhere"] | undefined | null
};
	["ProductRecipeLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductIngredientItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredientItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductRecipeLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductRecipeLocale"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocale"]: AliasType<{
	_meta?:ValueTypes["DeliveryMethodLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	trackingCodeUrlTemplate?:boolean | `@${string}`,
	processingTitle?:boolean | `@${string}`,
	processingText?:boolean | `@${string}`,
	processingIconUrl?:boolean | `@${string}`,
	dispatchedTitle?:boolean | `@${string}`,
	dispatchedText?:boolean | `@${string}`,
	dispatchedIconUrl?:boolean | `@${string}`,
	fulfilledTitle?:boolean | `@${string}`,
	fulfilledText?:boolean | `@${string}`,
	fulfilledIconUrl?:boolean | `@${string}`,
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
root?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	trackingCodeUrlTemplate?:ValueTypes["FieldMeta"],
	processingTitle?:ValueTypes["FieldMeta"],
	processingText?:ValueTypes["FieldMeta"],
	processingIconUrl?:ValueTypes["FieldMeta"],
	dispatchedTitle?:ValueTypes["FieldMeta"],
	dispatchedText?:ValueTypes["FieldMeta"],
	dispatchedIconUrl?:ValueTypes["FieldMeta"],
	fulfilledTitle?:ValueTypes["FieldMeta"],
	fulfilledText?:ValueTypes["FieldMeta"],
	fulfilledIconUrl?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethod"]: AliasType<{
	_meta?:ValueTypes["DeliveryMethodMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	enabled?:boolean | `@${string}`,
paymentMethods?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PaymentMethod"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	requireDeliveryAddress?:boolean | `@${string}`,
suitableForDeliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryZone"]],
	enableDeliveryTracking?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethodLocale"]],
timelinePreset?: [{	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null},ValueTypes["DeliveryTimelinePreset"]],
localesByLocale?: [{	by: ValueTypes["DeliveryMethodLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null},ValueTypes["DeliveryMethodLocale"]],
paginatePaymentMethods?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PaymentMethodConnection"]],
paginateSuitableForDeliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryZoneConnection"]],
paginateLocales?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	enabled?:ValueTypes["FieldMeta"],
	paymentMethods?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	requireDeliveryAddress?:ValueTypes["FieldMeta"],
	suitableForDeliveryZones?:ValueTypes["FieldMeta"],
	enableDeliveryTracking?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	timelinePreset?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PaymentMethod"]: AliasType<{
	_meta?:ValueTypes["PaymentMethodMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	enabled?:boolean | `@${string}`,
deliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethod"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	blockingDispatch?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	reduceGratuityByPercentage?:boolean | `@${string}`,
paginateDeliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PaymentMethodMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	enabled?:ValueTypes["FieldMeta"],
	deliveryMethods?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	blockingDispatch?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	reduceGratuityByPercentage?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	enabled?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	requireDeliveryAddress?: ValueTypes["OrderDirection"] | undefined | null,
	enableDeliveryTracking?: ValueTypes["OrderDirection"] | undefined | null,
	timelinePreset?: ValueTypes["DeliveryTimelinePresetOrderBy"] | undefined | null
};
	["DeliveryTimelinePresetOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	duration?: ValueTypes["OrderDirection"] | undefined | null,
	window?: ValueTypes["OrderDirection"] | undefined | null,
	deliveryMethod?: ValueTypes["DeliveryMethodOrderBy"] | undefined | null
};
	["DeliveryMethodConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryMethodEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodEdge"]: AliasType<{
	node?:ValueTypes["DeliveryMethod"],
		__typename?: boolean | `@${string}`
}>;
	["PaymentMethodOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	enabled?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	blockingDispatch?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	reduceGratuityByPercentage?: ValueTypes["OrderDirection"] | undefined | null
};
	["DeliveryZone"]: AliasType<{
	_meta?:ValueTypes["DeliveryZoneMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
addresses?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Address"]],
suitableDeliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethod"]],
deliveryZonesOfTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
deliveryZonesOfProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
addressesByBillingAddressOfOrders?: [{	by: ValueTypes["DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByDeliveryAddressOfOrderDeliveries?: [{	by: ValueTypes["DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByDefaultDeliveryAddressOfCustomer?: [{	by: ValueTypes["DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
paginateAddresses?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AddressConnection"]],
paginateSuitableDeliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodConnection"]],
paginateDeliveryZonesOfTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginateDeliveryZonesOfProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryZoneMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	addresses?:ValueTypes["FieldMeta"],
	suitableDeliveryMethods?:ValueTypes["FieldMeta"],
	deliveryZonesOfTags?:ValueTypes["FieldMeta"],
	deliveryZonesOfProducts?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere"]: {
	billingAddressOfOrders?: ValueTypes["OrderUniqueWhere"] | undefined | null
};
	["OrderUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	seq?: number | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	delivery?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null,
	items?: ValueTypes["OrderItemUniqueWhere"] | undefined | null,
	payments?: ValueTypes["OrderPaymentUniqueWhere"] | undefined | null
};
	["OrderDeliveryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	trackingCode?: string | undefined | null,
	order?: ValueTypes["OrderUniqueWhere"] | undefined | null,
	deliveryWaypoint?: ValueTypes["DeliveryWaypointUniqueWhere"] | undefined | null
};
	["DeliveryWaypointUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	delivery?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null
};
	["OrderPaymentUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: {
	deliveryAddressOfOrderDeliveries?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null
};
	["DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: {
	defaultDeliveryAddressOfCustomer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["CustomerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	addresses?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	defaultBillingAddress?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	orders?: ValueTypes["OrderUniqueWhere"] | undefined | null,
	name?: ValueTypes["CustomerNameUniqueWhere"] | undefined | null,
	defaultDeliveryAddress?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	meta?: ValueTypes["CustomerMetadataUniqueWhere"] | undefined | null
};
	["AddressUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	billingAddressOfOrders?: ValueTypes["OrderUniqueWhere"] | undefined | null,
	deliveryAddressOfOrderDeliveries?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["CustomerNameUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["CustomerMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["AddressConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AddressEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AddressEdge"]: AliasType<{
	node?:ValueTypes["Address"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	trackingCodeUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	processingTitle?: ValueTypes["OrderDirection"] | undefined | null,
	processingText?: ValueTypes["OrderDirection"] | undefined | null,
	processingIconUrl?: ValueTypes["OrderDirection"] | undefined | null,
	dispatchedTitle?: ValueTypes["OrderDirection"] | undefined | null,
	dispatchedText?: ValueTypes["OrderDirection"] | undefined | null,
	dispatchedIconUrl?: ValueTypes["OrderDirection"] | undefined | null,
	fulfilledTitle?: ValueTypes["OrderDirection"] | undefined | null,
	fulfilledText?: ValueTypes["OrderDirection"] | undefined | null,
	fulfilledIconUrl?: ValueTypes["OrderDirection"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	root?: ValueTypes["DeliveryMethodOrderBy"] | undefined | null
};
	["DeliveryTimelinePreset"]: AliasType<{
	_meta?:ValueTypes["DeliveryTimelinePresetMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	window?:boolean | `@${string}`,
deliveryMethod?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryTimelinePresetMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	duration?:ValueTypes["FieldMeta"],
	window?:ValueTypes["FieldMeta"],
	deliveryMethod?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["PaymentMethodConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PaymentMethodEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PaymentMethodEdge"]: AliasType<{
	node?:ValueTypes["PaymentMethod"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryZoneConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryZoneEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryZoneEdge"]: AliasType<{
	node?:ValueTypes["DeliveryZone"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryMethodLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocaleEdge"]: AliasType<{
	node?:ValueTypes["DeliveryMethodLocale"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationCodeLocale"]: AliasType<{
	_meta?:ValueTypes["IdentificationCodeLocaleMeta"],
	id?:boolean | `@${string}`,
	successMessage?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["IdentificationCodeWhere"] | undefined | null},ValueTypes["IdentificationCode"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationCodeLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	successMessage?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationCode"]: AliasType<{
	_meta?:ValueTypes["IdentificationCodeMeta"],
	id?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
attempts?: [{	filter?: ValueTypes["IdentificationAttemptWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationAttemptOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IdentificationAttempt"]],
locales?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IdentificationCodeLocale"]],
	internalName?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["IdentificationCodeLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null},ValueTypes["IdentificationCodeLocale"]],
paginateAttempts?: [{	filter?: ValueTypes["IdentificationAttemptWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationAttemptOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IdentificationAttemptConnection"]],
paginateLocales?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IdentificationCodeLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationCodeMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	attempts?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationAttempt"]: AliasType<{
	_meta?:ValueTypes["IdentificationAttemptMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
code?: [{	filter?: ValueTypes["IdentificationCodeWhere"] | undefined | null},ValueTypes["IdentificationCode"]],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationAttemptMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationAttemptOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["IdentificationCodeOrderBy"] | undefined | null
};
	["IdentificationCodeOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null
};
	["IdentificationCodeLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	successMessage?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["IdentificationCodeOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["IdentificationCodeLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["IdentificationAttemptConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IdentificationAttemptEdge"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationAttemptEdge"]: AliasType<{
	node?:ValueTypes["IdentificationAttempt"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationCodeLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IdentificationCodeLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationCodeLocaleEdge"]: AliasType<{
	node?:ValueTypes["IdentificationCodeLocale"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBoxLocale"]: AliasType<{
	_meta?:ValueTypes["SubscriptionBoxLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	stickerText?:boolean | `@${string}`,
	descriptionText?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["SubscriptionBoxWhere"] | undefined | null},ValueTypes["SubscriptionBox"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
	secondaryTitle?:boolean | `@${string}`,
texts?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
secondaryTexts?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBoxLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	stickerText?:ValueTypes["FieldMeta"],
	descriptionText?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	secondaryTitle?:ValueTypes["FieldMeta"],
	texts?:ValueTypes["FieldMeta"],
	secondaryTexts?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBox"]: AliasType<{
	_meta?:ValueTypes["SubscriptionBoxMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
	unique?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["SubscriptionBoxLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
localesByTexts?: [{	by: ValueTypes["SubscriptionBoxLocalesByTextsUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
localesBySecondaryTexts?: [{	by: ValueTypes["SubscriptionBoxLocalesBySecondaryTextsUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
paginateLocales?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SubscriptionBoxLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBoxMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBoxLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	stickerText?: ValueTypes["OrderDirection"] | undefined | null,
	descriptionText?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["SubscriptionBoxOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	secondaryTitle?: ValueTypes["OrderDirection"] | undefined | null,
	texts?: ValueTypes["TextListOrderBy"] | undefined | null,
	secondaryTexts?: ValueTypes["TextListOrderBy"] | undefined | null
};
	["SubscriptionBoxOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	unique?: ValueTypes["OrderDirection"] | undefined | null
};
	["SubscriptionBoxLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["SubscriptionBoxLocalesByTextsUniqueWhere"]: {
	texts?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["SubscriptionBoxLocalesBySecondaryTextsUniqueWhere"]: {
	secondaryTexts?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["SubscriptionBoxLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SubscriptionBoxLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBoxLocaleEdge"]: AliasType<{
	node?:ValueTypes["SubscriptionBoxLocale"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleBusinessCategoriesByRootUniqueWhere"]: {
	root?: ValueTypes["BusinessCategoryUniqueWhere"] | undefined | null
};
	["LocaleProductCategoryListsByRootUniqueWhere"]: {
	root?: ValueTypes["ProductCategoryListUniqueWhere"] | undefined | null
};
	["LocaleProductIngredientsByRootUniqueWhere"]: {
	root?: ValueTypes["ProductIngredientUniqueWhere"] | undefined | null
};
	["LocaleOrderRecurrencesByRootUniqueWhere"]: {
	root?: ValueTypes["OrderRecurrenceUniqueWhere"] | undefined | null
};
	["LocaleBlogsByRootUniqueWhere"]: {
	root?: ValueTypes["BlogUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsByRootUniqueWhere"]: {
	root?: ValueTypes["BlogPostUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsByCoverUniqueWhere"]: {
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null
};
	["LocalePagesByRootUniqueWhere"]: {
	root?: ValueTypes["PageUniqueWhere"] | undefined | null
};
	["LocalePagesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocalePagesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null
};
	["LocalePagesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null
};
	["LocalePagesByCoverUniqueWhere"]: {
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null
};
	["LocaleProductIngredientCategoriesByRootUniqueWhere"]: {
	root?: ValueTypes["ProductIngredientCategoryUniqueWhere"] | undefined | null
};
	["LocaleAllergensByRootUniqueWhere"]: {
	root?: ValueTypes["AllergenUniqueWhere"] | undefined | null
};
	["LocaleProductPackingsByRootUniqueWhere"]: {
	root?: ValueTypes["ProductPackingUniqueWhere"] | undefined | null
};
	["LocaleProductsByRootUniqueWhere"]: {
	root?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["LocaleProductsByFeatureListUniqueWhere"]: {
	featureList?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["LocaleProductRecipesByRootUniqueWhere"]: {
	root?: ValueTypes["ProductRecipeUniqueWhere"] | undefined | null
};
	["LocaleDeliveryMethodsByRootUniqueWhere"]: {
	root?: ValueTypes["DeliveryMethodUniqueWhere"] | undefined | null
};
	["LocaleIngredientSuppliersByRootUniqueWhere"]: {
	root?: ValueTypes["IngredientSupplierUniqueWhere"] | undefined | null
};
	["LocaleIdentificationCodesByRootUniqueWhere"]: {
	root?: ValueTypes["IdentificationCodeUniqueWhere"] | undefined | null
};
	["LocaleSubscriptionBoxesByRootUniqueWhere"]: {
	root?: ValueTypes["SubscriptionBoxUniqueWhere"] | undefined | null
};
	["LocaleSubscriptionBoxesByTextsUniqueWhere"]: {
	texts?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere"]: {
	secondaryTexts?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["ProductPackingLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductPackingLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductPackingLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductLocale"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedPackingQuantity"]: AliasType<{
	_meta?:ValueTypes["PreparedPackingQuantityMeta"],
	id?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
recipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
packing?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null},ValueTypes["ProductPacking"]],
	packedInCardboard?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PreparedPackingQuantityMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	date?:ValueTypes["FieldMeta"],
	quantity?:ValueTypes["FieldMeta"],
	recipe?:ValueTypes["FieldMeta"],
	packing?:ValueTypes["FieldMeta"],
	packedInCardboard?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedPackingQuantityOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	date?: ValueTypes["OrderDirection"] | undefined | null,
	quantity?: ValueTypes["OrderDirection"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeOrderBy"] | undefined | null,
	packing?: ValueTypes["ProductPackingOrderBy"] | undefined | null,
	packedInCardboard?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductPackingLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["PreparedPackingQuantityConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PreparedPackingQuantityEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedPackingQuantityEdge"]: AliasType<{
	node?:ValueTypes["PreparedPackingQuantity"],
		__typename?: boolean | `@${string}`
}>;
	["ProductStock"]: AliasType<{
	_meta?:ValueTypes["ProductStockMeta"],
	id?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
store?: [{	filter?: ValueTypes["StoreWhere"] | undefined | null},ValueTypes["Store"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductStockMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	quantity?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	store?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Store"]: AliasType<{
	_meta?:ValueTypes["StoreMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StoreMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductStockOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	quantity?: ValueTypes["OrderDirection"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null,
	store?: ValueTypes["StoreOrderBy"] | undefined | null
};
	["StoreOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null
};
	["OrderItem"]: AliasType<{
	_meta?:ValueTypes["OrderItemMeta"],
	id?:boolean | `@${string}`,
	unitPriceCents?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
order?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
	pickedAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
fulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FulfillmentNote"]],
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FulfillmentNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unitPriceCents?:ValueTypes["FieldMeta"],
	quantity?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	pickedAt?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	fulfillmentNotes?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Order"]: AliasType<{
	_meta?:ValueTypes["OrderMeta"],
	id?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	fulfilledAt?:boolean | `@${string}`,
	canceledAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
channel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null},ValueTypes["Channel"]],
delivery?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
items?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderItem"]],
	internalNote?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
billingAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
payments?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPayment"]],
	seq?:boolean | `@${string}`,
	doubleshotLegacyId?:boolean | `@${string}`,
venue?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null},ValueTypes["Venue"]],
	fulfillmentNote?:boolean | `@${string}`,
fulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FulfillmentNote"]],
	doubleshotTotalWeightGrams?:boolean | `@${string}`,
	doubleShotLegacyNumber?:boolean | `@${string}`,
	doubleshotLastUpdatedAt?:boolean | `@${string}`,
	doubleshotAdminUrl?:boolean | `@${string}`,
paginateItems?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderItemConnection"]],
paginatePayments?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPaymentConnection"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FulfillmentNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	state?:ValueTypes["FieldMeta"],
	fulfilledAt?:ValueTypes["FieldMeta"],
	canceledAt?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
	channel?:ValueTypes["FieldMeta"],
	delivery?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	billingAddress?:ValueTypes["FieldMeta"],
	payments?:ValueTypes["FieldMeta"],
	seq?:ValueTypes["FieldMeta"],
	doubleshotLegacyId?:ValueTypes["FieldMeta"],
	venue?:ValueTypes["FieldMeta"],
	fulfillmentNote?:ValueTypes["FieldMeta"],
	fulfillmentNotes?:ValueTypes["FieldMeta"],
	doubleshotTotalWeightGrams?:ValueTypes["FieldMeta"],
	doubleShotLegacyNumber?:ValueTypes["FieldMeta"],
	doubleshotLastUpdatedAt?:ValueTypes["FieldMeta"],
	doubleshotAdminUrl?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Channel"]: AliasType<{
	_meta?:ValueTypes["ChannelMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	disabled?:boolean | `@${string}`,
customers?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Customer"]],
	code?:boolean | `@${string}`,
addTagsOnRegistration?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
publicTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
venue?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null},ValueTypes["Venue"]],
paginateCustomers?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerConnection"]],
paginateAddTagsOnRegistration?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginatePublicTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ChannelMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	disabled?:ValueTypes["FieldMeta"],
	customers?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	addTagsOnRegistration?:ValueTypes["FieldMeta"],
	publicTags?:ValueTypes["FieldMeta"],
	venue?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Venue"]: AliasType<{
	_meta?:ValueTypes["VenueMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
deadlineTemplate?: [{	filter?: ValueTypes["DeadlineTemplateWhere"] | undefined | null},ValueTypes["DeadlineTemplate"]],
timetableDays?: [{	filter?: ValueTypes["TimetableDayWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableDay"]],
timetableTemplates?: [{	filter?: ValueTypes["TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableTemplateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableTemplate"]],
staffMembers?: [{	filter?: ValueTypes["StaffWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StaffOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Staff"]],
employeeRoles?: [{	filter?: ValueTypes["VenueEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueEmployeeRoleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VenueEmployeeRole"]],
orders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
fulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FulfillmentNote"]],
enabledUnassignReasons?: [{	filter?: ValueTypes["UnassignReasonWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UnassignReasonOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["UnassignReason"]],
channels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
	address?:boolean | `@${string}`,
	pointOfSalePngBase64ReceiptLogo?:boolean | `@${string}`,
	pointOfSaleInitialView?:boolean | `@${string}`,
	pointOfSalePreferPrintReceipt?:boolean | `@${string}`,
timetableDaysByDate?: [{	by: ValueTypes["VenueTimetableDaysByDateUniqueWhere"],	filter?: ValueTypes["TimetableDayWhere"] | undefined | null},ValueTypes["TimetableDay"]],
timetableDaysByShiftGroups?: [{	by: ValueTypes["VenueTimetableDaysByShiftGroupsUniqueWhere"],	filter?: ValueTypes["TimetableDayWhere"] | undefined | null},ValueTypes["TimetableDay"]],
timetableDaysByNotes?: [{	by: ValueTypes["VenueTimetableDaysByNotesUniqueWhere"],	filter?: ValueTypes["TimetableDayWhere"] | undefined | null},ValueTypes["TimetableDay"]],
timetableDaysByMeta?: [{	by: ValueTypes["VenueTimetableDaysByMetaUniqueWhere"],	filter?: ValueTypes["TimetableDayWhere"] | undefined | null},ValueTypes["TimetableDay"]],
employeeRolesByRole?: [{	by: ValueTypes["VenueEmployeeRolesByRoleUniqueWhere"],	filter?: ValueTypes["VenueEmployeeRoleWhere"] | undefined | null},ValueTypes["VenueEmployeeRole"]],
employeeRolesByEmployeePrivileges?: [{	by: ValueTypes["VenueEmployeeRolesByEmployeePrivilegesUniqueWhere"],	filter?: ValueTypes["VenueEmployeeRoleWhere"] | undefined | null},ValueTypes["VenueEmployeeRole"]],
ordersBySeq?: [{	by: ValueTypes["VenueOrdersBySeqUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDoubleshotLegacyId?: [{	by: ValueTypes["VenueOrdersByDoubleshotLegacyIdUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDoubleShotLegacyNumber?: [{	by: ValueTypes["VenueOrdersByDoubleShotLegacyNumberUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDelivery?: [{	by: ValueTypes["VenueOrdersByDeliveryUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByItems?: [{	by: ValueTypes["VenueOrdersByItemsUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByPayments?: [{	by: ValueTypes["VenueOrdersByPaymentsUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
channelsByCode?: [{	by: ValueTypes["VenueChannelsByCodeUniqueWhere"],	filter?: ValueTypes["ChannelWhere"] | undefined | null},ValueTypes["Channel"]],
paginateTimetableDays?: [{	filter?: ValueTypes["TimetableDayWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableDayConnection"]],
paginateTimetableTemplates?: [{	filter?: ValueTypes["TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableTemplateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableTemplateConnection"]],
paginateStaffMembers?: [{	filter?: ValueTypes["StaffWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StaffOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StaffConnection"]],
paginateEmployeeRoles?: [{	filter?: ValueTypes["VenueEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueEmployeeRoleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VenueEmployeeRoleConnection"]],
paginateOrders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FulfillmentNoteConnection"]],
paginateEnabledUnassignReasons?: [{	filter?: ValueTypes["UnassignReasonWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UnassignReasonOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["UnassignReasonConnection"]],
paginateChannels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["VenueMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	deadlineTemplate?:ValueTypes["FieldMeta"],
	timetableDays?:ValueTypes["FieldMeta"],
	timetableTemplates?:ValueTypes["FieldMeta"],
	staffMembers?:ValueTypes["FieldMeta"],
	employeeRoles?:ValueTypes["FieldMeta"],
	orders?:ValueTypes["FieldMeta"],
	fulfillmentNotes?:ValueTypes["FieldMeta"],
	enabledUnassignReasons?:ValueTypes["FieldMeta"],
	channels?:ValueTypes["FieldMeta"],
	address?:ValueTypes["FieldMeta"],
	pointOfSalePngBase64ReceiptLogo?:ValueTypes["FieldMeta"],
	pointOfSaleInitialView?:ValueTypes["FieldMeta"],
	pointOfSalePreferPrintReceipt?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeadlineTemplate"]: AliasType<{
	_meta?:ValueTypes["DeadlineTemplateMeta"],
	id?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	period?:boolean | `@${string}`,
	cutoff?:boolean | `@${string}`,
	closed?:boolean | `@${string}`,
venue?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null},ValueTypes["Venue"]],
		__typename?: boolean | `@${string}`
}>;
	["DeadlineTemplateMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	startDate?:ValueTypes["FieldMeta"],
	period?:ValueTypes["FieldMeta"],
	cutoff?:ValueTypes["FieldMeta"],
	closed?:ValueTypes["FieldMeta"],
	venue?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableDay"]: AliasType<{
	_meta?:ValueTypes["TimetableDayMeta"],
	id?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
shiftGroups?: [{	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftGroupOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableShiftGroup"]],
	tipsCents?:boolean | `@${string}`,
notes?: [{	filter?: ValueTypes["TimetableDayNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableDayNote"]],
venue?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null},ValueTypes["Venue"]],
meta?: [{	filter?: ValueTypes["TimetableDayMetadataWhere"] | undefined | null},ValueTypes["TimetableDayMetadata"]],
shiftGroupsByShifts?: [{	by: ValueTypes["TimetableDayShiftGroupsByShiftsUniqueWhere"],	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null},ValueTypes["TimetableShiftGroup"]],
shiftGroupsByQueue?: [{	by: ValueTypes["TimetableDayShiftGroupsByQueueUniqueWhere"],	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null},ValueTypes["TimetableShiftGroup"]],
paginateShiftGroups?: [{	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftGroupOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableShiftGroupConnection"]],
paginateNotes?: [{	filter?: ValueTypes["TimetableDayNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableDayNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TimetableDayMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	date?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	shiftGroups?:ValueTypes["FieldMeta"],
	tipsCents?:ValueTypes["FieldMeta"],
	notes?:ValueTypes["FieldMeta"],
	venue?:ValueTypes["FieldMeta"],
	meta?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableShiftGroup"]: AliasType<{
	_meta?:ValueTypes["TimetableShiftGroupMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
shifts?: [{	filter?: ValueTypes["TimetableShiftWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableShift"]],
day?: [{	filter?: ValueTypes["TimetableDayWhere"] | undefined | null},ValueTypes["TimetableDay"]],
type?: [{	filter?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null},ValueTypes["TimetableEmployeeRole"]],
position?: [{	filter?: ValueTypes["TimetableShiftPositionWhere"] | undefined | null},ValueTypes["TimetableShiftPosition"]],
queue?: [{	filter?: ValueTypes["EmployeeQueueWhere"] | undefined | null},ValueTypes["EmployeeQueue"]],
generatedByTemplate?: [{	filter?: ValueTypes["TimetableTemplateWhere"] | undefined | null},ValueTypes["TimetableTemplate"]],
shiftsByQueueItem?: [{	by: ValueTypes["TimetableShiftGroupShiftsByQueueItemUniqueWhere"],	filter?: ValueTypes["TimetableShiftWhere"] | undefined | null},ValueTypes["TimetableShift"]],
paginateShifts?: [{	filter?: ValueTypes["TimetableShiftWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableShiftConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TimetableShiftGroupMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	shifts?:ValueTypes["FieldMeta"],
	day?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	position?:ValueTypes["FieldMeta"],
	queue?:ValueTypes["FieldMeta"],
	generatedByTemplate?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableShift"]: AliasType<{
	_meta?:ValueTypes["TimetableShiftMeta"],
	id?:boolean | `@${string}`,
	locked?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	assigned?:boolean | `@${string}`,
group?: [{	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null},ValueTypes["TimetableShiftGroup"]],
shiftsProfile?: [{	filter?: ValueTypes["ShiftsProfileWhere"] | undefined | null},ValueTypes["ShiftsProfile"]],
queueItem?: [{	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null},ValueTypes["EmployeeQueueItem"]],
	isOvertime?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TimetableShiftMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locked?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	assigned?:ValueTypes["FieldMeta"],
	group?:ValueTypes["FieldMeta"],
	shiftsProfile?:ValueTypes["FieldMeta"],
	queueItem?:ValueTypes["FieldMeta"],
	isOvertime?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ShiftsProfile"]: AliasType<{
	_meta?:ValueTypes["ShiftsProfileMeta"],
	id?:boolean | `@${string}`,
queueItems?: [{	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["EmployeeQueueItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["EmployeeQueueItem"]],
staff?: [{	filter?: ValueTypes["StaffWhere"] | undefined | null},ValueTypes["Staff"]],
roles?: [{	filter?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableEmployeeRoleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableEmployeeRole"]],
shifts?: [{	filter?: ValueTypes["TimetableShiftWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableShift"]],
	allowSelfUnassigning?:boolean | `@${string}`,
queueItemsByQueue?: [{	by: ValueTypes["ShiftsProfileQueueItemsByQueueUniqueWhere"],	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null},ValueTypes["EmployeeQueueItem"]],
queueItemsByShift?: [{	by: ValueTypes["ShiftsProfileQueueItemsByShiftUniqueWhere"],	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null},ValueTypes["EmployeeQueueItem"]],
queueItemsByUnassignInfo?: [{	by: ValueTypes["ShiftsProfileQueueItemsByUnassignInfoUniqueWhere"],	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null},ValueTypes["EmployeeQueueItem"]],
shiftsByQueueItem?: [{	by: ValueTypes["ShiftsProfileShiftsByQueueItemUniqueWhere"],	filter?: ValueTypes["TimetableShiftWhere"] | undefined | null},ValueTypes["TimetableShift"]],
paginateQueueItems?: [{	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["EmployeeQueueItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["EmployeeQueueItemConnection"]],
paginateRoles?: [{	filter?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableEmployeeRoleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableEmployeeRoleConnection"]],
paginateShifts?: [{	filter?: ValueTypes["TimetableShiftWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableShiftConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ShiftsProfileMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	queueItems?:ValueTypes["FieldMeta"],
	staff?:ValueTypes["FieldMeta"],
	roles?:ValueTypes["FieldMeta"],
	shifts?:ValueTypes["FieldMeta"],
	allowSelfUnassigning?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueueItem"]: AliasType<{
	_meta?:ValueTypes["EmployeeQueueItemMeta"],
	id?:boolean | `@${string}`,
	start?:boolean | `@${string}`,
	end?:boolean | `@${string}`,
queue?: [{	filter?: ValueTypes["EmployeeQueueWhere"] | undefined | null},ValueTypes["EmployeeQueue"]],
shiftsProfile?: [{	filter?: ValueTypes["ShiftsProfileWhere"] | undefined | null},ValueTypes["ShiftsProfile"]],
shift?: [{	filter?: ValueTypes["TimetableShiftWhere"] | undefined | null},ValueTypes["TimetableShift"]],
unassignInfo?: [{	filter?: ValueTypes["UnassignedShiftInfoWhere"] | undefined | null},ValueTypes["UnassignedShiftInfo"]],
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueueItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	start?:ValueTypes["FieldMeta"],
	end?:ValueTypes["FieldMeta"],
	queue?:ValueTypes["FieldMeta"],
	shiftsProfile?:ValueTypes["FieldMeta"],
	shift?:ValueTypes["FieldMeta"],
	unassignInfo?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueue"]: AliasType<{
	_meta?:ValueTypes["EmployeeQueueMeta"],
	id?:boolean | `@${string}`,
shiftGroup?: [{	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null},ValueTypes["TimetableShiftGroup"]],
items?: [{	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["EmployeeQueueItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["EmployeeQueueItem"]],
	contemberBugFix?:boolean | `@${string}`,
itemsByShiftsProfile?: [{	by: ValueTypes["EmployeeQueueItemsByShiftsProfileUniqueWhere"],	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null},ValueTypes["EmployeeQueueItem"]],
itemsByShift?: [{	by: ValueTypes["EmployeeQueueItemsByShiftUniqueWhere"],	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null},ValueTypes["EmployeeQueueItem"]],
itemsByUnassignInfo?: [{	by: ValueTypes["EmployeeQueueItemsByUnassignInfoUniqueWhere"],	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null},ValueTypes["EmployeeQueueItem"]],
paginateItems?: [{	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["EmployeeQueueItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["EmployeeQueueItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueueMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	shiftGroup?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	contemberBugFix?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueueItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	start?: ValueTypes["OrderDirection"] | undefined | null,
	end?: ValueTypes["OrderDirection"] | undefined | null,
	queue?: ValueTypes["EmployeeQueueOrderBy"] | undefined | null,
	shiftsProfile?: ValueTypes["ShiftsProfileOrderBy"] | undefined | null,
	shift?: ValueTypes["TimetableShiftOrderBy"] | undefined | null,
	unassignInfo?: ValueTypes["UnassignedShiftInfoOrderBy"] | undefined | null
};
	["EmployeeQueueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	shiftGroup?: ValueTypes["TimetableShiftGroupOrderBy"] | undefined | null,
	contemberBugFix?: ValueTypes["OrderDirection"] | undefined | null
};
	["TimetableShiftGroupOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	day?: ValueTypes["TimetableDayOrderBy"] | undefined | null,
	type?: ValueTypes["TimetableEmployeeRoleOrderBy"] | undefined | null,
	position?: ValueTypes["TimetableShiftPositionOrderBy"] | undefined | null,
	queue?: ValueTypes["EmployeeQueueOrderBy"] | undefined | null,
	generatedByTemplate?: ValueTypes["TimetableTemplateOrderBy"] | undefined | null
};
	["TimetableDayOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	date?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	tipsCents?: ValueTypes["OrderDirection"] | undefined | null,
	venue?: ValueTypes["VenueOrderBy"] | undefined | null,
	meta?: ValueTypes["TimetableDayMetadataOrderBy"] | undefined | null
};
	["VenueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	deadlineTemplate?: ValueTypes["DeadlineTemplateOrderBy"] | undefined | null,
	address?: ValueTypes["OrderDirection"] | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: ValueTypes["OrderDirection"] | undefined | null,
	pointOfSaleInitialView?: ValueTypes["OrderDirection"] | undefined | null,
	pointOfSalePreferPrintReceipt?: ValueTypes["OrderDirection"] | undefined | null
};
	["DeadlineTemplateOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	startDate?: ValueTypes["OrderDirection"] | undefined | null,
	period?: ValueTypes["OrderDirection"] | undefined | null,
	cutoff?: ValueTypes["OrderDirection"] | undefined | null,
	closed?: ValueTypes["OrderDirection"] | undefined | null,
	venue?: ValueTypes["VenueOrderBy"] | undefined | null
};
	["TimetableDayMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	numberOfWorkers?: ValueTypes["OrderDirection"] | undefined | null,
	tipsTotalCents?: ValueTypes["OrderDirection"] | undefined | null,
	totalMinutes?: ValueTypes["OrderDirection"] | undefined | null,
	tipsCentsPerMinute?: ValueTypes["OrderDirection"] | undefined | null,
	day?: ValueTypes["TimetableDayOrderBy"] | undefined | null
};
	["TimetableEmployeeRoleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	color?: ValueTypes["OrderDirection"] | undefined | null
};
	["TimetableShiftPositionOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	start?: ValueTypes["OrderDirection"] | undefined | null,
	end?: ValueTypes["OrderDirection"] | undefined | null,
	isDynamic?: ValueTypes["OrderDirection"] | undefined | null
};
	["TimetableTemplateOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	startDate?: ValueTypes["OrderDirection"] | undefined | null,
	period?: ValueTypes["OrderDirection"] | undefined | null,
	slots?: ValueTypes["OrderDirection"] | undefined | null,
	position?: ValueTypes["TimetableShiftPositionOrderBy"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	venue?: ValueTypes["VenueOrderBy"] | undefined | null,
	employeeRole?: ValueTypes["TimetableEmployeeRoleOrderBy"] | undefined | null,
	workdaysOnly?: ValueTypes["OrderDirection"] | undefined | null,
	monday?: ValueTypes["OrderDirection"] | undefined | null,
	tuesday?: ValueTypes["OrderDirection"] | undefined | null,
	wednesday?: ValueTypes["OrderDirection"] | undefined | null,
	thursday?: ValueTypes["OrderDirection"] | undefined | null,
	friday?: ValueTypes["OrderDirection"] | undefined | null,
	saturday?: ValueTypes["OrderDirection"] | undefined | null,
	sunday?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null
};
	["ShiftsProfileOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	staff?: ValueTypes["StaffOrderBy"] | undefined | null,
	allowSelfUnassigning?: ValueTypes["OrderDirection"] | undefined | null
};
	["StaffOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	shiftsProfile?: ValueTypes["ShiftsProfileOrderBy"] | undefined | null,
	photo?: ValueTypes["ImageOrderBy"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null
};
	["TimetableShiftOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	locked?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	assigned?: ValueTypes["OrderDirection"] | undefined | null,
	group?: ValueTypes["TimetableShiftGroupOrderBy"] | undefined | null,
	shiftsProfile?: ValueTypes["ShiftsProfileOrderBy"] | undefined | null,
	queueItem?: ValueTypes["EmployeeQueueItemOrderBy"] | undefined | null,
	isOvertime?: ValueTypes["OrderDirection"] | undefined | null
};
	["UnassignedShiftInfoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	hoursMissed?: ValueTypes["OrderDirection"] | undefined | null,
	queueItem?: ValueTypes["EmployeeQueueItemOrderBy"] | undefined | null,
	legacyReason?: ValueTypes["OrderDirection"] | undefined | null,
	reason?: ValueTypes["UnassignReasonOrderBy"] | undefined | null
};
	["UnassignReasonOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null
};
	["EmployeeQueueItemsByShiftsProfileUniqueWhere"]: {
	shiftsProfile?: ValueTypes["ShiftsProfileUniqueWhere"] | undefined | null
};
	["ShiftsProfileUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	queueItems?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null,
	staff?: ValueTypes["StaffUniqueWhere"] | undefined | null,
	shifts?: ValueTypes["TimetableShiftUniqueWhere"] | undefined | null
};
	["EmployeeQueueItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shiftsProfile?: ValueTypes["ShiftsProfileUniqueWhere"] | undefined | null,
	queue?: ValueTypes["EmployeeQueueUniqueWhere"] | undefined | null,
	shift?: ValueTypes["TimetableShiftUniqueWhere"] | undefined | null,
	unassignInfo?: ValueTypes["UnassignedShiftInfoUniqueWhere"] | undefined | null
};
	["EmployeeQueueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shiftGroup?: ValueTypes["TimetableShiftGroupUniqueWhere"] | undefined | null,
	items?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null
};
	["TimetableShiftGroupUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shifts?: ValueTypes["TimetableShiftUniqueWhere"] | undefined | null,
	queue?: ValueTypes["EmployeeQueueUniqueWhere"] | undefined | null
};
	["TimetableShiftUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	queueItem?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null
};
	["UnassignedShiftInfoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	queueItem?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null
};
	["StaffUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shiftsProfile?: ValueTypes["ShiftsProfileUniqueWhere"] | undefined | null
};
	["EmployeeQueueItemsByShiftUniqueWhere"]: {
	shift?: ValueTypes["TimetableShiftUniqueWhere"] | undefined | null
};
	["EmployeeQueueItemsByUnassignInfoUniqueWhere"]: {
	unassignInfo?: ValueTypes["UnassignedShiftInfoUniqueWhere"] | undefined | null
};
	["EmployeeQueueItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["EmployeeQueueItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueueItemEdge"]: AliasType<{
	node?:ValueTypes["EmployeeQueueItem"],
		__typename?: boolean | `@${string}`
}>;
	["UnassignedShiftInfo"]: AliasType<{
	_meta?:ValueTypes["UnassignedShiftInfoMeta"],
	id?:boolean | `@${string}`,
	hoursMissed?:boolean | `@${string}`,
queueItem?: [{	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null},ValueTypes["EmployeeQueueItem"]],
	legacyReason?:boolean | `@${string}`,
reason?: [{	filter?: ValueTypes["UnassignReasonWhere"] | undefined | null},ValueTypes["UnassignReason"]],
		__typename?: boolean | `@${string}`
}>;
	["UnassignedShiftInfoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	hoursMissed?:ValueTypes["FieldMeta"],
	queueItem?:ValueTypes["FieldMeta"],
	legacyReason?:ValueTypes["FieldMeta"],
	reason?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["UnassignReason"]: AliasType<{
	_meta?:ValueTypes["UnassignReasonMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
venues?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Venue"]],
paginateVenues?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VenueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["UnassignReasonMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	venues?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["VenueConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VenueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VenueEdge"]: AliasType<{
	node?:ValueTypes["Venue"],
		__typename?: boolean | `@${string}`
}>;
	["Staff"]: AliasType<{
	_meta?:ValueTypes["StaffMeta"],
	id?:boolean | `@${string}`,
shiftsProfile?: [{	filter?: ValueTypes["ShiftsProfileWhere"] | undefined | null},ValueTypes["ShiftsProfile"]],
photo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	createdAt?:boolean | `@${string}`,
venues?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Venue"]],
paginateVenues?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VenueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["StaffMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	shiftsProfile?:ValueTypes["FieldMeta"],
	photo?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	venues?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableEmployeeRole"]: AliasType<{
	_meta?:ValueTypes["TimetableEmployeeRoleMeta"],
	id?:boolean | `@${string}`,
shiftGroups?: [{	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftGroupOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableShiftGroup"]],
shiftsProfiles?: [{	filter?: ValueTypes["ShiftsProfileWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ShiftsProfileOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ShiftsProfile"]],
	name?:boolean | `@${string}`,
timetableTemplates?: [{	filter?: ValueTypes["TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableTemplateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableTemplate"]],
	color?:boolean | `@${string}`,
shiftGroupsByShifts?: [{	by: ValueTypes["TimetableEmployeeRoleShiftGroupsByShiftsUniqueWhere"],	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null},ValueTypes["TimetableShiftGroup"]],
shiftGroupsByQueue?: [{	by: ValueTypes["TimetableEmployeeRoleShiftGroupsByQueueUniqueWhere"],	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null},ValueTypes["TimetableShiftGroup"]],
paginateShiftGroups?: [{	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftGroupOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableShiftGroupConnection"]],
paginateShiftsProfiles?: [{	filter?: ValueTypes["ShiftsProfileWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ShiftsProfileOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ShiftsProfileConnection"]],
paginateTimetableTemplates?: [{	filter?: ValueTypes["TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableTemplateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableTemplateConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TimetableEmployeeRoleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	shiftGroups?:ValueTypes["FieldMeta"],
	shiftsProfiles?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	timetableTemplates?:ValueTypes["FieldMeta"],
	color?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableTemplate"]: AliasType<{
	_meta?:ValueTypes["TimetableTemplateMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	period?:boolean | `@${string}`,
	slots?:boolean | `@${string}`,
position?: [{	filter?: ValueTypes["TimetableShiftPositionWhere"] | undefined | null},ValueTypes["TimetableShiftPosition"]],
	internalName?:boolean | `@${string}`,
venue?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null},ValueTypes["Venue"]],
employeeRole?: [{	filter?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null},ValueTypes["TimetableEmployeeRole"]],
	workdaysOnly?:boolean | `@${string}`,
	monday?:boolean | `@${string}`,
	tuesday?:boolean | `@${string}`,
	wednesday?:boolean | `@${string}`,
	thursday?:boolean | `@${string}`,
	friday?:boolean | `@${string}`,
	saturday?:boolean | `@${string}`,
	sunday?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TimetableTemplateMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	startDate?:ValueTypes["FieldMeta"],
	period?:ValueTypes["FieldMeta"],
	slots?:ValueTypes["FieldMeta"],
	position?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	venue?:ValueTypes["FieldMeta"],
	employeeRole?:ValueTypes["FieldMeta"],
	workdaysOnly?:ValueTypes["FieldMeta"],
	monday?:ValueTypes["FieldMeta"],
	tuesday?:ValueTypes["FieldMeta"],
	wednesday?:ValueTypes["FieldMeta"],
	thursday?:ValueTypes["FieldMeta"],
	friday?:ValueTypes["FieldMeta"],
	saturday?:ValueTypes["FieldMeta"],
	sunday?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableShiftPosition"]: AliasType<{
	_meta?:ValueTypes["TimetableShiftPositionMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	start?:boolean | `@${string}`,
	end?:boolean | `@${string}`,
shiftGroups?: [{	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftGroupOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableShiftGroup"]],
timetableTemplates?: [{	filter?: ValueTypes["TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableTemplateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableTemplate"]],
	isDynamic?:boolean | `@${string}`,
shiftGroupsByShifts?: [{	by: ValueTypes["TimetableShiftPositionShiftGroupsByShiftsUniqueWhere"],	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null},ValueTypes["TimetableShiftGroup"]],
shiftGroupsByQueue?: [{	by: ValueTypes["TimetableShiftPositionShiftGroupsByQueueUniqueWhere"],	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null},ValueTypes["TimetableShiftGroup"]],
paginateShiftGroups?: [{	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftGroupOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableShiftGroupConnection"]],
paginateTimetableTemplates?: [{	filter?: ValueTypes["TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableTemplateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableTemplateConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TimetableShiftPositionMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	start?:ValueTypes["FieldMeta"],
	end?:ValueTypes["FieldMeta"],
	shiftGroups?:ValueTypes["FieldMeta"],
	timetableTemplates?:ValueTypes["FieldMeta"],
	isDynamic?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableShiftPositionShiftGroupsByShiftsUniqueWhere"]: {
	shifts?: ValueTypes["TimetableShiftUniqueWhere"] | undefined | null
};
	["TimetableShiftPositionShiftGroupsByQueueUniqueWhere"]: {
	queue?: ValueTypes["EmployeeQueueUniqueWhere"] | undefined | null
};
	["TimetableShiftGroupConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TimetableShiftGroupEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableShiftGroupEdge"]: AliasType<{
	node?:ValueTypes["TimetableShiftGroup"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableTemplateConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TimetableTemplateEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableTemplateEdge"]: AliasType<{
	node?:ValueTypes["TimetableTemplate"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableEmployeeRoleShiftGroupsByShiftsUniqueWhere"]: {
	shifts?: ValueTypes["TimetableShiftUniqueWhere"] | undefined | null
};
	["TimetableEmployeeRoleShiftGroupsByQueueUniqueWhere"]: {
	queue?: ValueTypes["EmployeeQueueUniqueWhere"] | undefined | null
};
	["ShiftsProfileConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ShiftsProfileEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ShiftsProfileEdge"]: AliasType<{
	node?:ValueTypes["ShiftsProfile"],
		__typename?: boolean | `@${string}`
}>;
	["ShiftsProfileQueueItemsByQueueUniqueWhere"]: {
	queue?: ValueTypes["EmployeeQueueUniqueWhere"] | undefined | null
};
	["ShiftsProfileQueueItemsByShiftUniqueWhere"]: {
	shift?: ValueTypes["TimetableShiftUniqueWhere"] | undefined | null
};
	["ShiftsProfileQueueItemsByUnassignInfoUniqueWhere"]: {
	unassignInfo?: ValueTypes["UnassignedShiftInfoUniqueWhere"] | undefined | null
};
	["ShiftsProfileShiftsByQueueItemUniqueWhere"]: {
	queueItem?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null
};
	["TimetableEmployeeRoleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TimetableEmployeeRoleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableEmployeeRoleEdge"]: AliasType<{
	node?:ValueTypes["TimetableEmployeeRole"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableShiftConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TimetableShiftEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableShiftEdge"]: AliasType<{
	node?:ValueTypes["TimetableShift"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableShiftGroupShiftsByQueueItemUniqueWhere"]: {
	queueItem?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null
};
	["TimetableDayNote"]: AliasType<{
	_meta?:ValueTypes["TimetableDayNoteMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
day?: [{	filter?: ValueTypes["TimetableDayWhere"] | undefined | null},ValueTypes["TimetableDay"]],
role?: [{	filter?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null},ValueTypes["TimetableEmployeeRole"]],
		__typename?: boolean | `@${string}`
}>;
	["TimetableDayNoteMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	day?:ValueTypes["FieldMeta"],
	role?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableDayNoteOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	day?: ValueTypes["TimetableDayOrderBy"] | undefined | null,
	role?: ValueTypes["TimetableEmployeeRoleOrderBy"] | undefined | null
};
	["TimetableDayMetadata"]: AliasType<{
	_meta?:ValueTypes["TimetableDayMetadataMeta"],
	id?:boolean | `@${string}`,
	numberOfWorkers?:boolean | `@${string}`,
	tipsTotalCents?:boolean | `@${string}`,
	totalMinutes?:boolean | `@${string}`,
	tipsCentsPerMinute?:boolean | `@${string}`,
day?: [{	filter?: ValueTypes["TimetableDayWhere"] | undefined | null},ValueTypes["TimetableDay"]],
		__typename?: boolean | `@${string}`
}>;
	["TimetableDayMetadataMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	numberOfWorkers?:ValueTypes["FieldMeta"],
	tipsTotalCents?:ValueTypes["FieldMeta"],
	totalMinutes?:ValueTypes["FieldMeta"],
	tipsCentsPerMinute?:ValueTypes["FieldMeta"],
	day?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableDayShiftGroupsByShiftsUniqueWhere"]: {
	shifts?: ValueTypes["TimetableShiftUniqueWhere"] | undefined | null
};
	["TimetableDayShiftGroupsByQueueUniqueWhere"]: {
	queue?: ValueTypes["EmployeeQueueUniqueWhere"] | undefined | null
};
	["TimetableDayNoteConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TimetableDayNoteEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableDayNoteEdge"]: AliasType<{
	node?:ValueTypes["TimetableDayNote"],
		__typename?: boolean | `@${string}`
}>;
	["VenueEmployeeRole"]: AliasType<{
	_meta?:ValueTypes["VenueEmployeeRoleMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
employeePrivileges?: [{	filter?: ValueTypes["TimetablesEmployeePrivilegesWhere"] | undefined | null},ValueTypes["TimetablesEmployeePrivileges"]],
venue?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null},ValueTypes["Venue"]],
role?: [{	filter?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null},ValueTypes["TimetableEmployeeRole"]],
	getsTipsShare?:boolean | `@${string}`,
	notesEnabled?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["VenueEmployeeRoleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	employeePrivileges?:ValueTypes["FieldMeta"],
	venue?:ValueTypes["FieldMeta"],
	role?:ValueTypes["FieldMeta"],
	getsTipsShare?:ValueTypes["FieldMeta"],
	notesEnabled?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TimetablesEmployeePrivileges"]: AliasType<{
	_meta?:ValueTypes["TimetablesEmployeePrivilegesMeta"],
	id?:boolean | `@${string}`,
venueEmployeeRole?: [{	filter?: ValueTypes["VenueEmployeeRoleWhere"] | undefined | null},ValueTypes["VenueEmployeeRole"]],
	read?:boolean | `@${string}`,
	unassign?:boolean | `@${string}`,
	assign?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TimetablesEmployeePrivilegesMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	venueEmployeeRole?:ValueTypes["FieldMeta"],
	read?:ValueTypes["FieldMeta"],
	unassign?:ValueTypes["FieldMeta"],
	assign?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["VenueEmployeeRoleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	employeePrivileges?: ValueTypes["TimetablesEmployeePrivilegesOrderBy"] | undefined | null,
	venue?: ValueTypes["VenueOrderBy"] | undefined | null,
	role?: ValueTypes["TimetableEmployeeRoleOrderBy"] | undefined | null,
	getsTipsShare?: ValueTypes["OrderDirection"] | undefined | null,
	notesEnabled?: ValueTypes["OrderDirection"] | undefined | null
};
	["TimetablesEmployeePrivilegesOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	venueEmployeeRole?: ValueTypes["VenueEmployeeRoleOrderBy"] | undefined | null,
	read?: ValueTypes["OrderDirection"] | undefined | null,
	unassign?: ValueTypes["OrderDirection"] | undefined | null,
	assign?: ValueTypes["OrderDirection"] | undefined | null
};
	["OrderOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	state?: ValueTypes["OrderDirection"] | undefined | null,
	fulfilledAt?: ValueTypes["OrderDirection"] | undefined | null,
	canceledAt?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null,
	channel?: ValueTypes["ChannelOrderBy"] | undefined | null,
	delivery?: ValueTypes["OrderDeliveryOrderBy"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	billingAddress?: ValueTypes["AddressOrderBy"] | undefined | null,
	seq?: ValueTypes["OrderDirection"] | undefined | null,
	doubleshotLegacyId?: ValueTypes["OrderDirection"] | undefined | null,
	venue?: ValueTypes["VenueOrderBy"] | undefined | null,
	fulfillmentNote?: ValueTypes["OrderDirection"] | undefined | null,
	doubleshotTotalWeightGrams?: ValueTypes["OrderDirection"] | undefined | null,
	doubleShotLegacyNumber?: ValueTypes["OrderDirection"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["OrderDirection"] | undefined | null,
	doubleshotAdminUrl?: ValueTypes["OrderDirection"] | undefined | null
};
	["ChannelOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	disabled?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	venue?: ValueTypes["VenueOrderBy"] | undefined | null
};
	["OrderDeliveryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["OrderDirection"] | undefined | null,
	deliveredAt?: ValueTypes["OrderDirection"] | undefined | null,
	method?: ValueTypes["DeliveryMethodOrderBy"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	order?: ValueTypes["OrderOrderBy"] | undefined | null,
	expectedAt?: ValueTypes["OrderDirection"] | undefined | null,
	state?: ValueTypes["OrderDirection"] | undefined | null,
	loadingDurationHours?: ValueTypes["OrderDirection"] | undefined | null,
	dispatchAt?: ValueTypes["OrderDirection"] | undefined | null,
	duration?: ValueTypes["OrderDirection"] | undefined | null,
	window?: ValueTypes["OrderDirection"] | undefined | null,
	expectedEndAt?: ValueTypes["OrderDirection"] | undefined | null,
	processingAt?: ValueTypes["OrderDirection"] | undefined | null,
	deliveringAt?: ValueTypes["OrderDirection"] | undefined | null,
	failedAt?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	deliveryWaypoint?: ValueTypes["DeliveryWaypointOrderBy"] | undefined | null,
	prepareAt?: ValueTypes["OrderDirection"] | undefined | null,
	pickedAt?: ValueTypes["OrderDirection"] | undefined | null,
	address?: ValueTypes["AddressOrderBy"] | undefined | null,
	trackingCode?: ValueTypes["OrderDirection"] | undefined | null
};
	["DeliveryWaypointOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	estimatedArrival?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	route?: ValueTypes["DeliveryRouteOrderBy"] | undefined | null,
	sortingOrder?: ValueTypes["OrderDirection"] | undefined | null,
	delivery?: ValueTypes["OrderDeliveryOrderBy"] | undefined | null,
	estimatedDistance?: ValueTypes["OrderDirection"] | undefined | null,
	estimatedDuration?: ValueTypes["OrderDirection"] | undefined | null,
	originDeparture?: ValueTypes["OrderDirection"] | undefined | null,
	originAddress?: ValueTypes["OrderDirection"] | undefined | null,
	transitMode?: ValueTypes["OrderDirection"] | undefined | null
};
	["DeliveryRouteOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	dispatchAt?: ValueTypes["OrderDirection"] | undefined | null
};
	["FulfillmentNote"]: AliasType<{
	_meta?:ValueTypes["FulfillmentNoteMeta"],
	id?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
products?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
tags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
venues?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Venue"]],
orderItems?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderItem"]],
orders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
	highlight?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	emoji?:boolean | `@${string}`,
paginateProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginateVenues?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VenueConnection"]],
paginateOrderItems?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderItemConnection"]],
paginateOrders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FulfillmentNoteMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	tags?:ValueTypes["FieldMeta"],
	venues?:ValueTypes["FieldMeta"],
	orderItems?:ValueTypes["FieldMeta"],
	orders?:ValueTypes["FieldMeta"],
	highlight?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	emoji?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	unitPriceCents?: ValueTypes["OrderDirection"] | undefined | null,
	quantity?: ValueTypes["OrderDirection"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	order?: ValueTypes["OrderOrderBy"] | undefined | null,
	pickedAt?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null
};
	["OrderItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderItemEdge"]: AliasType<{
	node?:ValueTypes["OrderItem"],
		__typename?: boolean | `@${string}`
}>;
	["OrderConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderEdge"]: AliasType<{
	node?:ValueTypes["Order"],
		__typename?: boolean | `@${string}`
}>;
	["FulfillmentNoteOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	highlight?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	emoji?: ValueTypes["OrderDirection"] | undefined | null
};
	["VenueTimetableDaysByDateUniqueWhere"]: {
	date?: ValueTypes["Date"] | undefined | null
};
	["VenueTimetableDaysByShiftGroupsUniqueWhere"]: {
	shiftGroups?: ValueTypes["TimetableShiftGroupUniqueWhere"] | undefined | null
};
	["VenueTimetableDaysByNotesUniqueWhere"]: {
	notes?: ValueTypes["TimetableDayNoteUniqueWhere"] | undefined | null
};
	["TimetableDayNoteUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["VenueTimetableDaysByMetaUniqueWhere"]: {
	meta?: ValueTypes["TimetableDayMetadataUniqueWhere"] | undefined | null
};
	["TimetableDayMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	day?: ValueTypes["TimetableDayUniqueWhere"] | undefined | null
};
	["TimetableDayUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	venue?: ValueTypes["VenueUniqueWhere"] | undefined | null,
	date?: ValueTypes["Date"] | undefined | null,
	shiftGroups?: ValueTypes["TimetableShiftGroupUniqueWhere"] | undefined | null,
	notes?: ValueTypes["TimetableDayNoteUniqueWhere"] | undefined | null,
	meta?: ValueTypes["TimetableDayMetadataUniqueWhere"] | undefined | null
};
	["VenueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	deadlineTemplate?: ValueTypes["DeadlineTemplateUniqueWhere"] | undefined | null,
	timetableDays?: ValueTypes["TimetableDayUniqueWhere"] | undefined | null,
	timetableTemplates?: ValueTypes["TimetableTemplateUniqueWhere"] | undefined | null,
	employeeRoles?: ValueTypes["VenueEmployeeRoleUniqueWhere"] | undefined | null,
	orders?: ValueTypes["OrderUniqueWhere"] | undefined | null,
	channels?: ValueTypes["ChannelUniqueWhere"] | undefined | null
};
	["DeadlineTemplateUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	venue?: ValueTypes["VenueUniqueWhere"] | undefined | null
};
	["TimetableTemplateUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["VenueEmployeeRoleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	venue?: ValueTypes["VenueUniqueWhere"] | undefined | null,
	role?: ValueTypes["TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	employeePrivileges?: ValueTypes["TimetablesEmployeePrivilegesUniqueWhere"] | undefined | null
};
	["TimetableEmployeeRoleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shiftGroups?: ValueTypes["TimetableShiftGroupUniqueWhere"] | undefined | null,
	timetableTemplates?: ValueTypes["TimetableTemplateUniqueWhere"] | undefined | null
};
	["TimetablesEmployeePrivilegesUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	venueEmployeeRole?: ValueTypes["VenueEmployeeRoleUniqueWhere"] | undefined | null
};
	["ChannelUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: ValueTypes["ChannelCode"] | undefined | null
};
	["VenueEmployeeRolesByRoleUniqueWhere"]: {
	role?: ValueTypes["TimetableEmployeeRoleUniqueWhere"] | undefined | null
};
	["VenueEmployeeRolesByEmployeePrivilegesUniqueWhere"]: {
	employeePrivileges?: ValueTypes["TimetablesEmployeePrivilegesUniqueWhere"] | undefined | null
};
	["VenueOrdersBySeqUniqueWhere"]: {
	seq?: number | undefined | null
};
	["VenueOrdersByDoubleshotLegacyIdUniqueWhere"]: {
	doubleshotLegacyId?: string | undefined | null
};
	["VenueOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
	doubleShotLegacyNumber?: string | undefined | null
};
	["VenueOrdersByDeliveryUniqueWhere"]: {
	delivery?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null
};
	["VenueOrdersByItemsUniqueWhere"]: {
	items?: ValueTypes["OrderItemUniqueWhere"] | undefined | null
};
	["VenueOrdersByPaymentsUniqueWhere"]: {
	payments?: ValueTypes["OrderPaymentUniqueWhere"] | undefined | null
};
	["VenueChannelsByCodeUniqueWhere"]: {
	code?: ValueTypes["ChannelCode"] | undefined | null
};
	["TimetableDayConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TimetableDayEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableDayEdge"]: AliasType<{
	node?:ValueTypes["TimetableDay"],
		__typename?: boolean | `@${string}`
}>;
	["StaffConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["StaffEdge"],
		__typename?: boolean | `@${string}`
}>;
	["StaffEdge"]: AliasType<{
	node?:ValueTypes["Staff"],
		__typename?: boolean | `@${string}`
}>;
	["VenueEmployeeRoleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VenueEmployeeRoleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VenueEmployeeRoleEdge"]: AliasType<{
	node?:ValueTypes["VenueEmployeeRole"],
		__typename?: boolean | `@${string}`
}>;
	["FulfillmentNoteConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FulfillmentNoteEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FulfillmentNoteEdge"]: AliasType<{
	node?:ValueTypes["FulfillmentNote"],
		__typename?: boolean | `@${string}`
}>;
	["UnassignReasonConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["UnassignReasonEdge"],
		__typename?: boolean | `@${string}`
}>;
	["UnassignReasonEdge"]: AliasType<{
	node?:ValueTypes["UnassignReason"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ChannelEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelEdge"]: AliasType<{
	node?:ValueTypes["Channel"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CustomerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerEdge"]: AliasType<{
	node?:ValueTypes["Customer"],
		__typename?: boolean | `@${string}`
}>;
	["OrderDelivery"]: AliasType<{
	_meta?:ValueTypes["OrderDeliveryMeta"],
	id?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
	deliveredAt?:boolean | `@${string}`,
method?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
order?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
	expectedAt?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	loadingDurationHours?:boolean | `@${string}`,
	dispatchAt?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	window?:boolean | `@${string}`,
	expectedEndAt?:boolean | `@${string}`,
	processingAt?:boolean | `@${string}`,
	deliveringAt?:boolean | `@${string}`,
	failedAt?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
deliveryWaypoint?: [{	filter?: ValueTypes["DeliveryWaypointWhere"] | undefined | null},ValueTypes["DeliveryWaypoint"]],
	prepareAt?:boolean | `@${string}`,
	pickedAt?:boolean | `@${string}`,
address?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
	trackingCode?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderDeliveryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	priceCents?:ValueTypes["FieldMeta"],
	deliveredAt?:ValueTypes["FieldMeta"],
	method?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	expectedAt?:ValueTypes["FieldMeta"],
	state?:ValueTypes["FieldMeta"],
	loadingDurationHours?:ValueTypes["FieldMeta"],
	dispatchAt?:ValueTypes["FieldMeta"],
	duration?:ValueTypes["FieldMeta"],
	window?:ValueTypes["FieldMeta"],
	expectedEndAt?:ValueTypes["FieldMeta"],
	processingAt?:ValueTypes["FieldMeta"],
	deliveringAt?:ValueTypes["FieldMeta"],
	failedAt?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	deliveryWaypoint?:ValueTypes["FieldMeta"],
	prepareAt?:ValueTypes["FieldMeta"],
	pickedAt?:ValueTypes["FieldMeta"],
	address?:ValueTypes["FieldMeta"],
	trackingCode?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryWaypoint"]: AliasType<{
	_meta?:ValueTypes["DeliveryWaypointMeta"],
	id?:boolean | `@${string}`,
	estimatedArrival?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
route?: [{	filter?: ValueTypes["DeliveryRouteWhere"] | undefined | null},ValueTypes["DeliveryRoute"]],
	sortingOrder?:boolean | `@${string}`,
delivery?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
	estimatedDistance?:boolean | `@${string}`,
	estimatedDuration?:boolean | `@${string}`,
	originDeparture?:boolean | `@${string}`,
	originAddress?:boolean | `@${string}`,
	transitMode?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeliveryWaypointMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	estimatedArrival?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	route?:ValueTypes["FieldMeta"],
	sortingOrder?:ValueTypes["FieldMeta"],
	delivery?:ValueTypes["FieldMeta"],
	estimatedDistance?:ValueTypes["FieldMeta"],
	estimatedDuration?:ValueTypes["FieldMeta"],
	originDeparture?:ValueTypes["FieldMeta"],
	originAddress?:ValueTypes["FieldMeta"],
	transitMode?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRoute"]: AliasType<{
	_meta?:ValueTypes["DeliveryRouteMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
waypoints?: [{	filter?: ValueTypes["DeliveryWaypointWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryWaypointOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryWaypoint"]],
	dispatchAt?:boolean | `@${string}`,
waypointsByDelivery?: [{	by: ValueTypes["DeliveryRouteWaypointsByDeliveryUniqueWhere"],	filter?: ValueTypes["DeliveryWaypointWhere"] | undefined | null},ValueTypes["DeliveryWaypoint"]],
paginateWaypoints?: [{	filter?: ValueTypes["DeliveryWaypointWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryWaypointOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryWaypointConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRouteMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	waypoints?:ValueTypes["FieldMeta"],
	dispatchAt?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRouteWaypointsByDeliveryUniqueWhere"]: {
	delivery?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null
};
	["DeliveryWaypointConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryWaypointEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryWaypointEdge"]: AliasType<{
	node?:ValueTypes["DeliveryWaypoint"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPayment"]: AliasType<{
	_meta?:ValueTypes["OrderPaymentMeta"],
	id?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
	paymentCents?:boolean | `@${string}`,
method?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null},ValueTypes["PaymentMethod"]],
	failedAt?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	approvedAt?:boolean | `@${string}`,
order?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
	walletType?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	publicReceiptUrl?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
	otherCurrencyPriceCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	data?:ValueTypes["FieldMeta"],
	paymentCents?:ValueTypes["FieldMeta"],
	method?:ValueTypes["FieldMeta"],
	failedAt?:ValueTypes["FieldMeta"],
	state?:ValueTypes["FieldMeta"],
	approvedAt?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	walletType?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	publicReceiptUrl?:ValueTypes["FieldMeta"],
	priceCents?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	otherCurrencyPriceCents?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Json"]:unknown;
	["OrderPaymentOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	data?: ValueTypes["OrderDirection"] | undefined | null,
	paymentCents?: ValueTypes["OrderDirection"] | undefined | null,
	method?: ValueTypes["PaymentMethodOrderBy"] | undefined | null,
	failedAt?: ValueTypes["OrderDirection"] | undefined | null,
	state?: ValueTypes["OrderDirection"] | undefined | null,
	approvedAt?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderOrderBy"] | undefined | null,
	walletType?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	publicReceiptUrl?: ValueTypes["OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["OrderDirection"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	otherCurrencyPriceCents?: ValueTypes["OrderDirection"] | undefined | null
};
	["OrderPaymentConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderPaymentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentEdge"]: AliasType<{
	node?:ValueTypes["OrderPayment"],
		__typename?: boolean | `@${string}`
}>;
	["ProductMetadata"]: AliasType<{
	_meta?:ValueTypes["ProductMetadataMeta"],
	id?:boolean | `@${string}`,
	archived?:boolean | `@${string}`,
	available?:boolean | `@${string}`,
	availableQuantity?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductMetadataMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	archived?:ValueTypes["FieldMeta"],
	available?:ValueTypes["FieldMeta"],
	availableQuantity?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductStocksByStoreUniqueWhere"]: {
	store?: ValueTypes["StoreUniqueWhere"] | undefined | null
};
	["ProductLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductLocalesByFeatureListUniqueWhere"]: {
	featureList?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["ProductStockConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductStockEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductStockEdge"]: AliasType<{
	node?:ValueTypes["ProductStock"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerName"]: AliasType<{
	_meta?:ValueTypes["CustomerNameMeta"],
	id?:boolean | `@${string}`,
	nominative?:boolean | `@${string}`,
	vocative?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
		__typename?: boolean | `@${string}`
}>;
	["CustomerNameMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	nominative?:ValueTypes["FieldMeta"],
	vocative?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerMetadata"]: AliasType<{
	_meta?:ValueTypes["CustomerMetadataMeta"],
	id?:boolean | `@${string}`,
	isBusiness?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	fallbackName?:boolean | `@${string}`,
	remainingCreditsCents?:boolean | `@${string}`,
	ordersTotalCents?:boolean | `@${string}`,
	customerProfileUrl?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
		__typename?: boolean | `@${string}`
}>;
	["CustomerMetadataMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	isBusiness?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	fallbackName?:ValueTypes["FieldMeta"],
	remainingCreditsCents?:ValueTypes["FieldMeta"],
	ordersTotalCents?:ValueTypes["FieldMeta"],
	customerProfileUrl?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerAddressesByBillingAddressOfOrdersUniqueWhere"]: {
	billingAddressOfOrders?: ValueTypes["OrderUniqueWhere"] | undefined | null
};
	["CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: {
	deliveryAddressOfOrderDeliveries?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null
};
	["CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: {
	defaultDeliveryAddressOfCustomer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["CustomerOrdersBySeqUniqueWhere"]: {
	seq?: number | undefined | null
};
	["CustomerOrdersByDoubleshotLegacyIdUniqueWhere"]: {
	doubleshotLegacyId?: string | undefined | null
};
	["CustomerOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
	doubleShotLegacyNumber?: string | undefined | null
};
	["CustomerOrdersByDeliveryUniqueWhere"]: {
	delivery?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null
};
	["CustomerOrdersByItemsUniqueWhere"]: {
	items?: ValueTypes["OrderItemUniqueWhere"] | undefined | null
};
	["CustomerOrdersByPaymentsUniqueWhere"]: {
	payments?: ValueTypes["OrderPaymentUniqueWhere"] | undefined | null
};
	["Country"]: AliasType<{
	_meta?:ValueTypes["CountryMeta"],
	id?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CountryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AddressBillingAddressOfOrdersBySeqUniqueWhere"]: {
	seq?: number | undefined | null
};
	["AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere"]: {
	doubleshotLegacyId?: string | undefined | null
};
	["AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
	doubleShotLegacyNumber?: string | undefined | null
};
	["AddressBillingAddressOfOrdersByDeliveryUniqueWhere"]: {
	delivery?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null
};
	["AddressBillingAddressOfOrdersByItemsUniqueWhere"]: {
	items?: ValueTypes["OrderItemUniqueWhere"] | undefined | null
};
	["AddressBillingAddressOfOrdersByPaymentsUniqueWhere"]: {
	payments?: ValueTypes["OrderPaymentUniqueWhere"] | undefined | null
};
	["AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere"]: {
	trackingCode?: string | undefined | null
};
	["AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere"]: {
	order?: ValueTypes["OrderUniqueWhere"] | undefined | null
};
	["AddressDeliveryAddressOfOrderDeliveriesByDeliveryWaypointUniqueWhere"]: {
	deliveryWaypoint?: ValueTypes["DeliveryWaypointUniqueWhere"] | undefined | null
};
	["OrderDeliveryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderDeliveryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderDeliveryEdge"]: AliasType<{
	node?:ValueTypes["OrderDelivery"],
		__typename?: boolean | `@${string}`
}>;
	["CountryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null
};
	["CountryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CountryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CountryEdge"]: AliasType<{
	node?:ValueTypes["Country"],
		__typename?: boolean | `@${string}`
}>;
	["PaymentMethodUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null
};
	["VatRateUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	preset?: ValueTypes["VatRatePreset"] | undefined | null
};
	["VatRateConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VatRateEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VatRateEdge"]: AliasType<{
	node?:ValueTypes["VatRate"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductPackingEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingEdge"]: AliasType<{
	node?:ValueTypes["ProductPacking"],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductRecipeEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeEdge"]: AliasType<{
	node?:ValueTypes["ProductRecipe"],
		__typename?: boolean | `@${string}`
}>;
	["StoreConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["StoreEdge"],
		__typename?: boolean | `@${string}`
}>;
	["StoreEdge"]: AliasType<{
	node?:ValueTypes["Store"],
		__typename?: boolean | `@${string}`
}>;
	["TagUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null
};
	["ImageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageEdge"]: AliasType<{
	node?:ValueTypes["Image"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListEdge"]: AliasType<{
	node?:ValueTypes["ProductList"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListEdge"]: AliasType<{
	node?:ValueTypes["ProductCategoryList"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleEdge"]: AliasType<{
	node?:ValueTypes["Locale"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerNameConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CustomerNameEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerNameEdge"]: AliasType<{
	node?:ValueTypes["CustomerName"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemQuantityConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientItemQuantityEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemQuantityEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredientItemQuantity"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemQuantityUnitUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null
};
	["ProductIngredientItemQuantityUnitConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientItemQuantityUnitEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemQuantityUnitEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredientItemQuantityUnit"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientRating"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientRatingMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
icon?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientRatingMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientRatingUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ProductIngredientRatingWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	icon?: ValueTypes["ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientRatingWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientRatingWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null
};
	["ProductIngredientRatingOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	icon?: ValueTypes["ImageOrderBy"] | undefined | null
};
	["ProductIngredientRatingConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientRatingEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientRatingEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredientRating"],
		__typename?: boolean | `@${string}`
}>;
	["BlogConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogEdge"]: AliasType<{
	node?:ValueTypes["Blog"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostEdge"]: AliasType<{
	node?:ValueTypes["BlogPost"],
		__typename?: boolean | `@${string}`
}>;
	["ColorPalleteUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ColorPalleteConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ColorPalleteEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ColorPalleteEdge"]: AliasType<{
	node?:ValueTypes["ColorPallete"],
		__typename?: boolean | `@${string}`
}>;
	["ContentConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentEdge"]: AliasType<{
	node?:ValueTypes["Content"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["BlogPostListItemUniqueWhere"] | undefined | null
};
	["BlogPostListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["BlogPostListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListEdge"]: AliasType<{
	node?:ValueTypes["BlogPostList"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["LinkListItemUniqueWhere"] | undefined | null
};
	["LinkListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["LinkListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListEdge"]: AliasType<{
	node?:ValueTypes["LinkList"],
		__typename?: boolean | `@${string}`
}>;
	["CoverConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CoverEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CoverEdge"]: AliasType<{
	node?:ValueTypes["Cover"],
		__typename?: boolean | `@${string}`
}>;
	["LinkConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkEdge"]: AliasType<{
	node?:ValueTypes["Link"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkableEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableEdge"]: AliasType<{
	node?:ValueTypes["Linkable"],
		__typename?: boolean | `@${string}`
}>;
	["MediumUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["MediumConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["MediumEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MediumEdge"]: AliasType<{
	node?:ValueTypes["Medium"],
		__typename?: boolean | `@${string}`
}>;
	["VideoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["VideoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VideoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VideoEdge"]: AliasType<{
	node?:ValueTypes["Video"],
		__typename?: boolean | `@${string}`
}>;
	["SeoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SeoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SeoEdge"]: AliasType<{
	node?:ValueTypes["Seo"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationDomainEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainEdge"]: AliasType<{
	node?:ValueTypes["TranslationDomain"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationCataloguesIdentifierEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierEdge"]: AliasType<{
	node?:ValueTypes["TranslationCataloguesIdentifier"],
		__typename?: boolean | `@${string}`
}>;
	["SocialsAndAppsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["SocialsAndAppsConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SocialsAndAppsEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SocialsAndAppsEdge"]: AliasType<{
	node?:ValueTypes["SocialsAndApps"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	categories?: ValueTypes["ProductGridCategoryUniqueWhere"] | undefined | null
};
	["ProductGridCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["ProductGridItemUniqueWhere"] | undefined | null,
	images?: ValueTypes["ImageListUniqueWhere"] | undefined | null
};
	["ProductGridConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductGridEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridEdge"]: AliasType<{
	node?:ValueTypes["ProductGrid"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["GalleryItemUniqueWhere"] | undefined | null
};
	["GalleryItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["GalleryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GalleryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryEdge"]: AliasType<{
	node?:ValueTypes["Gallery"],
		__typename?: boolean | `@${string}`
}>;
	["HeroUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["HeroConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HeroEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HeroEdge"]: AliasType<{
	node?:ValueTypes["Hero"],
		__typename?: boolean | `@${string}`
}>;
	["PageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PageEdge"]: AliasType<{
	node?:ValueTypes["Page"],
		__typename?: boolean | `@${string}`
}>;
	["DeadlineTemplateConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeadlineTemplateEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeadlineTemplateEdge"]: AliasType<{
	node?:ValueTypes["DeadlineTemplate"],
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueueConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["EmployeeQueueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueueEdge"]: AliasType<{
	node?:ValueTypes["EmployeeQueue"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationResult"]: AliasType<{
	valid?:boolean | `@${string}`,
	errors?:ValueTypes["_ValidationError"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationError"]: AliasType<{
	path?:ValueTypes["_PathFragment"],
	message?:ValueTypes["_ValidationMessage"],
		__typename?: boolean | `@${string}`
}>;
	["_PathFragment"]: AliasType<{		["...on _FieldPathFragment"] : ValueTypes["_FieldPathFragment"],
		["...on _IndexPathFragment"] : ValueTypes["_IndexPathFragment"]
		__typename?: boolean | `@${string}`
}>;
	["_FieldPathFragment"]: AliasType<{
	field?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_IndexPathFragment"]: AliasType<{
	index?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_ValidationMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueueCreateInput"]: {
	items?: Array<ValueTypes["EmployeeQueueCreateItemsEntityRelationInput"]> | undefined | null,
	contemberBugFix?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["EmployeeQueueCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["EmployeeQueueItemWithoutQueueCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["EmployeeQueueItemWithoutQueueCreateInput"]: {
	start?: string | undefined | null,
	end?: string | undefined | null,
	shiftsProfile?: ValueTypes["EmployeeQueueItemCreateShiftsProfileEntityRelationInput"] | undefined | null,
	unassignInfo?: ValueTypes["EmployeeQueueItemCreateUnassignInfoEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["EmployeeQueueItemCreateShiftsProfileEntityRelationInput"]: {
	connect?: ValueTypes["ShiftsProfileUniqueWhere"] | undefined | null
};
	["EmployeeQueueItemCreateUnassignInfoEntityRelationInput"]: {
	connect?: ValueTypes["UnassignedShiftInfoUniqueWhere"] | undefined | null,
	create?: ValueTypes["UnassignedShiftInfoWithoutQueueItemCreateInput"] | undefined | null
};
	["UnassignedShiftInfoWithoutQueueItemCreateInput"]: {
	hoursMissed?: number | undefined | null,
	legacyReason?: ValueTypes["UnassignedInfoReason"] | undefined | null,
	reason?: ValueTypes["UnassignedShiftInfoCreateReasonEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["UnassignedShiftInfoCreateReasonEntityRelationInput"]: {
	connect?: ValueTypes["UnassignReasonUniqueWhere"] | undefined | null
};
	["UnassignReasonUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["EmployeeQueueUpdateInput"]: {
	items?: Array<ValueTypes["EmployeeQueueUpdateItemsEntityRelationInput"]> | undefined | null,
	contemberBugFix?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["EmployeeQueueUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["EmployeeQueueItemWithoutQueueCreateInput"] | undefined | null,
	update?: ValueTypes["EmployeeQueueUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["EmployeeQueueUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["EmployeeQueueUpdateItemsRelationInput"]: {
	by?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["EmployeeQueueItemWithoutQueueUpdateInput"] | undefined | null
};
	["EmployeeQueueItemWithoutQueueUpdateInput"]: {
	start?: string | undefined | null,
	end?: string | undefined | null,
	shiftsProfile?: ValueTypes["EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	shift?: ValueTypes["EmployeeQueueItemUpdateShiftEntityRelationInput"] | undefined | null,
	unassignInfo?: ValueTypes["EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"]: {
	update?: ValueTypes["ShiftsProfileWithoutQueueItemsUpdateInput"] | undefined | null,
	connect?: ValueTypes["ShiftsProfileUniqueWhere"] | undefined | null
};
	["ShiftsProfileWithoutQueueItemsUpdateInput"]: {
	shifts?: Array<ValueTypes["ShiftsProfileUpdateShiftsEntityRelationInput"]> | undefined | null,
	allowSelfUnassigning?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["ShiftsProfileUpdateShiftsEntityRelationInput"]: {
	update?: ValueTypes["ShiftsProfileUpdateShiftsRelationInput"] | undefined | null,
	connect?: ValueTypes["TimetableShiftUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["TimetableShiftUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["ShiftsProfileUpdateShiftsRelationInput"]: {
	by?: ValueTypes["TimetableShiftUniqueWhere"] | undefined | null,
	data?: ValueTypes["TimetableShiftWithoutShiftsProfileUpdateInput"] | undefined | null
};
	["TimetableShiftWithoutShiftsProfileUpdateInput"]: {
	locked?: boolean | undefined | null,
	order?: number | undefined | null,
	assigned?: ValueTypes["Date"] | undefined | null,
	group?: ValueTypes["TimetableShiftUpdateGroupEntityRelationInput"] | undefined | null,
	queueItem?: ValueTypes["TimetableShiftUpdateQueueItemEntityRelationInput"] | undefined | null,
	isOvertime?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["TimetableShiftUpdateGroupEntityRelationInput"]: {
	connect?: ValueTypes["TimetableShiftGroupUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["TimetableShiftUpdateQueueItemEntityRelationInput"]: {
	create?: ValueTypes["EmployeeQueueItemWithoutShiftCreateInput"] | undefined | null,
	update?: ValueTypes["EmployeeQueueItemWithoutShiftUpdateInput"] | undefined | null,
	upsert?: ValueTypes["TimetableShiftUpsertQueueItemRelationInput"] | undefined | null,
	connect?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["EmployeeQueueItemWithoutShiftCreateInput"]: {
	start?: string | undefined | null,
	end?: string | undefined | null,
	queue?: ValueTypes["EmployeeQueueItemCreateQueueEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["EmployeeQueueItemCreateShiftsProfileEntityRelationInput"] | undefined | null,
	unassignInfo?: ValueTypes["EmployeeQueueItemCreateUnassignInfoEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["EmployeeQueueItemCreateQueueEntityRelationInput"]: {
	connect?: ValueTypes["EmployeeQueueUniqueWhere"] | undefined | null,
	create?: ValueTypes["EmployeeQueueWithoutItemsCreateInput"] | undefined | null
};
	["EmployeeQueueWithoutItemsCreateInput"]: {
	contemberBugFix?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["EmployeeQueueItemWithoutShiftUpdateInput"]: {
	start?: string | undefined | null,
	end?: string | undefined | null,
	queue?: ValueTypes["EmployeeQueueItemUpdateQueueEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	unassignInfo?: ValueTypes["EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["EmployeeQueueItemUpdateQueueEntityRelationInput"]: {
	create?: ValueTypes["EmployeeQueueWithoutItemsCreateInput"] | undefined | null,
	update?: ValueTypes["EmployeeQueueWithoutItemsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["EmployeeQueueItemUpsertQueueRelationInput"] | undefined | null,
	connect?: ValueTypes["EmployeeQueueUniqueWhere"] | undefined | null
};
	["EmployeeQueueWithoutItemsUpdateInput"]: {
	contemberBugFix?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["EmployeeQueueItemUpsertQueueRelationInput"]: {
	update?: ValueTypes["EmployeeQueueWithoutItemsUpdateInput"] | undefined | null,
	create?: ValueTypes["EmployeeQueueWithoutItemsCreateInput"] | undefined | null
};
	["EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"]: {
	create?: ValueTypes["UnassignedShiftInfoWithoutQueueItemCreateInput"] | undefined | null,
	update?: ValueTypes["UnassignedShiftInfoWithoutQueueItemUpdateInput"] | undefined | null,
	upsert?: ValueTypes["EmployeeQueueItemUpsertUnassignInfoRelationInput"] | undefined | null,
	connect?: ValueTypes["UnassignedShiftInfoUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["UnassignedShiftInfoWithoutQueueItemUpdateInput"]: {
	hoursMissed?: number | undefined | null,
	legacyReason?: ValueTypes["UnassignedInfoReason"] | undefined | null,
	reason?: ValueTypes["UnassignedShiftInfoUpdateReasonEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["UnassignedShiftInfoUpdateReasonEntityRelationInput"]: {
	connect?: ValueTypes["UnassignReasonUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["EmployeeQueueItemUpsertUnassignInfoRelationInput"]: {
	update?: ValueTypes["UnassignedShiftInfoWithoutQueueItemUpdateInput"] | undefined | null,
	create?: ValueTypes["UnassignedShiftInfoWithoutQueueItemCreateInput"] | undefined | null
};
	["TimetableShiftUpsertQueueItemRelationInput"]: {
	update?: ValueTypes["EmployeeQueueItemWithoutShiftUpdateInput"] | undefined | null,
	create?: ValueTypes["EmployeeQueueItemWithoutShiftCreateInput"] | undefined | null
};
	["EmployeeQueueItemUpdateShiftEntityRelationInput"]: {
	update?: ValueTypes["TimetableShiftWithoutQueueItemUpdateInput"] | undefined | null,
	connect?: ValueTypes["TimetableShiftUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["TimetableShiftWithoutQueueItemUpdateInput"]: {
	locked?: boolean | undefined | null,
	order?: number | undefined | null,
	assigned?: ValueTypes["Date"] | undefined | null,
	group?: ValueTypes["TimetableShiftUpdateGroupEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["TimetableShiftUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	isOvertime?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["TimetableShiftUpdateShiftsProfileEntityRelationInput"]: {
	update?: ValueTypes["ShiftsProfileWithoutShiftsUpdateInput"] | undefined | null,
	connect?: ValueTypes["ShiftsProfileUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["ShiftsProfileWithoutShiftsUpdateInput"]: {
	queueItems?: Array<ValueTypes["ShiftsProfileUpdateQueueItemsEntityRelationInput"]> | undefined | null,
	allowSelfUnassigning?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["ShiftsProfileUpdateQueueItemsEntityRelationInput"]: {
	create?: ValueTypes["EmployeeQueueItemWithoutShiftsProfileCreateInput"] | undefined | null,
	update?: ValueTypes["ShiftsProfileUpdateQueueItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["ShiftsProfileUpsertQueueItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["EmployeeQueueItemWithoutShiftsProfileCreateInput"]: {
	start?: string | undefined | null,
	end?: string | undefined | null,
	queue?: ValueTypes["EmployeeQueueItemCreateQueueEntityRelationInput"] | undefined | null,
	unassignInfo?: ValueTypes["EmployeeQueueItemCreateUnassignInfoEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["ShiftsProfileUpdateQueueItemsRelationInput"]: {
	by?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["EmployeeQueueItemWithoutShiftsProfileUpdateInput"] | undefined | null
};
	["EmployeeQueueItemWithoutShiftsProfileUpdateInput"]: {
	start?: string | undefined | null,
	end?: string | undefined | null,
	queue?: ValueTypes["EmployeeQueueItemUpdateQueueEntityRelationInput"] | undefined | null,
	shift?: ValueTypes["EmployeeQueueItemUpdateShiftEntityRelationInput"] | undefined | null,
	unassignInfo?: ValueTypes["EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["ShiftsProfileUpsertQueueItemsRelationInput"]: {
	by?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["EmployeeQueueItemWithoutShiftsProfileUpdateInput"] | undefined | null,
	create?: ValueTypes["EmployeeQueueItemWithoutShiftsProfileCreateInput"] | undefined | null
};
	["EmployeeQueueUpsertItemsRelationInput"]: {
	by?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["EmployeeQueueItemWithoutQueueUpdateInput"] | undefined | null,
	create?: ValueTypes["EmployeeQueueItemWithoutQueueCreateInput"] | undefined | null
};
	["TimetableShiftUpdateInput"]: {
	locked?: boolean | undefined | null,
	order?: number | undefined | null,
	assigned?: ValueTypes["Date"] | undefined | null,
	group?: ValueTypes["TimetableShiftUpdateGroupEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["TimetableShiftUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	queueItem?: ValueTypes["TimetableShiftUpdateQueueItemEntityRelationInput"] | undefined | null,
	isOvertime?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["TimetableShiftPositionUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shiftGroups?: ValueTypes["TimetableShiftGroupUniqueWhere"] | undefined | null,
	timetableTemplates?: ValueTypes["TimetableTemplateUniqueWhere"] | undefined | null
};
	["TimetableShiftPositionConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TimetableShiftPositionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableShiftPositionEdge"]: AliasType<{
	node?:ValueTypes["TimetableShiftPosition"],
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueueItemCreateInput"]: {
	start?: string | undefined | null,
	end?: string | undefined | null,
	queue?: ValueTypes["EmployeeQueueItemCreateQueueEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["EmployeeQueueItemCreateShiftsProfileEntityRelationInput"] | undefined | null,
	unassignInfo?: ValueTypes["EmployeeQueueItemCreateUnassignInfoEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["EmployeeQueueItemUpdateInput"]: {
	start?: string | undefined | null,
	end?: string | undefined | null,
	queue?: ValueTypes["EmployeeQueueItemUpdateQueueEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	shift?: ValueTypes["EmployeeQueueItemUpdateShiftEntityRelationInput"] | undefined | null,
	unassignInfo?: ValueTypes["EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["ProductBannerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	button?: ValueTypes["LinkUniqueWhere"] | undefined | null
};
	["ProductBannerConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductBannerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerEdge"]: AliasType<{
	node?:ValueTypes["ProductBanner"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductBannerListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerListEdge"]: AliasType<{
	node?:ValueTypes["ProductBannerList"],
		__typename?: boolean | `@${string}`
}>;
	["ShiftsProfileUpdateInput"]: {
	queueItems?: Array<ValueTypes["ShiftsProfileUpdateQueueItemsEntityRelationInput"]> | undefined | null,
	shifts?: Array<ValueTypes["ShiftsProfileUpdateShiftsEntityRelationInput"]> | undefined | null,
	allowSelfUnassigning?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["DeliveryRouteUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	waypoints?: ValueTypes["DeliveryWaypointUniqueWhere"] | undefined | null
};
	["DeliveryRouteConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryRouteEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRouteEdge"]: AliasType<{
	node?:ValueTypes["DeliveryRoute"],
		__typename?: boolean | `@${string}`
}>;
	["WeekHoursUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["WeekHoursConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["WeekHoursEdge"],
		__typename?: boolean | `@${string}`
}>;
	["WeekHoursEdge"]: AliasType<{
	node?:ValueTypes["WeekHours"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedIngredientQuantity"]: AliasType<{
	_meta?:ValueTypes["PreparedIngredientQuantityMeta"],
	id?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	usedQuantity?:boolean | `@${string}`,
ingredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null},ValueTypes["ProductIngredient"]],
unit?: [{	filter?: ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null},ValueTypes["ProductIngredientItemQuantityUnit"]],
		__typename?: boolean | `@${string}`
}>;
	["PreparedIngredientQuantityMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	date?:ValueTypes["FieldMeta"],
	quantity?:ValueTypes["FieldMeta"],
	usedQuantity?:ValueTypes["FieldMeta"],
	ingredient?:ValueTypes["FieldMeta"],
	unit?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedIngredientQuantityUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	date?: ValueTypes["Date"] | undefined | null,
	ingredient?: ValueTypes["ProductIngredientUniqueWhere"] | undefined | null,
	unit?: ValueTypes["ProductIngredientItemQuantityUnitUniqueWhere"] | undefined | null
};
	["PreparedIngredientQuantityWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	date?: ValueTypes["DateCondition"] | undefined | null,
	quantity?: ValueTypes["FloatCondition"] | undefined | null,
	usedQuantity?: ValueTypes["FloatCondition"] | undefined | null,
	ingredient?: ValueTypes["ProductIngredientWhere"] | undefined | null,
	unit?: ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null,
	and?: Array<ValueTypes["PreparedIngredientQuantityWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["PreparedIngredientQuantityWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["PreparedIngredientQuantityWhere"] | undefined | null
};
	["PreparedIngredientQuantityOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	date?: ValueTypes["OrderDirection"] | undefined | null,
	quantity?: ValueTypes["OrderDirection"] | undefined | null,
	usedQuantity?: ValueTypes["OrderDirection"] | undefined | null,
	ingredient?: ValueTypes["ProductIngredientOrderBy"] | undefined | null,
	unit?: ValueTypes["ProductIngredientItemQuantityUnitOrderBy"] | undefined | null
};
	["PreparedIngredientQuantityConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PreparedIngredientQuantityEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedIngredientQuantityEdge"]: AliasType<{
	node?:ValueTypes["PreparedIngredientQuantity"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedIngredientQuantityCreateInput"]: {
	date?: ValueTypes["Date"] | undefined | null,
	quantity?: number | undefined | null,
	usedQuantity?: number | undefined | null,
	ingredient?: ValueTypes["PreparedIngredientQuantityCreateIngredientEntityRelationInput"] | undefined | null,
	unit?: ValueTypes["PreparedIngredientQuantityCreateUnitEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["PreparedIngredientQuantityCreateIngredientEntityRelationInput"]: {
	connect?: ValueTypes["ProductIngredientUniqueWhere"] | undefined | null
};
	["PreparedIngredientQuantityCreateUnitEntityRelationInput"]: {
	connect?: ValueTypes["ProductIngredientItemQuantityUnitUniqueWhere"] | undefined | null
};
	["PreparedIngredientQuantityUpdateInput"]: {
	date?: ValueTypes["Date"] | undefined | null,
	quantity?: number | undefined | null,
	usedQuantity?: number | undefined | null,
	ingredient?: ValueTypes["PreparedIngredientQuantityUpdateIngredientEntityRelationInput"] | undefined | null,
	unit?: ValueTypes["PreparedIngredientQuantityUpdateUnitEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["PreparedIngredientQuantityUpdateIngredientEntityRelationInput"]: {
	connect?: ValueTypes["ProductIngredientUniqueWhere"] | undefined | null
};
	["PreparedIngredientQuantityUpdateUnitEntityRelationInput"]: {
	connect?: ValueTypes["ProductIngredientItemQuantityUnitUniqueWhere"] | undefined | null
};
	["PreparedRecipeQuantity"]: AliasType<{
	_meta?:ValueTypes["PreparedRecipeQuantityMeta"],
	id?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
recipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
	usedQuantity?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PreparedRecipeQuantityMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	date?:ValueTypes["FieldMeta"],
	quantity?:ValueTypes["FieldMeta"],
	recipe?:ValueTypes["FieldMeta"],
	usedQuantity?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedRecipeQuantityUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	date?: ValueTypes["Date"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeUniqueWhere"] | undefined | null
};
	["PreparedRecipeQuantityWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	date?: ValueTypes["DateCondition"] | undefined | null,
	quantity?: ValueTypes["FloatCondition"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeWhere"] | undefined | null,
	usedQuantity?: ValueTypes["FloatCondition"] | undefined | null,
	and?: Array<ValueTypes["PreparedRecipeQuantityWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["PreparedRecipeQuantityWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["PreparedRecipeQuantityWhere"] | undefined | null
};
	["PreparedRecipeQuantityOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	date?: ValueTypes["OrderDirection"] | undefined | null,
	quantity?: ValueTypes["OrderDirection"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeOrderBy"] | undefined | null,
	usedQuantity?: ValueTypes["OrderDirection"] | undefined | null
};
	["PreparedRecipeQuantityConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PreparedRecipeQuantityEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedRecipeQuantityEdge"]: AliasType<{
	node?:ValueTypes["PreparedRecipeQuantity"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedRecipeQuantityCreateInput"]: {
	date?: ValueTypes["Date"] | undefined | null,
	quantity?: number | undefined | null,
	recipe?: ValueTypes["PreparedRecipeQuantityCreateRecipeEntityRelationInput"] | undefined | null,
	usedQuantity?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["PreparedRecipeQuantityCreateRecipeEntityRelationInput"]: {
	connect?: ValueTypes["ProductRecipeUniqueWhere"] | undefined | null
};
	["PreparedRecipeQuantityUpdateInput"]: {
	date?: ValueTypes["Date"] | undefined | null,
	quantity?: number | undefined | null,
	recipe?: ValueTypes["PreparedRecipeQuantityUpdateRecipeEntityRelationInput"] | undefined | null,
	usedQuantity?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["PreparedRecipeQuantityUpdateRecipeEntityRelationInput"]: {
	connect?: ValueTypes["ProductRecipeUniqueWhere"] | undefined | null
};
	["PreparedPackingQuantityCreateInput"]: {
	date?: ValueTypes["Date"] | undefined | null,
	quantity?: number | undefined | null,
	recipe?: ValueTypes["PreparedPackingQuantityCreateRecipeEntityRelationInput"] | undefined | null,
	packing?: ValueTypes["PreparedPackingQuantityCreatePackingEntityRelationInput"] | undefined | null,
	packedInCardboard?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["PreparedPackingQuantityCreateRecipeEntityRelationInput"]: {
	connect?: ValueTypes["ProductRecipeUniqueWhere"] | undefined | null
};
	["PreparedPackingQuantityCreatePackingEntityRelationInput"]: {
	connect?: ValueTypes["ProductPackingUniqueWhere"] | undefined | null
};
	["PreparedPackingQuantityUpdateInput"]: {
	date?: ValueTypes["Date"] | undefined | null,
	quantity?: number | undefined | null,
	recipe?: ValueTypes["PreparedPackingQuantityUpdateRecipeEntityRelationInput"] | undefined | null,
	packing?: ValueTypes["PreparedPackingQuantityUpdatePackingEntityRelationInput"] | undefined | null,
	packedInCardboard?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["PreparedPackingQuantityUpdateRecipeEntityRelationInput"]: {
	connect?: ValueTypes["ProductRecipeUniqueWhere"] | undefined | null
};
	["PreparedPackingQuantityUpdatePackingEntityRelationInput"]: {
	connect?: ValueTypes["ProductPackingUniqueWhere"] | undefined | null
};
	["DeliveryZoneUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	addresses?: ValueTypes["AddressUniqueWhere"] | undefined | null
};
	["DeliveryTimelinePresetConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryTimelinePresetEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryTimelinePresetEdge"]: AliasType<{
	node?:ValueTypes["DeliveryTimelinePreset"],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchase"]: AliasType<{
	_meta?:ValueTypes["SuppliesPurchaseMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SuppliesPurchaseItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SuppliesPurchaseItem"]],
supplier?: [{	filter?: ValueTypes["IngredientSupplierWhere"] | undefined | null},ValueTypes["IngredientSupplier"]],
	requestedDeliveryDate?:boolean | `@${string}`,
paginateItems?: [{	filter?: ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SuppliesPurchaseItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SuppliesPurchaseItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchaseMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	supplier?:ValueTypes["FieldMeta"],
	requestedDeliveryDate?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchaseItem"]: AliasType<{
	_meta?:ValueTypes["SuppliesPurchaseItemMeta"],
	id?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["SuppliesPurchaseWhere"] | undefined | null},ValueTypes["SuppliesPurchase"]],
ingredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null},ValueTypes["ProductIngredient"]],
	pricePaidCents?:boolean | `@${string}`,
preferredUnit?: [{	filter?: ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null},ValueTypes["ProductIngredientItemQuantityUnit"]],
	deletedAt?:boolean | `@${string}`,
	purchasedAt?:boolean | `@${string}`,
purchasedBy?: [{	filter?: ValueTypes["StaffWhere"] | undefined | null},ValueTypes["Staff"]],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchaseItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	amount?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	ingredient?:ValueTypes["FieldMeta"],
	pricePaidCents?:ValueTypes["FieldMeta"],
	preferredUnit?:ValueTypes["FieldMeta"],
	deletedAt?:ValueTypes["FieldMeta"],
	purchasedAt?:ValueTypes["FieldMeta"],
	purchasedBy?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchaseWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	items?: ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null,
	supplier?: ValueTypes["IngredientSupplierWhere"] | undefined | null,
	requestedDeliveryDate?: ValueTypes["DateCondition"] | undefined | null,
	and?: Array<ValueTypes["SuppliesPurchaseWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["SuppliesPurchaseWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["SuppliesPurchaseWhere"] | undefined | null
};
	["SuppliesPurchaseItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	amount?: ValueTypes["IntCondition"] | undefined | null,
	list?: ValueTypes["SuppliesPurchaseWhere"] | undefined | null,
	ingredient?: ValueTypes["ProductIngredientWhere"] | undefined | null,
	pricePaidCents?: ValueTypes["IntCondition"] | undefined | null,
	preferredUnit?: ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null,
	deletedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	purchasedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	purchasedBy?: ValueTypes["StaffWhere"] | undefined | null,
	and?: Array<ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null
};
	["SuppliesPurchaseItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	amount?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["SuppliesPurchaseOrderBy"] | undefined | null,
	ingredient?: ValueTypes["ProductIngredientOrderBy"] | undefined | null,
	pricePaidCents?: ValueTypes["OrderDirection"] | undefined | null,
	preferredUnit?: ValueTypes["ProductIngredientItemQuantityUnitOrderBy"] | undefined | null,
	deletedAt?: ValueTypes["OrderDirection"] | undefined | null,
	purchasedAt?: ValueTypes["OrderDirection"] | undefined | null,
	purchasedBy?: ValueTypes["StaffOrderBy"] | undefined | null
};
	["SuppliesPurchaseOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	supplier?: ValueTypes["IngredientSupplierOrderBy"] | undefined | null,
	requestedDeliveryDate?: ValueTypes["OrderDirection"] | undefined | null
};
	["SuppliesPurchaseItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SuppliesPurchaseItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchaseItemEdge"]: AliasType<{
	node?:ValueTypes["SuppliesPurchaseItem"],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchaseUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	supplier?: ValueTypes["IngredientSupplierUniqueWhere"] | undefined | null,
	items?: ValueTypes["SuppliesPurchaseItemUniqueWhere"] | undefined | null
};
	["SuppliesPurchaseItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["SuppliesPurchaseConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SuppliesPurchaseEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchaseEdge"]: AliasType<{
	node?:ValueTypes["SuppliesPurchase"],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchaseCreateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	items?: Array<ValueTypes["SuppliesPurchaseCreateItemsEntityRelationInput"]> | undefined | null,
	supplier?: ValueTypes["SuppliesPurchaseCreateSupplierEntityRelationInput"] | undefined | null,
	requestedDeliveryDate?: ValueTypes["Date"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["SuppliesPurchaseCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["SuppliesPurchaseItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["SuppliesPurchaseItemWithoutListCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["SuppliesPurchaseItemWithoutListCreateInput"]: {
	amount?: number | undefined | null,
	ingredient?: ValueTypes["SuppliesPurchaseItemCreateIngredientEntityRelationInput"] | undefined | null,
	pricePaidCents?: number | undefined | null,
	preferredUnit?: ValueTypes["SuppliesPurchaseItemCreatePreferredUnitEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	purchasedAt?: ValueTypes["DateTime"] | undefined | null,
	purchasedBy?: ValueTypes["SuppliesPurchaseItemCreatePurchasedByEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["SuppliesPurchaseItemCreateIngredientEntityRelationInput"]: {
	connect?: ValueTypes["ProductIngredientUniqueWhere"] | undefined | null
};
	["SuppliesPurchaseItemCreatePreferredUnitEntityRelationInput"]: {
	connect?: ValueTypes["ProductIngredientItemQuantityUnitUniqueWhere"] | undefined | null
};
	["SuppliesPurchaseItemCreatePurchasedByEntityRelationInput"]: {
	connect?: ValueTypes["StaffUniqueWhere"] | undefined | null
};
	["SuppliesPurchaseCreateSupplierEntityRelationInput"]: {
	connect?: ValueTypes["IngredientSupplierUniqueWhere"] | undefined | null
};
	["SuppliesPurchaseUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	items?: Array<ValueTypes["SuppliesPurchaseUpdateItemsEntityRelationInput"]> | undefined | null,
	supplier?: ValueTypes["SuppliesPurchaseUpdateSupplierEntityRelationInput"] | undefined | null,
	requestedDeliveryDate?: ValueTypes["Date"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["SuppliesPurchaseUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["SuppliesPurchaseItemWithoutListCreateInput"] | undefined | null,
	update?: ValueTypes["SuppliesPurchaseUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["SuppliesPurchaseUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["SuppliesPurchaseItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["SuppliesPurchaseItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["SuppliesPurchaseUpdateItemsRelationInput"]: {
	by?: ValueTypes["SuppliesPurchaseItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["SuppliesPurchaseItemWithoutListUpdateInput"] | undefined | null
};
	["SuppliesPurchaseItemWithoutListUpdateInput"]: {
	amount?: number | undefined | null,
	ingredient?: ValueTypes["SuppliesPurchaseItemUpdateIngredientEntityRelationInput"] | undefined | null,
	pricePaidCents?: number | undefined | null,
	preferredUnit?: ValueTypes["SuppliesPurchaseItemUpdatePreferredUnitEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	purchasedAt?: ValueTypes["DateTime"] | undefined | null,
	purchasedBy?: ValueTypes["SuppliesPurchaseItemUpdatePurchasedByEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["SuppliesPurchaseItemUpdateIngredientEntityRelationInput"]: {
	connect?: ValueTypes["ProductIngredientUniqueWhere"] | undefined | null
};
	["SuppliesPurchaseItemUpdatePreferredUnitEntityRelationInput"]: {
	connect?: ValueTypes["ProductIngredientItemQuantityUnitUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["SuppliesPurchaseItemUpdatePurchasedByEntityRelationInput"]: {
	connect?: ValueTypes["StaffUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["SuppliesPurchaseUpsertItemsRelationInput"]: {
	by?: ValueTypes["SuppliesPurchaseItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["SuppliesPurchaseItemWithoutListUpdateInput"] | undefined | null,
	create?: ValueTypes["SuppliesPurchaseItemWithoutListCreateInput"] | undefined | null
};
	["SuppliesPurchaseUpdateSupplierEntityRelationInput"]: {
	connect?: ValueTypes["IngredientSupplierUniqueWhere"] | undefined | null
};
	["LogRecord"]: AliasType<{
	_meta?:ValueTypes["LogRecordMeta"],
	id?:boolean | `@${string}`,
	level?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	shouldBeResolved?:boolean | `@${string}`,
	resolvedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LogRecordMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	level?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	message?:ValueTypes["FieldMeta"],
	data?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	shouldBeResolved?:ValueTypes["FieldMeta"],
	resolvedAt?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LogRecordUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["LogRecordWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	level?: ValueTypes["IntCondition"] | undefined | null,
	type?: ValueTypes["StringCondition"] | undefined | null,
	message?: ValueTypes["StringCondition"] | undefined | null,
	data?: ValueTypes["JsonCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	shouldBeResolved?: ValueTypes["BooleanCondition"] | undefined | null,
	resolvedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	and?: Array<ValueTypes["LogRecordWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LogRecordWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LogRecordWhere"] | undefined | null
};
	["LogRecordOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	level?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	message?: ValueTypes["OrderDirection"] | undefined | null,
	data?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	shouldBeResolved?: ValueTypes["OrderDirection"] | undefined | null,
	resolvedAt?: ValueTypes["OrderDirection"] | undefined | null
};
	["LogRecordConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LogRecordEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LogRecordEdge"]: AliasType<{
	node?:ValueTypes["LogRecord"],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchaseItemCreateInput"]: {
	amount?: number | undefined | null,
	list?: ValueTypes["SuppliesPurchaseItemCreateListEntityRelationInput"] | undefined | null,
	ingredient?: ValueTypes["SuppliesPurchaseItemCreateIngredientEntityRelationInput"] | undefined | null,
	pricePaidCents?: number | undefined | null,
	preferredUnit?: ValueTypes["SuppliesPurchaseItemCreatePreferredUnitEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	purchasedAt?: ValueTypes["DateTime"] | undefined | null,
	purchasedBy?: ValueTypes["SuppliesPurchaseItemCreatePurchasedByEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["SuppliesPurchaseItemCreateListEntityRelationInput"]: {
	connect?: ValueTypes["SuppliesPurchaseUniqueWhere"] | undefined | null,
	create?: ValueTypes["SuppliesPurchaseWithoutItemsCreateInput"] | undefined | null
};
	["SuppliesPurchaseWithoutItemsCreateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	supplier?: ValueTypes["SuppliesPurchaseCreateSupplierEntityRelationInput"] | undefined | null,
	requestedDeliveryDate?: ValueTypes["Date"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["SuppliesPurchaseItemUpdateInput"]: {
	amount?: number | undefined | null,
	list?: ValueTypes["SuppliesPurchaseItemUpdateListEntityRelationInput"] | undefined | null,
	ingredient?: ValueTypes["SuppliesPurchaseItemUpdateIngredientEntityRelationInput"] | undefined | null,
	pricePaidCents?: number | undefined | null,
	preferredUnit?: ValueTypes["SuppliesPurchaseItemUpdatePreferredUnitEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	purchasedAt?: ValueTypes["DateTime"] | undefined | null,
	purchasedBy?: ValueTypes["SuppliesPurchaseItemUpdatePurchasedByEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["SuppliesPurchaseItemUpdateListEntityRelationInput"]: {
	create?: ValueTypes["SuppliesPurchaseWithoutItemsCreateInput"] | undefined | null,
	update?: ValueTypes["SuppliesPurchaseWithoutItemsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["SuppliesPurchaseItemUpsertListRelationInput"] | undefined | null,
	connect?: ValueTypes["SuppliesPurchaseUniqueWhere"] | undefined | null
};
	["SuppliesPurchaseWithoutItemsUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	supplier?: ValueTypes["SuppliesPurchaseUpdateSupplierEntityRelationInput"] | undefined | null,
	requestedDeliveryDate?: ValueTypes["Date"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["SuppliesPurchaseItemUpsertListRelationInput"]: {
	update?: ValueTypes["SuppliesPurchaseWithoutItemsUpdateInput"] | undefined | null,
	create?: ValueTypes["SuppliesPurchaseWithoutItemsCreateInput"] | undefined | null
};
	["VirtualProductConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VirtualProductEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VirtualProductEdge"]: AliasType<{
	node?:ValueTypes["VirtualProduct"],
		__typename?: boolean | `@${string}`
}>;
	["ProductMetadataConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductMetadataEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductMetadataEdge"]: AliasType<{
	node?:ValueTypes["ProductMetadata"],
		__typename?: boolean | `@${string}`
}>;
	["UnassignedShiftInfoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["UnassignedShiftInfoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["UnassignedShiftInfoEdge"]: AliasType<{
	node?:ValueTypes["UnassignedShiftInfo"],
		__typename?: boolean | `@${string}`
}>;
	["UnassignedShiftInfoCreateInput"]: {
	hoursMissed?: number | undefined | null,
	queueItem?: ValueTypes["UnassignedShiftInfoCreateQueueItemEntityRelationInput"] | undefined | null,
	legacyReason?: ValueTypes["UnassignedInfoReason"] | undefined | null,
	reason?: ValueTypes["UnassignedShiftInfoCreateReasonEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["UnassignedShiftInfoCreateQueueItemEntityRelationInput"]: {
	connect?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["EmployeeQueueItemWithoutUnassignInfoCreateInput"] | undefined | null
};
	["EmployeeQueueItemWithoutUnassignInfoCreateInput"]: {
	start?: string | undefined | null,
	end?: string | undefined | null,
	queue?: ValueTypes["EmployeeQueueItemCreateQueueEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["EmployeeQueueItemCreateShiftsProfileEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["UnassignedShiftInfoUpdateInput"]: {
	hoursMissed?: number | undefined | null,
	queueItem?: ValueTypes["UnassignedShiftInfoUpdateQueueItemEntityRelationInput"] | undefined | null,
	legacyReason?: ValueTypes["UnassignedInfoReason"] | undefined | null,
	reason?: ValueTypes["UnassignedShiftInfoUpdateReasonEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["UnassignedShiftInfoUpdateQueueItemEntityRelationInput"]: {
	create?: ValueTypes["EmployeeQueueItemWithoutUnassignInfoCreateInput"] | undefined | null,
	update?: ValueTypes["EmployeeQueueItemWithoutUnassignInfoUpdateInput"] | undefined | null,
	upsert?: ValueTypes["UnassignedShiftInfoUpsertQueueItemRelationInput"] | undefined | null,
	connect?: ValueTypes["EmployeeQueueItemUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["EmployeeQueueItemWithoutUnassignInfoUpdateInput"]: {
	start?: string | undefined | null,
	end?: string | undefined | null,
	queue?: ValueTypes["EmployeeQueueItemUpdateQueueEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	shift?: ValueTypes["EmployeeQueueItemUpdateShiftEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["UnassignedShiftInfoUpsertQueueItemRelationInput"]: {
	update?: ValueTypes["EmployeeQueueItemWithoutUnassignInfoUpdateInput"] | undefined | null,
	create?: ValueTypes["EmployeeQueueItemWithoutUnassignInfoCreateInput"] | undefined | null
};
	["FreshingContainerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["TextListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TextListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TextListEdge"]: AliasType<{
	node?:ValueTypes["TextList"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryRegionsEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsEdge"]: AliasType<{
	node?:ValueTypes["DeliveryRegions"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListEdge"]: AliasType<{
	node?:ValueTypes["ImageList"],
		__typename?: boolean | `@${string}`
}>;
	["TimetablesEmployeePrivilegesConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TimetablesEmployeePrivilegesEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TimetablesEmployeePrivilegesEdge"]: AliasType<{
	node?:ValueTypes["TimetablesEmployeePrivileges"],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplierConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IngredientSupplierEdge"],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplierEdge"]: AliasType<{
	node?:ValueTypes["IngredientSupplier"],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplierListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IngredientSupplierListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["IngredientSupplierListEdge"]: AliasType<{
	node?:ValueTypes["IngredientSupplierList"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["RedirectEdge"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectEdge"]: AliasType<{
	node?:ValueTypes["Redirect"],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["One"] | undefined | null,
	freshingContainers?: ValueTypes["FreshingContainerUniqueWhere"] | undefined | null,
	openingHours?: ValueTypes["WeekHoursUniqueWhere"] | undefined | null,
	workingHours?: ValueTypes["WeekHoursUniqueWhere"] | undefined | null,
	creditsProduct?: ValueTypes["VirtualProductUniqueWhere"] | undefined | null,
	discountVatRate?: ValueTypes["VatRateUniqueWhere"] | undefined | null
};
	["WorkspaceConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["WorkspaceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceEdge"]: AliasType<{
	node?:ValueTypes["Workspace"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationCodeConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IdentificationCodeEdge"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationCodeEdge"]: AliasType<{
	node?:ValueTypes["IdentificationCode"],
		__typename?: boolean | `@${string}`
}>;
	["DeviceIdentifier"]: AliasType<{
	_meta?:ValueTypes["DeviceIdentifierMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	platform?:boolean | `@${string}`,
	resolution?:boolean | `@${string}`,
	devicePixelRatio?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
meta?: [{	filter?: ValueTypes["DeviceIdentifierMetadataWhere"] | undefined | null},ValueTypes["DeviceIdentifierMetadata"]],
	applicationVersionIdentifier?:boolean | `@${string}`,
	lastPointOfSaleSynchronizationErrorRawDetails?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeviceIdentifierMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	updatedAt?:ValueTypes["FieldMeta"],
	platform?:ValueTypes["FieldMeta"],
	resolution?:ValueTypes["FieldMeta"],
	devicePixelRatio?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	meta?:ValueTypes["FieldMeta"],
	applicationVersionIdentifier?:ValueTypes["FieldMeta"],
	lastPointOfSaleSynchronizationErrorRawDetails?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeviceIdentifierMetadata"]: AliasType<{
	_meta?:ValueTypes["DeviceIdentifierMetadataMeta"],
	id?:boolean | `@${string}`,
	prettyIdentifier?:boolean | `@${string}`,
deviceIdentifier?: [{	filter?: ValueTypes["DeviceIdentifierWhere"] | undefined | null},ValueTypes["DeviceIdentifier"]],
		__typename?: boolean | `@${string}`
}>;
	["DeviceIdentifierMetadataMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	prettyIdentifier?:ValueTypes["FieldMeta"],
	deviceIdentifier?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeviceIdentifierWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	updatedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	platform?: ValueTypes["StringCondition"] | undefined | null,
	resolution?: ValueTypes["StringCondition"] | undefined | null,
	devicePixelRatio?: ValueTypes["FloatCondition"] | undefined | null,
	identifier?: ValueTypes["StringCondition"] | undefined | null,
	meta?: ValueTypes["DeviceIdentifierMetadataWhere"] | undefined | null,
	applicationVersionIdentifier?: ValueTypes["StringCondition"] | undefined | null,
	lastPointOfSaleSynchronizationErrorRawDetails?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["DeviceIdentifierWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeviceIdentifierWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeviceIdentifierWhere"] | undefined | null
};
	["DeviceIdentifierMetadataWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	prettyIdentifier?: ValueTypes["StringCondition"] | undefined | null,
	deviceIdentifier?: ValueTypes["DeviceIdentifierWhere"] | undefined | null,
	and?: Array<ValueTypes["DeviceIdentifierMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeviceIdentifierMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeviceIdentifierMetadataWhere"] | undefined | null
};
	["DeviceIdentifierUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	identifier?: string | undefined | null,
	meta?: ValueTypes["DeviceIdentifierMetadataUniqueWhere"] | undefined | null
};
	["DeviceIdentifierMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	deviceIdentifier?: ValueTypes["DeviceIdentifierUniqueWhere"] | undefined | null
};
	["DeviceIdentifierOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	updatedAt?: ValueTypes["OrderDirection"] | undefined | null,
	platform?: ValueTypes["OrderDirection"] | undefined | null,
	resolution?: ValueTypes["OrderDirection"] | undefined | null,
	devicePixelRatio?: ValueTypes["OrderDirection"] | undefined | null,
	identifier?: ValueTypes["OrderDirection"] | undefined | null,
	meta?: ValueTypes["DeviceIdentifierMetadataOrderBy"] | undefined | null,
	applicationVersionIdentifier?: ValueTypes["OrderDirection"] | undefined | null,
	lastPointOfSaleSynchronizationErrorRawDetails?: ValueTypes["OrderDirection"] | undefined | null
};
	["DeviceIdentifierMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	prettyIdentifier?: ValueTypes["OrderDirection"] | undefined | null,
	deviceIdentifier?: ValueTypes["DeviceIdentifierOrderBy"] | undefined | null
};
	["DeviceIdentifierConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeviceIdentifierEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeviceIdentifierEdge"]: AliasType<{
	node?:ValueTypes["DeviceIdentifier"],
		__typename?: boolean | `@${string}`
}>;
	["DeviceIdentifierCreateInput"]: {
	internalName?: string | undefined | null,
	updatedAt?: ValueTypes["DateTime"] | undefined | null,
	platform?: string | undefined | null,
	resolution?: string | undefined | null,
	devicePixelRatio?: number | undefined | null,
	identifier?: string | undefined | null,
	applicationVersionIdentifier?: string | undefined | null,
	lastPointOfSaleSynchronizationErrorRawDetails?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["DeviceIdentifierUpdateInput"]: {
	internalName?: string | undefined | null,
	updatedAt?: ValueTypes["DateTime"] | undefined | null,
	platform?: string | undefined | null,
	resolution?: string | undefined | null,
	devicePixelRatio?: number | undefined | null,
	identifier?: string | undefined | null,
	applicationVersionIdentifier?: string | undefined | null,
	lastPointOfSaleSynchronizationErrorRawDetails?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["DeviceIdentifierMetadataConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeviceIdentifierMetadataEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeviceIdentifierMetadataEdge"]: AliasType<{
	node?:ValueTypes["DeviceIdentifierMetadata"],
		__typename?: boolean | `@${string}`
}>;
	["FulfillmentNoteUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["CustomerMetadataConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CustomerMetadataEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerMetadataEdge"]: AliasType<{
	node?:ValueTypes["CustomerMetadata"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBoxConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SubscriptionBoxEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBoxEdge"]: AliasType<{
	node?:ValueTypes["SubscriptionBox"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableDayMetadataConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TimetableDayMetadataEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableDayMetadataEdge"]: AliasType<{
	node?:ValueTypes["TimetableDayMetadata"],
		__typename?: boolean | `@${string}`
}>;
	["GridTileItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null
};
	["GridTilesUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["GridTileItemUniqueWhere"] | undefined | null
};
	["GridTilesConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GridTilesEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GridTilesEdge"]: AliasType<{
	node?:ValueTypes["GridTiles"],
		__typename?: boolean | `@${string}`
}>;
	["Position"]: AliasType<{
	_meta?:ValueTypes["PositionMeta"],
	id?:boolean | `@${string}`,
	x?:boolean | `@${string}`,
	y?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PositionMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	x?:ValueTypes["FieldMeta"],
	y?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["PositionWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	x?: ValueTypes["IntCondition"] | undefined | null,
	y?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["PositionWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["PositionWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["PositionWhere"] | undefined | null
};
	["PositionOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	x?: ValueTypes["OrderDirection"] | undefined | null,
	y?: ValueTypes["OrderDirection"] | undefined | null
};
	["PositionConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PositionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionEdge"]: AliasType<{
	node?:ValueTypes["Position"],
		__typename?: boolean | `@${string}`
}>;
	["StatsWidget"]: AliasType<{
	_meta?:ValueTypes["StatsWidgetMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	link?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	timeFrame?:boolean | `@${string}`,
layout?: [{	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null},ValueTypes["StatsWidgetsLayout"]],
icon?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
channels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
productCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
paymentMethods?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PaymentMethod"]],
	hoursFrom?:boolean | `@${string}`,
	hoursTo?:boolean | `@${string}`,
paginateChannels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
paginatePaymentMethods?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PaymentMethodConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["StatsWidgetMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	timeFrame?:ValueTypes["FieldMeta"],
	layout?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
	channels?:ValueTypes["FieldMeta"],
	productCategory?:ValueTypes["FieldMeta"],
	paymentMethods?:ValueTypes["FieldMeta"],
	hoursFrom?:ValueTypes["FieldMeta"],
	hoursTo?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["StatsEnabledWidgetTypeType"]:StatsEnabledWidgetTypeType;
	["StatsWidgetTimeFrame"]:StatsWidgetTimeFrame;
	["StatsWidgetsLayout"]: AliasType<{
	_meta?:ValueTypes["StatsWidgetsLayoutMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["StatsWidget"]],
paginateItems?: [{	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StatsWidgetConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["StatsWidgetsLayoutMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["StatsWidgetWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	size?: ValueTypes["IntCondition"] | undefined | null,
	type?: ValueTypes["StatsEnabledWidgetTypeTypeCondition"] | undefined | null,
	link?: ValueTypes["StringCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	timeFrame?: ValueTypes["StatsWidgetTimeFrameCondition"] | undefined | null,
	layout?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null,
	icon?: ValueTypes["ImageWhere"] | undefined | null,
	channels?: ValueTypes["ChannelWhere"] | undefined | null,
	productCategory?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	paymentMethods?: ValueTypes["PaymentMethodWhere"] | undefined | null,
	hoursFrom?: ValueTypes["StringCondition"] | undefined | null,
	hoursTo?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["StatsWidgetWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["StatsWidgetWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["StatsWidgetWhere"] | undefined | null
};
	["StatsEnabledWidgetTypeTypeCondition"]: {
	and?: Array<ValueTypes["StatsEnabledWidgetTypeTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["StatsEnabledWidgetTypeTypeCondition"]> | undefined | null,
	not?: ValueTypes["StatsEnabledWidgetTypeTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["StatsEnabledWidgetTypeType"] | undefined | null,
	notEq?: ValueTypes["StatsEnabledWidgetTypeType"] | undefined | null,
	in?: Array<ValueTypes["StatsEnabledWidgetTypeType"]> | undefined | null,
	notIn?: Array<ValueTypes["StatsEnabledWidgetTypeType"]> | undefined | null,
	lt?: ValueTypes["StatsEnabledWidgetTypeType"] | undefined | null,
	lte?: ValueTypes["StatsEnabledWidgetTypeType"] | undefined | null,
	gt?: ValueTypes["StatsEnabledWidgetTypeType"] | undefined | null,
	gte?: ValueTypes["StatsEnabledWidgetTypeType"] | undefined | null
};
	["StatsWidgetTimeFrameCondition"]: {
	and?: Array<ValueTypes["StatsWidgetTimeFrameCondition"]> | undefined | null,
	or?: Array<ValueTypes["StatsWidgetTimeFrameCondition"]> | undefined | null,
	not?: ValueTypes["StatsWidgetTimeFrameCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["StatsWidgetTimeFrame"] | undefined | null,
	notEq?: ValueTypes["StatsWidgetTimeFrame"] | undefined | null,
	in?: Array<ValueTypes["StatsWidgetTimeFrame"]> | undefined | null,
	notIn?: Array<ValueTypes["StatsWidgetTimeFrame"]> | undefined | null,
	lt?: ValueTypes["StatsWidgetTimeFrame"] | undefined | null,
	lte?: ValueTypes["StatsWidgetTimeFrame"] | undefined | null,
	gt?: ValueTypes["StatsWidgetTimeFrame"] | undefined | null,
	gte?: ValueTypes["StatsWidgetTimeFrame"] | undefined | null
};
	["StatsWidgetsLayoutWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["StatsWidgetWhere"] | undefined | null,
	and?: Array<ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null
};
	["StatsWidgetOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	size?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	link?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	timeFrame?: ValueTypes["OrderDirection"] | undefined | null,
	layout?: ValueTypes["StatsWidgetsLayoutOrderBy"] | undefined | null,
	icon?: ValueTypes["ImageOrderBy"] | undefined | null,
	productCategory?: ValueTypes["ProductCategoryOrderBy"] | undefined | null,
	hoursFrom?: ValueTypes["OrderDirection"] | undefined | null,
	hoursTo?: ValueTypes["OrderDirection"] | undefined | null
};
	["StatsWidgetsLayoutOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["StatsWidgetConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["StatsWidgetEdge"],
		__typename?: boolean | `@${string}`
}>;
	["StatsWidgetEdge"]: AliasType<{
	node?:ValueTypes["StatsWidget"],
		__typename?: boolean | `@${string}`
}>;
	["StatsWidgetUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["StatsWidgetsLayoutUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["StatsWidgetUniqueWhere"] | undefined | null
};
	["StatsWidgetsLayoutConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["StatsWidgetsLayoutEdge"],
		__typename?: boolean | `@${string}`
}>;
	["StatsWidgetsLayoutEdge"]: AliasType<{
	node?:ValueTypes["StatsWidgetsLayout"],
		__typename?: boolean | `@${string}`
}>;
	["StatsPage"]: AliasType<{
	_meta?:ValueTypes["StatsPageMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	enabled?:boolean | `@${string}`,
widgets?: [{	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null},ValueTypes["StatsWidgetsLayout"]],
		__typename?: boolean | `@${string}`
}>;
	["StatsPageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	enabled?:ValueTypes["FieldMeta"],
	widgets?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["StatsPageType"]:StatsPageType;
	["StatsPageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	type?: ValueTypes["StatsPageType"] | undefined | null,
	widgets?: ValueTypes["StatsWidgetsLayoutUniqueWhere"] | undefined | null
};
	["StatsPageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["StatsPageTypeCondition"] | undefined | null,
	enabled?: ValueTypes["BooleanCondition"] | undefined | null,
	widgets?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null,
	and?: Array<ValueTypes["StatsPageWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["StatsPageWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["StatsPageWhere"] | undefined | null
};
	["StatsPageTypeCondition"]: {
	and?: Array<ValueTypes["StatsPageTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["StatsPageTypeCondition"]> | undefined | null,
	not?: ValueTypes["StatsPageTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["StatsPageType"] | undefined | null,
	notEq?: ValueTypes["StatsPageType"] | undefined | null,
	in?: Array<ValueTypes["StatsPageType"]> | undefined | null,
	notIn?: Array<ValueTypes["StatsPageType"]> | undefined | null,
	lt?: ValueTypes["StatsPageType"] | undefined | null,
	lte?: ValueTypes["StatsPageType"] | undefined | null,
	gt?: ValueTypes["StatsPageType"] | undefined | null,
	gte?: ValueTypes["StatsPageType"] | undefined | null
};
	["StatsPageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	enabled?: ValueTypes["OrderDirection"] | undefined | null,
	widgets?: ValueTypes["StatsWidgetsLayoutOrderBy"] | undefined | null
};
	["StatsPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["StatsPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["StatsPageEdge"]: AliasType<{
	node?:ValueTypes["StatsPage"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentStatsByDay"]: AliasType<{
	_meta?:ValueTypes["OrderPaymentStatsByDayMeta"],
	id?:boolean | `@${string}`,
	day?:boolean | `@${string}`,
	totalGratuityCents?:boolean | `@${string}`,
	reducedGratuityCents?:boolean | `@${string}`,
channel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null},ValueTypes["Channel"]],
paymentMethod?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null},ValueTypes["PaymentMethod"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentStatsByDayMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	day?:ValueTypes["FieldMeta"],
	totalGratuityCents?:ValueTypes["FieldMeta"],
	reducedGratuityCents?:ValueTypes["FieldMeta"],
	channel?:ValueTypes["FieldMeta"],
	paymentMethod?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentStatsByDayUniqueWhere"]: {
	id?: string | undefined | null
};
	["OrderPaymentStatsByDayWhere"]: {
	id?: ValueTypes["StringCondition"] | undefined | null,
	day?: ValueTypes["DateCondition"] | undefined | null,
	totalGratuityCents?: ValueTypes["IntCondition"] | undefined | null,
	reducedGratuityCents?: ValueTypes["IntCondition"] | undefined | null,
	channel?: ValueTypes["ChannelWhere"] | undefined | null,
	paymentMethod?: ValueTypes["PaymentMethodWhere"] | undefined | null,
	and?: Array<ValueTypes["OrderPaymentStatsByDayWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderPaymentStatsByDayWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderPaymentStatsByDayWhere"] | undefined | null
};
	["OrderPaymentStatsByDayOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	day?: ValueTypes["OrderDirection"] | undefined | null,
	totalGratuityCents?: ValueTypes["OrderDirection"] | undefined | null,
	reducedGratuityCents?: ValueTypes["OrderDirection"] | undefined | null,
	channel?: ValueTypes["ChannelOrderBy"] | undefined | null,
	paymentMethod?: ValueTypes["PaymentMethodOrderBy"] | undefined | null
};
	["OrderPaymentStatsByDayConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderPaymentStatsByDayEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentStatsByDayEdge"]: AliasType<{
	node?:ValueTypes["OrderPaymentStatsByDay"],
		__typename?: boolean | `@${string}`
}>;
	["QueryTransaction"]: AliasType<{
getAddress?: [{	by: ValueTypes["AddressUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
listAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Address"]],
paginateAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AddressConnection"]],
getCountry?: [{	by: ValueTypes["CountryUniqueWhere"],	filter?: ValueTypes["CountryWhere"] | undefined | null},ValueTypes["Country"]],
listCountry?: [{	filter?: ValueTypes["CountryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CountryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Country"]],
paginateCountry?: [{	filter?: ValueTypes["CountryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CountryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CountryConnection"]],
getChannel?: [{	by: ValueTypes["ChannelUniqueWhere"],	filter?: ValueTypes["ChannelWhere"] | undefined | null},ValueTypes["Channel"]],
listChannel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
paginateChannel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
getCustomer?: [{	by: ValueTypes["CustomerUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
listCustomer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Customer"]],
paginateCustomer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerConnection"]],
getDeliveryMethod?: [{	by: ValueTypes["DeliveryMethodUniqueWhere"],	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
listDeliveryMethod?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethod"]],
paginateDeliveryMethod?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodConnection"]],
getOrder?: [{	by: ValueTypes["OrderUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
listOrder?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
paginateOrder?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
getOrderPayment?: [{	by: ValueTypes["OrderPaymentUniqueWhere"],	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null},ValueTypes["OrderPayment"]],
listOrderPayment?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPayment"]],
paginateOrderPayment?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPaymentConnection"]],
getOrderDelivery?: [{	by: ValueTypes["OrderDeliveryUniqueWhere"],	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
listOrderDelivery?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderDelivery"]],
paginateOrderDelivery?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderDeliveryConnection"]],
getOrderItem?: [{	by: ValueTypes["OrderItemUniqueWhere"],	filter?: ValueTypes["OrderItemWhere"] | undefined | null},ValueTypes["OrderItem"]],
listOrderItem?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderItem"]],
paginateOrderItem?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderItemConnection"]],
getPaymentMethod?: [{	by: ValueTypes["PaymentMethodUniqueWhere"],	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null},ValueTypes["PaymentMethod"]],
listPaymentMethod?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PaymentMethod"]],
paginatePaymentMethod?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PaymentMethodConnection"]],
getVatRate?: [{	by: ValueTypes["VatRateUniqueWhere"],	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
listVatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VatRateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VatRate"]],
paginateVatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VatRateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VatRateConnection"]],
getProduct?: [{	by: ValueTypes["ProductUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
listProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
paginateProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
getProductStock?: [{	by: ValueTypes["ProductStockUniqueWhere"],	filter?: ValueTypes["ProductStockWhere"] | undefined | null},ValueTypes["ProductStock"]],
listProductStock?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductStock"]],
paginateProductStock?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductStockConnection"]],
getProductPacking?: [{	by: ValueTypes["ProductPackingUniqueWhere"],	filter?: ValueTypes["ProductPackingWhere"] | undefined | null},ValueTypes["ProductPacking"]],
listProductPacking?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPacking"]],
paginateProductPacking?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingConnection"]],
getProductRecipe?: [{	by: ValueTypes["ProductRecipeUniqueWhere"],	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
listProductRecipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipe"]],
paginateProductRecipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeConnection"]],
getProductCategory?: [{	by: ValueTypes["ProductCategoryUniqueWhere"],	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
listProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategory"]],
paginateProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryConnection"]],
getStore?: [{	by: ValueTypes["StoreUniqueWhere"],	filter?: ValueTypes["StoreWhere"] | undefined | null},ValueTypes["Store"]],
listStore?: [{	filter?: ValueTypes["StoreWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StoreOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Store"]],
paginateStore?: [{	filter?: ValueTypes["StoreWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StoreOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StoreConnection"]],
getTag?: [{	by: ValueTypes["TagUniqueWhere"],	filter?: ValueTypes["TagWhere"] | undefined | null},ValueTypes["Tag"]],
listTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
paginateTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
getImage?: [{	by: ValueTypes["ImageUniqueWhere"],	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
listImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Image"]],
paginateImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageConnection"]],
getBusinessCategory?: [{	by: ValueTypes["BusinessCategoryUniqueWhere"],	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
listBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategory"]],
paginateBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryConnection"]],
getProductList?: [{	by: ValueTypes["ProductListUniqueWhere"],	filter?: ValueTypes["ProductListWhere"] | undefined | null},ValueTypes["ProductList"]],
listProductList?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductList"]],
paginateProductList?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductListConnection"]],
getProductListItem?: [{	by: ValueTypes["ProductListItemUniqueWhere"],	filter?: ValueTypes["ProductListItemWhere"] | undefined | null},ValueTypes["ProductListItem"]],
listProductListItem?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductListItem"]],
paginateProductListItem?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductListItemConnection"]],
getProductCategoryList?: [{	by: ValueTypes["ProductCategoryListUniqueWhere"],	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
listProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryList"]],
paginateProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListConnection"]],
getProductCategoryListItem?: [{	by: ValueTypes["ProductCategoryListItemUniqueWhere"],	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null},ValueTypes["ProductCategoryListItem"]],
listProductCategoryListItem?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListItem"]],
paginateProductCategoryListItem?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListItemConnection"]],
getBusinessCategoryLocale?: [{	by: ValueTypes["BusinessCategoryLocaleUniqueWhere"],	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null},ValueTypes["BusinessCategoryLocale"]],
listBusinessCategoryLocale?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategoryLocale"]],
paginateBusinessCategoryLocale?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryLocaleConnection"]],
getLocale?: [{	by: ValueTypes["LocaleUniqueWhere"],	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
listLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Locale"]],
paginateLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LocaleConnection"]],
getProductCategoryListLocale?: [{	by: ValueTypes["ProductCategoryListLocaleUniqueWhere"],	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null},ValueTypes["ProductCategoryListLocale"]],
listProductCategoryListLocale?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListLocale"]],
paginateProductCategoryListLocale?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListLocaleConnection"]],
getCustomerName?: [{	by: ValueTypes["CustomerNameUniqueWhere"],	filter?: ValueTypes["CustomerNameWhere"] | undefined | null},ValueTypes["CustomerName"]],
listCustomerName?: [{	filter?: ValueTypes["CustomerNameWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerNameOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerName"]],
paginateCustomerName?: [{	filter?: ValueTypes["CustomerNameWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerNameOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerNameConnection"]],
getProductIngredientItem?: [{	by: ValueTypes["ProductIngredientItemUniqueWhere"],	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null},ValueTypes["ProductIngredientItem"]],
listProductIngredientItem?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientItem"]],
paginateProductIngredientItem?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientItemConnection"]],
getProductIngredientItemQuantity?: [{	by: ValueTypes["ProductIngredientItemQuantityUniqueWhere"],	filter?: ValueTypes["ProductIngredientItemQuantityWhere"] | undefined | null},ValueTypes["ProductIngredientItemQuantity"]],
listProductIngredientItemQuantity?: [{	filter?: ValueTypes["ProductIngredientItemQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemQuantityOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientItemQuantity"]],
paginateProductIngredientItemQuantity?: [{	filter?: ValueTypes["ProductIngredientItemQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemQuantityOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientItemQuantityConnection"]],
getProductIngredientItemQuantityUnit?: [{	by: ValueTypes["ProductIngredientItemQuantityUnitUniqueWhere"],	filter?: ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null},ValueTypes["ProductIngredientItemQuantityUnit"]],
listProductIngredientItemQuantityUnit?: [{	filter?: ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemQuantityUnitOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientItemQuantityUnit"]],
paginateProductIngredientItemQuantityUnit?: [{	filter?: ValueTypes["ProductIngredientItemQuantityUnitWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemQuantityUnitOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientItemQuantityUnitConnection"]],
getProductIngredient?: [{	by: ValueTypes["ProductIngredientUniqueWhere"],	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null},ValueTypes["ProductIngredient"]],
listProductIngredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredient"]],
paginateProductIngredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientConnection"]],
getProductIngredientRating?: [{	by: ValueTypes["ProductIngredientRatingUniqueWhere"],	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null},ValueTypes["ProductIngredientRating"]],
listProductIngredientRating?: [{	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientRatingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientRating"]],
paginateProductIngredientRating?: [{	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientRatingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientRatingConnection"]],
getProductIngredientLocale?: [{	by: ValueTypes["ProductIngredientLocaleUniqueWhere"],	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientLocale"]],
listProductIngredientLocale?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientLocale"]],
paginateProductIngredientLocale?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientLocaleConnection"]],
getOrderRecurrence?: [{	by: ValueTypes["OrderRecurrenceUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null},ValueTypes["OrderRecurrence"]],
listOrderRecurrence?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrence"]],
paginateOrderRecurrence?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceConnection"]],
getOrderRecurrenceLocale?: [{	by: ValueTypes["OrderRecurrenceLocaleUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
listOrderRecurrenceLocale?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
paginateOrderRecurrenceLocale?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceLocaleConnection"]],
getStaff?: [{	by: ValueTypes["StaffUniqueWhere"],	filter?: ValueTypes["StaffWhere"] | undefined | null},ValueTypes["Staff"]],
listStaff?: [{	filter?: ValueTypes["StaffWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StaffOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Staff"]],
paginateStaff?: [{	filter?: ValueTypes["StaffWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StaffOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StaffConnection"]],
getBlog?: [{	by: ValueTypes["BlogUniqueWhere"],	filter?: ValueTypes["BlogWhere"] | undefined | null},ValueTypes["Blog"]],
listBlog?: [{	filter?: ValueTypes["BlogWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Blog"]],
paginateBlog?: [{	filter?: ValueTypes["BlogWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogConnection"]],
getBlogLocale?: [{	by: ValueTypes["BlogLocaleUniqueWhere"],	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null},ValueTypes["BlogLocale"]],
listBlogLocale?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogLocale"]],
paginateBlogLocale?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogLocaleConnection"]],
getBlogPost?: [{	by: ValueTypes["BlogPostUniqueWhere"],	filter?: ValueTypes["BlogPostWhere"] | undefined | null},ValueTypes["BlogPost"]],
listBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPost"]],
paginateBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostConnection"]],
getBlogPostLocale?: [{	by: ValueTypes["BlogPostLocaleUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
listBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostLocale"]],
paginateBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostLocaleConnection"]],
getColorPallete?: [{	by: ValueTypes["ColorPalleteUniqueWhere"],	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null},ValueTypes["ColorPallete"]],
listColorPallete?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ColorPalleteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ColorPallete"]],
paginateColorPallete?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ColorPalleteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ColorPalleteConnection"]],
getContent?: [{	by: ValueTypes["ContentUniqueWhere"],	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
listContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Content"]],
paginateContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentConnection"]],
getContentBlock?: [{	by: ValueTypes["ContentBlockUniqueWhere"],	filter?: ValueTypes["ContentBlockWhere"] | undefined | null},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ValueTypes["ContentReferenceUniqueWhere"],	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null},ValueTypes["ContentReference"]],
listContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentReferenceConnection"]],
getBlogPostList?: [{	by: ValueTypes["BlogPostListUniqueWhere"],	filter?: ValueTypes["BlogPostListWhere"] | undefined | null},ValueTypes["BlogPostList"]],
listBlogPostList?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostList"]],
paginateBlogPostList?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostListConnection"]],
getBlogPostListItem?: [{	by: ValueTypes["BlogPostListItemUniqueWhere"],	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null},ValueTypes["BlogPostListItem"]],
listBlogPostListItem?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostListItem"]],
paginateBlogPostListItem?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostListItemConnection"]],
getLinkList?: [{	by: ValueTypes["LinkListUniqueWhere"],	filter?: ValueTypes["LinkListWhere"] | undefined | null},ValueTypes["LinkList"]],
listLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LinkList"]],
paginateLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkListConnection"]],
getLinkListItem?: [{	by: ValueTypes["LinkListItemUniqueWhere"],	filter?: ValueTypes["LinkListItemWhere"] | undefined | null},ValueTypes["LinkListItem"]],
listLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LinkListItem"]],
paginateLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkListItemConnection"]],
getCover?: [{	by: ValueTypes["CoverUniqueWhere"],	filter?: ValueTypes["CoverWhere"] | undefined | null},ValueTypes["Cover"]],
listCover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CoverOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Cover"]],
paginateCover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CoverOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CoverConnection"]],
getLink?: [{	by: ValueTypes["LinkUniqueWhere"],	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
listLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Link"]],
paginateLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkConnection"]],
getLinkable?: [{	by: ValueTypes["LinkableUniqueWhere"],	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
listLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkableConnection"]],
getMedium?: [{	by: ValueTypes["MediumUniqueWhere"],	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
listMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Medium"]],
paginateMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MediumConnection"]],
getVideo?: [{	by: ValueTypes["VideoUniqueWhere"],	filter?: ValueTypes["VideoWhere"] | undefined | null},ValueTypes["Video"]],
listVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Video"]],
paginateVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VideoConnection"]],
getSeo?: [{	by: ValueTypes["SeoUniqueWhere"],	filter?: ValueTypes["SeoWhere"] | undefined | null},ValueTypes["Seo"]],
listSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Seo"]],
paginateSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SeoConnection"]],
getTranslationDomain?: [{	by: ValueTypes["TranslationDomainUniqueWhere"],	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null},ValueTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationDomainConnection"]],
getTranslationCatalogue?: [{	by: ValueTypes["TranslationCatalogueUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCatalogueConnection"]],
getTranslationKey?: [{	by: ValueTypes["TranslationKeyUniqueWhere"],	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null},ValueTypes["TranslationKey"]],
listTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationKeyConnection"]],
getTranslationValue?: [{	by: ValueTypes["TranslationValueUniqueWhere"],	filter?: ValueTypes["TranslationValueWhere"] | undefined | null},ValueTypes["TranslationValue"]],
listTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationValueConnection"]],
getTranslationCataloguesIdentifier?: [{	by: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"],	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
listTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
paginateTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCataloguesIdentifierConnection"]],
getSocialsAndApps?: [{	by: ValueTypes["SocialsAndAppsUniqueWhere"],	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null},ValueTypes["SocialsAndApps"]],
listSocialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SocialsAndAppsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SocialsAndApps"]],
paginateSocialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SocialsAndAppsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SocialsAndAppsConnection"]],
getProductGrid?: [{	by: ValueTypes["ProductGridUniqueWhere"],	filter?: ValueTypes["ProductGridWhere"] | undefined | null},ValueTypes["ProductGrid"]],
listProductGrid?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGrid"]],
paginateProductGrid?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridConnection"]],
getProductGridItem?: [{	by: ValueTypes["ProductGridItemUniqueWhere"],	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null},ValueTypes["ProductGridItem"]],
listProductGridItem?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridItem"]],
paginateProductGridItem?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridItemConnection"]],
getGallery?: [{	by: ValueTypes["GalleryUniqueWhere"],	filter?: ValueTypes["GalleryWhere"] | undefined | null},ValueTypes["Gallery"]],
listGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Gallery"]],
paginateGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GalleryConnection"]],
getGalleryItem?: [{	by: ValueTypes["GalleryItemUniqueWhere"],	filter?: ValueTypes["GalleryItemWhere"] | undefined | null},ValueTypes["GalleryItem"]],
listGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GalleryItem"]],
paginateGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GalleryItemConnection"]],
getHero?: [{	by: ValueTypes["HeroUniqueWhere"],	filter?: ValueTypes["HeroWhere"] | undefined | null},ValueTypes["Hero"]],
listHero?: [{	filter?: ValueTypes["HeroWhere"] | undefined | null,	orderBy?: Array<ValueTypes["HeroOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Hero"]],
paginateHero?: [{	filter?: ValueTypes["HeroWhere"] | undefined | null,	orderBy?: Array<ValueTypes["HeroOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["HeroConnection"]],
getPage?: [{	by: ValueTypes["PageUniqueWhere"],	filter?: ValueTypes["PageWhere"] | undefined | null},ValueTypes["Page"]],
listPage?: [{	filter?: ValueTypes["PageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Page"]],
paginatePage?: [{	filter?: ValueTypes["PageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageConnection"]],
getPageLocale?: [{	by: ValueTypes["PageLocaleUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
listPageLocale?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PageLocale"]],
paginatePageLocale?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageLocaleConnection"]],
getDeadlineTemplate?: [{	by: ValueTypes["DeadlineTemplateUniqueWhere"],	filter?: ValueTypes["DeadlineTemplateWhere"] | undefined | null},ValueTypes["DeadlineTemplate"]],
listDeadlineTemplate?: [{	filter?: ValueTypes["DeadlineTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeadlineTemplateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeadlineTemplate"]],
paginateDeadlineTemplate?: [{	filter?: ValueTypes["DeadlineTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeadlineTemplateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeadlineTemplateConnection"]],
getEmployeeQueue?: [{	by: ValueTypes["EmployeeQueueUniqueWhere"],	filter?: ValueTypes["EmployeeQueueWhere"] | undefined | null},ValueTypes["EmployeeQueue"]],
listEmployeeQueue?: [{	filter?: ValueTypes["EmployeeQueueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["EmployeeQueueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["EmployeeQueue"]],
paginateEmployeeQueue?: [{	filter?: ValueTypes["EmployeeQueueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["EmployeeQueueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["EmployeeQueueConnection"]],
validateCreateEmployeeQueue?: [{	data: ValueTypes["EmployeeQueueCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateEmployeeQueue?: [{	by: ValueTypes["EmployeeQueueUniqueWhere"],	data: ValueTypes["EmployeeQueueUpdateInput"]},ValueTypes["_ValidationResult"]],
getTimetableDay?: [{	by: ValueTypes["TimetableDayUniqueWhere"],	filter?: ValueTypes["TimetableDayWhere"] | undefined | null},ValueTypes["TimetableDay"]],
listTimetableDay?: [{	filter?: ValueTypes["TimetableDayWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableDay"]],
paginateTimetableDay?: [{	filter?: ValueTypes["TimetableDayWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableDayConnection"]],
getTimetableEmployeeRole?: [{	by: ValueTypes["TimetableEmployeeRoleUniqueWhere"],	filter?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null},ValueTypes["TimetableEmployeeRole"]],
listTimetableEmployeeRole?: [{	filter?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableEmployeeRoleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableEmployeeRole"]],
paginateTimetableEmployeeRole?: [{	filter?: ValueTypes["TimetableEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableEmployeeRoleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableEmployeeRoleConnection"]],
getTimetableShift?: [{	by: ValueTypes["TimetableShiftUniqueWhere"],	filter?: ValueTypes["TimetableShiftWhere"] | undefined | null},ValueTypes["TimetableShift"]],
listTimetableShift?: [{	filter?: ValueTypes["TimetableShiftWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableShift"]],
paginateTimetableShift?: [{	filter?: ValueTypes["TimetableShiftWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableShiftConnection"]],
validateUpdateTimetableShift?: [{	by: ValueTypes["TimetableShiftUniqueWhere"],	data: ValueTypes["TimetableShiftUpdateInput"]},ValueTypes["_ValidationResult"]],
getTimetableShiftGroup?: [{	by: ValueTypes["TimetableShiftGroupUniqueWhere"],	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null},ValueTypes["TimetableShiftGroup"]],
listTimetableShiftGroup?: [{	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftGroupOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableShiftGroup"]],
paginateTimetableShiftGroup?: [{	filter?: ValueTypes["TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftGroupOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableShiftGroupConnection"]],
getTimetableShiftPosition?: [{	by: ValueTypes["TimetableShiftPositionUniqueWhere"],	filter?: ValueTypes["TimetableShiftPositionWhere"] | undefined | null},ValueTypes["TimetableShiftPosition"]],
listTimetableShiftPosition?: [{	filter?: ValueTypes["TimetableShiftPositionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftPositionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableShiftPosition"]],
paginateTimetableShiftPosition?: [{	filter?: ValueTypes["TimetableShiftPositionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableShiftPositionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableShiftPositionConnection"]],
getTimetableTemplate?: [{	by: ValueTypes["TimetableTemplateUniqueWhere"],	filter?: ValueTypes["TimetableTemplateWhere"] | undefined | null},ValueTypes["TimetableTemplate"]],
listTimetableTemplate?: [{	filter?: ValueTypes["TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableTemplateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableTemplate"]],
paginateTimetableTemplate?: [{	filter?: ValueTypes["TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableTemplateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableTemplateConnection"]],
getEmployeeQueueItem?: [{	by: ValueTypes["EmployeeQueueItemUniqueWhere"],	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null},ValueTypes["EmployeeQueueItem"]],
listEmployeeQueueItem?: [{	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["EmployeeQueueItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["EmployeeQueueItem"]],
paginateEmployeeQueueItem?: [{	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["EmployeeQueueItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["EmployeeQueueItemConnection"]],
validateCreateEmployeeQueueItem?: [{	data: ValueTypes["EmployeeQueueItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateEmployeeQueueItem?: [{	by: ValueTypes["EmployeeQueueItemUniqueWhere"],	data: ValueTypes["EmployeeQueueItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductBanner?: [{	by: ValueTypes["ProductBannerUniqueWhere"],	filter?: ValueTypes["ProductBannerWhere"] | undefined | null},ValueTypes["ProductBanner"]],
listProductBanner?: [{	filter?: ValueTypes["ProductBannerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBanner"]],
paginateProductBanner?: [{	filter?: ValueTypes["ProductBannerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerConnection"]],
getProductBannerItem?: [{	by: ValueTypes["ProductBannerItemUniqueWhere"],	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null},ValueTypes["ProductBannerItem"]],
listProductBannerItem?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBannerItem"]],
paginateProductBannerItem?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerItemConnection"]],
getProductBannerList?: [{	by: ValueTypes["ProductBannerListUniqueWhere"],	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null},ValueTypes["ProductBannerList"]],
listProductBannerList?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBannerList"]],
paginateProductBannerList?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerListConnection"]],
getShiftsProfile?: [{	by: ValueTypes["ShiftsProfileUniqueWhere"],	filter?: ValueTypes["ShiftsProfileWhere"] | undefined | null},ValueTypes["ShiftsProfile"]],
listShiftsProfile?: [{	filter?: ValueTypes["ShiftsProfileWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ShiftsProfileOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ShiftsProfile"]],
paginateShiftsProfile?: [{	filter?: ValueTypes["ShiftsProfileWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ShiftsProfileOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ShiftsProfileConnection"]],
validateUpdateShiftsProfile?: [{	by: ValueTypes["ShiftsProfileUniqueWhere"],	data: ValueTypes["ShiftsProfileUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductIngredientCategory?: [{	by: ValueTypes["ProductIngredientCategoryUniqueWhere"],	filter?: ValueTypes["ProductIngredientCategoryWhere"] | undefined | null},ValueTypes["ProductIngredientCategory"]],
listProductIngredientCategory?: [{	filter?: ValueTypes["ProductIngredientCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientCategory"]],
paginateProductIngredientCategory?: [{	filter?: ValueTypes["ProductIngredientCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientCategoryConnection"]],
getProductIngredientCategoryLocale?: [{	by: ValueTypes["ProductIngredientCategoryLocaleUniqueWhere"],	filter?: ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientCategoryLocale"]],
listProductIngredientCategoryLocale?: [{	filter?: ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientCategoryLocale"]],
paginateProductIngredientCategoryLocale?: [{	filter?: ValueTypes["ProductIngredientCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientCategoryLocaleConnection"]],
getDeliveryRoute?: [{	by: ValueTypes["DeliveryRouteUniqueWhere"],	filter?: ValueTypes["DeliveryRouteWhere"] | undefined | null},ValueTypes["DeliveryRoute"]],
listDeliveryRoute?: [{	filter?: ValueTypes["DeliveryRouteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRouteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRoute"]],
paginateDeliveryRoute?: [{	filter?: ValueTypes["DeliveryRouteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRouteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRouteConnection"]],
getDeliveryWaypoint?: [{	by: ValueTypes["DeliveryWaypointUniqueWhere"],	filter?: ValueTypes["DeliveryWaypointWhere"] | undefined | null},ValueTypes["DeliveryWaypoint"]],
listDeliveryWaypoint?: [{	filter?: ValueTypes["DeliveryWaypointWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryWaypointOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryWaypoint"]],
paginateDeliveryWaypoint?: [{	filter?: ValueTypes["DeliveryWaypointWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryWaypointOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryWaypointConnection"]],
getAllergen?: [{	by: ValueTypes["AllergenUniqueWhere"],	filter?: ValueTypes["AllergenWhere"] | undefined | null},ValueTypes["Allergen"]],
listAllergen?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Allergen"]],
paginateAllergen?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenConnection"]],
getAllergenLocale?: [{	by: ValueTypes["AllergenLocaleUniqueWhere"],	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null},ValueTypes["AllergenLocale"]],
listAllergenLocale?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AllergenLocale"]],
paginateAllergenLocale?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenLocaleConnection"]],
getWeekHours?: [{	by: ValueTypes["WeekHoursUniqueWhere"],	filter?: ValueTypes["WeekHoursWhere"] | undefined | null},ValueTypes["WeekHours"]],
listWeekHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WeekHoursOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["WeekHours"]],
paginateWeekHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WeekHoursOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["WeekHoursConnection"]],
getPreparedIngredientQuantity?: [{	by: ValueTypes["PreparedIngredientQuantityUniqueWhere"],	filter?: ValueTypes["PreparedIngredientQuantityWhere"] | undefined | null},ValueTypes["PreparedIngredientQuantity"]],
listPreparedIngredientQuantity?: [{	filter?: ValueTypes["PreparedIngredientQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PreparedIngredientQuantityOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PreparedIngredientQuantity"]],
paginatePreparedIngredientQuantity?: [{	filter?: ValueTypes["PreparedIngredientQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PreparedIngredientQuantityOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PreparedIngredientQuantityConnection"]],
validateCreatePreparedIngredientQuantity?: [{	data: ValueTypes["PreparedIngredientQuantityCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdatePreparedIngredientQuantity?: [{	by: ValueTypes["PreparedIngredientQuantityUniqueWhere"],	data: ValueTypes["PreparedIngredientQuantityUpdateInput"]},ValueTypes["_ValidationResult"]],
getPreparedRecipeQuantity?: [{	by: ValueTypes["PreparedRecipeQuantityUniqueWhere"],	filter?: ValueTypes["PreparedRecipeQuantityWhere"] | undefined | null},ValueTypes["PreparedRecipeQuantity"]],
listPreparedRecipeQuantity?: [{	filter?: ValueTypes["PreparedRecipeQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PreparedRecipeQuantityOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PreparedRecipeQuantity"]],
paginatePreparedRecipeQuantity?: [{	filter?: ValueTypes["PreparedRecipeQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PreparedRecipeQuantityOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PreparedRecipeQuantityConnection"]],
validateCreatePreparedRecipeQuantity?: [{	data: ValueTypes["PreparedRecipeQuantityCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdatePreparedRecipeQuantity?: [{	by: ValueTypes["PreparedRecipeQuantityUniqueWhere"],	data: ValueTypes["PreparedRecipeQuantityUpdateInput"]},ValueTypes["_ValidationResult"]],
getPreparedPackingQuantity?: [{	by: ValueTypes["PreparedPackingQuantityUniqueWhere"],	filter?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null},ValueTypes["PreparedPackingQuantity"]],
listPreparedPackingQuantity?: [{	filter?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PreparedPackingQuantityOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PreparedPackingQuantity"]],
paginatePreparedPackingQuantity?: [{	filter?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PreparedPackingQuantityOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PreparedPackingQuantityConnection"]],
validateCreatePreparedPackingQuantity?: [{	data: ValueTypes["PreparedPackingQuantityCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdatePreparedPackingQuantity?: [{	by: ValueTypes["PreparedPackingQuantityUniqueWhere"],	data: ValueTypes["PreparedPackingQuantityUpdateInput"]},ValueTypes["_ValidationResult"]],
getDeliveryZone?: [{	by: ValueTypes["DeliveryZoneUniqueWhere"],	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null},ValueTypes["DeliveryZone"]],
listDeliveryZone?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryZone"]],
paginateDeliveryZone?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryZoneConnection"]],
getDeliveryTimelinePreset?: [{	by: ValueTypes["DeliveryTimelinePresetUniqueWhere"],	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null},ValueTypes["DeliveryTimelinePreset"]],
listDeliveryTimelinePreset?: [{	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryTimelinePresetOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryTimelinePreset"]],
paginateDeliveryTimelinePreset?: [{	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryTimelinePresetOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryTimelinePresetConnection"]],
getProductPackingLocale?: [{	by: ValueTypes["ProductPackingLocaleUniqueWhere"],	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null},ValueTypes["ProductPackingLocale"]],
listProductPackingLocale?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPackingLocale"]],
paginateProductPackingLocale?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingLocaleConnection"]],
getProductLocale?: [{	by: ValueTypes["ProductLocaleUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
listProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductLocale"]],
paginateProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductLocaleConnection"]],
getProductRecipeLocale?: [{	by: ValueTypes["ProductRecipeLocaleUniqueWhere"],	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null},ValueTypes["ProductRecipeLocale"]],
listProductRecipeLocale?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipeLocale"]],
paginateProductRecipeLocale?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeLocaleConnection"]],
getSuppliesPurchase?: [{	by: ValueTypes["SuppliesPurchaseUniqueWhere"],	filter?: ValueTypes["SuppliesPurchaseWhere"] | undefined | null},ValueTypes["SuppliesPurchase"]],
listSuppliesPurchase?: [{	filter?: ValueTypes["SuppliesPurchaseWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SuppliesPurchaseOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SuppliesPurchase"]],
paginateSuppliesPurchase?: [{	filter?: ValueTypes["SuppliesPurchaseWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SuppliesPurchaseOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SuppliesPurchaseConnection"]],
validateCreateSuppliesPurchase?: [{	data: ValueTypes["SuppliesPurchaseCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateSuppliesPurchase?: [{	by: ValueTypes["SuppliesPurchaseUniqueWhere"],	data: ValueTypes["SuppliesPurchaseUpdateInput"]},ValueTypes["_ValidationResult"]],
getLogRecord?: [{	by: ValueTypes["LogRecordUniqueWhere"],	filter?: ValueTypes["LogRecordWhere"] | undefined | null},ValueTypes["LogRecord"]],
listLogRecord?: [{	filter?: ValueTypes["LogRecordWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LogRecordOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LogRecord"]],
paginateLogRecord?: [{	filter?: ValueTypes["LogRecordWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LogRecordOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LogRecordConnection"]],
getSuppliesPurchaseItem?: [{	by: ValueTypes["SuppliesPurchaseItemUniqueWhere"],	filter?: ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null},ValueTypes["SuppliesPurchaseItem"]],
listSuppliesPurchaseItem?: [{	filter?: ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SuppliesPurchaseItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SuppliesPurchaseItem"]],
paginateSuppliesPurchaseItem?: [{	filter?: ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SuppliesPurchaseItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SuppliesPurchaseItemConnection"]],
validateCreateSuppliesPurchaseItem?: [{	data: ValueTypes["SuppliesPurchaseItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateSuppliesPurchaseItem?: [{	by: ValueTypes["SuppliesPurchaseItemUniqueWhere"],	data: ValueTypes["SuppliesPurchaseItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getVirtualProduct?: [{	by: ValueTypes["VirtualProductUniqueWhere"],	filter?: ValueTypes["VirtualProductWhere"] | undefined | null},ValueTypes["VirtualProduct"]],
listVirtualProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VirtualProduct"]],
paginateVirtualProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VirtualProductConnection"]],
getProductMetadata?: [{	by: ValueTypes["ProductMetadataUniqueWhere"],	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null},ValueTypes["ProductMetadata"]],
listProductMetadata?: [{	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductMetadata"]],
paginateProductMetadata?: [{	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductMetadataConnection"]],
getProductGridCategory?: [{	by: ValueTypes["ProductGridCategoryUniqueWhere"],	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null},ValueTypes["ProductGridCategory"]],
listProductGridCategory?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridCategory"]],
paginateProductGridCategory?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridCategoryConnection"]],
getUnassignedShiftInfo?: [{	by: ValueTypes["UnassignedShiftInfoUniqueWhere"],	filter?: ValueTypes["UnassignedShiftInfoWhere"] | undefined | null},ValueTypes["UnassignedShiftInfo"]],
listUnassignedShiftInfo?: [{	filter?: ValueTypes["UnassignedShiftInfoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UnassignedShiftInfoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["UnassignedShiftInfo"]],
paginateUnassignedShiftInfo?: [{	filter?: ValueTypes["UnassignedShiftInfoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UnassignedShiftInfoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["UnassignedShiftInfoConnection"]],
validateCreateUnassignedShiftInfo?: [{	data: ValueTypes["UnassignedShiftInfoCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateUnassignedShiftInfo?: [{	by: ValueTypes["UnassignedShiftInfoUniqueWhere"],	data: ValueTypes["UnassignedShiftInfoUpdateInput"]},ValueTypes["_ValidationResult"]],
getFreshingContainer?: [{	by: ValueTypes["FreshingContainerUniqueWhere"],	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null},ValueTypes["FreshingContainer"]],
listFreshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FreshingContainer"]],
paginateFreshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FreshingContainerConnection"]],
getTextItem?: [{	by: ValueTypes["TextItemUniqueWhere"],	filter?: ValueTypes["TextItemWhere"] | undefined | null},ValueTypes["TextItem"]],
listTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TextItem"]],
paginateTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TextItemConnection"]],
getTextList?: [{	by: ValueTypes["TextListUniqueWhere"],	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
listTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TextList"]],
paginateTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TextListConnection"]],
getDeliveryRegions?: [{	by: ValueTypes["DeliveryRegionsUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null},ValueTypes["DeliveryRegions"]],
listDeliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRegions"]],
paginateDeliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRegionsConnection"]],
getDeliveryRegionsItem?: [{	by: ValueTypes["DeliveryRegionsItemUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["DeliveryRegionsItem"]],
listDeliveryRegionsItem?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRegionsItem"]],
paginateDeliveryRegionsItem?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRegionsItemConnection"]],
getImageList?: [{	by: ValueTypes["ImageListUniqueWhere"],	filter?: ValueTypes["ImageListWhere"] | undefined | null},ValueTypes["ImageList"]],
listImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ImageList"]],
paginateImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageListConnection"]],
getImageListItem?: [{	by: ValueTypes["ImageListItemUniqueWhere"],	filter?: ValueTypes["ImageListItemWhere"] | undefined | null},ValueTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageListItemConnection"]],
getTimetablesEmployeePrivileges?: [{	by: ValueTypes["TimetablesEmployeePrivilegesUniqueWhere"],	filter?: ValueTypes["TimetablesEmployeePrivilegesWhere"] | undefined | null},ValueTypes["TimetablesEmployeePrivileges"]],
listTimetablesEmployeePrivileges?: [{	filter?: ValueTypes["TimetablesEmployeePrivilegesWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetablesEmployeePrivilegesOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetablesEmployeePrivileges"]],
paginateTimetablesEmployeePrivileges?: [{	filter?: ValueTypes["TimetablesEmployeePrivilegesWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetablesEmployeePrivilegesOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetablesEmployeePrivilegesConnection"]],
getTimetableDayNote?: [{	by: ValueTypes["TimetableDayNoteUniqueWhere"],	filter?: ValueTypes["TimetableDayNoteWhere"] | undefined | null},ValueTypes["TimetableDayNote"]],
listTimetableDayNote?: [{	filter?: ValueTypes["TimetableDayNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableDayNote"]],
paginateTimetableDayNote?: [{	filter?: ValueTypes["TimetableDayNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableDayNoteConnection"]],
getDeliveryMethodLocale?: [{	by: ValueTypes["DeliveryMethodLocaleUniqueWhere"],	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null},ValueTypes["DeliveryMethodLocale"]],
listDeliveryMethodLocale?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethodLocale"]],
paginateDeliveryMethodLocale?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodLocaleConnection"]],
getIngredientSupplier?: [{	by: ValueTypes["IngredientSupplierUniqueWhere"],	filter?: ValueTypes["IngredientSupplierWhere"] | undefined | null},ValueTypes["IngredientSupplier"]],
listIngredientSupplier?: [{	filter?: ValueTypes["IngredientSupplierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IngredientSupplier"]],
paginateIngredientSupplier?: [{	filter?: ValueTypes["IngredientSupplierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IngredientSupplierConnection"]],
getIngredientSupplierItem?: [{	by: ValueTypes["IngredientSupplierItemUniqueWhere"],	filter?: ValueTypes["IngredientSupplierItemWhere"] | undefined | null},ValueTypes["IngredientSupplierItem"]],
listIngredientSupplierItem?: [{	filter?: ValueTypes["IngredientSupplierItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IngredientSupplierItem"]],
paginateIngredientSupplierItem?: [{	filter?: ValueTypes["IngredientSupplierItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IngredientSupplierItemConnection"]],
getIngredientSupplierList?: [{	by: ValueTypes["IngredientSupplierListUniqueWhere"],	filter?: ValueTypes["IngredientSupplierListWhere"] | undefined | null},ValueTypes["IngredientSupplierList"]],
listIngredientSupplierList?: [{	filter?: ValueTypes["IngredientSupplierListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IngredientSupplierList"]],
paginateIngredientSupplierList?: [{	filter?: ValueTypes["IngredientSupplierListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IngredientSupplierListConnection"]],
getIngredientSupplierLocale?: [{	by: ValueTypes["IngredientSupplierLocaleUniqueWhere"],	filter?: ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null},ValueTypes["IngredientSupplierLocale"]],
listIngredientSupplierLocale?: [{	filter?: ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IngredientSupplierLocale"]],
paginateIngredientSupplierLocale?: [{	filter?: ValueTypes["IngredientSupplierLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IngredientSupplierLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IngredientSupplierLocaleConnection"]],
getRedirect?: [{	by: ValueTypes["RedirectUniqueWhere"],	filter?: ValueTypes["RedirectWhere"] | undefined | null},ValueTypes["Redirect"]],
listRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Redirect"]],
paginateRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["RedirectConnection"]],
getVenue?: [{	by: ValueTypes["VenueUniqueWhere"],	filter?: ValueTypes["VenueWhere"] | undefined | null},ValueTypes["Venue"]],
listVenue?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Venue"]],
paginateVenue?: [{	filter?: ValueTypes["VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VenueConnection"]],
getWorkspace?: [{	by: ValueTypes["WorkspaceUniqueWhere"],	filter?: ValueTypes["WorkspaceWhere"] | undefined | null},ValueTypes["Workspace"]],
listWorkspace?: [{	filter?: ValueTypes["WorkspaceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WorkspaceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Workspace"]],
paginateWorkspace?: [{	filter?: ValueTypes["WorkspaceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WorkspaceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["WorkspaceConnection"]],
getVenueEmployeeRole?: [{	by: ValueTypes["VenueEmployeeRoleUniqueWhere"],	filter?: ValueTypes["VenueEmployeeRoleWhere"] | undefined | null},ValueTypes["VenueEmployeeRole"]],
listVenueEmployeeRole?: [{	filter?: ValueTypes["VenueEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueEmployeeRoleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VenueEmployeeRole"]],
paginateVenueEmployeeRole?: [{	filter?: ValueTypes["VenueEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VenueEmployeeRoleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VenueEmployeeRoleConnection"]],
getIdentificationAttempt?: [{	by: ValueTypes["IdentificationAttemptUniqueWhere"],	filter?: ValueTypes["IdentificationAttemptWhere"] | undefined | null},ValueTypes["IdentificationAttempt"]],
listIdentificationAttempt?: [{	filter?: ValueTypes["IdentificationAttemptWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationAttemptOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IdentificationAttempt"]],
paginateIdentificationAttempt?: [{	filter?: ValueTypes["IdentificationAttemptWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationAttemptOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IdentificationAttemptConnection"]],
getIdentificationCode?: [{	by: ValueTypes["IdentificationCodeUniqueWhere"],	filter?: ValueTypes["IdentificationCodeWhere"] | undefined | null},ValueTypes["IdentificationCode"]],
listIdentificationCode?: [{	filter?: ValueTypes["IdentificationCodeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IdentificationCode"]],
paginateIdentificationCode?: [{	filter?: ValueTypes["IdentificationCodeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IdentificationCodeConnection"]],
getIdentificationCodeLocale?: [{	by: ValueTypes["IdentificationCodeLocaleUniqueWhere"],	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null},ValueTypes["IdentificationCodeLocale"]],
listIdentificationCodeLocale?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IdentificationCodeLocale"]],
paginateIdentificationCodeLocale?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IdentificationCodeLocaleConnection"]],
getDeviceIdentifier?: [{	by: ValueTypes["DeviceIdentifierUniqueWhere"],	filter?: ValueTypes["DeviceIdentifierWhere"] | undefined | null},ValueTypes["DeviceIdentifier"]],
listDeviceIdentifier?: [{	filter?: ValueTypes["DeviceIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeviceIdentifierOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeviceIdentifier"]],
paginateDeviceIdentifier?: [{	filter?: ValueTypes["DeviceIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeviceIdentifierOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeviceIdentifierConnection"]],
validateCreateDeviceIdentifier?: [{	data: ValueTypes["DeviceIdentifierCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateDeviceIdentifier?: [{	by: ValueTypes["DeviceIdentifierUniqueWhere"],	data: ValueTypes["DeviceIdentifierUpdateInput"]},ValueTypes["_ValidationResult"]],
getDeviceIdentifierMetadata?: [{	by: ValueTypes["DeviceIdentifierMetadataUniqueWhere"],	filter?: ValueTypes["DeviceIdentifierMetadataWhere"] | undefined | null},ValueTypes["DeviceIdentifierMetadata"]],
listDeviceIdentifierMetadata?: [{	filter?: ValueTypes["DeviceIdentifierMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeviceIdentifierMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeviceIdentifierMetadata"]],
paginateDeviceIdentifierMetadata?: [{	filter?: ValueTypes["DeviceIdentifierMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeviceIdentifierMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeviceIdentifierMetadataConnection"]],
getFulfillmentNote?: [{	by: ValueTypes["FulfillmentNoteUniqueWhere"],	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null},ValueTypes["FulfillmentNote"]],
listFulfillmentNote?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FulfillmentNote"]],
paginateFulfillmentNote?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FulfillmentNoteConnection"]],
getCustomerMetadata?: [{	by: ValueTypes["CustomerMetadataUniqueWhere"],	filter?: ValueTypes["CustomerMetadataWhere"] | undefined | null},ValueTypes["CustomerMetadata"]],
listCustomerMetadata?: [{	filter?: ValueTypes["CustomerMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerMetadata"]],
paginateCustomerMetadata?: [{	filter?: ValueTypes["CustomerMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerMetadataConnection"]],
getSubscriptionBox?: [{	by: ValueTypes["SubscriptionBoxUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxWhere"] | undefined | null},ValueTypes["SubscriptionBox"]],
listSubscriptionBox?: [{	filter?: ValueTypes["SubscriptionBoxWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SubscriptionBox"]],
paginateSubscriptionBox?: [{	filter?: ValueTypes["SubscriptionBoxWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SubscriptionBoxConnection"]],
getSubscriptionBoxLocale?: [{	by: ValueTypes["SubscriptionBoxLocaleUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
listSubscriptionBoxLocale?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
paginateSubscriptionBoxLocale?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SubscriptionBoxLocaleConnection"]],
getTimetableDayMetadata?: [{	by: ValueTypes["TimetableDayMetadataUniqueWhere"],	filter?: ValueTypes["TimetableDayMetadataWhere"] | undefined | null},ValueTypes["TimetableDayMetadata"]],
listTimetableDayMetadata?: [{	filter?: ValueTypes["TimetableDayMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TimetableDayMetadata"]],
paginateTimetableDayMetadata?: [{	filter?: ValueTypes["TimetableDayMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TimetableDayMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TimetableDayMetadataConnection"]],
getGridTileItem?: [{	by: ValueTypes["GridTileItemUniqueWhere"],	filter?: ValueTypes["GridTileItemWhere"] | undefined | null},ValueTypes["GridTileItem"]],
listGridTileItem?: [{	filter?: ValueTypes["GridTileItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTileItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GridTileItem"]],
paginateGridTileItem?: [{	filter?: ValueTypes["GridTileItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTileItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GridTileItemConnection"]],
getGridTiles?: [{	by: ValueTypes["GridTilesUniqueWhere"],	filter?: ValueTypes["GridTilesWhere"] | undefined | null},ValueTypes["GridTiles"]],
listGridTiles?: [{	filter?: ValueTypes["GridTilesWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTilesOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GridTiles"]],
paginateGridTiles?: [{	filter?: ValueTypes["GridTilesWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTilesOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GridTilesConnection"]],
getPosition?: [{	by: ValueTypes["PositionUniqueWhere"],	filter?: ValueTypes["PositionWhere"] | undefined | null},ValueTypes["Position"]],
listPosition?: [{	filter?: ValueTypes["PositionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PositionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Position"]],
paginatePosition?: [{	filter?: ValueTypes["PositionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PositionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PositionConnection"]],
getStatsWidget?: [{	by: ValueTypes["StatsWidgetUniqueWhere"],	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null},ValueTypes["StatsWidget"]],
listStatsWidget?: [{	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["StatsWidget"]],
paginateStatsWidget?: [{	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StatsWidgetConnection"]],
getStatsWidgetsLayout?: [{	by: ValueTypes["StatsWidgetsLayoutUniqueWhere"],	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null},ValueTypes["StatsWidgetsLayout"]],
listStatsWidgetsLayout?: [{	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetsLayoutOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["StatsWidgetsLayout"]],
paginateStatsWidgetsLayout?: [{	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetsLayoutOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StatsWidgetsLayoutConnection"]],
getStatsPage?: [{	by: ValueTypes["StatsPageUniqueWhere"],	filter?: ValueTypes["StatsPageWhere"] | undefined | null},ValueTypes["StatsPage"]],
listStatsPage?: [{	filter?: ValueTypes["StatsPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["StatsPage"]],
paginateStatsPage?: [{	filter?: ValueTypes["StatsPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StatsPageConnection"]],
getUnassignReason?: [{	by: ValueTypes["UnassignReasonUniqueWhere"],	filter?: ValueTypes["UnassignReasonWhere"] | undefined | null},ValueTypes["UnassignReason"]],
listUnassignReason?: [{	filter?: ValueTypes["UnassignReasonWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UnassignReasonOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["UnassignReason"]],
paginateUnassignReason?: [{	filter?: ValueTypes["UnassignReasonWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UnassignReasonOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["UnassignReasonConnection"]],
getOrderPaymentStatsByDay?: [{	by: ValueTypes["OrderPaymentStatsByDayUniqueWhere"],	filter?: ValueTypes["OrderPaymentStatsByDayWhere"] | undefined | null},ValueTypes["OrderPaymentStatsByDay"]],
listOrderPaymentStatsByDay?: [{	filter?: ValueTypes["OrderPaymentStatsByDayWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentStatsByDayOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPaymentStatsByDay"]],
paginateOrderPaymentStatsByDay?: [{	filter?: ValueTypes["OrderPaymentStatsByDayWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentStatsByDayOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPaymentStatsByDayConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Info"]: AliasType<{
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
createEmployeeQueue?: [{	data: ValueTypes["EmployeeQueueCreateInput"]},ValueTypes["EmployeeQueueCreateResult"]],
updateEmployeeQueue?: [{	by: ValueTypes["EmployeeQueueUniqueWhere"],	filter?: ValueTypes["EmployeeQueueWhere"] | undefined | null,	data: ValueTypes["EmployeeQueueUpdateInput"]},ValueTypes["EmployeeQueueUpdateResult"]],
upsertEmployeeQueue?: [{	by: ValueTypes["EmployeeQueueUniqueWhere"],	filter?: ValueTypes["EmployeeQueueWhere"] | undefined | null,	update: ValueTypes["EmployeeQueueUpdateInput"],	create: ValueTypes["EmployeeQueueCreateInput"]},ValueTypes["EmployeeQueueUpsertResult"]],
updateTimetableShift?: [{	by: ValueTypes["TimetableShiftUniqueWhere"],	filter?: ValueTypes["TimetableShiftWhere"] | undefined | null,	data: ValueTypes["TimetableShiftUpdateInput"]},ValueTypes["TimetableShiftUpdateResult"]],
createEmployeeQueueItem?: [{	data: ValueTypes["EmployeeQueueItemCreateInput"]},ValueTypes["EmployeeQueueItemCreateResult"]],
deleteEmployeeQueueItem?: [{	by: ValueTypes["EmployeeQueueItemUniqueWhere"],	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null},ValueTypes["EmployeeQueueItemDeleteResult"]],
updateEmployeeQueueItem?: [{	by: ValueTypes["EmployeeQueueItemUniqueWhere"],	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,	data: ValueTypes["EmployeeQueueItemUpdateInput"]},ValueTypes["EmployeeQueueItemUpdateResult"]],
upsertEmployeeQueueItem?: [{	by: ValueTypes["EmployeeQueueItemUniqueWhere"],	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,	update: ValueTypes["EmployeeQueueItemUpdateInput"],	create: ValueTypes["EmployeeQueueItemCreateInput"]},ValueTypes["EmployeeQueueItemUpsertResult"]],
updateShiftsProfile?: [{	by: ValueTypes["ShiftsProfileUniqueWhere"],	filter?: ValueTypes["ShiftsProfileWhere"] | undefined | null,	data: ValueTypes["ShiftsProfileUpdateInput"]},ValueTypes["ShiftsProfileUpdateResult"]],
createPreparedIngredientQuantity?: [{	data: ValueTypes["PreparedIngredientQuantityCreateInput"]},ValueTypes["PreparedIngredientQuantityCreateResult"]],
deletePreparedIngredientQuantity?: [{	by: ValueTypes["PreparedIngredientQuantityUniqueWhere"],	filter?: ValueTypes["PreparedIngredientQuantityWhere"] | undefined | null},ValueTypes["PreparedIngredientQuantityDeleteResult"]],
updatePreparedIngredientQuantity?: [{	by: ValueTypes["PreparedIngredientQuantityUniqueWhere"],	filter?: ValueTypes["PreparedIngredientQuantityWhere"] | undefined | null,	data: ValueTypes["PreparedIngredientQuantityUpdateInput"]},ValueTypes["PreparedIngredientQuantityUpdateResult"]],
upsertPreparedIngredientQuantity?: [{	by: ValueTypes["PreparedIngredientQuantityUniqueWhere"],	filter?: ValueTypes["PreparedIngredientQuantityWhere"] | undefined | null,	update: ValueTypes["PreparedIngredientQuantityUpdateInput"],	create: ValueTypes["PreparedIngredientQuantityCreateInput"]},ValueTypes["PreparedIngredientQuantityUpsertResult"]],
createPreparedRecipeQuantity?: [{	data: ValueTypes["PreparedRecipeQuantityCreateInput"]},ValueTypes["PreparedRecipeQuantityCreateResult"]],
deletePreparedRecipeQuantity?: [{	by: ValueTypes["PreparedRecipeQuantityUniqueWhere"],	filter?: ValueTypes["PreparedRecipeQuantityWhere"] | undefined | null},ValueTypes["PreparedRecipeQuantityDeleteResult"]],
updatePreparedRecipeQuantity?: [{	by: ValueTypes["PreparedRecipeQuantityUniqueWhere"],	filter?: ValueTypes["PreparedRecipeQuantityWhere"] | undefined | null,	data: ValueTypes["PreparedRecipeQuantityUpdateInput"]},ValueTypes["PreparedRecipeQuantityUpdateResult"]],
upsertPreparedRecipeQuantity?: [{	by: ValueTypes["PreparedRecipeQuantityUniqueWhere"],	filter?: ValueTypes["PreparedRecipeQuantityWhere"] | undefined | null,	update: ValueTypes["PreparedRecipeQuantityUpdateInput"],	create: ValueTypes["PreparedRecipeQuantityCreateInput"]},ValueTypes["PreparedRecipeQuantityUpsertResult"]],
createPreparedPackingQuantity?: [{	data: ValueTypes["PreparedPackingQuantityCreateInput"]},ValueTypes["PreparedPackingQuantityCreateResult"]],
deletePreparedPackingQuantity?: [{	by: ValueTypes["PreparedPackingQuantityUniqueWhere"],	filter?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null},ValueTypes["PreparedPackingQuantityDeleteResult"]],
updatePreparedPackingQuantity?: [{	by: ValueTypes["PreparedPackingQuantityUniqueWhere"],	filter?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null,	data: ValueTypes["PreparedPackingQuantityUpdateInput"]},ValueTypes["PreparedPackingQuantityUpdateResult"]],
upsertPreparedPackingQuantity?: [{	by: ValueTypes["PreparedPackingQuantityUniqueWhere"],	filter?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null,	update: ValueTypes["PreparedPackingQuantityUpdateInput"],	create: ValueTypes["PreparedPackingQuantityCreateInput"]},ValueTypes["PreparedPackingQuantityUpsertResult"]],
createSuppliesPurchase?: [{	data: ValueTypes["SuppliesPurchaseCreateInput"]},ValueTypes["SuppliesPurchaseCreateResult"]],
updateSuppliesPurchase?: [{	by: ValueTypes["SuppliesPurchaseUniqueWhere"],	filter?: ValueTypes["SuppliesPurchaseWhere"] | undefined | null,	data: ValueTypes["SuppliesPurchaseUpdateInput"]},ValueTypes["SuppliesPurchaseUpdateResult"]],
upsertSuppliesPurchase?: [{	by: ValueTypes["SuppliesPurchaseUniqueWhere"],	filter?: ValueTypes["SuppliesPurchaseWhere"] | undefined | null,	update: ValueTypes["SuppliesPurchaseUpdateInput"],	create: ValueTypes["SuppliesPurchaseCreateInput"]},ValueTypes["SuppliesPurchaseUpsertResult"]],
createSuppliesPurchaseItem?: [{	data: ValueTypes["SuppliesPurchaseItemCreateInput"]},ValueTypes["SuppliesPurchaseItemCreateResult"]],
updateSuppliesPurchaseItem?: [{	by: ValueTypes["SuppliesPurchaseItemUniqueWhere"],	filter?: ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null,	data: ValueTypes["SuppliesPurchaseItemUpdateInput"]},ValueTypes["SuppliesPurchaseItemUpdateResult"]],
upsertSuppliesPurchaseItem?: [{	by: ValueTypes["SuppliesPurchaseItemUniqueWhere"],	filter?: ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null,	update: ValueTypes["SuppliesPurchaseItemUpdateInput"],	create: ValueTypes["SuppliesPurchaseItemCreateInput"]},ValueTypes["SuppliesPurchaseItemUpsertResult"]],
createUnassignedShiftInfo?: [{	data: ValueTypes["UnassignedShiftInfoCreateInput"]},ValueTypes["UnassignedShiftInfoCreateResult"]],
deleteUnassignedShiftInfo?: [{	by: ValueTypes["UnassignedShiftInfoUniqueWhere"],	filter?: ValueTypes["UnassignedShiftInfoWhere"] | undefined | null},ValueTypes["UnassignedShiftInfoDeleteResult"]],
updateUnassignedShiftInfo?: [{	by: ValueTypes["UnassignedShiftInfoUniqueWhere"],	filter?: ValueTypes["UnassignedShiftInfoWhere"] | undefined | null,	data: ValueTypes["UnassignedShiftInfoUpdateInput"]},ValueTypes["UnassignedShiftInfoUpdateResult"]],
upsertUnassignedShiftInfo?: [{	by: ValueTypes["UnassignedShiftInfoUniqueWhere"],	filter?: ValueTypes["UnassignedShiftInfoWhere"] | undefined | null,	update: ValueTypes["UnassignedShiftInfoUpdateInput"],	create: ValueTypes["UnassignedShiftInfoCreateInput"]},ValueTypes["UnassignedShiftInfoUpsertResult"]],
createDeviceIdentifier?: [{	data: ValueTypes["DeviceIdentifierCreateInput"]},ValueTypes["DeviceIdentifierCreateResult"]],
updateDeviceIdentifier?: [{	by: ValueTypes["DeviceIdentifierUniqueWhere"],	filter?: ValueTypes["DeviceIdentifierWhere"] | undefined | null,	data: ValueTypes["DeviceIdentifierUpdateInput"]},ValueTypes["DeviceIdentifierUpdateResult"]],
upsertDeviceIdentifier?: [{	by: ValueTypes["DeviceIdentifierUniqueWhere"],	filter?: ValueTypes["DeviceIdentifierWhere"] | undefined | null,	update: ValueTypes["DeviceIdentifierUpdateInput"],	create: ValueTypes["DeviceIdentifierCreateInput"]},ValueTypes["DeviceIdentifierUpsertResult"]],
transaction?: [{	options?: ValueTypes["MutationTransactionOptions"] | undefined | null},ValueTypes["MutationTransaction"]],
	query?:ValueTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueueCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["EmployeeQueue"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationResult"]:AliasType<{
		ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"];
		['...on EmployeeQueueCreateResult']?: Omit<ValueTypes["EmployeeQueueCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on EmployeeQueueUpdateResult']?: Omit<ValueTypes["EmployeeQueueUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on EmployeeQueueUpsertResult']?: Omit<ValueTypes["EmployeeQueueUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on TimetableShiftUpdateResult']?: Omit<ValueTypes["TimetableShiftUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on EmployeeQueueItemCreateResult']?: Omit<ValueTypes["EmployeeQueueItemCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on EmployeeQueueItemDeleteResult']?: Omit<ValueTypes["EmployeeQueueItemDeleteResult"],keyof ValueTypes["MutationResult"]>;
		['...on EmployeeQueueItemUpdateResult']?: Omit<ValueTypes["EmployeeQueueItemUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on EmployeeQueueItemUpsertResult']?: Omit<ValueTypes["EmployeeQueueItemUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on ShiftsProfileUpdateResult']?: Omit<ValueTypes["ShiftsProfileUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on PreparedIngredientQuantityCreateResult']?: Omit<ValueTypes["PreparedIngredientQuantityCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on PreparedIngredientQuantityDeleteResult']?: Omit<ValueTypes["PreparedIngredientQuantityDeleteResult"],keyof ValueTypes["MutationResult"]>;
		['...on PreparedIngredientQuantityUpdateResult']?: Omit<ValueTypes["PreparedIngredientQuantityUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on PreparedIngredientQuantityUpsertResult']?: Omit<ValueTypes["PreparedIngredientQuantityUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on PreparedRecipeQuantityCreateResult']?: Omit<ValueTypes["PreparedRecipeQuantityCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on PreparedRecipeQuantityDeleteResult']?: Omit<ValueTypes["PreparedRecipeQuantityDeleteResult"],keyof ValueTypes["MutationResult"]>;
		['...on PreparedRecipeQuantityUpdateResult']?: Omit<ValueTypes["PreparedRecipeQuantityUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on PreparedRecipeQuantityUpsertResult']?: Omit<ValueTypes["PreparedRecipeQuantityUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on PreparedPackingQuantityCreateResult']?: Omit<ValueTypes["PreparedPackingQuantityCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on PreparedPackingQuantityDeleteResult']?: Omit<ValueTypes["PreparedPackingQuantityDeleteResult"],keyof ValueTypes["MutationResult"]>;
		['...on PreparedPackingQuantityUpdateResult']?: Omit<ValueTypes["PreparedPackingQuantityUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on PreparedPackingQuantityUpsertResult']?: Omit<ValueTypes["PreparedPackingQuantityUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on SuppliesPurchaseCreateResult']?: Omit<ValueTypes["SuppliesPurchaseCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on SuppliesPurchaseUpdateResult']?: Omit<ValueTypes["SuppliesPurchaseUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on SuppliesPurchaseUpsertResult']?: Omit<ValueTypes["SuppliesPurchaseUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on SuppliesPurchaseItemCreateResult']?: Omit<ValueTypes["SuppliesPurchaseItemCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on SuppliesPurchaseItemUpdateResult']?: Omit<ValueTypes["SuppliesPurchaseItemUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on SuppliesPurchaseItemUpsertResult']?: Omit<ValueTypes["SuppliesPurchaseItemUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on UnassignedShiftInfoCreateResult']?: Omit<ValueTypes["UnassignedShiftInfoCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on UnassignedShiftInfoDeleteResult']?: Omit<ValueTypes["UnassignedShiftInfoDeleteResult"],keyof ValueTypes["MutationResult"]>;
		['...on UnassignedShiftInfoUpdateResult']?: Omit<ValueTypes["UnassignedShiftInfoUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on UnassignedShiftInfoUpsertResult']?: Omit<ValueTypes["UnassignedShiftInfoUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on DeviceIdentifierCreateResult']?: Omit<ValueTypes["DeviceIdentifierCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on DeviceIdentifierUpdateResult']?: Omit<ValueTypes["DeviceIdentifierUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on DeviceIdentifierUpsertResult']?: Omit<ValueTypes["DeviceIdentifierUpsertResult"],keyof ValueTypes["MutationResult"]>;
		__typename?: boolean | `@${string}`
}>;
	["_MutationError"]: AliasType<{
	path?:ValueTypes["_PathFragment"],
	paths?:ValueTypes["_PathFragment"],
	type?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_MutationErrorType"]:_MutationErrorType;
	["EmployeeQueueUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["EmployeeQueue"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueueUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["EmployeeQueue"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["TimetableShiftUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["TimetableShift"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueueItemCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["EmployeeQueueItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueueItemDeleteResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["EmployeeQueueItem"],
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueueItemUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["EmployeeQueueItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["EmployeeQueueItemUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["EmployeeQueueItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["ShiftsProfileUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ShiftsProfile"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedIngredientQuantityCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["PreparedIngredientQuantity"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedIngredientQuantityDeleteResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["PreparedIngredientQuantity"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedIngredientQuantityUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["PreparedIngredientQuantity"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedIngredientQuantityUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["PreparedIngredientQuantity"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedRecipeQuantityCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["PreparedRecipeQuantity"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedRecipeQuantityDeleteResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["PreparedRecipeQuantity"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedRecipeQuantityUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["PreparedRecipeQuantity"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedRecipeQuantityUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["PreparedRecipeQuantity"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedPackingQuantityCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["PreparedPackingQuantity"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedPackingQuantityDeleteResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["PreparedPackingQuantity"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedPackingQuantityUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["PreparedPackingQuantity"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["PreparedPackingQuantityUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["PreparedPackingQuantity"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchaseCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["SuppliesPurchase"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchaseUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["SuppliesPurchase"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchaseUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["SuppliesPurchase"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchaseItemCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["SuppliesPurchaseItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchaseItemUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["SuppliesPurchaseItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["SuppliesPurchaseItemUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["SuppliesPurchaseItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["UnassignedShiftInfoCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["UnassignedShiftInfo"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["UnassignedShiftInfoDeleteResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["UnassignedShiftInfo"],
		__typename?: boolean | `@${string}`
}>;
	["UnassignedShiftInfoUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["UnassignedShiftInfo"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["UnassignedShiftInfoUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["UnassignedShiftInfo"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["DeviceIdentifierCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["DeviceIdentifier"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["DeviceIdentifierUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["DeviceIdentifier"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["DeviceIdentifierUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["DeviceIdentifier"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransaction"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	validation?:ValueTypes["_ValidationResult"],
createEmployeeQueue?: [{	data: ValueTypes["EmployeeQueueCreateInput"]},ValueTypes["EmployeeQueueCreateResult"]],
updateEmployeeQueue?: [{	by: ValueTypes["EmployeeQueueUniqueWhere"],	filter?: ValueTypes["EmployeeQueueWhere"] | undefined | null,	data: ValueTypes["EmployeeQueueUpdateInput"]},ValueTypes["EmployeeQueueUpdateResult"]],
upsertEmployeeQueue?: [{	by: ValueTypes["EmployeeQueueUniqueWhere"],	filter?: ValueTypes["EmployeeQueueWhere"] | undefined | null,	update: ValueTypes["EmployeeQueueUpdateInput"],	create: ValueTypes["EmployeeQueueCreateInput"]},ValueTypes["EmployeeQueueUpsertResult"]],
updateTimetableShift?: [{	by: ValueTypes["TimetableShiftUniqueWhere"],	filter?: ValueTypes["TimetableShiftWhere"] | undefined | null,	data: ValueTypes["TimetableShiftUpdateInput"]},ValueTypes["TimetableShiftUpdateResult"]],
createEmployeeQueueItem?: [{	data: ValueTypes["EmployeeQueueItemCreateInput"]},ValueTypes["EmployeeQueueItemCreateResult"]],
deleteEmployeeQueueItem?: [{	by: ValueTypes["EmployeeQueueItemUniqueWhere"],	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null},ValueTypes["EmployeeQueueItemDeleteResult"]],
updateEmployeeQueueItem?: [{	by: ValueTypes["EmployeeQueueItemUniqueWhere"],	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,	data: ValueTypes["EmployeeQueueItemUpdateInput"]},ValueTypes["EmployeeQueueItemUpdateResult"]],
upsertEmployeeQueueItem?: [{	by: ValueTypes["EmployeeQueueItemUniqueWhere"],	filter?: ValueTypes["EmployeeQueueItemWhere"] | undefined | null,	update: ValueTypes["EmployeeQueueItemUpdateInput"],	create: ValueTypes["EmployeeQueueItemCreateInput"]},ValueTypes["EmployeeQueueItemUpsertResult"]],
updateShiftsProfile?: [{	by: ValueTypes["ShiftsProfileUniqueWhere"],	filter?: ValueTypes["ShiftsProfileWhere"] | undefined | null,	data: ValueTypes["ShiftsProfileUpdateInput"]},ValueTypes["ShiftsProfileUpdateResult"]],
createPreparedIngredientQuantity?: [{	data: ValueTypes["PreparedIngredientQuantityCreateInput"]},ValueTypes["PreparedIngredientQuantityCreateResult"]],
deletePreparedIngredientQuantity?: [{	by: ValueTypes["PreparedIngredientQuantityUniqueWhere"],	filter?: ValueTypes["PreparedIngredientQuantityWhere"] | undefined | null},ValueTypes["PreparedIngredientQuantityDeleteResult"]],
updatePreparedIngredientQuantity?: [{	by: ValueTypes["PreparedIngredientQuantityUniqueWhere"],	filter?: ValueTypes["PreparedIngredientQuantityWhere"] | undefined | null,	data: ValueTypes["PreparedIngredientQuantityUpdateInput"]},ValueTypes["PreparedIngredientQuantityUpdateResult"]],
upsertPreparedIngredientQuantity?: [{	by: ValueTypes["PreparedIngredientQuantityUniqueWhere"],	filter?: ValueTypes["PreparedIngredientQuantityWhere"] | undefined | null,	update: ValueTypes["PreparedIngredientQuantityUpdateInput"],	create: ValueTypes["PreparedIngredientQuantityCreateInput"]},ValueTypes["PreparedIngredientQuantityUpsertResult"]],
createPreparedRecipeQuantity?: [{	data: ValueTypes["PreparedRecipeQuantityCreateInput"]},ValueTypes["PreparedRecipeQuantityCreateResult"]],
deletePreparedRecipeQuantity?: [{	by: ValueTypes["PreparedRecipeQuantityUniqueWhere"],	filter?: ValueTypes["PreparedRecipeQuantityWhere"] | undefined | null},ValueTypes["PreparedRecipeQuantityDeleteResult"]],
updatePreparedRecipeQuantity?: [{	by: ValueTypes["PreparedRecipeQuantityUniqueWhere"],	filter?: ValueTypes["PreparedRecipeQuantityWhere"] | undefined | null,	data: ValueTypes["PreparedRecipeQuantityUpdateInput"]},ValueTypes["PreparedRecipeQuantityUpdateResult"]],
upsertPreparedRecipeQuantity?: [{	by: ValueTypes["PreparedRecipeQuantityUniqueWhere"],	filter?: ValueTypes["PreparedRecipeQuantityWhere"] | undefined | null,	update: ValueTypes["PreparedRecipeQuantityUpdateInput"],	create: ValueTypes["PreparedRecipeQuantityCreateInput"]},ValueTypes["PreparedRecipeQuantityUpsertResult"]],
createPreparedPackingQuantity?: [{	data: ValueTypes["PreparedPackingQuantityCreateInput"]},ValueTypes["PreparedPackingQuantityCreateResult"]],
deletePreparedPackingQuantity?: [{	by: ValueTypes["PreparedPackingQuantityUniqueWhere"],	filter?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null},ValueTypes["PreparedPackingQuantityDeleteResult"]],
updatePreparedPackingQuantity?: [{	by: ValueTypes["PreparedPackingQuantityUniqueWhere"],	filter?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null,	data: ValueTypes["PreparedPackingQuantityUpdateInput"]},ValueTypes["PreparedPackingQuantityUpdateResult"]],
upsertPreparedPackingQuantity?: [{	by: ValueTypes["PreparedPackingQuantityUniqueWhere"],	filter?: ValueTypes["PreparedPackingQuantityWhere"] | undefined | null,	update: ValueTypes["PreparedPackingQuantityUpdateInput"],	create: ValueTypes["PreparedPackingQuantityCreateInput"]},ValueTypes["PreparedPackingQuantityUpsertResult"]],
createSuppliesPurchase?: [{	data: ValueTypes["SuppliesPurchaseCreateInput"]},ValueTypes["SuppliesPurchaseCreateResult"]],
updateSuppliesPurchase?: [{	by: ValueTypes["SuppliesPurchaseUniqueWhere"],	filter?: ValueTypes["SuppliesPurchaseWhere"] | undefined | null,	data: ValueTypes["SuppliesPurchaseUpdateInput"]},ValueTypes["SuppliesPurchaseUpdateResult"]],
upsertSuppliesPurchase?: [{	by: ValueTypes["SuppliesPurchaseUniqueWhere"],	filter?: ValueTypes["SuppliesPurchaseWhere"] | undefined | null,	update: ValueTypes["SuppliesPurchaseUpdateInput"],	create: ValueTypes["SuppliesPurchaseCreateInput"]},ValueTypes["SuppliesPurchaseUpsertResult"]],
createSuppliesPurchaseItem?: [{	data: ValueTypes["SuppliesPurchaseItemCreateInput"]},ValueTypes["SuppliesPurchaseItemCreateResult"]],
updateSuppliesPurchaseItem?: [{	by: ValueTypes["SuppliesPurchaseItemUniqueWhere"],	filter?: ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null,	data: ValueTypes["SuppliesPurchaseItemUpdateInput"]},ValueTypes["SuppliesPurchaseItemUpdateResult"]],
upsertSuppliesPurchaseItem?: [{	by: ValueTypes["SuppliesPurchaseItemUniqueWhere"],	filter?: ValueTypes["SuppliesPurchaseItemWhere"] | undefined | null,	update: ValueTypes["SuppliesPurchaseItemUpdateInput"],	create: ValueTypes["SuppliesPurchaseItemCreateInput"]},ValueTypes["SuppliesPurchaseItemUpsertResult"]],
createUnassignedShiftInfo?: [{	data: ValueTypes["UnassignedShiftInfoCreateInput"]},ValueTypes["UnassignedShiftInfoCreateResult"]],
deleteUnassignedShiftInfo?: [{	by: ValueTypes["UnassignedShiftInfoUniqueWhere"],	filter?: ValueTypes["UnassignedShiftInfoWhere"] | undefined | null},ValueTypes["UnassignedShiftInfoDeleteResult"]],
updateUnassignedShiftInfo?: [{	by: ValueTypes["UnassignedShiftInfoUniqueWhere"],	filter?: ValueTypes["UnassignedShiftInfoWhere"] | undefined | null,	data: ValueTypes["UnassignedShiftInfoUpdateInput"]},ValueTypes["UnassignedShiftInfoUpdateResult"]],
upsertUnassignedShiftInfo?: [{	by: ValueTypes["UnassignedShiftInfoUniqueWhere"],	filter?: ValueTypes["UnassignedShiftInfoWhere"] | undefined | null,	update: ValueTypes["UnassignedShiftInfoUpdateInput"],	create: ValueTypes["UnassignedShiftInfoCreateInput"]},ValueTypes["UnassignedShiftInfoUpsertResult"]],
createDeviceIdentifier?: [{	data: ValueTypes["DeviceIdentifierCreateInput"]},ValueTypes["DeviceIdentifierCreateResult"]],
updateDeviceIdentifier?: [{	by: ValueTypes["DeviceIdentifierUniqueWhere"],	filter?: ValueTypes["DeviceIdentifierWhere"] | undefined | null,	data: ValueTypes["DeviceIdentifierUpdateInput"]},ValueTypes["DeviceIdentifierUpdateResult"]],
upsertDeviceIdentifier?: [{	by: ValueTypes["DeviceIdentifierUniqueWhere"],	filter?: ValueTypes["DeviceIdentifierWhere"] | undefined | null,	update: ValueTypes["DeviceIdentifierUpdateInput"],	create: ValueTypes["DeviceIdentifierCreateInput"]},ValueTypes["DeviceIdentifierUpsertResult"]],
	query?:ValueTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransactionOptions"]: {
	deferForeignKeyConstraints?: boolean | undefined | null
};
	["_Schema"]: AliasType<{
	enums?:ValueTypes["_Enum"],
	entities?:ValueTypes["_Entity"],
		__typename?: boolean | `@${string}`
}>;
	["_Entity"]: AliasType<{
	name?:boolean | `@${string}`,
	customPrimaryAllowed?:boolean | `@${string}`,
	fields?:ValueTypes["_Field"],
	unique?:ValueTypes["_UniqueConstraint"],
		__typename?: boolean | `@${string}`
}>;
	["_UniqueConstraint"]: AliasType<{
	fields?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Field"]:AliasType<{
		name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"];
		['...on _Column']?: Omit<ValueTypes["_Column"],keyof ValueTypes["_Field"]>;
		['...on _Relation']?: Omit<ValueTypes["_Relation"],keyof ValueTypes["_Field"]>;
		__typename?: boolean | `@${string}`
}>;
	["_Column"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	enumName?:boolean | `@${string}`,
	defaultValue?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_OnDeleteBehaviour"]:_OnDeleteBehaviour;
	["_RelationSide"]:_RelationSide;
	["_OrderByDirection"]:_OrderByDirection;
	["_OrderBy"]: AliasType<{
	path?:boolean | `@${string}`,
	direction?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Relation"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	side?:boolean | `@${string}`,
	targetEntity?:boolean | `@${string}`,
	ownedBy?:boolean | `@${string}`,
	inversedBy?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	onDelete?:boolean | `@${string}`,
	orphanRemoval?:boolean | `@${string}`,
	orderBy?:ValueTypes["_OrderBy"],
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_Rule"]: AliasType<{
	message?:ValueTypes["_RuleMessage"],
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Validator"]: AliasType<{
	operation?:boolean | `@${string}`,
	arguments?:ValueTypes["_Argument"],
		__typename?: boolean | `@${string}`
}>;
	["_Argument"]: AliasType<{		["...on _ValidatorArgument"] : ValueTypes["_ValidatorArgument"],
		["...on _PathArgument"] : ValueTypes["_PathArgument"],
		["...on _LiteralArgument"] : ValueTypes["_LiteralArgument"]
		__typename?: boolean | `@${string}`
}>;
	["_ValidatorArgument"]: AliasType<{
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_PathArgument"]: AliasType<{
	path?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_LiteralArgument"]: AliasType<{
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_RuleMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Enum"]: AliasType<{
	name?:boolean | `@${string}`,
	values?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>
  }

export type ModelTypes = {
    ["Query"]: {
		getAddress?: GraphQLTypes["Address"] | undefined,
	listAddress: Array<GraphQLTypes["Address"]>,
	paginateAddress: GraphQLTypes["AddressConnection"],
	getCountry?: GraphQLTypes["Country"] | undefined,
	listCountry: Array<GraphQLTypes["Country"]>,
	paginateCountry: GraphQLTypes["CountryConnection"],
	getChannel?: GraphQLTypes["Channel"] | undefined,
	listChannel: Array<GraphQLTypes["Channel"]>,
	paginateChannel: GraphQLTypes["ChannelConnection"],
	getCustomer?: GraphQLTypes["Customer"] | undefined,
	listCustomer: Array<GraphQLTypes["Customer"]>,
	paginateCustomer: GraphQLTypes["CustomerConnection"],
	getDeliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined,
	listDeliveryMethod: Array<GraphQLTypes["DeliveryMethod"]>,
	paginateDeliveryMethod: GraphQLTypes["DeliveryMethodConnection"],
	getOrder?: GraphQLTypes["Order"] | undefined,
	listOrder: Array<GraphQLTypes["Order"]>,
	paginateOrder: GraphQLTypes["OrderConnection"],
	getOrderPayment?: GraphQLTypes["OrderPayment"] | undefined,
	listOrderPayment: Array<GraphQLTypes["OrderPayment"]>,
	paginateOrderPayment: GraphQLTypes["OrderPaymentConnection"],
	getOrderDelivery?: GraphQLTypes["OrderDelivery"] | undefined,
	listOrderDelivery: Array<GraphQLTypes["OrderDelivery"]>,
	paginateOrderDelivery: GraphQLTypes["OrderDeliveryConnection"],
	getOrderItem?: GraphQLTypes["OrderItem"] | undefined,
	listOrderItem: Array<GraphQLTypes["OrderItem"]>,
	paginateOrderItem: GraphQLTypes["OrderItemConnection"],
	getPaymentMethod?: GraphQLTypes["PaymentMethod"] | undefined,
	listPaymentMethod: Array<GraphQLTypes["PaymentMethod"]>,
	paginatePaymentMethod: GraphQLTypes["PaymentMethodConnection"],
	getVatRate?: GraphQLTypes["VatRate"] | undefined,
	listVatRate: Array<GraphQLTypes["VatRate"]>,
	paginateVatRate: GraphQLTypes["VatRateConnection"],
	getProduct?: GraphQLTypes["Product"] | undefined,
	listProduct: Array<GraphQLTypes["Product"]>,
	paginateProduct: GraphQLTypes["ProductConnection"],
	getProductStock?: GraphQLTypes["ProductStock"] | undefined,
	listProductStock: Array<GraphQLTypes["ProductStock"]>,
	paginateProductStock: GraphQLTypes["ProductStockConnection"],
	getProductPacking?: GraphQLTypes["ProductPacking"] | undefined,
	listProductPacking: Array<GraphQLTypes["ProductPacking"]>,
	paginateProductPacking: GraphQLTypes["ProductPackingConnection"],
	getProductRecipe?: GraphQLTypes["ProductRecipe"] | undefined,
	listProductRecipe: Array<GraphQLTypes["ProductRecipe"]>,
	paginateProductRecipe: GraphQLTypes["ProductRecipeConnection"],
	getProductCategory?: GraphQLTypes["ProductCategory"] | undefined,
	listProductCategory: Array<GraphQLTypes["ProductCategory"]>,
	paginateProductCategory: GraphQLTypes["ProductCategoryConnection"],
	getStore?: GraphQLTypes["Store"] | undefined,
	listStore: Array<GraphQLTypes["Store"]>,
	paginateStore: GraphQLTypes["StoreConnection"],
	getTag?: GraphQLTypes["Tag"] | undefined,
	listTag: Array<GraphQLTypes["Tag"]>,
	paginateTag: GraphQLTypes["TagConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	listBusinessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"],
	getProductList?: GraphQLTypes["ProductList"] | undefined,
	listProductList: Array<GraphQLTypes["ProductList"]>,
	paginateProductList: GraphQLTypes["ProductListConnection"],
	getProductListItem?: GraphQLTypes["ProductListItem"] | undefined,
	listProductListItem: Array<GraphQLTypes["ProductListItem"]>,
	paginateProductListItem: GraphQLTypes["ProductListItemConnection"],
	getProductCategoryList?: GraphQLTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<GraphQLTypes["ProductCategoryList"]>,
	paginateProductCategoryList: GraphQLTypes["ProductCategoryListConnection"],
	getProductCategoryListItem?: GraphQLTypes["ProductCategoryListItem"] | undefined,
	listProductCategoryListItem: Array<GraphQLTypes["ProductCategoryListItem"]>,
	paginateProductCategoryListItem: GraphQLTypes["ProductCategoryListItemConnection"],
	getBusinessCategoryLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	listBusinessCategoryLocale: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	paginateBusinessCategoryLocale: GraphQLTypes["BusinessCategoryLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getProductCategoryListLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	listProductCategoryListLocale: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	paginateProductCategoryListLocale: GraphQLTypes["ProductCategoryListLocaleConnection"],
	getCustomerName?: GraphQLTypes["CustomerName"] | undefined,
	listCustomerName: Array<GraphQLTypes["CustomerName"]>,
	paginateCustomerName: GraphQLTypes["CustomerNameConnection"],
	getProductIngredientItem?: GraphQLTypes["ProductIngredientItem"] | undefined,
	listProductIngredientItem: Array<GraphQLTypes["ProductIngredientItem"]>,
	paginateProductIngredientItem: GraphQLTypes["ProductIngredientItemConnection"],
	getProductIngredientItemQuantity?: GraphQLTypes["ProductIngredientItemQuantity"] | undefined,
	listProductIngredientItemQuantity: Array<GraphQLTypes["ProductIngredientItemQuantity"]>,
	paginateProductIngredientItemQuantity: GraphQLTypes["ProductIngredientItemQuantityConnection"],
	getProductIngredientItemQuantityUnit?: GraphQLTypes["ProductIngredientItemQuantityUnit"] | undefined,
	listProductIngredientItemQuantityUnit: Array<GraphQLTypes["ProductIngredientItemQuantityUnit"]>,
	paginateProductIngredientItemQuantityUnit: GraphQLTypes["ProductIngredientItemQuantityUnitConnection"],
	getProductIngredient?: GraphQLTypes["ProductIngredient"] | undefined,
	listProductIngredient: Array<GraphQLTypes["ProductIngredient"]>,
	paginateProductIngredient: GraphQLTypes["ProductIngredientConnection"],
	getProductIngredientRating?: GraphQLTypes["ProductIngredientRating"] | undefined,
	listProductIngredientRating: Array<GraphQLTypes["ProductIngredientRating"]>,
	paginateProductIngredientRating: GraphQLTypes["ProductIngredientRatingConnection"],
	getProductIngredientLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	listProductIngredientLocale: Array<GraphQLTypes["ProductIngredientLocale"]>,
	paginateProductIngredientLocale: GraphQLTypes["ProductIngredientLocaleConnection"],
	getOrderRecurrence?: GraphQLTypes["OrderRecurrence"] | undefined,
	listOrderRecurrence: Array<GraphQLTypes["OrderRecurrence"]>,
	paginateOrderRecurrence: GraphQLTypes["OrderRecurrenceConnection"],
	getOrderRecurrenceLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	listOrderRecurrenceLocale: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	paginateOrderRecurrenceLocale: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	getStaff?: GraphQLTypes["Staff"] | undefined,
	listStaff: Array<GraphQLTypes["Staff"]>,
	paginateStaff: GraphQLTypes["StaffConnection"],
	getBlog?: GraphQLTypes["Blog"] | undefined,
	listBlog: Array<GraphQLTypes["Blog"]>,
	paginateBlog: GraphQLTypes["BlogConnection"],
	getBlogLocale?: GraphQLTypes["BlogLocale"] | undefined,
	listBlogLocale: Array<GraphQLTypes["BlogLocale"]>,
	paginateBlogLocale: GraphQLTypes["BlogLocaleConnection"],
	getBlogPost?: GraphQLTypes["BlogPost"] | undefined,
	listBlogPost: Array<GraphQLTypes["BlogPost"]>,
	paginateBlogPost: GraphQLTypes["BlogPostConnection"],
	getBlogPostLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<GraphQLTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: GraphQLTypes["BlogPostLocaleConnection"],
	getColorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	listColorPallete: Array<GraphQLTypes["ColorPallete"]>,
	paginateColorPallete: GraphQLTypes["ColorPalleteConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getBlogPostList?: GraphQLTypes["BlogPostList"] | undefined,
	listBlogPostList: Array<GraphQLTypes["BlogPostList"]>,
	paginateBlogPostList: GraphQLTypes["BlogPostListConnection"],
	getBlogPostListItem?: GraphQLTypes["BlogPostListItem"] | undefined,
	listBlogPostListItem: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateBlogPostListItem: GraphQLTypes["BlogPostListItemConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getLinkListItem?: GraphQLTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<GraphQLTypes["LinkListItem"]>,
	paginateLinkListItem: GraphQLTypes["LinkListItemConnection"],
	getCover?: GraphQLTypes["Cover"] | undefined,
	listCover: Array<GraphQLTypes["Cover"]>,
	paginateCover: GraphQLTypes["CoverConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getMedium?: GraphQLTypes["Medium"] | undefined,
	listMedium: Array<GraphQLTypes["Medium"]>,
	paginateMedium: GraphQLTypes["MediumConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getSocialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	listSocialsAndApps: Array<GraphQLTypes["SocialsAndApps"]>,
	paginateSocialsAndApps: GraphQLTypes["SocialsAndAppsConnection"],
	getProductGrid?: GraphQLTypes["ProductGrid"] | undefined,
	listProductGrid: Array<GraphQLTypes["ProductGrid"]>,
	paginateProductGrid: GraphQLTypes["ProductGridConnection"],
	getProductGridItem?: GraphQLTypes["ProductGridItem"] | undefined,
	listProductGridItem: Array<GraphQLTypes["ProductGridItem"]>,
	paginateProductGridItem: GraphQLTypes["ProductGridItemConnection"],
	getGallery?: GraphQLTypes["Gallery"] | undefined,
	listGallery: Array<GraphQLTypes["Gallery"]>,
	paginateGallery: GraphQLTypes["GalleryConnection"],
	getGalleryItem?: GraphQLTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<GraphQLTypes["GalleryItem"]>,
	paginateGalleryItem: GraphQLTypes["GalleryItemConnection"],
	getHero?: GraphQLTypes["Hero"] | undefined,
	listHero: Array<GraphQLTypes["Hero"]>,
	paginateHero: GraphQLTypes["HeroConnection"],
	getPage?: GraphQLTypes["Page"] | undefined,
	listPage: Array<GraphQLTypes["Page"]>,
	paginatePage: GraphQLTypes["PageConnection"],
	getPageLocale?: GraphQLTypes["PageLocale"] | undefined,
	listPageLocale: Array<GraphQLTypes["PageLocale"]>,
	paginatePageLocale: GraphQLTypes["PageLocaleConnection"],
	getDeadlineTemplate?: GraphQLTypes["DeadlineTemplate"] | undefined,
	listDeadlineTemplate: Array<GraphQLTypes["DeadlineTemplate"]>,
	paginateDeadlineTemplate: GraphQLTypes["DeadlineTemplateConnection"],
	getEmployeeQueue?: GraphQLTypes["EmployeeQueue"] | undefined,
	listEmployeeQueue: Array<GraphQLTypes["EmployeeQueue"]>,
	paginateEmployeeQueue: GraphQLTypes["EmployeeQueueConnection"],
	validateCreateEmployeeQueue: GraphQLTypes["_ValidationResult"],
	validateUpdateEmployeeQueue: GraphQLTypes["_ValidationResult"],
	getTimetableDay?: GraphQLTypes["TimetableDay"] | undefined,
	listTimetableDay: Array<GraphQLTypes["TimetableDay"]>,
	paginateTimetableDay: GraphQLTypes["TimetableDayConnection"],
	getTimetableEmployeeRole?: GraphQLTypes["TimetableEmployeeRole"] | undefined,
	listTimetableEmployeeRole: Array<GraphQLTypes["TimetableEmployeeRole"]>,
	paginateTimetableEmployeeRole: GraphQLTypes["TimetableEmployeeRoleConnection"],
	getTimetableShift?: GraphQLTypes["TimetableShift"] | undefined,
	listTimetableShift: Array<GraphQLTypes["TimetableShift"]>,
	paginateTimetableShift: GraphQLTypes["TimetableShiftConnection"],
	validateUpdateTimetableShift: GraphQLTypes["_ValidationResult"],
	getTimetableShiftGroup?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	listTimetableShiftGroup: Array<GraphQLTypes["TimetableShiftGroup"]>,
	paginateTimetableShiftGroup: GraphQLTypes["TimetableShiftGroupConnection"],
	getTimetableShiftPosition?: GraphQLTypes["TimetableShiftPosition"] | undefined,
	listTimetableShiftPosition: Array<GraphQLTypes["TimetableShiftPosition"]>,
	paginateTimetableShiftPosition: GraphQLTypes["TimetableShiftPositionConnection"],
	getTimetableTemplate?: GraphQLTypes["TimetableTemplate"] | undefined,
	listTimetableTemplate: Array<GraphQLTypes["TimetableTemplate"]>,
	paginateTimetableTemplate: GraphQLTypes["TimetableTemplateConnection"],
	getEmployeeQueueItem?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	listEmployeeQueueItem: Array<GraphQLTypes["EmployeeQueueItem"]>,
	paginateEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemConnection"],
	validateCreateEmployeeQueueItem: GraphQLTypes["_ValidationResult"],
	validateUpdateEmployeeQueueItem: GraphQLTypes["_ValidationResult"],
	getProductBanner?: GraphQLTypes["ProductBanner"] | undefined,
	listProductBanner: Array<GraphQLTypes["ProductBanner"]>,
	paginateProductBanner: GraphQLTypes["ProductBannerConnection"],
	getProductBannerItem?: GraphQLTypes["ProductBannerItem"] | undefined,
	listProductBannerItem: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateProductBannerItem: GraphQLTypes["ProductBannerItemConnection"],
	getProductBannerList?: GraphQLTypes["ProductBannerList"] | undefined,
	listProductBannerList: Array<GraphQLTypes["ProductBannerList"]>,
	paginateProductBannerList: GraphQLTypes["ProductBannerListConnection"],
	getShiftsProfile?: GraphQLTypes["ShiftsProfile"] | undefined,
	listShiftsProfile: Array<GraphQLTypes["ShiftsProfile"]>,
	paginateShiftsProfile: GraphQLTypes["ShiftsProfileConnection"],
	validateUpdateShiftsProfile: GraphQLTypes["_ValidationResult"],
	getProductIngredientCategory?: GraphQLTypes["ProductIngredientCategory"] | undefined,
	listProductIngredientCategory: Array<GraphQLTypes["ProductIngredientCategory"]>,
	paginateProductIngredientCategory: GraphQLTypes["ProductIngredientCategoryConnection"],
	getProductIngredientCategoryLocale?: GraphQLTypes["ProductIngredientCategoryLocale"] | undefined,
	listProductIngredientCategoryLocale: Array<GraphQLTypes["ProductIngredientCategoryLocale"]>,
	paginateProductIngredientCategoryLocale: GraphQLTypes["ProductIngredientCategoryLocaleConnection"],
	getDeliveryRoute?: GraphQLTypes["DeliveryRoute"] | undefined,
	listDeliveryRoute: Array<GraphQLTypes["DeliveryRoute"]>,
	paginateDeliveryRoute: GraphQLTypes["DeliveryRouteConnection"],
	getDeliveryWaypoint?: GraphQLTypes["DeliveryWaypoint"] | undefined,
	listDeliveryWaypoint: Array<GraphQLTypes["DeliveryWaypoint"]>,
	paginateDeliveryWaypoint: GraphQLTypes["DeliveryWaypointConnection"],
	getAllergen?: GraphQLTypes["Allergen"] | undefined,
	listAllergen: Array<GraphQLTypes["Allergen"]>,
	paginateAllergen: GraphQLTypes["AllergenConnection"],
	getAllergenLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	listAllergenLocale: Array<GraphQLTypes["AllergenLocale"]>,
	paginateAllergenLocale: GraphQLTypes["AllergenLocaleConnection"],
	getWeekHours?: GraphQLTypes["WeekHours"] | undefined,
	listWeekHours: Array<GraphQLTypes["WeekHours"]>,
	paginateWeekHours: GraphQLTypes["WeekHoursConnection"],
	getPreparedIngredientQuantity?: GraphQLTypes["PreparedIngredientQuantity"] | undefined,
	listPreparedIngredientQuantity: Array<GraphQLTypes["PreparedIngredientQuantity"]>,
	paginatePreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityConnection"],
	validateCreatePreparedIngredientQuantity: GraphQLTypes["_ValidationResult"],
	validateUpdatePreparedIngredientQuantity: GraphQLTypes["_ValidationResult"],
	getPreparedRecipeQuantity?: GraphQLTypes["PreparedRecipeQuantity"] | undefined,
	listPreparedRecipeQuantity: Array<GraphQLTypes["PreparedRecipeQuantity"]>,
	paginatePreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityConnection"],
	validateCreatePreparedRecipeQuantity: GraphQLTypes["_ValidationResult"],
	validateUpdatePreparedRecipeQuantity: GraphQLTypes["_ValidationResult"],
	getPreparedPackingQuantity?: GraphQLTypes["PreparedPackingQuantity"] | undefined,
	listPreparedPackingQuantity: Array<GraphQLTypes["PreparedPackingQuantity"]>,
	paginatePreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityConnection"],
	validateCreatePreparedPackingQuantity: GraphQLTypes["_ValidationResult"],
	validateUpdatePreparedPackingQuantity: GraphQLTypes["_ValidationResult"],
	getDeliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	listDeliveryZone: Array<GraphQLTypes["DeliveryZone"]>,
	paginateDeliveryZone: GraphQLTypes["DeliveryZoneConnection"],
	getDeliveryTimelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	listDeliveryTimelinePreset: Array<GraphQLTypes["DeliveryTimelinePreset"]>,
	paginateDeliveryTimelinePreset: GraphQLTypes["DeliveryTimelinePresetConnection"],
	getProductPackingLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	listProductPackingLocale: Array<GraphQLTypes["ProductPackingLocale"]>,
	paginateProductPackingLocale: GraphQLTypes["ProductPackingLocaleConnection"],
	getProductLocale?: GraphQLTypes["ProductLocale"] | undefined,
	listProductLocale: Array<GraphQLTypes["ProductLocale"]>,
	paginateProductLocale: GraphQLTypes["ProductLocaleConnection"],
	getProductRecipeLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	listProductRecipeLocale: Array<GraphQLTypes["ProductRecipeLocale"]>,
	paginateProductRecipeLocale: GraphQLTypes["ProductRecipeLocaleConnection"],
	getSuppliesPurchase?: GraphQLTypes["SuppliesPurchase"] | undefined,
	listSuppliesPurchase: Array<GraphQLTypes["SuppliesPurchase"]>,
	paginateSuppliesPurchase: GraphQLTypes["SuppliesPurchaseConnection"],
	validateCreateSuppliesPurchase: GraphQLTypes["_ValidationResult"],
	validateUpdateSuppliesPurchase: GraphQLTypes["_ValidationResult"],
	getLogRecord?: GraphQLTypes["LogRecord"] | undefined,
	listLogRecord: Array<GraphQLTypes["LogRecord"]>,
	paginateLogRecord: GraphQLTypes["LogRecordConnection"],
	getSuppliesPurchaseItem?: GraphQLTypes["SuppliesPurchaseItem"] | undefined,
	listSuppliesPurchaseItem: Array<GraphQLTypes["SuppliesPurchaseItem"]>,
	paginateSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemConnection"],
	validateCreateSuppliesPurchaseItem: GraphQLTypes["_ValidationResult"],
	validateUpdateSuppliesPurchaseItem: GraphQLTypes["_ValidationResult"],
	getVirtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	listVirtualProduct: Array<GraphQLTypes["VirtualProduct"]>,
	paginateVirtualProduct: GraphQLTypes["VirtualProductConnection"],
	getProductMetadata?: GraphQLTypes["ProductMetadata"] | undefined,
	listProductMetadata: Array<GraphQLTypes["ProductMetadata"]>,
	paginateProductMetadata: GraphQLTypes["ProductMetadataConnection"],
	getProductGridCategory?: GraphQLTypes["ProductGridCategory"] | undefined,
	listProductGridCategory: Array<GraphQLTypes["ProductGridCategory"]>,
	paginateProductGridCategory: GraphQLTypes["ProductGridCategoryConnection"],
	getUnassignedShiftInfo?: GraphQLTypes["UnassignedShiftInfo"] | undefined,
	listUnassignedShiftInfo: Array<GraphQLTypes["UnassignedShiftInfo"]>,
	paginateUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoConnection"],
	validateCreateUnassignedShiftInfo: GraphQLTypes["_ValidationResult"],
	validateUpdateUnassignedShiftInfo: GraphQLTypes["_ValidationResult"],
	getFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	listFreshingContainer: Array<GraphQLTypes["FreshingContainer"]>,
	paginateFreshingContainer: GraphQLTypes["FreshingContainerConnection"],
	getTextItem?: GraphQLTypes["TextItem"] | undefined,
	listTextItem: Array<GraphQLTypes["TextItem"]>,
	paginateTextItem: GraphQLTypes["TextItemConnection"],
	getTextList?: GraphQLTypes["TextList"] | undefined,
	listTextList: Array<GraphQLTypes["TextList"]>,
	paginateTextList: GraphQLTypes["TextListConnection"],
	getDeliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	listDeliveryRegions: Array<GraphQLTypes["DeliveryRegions"]>,
	paginateDeliveryRegions: GraphQLTypes["DeliveryRegionsConnection"],
	getDeliveryRegionsItem?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	listDeliveryRegionsItem: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	paginateDeliveryRegionsItem: GraphQLTypes["DeliveryRegionsItemConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getTimetablesEmployeePrivileges?: GraphQLTypes["TimetablesEmployeePrivileges"] | undefined,
	listTimetablesEmployeePrivileges: Array<GraphQLTypes["TimetablesEmployeePrivileges"]>,
	paginateTimetablesEmployeePrivileges: GraphQLTypes["TimetablesEmployeePrivilegesConnection"],
	getTimetableDayNote?: GraphQLTypes["TimetableDayNote"] | undefined,
	listTimetableDayNote: Array<GraphQLTypes["TimetableDayNote"]>,
	paginateTimetableDayNote: GraphQLTypes["TimetableDayNoteConnection"],
	getDeliveryMethodLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	listDeliveryMethodLocale: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	paginateDeliveryMethodLocale: GraphQLTypes["DeliveryMethodLocaleConnection"],
	getIngredientSupplier?: GraphQLTypes["IngredientSupplier"] | undefined,
	listIngredientSupplier: Array<GraphQLTypes["IngredientSupplier"]>,
	paginateIngredientSupplier: GraphQLTypes["IngredientSupplierConnection"],
	getIngredientSupplierItem?: GraphQLTypes["IngredientSupplierItem"] | undefined,
	listIngredientSupplierItem: Array<GraphQLTypes["IngredientSupplierItem"]>,
	paginateIngredientSupplierItem: GraphQLTypes["IngredientSupplierItemConnection"],
	getIngredientSupplierList?: GraphQLTypes["IngredientSupplierList"] | undefined,
	listIngredientSupplierList: Array<GraphQLTypes["IngredientSupplierList"]>,
	paginateIngredientSupplierList: GraphQLTypes["IngredientSupplierListConnection"],
	getIngredientSupplierLocale?: GraphQLTypes["IngredientSupplierLocale"] | undefined,
	listIngredientSupplierLocale: Array<GraphQLTypes["IngredientSupplierLocale"]>,
	paginateIngredientSupplierLocale: GraphQLTypes["IngredientSupplierLocaleConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getVenue?: GraphQLTypes["Venue"] | undefined,
	listVenue: Array<GraphQLTypes["Venue"]>,
	paginateVenue: GraphQLTypes["VenueConnection"],
	getWorkspace?: GraphQLTypes["Workspace"] | undefined,
	listWorkspace: Array<GraphQLTypes["Workspace"]>,
	paginateWorkspace: GraphQLTypes["WorkspaceConnection"],
	getVenueEmployeeRole?: GraphQLTypes["VenueEmployeeRole"] | undefined,
	listVenueEmployeeRole: Array<GraphQLTypes["VenueEmployeeRole"]>,
	paginateVenueEmployeeRole: GraphQLTypes["VenueEmployeeRoleConnection"],
	getIdentificationAttempt?: GraphQLTypes["IdentificationAttempt"] | undefined,
	listIdentificationAttempt: Array<GraphQLTypes["IdentificationAttempt"]>,
	paginateIdentificationAttempt: GraphQLTypes["IdentificationAttemptConnection"],
	getIdentificationCode?: GraphQLTypes["IdentificationCode"] | undefined,
	listIdentificationCode: Array<GraphQLTypes["IdentificationCode"]>,
	paginateIdentificationCode: GraphQLTypes["IdentificationCodeConnection"],
	getIdentificationCodeLocale?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	listIdentificationCodeLocale: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	paginateIdentificationCodeLocale: GraphQLTypes["IdentificationCodeLocaleConnection"],
	getDeviceIdentifier?: GraphQLTypes["DeviceIdentifier"] | undefined,
	listDeviceIdentifier: Array<GraphQLTypes["DeviceIdentifier"]>,
	paginateDeviceIdentifier: GraphQLTypes["DeviceIdentifierConnection"],
	validateCreateDeviceIdentifier: GraphQLTypes["_ValidationResult"],
	validateUpdateDeviceIdentifier: GraphQLTypes["_ValidationResult"],
	getDeviceIdentifierMetadata?: GraphQLTypes["DeviceIdentifierMetadata"] | undefined,
	listDeviceIdentifierMetadata: Array<GraphQLTypes["DeviceIdentifierMetadata"]>,
	paginateDeviceIdentifierMetadata: GraphQLTypes["DeviceIdentifierMetadataConnection"],
	getFulfillmentNote?: GraphQLTypes["FulfillmentNote"] | undefined,
	listFulfillmentNote: Array<GraphQLTypes["FulfillmentNote"]>,
	paginateFulfillmentNote: GraphQLTypes["FulfillmentNoteConnection"],
	getCustomerMetadata?: GraphQLTypes["CustomerMetadata"] | undefined,
	listCustomerMetadata: Array<GraphQLTypes["CustomerMetadata"]>,
	paginateCustomerMetadata: GraphQLTypes["CustomerMetadataConnection"],
	getSubscriptionBox?: GraphQLTypes["SubscriptionBox"] | undefined,
	listSubscriptionBox: Array<GraphQLTypes["SubscriptionBox"]>,
	paginateSubscriptionBox: GraphQLTypes["SubscriptionBoxConnection"],
	getSubscriptionBoxLocale?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	listSubscriptionBoxLocale: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	paginateSubscriptionBoxLocale: GraphQLTypes["SubscriptionBoxLocaleConnection"],
	getTimetableDayMetadata?: GraphQLTypes["TimetableDayMetadata"] | undefined,
	listTimetableDayMetadata: Array<GraphQLTypes["TimetableDayMetadata"]>,
	paginateTimetableDayMetadata: GraphQLTypes["TimetableDayMetadataConnection"],
	getGridTileItem?: GraphQLTypes["GridTileItem"] | undefined,
	listGridTileItem: Array<GraphQLTypes["GridTileItem"]>,
	paginateGridTileItem: GraphQLTypes["GridTileItemConnection"],
	getGridTiles?: GraphQLTypes["GridTiles"] | undefined,
	listGridTiles: Array<GraphQLTypes["GridTiles"]>,
	paginateGridTiles: GraphQLTypes["GridTilesConnection"],
	getPosition?: GraphQLTypes["Position"] | undefined,
	listPosition: Array<GraphQLTypes["Position"]>,
	paginatePosition: GraphQLTypes["PositionConnection"],
	getStatsWidget?: GraphQLTypes["StatsWidget"] | undefined,
	listStatsWidget: Array<GraphQLTypes["StatsWidget"]>,
	paginateStatsWidget: GraphQLTypes["StatsWidgetConnection"],
	getStatsWidgetsLayout?: GraphQLTypes["StatsWidgetsLayout"] | undefined,
	listStatsWidgetsLayout: Array<GraphQLTypes["StatsWidgetsLayout"]>,
	paginateStatsWidgetsLayout: GraphQLTypes["StatsWidgetsLayoutConnection"],
	getStatsPage?: GraphQLTypes["StatsPage"] | undefined,
	listStatsPage: Array<GraphQLTypes["StatsPage"]>,
	paginateStatsPage: GraphQLTypes["StatsPageConnection"],
	getUnassignReason?: GraphQLTypes["UnassignReason"] | undefined,
	listUnassignReason: Array<GraphQLTypes["UnassignReason"]>,
	paginateUnassignReason: GraphQLTypes["UnassignReasonConnection"],
	getOrderPaymentStatsByDay?: GraphQLTypes["OrderPaymentStatsByDay"] | undefined,
	listOrderPaymentStatsByDay: Array<GraphQLTypes["OrderPaymentStatsByDay"]>,
	paginateOrderPaymentStatsByDay: GraphQLTypes["OrderPaymentStatsByDayConnection"],
	transaction?: GraphQLTypes["QueryTransaction"] | undefined,
	_info?: GraphQLTypes["Info"] | undefined,
	schema?: GraphQLTypes["_Schema"] | undefined
};
	["Address"]: {
		_meta?: GraphQLTypes["AddressMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden: boolean,
	customer?: GraphQLTypes["Customer"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Country"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders: Array<GraphQLTypes["Order"]>,
	deliveryAddressOfOrderDeliveries: Array<GraphQLTypes["OrderDelivery"]>,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Customer"] | undefined,
	billingAddressOfOrdersBySeq?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDelivery?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByItems?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByPayments?: GraphQLTypes["Order"] | undefined,
	deliveryAddressOfOrderDeliveriesByTrackingCode?: GraphQLTypes["OrderDelivery"] | undefined,
	deliveryAddressOfOrderDeliveriesByOrder?: GraphQLTypes["OrderDelivery"] | undefined,
	deliveryAddressOfOrderDeliveriesByDeliveryWaypoint?: GraphQLTypes["OrderDelivery"] | undefined,
	paginateBillingAddressOfOrders: GraphQLTypes["OrderConnection"],
	paginateDeliveryAddressOfOrderDeliveries: GraphQLTypes["OrderDeliveryConnection"]
};
	["AddressMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	firstName?: GraphQLTypes["FieldMeta"] | undefined,
	lastName?: GraphQLTypes["FieldMeta"] | undefined,
	addressLine2?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	phone?: GraphQLTypes["FieldMeta"] | undefined,
	companyName?: GraphQLTypes["FieldMeta"] | undefined,
	companyIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	vatIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	externalIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	hidden?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	country?: GraphQLTypes["FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	googlePlaceId?: GraphQLTypes["FieldMeta"] | undefined,
	raw?: GraphQLTypes["FieldMeta"] | undefined,
	formatted?: GraphQLTypes["FieldMeta"] | undefined,
	geocodeGeneratedAt?: GraphQLTypes["FieldMeta"] | undefined,
	geocodeResponse?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZone?: GraphQLTypes["FieldMeta"] | undefined,
	geocodeValid?: GraphQLTypes["FieldMeta"] | undefined,
	fullName?: GraphQLTypes["FieldMeta"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["FieldMeta"] | undefined,
	addressLine1?: GraphQLTypes["FieldMeta"] | undefined,
	city?: GraphQLTypes["FieldMeta"] | undefined,
	postalCode?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	billingAddressOfOrders?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryAddressOfOrderDeliveries?: GraphQLTypes["FieldMeta"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["FieldMeta"] | undefined
};
	["FieldMeta"]: {
		readable?: boolean | undefined,
	updatable?: boolean | undefined
};
	["UUID"]:string;
	["Customer"]: {
		_meta?: GraphQLTypes["CustomerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	addresses: Array<GraphQLTypes["Address"]>,
	defaultBillingAddress?: GraphQLTypes["Address"] | undefined,
	tags: Array<GraphQLTypes["Tag"]>,
	orders: Array<GraphQLTypes["Order"]>,
	photo?: GraphQLTypes["Image"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["CustomerName"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["CustomerFamiliarity"] | undefined,
	channels: Array<GraphQLTypes["Channel"]>,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Address"] | undefined,
	prefersPackingsWithoutCardboard: boolean,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["CustomerMetadata"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	addressesByBillingAddressOfOrders?: GraphQLTypes["Address"] | undefined,
	addressesByDeliveryAddressOfOrderDeliveries?: GraphQLTypes["Address"] | undefined,
	addressesByDefaultDeliveryAddressOfCustomer?: GraphQLTypes["Address"] | undefined,
	ordersBySeq?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	ordersByDelivery?: GraphQLTypes["Order"] | undefined,
	ordersByItems?: GraphQLTypes["Order"] | undefined,
	ordersByPayments?: GraphQLTypes["Order"] | undefined,
	paginateAddresses: GraphQLTypes["AddressConnection"],
	paginateTags: GraphQLTypes["TagConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"],
	paginateChannels: GraphQLTypes["ChannelConnection"]
};
	["CustomerMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	addresses?: GraphQLTypes["FieldMeta"] | undefined,
	defaultBillingAddress?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	orders?: GraphQLTypes["FieldMeta"] | undefined,
	photo?: GraphQLTypes["FieldMeta"] | undefined,
	phone?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	welcomeMessage?: GraphQLTypes["FieldMeta"] | undefined,
	familiarity?: GraphQLTypes["FieldMeta"] | undefined,
	channels?: GraphQLTypes["FieldMeta"] | undefined,
	disabledAt?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["FieldMeta"] | undefined,
	prefersPackingsWithoutCardboard?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["FieldMeta"] | undefined
};
	["AddressWhere"]: GraphQLTypes["AddressWhere"];
	["UUIDCondition"]: GraphQLTypes["UUIDCondition"];
	["StringCondition"]: GraphQLTypes["StringCondition"];
	["BooleanCondition"]: GraphQLTypes["BooleanCondition"];
	["CustomerWhere"]: GraphQLTypes["CustomerWhere"];
	["TagWhere"]: GraphQLTypes["TagWhere"];
	["ProductWhere"]: GraphQLTypes["ProductWhere"];
	["IntCondition"]: GraphQLTypes["IntCondition"];
	["ProductPackingWhere"]: GraphQLTypes["ProductPackingWhere"];
	["ImageWhere"]: GraphQLTypes["ImageWhere"];
	["ProductPackingLocaleWhere"]: GraphQLTypes["ProductPackingLocaleWhere"];
	["LocaleWhere"]: GraphQLTypes["LocaleWhere"];
	["BusinessCategoryLocaleWhere"]: GraphQLTypes["BusinessCategoryLocaleWhere"];
	["BusinessCategoryWhere"]: GraphQLTypes["BusinessCategoryWhere"];
	["OrderRecurrenceWhere"]: GraphQLTypes["OrderRecurrenceWhere"];
	["OrderRecurrenceLocaleWhere"]: GraphQLTypes["OrderRecurrenceLocaleWhere"];
	["ProductListWhere"]: GraphQLTypes["ProductListWhere"];
	["ProductListItemWhere"]: GraphQLTypes["ProductListItemWhere"];
	["ProductCategoryListWhere"]: GraphQLTypes["ProductCategoryListWhere"];
	["ProductCategoryListItemWhere"]: GraphQLTypes["ProductCategoryListItemWhere"];
	["ProductCategoryWhere"]: GraphQLTypes["ProductCategoryWhere"];
	["FloatCondition"]: GraphQLTypes["FloatCondition"];
	["ProductCategoryListLocaleWhere"]: GraphQLTypes["ProductCategoryListLocaleWhere"];
	["ProductIngredientLocaleWhere"]: GraphQLTypes["ProductIngredientLocaleWhere"];
	["ProductIngredientWhere"]: GraphQLTypes["ProductIngredientWhere"];
	["ProductIngredientCategoryWhere"]: GraphQLTypes["ProductIngredientCategoryWhere"];
	["ProductIngredientCategoryLocaleWhere"]: GraphQLTypes["ProductIngredientCategoryLocaleWhere"];
	["AllergenWhere"]: GraphQLTypes["AllergenWhere"];
	["AllergenLocaleWhere"]: GraphQLTypes["AllergenLocaleWhere"];
	["ProductIngredientItemQuantityUnitWhere"]: GraphQLTypes["ProductIngredientItemQuantityUnitWhere"];
	["FreshingContainerWhere"]: GraphQLTypes["FreshingContainerWhere"];
	["WorkspaceWhere"]: GraphQLTypes["WorkspaceWhere"];
	["OneCondition"]: GraphQLTypes["OneCondition"];
	["One"]: GraphQLTypes["One"];
	["WeekHoursWhere"]: GraphQLTypes["WeekHoursWhere"];
	["VirtualProductWhere"]: GraphQLTypes["VirtualProductWhere"];
	["VirtualProductTypeCondition"]: GraphQLTypes["VirtualProductTypeCondition"];
	["VirtualProductType"]: GraphQLTypes["VirtualProductType"];
	["VirtualProductPhysicalRepresentationTypeCondition"]: GraphQLTypes["VirtualProductPhysicalRepresentationTypeCondition"];
	["VirtualProductPhysicalRepresentationType"]: GraphQLTypes["VirtualProductPhysicalRepresentationType"];
	["VatRateWhere"]: GraphQLTypes["VatRateWhere"];
	["VatRatePresetCondition"]: GraphQLTypes["VatRatePresetCondition"];
	["VatRatePreset"]: GraphQLTypes["VatRatePreset"];
	["IngredientSupplierListWhere"]: GraphQLTypes["IngredientSupplierListWhere"];
	["IngredientSupplierItemWhere"]: GraphQLTypes["IngredientSupplierItemWhere"];
	["IngredientSupplierWhere"]: GraphQLTypes["IngredientSupplierWhere"];
	["IngredientSupplierLocaleWhere"]: GraphQLTypes["IngredientSupplierLocaleWhere"];
	["BlogLocaleWhere"]: GraphQLTypes["BlogLocaleWhere"];
	["BlogWhere"]: GraphQLTypes["BlogWhere"];
	["BlogPostLocaleWhere"]: GraphQLTypes["BlogPostLocaleWhere"];
	["BlogPostWhere"]: GraphQLTypes["BlogPostWhere"];
	["CoverWhere"]: GraphQLTypes["CoverWhere"];
	["MediumWhere"]: GraphQLTypes["MediumWhere"];
	["MediumTypeCondition"]: GraphQLTypes["MediumTypeCondition"];
	["MediumType"]: GraphQLTypes["MediumType"];
	["ColorThemeCondition"]: GraphQLTypes["ColorThemeCondition"];
	["ColorTheme"]: GraphQLTypes["ColorTheme"];
	["VideoWhere"]: GraphQLTypes["VideoWhere"];
	["ColorPalleteWhere"]: GraphQLTypes["ColorPalleteWhere"];
	["ContentWhere"]: GraphQLTypes["ContentWhere"];
	["ContentBlockWhere"]: GraphQLTypes["ContentBlockWhere"];
	["ContentReferenceWhere"]: GraphQLTypes["ContentReferenceWhere"];
	["ContentReferenceTypeCondition"]: GraphQLTypes["ContentReferenceTypeCondition"];
	["ContentReferenceType"]: GraphQLTypes["ContentReferenceType"];
	["LinkWhere"]: GraphQLTypes["LinkWhere"];
	["LinkTypeCondition"]: GraphQLTypes["LinkTypeCondition"];
	["LinkType"]: GraphQLTypes["LinkType"];
	["LinkableWhere"]: GraphQLTypes["LinkableWhere"];
	["PageLocaleWhere"]: GraphQLTypes["PageLocaleWhere"];
	["PageWhere"]: GraphQLTypes["PageWhere"];
	["SeoWhere"]: GraphQLTypes["SeoWhere"];
	["PageThemeCondition"]: GraphQLTypes["PageThemeCondition"];
	["PageTheme"]: GraphQLTypes["PageTheme"];
	["RedirectWhere"]: GraphQLTypes["RedirectWhere"];
	["BlogPostListWhere"]: GraphQLTypes["BlogPostListWhere"];
	["BlogPostListItemWhere"]: GraphQLTypes["BlogPostListItemWhere"];
	["LinkListWhere"]: GraphQLTypes["LinkListWhere"];
	["LinkListItemWhere"]: GraphQLTypes["LinkListItemWhere"];
	["ContentSizeCondition"]: GraphQLTypes["ContentSizeCondition"];
	["ContentSize"]: GraphQLTypes["ContentSize"];
	["HeroWhere"]: GraphQLTypes["HeroWhere"];
	["GalleryWhere"]: GraphQLTypes["GalleryWhere"];
	["GalleryItemWhere"]: GraphQLTypes["GalleryItemWhere"];
	["SocialsAndAppsWhere"]: GraphQLTypes["SocialsAndAppsWhere"];
	["ProductGridWhere"]: GraphQLTypes["ProductGridWhere"];
	["ProductGridCategoryWhere"]: GraphQLTypes["ProductGridCategoryWhere"];
	["ProductGridItemWhere"]: GraphQLTypes["ProductGridItemWhere"];
	["ImageListWhere"]: GraphQLTypes["ImageListWhere"];
	["ImageListItemWhere"]: GraphQLTypes["ImageListItemWhere"];
	["ProductGridTypeCondition"]: GraphQLTypes["ProductGridTypeCondition"];
	["ProductGridType"]: GraphQLTypes["ProductGridType"];
	["ProductBannerListWhere"]: GraphQLTypes["ProductBannerListWhere"];
	["ProductBannerItemWhere"]: GraphQLTypes["ProductBannerItemWhere"];
	["ProductBannerWhere"]: GraphQLTypes["ProductBannerWhere"];
	["ProductBannerItemSizeCondition"]: GraphQLTypes["ProductBannerItemSizeCondition"];
	["ProductBannerItemSize"]: GraphQLTypes["ProductBannerItemSize"];
	["DeliveryRegionsWhere"]: GraphQLTypes["DeliveryRegionsWhere"];
	["DeliveryRegionsItemWhere"]: GraphQLTypes["DeliveryRegionsItemWhere"];
	["TextListWhere"]: GraphQLTypes["TextListWhere"];
	["TextItemWhere"]: GraphQLTypes["TextItemWhere"];
	["GridTilesWhere"]: GraphQLTypes["GridTilesWhere"];
	["GridTileItemWhere"]: GraphQLTypes["GridTileItemWhere"];
	["TranslationCataloguesIdentifierWhere"]: GraphQLTypes["TranslationCataloguesIdentifierWhere"];
	["TranslationCatalogueWhere"]: GraphQLTypes["TranslationCatalogueWhere"];
	["TranslationDomainWhere"]: GraphQLTypes["TranslationDomainWhere"];
	["TranslationKeyWhere"]: GraphQLTypes["TranslationKeyWhere"];
	["TranslationContentTypeCondition"]: GraphQLTypes["TranslationContentTypeCondition"];
	["TranslationContentType"]: GraphQLTypes["TranslationContentType"];
	["TranslationValueWhere"]: GraphQLTypes["TranslationValueWhere"];
	["ProductLocaleWhere"]: GraphQLTypes["ProductLocaleWhere"];
	["ProductRecipeLocaleWhere"]: GraphQLTypes["ProductRecipeLocaleWhere"];
	["ProductRecipeWhere"]: GraphQLTypes["ProductRecipeWhere"];
	["ProductIngredientItemWhere"]: GraphQLTypes["ProductIngredientItemWhere"];
	["ProductIngredientItemQuantityWhere"]: GraphQLTypes["ProductIngredientItemQuantityWhere"];
	["DeliveryMethodLocaleWhere"]: GraphQLTypes["DeliveryMethodLocaleWhere"];
	["DeliveryMethodWhere"]: GraphQLTypes["DeliveryMethodWhere"];
	["PaymentMethodWhere"]: GraphQLTypes["PaymentMethodWhere"];
	["PaymentMethodTypeCondition"]: GraphQLTypes["PaymentMethodTypeCondition"];
	["PaymentMethodType"]: GraphQLTypes["PaymentMethodType"];
	["DeliveryZoneWhere"]: GraphQLTypes["DeliveryZoneWhere"];
	["DeliveryZoneTypeCondition"]: GraphQLTypes["DeliveryZoneTypeCondition"];
	["DeliveryZoneType"]: GraphQLTypes["DeliveryZoneType"];
	["DeliveryTimelinePresetWhere"]: GraphQLTypes["DeliveryTimelinePresetWhere"];
	["DeliveryTimelineTypeCondition"]: GraphQLTypes["DeliveryTimelineTypeCondition"];
	["DeliveryTimelineType"]: GraphQLTypes["DeliveryTimelineType"];
	["IdentificationCodeLocaleWhere"]: GraphQLTypes["IdentificationCodeLocaleWhere"];
	["IdentificationCodeWhere"]: GraphQLTypes["IdentificationCodeWhere"];
	["DateTimeCondition"]: GraphQLTypes["DateTimeCondition"];
	["DateTime"]:string;
	["IdentificationAttemptWhere"]: GraphQLTypes["IdentificationAttemptWhere"];
	["SubscriptionBoxLocaleWhere"]: GraphQLTypes["SubscriptionBoxLocaleWhere"];
	["SubscriptionBoxWhere"]: GraphQLTypes["SubscriptionBoxWhere"];
	["PreparedPackingQuantityWhere"]: GraphQLTypes["PreparedPackingQuantityWhere"];
	["DateCondition"]: GraphQLTypes["DateCondition"];
	["Date"]:any;
	["ProductStockWhere"]: GraphQLTypes["ProductStockWhere"];
	["StoreWhere"]: GraphQLTypes["StoreWhere"];
	["ProductAvailabilityCondition"]: GraphQLTypes["ProductAvailabilityCondition"];
	["ProductAvailability"]: GraphQLTypes["ProductAvailability"];
	["OrderItemWhere"]: GraphQLTypes["OrderItemWhere"];
	["OrderWhere"]: GraphQLTypes["OrderWhere"];
	["OrderStateCondition"]: GraphQLTypes["OrderStateCondition"];
	["OrderState"]: GraphQLTypes["OrderState"];
	["ChannelWhere"]: GraphQLTypes["ChannelWhere"];
	["ChannelCodeCondition"]: GraphQLTypes["ChannelCodeCondition"];
	["ChannelCode"]: GraphQLTypes["ChannelCode"];
	["VenueWhere"]: GraphQLTypes["VenueWhere"];
	["DeadlineTemplateWhere"]: GraphQLTypes["DeadlineTemplateWhere"];
	["TimetableDayWhere"]: GraphQLTypes["TimetableDayWhere"];
	["TimetableDayTypeCondition"]: GraphQLTypes["TimetableDayTypeCondition"];
	["TimetableDayType"]: GraphQLTypes["TimetableDayType"];
	["TimetableShiftGroupWhere"]: GraphQLTypes["TimetableShiftGroupWhere"];
	["TimetableShiftWhere"]: GraphQLTypes["TimetableShiftWhere"];
	["ShiftsProfileWhere"]: GraphQLTypes["ShiftsProfileWhere"];
	["EmployeeQueueItemWhere"]: GraphQLTypes["EmployeeQueueItemWhere"];
	["EmployeeQueueWhere"]: GraphQLTypes["EmployeeQueueWhere"];
	["UnassignedShiftInfoWhere"]: GraphQLTypes["UnassignedShiftInfoWhere"];
	["UnassignedInfoReasonCondition"]: GraphQLTypes["UnassignedInfoReasonCondition"];
	["UnassignedInfoReason"]: GraphQLTypes["UnassignedInfoReason"];
	["UnassignReasonWhere"]: GraphQLTypes["UnassignReasonWhere"];
	["StaffWhere"]: GraphQLTypes["StaffWhere"];
	["TimetableEmployeeRoleWhere"]: GraphQLTypes["TimetableEmployeeRoleWhere"];
	["TimetableTemplateWhere"]: GraphQLTypes["TimetableTemplateWhere"];
	["TimetableShiftPositionWhere"]: GraphQLTypes["TimetableShiftPositionWhere"];
	["TemplateTypeCondition"]: GraphQLTypes["TemplateTypeCondition"];
	["TemplateType"]: GraphQLTypes["TemplateType"];
	["TimetableDayNoteWhere"]: GraphQLTypes["TimetableDayNoteWhere"];
	["TimetableDayMetadataWhere"]: GraphQLTypes["TimetableDayMetadataWhere"];
	["VenueEmployeeRoleWhere"]: GraphQLTypes["VenueEmployeeRoleWhere"];
	["TimetablesEmployeePrivilegesWhere"]: GraphQLTypes["TimetablesEmployeePrivilegesWhere"];
	["FulfillmentNoteWhere"]: GraphQLTypes["FulfillmentNoteWhere"];
	["PointOfSaleInitialViewCondition"]: GraphQLTypes["PointOfSaleInitialViewCondition"];
	["PointOfSaleInitialView"]: GraphQLTypes["PointOfSaleInitialView"];
	["OrderDeliveryWhere"]: GraphQLTypes["OrderDeliveryWhere"];
	["OrderDeliveryStateCondition"]: GraphQLTypes["OrderDeliveryStateCondition"];
	["OrderDeliveryState"]: GraphQLTypes["OrderDeliveryState"];
	["DeliveryWaypointWhere"]: GraphQLTypes["DeliveryWaypointWhere"];
	["DeliveryRouteWhere"]: GraphQLTypes["DeliveryRouteWhere"];
	["OrderPaymentWhere"]: GraphQLTypes["OrderPaymentWhere"];
	["JsonCondition"]: GraphQLTypes["JsonCondition"];
	["OrderPaymentStateCondition"]: GraphQLTypes["OrderPaymentStateCondition"];
	["OrderPaymentState"]: GraphQLTypes["OrderPaymentState"];
	["ProductMetadataWhere"]: GraphQLTypes["ProductMetadataWhere"];
	["CustomerNameWhere"]: GraphQLTypes["CustomerNameWhere"];
	["CustomerFamiliarityCondition"]: GraphQLTypes["CustomerFamiliarityCondition"];
	["CustomerFamiliarity"]: GraphQLTypes["CustomerFamiliarity"];
	["CustomerMetadataWhere"]: GraphQLTypes["CustomerMetadataWhere"];
	["CountryWhere"]: GraphQLTypes["CountryWhere"];
	["AddressOrderBy"]: GraphQLTypes["AddressOrderBy"];
	["OrderDirection"]: GraphQLTypes["OrderDirection"];
	["CustomerOrderBy"]: GraphQLTypes["CustomerOrderBy"];
	["ImageOrderBy"]: GraphQLTypes["ImageOrderBy"];
	["CustomerNameOrderBy"]: GraphQLTypes["CustomerNameOrderBy"];
	["CustomerMetadataOrderBy"]: GraphQLTypes["CustomerMetadataOrderBy"];
	["CountryOrderBy"]: GraphQLTypes["CountryOrderBy"];
	["DeliveryZoneOrderBy"]: GraphQLTypes["DeliveryZoneOrderBy"];
	["Tag"]: {
		_meta?: GraphQLTypes["TagMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	customers: Array<GraphQLTypes["Customer"]>,
	products: Array<GraphQLTypes["Product"]>,
	categories: Array<GraphQLTypes["ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Channel"]>,
	deliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	publicInChannels: Array<GraphQLTypes["Channel"]>,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	paginateCustomers: GraphQLTypes["CustomerConnection"],
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateCategories: GraphQLTypes["ProductCategoryConnection"],
	paginateAddTagsOnRegistration: GraphQLTypes["ChannelConnection"],
	paginateDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginatePublicInChannels: GraphQLTypes["ChannelConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["TagMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	customers?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	publicInChannels?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined
};
	["Product"]: {
		_meta?: GraphQLTypes["ProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	priceCents: number,
	notDiscountable: boolean,
	packing?: GraphQLTypes["ProductPacking"] | undefined,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	tags: Array<GraphQLTypes["Tag"]>,
	categories: Array<GraphQLTypes["ProductCategory"]>,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	stocks: Array<GraphQLTypes["ProductStock"]>,
	image?: GraphQLTypes["Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	availability: GraphQLTypes["ProductAvailability"],
	order: number,
	orderItems: Array<GraphQLTypes["OrderItem"]>,
	maximumQuantityPerOrder?: number | undefined,
	deliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	locales: Array<GraphQLTypes["ProductLocale"]>,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	virtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	meta?: GraphQLTypes["ProductMetadata"] | undefined,
	page?: GraphQLTypes["Page"] | undefined,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	hasSubscription: boolean,
	pointOfSaleTileColor?: string | undefined,
	stocksByStore?: GraphQLTypes["ProductStock"] | undefined,
	localesByLocale?: GraphQLTypes["ProductLocale"] | undefined,
	localesByFeatureList?: GraphQLTypes["ProductLocale"] | undefined,
	paginateTags: GraphQLTypes["TagConnection"],
	paginateCategories: GraphQLTypes["ProductCategoryConnection"],
	paginateStocks: GraphQLTypes["ProductStockConnection"],
	paginateOrderItems: GraphQLTypes["OrderItemConnection"],
	paginateDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginateLocales: GraphQLTypes["ProductLocaleConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["ProductMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	notDiscountable?: GraphQLTypes["FieldMeta"] | undefined,
	packing?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	stocks?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	availability?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	orderItems?: GraphQLTypes["FieldMeta"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	archivedAt?: GraphQLTypes["FieldMeta"] | undefined,
	virtualProduct?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	page?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined,
	hasSubscription?: GraphQLTypes["FieldMeta"] | undefined,
	pointOfSaleTileColor?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductPacking"]: {
		_meta?: GraphQLTypes["ProductPackingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	image?: GraphQLTypes["Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	volumeMl?: number | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	shortName?: string | undefined,
	locales: Array<GraphQLTypes["ProductPackingLocale"]>,
	preparedPackingQuantities: Array<GraphQLTypes["PreparedPackingQuantity"]>,
	isPackedInCardboard: boolean,
	localesByLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	paginateLocales: GraphQLTypes["ProductPackingLocaleConnection"],
	paginatePreparedPackingQuantities: GraphQLTypes["PreparedPackingQuantityConnection"]
};
	["ProductPackingMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	shortName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	preparedPackingQuantities?: GraphQLTypes["FieldMeta"] | undefined,
	isPackedInCardboard?: GraphQLTypes["FieldMeta"] | undefined
};
	["Image"]: {
		_meta?: GraphQLTypes["ImageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined
};
	["ImageMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	alt?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductPackingLocale"]: {
		_meta?: GraphQLTypes["ProductPackingLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	shortName?: string | undefined,
	description?: string | undefined,
	root?: GraphQLTypes["ProductPacking"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductPackingLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	shortName?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["Locale"]: {
		_meta?: GraphQLTypes["LocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	businessCategories: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	productCategoryLists: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	productIngredients: Array<GraphQLTypes["ProductIngredientLocale"]>,
	orderRecurrences: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	blogs: Array<GraphQLTypes["BlogLocale"]>,
	blogPosts: Array<GraphQLTypes["BlogPostLocale"]>,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	pages: Array<GraphQLTypes["PageLocale"]>,
	productIngredientCategories: Array<GraphQLTypes["ProductIngredientCategoryLocale"]>,
	allergens: Array<GraphQLTypes["AllergenLocale"]>,
	productPackings: Array<GraphQLTypes["ProductPackingLocale"]>,
	products: Array<GraphQLTypes["ProductLocale"]>,
	productRecipes: Array<GraphQLTypes["ProductRecipeLocale"]>,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	ingredientSuppliers: Array<GraphQLTypes["IngredientSupplierLocale"]>,
	identificationCodes: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	subscriptionBoxes: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	businessCategoriesByRoot?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	productCategoryListsByRoot?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	productIngredientsByRoot?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	orderRecurrencesByRoot?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	blogsByRoot?: GraphQLTypes["BlogLocale"] | undefined,
	blogPostsByRoot?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByCover?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByContent?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByLink?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsBySeo?: GraphQLTypes["BlogPostLocale"] | undefined,
	pagesByRoot?: GraphQLTypes["PageLocale"] | undefined,
	pagesByLink?: GraphQLTypes["PageLocale"] | undefined,
	pagesBySeo?: GraphQLTypes["PageLocale"] | undefined,
	pagesByContent?: GraphQLTypes["PageLocale"] | undefined,
	pagesByCover?: GraphQLTypes["PageLocale"] | undefined,
	productIngredientCategoriesByRoot?: GraphQLTypes["ProductIngredientCategoryLocale"] | undefined,
	allergensByRoot?: GraphQLTypes["AllergenLocale"] | undefined,
	productPackingsByRoot?: GraphQLTypes["ProductPackingLocale"] | undefined,
	productsByRoot?: GraphQLTypes["ProductLocale"] | undefined,
	productsByFeatureList?: GraphQLTypes["ProductLocale"] | undefined,
	productRecipesByRoot?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	deliveryMethodsByRoot?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	ingredientSuppliersByRoot?: GraphQLTypes["IngredientSupplierLocale"] | undefined,
	identificationCodesByRoot?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	subscriptionBoxesByRoot?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	subscriptionBoxesByTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	subscriptionBoxesBySecondaryTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	paginateBusinessCategories: GraphQLTypes["BusinessCategoryLocaleConnection"],
	paginateProductCategoryLists: GraphQLTypes["ProductCategoryListLocaleConnection"],
	paginateProductIngredients: GraphQLTypes["ProductIngredientLocaleConnection"],
	paginateOrderRecurrences: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	paginateBlogs: GraphQLTypes["BlogLocaleConnection"],
	paginateBlogPosts: GraphQLTypes["BlogPostLocaleConnection"],
	paginatePages: GraphQLTypes["PageLocaleConnection"],
	paginateProductIngredientCategories: GraphQLTypes["ProductIngredientCategoryLocaleConnection"],
	paginateAllergens: GraphQLTypes["AllergenLocaleConnection"],
	paginateProductPackings: GraphQLTypes["ProductPackingLocaleConnection"],
	paginateProducts: GraphQLTypes["ProductLocaleConnection"],
	paginateProductRecipes: GraphQLTypes["ProductRecipeLocaleConnection"],
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodLocaleConnection"],
	paginateIngredientSuppliers: GraphQLTypes["IngredientSupplierLocaleConnection"],
	paginateIdentificationCodes: GraphQLTypes["IdentificationCodeLocaleConnection"],
	paginateSubscriptionBoxes: GraphQLTypes["SubscriptionBoxLocaleConnection"]
};
	["LocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategories?: GraphQLTypes["FieldMeta"] | undefined,
	productCategoryLists?: GraphQLTypes["FieldMeta"] | undefined,
	productIngredients?: GraphQLTypes["FieldMeta"] | undefined,
	orderRecurrences?: GraphQLTypes["FieldMeta"] | undefined,
	blogs?: GraphQLTypes["FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	pages?: GraphQLTypes["FieldMeta"] | undefined,
	productIngredientCategories?: GraphQLTypes["FieldMeta"] | undefined,
	allergens?: GraphQLTypes["FieldMeta"] | undefined,
	productPackings?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	productRecipes?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	ingredientSuppliers?: GraphQLTypes["FieldMeta"] | undefined,
	identificationCodes?: GraphQLTypes["FieldMeta"] | undefined,
	subscriptionBoxes?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategoryLocale"]: {
		_meta?: GraphQLTypes["BusinessCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["BusinessCategory"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	title?: string | undefined
};
	["BusinessCategoryLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategory"]: {
		_meta?: GraphQLTypes["BusinessCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	visibleToNewCustomers: boolean,
	locales: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	internalName: string,
	recurrences: Array<GraphQLTypes["OrderRecurrence"]>,
	specificProducts?: GraphQLTypes["ProductList"] | undefined,
	seasonalProductCategories?: GraphQLTypes["ProductCategoryList"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["ProductCategoryList"] | undefined,
	slug: string,
	localesByLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	paginateLocales: GraphQLTypes["BusinessCategoryLocaleConnection"],
	paginateRecurrences: GraphQLTypes["OrderRecurrenceConnection"]
};
	["BusinessCategoryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	recurrences?: GraphQLTypes["FieldMeta"] | undefined,
	specificProducts?: GraphQLTypes["FieldMeta"] | undefined,
	seasonalProductCategories?: GraphQLTypes["FieldMeta"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["FieldMeta"] | undefined,
	slug?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategoryLocaleOrderBy"]: GraphQLTypes["BusinessCategoryLocaleOrderBy"];
	["BusinessCategoryOrderBy"]: GraphQLTypes["BusinessCategoryOrderBy"];
	["ProductListOrderBy"]: GraphQLTypes["ProductListOrderBy"];
	["ProductCategoryListOrderBy"]: GraphQLTypes["ProductCategoryListOrderBy"];
	["LocaleOrderBy"]: GraphQLTypes["LocaleOrderBy"];
	["TranslationCataloguesIdentifierOrderBy"]: GraphQLTypes["TranslationCataloguesIdentifierOrderBy"];
	["OrderRecurrence"]: {
		_meta?: GraphQLTypes["OrderRecurrenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	order: number,
	businessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	localesByLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	paginateLocales: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"]
};
	["OrderRecurrenceMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderRecurrenceLocale"]: {
		_meta?: GraphQLTypes["OrderRecurrenceLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["OrderRecurrence"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["OrderRecurrenceLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderRecurrenceLocaleOrderBy"]: GraphQLTypes["OrderRecurrenceLocaleOrderBy"];
	["OrderRecurrenceOrderBy"]: GraphQLTypes["OrderRecurrenceOrderBy"];
	["OrderRecurrenceLocalesByLocaleUniqueWhere"]: GraphQLTypes["OrderRecurrenceLocalesByLocaleUniqueWhere"];
	["LocaleUniqueWhere"]: GraphQLTypes["LocaleUniqueWhere"];
	["BusinessCategoryLocaleUniqueWhere"]: GraphQLTypes["BusinessCategoryLocaleUniqueWhere"];
	["BusinessCategoryUniqueWhere"]: GraphQLTypes["BusinessCategoryUniqueWhere"];
	["ProductListUniqueWhere"]: GraphQLTypes["ProductListUniqueWhere"];
	["ProductListItemUniqueWhere"]: GraphQLTypes["ProductListItemUniqueWhere"];
	["ProductCategoryListUniqueWhere"]: GraphQLTypes["ProductCategoryListUniqueWhere"];
	["ProductCategoryListItemUniqueWhere"]: GraphQLTypes["ProductCategoryListItemUniqueWhere"];
	["ProductCategoryListLocaleUniqueWhere"]: GraphQLTypes["ProductCategoryListLocaleUniqueWhere"];
	["ProductIngredientLocaleUniqueWhere"]: GraphQLTypes["ProductIngredientLocaleUniqueWhere"];
	["ProductIngredientUniqueWhere"]: GraphQLTypes["ProductIngredientUniqueWhere"];
	["IngredientSupplierListUniqueWhere"]: GraphQLTypes["IngredientSupplierListUniqueWhere"];
	["IngredientSupplierItemUniqueWhere"]: GraphQLTypes["IngredientSupplierItemUniqueWhere"];
	["OrderRecurrenceLocaleUniqueWhere"]: GraphQLTypes["OrderRecurrenceLocaleUniqueWhere"];
	["OrderRecurrenceUniqueWhere"]: GraphQLTypes["OrderRecurrenceUniqueWhere"];
	["BlogLocaleUniqueWhere"]: GraphQLTypes["BlogLocaleUniqueWhere"];
	["BlogUniqueWhere"]: GraphQLTypes["BlogUniqueWhere"];
	["BlogPostLocaleUniqueWhere"]: GraphQLTypes["BlogPostLocaleUniqueWhere"];
	["BlogPostUniqueWhere"]: GraphQLTypes["BlogPostUniqueWhere"];
	["CoverUniqueWhere"]: GraphQLTypes["CoverUniqueWhere"];
	["ContentUniqueWhere"]: GraphQLTypes["ContentUniqueWhere"];
	["ContentBlockUniqueWhere"]: GraphQLTypes["ContentBlockUniqueWhere"];
	["ContentReferenceUniqueWhere"]: GraphQLTypes["ContentReferenceUniqueWhere"];
	["ProductBannerListUniqueWhere"]: GraphQLTypes["ProductBannerListUniqueWhere"];
	["ProductBannerItemUniqueWhere"]: GraphQLTypes["ProductBannerItemUniqueWhere"];
	["DeliveryRegionsUniqueWhere"]: GraphQLTypes["DeliveryRegionsUniqueWhere"];
	["DeliveryRegionsItemUniqueWhere"]: GraphQLTypes["DeliveryRegionsItemUniqueWhere"];
	["ImageUniqueWhere"]: GraphQLTypes["ImageUniqueWhere"];
	["TextListUniqueWhere"]: GraphQLTypes["TextListUniqueWhere"];
	["TextItemUniqueWhere"]: GraphQLTypes["TextItemUniqueWhere"];
	["ImageListUniqueWhere"]: GraphQLTypes["ImageListUniqueWhere"];
	["ImageListItemUniqueWhere"]: GraphQLTypes["ImageListItemUniqueWhere"];
	["LinkableUniqueWhere"]: GraphQLTypes["LinkableUniqueWhere"];
	["PageLocaleUniqueWhere"]: GraphQLTypes["PageLocaleUniqueWhere"];
	["PageUniqueWhere"]: GraphQLTypes["PageUniqueWhere"];
	["SeoUniqueWhere"]: GraphQLTypes["SeoUniqueWhere"];
	["RedirectUniqueWhere"]: GraphQLTypes["RedirectUniqueWhere"];
	["TranslationCataloguesIdentifierUniqueWhere"]: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"];
	["TranslationCatalogueUniqueWhere"]: GraphQLTypes["TranslationCatalogueUniqueWhere"];
	["TranslationDomainUniqueWhere"]: GraphQLTypes["TranslationDomainUniqueWhere"];
	["TranslationKeyUniqueWhere"]: GraphQLTypes["TranslationKeyUniqueWhere"];
	["TranslationValueUniqueWhere"]: GraphQLTypes["TranslationValueUniqueWhere"];
	["ProductIngredientCategoryLocaleUniqueWhere"]: GraphQLTypes["ProductIngredientCategoryLocaleUniqueWhere"];
	["ProductIngredientCategoryUniqueWhere"]: GraphQLTypes["ProductIngredientCategoryUniqueWhere"];
	["AllergenLocaleUniqueWhere"]: GraphQLTypes["AllergenLocaleUniqueWhere"];
	["AllergenUniqueWhere"]: GraphQLTypes["AllergenUniqueWhere"];
	["ProductPackingLocaleUniqueWhere"]: GraphQLTypes["ProductPackingLocaleUniqueWhere"];
	["ProductPackingUniqueWhere"]: GraphQLTypes["ProductPackingUniqueWhere"];
	["PreparedPackingQuantityUniqueWhere"]: GraphQLTypes["PreparedPackingQuantityUniqueWhere"];
	["ProductRecipeUniqueWhere"]: GraphQLTypes["ProductRecipeUniqueWhere"];
	["ProductUniqueWhere"]: GraphQLTypes["ProductUniqueWhere"];
	["ProductStockUniqueWhere"]: GraphQLTypes["ProductStockUniqueWhere"];
	["StoreUniqueWhere"]: GraphQLTypes["StoreUniqueWhere"];
	["OrderItemUniqueWhere"]: GraphQLTypes["OrderItemUniqueWhere"];
	["ProductLocaleUniqueWhere"]: GraphQLTypes["ProductLocaleUniqueWhere"];
	["VirtualProductUniqueWhere"]: GraphQLTypes["VirtualProductUniqueWhere"];
	["ProductMetadataUniqueWhere"]: GraphQLTypes["ProductMetadataUniqueWhere"];
	["ProductIngredientItemUniqueWhere"]: GraphQLTypes["ProductIngredientItemUniqueWhere"];
	["ProductIngredientItemQuantityUniqueWhere"]: GraphQLTypes["ProductIngredientItemQuantityUniqueWhere"];
	["ProductRecipeLocaleUniqueWhere"]: GraphQLTypes["ProductRecipeLocaleUniqueWhere"];
	["DeliveryMethodLocaleUniqueWhere"]: GraphQLTypes["DeliveryMethodLocaleUniqueWhere"];
	["DeliveryMethodUniqueWhere"]: GraphQLTypes["DeliveryMethodUniqueWhere"];
	["DeliveryTimelinePresetUniqueWhere"]: GraphQLTypes["DeliveryTimelinePresetUniqueWhere"];
	["IngredientSupplierLocaleUniqueWhere"]: GraphQLTypes["IngredientSupplierLocaleUniqueWhere"];
	["IngredientSupplierUniqueWhere"]: GraphQLTypes["IngredientSupplierUniqueWhere"];
	["IdentificationCodeLocaleUniqueWhere"]: GraphQLTypes["IdentificationCodeLocaleUniqueWhere"];
	["IdentificationCodeUniqueWhere"]: GraphQLTypes["IdentificationCodeUniqueWhere"];
	["IdentificationAttemptUniqueWhere"]: GraphQLTypes["IdentificationAttemptUniqueWhere"];
	["SubscriptionBoxLocaleUniqueWhere"]: GraphQLTypes["SubscriptionBoxLocaleUniqueWhere"];
	["SubscriptionBoxUniqueWhere"]: GraphQLTypes["SubscriptionBoxUniqueWhere"];
	["OrderRecurrenceLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderRecurrenceLocaleEdge"]>
};
	["PageInfo"]: {
		totalCount: number
};
	["OrderRecurrenceLocaleEdge"]: {
		node: GraphQLTypes["OrderRecurrenceLocale"]
};
	["BusinessCategoryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCategoryEdge"]>
};
	["BusinessCategoryEdge"]: {
		node: GraphQLTypes["BusinessCategory"]
};
	["ProductList"]: {
		_meta?: GraphQLTypes["ProductListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductListItem"]>,
	businessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	paginateItems: GraphQLTypes["ProductListItemConnection"]
};
	["ProductListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductListItem"]: {
		_meta?: GraphQLTypes["ProductListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductList"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	defaultQuantityForOrder?: number | undefined
};
	["ProductListItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductListItemOrderBy"]: GraphQLTypes["ProductListItemOrderBy"];
	["ProductOrderBy"]: GraphQLTypes["ProductOrderBy"];
	["ProductPackingOrderBy"]: GraphQLTypes["ProductPackingOrderBy"];
	["ProductRecipeOrderBy"]: GraphQLTypes["ProductRecipeOrderBy"];
	["FreshingContainerOrderBy"]: GraphQLTypes["FreshingContainerOrderBy"];
	["WorkspaceOrderBy"]: GraphQLTypes["WorkspaceOrderBy"];
	["WeekHoursOrderBy"]: GraphQLTypes["WeekHoursOrderBy"];
	["VirtualProductOrderBy"]: GraphQLTypes["VirtualProductOrderBy"];
	["VatRateOrderBy"]: GraphQLTypes["VatRateOrderBy"];
	["ProductMetadataOrderBy"]: GraphQLTypes["ProductMetadataOrderBy"];
	["PageOrderBy"]: GraphQLTypes["PageOrderBy"];
	["ProductListItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductListItemEdge"]>
};
	["ProductListItemEdge"]: {
		node: GraphQLTypes["ProductListItem"]
};
	["ProductCategoryList"]: {
		_meta?: GraphQLTypes["ProductCategoryListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductCategoryListItem"]>,
	locales: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	localesByLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	paginateItems: GraphQLTypes["ProductCategoryListItemConnection"],
	paginateLocales: GraphQLTypes["ProductCategoryListLocaleConnection"]
};
	["ProductCategoryListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["FieldMeta"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategoryListItem"]: {
		_meta?: GraphQLTypes["ProductCategoryListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductCategoryList"] | undefined,
	productCategory?: GraphQLTypes["ProductCategory"] | undefined,
	showRecipeImages: boolean,
	showPackingImages: boolean,
	showAllAvailableProducts: boolean
};
	["ProductCategoryListItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["FieldMeta"] | undefined,
	showRecipeImages?: GraphQLTypes["FieldMeta"] | undefined,
	showPackingImages?: GraphQLTypes["FieldMeta"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategory"]: {
		_meta?: GraphQLTypes["ProductCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	products: Array<GraphQLTypes["Product"]>,
	tags: Array<GraphQLTypes["Tag"]>,
	parent?: GraphQLTypes["ProductCategory"] | undefined,
	children: Array<GraphQLTypes["ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	order: number,
	childrenByCode?: GraphQLTypes["ProductCategory"] | undefined,
	childrenByChildren?: GraphQLTypes["ProductCategory"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateTags: GraphQLTypes["TagConnection"],
	paginateChildren: GraphQLTypes["ProductCategoryConnection"]
};
	["ProductCategoryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	parent?: GraphQLTypes["FieldMeta"] | undefined,
	children?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	color?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["TagOrderBy"]: GraphQLTypes["TagOrderBy"];
	["ProductCategoryOrderBy"]: GraphQLTypes["ProductCategoryOrderBy"];
	["ProductCategoryChildrenByCodeUniqueWhere"]: GraphQLTypes["ProductCategoryChildrenByCodeUniqueWhere"];
	["ProductCategoryChildrenByChildrenUniqueWhere"]: GraphQLTypes["ProductCategoryChildrenByChildrenUniqueWhere"];
	["ProductCategoryUniqueWhere"]: GraphQLTypes["ProductCategoryUniqueWhere"];
	["ProductConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductEdge"]>
};
	["ProductEdge"]: {
		node: GraphQLTypes["Product"]
};
	["TagConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TagEdge"]>
};
	["TagEdge"]: {
		node: GraphQLTypes["Tag"]
};
	["ProductCategoryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryEdge"]>
};
	["ProductCategoryEdge"]: {
		node: GraphQLTypes["ProductCategory"]
};
	["ProductCategoryListItemOrderBy"]: GraphQLTypes["ProductCategoryListItemOrderBy"];
	["ProductCategoryListLocale"]: {
		_meta?: GraphQLTypes["ProductCategoryListLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductCategoryList"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductCategoryListLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategoryListLocaleOrderBy"]: GraphQLTypes["ProductCategoryListLocaleOrderBy"];
	["ProductCategoryListLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductCategoryListLocalesByLocaleUniqueWhere"];
	["ProductCategoryListItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListItemEdge"]>
};
	["ProductCategoryListItemEdge"]: {
		node: GraphQLTypes["ProductCategoryListItem"]
};
	["ProductCategoryListLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListLocaleEdge"]>
};
	["ProductCategoryListLocaleEdge"]: {
		node: GraphQLTypes["ProductCategoryListLocale"]
};
	["BusinessCategoryLocalesByLocaleUniqueWhere"]: GraphQLTypes["BusinessCategoryLocalesByLocaleUniqueWhere"];
	["BusinessCategoryLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCategoryLocaleEdge"]>
};
	["BusinessCategoryLocaleEdge"]: {
		node: GraphQLTypes["BusinessCategoryLocale"]
};
	["OrderRecurrenceConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderRecurrenceEdge"]>
};
	["OrderRecurrenceEdge"]: {
		node: GraphQLTypes["OrderRecurrence"]
};
	["ProductIngredientLocale"]: {
		_meta?: GraphQLTypes["ProductIngredientLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductIngredient"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductIngredientLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredient"]: {
		_meta?: GraphQLTypes["ProductIngredientMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalNote?: string | undefined,
	locales: Array<GraphQLTypes["ProductIngredientLocale"]>,
	internalName: string,
	categories: Array<GraphQLTypes["ProductIngredientCategory"]>,
	allergens: Array<GraphQLTypes["Allergen"]>,
	needsPrepping: boolean,
	allowCustomerRating?: boolean | undefined,
	preferredQuantityUnit?: GraphQLTypes["ProductIngredientItemQuantityUnit"] | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	suppliers?: GraphQLTypes["IngredientSupplierList"] | undefined,
	incrementQuantityNumber: number,
	localesByLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	paginateLocales: GraphQLTypes["ProductIngredientLocaleConnection"],
	paginateCategories: GraphQLTypes["ProductIngredientCategoryConnection"],
	paginateAllergens: GraphQLTypes["AllergenConnection"]
};
	["ProductIngredientMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	allergens?: GraphQLTypes["FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["FieldMeta"] | undefined,
	allowCustomerRating?: GraphQLTypes["FieldMeta"] | undefined,
	preferredQuantityUnit?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	suppliers?: GraphQLTypes["FieldMeta"] | undefined,
	incrementQuantityNumber?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientLocaleOrderBy"]: GraphQLTypes["ProductIngredientLocaleOrderBy"];
	["ProductIngredientOrderBy"]: GraphQLTypes["ProductIngredientOrderBy"];
	["ProductIngredientItemQuantityUnitOrderBy"]: GraphQLTypes["ProductIngredientItemQuantityUnitOrderBy"];
	["IngredientSupplierListOrderBy"]: GraphQLTypes["IngredientSupplierListOrderBy"];
	["ProductIngredientCategory"]: {
		_meta?: GraphQLTypes["ProductIngredientCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	internalNote?: string | undefined,
	ingredients: Array<GraphQLTypes["ProductIngredient"]>,
	locales: Array<GraphQLTypes["ProductIngredientCategoryLocale"]>,
	order: number,
	localesByLocale?: GraphQLTypes["ProductIngredientCategoryLocale"] | undefined,
	paginateIngredients: GraphQLTypes["ProductIngredientConnection"],
	paginateLocales: GraphQLTypes["ProductIngredientCategoryLocaleConnection"]
};
	["ProductIngredientCategoryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientCategoryLocale"]: {
		_meta?: GraphQLTypes["ProductIngredientCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductIngredientCategory"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductIngredientCategoryLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientCategoryLocaleOrderBy"]: GraphQLTypes["ProductIngredientCategoryLocaleOrderBy"];
	["ProductIngredientCategoryOrderBy"]: GraphQLTypes["ProductIngredientCategoryOrderBy"];
	["ProductIngredientCategoryLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductIngredientCategoryLocalesByLocaleUniqueWhere"];
	["ProductIngredientConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientEdge"]>
};
	["ProductIngredientEdge"]: {
		node: GraphQLTypes["ProductIngredient"]
};
	["ProductIngredientCategoryLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientCategoryLocaleEdge"]>
};
	["ProductIngredientCategoryLocaleEdge"]: {
		node: GraphQLTypes["ProductIngredientCategoryLocale"]
};
	["Allergen"]: {
		_meta?: GraphQLTypes["AllergenMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["AllergenLocale"]>,
	ingredients: Array<GraphQLTypes["ProductIngredient"]>,
	code: number,
	localesByLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	paginateLocales: GraphQLTypes["AllergenLocaleConnection"],
	paginateIngredients: GraphQLTypes["ProductIngredientConnection"]
};
	["AllergenMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined
};
	["AllergenLocale"]: {
		_meta?: GraphQLTypes["AllergenLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	root?: GraphQLTypes["Allergen"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["AllergenLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["AllergenLocaleOrderBy"]: GraphQLTypes["AllergenLocaleOrderBy"];
	["AllergenOrderBy"]: GraphQLTypes["AllergenOrderBy"];
	["AllergenLocalesByLocaleUniqueWhere"]: GraphQLTypes["AllergenLocalesByLocaleUniqueWhere"];
	["AllergenLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AllergenLocaleEdge"]>
};
	["AllergenLocaleEdge"]: {
		node: GraphQLTypes["AllergenLocale"]
};
	["ProductIngredientItemQuantityUnit"]: {
		_meta?: GraphQLTypes["ProductIngredientItemQuantityUnitMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string
};
	["ProductIngredientItemQuantityUnitMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined
};
	["FreshingContainer"]: {
		_meta?: GraphQLTypes["FreshingContainerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	volumeMl: number,
	workspace?: GraphQLTypes["Workspace"] | undefined
};
	["FreshingContainerMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	workspace?: GraphQLTypes["FieldMeta"] | undefined
};
	["Workspace"]: {
		_meta?: GraphQLTypes["WorkspaceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	orderDeadlineTime?: string | undefined,
	ordersLoadingDurationHours: number,
	freshingContainerVolumeMl: number,
	freshingPrepareOrderByOffsetMinutes: number,
	checkoutUrlTemplate?: string | undefined,
	orderUrlTemplate?: string | undefined,
	paymentUrlTemplate?: string | undefined,
	customerProfileUrlTemplate?: string | undefined,
	userProfileUrlTemplate?: string | undefined,
	messengerUrl?: string | undefined,
	messengerIosUrl?: string | undefined,
	messengerAndroidUrl?: string | undefined,
	freshingContainers: Array<GraphQLTypes["FreshingContainer"]>,
	openingHours?: GraphQLTypes["WeekHours"] | undefined,
	workingHours?: GraphQLTypes["WeekHours"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	creditsProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	discountVatRate?: GraphQLTypes["VatRate"] | undefined,
	name: string,
	businessAddress?: string | undefined,
	paginateFreshingContainers: GraphQLTypes["FreshingContainerConnection"]
};
	["WorkspaceMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	orderDeadlineTime?: GraphQLTypes["FieldMeta"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["FieldMeta"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	orderUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	messengerUrl?: GraphQLTypes["FieldMeta"] | undefined,
	messengerIosUrl?: GraphQLTypes["FieldMeta"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainers?: GraphQLTypes["FieldMeta"] | undefined,
	openingHours?: GraphQLTypes["FieldMeta"] | undefined,
	workingHours?: GraphQLTypes["FieldMeta"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	creditsProduct?: GraphQLTypes["FieldMeta"] | undefined,
	discountVatRate?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	businessAddress?: GraphQLTypes["FieldMeta"] | undefined
};
	["WeekHours"]: {
		_meta?: GraphQLTypes["WeekHoursMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	mon?: string | undefined,
	tue?: string | undefined,
	wed?: string | undefined,
	thu?: string | undefined,
	fri?: string | undefined,
	sat?: string | undefined,
	sun?: string | undefined
};
	["WeekHoursMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	mon?: GraphQLTypes["FieldMeta"] | undefined,
	tue?: GraphQLTypes["FieldMeta"] | undefined,
	wed?: GraphQLTypes["FieldMeta"] | undefined,
	thu?: GraphQLTypes["FieldMeta"] | undefined,
	fri?: GraphQLTypes["FieldMeta"] | undefined,
	sat?: GraphQLTypes["FieldMeta"] | undefined,
	sun?: GraphQLTypes["FieldMeta"] | undefined
};
	["VirtualProduct"]: {
		_meta?: GraphQLTypes["VirtualProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["VirtualProductType"],
	product?: GraphQLTypes["Product"] | undefined,
	voucherCreditCents?: number | undefined,
	physicalRepresentation: GraphQLTypes["VirtualProductPhysicalRepresentationType"],
	recipientEmail?: boolean | undefined
};
	["VirtualProductMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	voucherCreditCents?: GraphQLTypes["FieldMeta"] | undefined,
	physicalRepresentation?: GraphQLTypes["FieldMeta"] | undefined,
	recipientEmail?: GraphQLTypes["FieldMeta"] | undefined
};
	["VatRate"]: {
		_meta?: GraphQLTypes["VatRateMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	ratePermille: number,
	description?: string | undefined,
	internalNote?: string | undefined,
	preset?: GraphQLTypes["VatRatePreset"] | undefined
};
	["VatRateMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	ratePermille?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	preset?: GraphQLTypes["FieldMeta"] | undefined
};
	["FreshingContainerConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FreshingContainerEdge"]>
};
	["FreshingContainerEdge"]: {
		node: GraphQLTypes["FreshingContainer"]
};
	["IngredientSupplierList"]: {
		_meta?: GraphQLTypes["IngredientSupplierListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["IngredientSupplierItem"]>,
	paginateItems: GraphQLTypes["IngredientSupplierItemConnection"]
};
	["IngredientSupplierListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["IngredientSupplierItem"]: {
		_meta?: GraphQLTypes["IngredientSupplierItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["IngredientSupplierList"] | undefined,
	supplier?: GraphQLTypes["IngredientSupplier"] | undefined
};
	["IngredientSupplierItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	supplier?: GraphQLTypes["FieldMeta"] | undefined
};
	["IngredientSupplier"]: {
		_meta?: GraphQLTypes["IngredientSupplierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	internalNote?: string | undefined,
	locales: Array<GraphQLTypes["IngredientSupplierLocale"]>,
	localesByLocale?: GraphQLTypes["IngredientSupplierLocale"] | undefined,
	paginateLocales: GraphQLTypes["IngredientSupplierLocaleConnection"]
};
	["IngredientSupplierMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["IngredientSupplierLocale"]: {
		_meta?: GraphQLTypes["IngredientSupplierLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["IngredientSupplier"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["IngredientSupplierLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["IngredientSupplierLocaleOrderBy"]: GraphQLTypes["IngredientSupplierLocaleOrderBy"];
	["IngredientSupplierOrderBy"]: GraphQLTypes["IngredientSupplierOrderBy"];
	["IngredientSupplierLocalesByLocaleUniqueWhere"]: GraphQLTypes["IngredientSupplierLocalesByLocaleUniqueWhere"];
	["IngredientSupplierLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IngredientSupplierLocaleEdge"]>
};
	["IngredientSupplierLocaleEdge"]: {
		node: GraphQLTypes["IngredientSupplierLocale"]
};
	["IngredientSupplierItemOrderBy"]: GraphQLTypes["IngredientSupplierItemOrderBy"];
	["IngredientSupplierItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IngredientSupplierItemEdge"]>
};
	["IngredientSupplierItemEdge"]: {
		node: GraphQLTypes["IngredientSupplierItem"]
};
	["ProductIngredientLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductIngredientLocalesByLocaleUniqueWhere"];
	["ProductIngredientLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientLocaleEdge"]>
};
	["ProductIngredientLocaleEdge"]: {
		node: GraphQLTypes["ProductIngredientLocale"]
};
	["ProductIngredientCategoryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientCategoryEdge"]>
};
	["ProductIngredientCategoryEdge"]: {
		node: GraphQLTypes["ProductIngredientCategory"]
};
	["AllergenConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AllergenEdge"]>
};
	["AllergenEdge"]: {
		node: GraphQLTypes["Allergen"]
};
	["BlogLocale"]: {
		_meta?: GraphQLTypes["BlogLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	pageName?: string | undefined,
	title?: string | undefined,
	lead?: string | undefined,
	root?: GraphQLTypes["Blog"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["BlogLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	pageName?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	lead?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["Blog"]: {
		_meta?: GraphQLTypes["BlogMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["BlogLocale"]>,
	localesByLocale?: GraphQLTypes["BlogLocale"] | undefined,
	paginateLocales: GraphQLTypes["BlogLocaleConnection"]
};
	["BlogMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogLocaleOrderBy"]: GraphQLTypes["BlogLocaleOrderBy"];
	["BlogOrderBy"]: GraphQLTypes["BlogOrderBy"];
	["BlogLocalesByLocaleUniqueWhere"]: GraphQLTypes["BlogLocalesByLocaleUniqueWhere"];
	["BlogLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogLocaleEdge"]>
};
	["BlogLocaleEdge"]: {
		node: GraphQLTypes["BlogLocale"]
};
	["BlogPostLocale"]: {
		_meta?: GraphQLTypes["BlogPostLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	availableForWeb?: boolean | undefined,
	availableForMobile?: boolean | undefined,
	root?: GraphQLTypes["BlogPost"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	cover?: GraphQLTypes["Cover"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	products: Array<GraphQLTypes["Product"]>,
	paginateProducts: GraphQLTypes["ProductConnection"]
};
	["BlogPostLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	availableForWeb?: GraphQLTypes["FieldMeta"] | undefined,
	availableForMobile?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	cover?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPost"]: {
		_meta?: GraphQLTypes["BlogPostMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	locales: Array<GraphQLTypes["BlogPostLocale"]>,
	localesByLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByCover?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByContent?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByLink?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesBySeo?: GraphQLTypes["BlogPostLocale"] | undefined,
	paginateLocales: GraphQLTypes["BlogPostLocaleConnection"]
};
	["BlogPostMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostLocaleOrderBy"]: GraphQLTypes["BlogPostLocaleOrderBy"];
	["BlogPostOrderBy"]: GraphQLTypes["BlogPostOrderBy"];
	["CoverOrderBy"]: GraphQLTypes["CoverOrderBy"];
	["MediumOrderBy"]: GraphQLTypes["MediumOrderBy"];
	["VideoOrderBy"]: GraphQLTypes["VideoOrderBy"];
	["ColorPalleteOrderBy"]: GraphQLTypes["ColorPalleteOrderBy"];
	["ContentOrderBy"]: GraphQLTypes["ContentOrderBy"];
	["LinkableOrderBy"]: GraphQLTypes["LinkableOrderBy"];
	["PageLocaleOrderBy"]: GraphQLTypes["PageLocaleOrderBy"];
	["SeoOrderBy"]: GraphQLTypes["SeoOrderBy"];
	["RedirectOrderBy"]: GraphQLTypes["RedirectOrderBy"];
	["LinkOrderBy"]: GraphQLTypes["LinkOrderBy"];
	["BlogPostLocalesByLocaleUniqueWhere"]: GraphQLTypes["BlogPostLocalesByLocaleUniqueWhere"];
	["BlogPostLocalesByCoverUniqueWhere"]: GraphQLTypes["BlogPostLocalesByCoverUniqueWhere"];
	["BlogPostLocalesByContentUniqueWhere"]: GraphQLTypes["BlogPostLocalesByContentUniqueWhere"];
	["BlogPostLocalesByLinkUniqueWhere"]: GraphQLTypes["BlogPostLocalesByLinkUniqueWhere"];
	["BlogPostLocalesBySeoUniqueWhere"]: GraphQLTypes["BlogPostLocalesBySeoUniqueWhere"];
	["BlogPostLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostLocaleEdge"]>
};
	["BlogPostLocaleEdge"]: {
		node: GraphQLTypes["BlogPostLocale"]
};
	["Cover"]: {
		_meta?: GraphQLTypes["CoverMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	primaryTitle?: string | undefined,
	secondaryTitle?: string | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	buttonColors?: GraphQLTypes["ColorPallete"] | undefined
};
	["CoverMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	primaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	buttonColors?: GraphQLTypes["FieldMeta"] | undefined
};
	["Medium"]: {
		_meta?: GraphQLTypes["MediumMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["MediumType"] | undefined,
	colorTheme?: GraphQLTypes["ColorTheme"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	video?: GraphQLTypes["Video"] | undefined
};
	["MediumMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	colorTheme?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	video?: GraphQLTypes["FieldMeta"] | undefined
};
	["Video"]: {
		_meta?: GraphQLTypes["VideoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	src?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	duration?: number | undefined,
	poster?: GraphQLTypes["Image"] | undefined
};
	["VideoMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	src?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	poster?: GraphQLTypes["FieldMeta"] | undefined
};
	["ColorPallete"]: {
		_meta?: GraphQLTypes["ColorPalleteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	background?: string | undefined,
	text?: string | undefined
};
	["ColorPalleteMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	background?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined
};
	["Content"]: {
		_meta?: GraphQLTypes["ContentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	blocks: Array<GraphQLTypes["ContentBlock"]>,
	blocksByReferences?: GraphQLTypes["ContentBlock"] | undefined,
	paginateBlocks: GraphQLTypes["ContentBlockConnection"]
};
	["ContentMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	blocks?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentBlock"]: {
		_meta?: GraphQLTypes["ContentBlockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	json: string,
	content?: GraphQLTypes["Content"] | undefined,
	references: Array<GraphQLTypes["ContentReference"]>,
	referencesByProducts?: GraphQLTypes["ContentReference"] | undefined,
	referencesByProductBanners?: GraphQLTypes["ContentReference"] | undefined,
	referencesByDeliveryRegions?: GraphQLTypes["ContentReference"] | undefined,
	paginateReferences: GraphQLTypes["ContentReferenceConnection"]
};
	["ContentBlockMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	json?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	references?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReference"]: {
		_meta?: GraphQLTypes["ContentReferenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["ContentReferenceType"],
	primaryText?: string | undefined,
	secondaryText?: string | undefined,
	jsonContent?: string | undefined,
	block?: GraphQLTypes["ContentBlock"] | undefined,
	colorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostList"] | undefined,
	links?: GraphQLTypes["LinkList"] | undefined,
	products?: GraphQLTypes["ProductList"] | undefined,
	contentSize?: GraphQLTypes["ContentSize"] | undefined,
	misc?: string | undefined,
	hero?: GraphQLTypes["Hero"] | undefined,
	gallery?: GraphQLTypes["Gallery"] | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	productGrid?: GraphQLTypes["ProductGrid"] | undefined,
	productBanners?: GraphQLTypes["ProductBannerList"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	textList?: GraphQLTypes["TextList"] | undefined,
	gridTiles?: GraphQLTypes["GridTiles"] | undefined
};
	["ContentReferenceMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	primaryText?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryText?: GraphQLTypes["FieldMeta"] | undefined,
	jsonContent?: GraphQLTypes["FieldMeta"] | undefined,
	block?: GraphQLTypes["FieldMeta"] | undefined,
	colorPallete?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["FieldMeta"] | undefined,
	links?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	contentSize?: GraphQLTypes["FieldMeta"] | undefined,
	misc?: GraphQLTypes["FieldMeta"] | undefined,
	hero?: GraphQLTypes["FieldMeta"] | undefined,
	gallery?: GraphQLTypes["FieldMeta"] | undefined,
	socialsAndApps?: GraphQLTypes["FieldMeta"] | undefined,
	productGrid?: GraphQLTypes["FieldMeta"] | undefined,
	productBanners?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryRegions?: GraphQLTypes["FieldMeta"] | undefined,
	textList?: GraphQLTypes["FieldMeta"] | undefined,
	gridTiles?: GraphQLTypes["FieldMeta"] | undefined
};
	["Link"]: {
		_meta?: GraphQLTypes["LinkMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["LinkType"],
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: GraphQLTypes["Linkable"] | undefined
};
	["LinkMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	externalLink?: GraphQLTypes["FieldMeta"] | undefined,
	internalLink?: GraphQLTypes["FieldMeta"] | undefined
};
	["Linkable"]: {
		_meta?: GraphQLTypes["LinkableMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	blogPost?: GraphQLTypes["BlogPostLocale"] | undefined,
	page?: GraphQLTypes["PageLocale"] | undefined,
	redirect?: GraphQLTypes["Redirect"] | undefined
};
	["LinkableMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["FieldMeta"] | undefined,
	page?: GraphQLTypes["FieldMeta"] | undefined,
	redirect?: GraphQLTypes["FieldMeta"] | undefined
};
	["PageLocale"]: {
		_meta?: GraphQLTypes["PageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	root?: GraphQLTypes["Page"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	cover?: GraphQLTypes["Cover"] | undefined,
	theme?: GraphQLTypes["PageTheme"] | undefined
};
	["PageLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	cover?: GraphQLTypes["FieldMeta"] | undefined,
	theme?: GraphQLTypes["FieldMeta"] | undefined
};
	["Page"]: {
		_meta?: GraphQLTypes["PageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["PageLocale"]>,
	localesByLocale?: GraphQLTypes["PageLocale"] | undefined,
	localesByLink?: GraphQLTypes["PageLocale"] | undefined,
	localesBySeo?: GraphQLTypes["PageLocale"] | undefined,
	localesByContent?: GraphQLTypes["PageLocale"] | undefined,
	localesByCover?: GraphQLTypes["PageLocale"] | undefined,
	paginateLocales: GraphQLTypes["PageLocaleConnection"]
};
	["PageMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["PageLocalesByLocaleUniqueWhere"]: GraphQLTypes["PageLocalesByLocaleUniqueWhere"];
	["PageLocalesByLinkUniqueWhere"]: GraphQLTypes["PageLocalesByLinkUniqueWhere"];
	["PageLocalesBySeoUniqueWhere"]: GraphQLTypes["PageLocalesBySeoUniqueWhere"];
	["PageLocalesByContentUniqueWhere"]: GraphQLTypes["PageLocalesByContentUniqueWhere"];
	["PageLocalesByCoverUniqueWhere"]: GraphQLTypes["PageLocalesByCoverUniqueWhere"];
	["PageLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PageLocaleEdge"]>
};
	["PageLocaleEdge"]: {
		node: GraphQLTypes["PageLocale"]
};
	["Seo"]: {
		_meta?: GraphQLTypes["SeoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	ogImage?: GraphQLTypes["Image"] | undefined
};
	["SeoMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	ogTitle?: GraphQLTypes["FieldMeta"] | undefined,
	ogDescription?: GraphQLTypes["FieldMeta"] | undefined,
	ogImage?: GraphQLTypes["FieldMeta"] | undefined
};
	["Redirect"]: {
		_meta?: GraphQLTypes["RedirectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	link?: GraphQLTypes["Linkable"] | undefined,
	target?: GraphQLTypes["Link"] | undefined
};
	["RedirectMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	target?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostList"]: {
		_meta?: GraphQLTypes["BlogPostListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateItems: GraphQLTypes["BlogPostListItemConnection"]
};
	["BlogPostListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostListItem"]: {
		_meta?: GraphQLTypes["BlogPostListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["BlogPostList"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocale"] | undefined
};
	["BlogPostListItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	caption?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostListItemOrderBy"]: GraphQLTypes["BlogPostListItemOrderBy"];
	["BlogPostListOrderBy"]: GraphQLTypes["BlogPostListOrderBy"];
	["BlogPostListItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostListItemEdge"]>
};
	["BlogPostListItemEdge"]: {
		node: GraphQLTypes["BlogPostListItem"]
};
	["LinkList"]: {
		_meta?: GraphQLTypes["LinkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["LinkListItem"]>,
	paginateItems: GraphQLTypes["LinkListItemConnection"]
};
	["LinkListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkListItem"]: {
		_meta?: GraphQLTypes["LinkListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	isPrimary?: boolean | undefined,
	list?: GraphQLTypes["LinkList"] | undefined,
	link?: GraphQLTypes["Link"] | undefined
};
	["LinkListItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	isPrimary?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkListItemOrderBy"]: GraphQLTypes["LinkListItemOrderBy"];
	["LinkListOrderBy"]: GraphQLTypes["LinkListOrderBy"];
	["LinkListItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkListItemEdge"]>
};
	["LinkListItemEdge"]: {
		node: GraphQLTypes["LinkListItem"]
};
	["Hero"]: {
		_meta?: GraphQLTypes["HeroMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	showLogo?: boolean | undefined,
	middleContent?: string | undefined,
	medium?: GraphQLTypes["Medium"] | undefined
};
	["HeroMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	showLogo?: GraphQLTypes["FieldMeta"] | undefined,
	middleContent?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined
};
	["Gallery"]: {
		_meta?: GraphQLTypes["GalleryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["GalleryItem"]>,
	paginateItems: GraphQLTypes["GalleryItemConnection"]
};
	["GalleryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["GalleryItem"]: {
		_meta?: GraphQLTypes["GalleryItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["Gallery"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined
};
	["GalleryItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	caption?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined
};
	["GalleryItemOrderBy"]: GraphQLTypes["GalleryItemOrderBy"];
	["GalleryOrderBy"]: GraphQLTypes["GalleryOrderBy"];
	["GalleryItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GalleryItemEdge"]>
};
	["GalleryItemEdge"]: {
		node: GraphQLTypes["GalleryItem"]
};
	["SocialsAndApps"]: {
		_meta?: GraphQLTypes["SocialsAndAppsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	instagram?: boolean | undefined,
	appStore?: boolean | undefined,
	googlePlay?: boolean | undefined
};
	["SocialsAndAppsMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	instagram?: GraphQLTypes["FieldMeta"] | undefined,
	appStore?: GraphQLTypes["FieldMeta"] | undefined,
	googlePlay?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGrid"]: {
		_meta?: GraphQLTypes["ProductGridMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isCarousel?: boolean | undefined,
	categories: Array<GraphQLTypes["ProductGridCategory"]>,
	type?: GraphQLTypes["ProductGridType"] | undefined,
	categoriesByItems?: GraphQLTypes["ProductGridCategory"] | undefined,
	categoriesByImages?: GraphQLTypes["ProductGridCategory"] | undefined,
	paginateCategories: GraphQLTypes["ProductGridCategoryConnection"]
};
	["ProductGridMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	isCarousel?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridCategory"]: {
		_meta?: GraphQLTypes["ProductGridCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	root?: GraphQLTypes["ProductGrid"] | undefined,
	items: Array<GraphQLTypes["ProductGridItem"]>,
	images?: GraphQLTypes["ImageList"] | undefined,
	paginateItems: GraphQLTypes["ProductGridItemConnection"]
};
	["ProductGridCategoryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	images?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridItem"]: {
		_meta?: GraphQLTypes["ProductGridItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	lead?: string | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	category?: GraphQLTypes["ProductGridCategory"] | undefined,
	description?: string | undefined
};
	["ProductGridItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	lead?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	category?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridItemOrderBy"]: GraphQLTypes["ProductGridItemOrderBy"];
	["ProductGridCategoryOrderBy"]: GraphQLTypes["ProductGridCategoryOrderBy"];
	["ProductGridOrderBy"]: GraphQLTypes["ProductGridOrderBy"];
	["ImageListOrderBy"]: GraphQLTypes["ImageListOrderBy"];
	["ImageList"]: {
		_meta?: GraphQLTypes["ImageListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ImageListItem"]>,
	itemsByImage?: GraphQLTypes["ImageListItem"] | undefined,
	paginateItems: GraphQLTypes["ImageListItemConnection"]
};
	["ImageListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItem"]: {
		_meta?: GraphQLTypes["ImageListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ImageList"] | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["ImageListItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItemOrderBy"]: GraphQLTypes["ImageListItemOrderBy"];
	["ImageListItemsByImageUniqueWhere"]: GraphQLTypes["ImageListItemsByImageUniqueWhere"];
	["ImageListItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListItemEdge"]>
};
	["ImageListItemEdge"]: {
		node: GraphQLTypes["ImageListItem"]
};
	["ProductGridItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridItemEdge"]>
};
	["ProductGridItemEdge"]: {
		node: GraphQLTypes["ProductGridItem"]
};
	["ProductGridCategoriesByItemsUniqueWhere"]: GraphQLTypes["ProductGridCategoriesByItemsUniqueWhere"];
	["ProductGridItemUniqueWhere"]: GraphQLTypes["ProductGridItemUniqueWhere"];
	["ProductGridCategoriesByImagesUniqueWhere"]: GraphQLTypes["ProductGridCategoriesByImagesUniqueWhere"];
	["ProductGridCategoryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridCategoryEdge"]>
};
	["ProductGridCategoryEdge"]: {
		node: GraphQLTypes["ProductGridCategory"]
};
	["ProductBannerList"]: {
		_meta?: GraphQLTypes["ProductBannerListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateItems: GraphQLTypes["ProductBannerItemConnection"]
};
	["ProductBannerListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBannerItem"]: {
		_meta?: GraphQLTypes["ProductBannerItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductBannerList"] | undefined,
	banner?: GraphQLTypes["ProductBanner"] | undefined,
	size?: GraphQLTypes["ProductBannerItemSize"] | undefined
};
	["ProductBannerItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	banner?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBanner"]: {
		_meta?: GraphQLTypes["ProductBannerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	beforeTitle?: string | undefined,
	title?: string | undefined,
	label?: string | undefined,
	button?: GraphQLTypes["Link"] | undefined,
	backgroundImage?: GraphQLTypes["Image"] | undefined,
	productImage?: GraphQLTypes["Image"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductBannerMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	beforeTitle?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	label?: GraphQLTypes["FieldMeta"] | undefined,
	button?: GraphQLTypes["FieldMeta"] | undefined,
	backgroundImage?: GraphQLTypes["FieldMeta"] | undefined,
	productImage?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBannerItemOrderBy"]: GraphQLTypes["ProductBannerItemOrderBy"];
	["ProductBannerListOrderBy"]: GraphQLTypes["ProductBannerListOrderBy"];
	["ProductBannerOrderBy"]: GraphQLTypes["ProductBannerOrderBy"];
	["ProductBannerItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerItemEdge"]>
};
	["ProductBannerItemEdge"]: {
		node: GraphQLTypes["ProductBannerItem"]
};
	["DeliveryRegions"]: {
		_meta?: GraphQLTypes["DeliveryRegionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	itemsByImage?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	itemsByTextList?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	itemsByGallery?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	paginateItems: GraphQLTypes["DeliveryRegionsItemConnection"]
};
	["DeliveryRegionsMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryRegionsItem"]: {
		_meta?: GraphQLTypes["DeliveryRegionsItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	offerLabel?: string | undefined,
	list?: GraphQLTypes["DeliveryRegions"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	textList?: GraphQLTypes["TextList"] | undefined,
	gallery?: GraphQLTypes["ImageList"] | undefined
};
	["DeliveryRegionsItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	offerLabel?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	textList?: GraphQLTypes["FieldMeta"] | undefined,
	gallery?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextList"]: {
		_meta?: GraphQLTypes["TextListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["TextItem"]>,
	paginateItems: GraphQLTypes["TextItemConnection"]
};
	["TextListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextItem"]: {
		_meta?: GraphQLTypes["TextItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	text?: string | undefined,
	list?: GraphQLTypes["TextList"] | undefined,
	title?: string | undefined
};
	["TextItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextItemOrderBy"]: GraphQLTypes["TextItemOrderBy"];
	["TextListOrderBy"]: GraphQLTypes["TextListOrderBy"];
	["TextItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TextItemEdge"]>
};
	["TextItemEdge"]: {
		node: GraphQLTypes["TextItem"]
};
	["DeliveryRegionsItemOrderBy"]: GraphQLTypes["DeliveryRegionsItemOrderBy"];
	["DeliveryRegionsOrderBy"]: GraphQLTypes["DeliveryRegionsOrderBy"];
	["DeliveryRegionsItemsByImageUniqueWhere"]: GraphQLTypes["DeliveryRegionsItemsByImageUniqueWhere"];
	["DeliveryRegionsItemsByTextListUniqueWhere"]: GraphQLTypes["DeliveryRegionsItemsByTextListUniqueWhere"];
	["DeliveryRegionsItemsByGalleryUniqueWhere"]: GraphQLTypes["DeliveryRegionsItemsByGalleryUniqueWhere"];
	["DeliveryRegionsItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryRegionsItemEdge"]>
};
	["DeliveryRegionsItemEdge"]: {
		node: GraphQLTypes["DeliveryRegionsItem"]
};
	["GridTiles"]: {
		_meta?: GraphQLTypes["GridTilesMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["GridTileItem"]>,
	itemsByLink?: GraphQLTypes["GridTileItem"] | undefined,
	paginateItems: GraphQLTypes["GridTileItemConnection"]
};
	["GridTilesMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["GridTileItem"]: {
		_meta?: GraphQLTypes["GridTileItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	text?: string | undefined,
	list?: GraphQLTypes["GridTiles"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined
};
	["GridTileItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["GridTileItemOrderBy"]: GraphQLTypes["GridTileItemOrderBy"];
	["GridTilesOrderBy"]: GraphQLTypes["GridTilesOrderBy"];
	["GridTilesItemsByLinkUniqueWhere"]: GraphQLTypes["GridTilesItemsByLinkUniqueWhere"];
	["LinkUniqueWhere"]: GraphQLTypes["LinkUniqueWhere"];
	["GridTileItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GridTileItemEdge"]>
};
	["GridTileItemEdge"]: {
		node: GraphQLTypes["GridTileItem"]
};
	["ContentReferenceOrderBy"]: GraphQLTypes["ContentReferenceOrderBy"];
	["ContentBlockOrderBy"]: GraphQLTypes["ContentBlockOrderBy"];
	["HeroOrderBy"]: GraphQLTypes["HeroOrderBy"];
	["SocialsAndAppsOrderBy"]: GraphQLTypes["SocialsAndAppsOrderBy"];
	["ContentBlockReferencesByProductsUniqueWhere"]: GraphQLTypes["ContentBlockReferencesByProductsUniqueWhere"];
	["ContentBlockReferencesByProductBannersUniqueWhere"]: GraphQLTypes["ContentBlockReferencesByProductBannersUniqueWhere"];
	["ContentBlockReferencesByDeliveryRegionsUniqueWhere"]: GraphQLTypes["ContentBlockReferencesByDeliveryRegionsUniqueWhere"];
	["ContentReferenceConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentReferenceEdge"]>
};
	["ContentReferenceEdge"]: {
		node: GraphQLTypes["ContentReference"]
};
	["ContentBlocksByReferencesUniqueWhere"]: GraphQLTypes["ContentBlocksByReferencesUniqueWhere"];
	["ContentBlockConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentBlockEdge"]>
};
	["ContentBlockEdge"]: {
		node: GraphQLTypes["ContentBlock"]
};
	["TranslationCataloguesIdentifier"]: {
		_meta?: GraphQLTypes["TranslationCataloguesIdentifierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	catalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	catalogueByDomain?: GraphQLTypes["TranslationCatalogue"] | undefined,
	catalogueByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	paginateCatalogue: GraphQLTypes["TranslationCatalogueConnection"]
};
	["TranslationCataloguesIdentifierMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCatalogue"]: {
		_meta?: GraphQLTypes["TranslationCatalogueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogue"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	valuesByKey?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationCatalogueMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	fallback?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationDomain"]: {
		_meta?: GraphQLTypes["TranslationDomainMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	name: string,
	catalogues: Array<GraphQLTypes["TranslationCatalogue"]>,
	keys: Array<GraphQLTypes["TranslationKey"]>,
	cataloguesByIdentifier?: GraphQLTypes["TranslationCatalogue"] | undefined,
	cataloguesByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	keysByIdentifier?: GraphQLTypes["TranslationKey"] | undefined,
	keysByValues?: GraphQLTypes["TranslationKey"] | undefined,
	paginateCatalogues: GraphQLTypes["TranslationCatalogueConnection"],
	paginateKeys: GraphQLTypes["TranslationKeyConnection"]
};
	["TranslationDomainMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	catalogues?: GraphQLTypes["FieldMeta"] | undefined,
	keys?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCatalogueOrderBy"]: GraphQLTypes["TranslationCatalogueOrderBy"];
	["TranslationDomainOrderBy"]: GraphQLTypes["TranslationDomainOrderBy"];
	["TranslationKey"]: {
		_meta?: GraphQLTypes["TranslationKeyMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	contentType: GraphQLTypes["TranslationContentType"],
	note?: string | undefined,
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	valuesByCatalogue?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationKeyMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	contentType?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValue"]: {
		_meta?: GraphQLTypes["TranslationValueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	value: string,
	catalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	key?: GraphQLTypes["TranslationKey"] | undefined
};
	["TranslationValueMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	value?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined,
	key?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValueOrderBy"]: GraphQLTypes["TranslationValueOrderBy"];
	["TranslationKeyOrderBy"]: GraphQLTypes["TranslationKeyOrderBy"];
	["TranslationKeyValuesByCatalogueUniqueWhere"]: GraphQLTypes["TranslationKeyValuesByCatalogueUniqueWhere"];
	["TranslationValueConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationValueEdge"]>
};
	["TranslationValueEdge"]: {
		node: GraphQLTypes["TranslationValue"]
};
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: GraphQLTypes["TranslationDomainCataloguesByIdentifierUniqueWhere"];
	["TranslationDomainCataloguesByValuesUniqueWhere"]: GraphQLTypes["TranslationDomainCataloguesByValuesUniqueWhere"];
	["TranslationDomainKeysByIdentifierUniqueWhere"]: GraphQLTypes["TranslationDomainKeysByIdentifierUniqueWhere"];
	["TranslationDomainKeysByValuesUniqueWhere"]: GraphQLTypes["TranslationDomainKeysByValuesUniqueWhere"];
	["TranslationCatalogueConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCatalogueEdge"]>
};
	["TranslationCatalogueEdge"]: {
		node: GraphQLTypes["TranslationCatalogue"]
};
	["TranslationKeyConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationKeyEdge"]>
};
	["TranslationKeyEdge"]: {
		node: GraphQLTypes["TranslationKey"]
};
	["TranslationCatalogueValuesByKeyUniqueWhere"]: GraphQLTypes["TranslationCatalogueValuesByKeyUniqueWhere"];
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: GraphQLTypes["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"];
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: GraphQLTypes["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"];
	["ProductPackingLocaleOrderBy"]: GraphQLTypes["ProductPackingLocaleOrderBy"];
	["ProductLocale"]: {
		_meta?: GraphQLTypes["ProductLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	root?: GraphQLTypes["Product"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	featureList?: GraphQLTypes["TextList"] | undefined,
	printableName?: string | undefined,
	firstSticker?: string | undefined,
	secondSticker?: string | undefined
};
	["ProductLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	featureList?: GraphQLTypes["FieldMeta"] | undefined,
	printableName?: GraphQLTypes["FieldMeta"] | undefined,
	firstSticker?: GraphQLTypes["FieldMeta"] | undefined,
	secondSticker?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductLocaleOrderBy"]: GraphQLTypes["ProductLocaleOrderBy"];
	["ProductRecipeLocale"]: {
		_meta?: GraphQLTypes["ProductRecipeLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductRecipe"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	description?: string | undefined
};
	["ProductRecipeLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductRecipe"]: {
		_meta?: GraphQLTypes["ProductRecipeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	products: Array<GraphQLTypes["Product"]>,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	ingredients: Array<GraphQLTypes["ProductIngredientItem"]>,
	needsPrepping: boolean,
	locales: Array<GraphQLTypes["ProductRecipeLocale"]>,
	gutTuning?: number | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	description?: string | undefined,
	productsByCode?: GraphQLTypes["Product"] | undefined,
	productsByStocks?: GraphQLTypes["Product"] | undefined,
	productsByOrderItems?: GraphQLTypes["Product"] | undefined,
	productsByLocales?: GraphQLTypes["Product"] | undefined,
	productsByVirtualProduct?: GraphQLTypes["Product"] | undefined,
	productsByMeta?: GraphQLTypes["Product"] | undefined,
	productsByPage?: GraphQLTypes["Product"] | undefined,
	ingredientsByQuantity?: GraphQLTypes["ProductIngredientItem"] | undefined,
	localesByLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateIngredients: GraphQLTypes["ProductIngredientItemConnection"],
	paginateLocales: GraphQLTypes["ProductRecipeLocaleConnection"]
};
	["ProductRecipeMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	color?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	gutTuning?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientItem"]: {
		_meta?: GraphQLTypes["ProductIngredientItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	internalOrder: number,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredient"] | undefined,
	quantity?: GraphQLTypes["ProductIngredientItemQuantity"] | undefined
};
	["ProductIngredientItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	internalOrder?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientItemQuantity"]: {
		_meta?: GraphQLTypes["ProductIngredientItemQuantityMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unit?: GraphQLTypes["ProductIngredientItemQuantityUnit"] | undefined,
	value: number
};
	["ProductIngredientItemQuantityMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	unit?: GraphQLTypes["FieldMeta"] | undefined,
	value?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientItemOrderBy"]: GraphQLTypes["ProductIngredientItemOrderBy"];
	["ProductIngredientItemQuantityOrderBy"]: GraphQLTypes["ProductIngredientItemQuantityOrderBy"];
	["ProductRecipeLocaleOrderBy"]: GraphQLTypes["ProductRecipeLocaleOrderBy"];
	["ProductRecipeProductsByCodeUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByCodeUniqueWhere"];
	["ProductRecipeProductsByStocksUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByStocksUniqueWhere"];
	["ProductRecipeProductsByOrderItemsUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByOrderItemsUniqueWhere"];
	["ProductRecipeProductsByLocalesUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByLocalesUniqueWhere"];
	["ProductRecipeProductsByVirtualProductUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByVirtualProductUniqueWhere"];
	["ProductRecipeProductsByMetaUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByMetaUniqueWhere"];
	["ProductRecipeProductsByPageUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByPageUniqueWhere"];
	["ProductRecipeIngredientsByQuantityUniqueWhere"]: GraphQLTypes["ProductRecipeIngredientsByQuantityUniqueWhere"];
	["ProductRecipeLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductRecipeLocalesByLocaleUniqueWhere"];
	["ProductIngredientItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientItemEdge"]>
};
	["ProductIngredientItemEdge"]: {
		node: GraphQLTypes["ProductIngredientItem"]
};
	["ProductRecipeLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductRecipeLocaleEdge"]>
};
	["ProductRecipeLocaleEdge"]: {
		node: GraphQLTypes["ProductRecipeLocale"]
};
	["DeliveryMethodLocale"]: {
		_meta?: GraphQLTypes["DeliveryMethodLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	trackingCodeUrlTemplate?: string | undefined,
	processingTitle?: string | undefined,
	processingText?: string | undefined,
	processingIconUrl?: string | undefined,
	dispatchedTitle?: string | undefined,
	dispatchedText?: string | undefined,
	dispatchedIconUrl?: string | undefined,
	fulfilledTitle?: string | undefined,
	fulfilledText?: string | undefined,
	fulfilledIconUrl?: string | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	root?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["DeliveryMethodLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	processingTitle?: GraphQLTypes["FieldMeta"] | undefined,
	processingText?: GraphQLTypes["FieldMeta"] | undefined,
	processingIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedTitle?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedText?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledTitle?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledText?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethod"]: {
		_meta?: GraphQLTypes["DeliveryMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	paymentMethods: Array<GraphQLTypes["PaymentMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress: boolean,
	suitableForDeliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	enableDeliveryTracking: boolean,
	locales: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	timelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	localesByLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	paginatePaymentMethods: GraphQLTypes["PaymentMethodConnection"],
	paginateSuitableForDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginateLocales: GraphQLTypes["DeliveryMethodLocaleConnection"]
};
	["DeliveryMethodMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	paymentMethods?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["FieldMeta"] | undefined,
	suitableForDeliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	timelinePreset?: GraphQLTypes["FieldMeta"] | undefined
};
	["PaymentMethod"]: {
		_meta?: GraphQLTypes["PaymentMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	blockingDispatch: boolean,
	type?: GraphQLTypes["PaymentMethodType"] | undefined,
	reduceGratuityByPercentage: number,
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"]
};
	["PaymentMethodMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	blockingDispatch?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	reduceGratuityByPercentage?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethodOrderBy"]: GraphQLTypes["DeliveryMethodOrderBy"];
	["DeliveryTimelinePresetOrderBy"]: GraphQLTypes["DeliveryTimelinePresetOrderBy"];
	["DeliveryMethodConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryMethodEdge"]>
};
	["DeliveryMethodEdge"]: {
		node: GraphQLTypes["DeliveryMethod"]
};
	["PaymentMethodOrderBy"]: GraphQLTypes["PaymentMethodOrderBy"];
	["DeliveryZone"]: {
		_meta?: GraphQLTypes["DeliveryZoneMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type: GraphQLTypes["DeliveryZoneType"],
	addresses: Array<GraphQLTypes["Address"]>,
	suitableDeliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	deliveryZonesOfTags: Array<GraphQLTypes["Tag"]>,
	deliveryZonesOfProducts: Array<GraphQLTypes["Product"]>,
	addressesByBillingAddressOfOrders?: GraphQLTypes["Address"] | undefined,
	addressesByDeliveryAddressOfOrderDeliveries?: GraphQLTypes["Address"] | undefined,
	addressesByDefaultDeliveryAddressOfCustomer?: GraphQLTypes["Address"] | undefined,
	paginateAddresses: GraphQLTypes["AddressConnection"],
	paginateSuitableDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"],
	paginateDeliveryZonesOfTags: GraphQLTypes["TagConnection"],
	paginateDeliveryZonesOfProducts: GraphQLTypes["ProductConnection"]
};
	["DeliveryZoneMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	addresses?: GraphQLTypes["FieldMeta"] | undefined,
	suitableDeliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZonesOfTags?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZonesOfProducts?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere"]: GraphQLTypes["DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere"];
	["OrderUniqueWhere"]: GraphQLTypes["OrderUniqueWhere"];
	["OrderDeliveryUniqueWhere"]: GraphQLTypes["OrderDeliveryUniqueWhere"];
	["DeliveryWaypointUniqueWhere"]: GraphQLTypes["DeliveryWaypointUniqueWhere"];
	["OrderPaymentUniqueWhere"]: GraphQLTypes["OrderPaymentUniqueWhere"];
	["DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: GraphQLTypes["DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"];
	["DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: GraphQLTypes["DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"];
	["CustomerUniqueWhere"]: GraphQLTypes["CustomerUniqueWhere"];
	["AddressUniqueWhere"]: GraphQLTypes["AddressUniqueWhere"];
	["CustomerNameUniqueWhere"]: GraphQLTypes["CustomerNameUniqueWhere"];
	["CustomerMetadataUniqueWhere"]: GraphQLTypes["CustomerMetadataUniqueWhere"];
	["AddressConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AddressEdge"]>
};
	["AddressEdge"]: {
		node: GraphQLTypes["Address"]
};
	["DeliveryMethodLocaleOrderBy"]: GraphQLTypes["DeliveryMethodLocaleOrderBy"];
	["DeliveryTimelinePreset"]: {
		_meta?: GraphQLTypes["DeliveryTimelinePresetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["DeliveryTimelineType"],
	duration?: string | undefined,
	window?: string | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["DeliveryTimelinePresetMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	window?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethod?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethodLocalesByLocaleUniqueWhere"]: GraphQLTypes["DeliveryMethodLocalesByLocaleUniqueWhere"];
	["PaymentMethodConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PaymentMethodEdge"]>
};
	["PaymentMethodEdge"]: {
		node: GraphQLTypes["PaymentMethod"]
};
	["DeliveryZoneConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryZoneEdge"]>
};
	["DeliveryZoneEdge"]: {
		node: GraphQLTypes["DeliveryZone"]
};
	["DeliveryMethodLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryMethodLocaleEdge"]>
};
	["DeliveryMethodLocaleEdge"]: {
		node: GraphQLTypes["DeliveryMethodLocale"]
};
	["IdentificationCodeLocale"]: {
		_meta?: GraphQLTypes["IdentificationCodeLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	successMessage?: string | undefined,
	root?: GraphQLTypes["IdentificationCode"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["IdentificationCodeLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	successMessage?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["IdentificationCode"]: {
		_meta?: GraphQLTypes["IdentificationCodeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	createdAt: GraphQLTypes["DateTime"],
	attempts: Array<GraphQLTypes["IdentificationAttempt"]>,
	locales: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	internalName?: string | undefined,
	localesByLocale?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	paginateAttempts: GraphQLTypes["IdentificationAttemptConnection"],
	paginateLocales: GraphQLTypes["IdentificationCodeLocaleConnection"]
};
	["IdentificationCodeMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	attempts?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined
};
	["IdentificationAttempt"]: {
		_meta?: GraphQLTypes["IdentificationAttemptMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	code?: GraphQLTypes["IdentificationCode"] | undefined
};
	["IdentificationAttemptMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined
};
	["IdentificationAttemptOrderBy"]: GraphQLTypes["IdentificationAttemptOrderBy"];
	["IdentificationCodeOrderBy"]: GraphQLTypes["IdentificationCodeOrderBy"];
	["IdentificationCodeLocaleOrderBy"]: GraphQLTypes["IdentificationCodeLocaleOrderBy"];
	["IdentificationCodeLocalesByLocaleUniqueWhere"]: GraphQLTypes["IdentificationCodeLocalesByLocaleUniqueWhere"];
	["IdentificationAttemptConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IdentificationAttemptEdge"]>
};
	["IdentificationAttemptEdge"]: {
		node: GraphQLTypes["IdentificationAttempt"]
};
	["IdentificationCodeLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IdentificationCodeLocaleEdge"]>
};
	["IdentificationCodeLocaleEdge"]: {
		node: GraphQLTypes["IdentificationCodeLocale"]
};
	["SubscriptionBoxLocale"]: {
		_meta?: GraphQLTypes["SubscriptionBoxLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	stickerText?: string | undefined,
	descriptionText?: string | undefined,
	root?: GraphQLTypes["SubscriptionBox"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	secondaryTitle?: string | undefined,
	texts?: GraphQLTypes["TextList"] | undefined,
	secondaryTexts?: GraphQLTypes["TextList"] | undefined
};
	["SubscriptionBoxLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	stickerText?: GraphQLTypes["FieldMeta"] | undefined,
	descriptionText?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	texts?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryTexts?: GraphQLTypes["FieldMeta"] | undefined
};
	["SubscriptionBox"]: {
		_meta?: GraphQLTypes["SubscriptionBoxMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	unique: GraphQLTypes["One"],
	localesByLocale?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	localesByTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	localesBySecondaryTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	paginateLocales: GraphQLTypes["SubscriptionBoxLocaleConnection"]
};
	["SubscriptionBoxMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined
};
	["SubscriptionBoxLocaleOrderBy"]: GraphQLTypes["SubscriptionBoxLocaleOrderBy"];
	["SubscriptionBoxOrderBy"]: GraphQLTypes["SubscriptionBoxOrderBy"];
	["SubscriptionBoxLocalesByLocaleUniqueWhere"]: GraphQLTypes["SubscriptionBoxLocalesByLocaleUniqueWhere"];
	["SubscriptionBoxLocalesByTextsUniqueWhere"]: GraphQLTypes["SubscriptionBoxLocalesByTextsUniqueWhere"];
	["SubscriptionBoxLocalesBySecondaryTextsUniqueWhere"]: GraphQLTypes["SubscriptionBoxLocalesBySecondaryTextsUniqueWhere"];
	["SubscriptionBoxLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SubscriptionBoxLocaleEdge"]>
};
	["SubscriptionBoxLocaleEdge"]: {
		node: GraphQLTypes["SubscriptionBoxLocale"]
};
	["LocaleBusinessCategoriesByRootUniqueWhere"]: GraphQLTypes["LocaleBusinessCategoriesByRootUniqueWhere"];
	["LocaleProductCategoryListsByRootUniqueWhere"]: GraphQLTypes["LocaleProductCategoryListsByRootUniqueWhere"];
	["LocaleProductIngredientsByRootUniqueWhere"]: GraphQLTypes["LocaleProductIngredientsByRootUniqueWhere"];
	["LocaleOrderRecurrencesByRootUniqueWhere"]: GraphQLTypes["LocaleOrderRecurrencesByRootUniqueWhere"];
	["LocaleBlogsByRootUniqueWhere"]: GraphQLTypes["LocaleBlogsByRootUniqueWhere"];
	["LocaleBlogPostsByRootUniqueWhere"]: GraphQLTypes["LocaleBlogPostsByRootUniqueWhere"];
	["LocaleBlogPostsByCoverUniqueWhere"]: GraphQLTypes["LocaleBlogPostsByCoverUniqueWhere"];
	["LocaleBlogPostsByContentUniqueWhere"]: GraphQLTypes["LocaleBlogPostsByContentUniqueWhere"];
	["LocaleBlogPostsByLinkUniqueWhere"]: GraphQLTypes["LocaleBlogPostsByLinkUniqueWhere"];
	["LocaleBlogPostsBySeoUniqueWhere"]: GraphQLTypes["LocaleBlogPostsBySeoUniqueWhere"];
	["LocalePagesByRootUniqueWhere"]: GraphQLTypes["LocalePagesByRootUniqueWhere"];
	["LocalePagesByLinkUniqueWhere"]: GraphQLTypes["LocalePagesByLinkUniqueWhere"];
	["LocalePagesBySeoUniqueWhere"]: GraphQLTypes["LocalePagesBySeoUniqueWhere"];
	["LocalePagesByContentUniqueWhere"]: GraphQLTypes["LocalePagesByContentUniqueWhere"];
	["LocalePagesByCoverUniqueWhere"]: GraphQLTypes["LocalePagesByCoverUniqueWhere"];
	["LocaleProductIngredientCategoriesByRootUniqueWhere"]: GraphQLTypes["LocaleProductIngredientCategoriesByRootUniqueWhere"];
	["LocaleAllergensByRootUniqueWhere"]: GraphQLTypes["LocaleAllergensByRootUniqueWhere"];
	["LocaleProductPackingsByRootUniqueWhere"]: GraphQLTypes["LocaleProductPackingsByRootUniqueWhere"];
	["LocaleProductsByRootUniqueWhere"]: GraphQLTypes["LocaleProductsByRootUniqueWhere"];
	["LocaleProductsByFeatureListUniqueWhere"]: GraphQLTypes["LocaleProductsByFeatureListUniqueWhere"];
	["LocaleProductRecipesByRootUniqueWhere"]: GraphQLTypes["LocaleProductRecipesByRootUniqueWhere"];
	["LocaleDeliveryMethodsByRootUniqueWhere"]: GraphQLTypes["LocaleDeliveryMethodsByRootUniqueWhere"];
	["LocaleIngredientSuppliersByRootUniqueWhere"]: GraphQLTypes["LocaleIngredientSuppliersByRootUniqueWhere"];
	["LocaleIdentificationCodesByRootUniqueWhere"]: GraphQLTypes["LocaleIdentificationCodesByRootUniqueWhere"];
	["LocaleSubscriptionBoxesByRootUniqueWhere"]: GraphQLTypes["LocaleSubscriptionBoxesByRootUniqueWhere"];
	["LocaleSubscriptionBoxesByTextsUniqueWhere"]: GraphQLTypes["LocaleSubscriptionBoxesByTextsUniqueWhere"];
	["LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere"]: GraphQLTypes["LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere"];
	["ProductPackingLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductPackingLocaleEdge"]>
};
	["ProductPackingLocaleEdge"]: {
		node: GraphQLTypes["ProductPackingLocale"]
};
	["ProductLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductLocaleEdge"]>
};
	["ProductLocaleEdge"]: {
		node: GraphQLTypes["ProductLocale"]
};
	["PreparedPackingQuantity"]: {
		_meta?: GraphQLTypes["PreparedPackingQuantityMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	date: GraphQLTypes["Date"],
	quantity?: number | undefined,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	packing?: GraphQLTypes["ProductPacking"] | undefined,
	packedInCardboard: boolean
};
	["PreparedPackingQuantityMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	date?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	packing?: GraphQLTypes["FieldMeta"] | undefined,
	packedInCardboard?: GraphQLTypes["FieldMeta"] | undefined
};
	["PreparedPackingQuantityOrderBy"]: GraphQLTypes["PreparedPackingQuantityOrderBy"];
	["ProductPackingLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductPackingLocalesByLocaleUniqueWhere"];
	["PreparedPackingQuantityConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PreparedPackingQuantityEdge"]>
};
	["PreparedPackingQuantityEdge"]: {
		node: GraphQLTypes["PreparedPackingQuantity"]
};
	["ProductStock"]: {
		_meta?: GraphQLTypes["ProductStockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	quantity: number,
	product?: GraphQLTypes["Product"] | undefined,
	store?: GraphQLTypes["Store"] | undefined
};
	["ProductStockMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	store?: GraphQLTypes["FieldMeta"] | undefined
};
	["Store"]: {
		_meta?: GraphQLTypes["StoreMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	description?: string | undefined,
	internalNote?: string | undefined
};
	["StoreMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductStockOrderBy"]: GraphQLTypes["ProductStockOrderBy"];
	["StoreOrderBy"]: GraphQLTypes["StoreOrderBy"];
	["OrderItem"]: {
		_meta?: GraphQLTypes["OrderItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unitPriceCents: number,
	quantity: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	product?: GraphQLTypes["Product"] | undefined,
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["OrderItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	unitPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	pickedAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["Order"]: {
		_meta?: GraphQLTypes["OrderMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	state: GraphQLTypes["OrderState"],
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Customer"] | undefined,
	channel?: GraphQLTypes["Channel"] | undefined,
	delivery?: GraphQLTypes["OrderDelivery"] | undefined,
	items: Array<GraphQLTypes["OrderItem"]>,
	internalNote?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	billingAddress?: GraphQLTypes["Address"] | undefined,
	payments: Array<GraphQLTypes["OrderPayment"]>,
	seq: number,
	doubleshotLegacyId?: string | undefined,
	venue?: GraphQLTypes["Venue"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	paginateItems: GraphQLTypes["OrderItemConnection"],
	paginatePayments: GraphQLTypes["OrderPaymentConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["OrderMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	state?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledAt?: GraphQLTypes["FieldMeta"] | undefined,
	canceledAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	channel?: GraphQLTypes["FieldMeta"] | undefined,
	delivery?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	billingAddress?: GraphQLTypes["FieldMeta"] | undefined,
	payments?: GraphQLTypes["FieldMeta"] | undefined,
	seq?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["FieldMeta"] | undefined,
	venue?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNote?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotTotalWeightGrams?: GraphQLTypes["FieldMeta"] | undefined,
	doubleShotLegacyNumber?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotAdminUrl?: GraphQLTypes["FieldMeta"] | undefined
};
	["Channel"]: {
		_meta?: GraphQLTypes["ChannelMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	disabled: boolean,
	customers: Array<GraphQLTypes["Customer"]>,
	code?: GraphQLTypes["ChannelCode"] | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Tag"]>,
	publicTags: Array<GraphQLTypes["Tag"]>,
	venue?: GraphQLTypes["Venue"] | undefined,
	paginateCustomers: GraphQLTypes["CustomerConnection"],
	paginateAddTagsOnRegistration: GraphQLTypes["TagConnection"],
	paginatePublicTags: GraphQLTypes["TagConnection"]
};
	["ChannelMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	disabled?: GraphQLTypes["FieldMeta"] | undefined,
	customers?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["FieldMeta"] | undefined,
	publicTags?: GraphQLTypes["FieldMeta"] | undefined,
	venue?: GraphQLTypes["FieldMeta"] | undefined
};
	["Venue"]: {
		_meta?: GraphQLTypes["VenueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	deadlineTemplate?: GraphQLTypes["DeadlineTemplate"] | undefined,
	timetableDays: Array<GraphQLTypes["TimetableDay"]>,
	timetableTemplates: Array<GraphQLTypes["TimetableTemplate"]>,
	staffMembers: Array<GraphQLTypes["Staff"]>,
	employeeRoles: Array<GraphQLTypes["VenueEmployeeRole"]>,
	orders: Array<GraphQLTypes["Order"]>,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	enabledUnassignReasons: Array<GraphQLTypes["UnassignReason"]>,
	channels: Array<GraphQLTypes["Channel"]>,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView: GraphQLTypes["PointOfSaleInitialView"],
	pointOfSalePreferPrintReceipt: boolean,
	timetableDaysByDate?: GraphQLTypes["TimetableDay"] | undefined,
	timetableDaysByShiftGroups?: GraphQLTypes["TimetableDay"] | undefined,
	timetableDaysByNotes?: GraphQLTypes["TimetableDay"] | undefined,
	timetableDaysByMeta?: GraphQLTypes["TimetableDay"] | undefined,
	employeeRolesByRole?: GraphQLTypes["VenueEmployeeRole"] | undefined,
	employeeRolesByEmployeePrivileges?: GraphQLTypes["VenueEmployeeRole"] | undefined,
	ordersBySeq?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	ordersByDelivery?: GraphQLTypes["Order"] | undefined,
	ordersByItems?: GraphQLTypes["Order"] | undefined,
	ordersByPayments?: GraphQLTypes["Order"] | undefined,
	channelsByCode?: GraphQLTypes["Channel"] | undefined,
	paginateTimetableDays: GraphQLTypes["TimetableDayConnection"],
	paginateTimetableTemplates: GraphQLTypes["TimetableTemplateConnection"],
	paginateStaffMembers: GraphQLTypes["StaffConnection"],
	paginateEmployeeRoles: GraphQLTypes["VenueEmployeeRoleConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"],
	paginateEnabledUnassignReasons: GraphQLTypes["UnassignReasonConnection"],
	paginateChannels: GraphQLTypes["ChannelConnection"]
};
	["VenueMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	deadlineTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	timetableDays?: GraphQLTypes["FieldMeta"] | undefined,
	timetableTemplates?: GraphQLTypes["FieldMeta"] | undefined,
	staffMembers?: GraphQLTypes["FieldMeta"] | undefined,
	employeeRoles?: GraphQLTypes["FieldMeta"] | undefined,
	orders?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined,
	enabledUnassignReasons?: GraphQLTypes["FieldMeta"] | undefined,
	channels?: GraphQLTypes["FieldMeta"] | undefined,
	address?: GraphQLTypes["FieldMeta"] | undefined,
	pointOfSalePngBase64ReceiptLogo?: GraphQLTypes["FieldMeta"] | undefined,
	pointOfSaleInitialView?: GraphQLTypes["FieldMeta"] | undefined,
	pointOfSalePreferPrintReceipt?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeadlineTemplate"]: {
		_meta?: GraphQLTypes["DeadlineTemplateMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	startDate: GraphQLTypes["Date"],
	period: number,
	cutoff?: number | undefined,
	closed?: boolean | undefined,
	venue?: GraphQLTypes["Venue"] | undefined
};
	["DeadlineTemplateMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	startDate?: GraphQLTypes["FieldMeta"] | undefined,
	period?: GraphQLTypes["FieldMeta"] | undefined,
	cutoff?: GraphQLTypes["FieldMeta"] | undefined,
	closed?: GraphQLTypes["FieldMeta"] | undefined,
	venue?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableDay"]: {
		_meta?: GraphQLTypes["TimetableDayMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	date: GraphQLTypes["Date"],
	type?: GraphQLTypes["TimetableDayType"] | undefined,
	shiftGroups: Array<GraphQLTypes["TimetableShiftGroup"]>,
	tipsCents?: number | undefined,
	notes: Array<GraphQLTypes["TimetableDayNote"]>,
	venue?: GraphQLTypes["Venue"] | undefined,
	meta?: GraphQLTypes["TimetableDayMetadata"] | undefined,
	shiftGroupsByShifts?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	shiftGroupsByQueue?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	paginateShiftGroups: GraphQLTypes["TimetableShiftGroupConnection"],
	paginateNotes: GraphQLTypes["TimetableDayNoteConnection"]
};
	["TimetableDayMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	date?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	shiftGroups?: GraphQLTypes["FieldMeta"] | undefined,
	tipsCents?: GraphQLTypes["FieldMeta"] | undefined,
	notes?: GraphQLTypes["FieldMeta"] | undefined,
	venue?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableShiftGroup"]: {
		_meta?: GraphQLTypes["TimetableShiftGroupMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	shifts: Array<GraphQLTypes["TimetableShift"]>,
	day?: GraphQLTypes["TimetableDay"] | undefined,
	type?: GraphQLTypes["TimetableEmployeeRole"] | undefined,
	position?: GraphQLTypes["TimetableShiftPosition"] | undefined,
	queue?: GraphQLTypes["EmployeeQueue"] | undefined,
	generatedByTemplate?: GraphQLTypes["TimetableTemplate"] | undefined,
	shiftsByQueueItem?: GraphQLTypes["TimetableShift"] | undefined,
	paginateShifts: GraphQLTypes["TimetableShiftConnection"]
};
	["TimetableShiftGroupMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	shifts?: GraphQLTypes["FieldMeta"] | undefined,
	day?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	position?: GraphQLTypes["FieldMeta"] | undefined,
	queue?: GraphQLTypes["FieldMeta"] | undefined,
	generatedByTemplate?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableShift"]: {
		_meta?: GraphQLTypes["TimetableShiftMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locked: boolean,
	order: number,
	assigned?: GraphQLTypes["Date"] | undefined,
	group?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	shiftsProfile?: GraphQLTypes["ShiftsProfile"] | undefined,
	queueItem?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	isOvertime: boolean
};
	["TimetableShiftMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	locked?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	assigned?: GraphQLTypes["FieldMeta"] | undefined,
	group?: GraphQLTypes["FieldMeta"] | undefined,
	shiftsProfile?: GraphQLTypes["FieldMeta"] | undefined,
	queueItem?: GraphQLTypes["FieldMeta"] | undefined,
	isOvertime?: GraphQLTypes["FieldMeta"] | undefined
};
	["ShiftsProfile"]: {
		_meta?: GraphQLTypes["ShiftsProfileMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	queueItems: Array<GraphQLTypes["EmployeeQueueItem"]>,
	staff?: GraphQLTypes["Staff"] | undefined,
	roles: Array<GraphQLTypes["TimetableEmployeeRole"]>,
	shifts: Array<GraphQLTypes["TimetableShift"]>,
	allowSelfUnassigning?: boolean | undefined,
	queueItemsByQueue?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	queueItemsByShift?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	queueItemsByUnassignInfo?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	shiftsByQueueItem?: GraphQLTypes["TimetableShift"] | undefined,
	paginateQueueItems: GraphQLTypes["EmployeeQueueItemConnection"],
	paginateRoles: GraphQLTypes["TimetableEmployeeRoleConnection"],
	paginateShifts: GraphQLTypes["TimetableShiftConnection"]
};
	["ShiftsProfileMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	queueItems?: GraphQLTypes["FieldMeta"] | undefined,
	staff?: GraphQLTypes["FieldMeta"] | undefined,
	roles?: GraphQLTypes["FieldMeta"] | undefined,
	shifts?: GraphQLTypes["FieldMeta"] | undefined,
	allowSelfUnassigning?: GraphQLTypes["FieldMeta"] | undefined
};
	["EmployeeQueueItem"]: {
		_meta?: GraphQLTypes["EmployeeQueueItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["EmployeeQueue"] | undefined,
	shiftsProfile?: GraphQLTypes["ShiftsProfile"] | undefined,
	shift?: GraphQLTypes["TimetableShift"] | undefined,
	unassignInfo?: GraphQLTypes["UnassignedShiftInfo"] | undefined
};
	["EmployeeQueueItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	start?: GraphQLTypes["FieldMeta"] | undefined,
	end?: GraphQLTypes["FieldMeta"] | undefined,
	queue?: GraphQLTypes["FieldMeta"] | undefined,
	shiftsProfile?: GraphQLTypes["FieldMeta"] | undefined,
	shift?: GraphQLTypes["FieldMeta"] | undefined,
	unassignInfo?: GraphQLTypes["FieldMeta"] | undefined
};
	["EmployeeQueue"]: {
		_meta?: GraphQLTypes["EmployeeQueueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	shiftGroup?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	items: Array<GraphQLTypes["EmployeeQueueItem"]>,
	contemberBugFix?: boolean | undefined,
	itemsByShiftsProfile?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	itemsByShift?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	itemsByUnassignInfo?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	paginateItems: GraphQLTypes["EmployeeQueueItemConnection"]
};
	["EmployeeQueueMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	shiftGroup?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	contemberBugFix?: GraphQLTypes["FieldMeta"] | undefined
};
	["EmployeeQueueItemOrderBy"]: GraphQLTypes["EmployeeQueueItemOrderBy"];
	["EmployeeQueueOrderBy"]: GraphQLTypes["EmployeeQueueOrderBy"];
	["TimetableShiftGroupOrderBy"]: GraphQLTypes["TimetableShiftGroupOrderBy"];
	["TimetableDayOrderBy"]: GraphQLTypes["TimetableDayOrderBy"];
	["VenueOrderBy"]: GraphQLTypes["VenueOrderBy"];
	["DeadlineTemplateOrderBy"]: GraphQLTypes["DeadlineTemplateOrderBy"];
	["TimetableDayMetadataOrderBy"]: GraphQLTypes["TimetableDayMetadataOrderBy"];
	["TimetableEmployeeRoleOrderBy"]: GraphQLTypes["TimetableEmployeeRoleOrderBy"];
	["TimetableShiftPositionOrderBy"]: GraphQLTypes["TimetableShiftPositionOrderBy"];
	["TimetableTemplateOrderBy"]: GraphQLTypes["TimetableTemplateOrderBy"];
	["ShiftsProfileOrderBy"]: GraphQLTypes["ShiftsProfileOrderBy"];
	["StaffOrderBy"]: GraphQLTypes["StaffOrderBy"];
	["TimetableShiftOrderBy"]: GraphQLTypes["TimetableShiftOrderBy"];
	["UnassignedShiftInfoOrderBy"]: GraphQLTypes["UnassignedShiftInfoOrderBy"];
	["UnassignReasonOrderBy"]: GraphQLTypes["UnassignReasonOrderBy"];
	["EmployeeQueueItemsByShiftsProfileUniqueWhere"]: GraphQLTypes["EmployeeQueueItemsByShiftsProfileUniqueWhere"];
	["ShiftsProfileUniqueWhere"]: GraphQLTypes["ShiftsProfileUniqueWhere"];
	["EmployeeQueueItemUniqueWhere"]: GraphQLTypes["EmployeeQueueItemUniqueWhere"];
	["EmployeeQueueUniqueWhere"]: GraphQLTypes["EmployeeQueueUniqueWhere"];
	["TimetableShiftGroupUniqueWhere"]: GraphQLTypes["TimetableShiftGroupUniqueWhere"];
	["TimetableShiftUniqueWhere"]: GraphQLTypes["TimetableShiftUniqueWhere"];
	["UnassignedShiftInfoUniqueWhere"]: GraphQLTypes["UnassignedShiftInfoUniqueWhere"];
	["StaffUniqueWhere"]: GraphQLTypes["StaffUniqueWhere"];
	["EmployeeQueueItemsByShiftUniqueWhere"]: GraphQLTypes["EmployeeQueueItemsByShiftUniqueWhere"];
	["EmployeeQueueItemsByUnassignInfoUniqueWhere"]: GraphQLTypes["EmployeeQueueItemsByUnassignInfoUniqueWhere"];
	["EmployeeQueueItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["EmployeeQueueItemEdge"]>
};
	["EmployeeQueueItemEdge"]: {
		node: GraphQLTypes["EmployeeQueueItem"]
};
	["UnassignedShiftInfo"]: {
		_meta?: GraphQLTypes["UnassignedShiftInfoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	hoursMissed?: number | undefined,
	queueItem?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	legacyReason?: GraphQLTypes["UnassignedInfoReason"] | undefined,
	reason?: GraphQLTypes["UnassignReason"] | undefined
};
	["UnassignedShiftInfoMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	hoursMissed?: GraphQLTypes["FieldMeta"] | undefined,
	queueItem?: GraphQLTypes["FieldMeta"] | undefined,
	legacyReason?: GraphQLTypes["FieldMeta"] | undefined,
	reason?: GraphQLTypes["FieldMeta"] | undefined
};
	["UnassignReason"]: {
		_meta?: GraphQLTypes["UnassignReasonMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	name: string,
	venues: Array<GraphQLTypes["Venue"]>,
	paginateVenues: GraphQLTypes["VenueConnection"]
};
	["UnassignReasonMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	venues?: GraphQLTypes["FieldMeta"] | undefined
};
	["VenueConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VenueEdge"]>
};
	["VenueEdge"]: {
		node: GraphQLTypes["Venue"]
};
	["Staff"]: {
		_meta?: GraphQLTypes["StaffMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	shiftsProfile?: GraphQLTypes["ShiftsProfile"] | undefined,
	photo?: GraphQLTypes["Image"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	venues: Array<GraphQLTypes["Venue"]>,
	paginateVenues: GraphQLTypes["VenueConnection"]
};
	["StaffMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	shiftsProfile?: GraphQLTypes["FieldMeta"] | undefined,
	photo?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	venues?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableEmployeeRole"]: {
		_meta?: GraphQLTypes["TimetableEmployeeRoleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	shiftGroups: Array<GraphQLTypes["TimetableShiftGroup"]>,
	shiftsProfiles: Array<GraphQLTypes["ShiftsProfile"]>,
	name: string,
	timetableTemplates: Array<GraphQLTypes["TimetableTemplate"]>,
	color: string,
	shiftGroupsByShifts?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	shiftGroupsByQueue?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	paginateShiftGroups: GraphQLTypes["TimetableShiftGroupConnection"],
	paginateShiftsProfiles: GraphQLTypes["ShiftsProfileConnection"],
	paginateTimetableTemplates: GraphQLTypes["TimetableTemplateConnection"]
};
	["TimetableEmployeeRoleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	shiftGroups?: GraphQLTypes["FieldMeta"] | undefined,
	shiftsProfiles?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	timetableTemplates?: GraphQLTypes["FieldMeta"] | undefined,
	color?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableTemplate"]: {
		_meta?: GraphQLTypes["TimetableTemplateMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	startDate: GraphQLTypes["Date"],
	period?: number | undefined,
	slots?: number | undefined,
	position?: GraphQLTypes["TimetableShiftPosition"] | undefined,
	internalName?: string | undefined,
	venue?: GraphQLTypes["Venue"] | undefined,
	employeeRole?: GraphQLTypes["TimetableEmployeeRole"] | undefined,
	workdaysOnly?: boolean | undefined,
	monday: boolean,
	tuesday: boolean,
	wednesday: boolean,
	thursday: boolean,
	friday: boolean,
	saturday: boolean,
	sunday: boolean,
	type: GraphQLTypes["TemplateType"]
};
	["TimetableTemplateMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	startDate?: GraphQLTypes["FieldMeta"] | undefined,
	period?: GraphQLTypes["FieldMeta"] | undefined,
	slots?: GraphQLTypes["FieldMeta"] | undefined,
	position?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	venue?: GraphQLTypes["FieldMeta"] | undefined,
	employeeRole?: GraphQLTypes["FieldMeta"] | undefined,
	workdaysOnly?: GraphQLTypes["FieldMeta"] | undefined,
	monday?: GraphQLTypes["FieldMeta"] | undefined,
	tuesday?: GraphQLTypes["FieldMeta"] | undefined,
	wednesday?: GraphQLTypes["FieldMeta"] | undefined,
	thursday?: GraphQLTypes["FieldMeta"] | undefined,
	friday?: GraphQLTypes["FieldMeta"] | undefined,
	saturday?: GraphQLTypes["FieldMeta"] | undefined,
	sunday?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableShiftPosition"]: {
		_meta?: GraphQLTypes["TimetableShiftPositionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	start?: string | undefined,
	end?: string | undefined,
	shiftGroups: Array<GraphQLTypes["TimetableShiftGroup"]>,
	timetableTemplates: Array<GraphQLTypes["TimetableTemplate"]>,
	isDynamic: boolean,
	shiftGroupsByShifts?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	shiftGroupsByQueue?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	paginateShiftGroups: GraphQLTypes["TimetableShiftGroupConnection"],
	paginateTimetableTemplates: GraphQLTypes["TimetableTemplateConnection"]
};
	["TimetableShiftPositionMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	start?: GraphQLTypes["FieldMeta"] | undefined,
	end?: GraphQLTypes["FieldMeta"] | undefined,
	shiftGroups?: GraphQLTypes["FieldMeta"] | undefined,
	timetableTemplates?: GraphQLTypes["FieldMeta"] | undefined,
	isDynamic?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableShiftPositionShiftGroupsByShiftsUniqueWhere"]: GraphQLTypes["TimetableShiftPositionShiftGroupsByShiftsUniqueWhere"];
	["TimetableShiftPositionShiftGroupsByQueueUniqueWhere"]: GraphQLTypes["TimetableShiftPositionShiftGroupsByQueueUniqueWhere"];
	["TimetableShiftGroupConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableShiftGroupEdge"]>
};
	["TimetableShiftGroupEdge"]: {
		node: GraphQLTypes["TimetableShiftGroup"]
};
	["TimetableTemplateConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableTemplateEdge"]>
};
	["TimetableTemplateEdge"]: {
		node: GraphQLTypes["TimetableTemplate"]
};
	["TimetableEmployeeRoleShiftGroupsByShiftsUniqueWhere"]: GraphQLTypes["TimetableEmployeeRoleShiftGroupsByShiftsUniqueWhere"];
	["TimetableEmployeeRoleShiftGroupsByQueueUniqueWhere"]: GraphQLTypes["TimetableEmployeeRoleShiftGroupsByQueueUniqueWhere"];
	["ShiftsProfileConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ShiftsProfileEdge"]>
};
	["ShiftsProfileEdge"]: {
		node: GraphQLTypes["ShiftsProfile"]
};
	["ShiftsProfileQueueItemsByQueueUniqueWhere"]: GraphQLTypes["ShiftsProfileQueueItemsByQueueUniqueWhere"];
	["ShiftsProfileQueueItemsByShiftUniqueWhere"]: GraphQLTypes["ShiftsProfileQueueItemsByShiftUniqueWhere"];
	["ShiftsProfileQueueItemsByUnassignInfoUniqueWhere"]: GraphQLTypes["ShiftsProfileQueueItemsByUnassignInfoUniqueWhere"];
	["ShiftsProfileShiftsByQueueItemUniqueWhere"]: GraphQLTypes["ShiftsProfileShiftsByQueueItemUniqueWhere"];
	["TimetableEmployeeRoleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableEmployeeRoleEdge"]>
};
	["TimetableEmployeeRoleEdge"]: {
		node: GraphQLTypes["TimetableEmployeeRole"]
};
	["TimetableShiftConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableShiftEdge"]>
};
	["TimetableShiftEdge"]: {
		node: GraphQLTypes["TimetableShift"]
};
	["TimetableShiftGroupShiftsByQueueItemUniqueWhere"]: GraphQLTypes["TimetableShiftGroupShiftsByQueueItemUniqueWhere"];
	["TimetableDayNote"]: {
		_meta?: GraphQLTypes["TimetableDayNoteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	note: string,
	day?: GraphQLTypes["TimetableDay"] | undefined,
	role?: GraphQLTypes["TimetableEmployeeRole"] | undefined
};
	["TimetableDayNoteMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	day?: GraphQLTypes["FieldMeta"] | undefined,
	role?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableDayNoteOrderBy"]: GraphQLTypes["TimetableDayNoteOrderBy"];
	["TimetableDayMetadata"]: {
		_meta?: GraphQLTypes["TimetableDayMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	numberOfWorkers: number,
	tipsTotalCents: number,
	totalMinutes: number,
	tipsCentsPerMinute: number,
	day?: GraphQLTypes["TimetableDay"] | undefined
};
	["TimetableDayMetadataMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	numberOfWorkers?: GraphQLTypes["FieldMeta"] | undefined,
	tipsTotalCents?: GraphQLTypes["FieldMeta"] | undefined,
	totalMinutes?: GraphQLTypes["FieldMeta"] | undefined,
	tipsCentsPerMinute?: GraphQLTypes["FieldMeta"] | undefined,
	day?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableDayShiftGroupsByShiftsUniqueWhere"]: GraphQLTypes["TimetableDayShiftGroupsByShiftsUniqueWhere"];
	["TimetableDayShiftGroupsByQueueUniqueWhere"]: GraphQLTypes["TimetableDayShiftGroupsByQueueUniqueWhere"];
	["TimetableDayNoteConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableDayNoteEdge"]>
};
	["TimetableDayNoteEdge"]: {
		node: GraphQLTypes["TimetableDayNote"]
};
	["VenueEmployeeRole"]: {
		_meta?: GraphQLTypes["VenueEmployeeRoleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	employeePrivileges?: GraphQLTypes["TimetablesEmployeePrivileges"] | undefined,
	venue?: GraphQLTypes["Venue"] | undefined,
	role?: GraphQLTypes["TimetableEmployeeRole"] | undefined,
	getsTipsShare: boolean,
	notesEnabled: boolean
};
	["VenueEmployeeRoleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	employeePrivileges?: GraphQLTypes["FieldMeta"] | undefined,
	venue?: GraphQLTypes["FieldMeta"] | undefined,
	role?: GraphQLTypes["FieldMeta"] | undefined,
	getsTipsShare?: GraphQLTypes["FieldMeta"] | undefined,
	notesEnabled?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetablesEmployeePrivileges"]: {
		_meta?: GraphQLTypes["TimetablesEmployeePrivilegesMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	venueEmployeeRole?: GraphQLTypes["VenueEmployeeRole"] | undefined,
	read: boolean,
	unassign: boolean,
	assign: boolean
};
	["TimetablesEmployeePrivilegesMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	venueEmployeeRole?: GraphQLTypes["FieldMeta"] | undefined,
	read?: GraphQLTypes["FieldMeta"] | undefined,
	unassign?: GraphQLTypes["FieldMeta"] | undefined,
	assign?: GraphQLTypes["FieldMeta"] | undefined
};
	["VenueEmployeeRoleOrderBy"]: GraphQLTypes["VenueEmployeeRoleOrderBy"];
	["TimetablesEmployeePrivilegesOrderBy"]: GraphQLTypes["TimetablesEmployeePrivilegesOrderBy"];
	["OrderOrderBy"]: GraphQLTypes["OrderOrderBy"];
	["ChannelOrderBy"]: GraphQLTypes["ChannelOrderBy"];
	["OrderDeliveryOrderBy"]: GraphQLTypes["OrderDeliveryOrderBy"];
	["DeliveryWaypointOrderBy"]: GraphQLTypes["DeliveryWaypointOrderBy"];
	["DeliveryRouteOrderBy"]: GraphQLTypes["DeliveryRouteOrderBy"];
	["FulfillmentNote"]: {
		_meta?: GraphQLTypes["FulfillmentNoteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	note: string,
	products: Array<GraphQLTypes["Product"]>,
	tags: Array<GraphQLTypes["Tag"]>,
	venues: Array<GraphQLTypes["Venue"]>,
	orderItems: Array<GraphQLTypes["OrderItem"]>,
	orders: Array<GraphQLTypes["Order"]>,
	highlight: boolean,
	createdAt: GraphQLTypes["DateTime"],
	emoji?: string | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateTags: GraphQLTypes["TagConnection"],
	paginateVenues: GraphQLTypes["VenueConnection"],
	paginateOrderItems: GraphQLTypes["OrderItemConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"]
};
	["FulfillmentNoteMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	venues?: GraphQLTypes["FieldMeta"] | undefined,
	orderItems?: GraphQLTypes["FieldMeta"] | undefined,
	orders?: GraphQLTypes["FieldMeta"] | undefined,
	highlight?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	emoji?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderItemOrderBy"]: GraphQLTypes["OrderItemOrderBy"];
	["OrderItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderItemEdge"]>
};
	["OrderItemEdge"]: {
		node: GraphQLTypes["OrderItem"]
};
	["OrderConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderEdge"]>
};
	["OrderEdge"]: {
		node: GraphQLTypes["Order"]
};
	["FulfillmentNoteOrderBy"]: GraphQLTypes["FulfillmentNoteOrderBy"];
	["VenueTimetableDaysByDateUniqueWhere"]: GraphQLTypes["VenueTimetableDaysByDateUniqueWhere"];
	["VenueTimetableDaysByShiftGroupsUniqueWhere"]: GraphQLTypes["VenueTimetableDaysByShiftGroupsUniqueWhere"];
	["VenueTimetableDaysByNotesUniqueWhere"]: GraphQLTypes["VenueTimetableDaysByNotesUniqueWhere"];
	["TimetableDayNoteUniqueWhere"]: GraphQLTypes["TimetableDayNoteUniqueWhere"];
	["VenueTimetableDaysByMetaUniqueWhere"]: GraphQLTypes["VenueTimetableDaysByMetaUniqueWhere"];
	["TimetableDayMetadataUniqueWhere"]: GraphQLTypes["TimetableDayMetadataUniqueWhere"];
	["TimetableDayUniqueWhere"]: GraphQLTypes["TimetableDayUniqueWhere"];
	["VenueUniqueWhere"]: GraphQLTypes["VenueUniqueWhere"];
	["DeadlineTemplateUniqueWhere"]: GraphQLTypes["DeadlineTemplateUniqueWhere"];
	["TimetableTemplateUniqueWhere"]: GraphQLTypes["TimetableTemplateUniqueWhere"];
	["VenueEmployeeRoleUniqueWhere"]: GraphQLTypes["VenueEmployeeRoleUniqueWhere"];
	["TimetableEmployeeRoleUniqueWhere"]: GraphQLTypes["TimetableEmployeeRoleUniqueWhere"];
	["TimetablesEmployeePrivilegesUniqueWhere"]: GraphQLTypes["TimetablesEmployeePrivilegesUniqueWhere"];
	["ChannelUniqueWhere"]: GraphQLTypes["ChannelUniqueWhere"];
	["VenueEmployeeRolesByRoleUniqueWhere"]: GraphQLTypes["VenueEmployeeRolesByRoleUniqueWhere"];
	["VenueEmployeeRolesByEmployeePrivilegesUniqueWhere"]: GraphQLTypes["VenueEmployeeRolesByEmployeePrivilegesUniqueWhere"];
	["VenueOrdersBySeqUniqueWhere"]: GraphQLTypes["VenueOrdersBySeqUniqueWhere"];
	["VenueOrdersByDoubleshotLegacyIdUniqueWhere"]: GraphQLTypes["VenueOrdersByDoubleshotLegacyIdUniqueWhere"];
	["VenueOrdersByDoubleShotLegacyNumberUniqueWhere"]: GraphQLTypes["VenueOrdersByDoubleShotLegacyNumberUniqueWhere"];
	["VenueOrdersByDeliveryUniqueWhere"]: GraphQLTypes["VenueOrdersByDeliveryUniqueWhere"];
	["VenueOrdersByItemsUniqueWhere"]: GraphQLTypes["VenueOrdersByItemsUniqueWhere"];
	["VenueOrdersByPaymentsUniqueWhere"]: GraphQLTypes["VenueOrdersByPaymentsUniqueWhere"];
	["VenueChannelsByCodeUniqueWhere"]: GraphQLTypes["VenueChannelsByCodeUniqueWhere"];
	["TimetableDayConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableDayEdge"]>
};
	["TimetableDayEdge"]: {
		node: GraphQLTypes["TimetableDay"]
};
	["StaffConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StaffEdge"]>
};
	["StaffEdge"]: {
		node: GraphQLTypes["Staff"]
};
	["VenueEmployeeRoleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VenueEmployeeRoleEdge"]>
};
	["VenueEmployeeRoleEdge"]: {
		node: GraphQLTypes["VenueEmployeeRole"]
};
	["FulfillmentNoteConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FulfillmentNoteEdge"]>
};
	["FulfillmentNoteEdge"]: {
		node: GraphQLTypes["FulfillmentNote"]
};
	["UnassignReasonConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["UnassignReasonEdge"]>
};
	["UnassignReasonEdge"]: {
		node: GraphQLTypes["UnassignReason"]
};
	["ChannelConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ChannelEdge"]>
};
	["ChannelEdge"]: {
		node: GraphQLTypes["Channel"]
};
	["CustomerConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerEdge"]>
};
	["CustomerEdge"]: {
		node: GraphQLTypes["Customer"]
};
	["OrderDelivery"]: {
		_meta?: GraphQLTypes["OrderDeliveryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	priceCents: number,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["DeliveryMethod"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state: GraphQLTypes["OrderDeliveryState"],
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	deliveryWaypoint?: GraphQLTypes["DeliveryWaypoint"] | undefined,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	address?: GraphQLTypes["Address"] | undefined,
	trackingCode?: string | undefined
};
	["OrderDeliveryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	deliveredAt?: GraphQLTypes["FieldMeta"] | undefined,
	method?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	expectedAt?: GraphQLTypes["FieldMeta"] | undefined,
	state?: GraphQLTypes["FieldMeta"] | undefined,
	loadingDurationHours?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchAt?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	window?: GraphQLTypes["FieldMeta"] | undefined,
	expectedEndAt?: GraphQLTypes["FieldMeta"] | undefined,
	processingAt?: GraphQLTypes["FieldMeta"] | undefined,
	deliveringAt?: GraphQLTypes["FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryWaypoint?: GraphQLTypes["FieldMeta"] | undefined,
	prepareAt?: GraphQLTypes["FieldMeta"] | undefined,
	pickedAt?: GraphQLTypes["FieldMeta"] | undefined,
	address?: GraphQLTypes["FieldMeta"] | undefined,
	trackingCode?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryWaypoint"]: {
		_meta?: GraphQLTypes["DeliveryWaypointMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	estimatedArrival?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	route?: GraphQLTypes["DeliveryRoute"] | undefined,
	sortingOrder: number,
	delivery?: GraphQLTypes["OrderDelivery"] | undefined,
	estimatedDistance?: number | undefined,
	estimatedDuration?: number | undefined,
	originDeparture?: GraphQLTypes["DateTime"] | undefined,
	originAddress?: string | undefined,
	transitMode?: string | undefined
};
	["DeliveryWaypointMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	estimatedArrival?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	route?: GraphQLTypes["FieldMeta"] | undefined,
	sortingOrder?: GraphQLTypes["FieldMeta"] | undefined,
	delivery?: GraphQLTypes["FieldMeta"] | undefined,
	estimatedDistance?: GraphQLTypes["FieldMeta"] | undefined,
	estimatedDuration?: GraphQLTypes["FieldMeta"] | undefined,
	originDeparture?: GraphQLTypes["FieldMeta"] | undefined,
	originAddress?: GraphQLTypes["FieldMeta"] | undefined,
	transitMode?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryRoute"]: {
		_meta?: GraphQLTypes["DeliveryRouteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	waypoints: Array<GraphQLTypes["DeliveryWaypoint"]>,
	dispatchAt: string,
	waypointsByDelivery?: GraphQLTypes["DeliveryWaypoint"] | undefined,
	paginateWaypoints: GraphQLTypes["DeliveryWaypointConnection"]
};
	["DeliveryRouteMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	waypoints?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchAt?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryRouteWaypointsByDeliveryUniqueWhere"]: GraphQLTypes["DeliveryRouteWaypointsByDeliveryUniqueWhere"];
	["DeliveryWaypointConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryWaypointEdge"]>
};
	["DeliveryWaypointEdge"]: {
		node: GraphQLTypes["DeliveryWaypoint"]
};
	["OrderPayment"]: {
		_meta?: GraphQLTypes["OrderPaymentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	data?: GraphQLTypes["Json"] | undefined,
	paymentCents: number,
	method?: GraphQLTypes["PaymentMethod"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	state: GraphQLTypes["OrderPaymentState"],
	approvedAt?: GraphQLTypes["DateTime"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	walletType?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicReceiptUrl?: string | undefined,
	priceCents?: number | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	otherCurrencyPriceCents?: string | undefined
};
	["OrderPaymentMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	data?: GraphQLTypes["FieldMeta"] | undefined,
	paymentCents?: GraphQLTypes["FieldMeta"] | undefined,
	method?: GraphQLTypes["FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["FieldMeta"] | undefined,
	state?: GraphQLTypes["FieldMeta"] | undefined,
	approvedAt?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	walletType?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	publicReceiptUrl?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	otherCurrencyPriceCents?: GraphQLTypes["FieldMeta"] | undefined
};
	["Json"]:any;
	["OrderPaymentOrderBy"]: GraphQLTypes["OrderPaymentOrderBy"];
	["OrderPaymentConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderPaymentEdge"]>
};
	["OrderPaymentEdge"]: {
		node: GraphQLTypes["OrderPayment"]
};
	["ProductMetadata"]: {
		_meta?: GraphQLTypes["ProductMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	archived: boolean,
	available: boolean,
	availableQuantity: number,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductMetadataMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	archived?: GraphQLTypes["FieldMeta"] | undefined,
	available?: GraphQLTypes["FieldMeta"] | undefined,
	availableQuantity?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductStocksByStoreUniqueWhere"]: GraphQLTypes["ProductStocksByStoreUniqueWhere"];
	["ProductLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductLocalesByLocaleUniqueWhere"];
	["ProductLocalesByFeatureListUniqueWhere"]: GraphQLTypes["ProductLocalesByFeatureListUniqueWhere"];
	["ProductStockConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductStockEdge"]>
};
	["ProductStockEdge"]: {
		node: GraphQLTypes["ProductStock"]
};
	["CustomerName"]: {
		_meta?: GraphQLTypes["CustomerNameMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	nominative: string,
	vocative?: string | undefined,
	customer?: GraphQLTypes["Customer"] | undefined
};
	["CustomerNameMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	nominative?: GraphQLTypes["FieldMeta"] | undefined,
	vocative?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerMetadata"]: {
		_meta?: GraphQLTypes["CustomerMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isBusiness: boolean,
	name?: string | undefined,
	fallbackName: string,
	remainingCreditsCents: number,
	ordersTotalCents: number,
	customerProfileUrl: string,
	customer?: GraphQLTypes["Customer"] | undefined
};
	["CustomerMetadataMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	isBusiness?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	fallbackName?: GraphQLTypes["FieldMeta"] | undefined,
	remainingCreditsCents?: GraphQLTypes["FieldMeta"] | undefined,
	ordersTotalCents?: GraphQLTypes["FieldMeta"] | undefined,
	customerProfileUrl?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerAddressesByBillingAddressOfOrdersUniqueWhere"]: GraphQLTypes["CustomerAddressesByBillingAddressOfOrdersUniqueWhere"];
	["CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: GraphQLTypes["CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"];
	["CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: GraphQLTypes["CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"];
	["CustomerOrdersBySeqUniqueWhere"]: GraphQLTypes["CustomerOrdersBySeqUniqueWhere"];
	["CustomerOrdersByDoubleshotLegacyIdUniqueWhere"]: GraphQLTypes["CustomerOrdersByDoubleshotLegacyIdUniqueWhere"];
	["CustomerOrdersByDoubleShotLegacyNumberUniqueWhere"]: GraphQLTypes["CustomerOrdersByDoubleShotLegacyNumberUniqueWhere"];
	["CustomerOrdersByDeliveryUniqueWhere"]: GraphQLTypes["CustomerOrdersByDeliveryUniqueWhere"];
	["CustomerOrdersByItemsUniqueWhere"]: GraphQLTypes["CustomerOrdersByItemsUniqueWhere"];
	["CustomerOrdersByPaymentsUniqueWhere"]: GraphQLTypes["CustomerOrdersByPaymentsUniqueWhere"];
	["Country"]: {
		_meta?: GraphQLTypes["CountryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	order: number
};
	["CountryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["AddressBillingAddressOfOrdersBySeqUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersBySeqUniqueWhere"];
	["AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere"];
	["AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere"];
	["AddressBillingAddressOfOrdersByDeliveryUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersByDeliveryUniqueWhere"];
	["AddressBillingAddressOfOrdersByItemsUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersByItemsUniqueWhere"];
	["AddressBillingAddressOfOrdersByPaymentsUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersByPaymentsUniqueWhere"];
	["AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere"]: GraphQLTypes["AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere"];
	["AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere"]: GraphQLTypes["AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere"];
	["AddressDeliveryAddressOfOrderDeliveriesByDeliveryWaypointUniqueWhere"]: GraphQLTypes["AddressDeliveryAddressOfOrderDeliveriesByDeliveryWaypointUniqueWhere"];
	["OrderDeliveryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderDeliveryEdge"]>
};
	["OrderDeliveryEdge"]: {
		node: GraphQLTypes["OrderDelivery"]
};
	["CountryUniqueWhere"]: GraphQLTypes["CountryUniqueWhere"];
	["CountryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CountryEdge"]>
};
	["CountryEdge"]: {
		node: GraphQLTypes["Country"]
};
	["PaymentMethodUniqueWhere"]: GraphQLTypes["PaymentMethodUniqueWhere"];
	["VatRateUniqueWhere"]: GraphQLTypes["VatRateUniqueWhere"];
	["VatRateConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VatRateEdge"]>
};
	["VatRateEdge"]: {
		node: GraphQLTypes["VatRate"]
};
	["ProductPackingConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductPackingEdge"]>
};
	["ProductPackingEdge"]: {
		node: GraphQLTypes["ProductPacking"]
};
	["ProductRecipeConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductRecipeEdge"]>
};
	["ProductRecipeEdge"]: {
		node: GraphQLTypes["ProductRecipe"]
};
	["StoreConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StoreEdge"]>
};
	["StoreEdge"]: {
		node: GraphQLTypes["Store"]
};
	["TagUniqueWhere"]: GraphQLTypes["TagUniqueWhere"];
	["ImageConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageEdge"]>
};
	["ImageEdge"]: {
		node: GraphQLTypes["Image"]
};
	["ProductListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductListEdge"]>
};
	["ProductListEdge"]: {
		node: GraphQLTypes["ProductList"]
};
	["ProductCategoryListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListEdge"]>
};
	["ProductCategoryListEdge"]: {
		node: GraphQLTypes["ProductCategoryList"]
};
	["LocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LocaleEdge"]>
};
	["LocaleEdge"]: {
		node: GraphQLTypes["Locale"]
};
	["CustomerNameConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerNameEdge"]>
};
	["CustomerNameEdge"]: {
		node: GraphQLTypes["CustomerName"]
};
	["ProductIngredientItemQuantityConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientItemQuantityEdge"]>
};
	["ProductIngredientItemQuantityEdge"]: {
		node: GraphQLTypes["ProductIngredientItemQuantity"]
};
	["ProductIngredientItemQuantityUnitUniqueWhere"]: GraphQLTypes["ProductIngredientItemQuantityUnitUniqueWhere"];
	["ProductIngredientItemQuantityUnitConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientItemQuantityUnitEdge"]>
};
	["ProductIngredientItemQuantityUnitEdge"]: {
		node: GraphQLTypes["ProductIngredientItemQuantityUnit"]
};
	["ProductIngredientRating"]: {
		_meta?: GraphQLTypes["ProductIngredientRatingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	name: string,
	icon?: GraphQLTypes["Image"] | undefined
};
	["ProductIngredientRatingMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientRatingUniqueWhere"]: GraphQLTypes["ProductIngredientRatingUniqueWhere"];
	["ProductIngredientRatingWhere"]: GraphQLTypes["ProductIngredientRatingWhere"];
	["ProductIngredientRatingOrderBy"]: GraphQLTypes["ProductIngredientRatingOrderBy"];
	["ProductIngredientRatingConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientRatingEdge"]>
};
	["ProductIngredientRatingEdge"]: {
		node: GraphQLTypes["ProductIngredientRating"]
};
	["BlogConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogEdge"]>
};
	["BlogEdge"]: {
		node: GraphQLTypes["Blog"]
};
	["BlogPostConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostEdge"]>
};
	["BlogPostEdge"]: {
		node: GraphQLTypes["BlogPost"]
};
	["ColorPalleteUniqueWhere"]: GraphQLTypes["ColorPalleteUniqueWhere"];
	["ColorPalleteConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ColorPalleteEdge"]>
};
	["ColorPalleteEdge"]: {
		node: GraphQLTypes["ColorPallete"]
};
	["ContentConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentEdge"]>
};
	["ContentEdge"]: {
		node: GraphQLTypes["Content"]
};
	["BlogPostListUniqueWhere"]: GraphQLTypes["BlogPostListUniqueWhere"];
	["BlogPostListItemUniqueWhere"]: GraphQLTypes["BlogPostListItemUniqueWhere"];
	["BlogPostListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostListEdge"]>
};
	["BlogPostListEdge"]: {
		node: GraphQLTypes["BlogPostList"]
};
	["LinkListUniqueWhere"]: GraphQLTypes["LinkListUniqueWhere"];
	["LinkListItemUniqueWhere"]: GraphQLTypes["LinkListItemUniqueWhere"];
	["LinkListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkListEdge"]>
};
	["LinkListEdge"]: {
		node: GraphQLTypes["LinkList"]
};
	["CoverConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CoverEdge"]>
};
	["CoverEdge"]: {
		node: GraphQLTypes["Cover"]
};
	["LinkConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkEdge"]>
};
	["LinkEdge"]: {
		node: GraphQLTypes["Link"]
};
	["LinkableConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkableEdge"]>
};
	["LinkableEdge"]: {
		node: GraphQLTypes["Linkable"]
};
	["MediumUniqueWhere"]: GraphQLTypes["MediumUniqueWhere"];
	["MediumConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MediumEdge"]>
};
	["MediumEdge"]: {
		node: GraphQLTypes["Medium"]
};
	["VideoUniqueWhere"]: GraphQLTypes["VideoUniqueWhere"];
	["VideoConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VideoEdge"]>
};
	["VideoEdge"]: {
		node: GraphQLTypes["Video"]
};
	["SeoConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SeoEdge"]>
};
	["SeoEdge"]: {
		node: GraphQLTypes["Seo"]
};
	["TranslationDomainConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationDomainEdge"]>
};
	["TranslationDomainEdge"]: {
		node: GraphQLTypes["TranslationDomain"]
};
	["TranslationCataloguesIdentifierConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCataloguesIdentifierEdge"]>
};
	["TranslationCataloguesIdentifierEdge"]: {
		node: GraphQLTypes["TranslationCataloguesIdentifier"]
};
	["SocialsAndAppsUniqueWhere"]: GraphQLTypes["SocialsAndAppsUniqueWhere"];
	["SocialsAndAppsConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SocialsAndAppsEdge"]>
};
	["SocialsAndAppsEdge"]: {
		node: GraphQLTypes["SocialsAndApps"]
};
	["ProductGridUniqueWhere"]: GraphQLTypes["ProductGridUniqueWhere"];
	["ProductGridCategoryUniqueWhere"]: GraphQLTypes["ProductGridCategoryUniqueWhere"];
	["ProductGridConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridEdge"]>
};
	["ProductGridEdge"]: {
		node: GraphQLTypes["ProductGrid"]
};
	["GalleryUniqueWhere"]: GraphQLTypes["GalleryUniqueWhere"];
	["GalleryItemUniqueWhere"]: GraphQLTypes["GalleryItemUniqueWhere"];
	["GalleryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GalleryEdge"]>
};
	["GalleryEdge"]: {
		node: GraphQLTypes["Gallery"]
};
	["HeroUniqueWhere"]: GraphQLTypes["HeroUniqueWhere"];
	["HeroConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HeroEdge"]>
};
	["HeroEdge"]: {
		node: GraphQLTypes["Hero"]
};
	["PageConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PageEdge"]>
};
	["PageEdge"]: {
		node: GraphQLTypes["Page"]
};
	["DeadlineTemplateConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeadlineTemplateEdge"]>
};
	["DeadlineTemplateEdge"]: {
		node: GraphQLTypes["DeadlineTemplate"]
};
	["EmployeeQueueConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["EmployeeQueueEdge"]>
};
	["EmployeeQueueEdge"]: {
		node: GraphQLTypes["EmployeeQueue"]
};
	["_ValidationResult"]: {
		valid: boolean,
	errors: Array<GraphQLTypes["_ValidationError"]>
};
	["_ValidationError"]: {
		path: Array<GraphQLTypes["_PathFragment"]>,
	message: GraphQLTypes["_ValidationMessage"]
};
	["_PathFragment"]:ModelTypes["_FieldPathFragment"] | ModelTypes["_IndexPathFragment"];
	["_FieldPathFragment"]: {
		field: string
};
	["_IndexPathFragment"]: {
		index: number,
	alias?: string | undefined
};
	["_ValidationMessage"]: {
		text: string
};
	["EmployeeQueueCreateInput"]: GraphQLTypes["EmployeeQueueCreateInput"];
	["EmployeeQueueCreateItemsEntityRelationInput"]: GraphQLTypes["EmployeeQueueCreateItemsEntityRelationInput"];
	["EmployeeQueueItemWithoutQueueCreateInput"]: GraphQLTypes["EmployeeQueueItemWithoutQueueCreateInput"];
	["EmployeeQueueItemCreateShiftsProfileEntityRelationInput"]: GraphQLTypes["EmployeeQueueItemCreateShiftsProfileEntityRelationInput"];
	["EmployeeQueueItemCreateUnassignInfoEntityRelationInput"]: GraphQLTypes["EmployeeQueueItemCreateUnassignInfoEntityRelationInput"];
	["UnassignedShiftInfoWithoutQueueItemCreateInput"]: GraphQLTypes["UnassignedShiftInfoWithoutQueueItemCreateInput"];
	["UnassignedShiftInfoCreateReasonEntityRelationInput"]: GraphQLTypes["UnassignedShiftInfoCreateReasonEntityRelationInput"];
	["UnassignReasonUniqueWhere"]: GraphQLTypes["UnassignReasonUniqueWhere"];
	["EmployeeQueueUpdateInput"]: GraphQLTypes["EmployeeQueueUpdateInput"];
	["EmployeeQueueUpdateItemsEntityRelationInput"]: GraphQLTypes["EmployeeQueueUpdateItemsEntityRelationInput"];
	["EmployeeQueueUpdateItemsRelationInput"]: GraphQLTypes["EmployeeQueueUpdateItemsRelationInput"];
	["EmployeeQueueItemWithoutQueueUpdateInput"]: GraphQLTypes["EmployeeQueueItemWithoutQueueUpdateInput"];
	["EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"]: GraphQLTypes["EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"];
	["ShiftsProfileWithoutQueueItemsUpdateInput"]: GraphQLTypes["ShiftsProfileWithoutQueueItemsUpdateInput"];
	["ShiftsProfileUpdateShiftsEntityRelationInput"]: GraphQLTypes["ShiftsProfileUpdateShiftsEntityRelationInput"];
	["ShiftsProfileUpdateShiftsRelationInput"]: GraphQLTypes["ShiftsProfileUpdateShiftsRelationInput"];
	["TimetableShiftWithoutShiftsProfileUpdateInput"]: GraphQLTypes["TimetableShiftWithoutShiftsProfileUpdateInput"];
	["TimetableShiftUpdateGroupEntityRelationInput"]: GraphQLTypes["TimetableShiftUpdateGroupEntityRelationInput"];
	["TimetableShiftUpdateQueueItemEntityRelationInput"]: GraphQLTypes["TimetableShiftUpdateQueueItemEntityRelationInput"];
	["EmployeeQueueItemWithoutShiftCreateInput"]: GraphQLTypes["EmployeeQueueItemWithoutShiftCreateInput"];
	["EmployeeQueueItemCreateQueueEntityRelationInput"]: GraphQLTypes["EmployeeQueueItemCreateQueueEntityRelationInput"];
	["EmployeeQueueWithoutItemsCreateInput"]: GraphQLTypes["EmployeeQueueWithoutItemsCreateInput"];
	["EmployeeQueueItemWithoutShiftUpdateInput"]: GraphQLTypes["EmployeeQueueItemWithoutShiftUpdateInput"];
	["EmployeeQueueItemUpdateQueueEntityRelationInput"]: GraphQLTypes["EmployeeQueueItemUpdateQueueEntityRelationInput"];
	["EmployeeQueueWithoutItemsUpdateInput"]: GraphQLTypes["EmployeeQueueWithoutItemsUpdateInput"];
	["EmployeeQueueItemUpsertQueueRelationInput"]: GraphQLTypes["EmployeeQueueItemUpsertQueueRelationInput"];
	["EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"]: GraphQLTypes["EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"];
	["UnassignedShiftInfoWithoutQueueItemUpdateInput"]: GraphQLTypes["UnassignedShiftInfoWithoutQueueItemUpdateInput"];
	["UnassignedShiftInfoUpdateReasonEntityRelationInput"]: GraphQLTypes["UnassignedShiftInfoUpdateReasonEntityRelationInput"];
	["EmployeeQueueItemUpsertUnassignInfoRelationInput"]: GraphQLTypes["EmployeeQueueItemUpsertUnassignInfoRelationInput"];
	["TimetableShiftUpsertQueueItemRelationInput"]: GraphQLTypes["TimetableShiftUpsertQueueItemRelationInput"];
	["EmployeeQueueItemUpdateShiftEntityRelationInput"]: GraphQLTypes["EmployeeQueueItemUpdateShiftEntityRelationInput"];
	["TimetableShiftWithoutQueueItemUpdateInput"]: GraphQLTypes["TimetableShiftWithoutQueueItemUpdateInput"];
	["TimetableShiftUpdateShiftsProfileEntityRelationInput"]: GraphQLTypes["TimetableShiftUpdateShiftsProfileEntityRelationInput"];
	["ShiftsProfileWithoutShiftsUpdateInput"]: GraphQLTypes["ShiftsProfileWithoutShiftsUpdateInput"];
	["ShiftsProfileUpdateQueueItemsEntityRelationInput"]: GraphQLTypes["ShiftsProfileUpdateQueueItemsEntityRelationInput"];
	["EmployeeQueueItemWithoutShiftsProfileCreateInput"]: GraphQLTypes["EmployeeQueueItemWithoutShiftsProfileCreateInput"];
	["ShiftsProfileUpdateQueueItemsRelationInput"]: GraphQLTypes["ShiftsProfileUpdateQueueItemsRelationInput"];
	["EmployeeQueueItemWithoutShiftsProfileUpdateInput"]: GraphQLTypes["EmployeeQueueItemWithoutShiftsProfileUpdateInput"];
	["ShiftsProfileUpsertQueueItemsRelationInput"]: GraphQLTypes["ShiftsProfileUpsertQueueItemsRelationInput"];
	["EmployeeQueueUpsertItemsRelationInput"]: GraphQLTypes["EmployeeQueueUpsertItemsRelationInput"];
	["TimetableShiftUpdateInput"]: GraphQLTypes["TimetableShiftUpdateInput"];
	["TimetableShiftPositionUniqueWhere"]: GraphQLTypes["TimetableShiftPositionUniqueWhere"];
	["TimetableShiftPositionConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableShiftPositionEdge"]>
};
	["TimetableShiftPositionEdge"]: {
		node: GraphQLTypes["TimetableShiftPosition"]
};
	["EmployeeQueueItemCreateInput"]: GraphQLTypes["EmployeeQueueItemCreateInput"];
	["EmployeeQueueItemUpdateInput"]: GraphQLTypes["EmployeeQueueItemUpdateInput"];
	["ProductBannerUniqueWhere"]: GraphQLTypes["ProductBannerUniqueWhere"];
	["ProductBannerConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerEdge"]>
};
	["ProductBannerEdge"]: {
		node: GraphQLTypes["ProductBanner"]
};
	["ProductBannerListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerListEdge"]>
};
	["ProductBannerListEdge"]: {
		node: GraphQLTypes["ProductBannerList"]
};
	["ShiftsProfileUpdateInput"]: GraphQLTypes["ShiftsProfileUpdateInput"];
	["DeliveryRouteUniqueWhere"]: GraphQLTypes["DeliveryRouteUniqueWhere"];
	["DeliveryRouteConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryRouteEdge"]>
};
	["DeliveryRouteEdge"]: {
		node: GraphQLTypes["DeliveryRoute"]
};
	["WeekHoursUniqueWhere"]: GraphQLTypes["WeekHoursUniqueWhere"];
	["WeekHoursConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["WeekHoursEdge"]>
};
	["WeekHoursEdge"]: {
		node: GraphQLTypes["WeekHours"]
};
	["PreparedIngredientQuantity"]: {
		_meta?: GraphQLTypes["PreparedIngredientQuantityMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	date: GraphQLTypes["Date"],
	quantity?: number | undefined,
	usedQuantity?: number | undefined,
	ingredient?: GraphQLTypes["ProductIngredient"] | undefined,
	unit?: GraphQLTypes["ProductIngredientItemQuantityUnit"] | undefined
};
	["PreparedIngredientQuantityMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	date?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	usedQuantity?: GraphQLTypes["FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["FieldMeta"] | undefined,
	unit?: GraphQLTypes["FieldMeta"] | undefined
};
	["PreparedIngredientQuantityUniqueWhere"]: GraphQLTypes["PreparedIngredientQuantityUniqueWhere"];
	["PreparedIngredientQuantityWhere"]: GraphQLTypes["PreparedIngredientQuantityWhere"];
	["PreparedIngredientQuantityOrderBy"]: GraphQLTypes["PreparedIngredientQuantityOrderBy"];
	["PreparedIngredientQuantityConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PreparedIngredientQuantityEdge"]>
};
	["PreparedIngredientQuantityEdge"]: {
		node: GraphQLTypes["PreparedIngredientQuantity"]
};
	["PreparedIngredientQuantityCreateInput"]: GraphQLTypes["PreparedIngredientQuantityCreateInput"];
	["PreparedIngredientQuantityCreateIngredientEntityRelationInput"]: GraphQLTypes["PreparedIngredientQuantityCreateIngredientEntityRelationInput"];
	["PreparedIngredientQuantityCreateUnitEntityRelationInput"]: GraphQLTypes["PreparedIngredientQuantityCreateUnitEntityRelationInput"];
	["PreparedIngredientQuantityUpdateInput"]: GraphQLTypes["PreparedIngredientQuantityUpdateInput"];
	["PreparedIngredientQuantityUpdateIngredientEntityRelationInput"]: GraphQLTypes["PreparedIngredientQuantityUpdateIngredientEntityRelationInput"];
	["PreparedIngredientQuantityUpdateUnitEntityRelationInput"]: GraphQLTypes["PreparedIngredientQuantityUpdateUnitEntityRelationInput"];
	["PreparedRecipeQuantity"]: {
		_meta?: GraphQLTypes["PreparedRecipeQuantityMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	date: GraphQLTypes["Date"],
	quantity?: number | undefined,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	usedQuantity?: number | undefined
};
	["PreparedRecipeQuantityMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	date?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	usedQuantity?: GraphQLTypes["FieldMeta"] | undefined
};
	["PreparedRecipeQuantityUniqueWhere"]: GraphQLTypes["PreparedRecipeQuantityUniqueWhere"];
	["PreparedRecipeQuantityWhere"]: GraphQLTypes["PreparedRecipeQuantityWhere"];
	["PreparedRecipeQuantityOrderBy"]: GraphQLTypes["PreparedRecipeQuantityOrderBy"];
	["PreparedRecipeQuantityConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PreparedRecipeQuantityEdge"]>
};
	["PreparedRecipeQuantityEdge"]: {
		node: GraphQLTypes["PreparedRecipeQuantity"]
};
	["PreparedRecipeQuantityCreateInput"]: GraphQLTypes["PreparedRecipeQuantityCreateInput"];
	["PreparedRecipeQuantityCreateRecipeEntityRelationInput"]: GraphQLTypes["PreparedRecipeQuantityCreateRecipeEntityRelationInput"];
	["PreparedRecipeQuantityUpdateInput"]: GraphQLTypes["PreparedRecipeQuantityUpdateInput"];
	["PreparedRecipeQuantityUpdateRecipeEntityRelationInput"]: GraphQLTypes["PreparedRecipeQuantityUpdateRecipeEntityRelationInput"];
	["PreparedPackingQuantityCreateInput"]: GraphQLTypes["PreparedPackingQuantityCreateInput"];
	["PreparedPackingQuantityCreateRecipeEntityRelationInput"]: GraphQLTypes["PreparedPackingQuantityCreateRecipeEntityRelationInput"];
	["PreparedPackingQuantityCreatePackingEntityRelationInput"]: GraphQLTypes["PreparedPackingQuantityCreatePackingEntityRelationInput"];
	["PreparedPackingQuantityUpdateInput"]: GraphQLTypes["PreparedPackingQuantityUpdateInput"];
	["PreparedPackingQuantityUpdateRecipeEntityRelationInput"]: GraphQLTypes["PreparedPackingQuantityUpdateRecipeEntityRelationInput"];
	["PreparedPackingQuantityUpdatePackingEntityRelationInput"]: GraphQLTypes["PreparedPackingQuantityUpdatePackingEntityRelationInput"];
	["DeliveryZoneUniqueWhere"]: GraphQLTypes["DeliveryZoneUniqueWhere"];
	["DeliveryTimelinePresetConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryTimelinePresetEdge"]>
};
	["DeliveryTimelinePresetEdge"]: {
		node: GraphQLTypes["DeliveryTimelinePreset"]
};
	["SuppliesPurchase"]: {
		_meta?: GraphQLTypes["SuppliesPurchaseMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	items: Array<GraphQLTypes["SuppliesPurchaseItem"]>,
	supplier?: GraphQLTypes["IngredientSupplier"] | undefined,
	requestedDeliveryDate?: GraphQLTypes["Date"] | undefined,
	paginateItems: GraphQLTypes["SuppliesPurchaseItemConnection"]
};
	["SuppliesPurchaseMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	supplier?: GraphQLTypes["FieldMeta"] | undefined,
	requestedDeliveryDate?: GraphQLTypes["FieldMeta"] | undefined
};
	["SuppliesPurchaseItem"]: {
		_meta?: GraphQLTypes["SuppliesPurchaseItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	amount?: number | undefined,
	list?: GraphQLTypes["SuppliesPurchase"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredient"] | undefined,
	pricePaidCents?: number | undefined,
	preferredUnit?: GraphQLTypes["ProductIngredientItemQuantityUnit"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	purchasedAt?: GraphQLTypes["DateTime"] | undefined,
	purchasedBy?: GraphQLTypes["Staff"] | undefined
};
	["SuppliesPurchaseItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	amount?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["FieldMeta"] | undefined,
	pricePaidCents?: GraphQLTypes["FieldMeta"] | undefined,
	preferredUnit?: GraphQLTypes["FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["FieldMeta"] | undefined,
	purchasedAt?: GraphQLTypes["FieldMeta"] | undefined,
	purchasedBy?: GraphQLTypes["FieldMeta"] | undefined
};
	["SuppliesPurchaseWhere"]: GraphQLTypes["SuppliesPurchaseWhere"];
	["SuppliesPurchaseItemWhere"]: GraphQLTypes["SuppliesPurchaseItemWhere"];
	["SuppliesPurchaseItemOrderBy"]: GraphQLTypes["SuppliesPurchaseItemOrderBy"];
	["SuppliesPurchaseOrderBy"]: GraphQLTypes["SuppliesPurchaseOrderBy"];
	["SuppliesPurchaseItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SuppliesPurchaseItemEdge"]>
};
	["SuppliesPurchaseItemEdge"]: {
		node: GraphQLTypes["SuppliesPurchaseItem"]
};
	["SuppliesPurchaseUniqueWhere"]: GraphQLTypes["SuppliesPurchaseUniqueWhere"];
	["SuppliesPurchaseItemUniqueWhere"]: GraphQLTypes["SuppliesPurchaseItemUniqueWhere"];
	["SuppliesPurchaseConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SuppliesPurchaseEdge"]>
};
	["SuppliesPurchaseEdge"]: {
		node: GraphQLTypes["SuppliesPurchase"]
};
	["SuppliesPurchaseCreateInput"]: GraphQLTypes["SuppliesPurchaseCreateInput"];
	["SuppliesPurchaseCreateItemsEntityRelationInput"]: GraphQLTypes["SuppliesPurchaseCreateItemsEntityRelationInput"];
	["SuppliesPurchaseItemWithoutListCreateInput"]: GraphQLTypes["SuppliesPurchaseItemWithoutListCreateInput"];
	["SuppliesPurchaseItemCreateIngredientEntityRelationInput"]: GraphQLTypes["SuppliesPurchaseItemCreateIngredientEntityRelationInput"];
	["SuppliesPurchaseItemCreatePreferredUnitEntityRelationInput"]: GraphQLTypes["SuppliesPurchaseItemCreatePreferredUnitEntityRelationInput"];
	["SuppliesPurchaseItemCreatePurchasedByEntityRelationInput"]: GraphQLTypes["SuppliesPurchaseItemCreatePurchasedByEntityRelationInput"];
	["SuppliesPurchaseCreateSupplierEntityRelationInput"]: GraphQLTypes["SuppliesPurchaseCreateSupplierEntityRelationInput"];
	["SuppliesPurchaseUpdateInput"]: GraphQLTypes["SuppliesPurchaseUpdateInput"];
	["SuppliesPurchaseUpdateItemsEntityRelationInput"]: GraphQLTypes["SuppliesPurchaseUpdateItemsEntityRelationInput"];
	["SuppliesPurchaseUpdateItemsRelationInput"]: GraphQLTypes["SuppliesPurchaseUpdateItemsRelationInput"];
	["SuppliesPurchaseItemWithoutListUpdateInput"]: GraphQLTypes["SuppliesPurchaseItemWithoutListUpdateInput"];
	["SuppliesPurchaseItemUpdateIngredientEntityRelationInput"]: GraphQLTypes["SuppliesPurchaseItemUpdateIngredientEntityRelationInput"];
	["SuppliesPurchaseItemUpdatePreferredUnitEntityRelationInput"]: GraphQLTypes["SuppliesPurchaseItemUpdatePreferredUnitEntityRelationInput"];
	["SuppliesPurchaseItemUpdatePurchasedByEntityRelationInput"]: GraphQLTypes["SuppliesPurchaseItemUpdatePurchasedByEntityRelationInput"];
	["SuppliesPurchaseUpsertItemsRelationInput"]: GraphQLTypes["SuppliesPurchaseUpsertItemsRelationInput"];
	["SuppliesPurchaseUpdateSupplierEntityRelationInput"]: GraphQLTypes["SuppliesPurchaseUpdateSupplierEntityRelationInput"];
	["LogRecord"]: {
		_meta?: GraphQLTypes["LogRecordMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	level: number,
	type: string,
	message?: string | undefined,
	data?: GraphQLTypes["Json"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	shouldBeResolved?: boolean | undefined,
	resolvedAt?: GraphQLTypes["DateTime"] | undefined
};
	["LogRecordMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	level?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	message?: GraphQLTypes["FieldMeta"] | undefined,
	data?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	shouldBeResolved?: GraphQLTypes["FieldMeta"] | undefined,
	resolvedAt?: GraphQLTypes["FieldMeta"] | undefined
};
	["LogRecordUniqueWhere"]: GraphQLTypes["LogRecordUniqueWhere"];
	["LogRecordWhere"]: GraphQLTypes["LogRecordWhere"];
	["LogRecordOrderBy"]: GraphQLTypes["LogRecordOrderBy"];
	["LogRecordConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LogRecordEdge"]>
};
	["LogRecordEdge"]: {
		node: GraphQLTypes["LogRecord"]
};
	["SuppliesPurchaseItemCreateInput"]: GraphQLTypes["SuppliesPurchaseItemCreateInput"];
	["SuppliesPurchaseItemCreateListEntityRelationInput"]: GraphQLTypes["SuppliesPurchaseItemCreateListEntityRelationInput"];
	["SuppliesPurchaseWithoutItemsCreateInput"]: GraphQLTypes["SuppliesPurchaseWithoutItemsCreateInput"];
	["SuppliesPurchaseItemUpdateInput"]: GraphQLTypes["SuppliesPurchaseItemUpdateInput"];
	["SuppliesPurchaseItemUpdateListEntityRelationInput"]: GraphQLTypes["SuppliesPurchaseItemUpdateListEntityRelationInput"];
	["SuppliesPurchaseWithoutItemsUpdateInput"]: GraphQLTypes["SuppliesPurchaseWithoutItemsUpdateInput"];
	["SuppliesPurchaseItemUpsertListRelationInput"]: GraphQLTypes["SuppliesPurchaseItemUpsertListRelationInput"];
	["VirtualProductConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VirtualProductEdge"]>
};
	["VirtualProductEdge"]: {
		node: GraphQLTypes["VirtualProduct"]
};
	["ProductMetadataConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductMetadataEdge"]>
};
	["ProductMetadataEdge"]: {
		node: GraphQLTypes["ProductMetadata"]
};
	["UnassignedShiftInfoConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["UnassignedShiftInfoEdge"]>
};
	["UnassignedShiftInfoEdge"]: {
		node: GraphQLTypes["UnassignedShiftInfo"]
};
	["UnassignedShiftInfoCreateInput"]: GraphQLTypes["UnassignedShiftInfoCreateInput"];
	["UnassignedShiftInfoCreateQueueItemEntityRelationInput"]: GraphQLTypes["UnassignedShiftInfoCreateQueueItemEntityRelationInput"];
	["EmployeeQueueItemWithoutUnassignInfoCreateInput"]: GraphQLTypes["EmployeeQueueItemWithoutUnassignInfoCreateInput"];
	["UnassignedShiftInfoUpdateInput"]: GraphQLTypes["UnassignedShiftInfoUpdateInput"];
	["UnassignedShiftInfoUpdateQueueItemEntityRelationInput"]: GraphQLTypes["UnassignedShiftInfoUpdateQueueItemEntityRelationInput"];
	["EmployeeQueueItemWithoutUnassignInfoUpdateInput"]: GraphQLTypes["EmployeeQueueItemWithoutUnassignInfoUpdateInput"];
	["UnassignedShiftInfoUpsertQueueItemRelationInput"]: GraphQLTypes["UnassignedShiftInfoUpsertQueueItemRelationInput"];
	["FreshingContainerUniqueWhere"]: GraphQLTypes["FreshingContainerUniqueWhere"];
	["TextListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TextListEdge"]>
};
	["TextListEdge"]: {
		node: GraphQLTypes["TextList"]
};
	["DeliveryRegionsConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryRegionsEdge"]>
};
	["DeliveryRegionsEdge"]: {
		node: GraphQLTypes["DeliveryRegions"]
};
	["ImageListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListEdge"]>
};
	["ImageListEdge"]: {
		node: GraphQLTypes["ImageList"]
};
	["TimetablesEmployeePrivilegesConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetablesEmployeePrivilegesEdge"]>
};
	["TimetablesEmployeePrivilegesEdge"]: {
		node: GraphQLTypes["TimetablesEmployeePrivileges"]
};
	["IngredientSupplierConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IngredientSupplierEdge"]>
};
	["IngredientSupplierEdge"]: {
		node: GraphQLTypes["IngredientSupplier"]
};
	["IngredientSupplierListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IngredientSupplierListEdge"]>
};
	["IngredientSupplierListEdge"]: {
		node: GraphQLTypes["IngredientSupplierList"]
};
	["RedirectConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["RedirectEdge"]>
};
	["RedirectEdge"]: {
		node: GraphQLTypes["Redirect"]
};
	["WorkspaceUniqueWhere"]: GraphQLTypes["WorkspaceUniqueWhere"];
	["WorkspaceConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["WorkspaceEdge"]>
};
	["WorkspaceEdge"]: {
		node: GraphQLTypes["Workspace"]
};
	["IdentificationCodeConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IdentificationCodeEdge"]>
};
	["IdentificationCodeEdge"]: {
		node: GraphQLTypes["IdentificationCode"]
};
	["DeviceIdentifier"]: {
		_meta?: GraphQLTypes["DeviceIdentifierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName?: string | undefined,
	updatedAt: GraphQLTypes["DateTime"],
	platform?: string | undefined,
	resolution?: string | undefined,
	devicePixelRatio?: number | undefined,
	identifier: string,
	meta?: GraphQLTypes["DeviceIdentifierMetadata"] | undefined,
	applicationVersionIdentifier?: string | undefined,
	lastPointOfSaleSynchronizationErrorRawDetails?: string | undefined
};
	["DeviceIdentifierMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	updatedAt?: GraphQLTypes["FieldMeta"] | undefined,
	platform?: GraphQLTypes["FieldMeta"] | undefined,
	resolution?: GraphQLTypes["FieldMeta"] | undefined,
	devicePixelRatio?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	applicationVersionIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	lastPointOfSaleSynchronizationErrorRawDetails?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeviceIdentifierMetadata"]: {
		_meta?: GraphQLTypes["DeviceIdentifierMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	prettyIdentifier: string,
	deviceIdentifier?: GraphQLTypes["DeviceIdentifier"] | undefined
};
	["DeviceIdentifierMetadataMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	prettyIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	deviceIdentifier?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeviceIdentifierWhere"]: GraphQLTypes["DeviceIdentifierWhere"];
	["DeviceIdentifierMetadataWhere"]: GraphQLTypes["DeviceIdentifierMetadataWhere"];
	["DeviceIdentifierUniqueWhere"]: GraphQLTypes["DeviceIdentifierUniqueWhere"];
	["DeviceIdentifierMetadataUniqueWhere"]: GraphQLTypes["DeviceIdentifierMetadataUniqueWhere"];
	["DeviceIdentifierOrderBy"]: GraphQLTypes["DeviceIdentifierOrderBy"];
	["DeviceIdentifierMetadataOrderBy"]: GraphQLTypes["DeviceIdentifierMetadataOrderBy"];
	["DeviceIdentifierConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeviceIdentifierEdge"]>
};
	["DeviceIdentifierEdge"]: {
		node: GraphQLTypes["DeviceIdentifier"]
};
	["DeviceIdentifierCreateInput"]: GraphQLTypes["DeviceIdentifierCreateInput"];
	["DeviceIdentifierUpdateInput"]: GraphQLTypes["DeviceIdentifierUpdateInput"];
	["DeviceIdentifierMetadataConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeviceIdentifierMetadataEdge"]>
};
	["DeviceIdentifierMetadataEdge"]: {
		node: GraphQLTypes["DeviceIdentifierMetadata"]
};
	["FulfillmentNoteUniqueWhere"]: GraphQLTypes["FulfillmentNoteUniqueWhere"];
	["CustomerMetadataConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerMetadataEdge"]>
};
	["CustomerMetadataEdge"]: {
		node: GraphQLTypes["CustomerMetadata"]
};
	["SubscriptionBoxConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SubscriptionBoxEdge"]>
};
	["SubscriptionBoxEdge"]: {
		node: GraphQLTypes["SubscriptionBox"]
};
	["TimetableDayMetadataConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableDayMetadataEdge"]>
};
	["TimetableDayMetadataEdge"]: {
		node: GraphQLTypes["TimetableDayMetadata"]
};
	["GridTileItemUniqueWhere"]: GraphQLTypes["GridTileItemUniqueWhere"];
	["GridTilesUniqueWhere"]: GraphQLTypes["GridTilesUniqueWhere"];
	["GridTilesConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GridTilesEdge"]>
};
	["GridTilesEdge"]: {
		node: GraphQLTypes["GridTiles"]
};
	["Position"]: {
		_meta?: GraphQLTypes["PositionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	x: number,
	y: number
};
	["PositionMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	x?: GraphQLTypes["FieldMeta"] | undefined,
	y?: GraphQLTypes["FieldMeta"] | undefined
};
	["PositionUniqueWhere"]: GraphQLTypes["PositionUniqueWhere"];
	["PositionWhere"]: GraphQLTypes["PositionWhere"];
	["PositionOrderBy"]: GraphQLTypes["PositionOrderBy"];
	["PositionConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PositionEdge"]>
};
	["PositionEdge"]: {
		node: GraphQLTypes["Position"]
};
	["StatsWidget"]: {
		_meta?: GraphQLTypes["StatsWidgetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	size: number,
	type: GraphQLTypes["StatsEnabledWidgetTypeType"],
	link?: string | undefined,
	title?: string | undefined,
	timeFrame: GraphQLTypes["StatsWidgetTimeFrame"],
	layout?: GraphQLTypes["StatsWidgetsLayout"] | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	channels: Array<GraphQLTypes["Channel"]>,
	productCategory?: GraphQLTypes["ProductCategory"] | undefined,
	paymentMethods: Array<GraphQLTypes["PaymentMethod"]>,
	hoursFrom?: string | undefined,
	hoursTo?: string | undefined,
	paginateChannels: GraphQLTypes["ChannelConnection"],
	paginatePaymentMethods: GraphQLTypes["PaymentMethodConnection"]
};
	["StatsWidgetMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	timeFrame?: GraphQLTypes["FieldMeta"] | undefined,
	layout?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	channels?: GraphQLTypes["FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["FieldMeta"] | undefined,
	paymentMethods?: GraphQLTypes["FieldMeta"] | undefined,
	hoursFrom?: GraphQLTypes["FieldMeta"] | undefined,
	hoursTo?: GraphQLTypes["FieldMeta"] | undefined
};
	["StatsEnabledWidgetTypeType"]: GraphQLTypes["StatsEnabledWidgetTypeType"];
	["StatsWidgetTimeFrame"]: GraphQLTypes["StatsWidgetTimeFrame"];
	["StatsWidgetsLayout"]: {
		_meta?: GraphQLTypes["StatsWidgetsLayoutMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["StatsWidget"]>,
	paginateItems: GraphQLTypes["StatsWidgetConnection"]
};
	["StatsWidgetsLayoutMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["StatsWidgetWhere"]: GraphQLTypes["StatsWidgetWhere"];
	["StatsEnabledWidgetTypeTypeCondition"]: GraphQLTypes["StatsEnabledWidgetTypeTypeCondition"];
	["StatsWidgetTimeFrameCondition"]: GraphQLTypes["StatsWidgetTimeFrameCondition"];
	["StatsWidgetsLayoutWhere"]: GraphQLTypes["StatsWidgetsLayoutWhere"];
	["StatsWidgetOrderBy"]: GraphQLTypes["StatsWidgetOrderBy"];
	["StatsWidgetsLayoutOrderBy"]: GraphQLTypes["StatsWidgetsLayoutOrderBy"];
	["StatsWidgetConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StatsWidgetEdge"]>
};
	["StatsWidgetEdge"]: {
		node: GraphQLTypes["StatsWidget"]
};
	["StatsWidgetUniqueWhere"]: GraphQLTypes["StatsWidgetUniqueWhere"];
	["StatsWidgetsLayoutUniqueWhere"]: GraphQLTypes["StatsWidgetsLayoutUniqueWhere"];
	["StatsWidgetsLayoutConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StatsWidgetsLayoutEdge"]>
};
	["StatsWidgetsLayoutEdge"]: {
		node: GraphQLTypes["StatsWidgetsLayout"]
};
	["StatsPage"]: {
		_meta?: GraphQLTypes["StatsPageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["StatsPageType"],
	enabled: boolean,
	widgets?: GraphQLTypes["StatsWidgetsLayout"] | undefined
};
	["StatsPageMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	widgets?: GraphQLTypes["FieldMeta"] | undefined
};
	["StatsPageType"]: GraphQLTypes["StatsPageType"];
	["StatsPageUniqueWhere"]: GraphQLTypes["StatsPageUniqueWhere"];
	["StatsPageWhere"]: GraphQLTypes["StatsPageWhere"];
	["StatsPageTypeCondition"]: GraphQLTypes["StatsPageTypeCondition"];
	["StatsPageOrderBy"]: GraphQLTypes["StatsPageOrderBy"];
	["StatsPageConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StatsPageEdge"]>
};
	["StatsPageEdge"]: {
		node: GraphQLTypes["StatsPage"]
};
	["OrderPaymentStatsByDay"]: {
		_meta?: GraphQLTypes["OrderPaymentStatsByDayMeta"] | undefined,
	id: string,
	day: GraphQLTypes["Date"],
	totalGratuityCents: number,
	reducedGratuityCents: number,
	channel?: GraphQLTypes["Channel"] | undefined,
	paymentMethod?: GraphQLTypes["PaymentMethod"] | undefined
};
	["OrderPaymentStatsByDayMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	day?: GraphQLTypes["FieldMeta"] | undefined,
	totalGratuityCents?: GraphQLTypes["FieldMeta"] | undefined,
	reducedGratuityCents?: GraphQLTypes["FieldMeta"] | undefined,
	channel?: GraphQLTypes["FieldMeta"] | undefined,
	paymentMethod?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderPaymentStatsByDayUniqueWhere"]: GraphQLTypes["OrderPaymentStatsByDayUniqueWhere"];
	["OrderPaymentStatsByDayWhere"]: GraphQLTypes["OrderPaymentStatsByDayWhere"];
	["OrderPaymentStatsByDayOrderBy"]: GraphQLTypes["OrderPaymentStatsByDayOrderBy"];
	["OrderPaymentStatsByDayConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderPaymentStatsByDayEdge"]>
};
	["OrderPaymentStatsByDayEdge"]: {
		node: GraphQLTypes["OrderPaymentStatsByDay"]
};
	["QueryTransaction"]: {
		getAddress?: GraphQLTypes["Address"] | undefined,
	listAddress: Array<GraphQLTypes["Address"]>,
	paginateAddress: GraphQLTypes["AddressConnection"],
	getCountry?: GraphQLTypes["Country"] | undefined,
	listCountry: Array<GraphQLTypes["Country"]>,
	paginateCountry: GraphQLTypes["CountryConnection"],
	getChannel?: GraphQLTypes["Channel"] | undefined,
	listChannel: Array<GraphQLTypes["Channel"]>,
	paginateChannel: GraphQLTypes["ChannelConnection"],
	getCustomer?: GraphQLTypes["Customer"] | undefined,
	listCustomer: Array<GraphQLTypes["Customer"]>,
	paginateCustomer: GraphQLTypes["CustomerConnection"],
	getDeliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined,
	listDeliveryMethod: Array<GraphQLTypes["DeliveryMethod"]>,
	paginateDeliveryMethod: GraphQLTypes["DeliveryMethodConnection"],
	getOrder?: GraphQLTypes["Order"] | undefined,
	listOrder: Array<GraphQLTypes["Order"]>,
	paginateOrder: GraphQLTypes["OrderConnection"],
	getOrderPayment?: GraphQLTypes["OrderPayment"] | undefined,
	listOrderPayment: Array<GraphQLTypes["OrderPayment"]>,
	paginateOrderPayment: GraphQLTypes["OrderPaymentConnection"],
	getOrderDelivery?: GraphQLTypes["OrderDelivery"] | undefined,
	listOrderDelivery: Array<GraphQLTypes["OrderDelivery"]>,
	paginateOrderDelivery: GraphQLTypes["OrderDeliveryConnection"],
	getOrderItem?: GraphQLTypes["OrderItem"] | undefined,
	listOrderItem: Array<GraphQLTypes["OrderItem"]>,
	paginateOrderItem: GraphQLTypes["OrderItemConnection"],
	getPaymentMethod?: GraphQLTypes["PaymentMethod"] | undefined,
	listPaymentMethod: Array<GraphQLTypes["PaymentMethod"]>,
	paginatePaymentMethod: GraphQLTypes["PaymentMethodConnection"],
	getVatRate?: GraphQLTypes["VatRate"] | undefined,
	listVatRate: Array<GraphQLTypes["VatRate"]>,
	paginateVatRate: GraphQLTypes["VatRateConnection"],
	getProduct?: GraphQLTypes["Product"] | undefined,
	listProduct: Array<GraphQLTypes["Product"]>,
	paginateProduct: GraphQLTypes["ProductConnection"],
	getProductStock?: GraphQLTypes["ProductStock"] | undefined,
	listProductStock: Array<GraphQLTypes["ProductStock"]>,
	paginateProductStock: GraphQLTypes["ProductStockConnection"],
	getProductPacking?: GraphQLTypes["ProductPacking"] | undefined,
	listProductPacking: Array<GraphQLTypes["ProductPacking"]>,
	paginateProductPacking: GraphQLTypes["ProductPackingConnection"],
	getProductRecipe?: GraphQLTypes["ProductRecipe"] | undefined,
	listProductRecipe: Array<GraphQLTypes["ProductRecipe"]>,
	paginateProductRecipe: GraphQLTypes["ProductRecipeConnection"],
	getProductCategory?: GraphQLTypes["ProductCategory"] | undefined,
	listProductCategory: Array<GraphQLTypes["ProductCategory"]>,
	paginateProductCategory: GraphQLTypes["ProductCategoryConnection"],
	getStore?: GraphQLTypes["Store"] | undefined,
	listStore: Array<GraphQLTypes["Store"]>,
	paginateStore: GraphQLTypes["StoreConnection"],
	getTag?: GraphQLTypes["Tag"] | undefined,
	listTag: Array<GraphQLTypes["Tag"]>,
	paginateTag: GraphQLTypes["TagConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	listBusinessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"],
	getProductList?: GraphQLTypes["ProductList"] | undefined,
	listProductList: Array<GraphQLTypes["ProductList"]>,
	paginateProductList: GraphQLTypes["ProductListConnection"],
	getProductListItem?: GraphQLTypes["ProductListItem"] | undefined,
	listProductListItem: Array<GraphQLTypes["ProductListItem"]>,
	paginateProductListItem: GraphQLTypes["ProductListItemConnection"],
	getProductCategoryList?: GraphQLTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<GraphQLTypes["ProductCategoryList"]>,
	paginateProductCategoryList: GraphQLTypes["ProductCategoryListConnection"],
	getProductCategoryListItem?: GraphQLTypes["ProductCategoryListItem"] | undefined,
	listProductCategoryListItem: Array<GraphQLTypes["ProductCategoryListItem"]>,
	paginateProductCategoryListItem: GraphQLTypes["ProductCategoryListItemConnection"],
	getBusinessCategoryLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	listBusinessCategoryLocale: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	paginateBusinessCategoryLocale: GraphQLTypes["BusinessCategoryLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getProductCategoryListLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	listProductCategoryListLocale: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	paginateProductCategoryListLocale: GraphQLTypes["ProductCategoryListLocaleConnection"],
	getCustomerName?: GraphQLTypes["CustomerName"] | undefined,
	listCustomerName: Array<GraphQLTypes["CustomerName"]>,
	paginateCustomerName: GraphQLTypes["CustomerNameConnection"],
	getProductIngredientItem?: GraphQLTypes["ProductIngredientItem"] | undefined,
	listProductIngredientItem: Array<GraphQLTypes["ProductIngredientItem"]>,
	paginateProductIngredientItem: GraphQLTypes["ProductIngredientItemConnection"],
	getProductIngredientItemQuantity?: GraphQLTypes["ProductIngredientItemQuantity"] | undefined,
	listProductIngredientItemQuantity: Array<GraphQLTypes["ProductIngredientItemQuantity"]>,
	paginateProductIngredientItemQuantity: GraphQLTypes["ProductIngredientItemQuantityConnection"],
	getProductIngredientItemQuantityUnit?: GraphQLTypes["ProductIngredientItemQuantityUnit"] | undefined,
	listProductIngredientItemQuantityUnit: Array<GraphQLTypes["ProductIngredientItemQuantityUnit"]>,
	paginateProductIngredientItemQuantityUnit: GraphQLTypes["ProductIngredientItemQuantityUnitConnection"],
	getProductIngredient?: GraphQLTypes["ProductIngredient"] | undefined,
	listProductIngredient: Array<GraphQLTypes["ProductIngredient"]>,
	paginateProductIngredient: GraphQLTypes["ProductIngredientConnection"],
	getProductIngredientRating?: GraphQLTypes["ProductIngredientRating"] | undefined,
	listProductIngredientRating: Array<GraphQLTypes["ProductIngredientRating"]>,
	paginateProductIngredientRating: GraphQLTypes["ProductIngredientRatingConnection"],
	getProductIngredientLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	listProductIngredientLocale: Array<GraphQLTypes["ProductIngredientLocale"]>,
	paginateProductIngredientLocale: GraphQLTypes["ProductIngredientLocaleConnection"],
	getOrderRecurrence?: GraphQLTypes["OrderRecurrence"] | undefined,
	listOrderRecurrence: Array<GraphQLTypes["OrderRecurrence"]>,
	paginateOrderRecurrence: GraphQLTypes["OrderRecurrenceConnection"],
	getOrderRecurrenceLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	listOrderRecurrenceLocale: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	paginateOrderRecurrenceLocale: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	getStaff?: GraphQLTypes["Staff"] | undefined,
	listStaff: Array<GraphQLTypes["Staff"]>,
	paginateStaff: GraphQLTypes["StaffConnection"],
	getBlog?: GraphQLTypes["Blog"] | undefined,
	listBlog: Array<GraphQLTypes["Blog"]>,
	paginateBlog: GraphQLTypes["BlogConnection"],
	getBlogLocale?: GraphQLTypes["BlogLocale"] | undefined,
	listBlogLocale: Array<GraphQLTypes["BlogLocale"]>,
	paginateBlogLocale: GraphQLTypes["BlogLocaleConnection"],
	getBlogPost?: GraphQLTypes["BlogPost"] | undefined,
	listBlogPost: Array<GraphQLTypes["BlogPost"]>,
	paginateBlogPost: GraphQLTypes["BlogPostConnection"],
	getBlogPostLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<GraphQLTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: GraphQLTypes["BlogPostLocaleConnection"],
	getColorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	listColorPallete: Array<GraphQLTypes["ColorPallete"]>,
	paginateColorPallete: GraphQLTypes["ColorPalleteConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getBlogPostList?: GraphQLTypes["BlogPostList"] | undefined,
	listBlogPostList: Array<GraphQLTypes["BlogPostList"]>,
	paginateBlogPostList: GraphQLTypes["BlogPostListConnection"],
	getBlogPostListItem?: GraphQLTypes["BlogPostListItem"] | undefined,
	listBlogPostListItem: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateBlogPostListItem: GraphQLTypes["BlogPostListItemConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getLinkListItem?: GraphQLTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<GraphQLTypes["LinkListItem"]>,
	paginateLinkListItem: GraphQLTypes["LinkListItemConnection"],
	getCover?: GraphQLTypes["Cover"] | undefined,
	listCover: Array<GraphQLTypes["Cover"]>,
	paginateCover: GraphQLTypes["CoverConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getMedium?: GraphQLTypes["Medium"] | undefined,
	listMedium: Array<GraphQLTypes["Medium"]>,
	paginateMedium: GraphQLTypes["MediumConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getSocialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	listSocialsAndApps: Array<GraphQLTypes["SocialsAndApps"]>,
	paginateSocialsAndApps: GraphQLTypes["SocialsAndAppsConnection"],
	getProductGrid?: GraphQLTypes["ProductGrid"] | undefined,
	listProductGrid: Array<GraphQLTypes["ProductGrid"]>,
	paginateProductGrid: GraphQLTypes["ProductGridConnection"],
	getProductGridItem?: GraphQLTypes["ProductGridItem"] | undefined,
	listProductGridItem: Array<GraphQLTypes["ProductGridItem"]>,
	paginateProductGridItem: GraphQLTypes["ProductGridItemConnection"],
	getGallery?: GraphQLTypes["Gallery"] | undefined,
	listGallery: Array<GraphQLTypes["Gallery"]>,
	paginateGallery: GraphQLTypes["GalleryConnection"],
	getGalleryItem?: GraphQLTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<GraphQLTypes["GalleryItem"]>,
	paginateGalleryItem: GraphQLTypes["GalleryItemConnection"],
	getHero?: GraphQLTypes["Hero"] | undefined,
	listHero: Array<GraphQLTypes["Hero"]>,
	paginateHero: GraphQLTypes["HeroConnection"],
	getPage?: GraphQLTypes["Page"] | undefined,
	listPage: Array<GraphQLTypes["Page"]>,
	paginatePage: GraphQLTypes["PageConnection"],
	getPageLocale?: GraphQLTypes["PageLocale"] | undefined,
	listPageLocale: Array<GraphQLTypes["PageLocale"]>,
	paginatePageLocale: GraphQLTypes["PageLocaleConnection"],
	getDeadlineTemplate?: GraphQLTypes["DeadlineTemplate"] | undefined,
	listDeadlineTemplate: Array<GraphQLTypes["DeadlineTemplate"]>,
	paginateDeadlineTemplate: GraphQLTypes["DeadlineTemplateConnection"],
	getEmployeeQueue?: GraphQLTypes["EmployeeQueue"] | undefined,
	listEmployeeQueue: Array<GraphQLTypes["EmployeeQueue"]>,
	paginateEmployeeQueue: GraphQLTypes["EmployeeQueueConnection"],
	validateCreateEmployeeQueue: GraphQLTypes["_ValidationResult"],
	validateUpdateEmployeeQueue: GraphQLTypes["_ValidationResult"],
	getTimetableDay?: GraphQLTypes["TimetableDay"] | undefined,
	listTimetableDay: Array<GraphQLTypes["TimetableDay"]>,
	paginateTimetableDay: GraphQLTypes["TimetableDayConnection"],
	getTimetableEmployeeRole?: GraphQLTypes["TimetableEmployeeRole"] | undefined,
	listTimetableEmployeeRole: Array<GraphQLTypes["TimetableEmployeeRole"]>,
	paginateTimetableEmployeeRole: GraphQLTypes["TimetableEmployeeRoleConnection"],
	getTimetableShift?: GraphQLTypes["TimetableShift"] | undefined,
	listTimetableShift: Array<GraphQLTypes["TimetableShift"]>,
	paginateTimetableShift: GraphQLTypes["TimetableShiftConnection"],
	validateUpdateTimetableShift: GraphQLTypes["_ValidationResult"],
	getTimetableShiftGroup?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	listTimetableShiftGroup: Array<GraphQLTypes["TimetableShiftGroup"]>,
	paginateTimetableShiftGroup: GraphQLTypes["TimetableShiftGroupConnection"],
	getTimetableShiftPosition?: GraphQLTypes["TimetableShiftPosition"] | undefined,
	listTimetableShiftPosition: Array<GraphQLTypes["TimetableShiftPosition"]>,
	paginateTimetableShiftPosition: GraphQLTypes["TimetableShiftPositionConnection"],
	getTimetableTemplate?: GraphQLTypes["TimetableTemplate"] | undefined,
	listTimetableTemplate: Array<GraphQLTypes["TimetableTemplate"]>,
	paginateTimetableTemplate: GraphQLTypes["TimetableTemplateConnection"],
	getEmployeeQueueItem?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	listEmployeeQueueItem: Array<GraphQLTypes["EmployeeQueueItem"]>,
	paginateEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemConnection"],
	validateCreateEmployeeQueueItem: GraphQLTypes["_ValidationResult"],
	validateUpdateEmployeeQueueItem: GraphQLTypes["_ValidationResult"],
	getProductBanner?: GraphQLTypes["ProductBanner"] | undefined,
	listProductBanner: Array<GraphQLTypes["ProductBanner"]>,
	paginateProductBanner: GraphQLTypes["ProductBannerConnection"],
	getProductBannerItem?: GraphQLTypes["ProductBannerItem"] | undefined,
	listProductBannerItem: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateProductBannerItem: GraphQLTypes["ProductBannerItemConnection"],
	getProductBannerList?: GraphQLTypes["ProductBannerList"] | undefined,
	listProductBannerList: Array<GraphQLTypes["ProductBannerList"]>,
	paginateProductBannerList: GraphQLTypes["ProductBannerListConnection"],
	getShiftsProfile?: GraphQLTypes["ShiftsProfile"] | undefined,
	listShiftsProfile: Array<GraphQLTypes["ShiftsProfile"]>,
	paginateShiftsProfile: GraphQLTypes["ShiftsProfileConnection"],
	validateUpdateShiftsProfile: GraphQLTypes["_ValidationResult"],
	getProductIngredientCategory?: GraphQLTypes["ProductIngredientCategory"] | undefined,
	listProductIngredientCategory: Array<GraphQLTypes["ProductIngredientCategory"]>,
	paginateProductIngredientCategory: GraphQLTypes["ProductIngredientCategoryConnection"],
	getProductIngredientCategoryLocale?: GraphQLTypes["ProductIngredientCategoryLocale"] | undefined,
	listProductIngredientCategoryLocale: Array<GraphQLTypes["ProductIngredientCategoryLocale"]>,
	paginateProductIngredientCategoryLocale: GraphQLTypes["ProductIngredientCategoryLocaleConnection"],
	getDeliveryRoute?: GraphQLTypes["DeliveryRoute"] | undefined,
	listDeliveryRoute: Array<GraphQLTypes["DeliveryRoute"]>,
	paginateDeliveryRoute: GraphQLTypes["DeliveryRouteConnection"],
	getDeliveryWaypoint?: GraphQLTypes["DeliveryWaypoint"] | undefined,
	listDeliveryWaypoint: Array<GraphQLTypes["DeliveryWaypoint"]>,
	paginateDeliveryWaypoint: GraphQLTypes["DeliveryWaypointConnection"],
	getAllergen?: GraphQLTypes["Allergen"] | undefined,
	listAllergen: Array<GraphQLTypes["Allergen"]>,
	paginateAllergen: GraphQLTypes["AllergenConnection"],
	getAllergenLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	listAllergenLocale: Array<GraphQLTypes["AllergenLocale"]>,
	paginateAllergenLocale: GraphQLTypes["AllergenLocaleConnection"],
	getWeekHours?: GraphQLTypes["WeekHours"] | undefined,
	listWeekHours: Array<GraphQLTypes["WeekHours"]>,
	paginateWeekHours: GraphQLTypes["WeekHoursConnection"],
	getPreparedIngredientQuantity?: GraphQLTypes["PreparedIngredientQuantity"] | undefined,
	listPreparedIngredientQuantity: Array<GraphQLTypes["PreparedIngredientQuantity"]>,
	paginatePreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityConnection"],
	validateCreatePreparedIngredientQuantity: GraphQLTypes["_ValidationResult"],
	validateUpdatePreparedIngredientQuantity: GraphQLTypes["_ValidationResult"],
	getPreparedRecipeQuantity?: GraphQLTypes["PreparedRecipeQuantity"] | undefined,
	listPreparedRecipeQuantity: Array<GraphQLTypes["PreparedRecipeQuantity"]>,
	paginatePreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityConnection"],
	validateCreatePreparedRecipeQuantity: GraphQLTypes["_ValidationResult"],
	validateUpdatePreparedRecipeQuantity: GraphQLTypes["_ValidationResult"],
	getPreparedPackingQuantity?: GraphQLTypes["PreparedPackingQuantity"] | undefined,
	listPreparedPackingQuantity: Array<GraphQLTypes["PreparedPackingQuantity"]>,
	paginatePreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityConnection"],
	validateCreatePreparedPackingQuantity: GraphQLTypes["_ValidationResult"],
	validateUpdatePreparedPackingQuantity: GraphQLTypes["_ValidationResult"],
	getDeliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	listDeliveryZone: Array<GraphQLTypes["DeliveryZone"]>,
	paginateDeliveryZone: GraphQLTypes["DeliveryZoneConnection"],
	getDeliveryTimelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	listDeliveryTimelinePreset: Array<GraphQLTypes["DeliveryTimelinePreset"]>,
	paginateDeliveryTimelinePreset: GraphQLTypes["DeliveryTimelinePresetConnection"],
	getProductPackingLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	listProductPackingLocale: Array<GraphQLTypes["ProductPackingLocale"]>,
	paginateProductPackingLocale: GraphQLTypes["ProductPackingLocaleConnection"],
	getProductLocale?: GraphQLTypes["ProductLocale"] | undefined,
	listProductLocale: Array<GraphQLTypes["ProductLocale"]>,
	paginateProductLocale: GraphQLTypes["ProductLocaleConnection"],
	getProductRecipeLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	listProductRecipeLocale: Array<GraphQLTypes["ProductRecipeLocale"]>,
	paginateProductRecipeLocale: GraphQLTypes["ProductRecipeLocaleConnection"],
	getSuppliesPurchase?: GraphQLTypes["SuppliesPurchase"] | undefined,
	listSuppliesPurchase: Array<GraphQLTypes["SuppliesPurchase"]>,
	paginateSuppliesPurchase: GraphQLTypes["SuppliesPurchaseConnection"],
	validateCreateSuppliesPurchase: GraphQLTypes["_ValidationResult"],
	validateUpdateSuppliesPurchase: GraphQLTypes["_ValidationResult"],
	getLogRecord?: GraphQLTypes["LogRecord"] | undefined,
	listLogRecord: Array<GraphQLTypes["LogRecord"]>,
	paginateLogRecord: GraphQLTypes["LogRecordConnection"],
	getSuppliesPurchaseItem?: GraphQLTypes["SuppliesPurchaseItem"] | undefined,
	listSuppliesPurchaseItem: Array<GraphQLTypes["SuppliesPurchaseItem"]>,
	paginateSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemConnection"],
	validateCreateSuppliesPurchaseItem: GraphQLTypes["_ValidationResult"],
	validateUpdateSuppliesPurchaseItem: GraphQLTypes["_ValidationResult"],
	getVirtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	listVirtualProduct: Array<GraphQLTypes["VirtualProduct"]>,
	paginateVirtualProduct: GraphQLTypes["VirtualProductConnection"],
	getProductMetadata?: GraphQLTypes["ProductMetadata"] | undefined,
	listProductMetadata: Array<GraphQLTypes["ProductMetadata"]>,
	paginateProductMetadata: GraphQLTypes["ProductMetadataConnection"],
	getProductGridCategory?: GraphQLTypes["ProductGridCategory"] | undefined,
	listProductGridCategory: Array<GraphQLTypes["ProductGridCategory"]>,
	paginateProductGridCategory: GraphQLTypes["ProductGridCategoryConnection"],
	getUnassignedShiftInfo?: GraphQLTypes["UnassignedShiftInfo"] | undefined,
	listUnassignedShiftInfo: Array<GraphQLTypes["UnassignedShiftInfo"]>,
	paginateUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoConnection"],
	validateCreateUnassignedShiftInfo: GraphQLTypes["_ValidationResult"],
	validateUpdateUnassignedShiftInfo: GraphQLTypes["_ValidationResult"],
	getFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	listFreshingContainer: Array<GraphQLTypes["FreshingContainer"]>,
	paginateFreshingContainer: GraphQLTypes["FreshingContainerConnection"],
	getTextItem?: GraphQLTypes["TextItem"] | undefined,
	listTextItem: Array<GraphQLTypes["TextItem"]>,
	paginateTextItem: GraphQLTypes["TextItemConnection"],
	getTextList?: GraphQLTypes["TextList"] | undefined,
	listTextList: Array<GraphQLTypes["TextList"]>,
	paginateTextList: GraphQLTypes["TextListConnection"],
	getDeliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	listDeliveryRegions: Array<GraphQLTypes["DeliveryRegions"]>,
	paginateDeliveryRegions: GraphQLTypes["DeliveryRegionsConnection"],
	getDeliveryRegionsItem?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	listDeliveryRegionsItem: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	paginateDeliveryRegionsItem: GraphQLTypes["DeliveryRegionsItemConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getTimetablesEmployeePrivileges?: GraphQLTypes["TimetablesEmployeePrivileges"] | undefined,
	listTimetablesEmployeePrivileges: Array<GraphQLTypes["TimetablesEmployeePrivileges"]>,
	paginateTimetablesEmployeePrivileges: GraphQLTypes["TimetablesEmployeePrivilegesConnection"],
	getTimetableDayNote?: GraphQLTypes["TimetableDayNote"] | undefined,
	listTimetableDayNote: Array<GraphQLTypes["TimetableDayNote"]>,
	paginateTimetableDayNote: GraphQLTypes["TimetableDayNoteConnection"],
	getDeliveryMethodLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	listDeliveryMethodLocale: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	paginateDeliveryMethodLocale: GraphQLTypes["DeliveryMethodLocaleConnection"],
	getIngredientSupplier?: GraphQLTypes["IngredientSupplier"] | undefined,
	listIngredientSupplier: Array<GraphQLTypes["IngredientSupplier"]>,
	paginateIngredientSupplier: GraphQLTypes["IngredientSupplierConnection"],
	getIngredientSupplierItem?: GraphQLTypes["IngredientSupplierItem"] | undefined,
	listIngredientSupplierItem: Array<GraphQLTypes["IngredientSupplierItem"]>,
	paginateIngredientSupplierItem: GraphQLTypes["IngredientSupplierItemConnection"],
	getIngredientSupplierList?: GraphQLTypes["IngredientSupplierList"] | undefined,
	listIngredientSupplierList: Array<GraphQLTypes["IngredientSupplierList"]>,
	paginateIngredientSupplierList: GraphQLTypes["IngredientSupplierListConnection"],
	getIngredientSupplierLocale?: GraphQLTypes["IngredientSupplierLocale"] | undefined,
	listIngredientSupplierLocale: Array<GraphQLTypes["IngredientSupplierLocale"]>,
	paginateIngredientSupplierLocale: GraphQLTypes["IngredientSupplierLocaleConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getVenue?: GraphQLTypes["Venue"] | undefined,
	listVenue: Array<GraphQLTypes["Venue"]>,
	paginateVenue: GraphQLTypes["VenueConnection"],
	getWorkspace?: GraphQLTypes["Workspace"] | undefined,
	listWorkspace: Array<GraphQLTypes["Workspace"]>,
	paginateWorkspace: GraphQLTypes["WorkspaceConnection"],
	getVenueEmployeeRole?: GraphQLTypes["VenueEmployeeRole"] | undefined,
	listVenueEmployeeRole: Array<GraphQLTypes["VenueEmployeeRole"]>,
	paginateVenueEmployeeRole: GraphQLTypes["VenueEmployeeRoleConnection"],
	getIdentificationAttempt?: GraphQLTypes["IdentificationAttempt"] | undefined,
	listIdentificationAttempt: Array<GraphQLTypes["IdentificationAttempt"]>,
	paginateIdentificationAttempt: GraphQLTypes["IdentificationAttemptConnection"],
	getIdentificationCode?: GraphQLTypes["IdentificationCode"] | undefined,
	listIdentificationCode: Array<GraphQLTypes["IdentificationCode"]>,
	paginateIdentificationCode: GraphQLTypes["IdentificationCodeConnection"],
	getIdentificationCodeLocale?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	listIdentificationCodeLocale: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	paginateIdentificationCodeLocale: GraphQLTypes["IdentificationCodeLocaleConnection"],
	getDeviceIdentifier?: GraphQLTypes["DeviceIdentifier"] | undefined,
	listDeviceIdentifier: Array<GraphQLTypes["DeviceIdentifier"]>,
	paginateDeviceIdentifier: GraphQLTypes["DeviceIdentifierConnection"],
	validateCreateDeviceIdentifier: GraphQLTypes["_ValidationResult"],
	validateUpdateDeviceIdentifier: GraphQLTypes["_ValidationResult"],
	getDeviceIdentifierMetadata?: GraphQLTypes["DeviceIdentifierMetadata"] | undefined,
	listDeviceIdentifierMetadata: Array<GraphQLTypes["DeviceIdentifierMetadata"]>,
	paginateDeviceIdentifierMetadata: GraphQLTypes["DeviceIdentifierMetadataConnection"],
	getFulfillmentNote?: GraphQLTypes["FulfillmentNote"] | undefined,
	listFulfillmentNote: Array<GraphQLTypes["FulfillmentNote"]>,
	paginateFulfillmentNote: GraphQLTypes["FulfillmentNoteConnection"],
	getCustomerMetadata?: GraphQLTypes["CustomerMetadata"] | undefined,
	listCustomerMetadata: Array<GraphQLTypes["CustomerMetadata"]>,
	paginateCustomerMetadata: GraphQLTypes["CustomerMetadataConnection"],
	getSubscriptionBox?: GraphQLTypes["SubscriptionBox"] | undefined,
	listSubscriptionBox: Array<GraphQLTypes["SubscriptionBox"]>,
	paginateSubscriptionBox: GraphQLTypes["SubscriptionBoxConnection"],
	getSubscriptionBoxLocale?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	listSubscriptionBoxLocale: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	paginateSubscriptionBoxLocale: GraphQLTypes["SubscriptionBoxLocaleConnection"],
	getTimetableDayMetadata?: GraphQLTypes["TimetableDayMetadata"] | undefined,
	listTimetableDayMetadata: Array<GraphQLTypes["TimetableDayMetadata"]>,
	paginateTimetableDayMetadata: GraphQLTypes["TimetableDayMetadataConnection"],
	getGridTileItem?: GraphQLTypes["GridTileItem"] | undefined,
	listGridTileItem: Array<GraphQLTypes["GridTileItem"]>,
	paginateGridTileItem: GraphQLTypes["GridTileItemConnection"],
	getGridTiles?: GraphQLTypes["GridTiles"] | undefined,
	listGridTiles: Array<GraphQLTypes["GridTiles"]>,
	paginateGridTiles: GraphQLTypes["GridTilesConnection"],
	getPosition?: GraphQLTypes["Position"] | undefined,
	listPosition: Array<GraphQLTypes["Position"]>,
	paginatePosition: GraphQLTypes["PositionConnection"],
	getStatsWidget?: GraphQLTypes["StatsWidget"] | undefined,
	listStatsWidget: Array<GraphQLTypes["StatsWidget"]>,
	paginateStatsWidget: GraphQLTypes["StatsWidgetConnection"],
	getStatsWidgetsLayout?: GraphQLTypes["StatsWidgetsLayout"] | undefined,
	listStatsWidgetsLayout: Array<GraphQLTypes["StatsWidgetsLayout"]>,
	paginateStatsWidgetsLayout: GraphQLTypes["StatsWidgetsLayoutConnection"],
	getStatsPage?: GraphQLTypes["StatsPage"] | undefined,
	listStatsPage: Array<GraphQLTypes["StatsPage"]>,
	paginateStatsPage: GraphQLTypes["StatsPageConnection"],
	getUnassignReason?: GraphQLTypes["UnassignReason"] | undefined,
	listUnassignReason: Array<GraphQLTypes["UnassignReason"]>,
	paginateUnassignReason: GraphQLTypes["UnassignReasonConnection"],
	getOrderPaymentStatsByDay?: GraphQLTypes["OrderPaymentStatsByDay"] | undefined,
	listOrderPaymentStatsByDay: Array<GraphQLTypes["OrderPaymentStatsByDay"]>,
	paginateOrderPaymentStatsByDay: GraphQLTypes["OrderPaymentStatsByDayConnection"]
};
	["Info"]: {
		description?: string | undefined
};
	["Mutation"]: {
		createEmployeeQueue: GraphQLTypes["EmployeeQueueCreateResult"],
	updateEmployeeQueue: GraphQLTypes["EmployeeQueueUpdateResult"],
	upsertEmployeeQueue: GraphQLTypes["EmployeeQueueUpsertResult"],
	updateTimetableShift: GraphQLTypes["TimetableShiftUpdateResult"],
	createEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemCreateResult"],
	deleteEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemDeleteResult"],
	updateEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemUpdateResult"],
	upsertEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemUpsertResult"],
	updateShiftsProfile: GraphQLTypes["ShiftsProfileUpdateResult"],
	createPreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityCreateResult"],
	deletePreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityDeleteResult"],
	updatePreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityUpdateResult"],
	upsertPreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityUpsertResult"],
	createPreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityCreateResult"],
	deletePreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityDeleteResult"],
	updatePreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityUpdateResult"],
	upsertPreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityUpsertResult"],
	createPreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityCreateResult"],
	deletePreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityDeleteResult"],
	updatePreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityUpdateResult"],
	upsertPreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityUpsertResult"],
	createSuppliesPurchase: GraphQLTypes["SuppliesPurchaseCreateResult"],
	updateSuppliesPurchase: GraphQLTypes["SuppliesPurchaseUpdateResult"],
	upsertSuppliesPurchase: GraphQLTypes["SuppliesPurchaseUpsertResult"],
	createSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemCreateResult"],
	updateSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemUpdateResult"],
	upsertSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemUpsertResult"],
	createUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoCreateResult"],
	deleteUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoDeleteResult"],
	updateUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoUpdateResult"],
	upsertUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoUpsertResult"],
	createDeviceIdentifier: GraphQLTypes["DeviceIdentifierCreateResult"],
	updateDeviceIdentifier: GraphQLTypes["DeviceIdentifierUpdateResult"],
	upsertDeviceIdentifier: GraphQLTypes["DeviceIdentifierUpsertResult"],
	transaction: GraphQLTypes["MutationTransaction"],
	query: GraphQLTypes["Query"]
};
	["EmployeeQueueCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["EmployeeQueue"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["MutationResult"]: ModelTypes["EmployeeQueueCreateResult"] | ModelTypes["EmployeeQueueUpdateResult"] | ModelTypes["EmployeeQueueUpsertResult"] | ModelTypes["TimetableShiftUpdateResult"] | ModelTypes["EmployeeQueueItemCreateResult"] | ModelTypes["EmployeeQueueItemDeleteResult"] | ModelTypes["EmployeeQueueItemUpdateResult"] | ModelTypes["EmployeeQueueItemUpsertResult"] | ModelTypes["ShiftsProfileUpdateResult"] | ModelTypes["PreparedIngredientQuantityCreateResult"] | ModelTypes["PreparedIngredientQuantityDeleteResult"] | ModelTypes["PreparedIngredientQuantityUpdateResult"] | ModelTypes["PreparedIngredientQuantityUpsertResult"] | ModelTypes["PreparedRecipeQuantityCreateResult"] | ModelTypes["PreparedRecipeQuantityDeleteResult"] | ModelTypes["PreparedRecipeQuantityUpdateResult"] | ModelTypes["PreparedRecipeQuantityUpsertResult"] | ModelTypes["PreparedPackingQuantityCreateResult"] | ModelTypes["PreparedPackingQuantityDeleteResult"] | ModelTypes["PreparedPackingQuantityUpdateResult"] | ModelTypes["PreparedPackingQuantityUpsertResult"] | ModelTypes["SuppliesPurchaseCreateResult"] | ModelTypes["SuppliesPurchaseUpdateResult"] | ModelTypes["SuppliesPurchaseUpsertResult"] | ModelTypes["SuppliesPurchaseItemCreateResult"] | ModelTypes["SuppliesPurchaseItemUpdateResult"] | ModelTypes["SuppliesPurchaseItemUpsertResult"] | ModelTypes["UnassignedShiftInfoCreateResult"] | ModelTypes["UnassignedShiftInfoDeleteResult"] | ModelTypes["UnassignedShiftInfoUpdateResult"] | ModelTypes["UnassignedShiftInfoUpsertResult"] | ModelTypes["DeviceIdentifierCreateResult"] | ModelTypes["DeviceIdentifierUpdateResult"] | ModelTypes["DeviceIdentifierUpsertResult"];
	["_MutationError"]: {
		path: Array<GraphQLTypes["_PathFragment"]>,
	paths: Array<Array<GraphQLTypes["_PathFragment"]>>,
	type: GraphQLTypes["_MutationErrorType"],
	message?: string | undefined
};
	["_MutationErrorType"]: GraphQLTypes["_MutationErrorType"];
	["EmployeeQueueUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["EmployeeQueue"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["EmployeeQueueUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["EmployeeQueue"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["TimetableShiftUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["TimetableShift"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["EmployeeQueueItemCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["EmployeeQueueItemDeleteResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["EmployeeQueueItem"] | undefined
};
	["EmployeeQueueItemUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["EmployeeQueueItemUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["ShiftsProfileUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["ShiftsProfile"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedIngredientQuantityCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedIngredientQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedIngredientQuantityDeleteResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedIngredientQuantity"] | undefined
};
	["PreparedIngredientQuantityUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedIngredientQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedIngredientQuantityUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedIngredientQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedRecipeQuantityCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedRecipeQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedRecipeQuantityDeleteResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedRecipeQuantity"] | undefined
};
	["PreparedRecipeQuantityUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedRecipeQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedRecipeQuantityUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedRecipeQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedPackingQuantityCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedPackingQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedPackingQuantityDeleteResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedPackingQuantity"] | undefined
};
	["PreparedPackingQuantityUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedPackingQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedPackingQuantityUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedPackingQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["SuppliesPurchaseCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["SuppliesPurchase"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["SuppliesPurchaseUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["SuppliesPurchase"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["SuppliesPurchaseUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["SuppliesPurchase"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["SuppliesPurchaseItemCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["SuppliesPurchaseItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["SuppliesPurchaseItemUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["SuppliesPurchaseItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["SuppliesPurchaseItemUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["SuppliesPurchaseItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["UnassignedShiftInfoCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["UnassignedShiftInfo"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["UnassignedShiftInfoDeleteResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["UnassignedShiftInfo"] | undefined
};
	["UnassignedShiftInfoUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["UnassignedShiftInfo"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["UnassignedShiftInfoUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["UnassignedShiftInfo"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["DeviceIdentifierCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["DeviceIdentifier"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["DeviceIdentifierUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["DeviceIdentifier"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["DeviceIdentifierUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["DeviceIdentifier"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["MutationTransaction"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	validation: GraphQLTypes["_ValidationResult"],
	createEmployeeQueue: GraphQLTypes["EmployeeQueueCreateResult"],
	updateEmployeeQueue: GraphQLTypes["EmployeeQueueUpdateResult"],
	upsertEmployeeQueue: GraphQLTypes["EmployeeQueueUpsertResult"],
	updateTimetableShift: GraphQLTypes["TimetableShiftUpdateResult"],
	createEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemCreateResult"],
	deleteEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemDeleteResult"],
	updateEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemUpdateResult"],
	upsertEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemUpsertResult"],
	updateShiftsProfile: GraphQLTypes["ShiftsProfileUpdateResult"],
	createPreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityCreateResult"],
	deletePreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityDeleteResult"],
	updatePreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityUpdateResult"],
	upsertPreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityUpsertResult"],
	createPreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityCreateResult"],
	deletePreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityDeleteResult"],
	updatePreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityUpdateResult"],
	upsertPreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityUpsertResult"],
	createPreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityCreateResult"],
	deletePreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityDeleteResult"],
	updatePreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityUpdateResult"],
	upsertPreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityUpsertResult"],
	createSuppliesPurchase: GraphQLTypes["SuppliesPurchaseCreateResult"],
	updateSuppliesPurchase: GraphQLTypes["SuppliesPurchaseUpdateResult"],
	upsertSuppliesPurchase: GraphQLTypes["SuppliesPurchaseUpsertResult"],
	createSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemCreateResult"],
	updateSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemUpdateResult"],
	upsertSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemUpsertResult"],
	createUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoCreateResult"],
	deleteUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoDeleteResult"],
	updateUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoUpdateResult"],
	upsertUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoUpsertResult"],
	createDeviceIdentifier: GraphQLTypes["DeviceIdentifierCreateResult"],
	updateDeviceIdentifier: GraphQLTypes["DeviceIdentifierUpdateResult"],
	upsertDeviceIdentifier: GraphQLTypes["DeviceIdentifierUpsertResult"],
	query?: GraphQLTypes["Query"] | undefined
};
	["MutationTransactionOptions"]: GraphQLTypes["MutationTransactionOptions"];
	["_Schema"]: {
		enums: Array<GraphQLTypes["_Enum"]>,
	entities: Array<GraphQLTypes["_Entity"]>
};
	["_Entity"]: {
		name: string,
	customPrimaryAllowed: boolean,
	fields: Array<GraphQLTypes["_Field"]>,
	unique: Array<GraphQLTypes["_UniqueConstraint"]>
};
	["_UniqueConstraint"]: {
		fields: Array<string>
};
	["_Field"]: ModelTypes["_Column"] | ModelTypes["_Relation"];
	["_Column"]: {
		name: string,
	type: string,
	enumName?: string | undefined,
	defaultValue?: GraphQLTypes["Json"] | undefined,
	nullable: boolean,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_OnDeleteBehaviour"]: GraphQLTypes["_OnDeleteBehaviour"];
	["_RelationSide"]: GraphQLTypes["_RelationSide"];
	["_OrderByDirection"]: GraphQLTypes["_OrderByDirection"];
	["_OrderBy"]: {
		path: Array<string>,
	direction: GraphQLTypes["_OrderByDirection"]
};
	["_Relation"]: {
		name: string,
	type: string,
	side: GraphQLTypes["_RelationSide"],
	targetEntity: string,
	ownedBy?: string | undefined,
	inversedBy?: string | undefined,
	nullable?: boolean | undefined,
	onDelete?: GraphQLTypes["_OnDeleteBehaviour"] | undefined,
	orphanRemoval?: boolean | undefined,
	orderBy?: Array<GraphQLTypes["_OrderBy"]> | undefined,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_Rule"]: {
		message?: GraphQLTypes["_RuleMessage"] | undefined,
	validator: number
};
	["_Validator"]: {
		operation: string,
	arguments: Array<GraphQLTypes["_Argument"]>
};
	["_Argument"]:ModelTypes["_ValidatorArgument"] | ModelTypes["_PathArgument"] | ModelTypes["_LiteralArgument"];
	["_ValidatorArgument"]: {
		validator: number
};
	["_PathArgument"]: {
		path: Array<string>
};
	["_LiteralArgument"]: {
		value?: GraphQLTypes["Json"] | undefined
};
	["_RuleMessage"]: {
		text?: string | undefined
};
	["_Enum"]: {
		name: string,
	values: Array<string>
}
    }

export type GraphQLTypes = {
    ["Query"]: {
	__typename: "Query",
	getAddress?: GraphQLTypes["Address"] | undefined,
	listAddress: Array<GraphQLTypes["Address"]>,
	paginateAddress: GraphQLTypes["AddressConnection"],
	getCountry?: GraphQLTypes["Country"] | undefined,
	listCountry: Array<GraphQLTypes["Country"]>,
	paginateCountry: GraphQLTypes["CountryConnection"],
	getChannel?: GraphQLTypes["Channel"] | undefined,
	listChannel: Array<GraphQLTypes["Channel"]>,
	paginateChannel: GraphQLTypes["ChannelConnection"],
	getCustomer?: GraphQLTypes["Customer"] | undefined,
	listCustomer: Array<GraphQLTypes["Customer"]>,
	paginateCustomer: GraphQLTypes["CustomerConnection"],
	getDeliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined,
	listDeliveryMethod: Array<GraphQLTypes["DeliveryMethod"]>,
	paginateDeliveryMethod: GraphQLTypes["DeliveryMethodConnection"],
	getOrder?: GraphQLTypes["Order"] | undefined,
	listOrder: Array<GraphQLTypes["Order"]>,
	paginateOrder: GraphQLTypes["OrderConnection"],
	getOrderPayment?: GraphQLTypes["OrderPayment"] | undefined,
	listOrderPayment: Array<GraphQLTypes["OrderPayment"]>,
	paginateOrderPayment: GraphQLTypes["OrderPaymentConnection"],
	getOrderDelivery?: GraphQLTypes["OrderDelivery"] | undefined,
	listOrderDelivery: Array<GraphQLTypes["OrderDelivery"]>,
	paginateOrderDelivery: GraphQLTypes["OrderDeliveryConnection"],
	getOrderItem?: GraphQLTypes["OrderItem"] | undefined,
	listOrderItem: Array<GraphQLTypes["OrderItem"]>,
	paginateOrderItem: GraphQLTypes["OrderItemConnection"],
	getPaymentMethod?: GraphQLTypes["PaymentMethod"] | undefined,
	listPaymentMethod: Array<GraphQLTypes["PaymentMethod"]>,
	paginatePaymentMethod: GraphQLTypes["PaymentMethodConnection"],
	getVatRate?: GraphQLTypes["VatRate"] | undefined,
	listVatRate: Array<GraphQLTypes["VatRate"]>,
	paginateVatRate: GraphQLTypes["VatRateConnection"],
	getProduct?: GraphQLTypes["Product"] | undefined,
	listProduct: Array<GraphQLTypes["Product"]>,
	paginateProduct: GraphQLTypes["ProductConnection"],
	getProductStock?: GraphQLTypes["ProductStock"] | undefined,
	listProductStock: Array<GraphQLTypes["ProductStock"]>,
	paginateProductStock: GraphQLTypes["ProductStockConnection"],
	getProductPacking?: GraphQLTypes["ProductPacking"] | undefined,
	listProductPacking: Array<GraphQLTypes["ProductPacking"]>,
	paginateProductPacking: GraphQLTypes["ProductPackingConnection"],
	getProductRecipe?: GraphQLTypes["ProductRecipe"] | undefined,
	listProductRecipe: Array<GraphQLTypes["ProductRecipe"]>,
	paginateProductRecipe: GraphQLTypes["ProductRecipeConnection"],
	getProductCategory?: GraphQLTypes["ProductCategory"] | undefined,
	listProductCategory: Array<GraphQLTypes["ProductCategory"]>,
	paginateProductCategory: GraphQLTypes["ProductCategoryConnection"],
	getStore?: GraphQLTypes["Store"] | undefined,
	listStore: Array<GraphQLTypes["Store"]>,
	paginateStore: GraphQLTypes["StoreConnection"],
	getTag?: GraphQLTypes["Tag"] | undefined,
	listTag: Array<GraphQLTypes["Tag"]>,
	paginateTag: GraphQLTypes["TagConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	listBusinessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"],
	getProductList?: GraphQLTypes["ProductList"] | undefined,
	listProductList: Array<GraphQLTypes["ProductList"]>,
	paginateProductList: GraphQLTypes["ProductListConnection"],
	getProductListItem?: GraphQLTypes["ProductListItem"] | undefined,
	listProductListItem: Array<GraphQLTypes["ProductListItem"]>,
	paginateProductListItem: GraphQLTypes["ProductListItemConnection"],
	getProductCategoryList?: GraphQLTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<GraphQLTypes["ProductCategoryList"]>,
	paginateProductCategoryList: GraphQLTypes["ProductCategoryListConnection"],
	getProductCategoryListItem?: GraphQLTypes["ProductCategoryListItem"] | undefined,
	listProductCategoryListItem: Array<GraphQLTypes["ProductCategoryListItem"]>,
	paginateProductCategoryListItem: GraphQLTypes["ProductCategoryListItemConnection"],
	getBusinessCategoryLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	listBusinessCategoryLocale: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	paginateBusinessCategoryLocale: GraphQLTypes["BusinessCategoryLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getProductCategoryListLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	listProductCategoryListLocale: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	paginateProductCategoryListLocale: GraphQLTypes["ProductCategoryListLocaleConnection"],
	getCustomerName?: GraphQLTypes["CustomerName"] | undefined,
	listCustomerName: Array<GraphQLTypes["CustomerName"]>,
	paginateCustomerName: GraphQLTypes["CustomerNameConnection"],
	getProductIngredientItem?: GraphQLTypes["ProductIngredientItem"] | undefined,
	listProductIngredientItem: Array<GraphQLTypes["ProductIngredientItem"]>,
	paginateProductIngredientItem: GraphQLTypes["ProductIngredientItemConnection"],
	getProductIngredientItemQuantity?: GraphQLTypes["ProductIngredientItemQuantity"] | undefined,
	listProductIngredientItemQuantity: Array<GraphQLTypes["ProductIngredientItemQuantity"]>,
	paginateProductIngredientItemQuantity: GraphQLTypes["ProductIngredientItemQuantityConnection"],
	getProductIngredientItemQuantityUnit?: GraphQLTypes["ProductIngredientItemQuantityUnit"] | undefined,
	listProductIngredientItemQuantityUnit: Array<GraphQLTypes["ProductIngredientItemQuantityUnit"]>,
	paginateProductIngredientItemQuantityUnit: GraphQLTypes["ProductIngredientItemQuantityUnitConnection"],
	getProductIngredient?: GraphQLTypes["ProductIngredient"] | undefined,
	listProductIngredient: Array<GraphQLTypes["ProductIngredient"]>,
	paginateProductIngredient: GraphQLTypes["ProductIngredientConnection"],
	getProductIngredientRating?: GraphQLTypes["ProductIngredientRating"] | undefined,
	listProductIngredientRating: Array<GraphQLTypes["ProductIngredientRating"]>,
	paginateProductIngredientRating: GraphQLTypes["ProductIngredientRatingConnection"],
	getProductIngredientLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	listProductIngredientLocale: Array<GraphQLTypes["ProductIngredientLocale"]>,
	paginateProductIngredientLocale: GraphQLTypes["ProductIngredientLocaleConnection"],
	getOrderRecurrence?: GraphQLTypes["OrderRecurrence"] | undefined,
	listOrderRecurrence: Array<GraphQLTypes["OrderRecurrence"]>,
	paginateOrderRecurrence: GraphQLTypes["OrderRecurrenceConnection"],
	getOrderRecurrenceLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	listOrderRecurrenceLocale: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	paginateOrderRecurrenceLocale: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	getStaff?: GraphQLTypes["Staff"] | undefined,
	listStaff: Array<GraphQLTypes["Staff"]>,
	paginateStaff: GraphQLTypes["StaffConnection"],
	getBlog?: GraphQLTypes["Blog"] | undefined,
	listBlog: Array<GraphQLTypes["Blog"]>,
	paginateBlog: GraphQLTypes["BlogConnection"],
	getBlogLocale?: GraphQLTypes["BlogLocale"] | undefined,
	listBlogLocale: Array<GraphQLTypes["BlogLocale"]>,
	paginateBlogLocale: GraphQLTypes["BlogLocaleConnection"],
	getBlogPost?: GraphQLTypes["BlogPost"] | undefined,
	listBlogPost: Array<GraphQLTypes["BlogPost"]>,
	paginateBlogPost: GraphQLTypes["BlogPostConnection"],
	getBlogPostLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<GraphQLTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: GraphQLTypes["BlogPostLocaleConnection"],
	getColorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	listColorPallete: Array<GraphQLTypes["ColorPallete"]>,
	paginateColorPallete: GraphQLTypes["ColorPalleteConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getBlogPostList?: GraphQLTypes["BlogPostList"] | undefined,
	listBlogPostList: Array<GraphQLTypes["BlogPostList"]>,
	paginateBlogPostList: GraphQLTypes["BlogPostListConnection"],
	getBlogPostListItem?: GraphQLTypes["BlogPostListItem"] | undefined,
	listBlogPostListItem: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateBlogPostListItem: GraphQLTypes["BlogPostListItemConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getLinkListItem?: GraphQLTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<GraphQLTypes["LinkListItem"]>,
	paginateLinkListItem: GraphQLTypes["LinkListItemConnection"],
	getCover?: GraphQLTypes["Cover"] | undefined,
	listCover: Array<GraphQLTypes["Cover"]>,
	paginateCover: GraphQLTypes["CoverConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getMedium?: GraphQLTypes["Medium"] | undefined,
	listMedium: Array<GraphQLTypes["Medium"]>,
	paginateMedium: GraphQLTypes["MediumConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getSocialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	listSocialsAndApps: Array<GraphQLTypes["SocialsAndApps"]>,
	paginateSocialsAndApps: GraphQLTypes["SocialsAndAppsConnection"],
	getProductGrid?: GraphQLTypes["ProductGrid"] | undefined,
	listProductGrid: Array<GraphQLTypes["ProductGrid"]>,
	paginateProductGrid: GraphQLTypes["ProductGridConnection"],
	getProductGridItem?: GraphQLTypes["ProductGridItem"] | undefined,
	listProductGridItem: Array<GraphQLTypes["ProductGridItem"]>,
	paginateProductGridItem: GraphQLTypes["ProductGridItemConnection"],
	getGallery?: GraphQLTypes["Gallery"] | undefined,
	listGallery: Array<GraphQLTypes["Gallery"]>,
	paginateGallery: GraphQLTypes["GalleryConnection"],
	getGalleryItem?: GraphQLTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<GraphQLTypes["GalleryItem"]>,
	paginateGalleryItem: GraphQLTypes["GalleryItemConnection"],
	getHero?: GraphQLTypes["Hero"] | undefined,
	listHero: Array<GraphQLTypes["Hero"]>,
	paginateHero: GraphQLTypes["HeroConnection"],
	getPage?: GraphQLTypes["Page"] | undefined,
	listPage: Array<GraphQLTypes["Page"]>,
	paginatePage: GraphQLTypes["PageConnection"],
	getPageLocale?: GraphQLTypes["PageLocale"] | undefined,
	listPageLocale: Array<GraphQLTypes["PageLocale"]>,
	paginatePageLocale: GraphQLTypes["PageLocaleConnection"],
	getDeadlineTemplate?: GraphQLTypes["DeadlineTemplate"] | undefined,
	listDeadlineTemplate: Array<GraphQLTypes["DeadlineTemplate"]>,
	paginateDeadlineTemplate: GraphQLTypes["DeadlineTemplateConnection"],
	getEmployeeQueue?: GraphQLTypes["EmployeeQueue"] | undefined,
	listEmployeeQueue: Array<GraphQLTypes["EmployeeQueue"]>,
	paginateEmployeeQueue: GraphQLTypes["EmployeeQueueConnection"],
	validateCreateEmployeeQueue: GraphQLTypes["_ValidationResult"],
	validateUpdateEmployeeQueue: GraphQLTypes["_ValidationResult"],
	getTimetableDay?: GraphQLTypes["TimetableDay"] | undefined,
	listTimetableDay: Array<GraphQLTypes["TimetableDay"]>,
	paginateTimetableDay: GraphQLTypes["TimetableDayConnection"],
	getTimetableEmployeeRole?: GraphQLTypes["TimetableEmployeeRole"] | undefined,
	listTimetableEmployeeRole: Array<GraphQLTypes["TimetableEmployeeRole"]>,
	paginateTimetableEmployeeRole: GraphQLTypes["TimetableEmployeeRoleConnection"],
	getTimetableShift?: GraphQLTypes["TimetableShift"] | undefined,
	listTimetableShift: Array<GraphQLTypes["TimetableShift"]>,
	paginateTimetableShift: GraphQLTypes["TimetableShiftConnection"],
	validateUpdateTimetableShift: GraphQLTypes["_ValidationResult"],
	getTimetableShiftGroup?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	listTimetableShiftGroup: Array<GraphQLTypes["TimetableShiftGroup"]>,
	paginateTimetableShiftGroup: GraphQLTypes["TimetableShiftGroupConnection"],
	getTimetableShiftPosition?: GraphQLTypes["TimetableShiftPosition"] | undefined,
	listTimetableShiftPosition: Array<GraphQLTypes["TimetableShiftPosition"]>,
	paginateTimetableShiftPosition: GraphQLTypes["TimetableShiftPositionConnection"],
	getTimetableTemplate?: GraphQLTypes["TimetableTemplate"] | undefined,
	listTimetableTemplate: Array<GraphQLTypes["TimetableTemplate"]>,
	paginateTimetableTemplate: GraphQLTypes["TimetableTemplateConnection"],
	getEmployeeQueueItem?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	listEmployeeQueueItem: Array<GraphQLTypes["EmployeeQueueItem"]>,
	paginateEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemConnection"],
	validateCreateEmployeeQueueItem: GraphQLTypes["_ValidationResult"],
	validateUpdateEmployeeQueueItem: GraphQLTypes["_ValidationResult"],
	getProductBanner?: GraphQLTypes["ProductBanner"] | undefined,
	listProductBanner: Array<GraphQLTypes["ProductBanner"]>,
	paginateProductBanner: GraphQLTypes["ProductBannerConnection"],
	getProductBannerItem?: GraphQLTypes["ProductBannerItem"] | undefined,
	listProductBannerItem: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateProductBannerItem: GraphQLTypes["ProductBannerItemConnection"],
	getProductBannerList?: GraphQLTypes["ProductBannerList"] | undefined,
	listProductBannerList: Array<GraphQLTypes["ProductBannerList"]>,
	paginateProductBannerList: GraphQLTypes["ProductBannerListConnection"],
	getShiftsProfile?: GraphQLTypes["ShiftsProfile"] | undefined,
	listShiftsProfile: Array<GraphQLTypes["ShiftsProfile"]>,
	paginateShiftsProfile: GraphQLTypes["ShiftsProfileConnection"],
	validateUpdateShiftsProfile: GraphQLTypes["_ValidationResult"],
	getProductIngredientCategory?: GraphQLTypes["ProductIngredientCategory"] | undefined,
	listProductIngredientCategory: Array<GraphQLTypes["ProductIngredientCategory"]>,
	paginateProductIngredientCategory: GraphQLTypes["ProductIngredientCategoryConnection"],
	getProductIngredientCategoryLocale?: GraphQLTypes["ProductIngredientCategoryLocale"] | undefined,
	listProductIngredientCategoryLocale: Array<GraphQLTypes["ProductIngredientCategoryLocale"]>,
	paginateProductIngredientCategoryLocale: GraphQLTypes["ProductIngredientCategoryLocaleConnection"],
	getDeliveryRoute?: GraphQLTypes["DeliveryRoute"] | undefined,
	listDeliveryRoute: Array<GraphQLTypes["DeliveryRoute"]>,
	paginateDeliveryRoute: GraphQLTypes["DeliveryRouteConnection"],
	getDeliveryWaypoint?: GraphQLTypes["DeliveryWaypoint"] | undefined,
	listDeliveryWaypoint: Array<GraphQLTypes["DeliveryWaypoint"]>,
	paginateDeliveryWaypoint: GraphQLTypes["DeliveryWaypointConnection"],
	getAllergen?: GraphQLTypes["Allergen"] | undefined,
	listAllergen: Array<GraphQLTypes["Allergen"]>,
	paginateAllergen: GraphQLTypes["AllergenConnection"],
	getAllergenLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	listAllergenLocale: Array<GraphQLTypes["AllergenLocale"]>,
	paginateAllergenLocale: GraphQLTypes["AllergenLocaleConnection"],
	getWeekHours?: GraphQLTypes["WeekHours"] | undefined,
	listWeekHours: Array<GraphQLTypes["WeekHours"]>,
	paginateWeekHours: GraphQLTypes["WeekHoursConnection"],
	getPreparedIngredientQuantity?: GraphQLTypes["PreparedIngredientQuantity"] | undefined,
	listPreparedIngredientQuantity: Array<GraphQLTypes["PreparedIngredientQuantity"]>,
	paginatePreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityConnection"],
	validateCreatePreparedIngredientQuantity: GraphQLTypes["_ValidationResult"],
	validateUpdatePreparedIngredientQuantity: GraphQLTypes["_ValidationResult"],
	getPreparedRecipeQuantity?: GraphQLTypes["PreparedRecipeQuantity"] | undefined,
	listPreparedRecipeQuantity: Array<GraphQLTypes["PreparedRecipeQuantity"]>,
	paginatePreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityConnection"],
	validateCreatePreparedRecipeQuantity: GraphQLTypes["_ValidationResult"],
	validateUpdatePreparedRecipeQuantity: GraphQLTypes["_ValidationResult"],
	getPreparedPackingQuantity?: GraphQLTypes["PreparedPackingQuantity"] | undefined,
	listPreparedPackingQuantity: Array<GraphQLTypes["PreparedPackingQuantity"]>,
	paginatePreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityConnection"],
	validateCreatePreparedPackingQuantity: GraphQLTypes["_ValidationResult"],
	validateUpdatePreparedPackingQuantity: GraphQLTypes["_ValidationResult"],
	getDeliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	listDeliveryZone: Array<GraphQLTypes["DeliveryZone"]>,
	paginateDeliveryZone: GraphQLTypes["DeliveryZoneConnection"],
	getDeliveryTimelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	listDeliveryTimelinePreset: Array<GraphQLTypes["DeliveryTimelinePreset"]>,
	paginateDeliveryTimelinePreset: GraphQLTypes["DeliveryTimelinePresetConnection"],
	getProductPackingLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	listProductPackingLocale: Array<GraphQLTypes["ProductPackingLocale"]>,
	paginateProductPackingLocale: GraphQLTypes["ProductPackingLocaleConnection"],
	getProductLocale?: GraphQLTypes["ProductLocale"] | undefined,
	listProductLocale: Array<GraphQLTypes["ProductLocale"]>,
	paginateProductLocale: GraphQLTypes["ProductLocaleConnection"],
	getProductRecipeLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	listProductRecipeLocale: Array<GraphQLTypes["ProductRecipeLocale"]>,
	paginateProductRecipeLocale: GraphQLTypes["ProductRecipeLocaleConnection"],
	getSuppliesPurchase?: GraphQLTypes["SuppliesPurchase"] | undefined,
	listSuppliesPurchase: Array<GraphQLTypes["SuppliesPurchase"]>,
	paginateSuppliesPurchase: GraphQLTypes["SuppliesPurchaseConnection"],
	validateCreateSuppliesPurchase: GraphQLTypes["_ValidationResult"],
	validateUpdateSuppliesPurchase: GraphQLTypes["_ValidationResult"],
	getLogRecord?: GraphQLTypes["LogRecord"] | undefined,
	listLogRecord: Array<GraphQLTypes["LogRecord"]>,
	paginateLogRecord: GraphQLTypes["LogRecordConnection"],
	getSuppliesPurchaseItem?: GraphQLTypes["SuppliesPurchaseItem"] | undefined,
	listSuppliesPurchaseItem: Array<GraphQLTypes["SuppliesPurchaseItem"]>,
	paginateSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemConnection"],
	validateCreateSuppliesPurchaseItem: GraphQLTypes["_ValidationResult"],
	validateUpdateSuppliesPurchaseItem: GraphQLTypes["_ValidationResult"],
	getVirtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	listVirtualProduct: Array<GraphQLTypes["VirtualProduct"]>,
	paginateVirtualProduct: GraphQLTypes["VirtualProductConnection"],
	getProductMetadata?: GraphQLTypes["ProductMetadata"] | undefined,
	listProductMetadata: Array<GraphQLTypes["ProductMetadata"]>,
	paginateProductMetadata: GraphQLTypes["ProductMetadataConnection"],
	getProductGridCategory?: GraphQLTypes["ProductGridCategory"] | undefined,
	listProductGridCategory: Array<GraphQLTypes["ProductGridCategory"]>,
	paginateProductGridCategory: GraphQLTypes["ProductGridCategoryConnection"],
	getUnassignedShiftInfo?: GraphQLTypes["UnassignedShiftInfo"] | undefined,
	listUnassignedShiftInfo: Array<GraphQLTypes["UnassignedShiftInfo"]>,
	paginateUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoConnection"],
	validateCreateUnassignedShiftInfo: GraphQLTypes["_ValidationResult"],
	validateUpdateUnassignedShiftInfo: GraphQLTypes["_ValidationResult"],
	getFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	listFreshingContainer: Array<GraphQLTypes["FreshingContainer"]>,
	paginateFreshingContainer: GraphQLTypes["FreshingContainerConnection"],
	getTextItem?: GraphQLTypes["TextItem"] | undefined,
	listTextItem: Array<GraphQLTypes["TextItem"]>,
	paginateTextItem: GraphQLTypes["TextItemConnection"],
	getTextList?: GraphQLTypes["TextList"] | undefined,
	listTextList: Array<GraphQLTypes["TextList"]>,
	paginateTextList: GraphQLTypes["TextListConnection"],
	getDeliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	listDeliveryRegions: Array<GraphQLTypes["DeliveryRegions"]>,
	paginateDeliveryRegions: GraphQLTypes["DeliveryRegionsConnection"],
	getDeliveryRegionsItem?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	listDeliveryRegionsItem: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	paginateDeliveryRegionsItem: GraphQLTypes["DeliveryRegionsItemConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getTimetablesEmployeePrivileges?: GraphQLTypes["TimetablesEmployeePrivileges"] | undefined,
	listTimetablesEmployeePrivileges: Array<GraphQLTypes["TimetablesEmployeePrivileges"]>,
	paginateTimetablesEmployeePrivileges: GraphQLTypes["TimetablesEmployeePrivilegesConnection"],
	getTimetableDayNote?: GraphQLTypes["TimetableDayNote"] | undefined,
	listTimetableDayNote: Array<GraphQLTypes["TimetableDayNote"]>,
	paginateTimetableDayNote: GraphQLTypes["TimetableDayNoteConnection"],
	getDeliveryMethodLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	listDeliveryMethodLocale: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	paginateDeliveryMethodLocale: GraphQLTypes["DeliveryMethodLocaleConnection"],
	getIngredientSupplier?: GraphQLTypes["IngredientSupplier"] | undefined,
	listIngredientSupplier: Array<GraphQLTypes["IngredientSupplier"]>,
	paginateIngredientSupplier: GraphQLTypes["IngredientSupplierConnection"],
	getIngredientSupplierItem?: GraphQLTypes["IngredientSupplierItem"] | undefined,
	listIngredientSupplierItem: Array<GraphQLTypes["IngredientSupplierItem"]>,
	paginateIngredientSupplierItem: GraphQLTypes["IngredientSupplierItemConnection"],
	getIngredientSupplierList?: GraphQLTypes["IngredientSupplierList"] | undefined,
	listIngredientSupplierList: Array<GraphQLTypes["IngredientSupplierList"]>,
	paginateIngredientSupplierList: GraphQLTypes["IngredientSupplierListConnection"],
	getIngredientSupplierLocale?: GraphQLTypes["IngredientSupplierLocale"] | undefined,
	listIngredientSupplierLocale: Array<GraphQLTypes["IngredientSupplierLocale"]>,
	paginateIngredientSupplierLocale: GraphQLTypes["IngredientSupplierLocaleConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getVenue?: GraphQLTypes["Venue"] | undefined,
	listVenue: Array<GraphQLTypes["Venue"]>,
	paginateVenue: GraphQLTypes["VenueConnection"],
	getWorkspace?: GraphQLTypes["Workspace"] | undefined,
	listWorkspace: Array<GraphQLTypes["Workspace"]>,
	paginateWorkspace: GraphQLTypes["WorkspaceConnection"],
	getVenueEmployeeRole?: GraphQLTypes["VenueEmployeeRole"] | undefined,
	listVenueEmployeeRole: Array<GraphQLTypes["VenueEmployeeRole"]>,
	paginateVenueEmployeeRole: GraphQLTypes["VenueEmployeeRoleConnection"],
	getIdentificationAttempt?: GraphQLTypes["IdentificationAttempt"] | undefined,
	listIdentificationAttempt: Array<GraphQLTypes["IdentificationAttempt"]>,
	paginateIdentificationAttempt: GraphQLTypes["IdentificationAttemptConnection"],
	getIdentificationCode?: GraphQLTypes["IdentificationCode"] | undefined,
	listIdentificationCode: Array<GraphQLTypes["IdentificationCode"]>,
	paginateIdentificationCode: GraphQLTypes["IdentificationCodeConnection"],
	getIdentificationCodeLocale?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	listIdentificationCodeLocale: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	paginateIdentificationCodeLocale: GraphQLTypes["IdentificationCodeLocaleConnection"],
	getDeviceIdentifier?: GraphQLTypes["DeviceIdentifier"] | undefined,
	listDeviceIdentifier: Array<GraphQLTypes["DeviceIdentifier"]>,
	paginateDeviceIdentifier: GraphQLTypes["DeviceIdentifierConnection"],
	validateCreateDeviceIdentifier: GraphQLTypes["_ValidationResult"],
	validateUpdateDeviceIdentifier: GraphQLTypes["_ValidationResult"],
	getDeviceIdentifierMetadata?: GraphQLTypes["DeviceIdentifierMetadata"] | undefined,
	listDeviceIdentifierMetadata: Array<GraphQLTypes["DeviceIdentifierMetadata"]>,
	paginateDeviceIdentifierMetadata: GraphQLTypes["DeviceIdentifierMetadataConnection"],
	getFulfillmentNote?: GraphQLTypes["FulfillmentNote"] | undefined,
	listFulfillmentNote: Array<GraphQLTypes["FulfillmentNote"]>,
	paginateFulfillmentNote: GraphQLTypes["FulfillmentNoteConnection"],
	getCustomerMetadata?: GraphQLTypes["CustomerMetadata"] | undefined,
	listCustomerMetadata: Array<GraphQLTypes["CustomerMetadata"]>,
	paginateCustomerMetadata: GraphQLTypes["CustomerMetadataConnection"],
	getSubscriptionBox?: GraphQLTypes["SubscriptionBox"] | undefined,
	listSubscriptionBox: Array<GraphQLTypes["SubscriptionBox"]>,
	paginateSubscriptionBox: GraphQLTypes["SubscriptionBoxConnection"],
	getSubscriptionBoxLocale?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	listSubscriptionBoxLocale: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	paginateSubscriptionBoxLocale: GraphQLTypes["SubscriptionBoxLocaleConnection"],
	getTimetableDayMetadata?: GraphQLTypes["TimetableDayMetadata"] | undefined,
	listTimetableDayMetadata: Array<GraphQLTypes["TimetableDayMetadata"]>,
	paginateTimetableDayMetadata: GraphQLTypes["TimetableDayMetadataConnection"],
	getGridTileItem?: GraphQLTypes["GridTileItem"] | undefined,
	listGridTileItem: Array<GraphQLTypes["GridTileItem"]>,
	paginateGridTileItem: GraphQLTypes["GridTileItemConnection"],
	getGridTiles?: GraphQLTypes["GridTiles"] | undefined,
	listGridTiles: Array<GraphQLTypes["GridTiles"]>,
	paginateGridTiles: GraphQLTypes["GridTilesConnection"],
	getPosition?: GraphQLTypes["Position"] | undefined,
	listPosition: Array<GraphQLTypes["Position"]>,
	paginatePosition: GraphQLTypes["PositionConnection"],
	getStatsWidget?: GraphQLTypes["StatsWidget"] | undefined,
	listStatsWidget: Array<GraphQLTypes["StatsWidget"]>,
	paginateStatsWidget: GraphQLTypes["StatsWidgetConnection"],
	getStatsWidgetsLayout?: GraphQLTypes["StatsWidgetsLayout"] | undefined,
	listStatsWidgetsLayout: Array<GraphQLTypes["StatsWidgetsLayout"]>,
	paginateStatsWidgetsLayout: GraphQLTypes["StatsWidgetsLayoutConnection"],
	getStatsPage?: GraphQLTypes["StatsPage"] | undefined,
	listStatsPage: Array<GraphQLTypes["StatsPage"]>,
	paginateStatsPage: GraphQLTypes["StatsPageConnection"],
	getUnassignReason?: GraphQLTypes["UnassignReason"] | undefined,
	listUnassignReason: Array<GraphQLTypes["UnassignReason"]>,
	paginateUnassignReason: GraphQLTypes["UnassignReasonConnection"],
	getOrderPaymentStatsByDay?: GraphQLTypes["OrderPaymentStatsByDay"] | undefined,
	listOrderPaymentStatsByDay: Array<GraphQLTypes["OrderPaymentStatsByDay"]>,
	paginateOrderPaymentStatsByDay: GraphQLTypes["OrderPaymentStatsByDayConnection"],
	transaction?: GraphQLTypes["QueryTransaction"] | undefined,
	_info?: GraphQLTypes["Info"] | undefined,
	schema?: GraphQLTypes["_Schema"] | undefined
};
	["Address"]: {
	__typename: "Address",
	_meta?: GraphQLTypes["AddressMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden: boolean,
	customer?: GraphQLTypes["Customer"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Country"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders: Array<GraphQLTypes["Order"]>,
	deliveryAddressOfOrderDeliveries: Array<GraphQLTypes["OrderDelivery"]>,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Customer"] | undefined,
	billingAddressOfOrdersBySeq?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDelivery?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByItems?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByPayments?: GraphQLTypes["Order"] | undefined,
	deliveryAddressOfOrderDeliveriesByTrackingCode?: GraphQLTypes["OrderDelivery"] | undefined,
	deliveryAddressOfOrderDeliveriesByOrder?: GraphQLTypes["OrderDelivery"] | undefined,
	deliveryAddressOfOrderDeliveriesByDeliveryWaypoint?: GraphQLTypes["OrderDelivery"] | undefined,
	paginateBillingAddressOfOrders: GraphQLTypes["OrderConnection"],
	paginateDeliveryAddressOfOrderDeliveries: GraphQLTypes["OrderDeliveryConnection"]
};
	["AddressMeta"]: {
	__typename: "AddressMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	firstName?: GraphQLTypes["FieldMeta"] | undefined,
	lastName?: GraphQLTypes["FieldMeta"] | undefined,
	addressLine2?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	phone?: GraphQLTypes["FieldMeta"] | undefined,
	companyName?: GraphQLTypes["FieldMeta"] | undefined,
	companyIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	vatIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	externalIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	hidden?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	country?: GraphQLTypes["FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	googlePlaceId?: GraphQLTypes["FieldMeta"] | undefined,
	raw?: GraphQLTypes["FieldMeta"] | undefined,
	formatted?: GraphQLTypes["FieldMeta"] | undefined,
	geocodeGeneratedAt?: GraphQLTypes["FieldMeta"] | undefined,
	geocodeResponse?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZone?: GraphQLTypes["FieldMeta"] | undefined,
	geocodeValid?: GraphQLTypes["FieldMeta"] | undefined,
	fullName?: GraphQLTypes["FieldMeta"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["FieldMeta"] | undefined,
	addressLine1?: GraphQLTypes["FieldMeta"] | undefined,
	city?: GraphQLTypes["FieldMeta"] | undefined,
	postalCode?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	billingAddressOfOrders?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryAddressOfOrderDeliveries?: GraphQLTypes["FieldMeta"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["FieldMeta"] | undefined
};
	["FieldMeta"]: {
	__typename: "FieldMeta",
	readable?: boolean | undefined,
	updatable?: boolean | undefined
};
	["UUID"]: string;
	["Customer"]: {
	__typename: "Customer",
	_meta?: GraphQLTypes["CustomerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	addresses: Array<GraphQLTypes["Address"]>,
	defaultBillingAddress?: GraphQLTypes["Address"] | undefined,
	tags: Array<GraphQLTypes["Tag"]>,
	orders: Array<GraphQLTypes["Order"]>,
	photo?: GraphQLTypes["Image"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["CustomerName"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["CustomerFamiliarity"] | undefined,
	channels: Array<GraphQLTypes["Channel"]>,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Address"] | undefined,
	prefersPackingsWithoutCardboard: boolean,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["CustomerMetadata"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	addressesByBillingAddressOfOrders?: GraphQLTypes["Address"] | undefined,
	addressesByDeliveryAddressOfOrderDeliveries?: GraphQLTypes["Address"] | undefined,
	addressesByDefaultDeliveryAddressOfCustomer?: GraphQLTypes["Address"] | undefined,
	ordersBySeq?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	ordersByDelivery?: GraphQLTypes["Order"] | undefined,
	ordersByItems?: GraphQLTypes["Order"] | undefined,
	ordersByPayments?: GraphQLTypes["Order"] | undefined,
	paginateAddresses: GraphQLTypes["AddressConnection"],
	paginateTags: GraphQLTypes["TagConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"],
	paginateChannels: GraphQLTypes["ChannelConnection"]
};
	["CustomerMeta"]: {
	__typename: "CustomerMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	addresses?: GraphQLTypes["FieldMeta"] | undefined,
	defaultBillingAddress?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	orders?: GraphQLTypes["FieldMeta"] | undefined,
	photo?: GraphQLTypes["FieldMeta"] | undefined,
	phone?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	welcomeMessage?: GraphQLTypes["FieldMeta"] | undefined,
	familiarity?: GraphQLTypes["FieldMeta"] | undefined,
	channels?: GraphQLTypes["FieldMeta"] | undefined,
	disabledAt?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["FieldMeta"] | undefined,
	prefersPackingsWithoutCardboard?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["FieldMeta"] | undefined
};
	["AddressWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	firstName?: GraphQLTypes["StringCondition"] | undefined,
	lastName?: GraphQLTypes["StringCondition"] | undefined,
	addressLine2?: GraphQLTypes["StringCondition"] | undefined,
	email?: GraphQLTypes["StringCondition"] | undefined,
	phone?: GraphQLTypes["StringCondition"] | undefined,
	companyName?: GraphQLTypes["StringCondition"] | undefined,
	companyIdentifier?: GraphQLTypes["StringCondition"] | undefined,
	vatIdentifier?: GraphQLTypes["StringCondition"] | undefined,
	externalIdentifier?: GraphQLTypes["StringCondition"] | undefined,
	hidden?: GraphQLTypes["BooleanCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	country?: GraphQLTypes["CountryWhere"] | undefined,
	deletedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	googlePlaceId?: GraphQLTypes["StringCondition"] | undefined,
	raw?: GraphQLTypes["StringCondition"] | undefined,
	formatted?: GraphQLTypes["StringCondition"] | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	geocodeResponse?: GraphQLTypes["StringCondition"] | undefined,
	deliveryZone?: GraphQLTypes["DeliveryZoneWhere"] | undefined,
	geocodeValid?: GraphQLTypes["BooleanCondition"] | undefined,
	fullName?: GraphQLTypes["StringCondition"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["StringCondition"] | undefined,
	addressLine1?: GraphQLTypes["StringCondition"] | undefined,
	city?: GraphQLTypes["StringCondition"] | undefined,
	postalCode?: GraphQLTypes["StringCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	billingAddressOfOrders?: GraphQLTypes["OrderWhere"] | undefined,
	deliveryAddressOfOrderDeliveries?: GraphQLTypes["OrderDeliveryWhere"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["CustomerWhere"] | undefined,
	and?: Array<GraphQLTypes["AddressWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["AddressWhere"] | undefined> | undefined,
	not?: GraphQLTypes["AddressWhere"] | undefined
};
	["UUIDCondition"]: {
		and?: Array<GraphQLTypes["UUIDCondition"]> | undefined,
	or?: Array<GraphQLTypes["UUIDCondition"]> | undefined,
	not?: GraphQLTypes["UUIDCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["UUID"] | undefined,
	notEq?: GraphQLTypes["UUID"] | undefined,
	in?: Array<GraphQLTypes["UUID"]> | undefined,
	notIn?: Array<GraphQLTypes["UUID"]> | undefined,
	lt?: GraphQLTypes["UUID"] | undefined,
	lte?: GraphQLTypes["UUID"] | undefined,
	gt?: GraphQLTypes["UUID"] | undefined,
	gte?: GraphQLTypes["UUID"] | undefined
};
	["StringCondition"]: {
		and?: Array<GraphQLTypes["StringCondition"]> | undefined,
	or?: Array<GraphQLTypes["StringCondition"]> | undefined,
	not?: GraphQLTypes["StringCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: string | undefined,
	notEq?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	containsCI?: string | undefined,
	startsWithCI?: string | undefined,
	endsWithCI?: string | undefined
};
	["BooleanCondition"]: {
		and?: Array<GraphQLTypes["BooleanCondition"]> | undefined,
	or?: Array<GraphQLTypes["BooleanCondition"]> | undefined,
	not?: GraphQLTypes["BooleanCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: boolean | undefined,
	notEq?: boolean | undefined,
	in?: Array<boolean> | undefined,
	notIn?: Array<boolean> | undefined,
	lt?: boolean | undefined,
	lte?: boolean | undefined,
	gt?: boolean | undefined,
	gte?: boolean | undefined
};
	["CustomerWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	addresses?: GraphQLTypes["AddressWhere"] | undefined,
	defaultBillingAddress?: GraphQLTypes["AddressWhere"] | undefined,
	tags?: GraphQLTypes["TagWhere"] | undefined,
	orders?: GraphQLTypes["OrderWhere"] | undefined,
	photo?: GraphQLTypes["ImageWhere"] | undefined,
	phone?: GraphQLTypes["StringCondition"] | undefined,
	name?: GraphQLTypes["CustomerNameWhere"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	welcomeMessage?: GraphQLTypes["StringCondition"] | undefined,
	familiarity?: GraphQLTypes["CustomerFamiliarityCondition"] | undefined,
	channels?: GraphQLTypes["ChannelWhere"] | undefined,
	disabledAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	email?: GraphQLTypes["StringCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["AddressWhere"] | undefined,
	prefersPackingsWithoutCardboard?: GraphQLTypes["BooleanCondition"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["StringCondition"] | undefined,
	meta?: GraphQLTypes["CustomerMetadataWhere"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["CustomerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CustomerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CustomerWhere"] | undefined
};
	["TagWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	customers?: GraphQLTypes["CustomerWhere"] | undefined,
	products?: GraphQLTypes["ProductWhere"] | undefined,
	categories?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["ChannelWhere"] | undefined,
	deliveryZones?: GraphQLTypes["DeliveryZoneWhere"] | undefined,
	publicInChannels?: GraphQLTypes["ChannelWhere"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FulfillmentNoteWhere"] | undefined,
	and?: Array<GraphQLTypes["TagWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TagWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TagWhere"] | undefined
};
	["ProductWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	priceCents?: GraphQLTypes["IntCondition"] | undefined,
	notDiscountable?: GraphQLTypes["BooleanCondition"] | undefined,
	packing?: GraphQLTypes["ProductPackingWhere"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeWhere"] | undefined,
	tags?: GraphQLTypes["TagWhere"] | undefined,
	categories?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	stocks?: GraphQLTypes["ProductStockWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	availability?: GraphQLTypes["ProductAvailabilityCondition"] | undefined,
	order?: GraphQLTypes["FloatCondition"] | undefined,
	orderItems?: GraphQLTypes["OrderItemWhere"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["IntCondition"] | undefined,
	deliveryZones?: GraphQLTypes["DeliveryZoneWhere"] | undefined,
	locales?: GraphQLTypes["ProductLocaleWhere"] | undefined,
	archivedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	virtualProduct?: GraphQLTypes["VirtualProductWhere"] | undefined,
	meta?: GraphQLTypes["ProductMetadataWhere"] | undefined,
	page?: GraphQLTypes["PageWhere"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FulfillmentNoteWhere"] | undefined,
	hasSubscription?: GraphQLTypes["BooleanCondition"] | undefined,
	pointOfSaleTileColor?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductWhere"] | undefined
};
	["IntCondition"]: {
		and?: Array<GraphQLTypes["IntCondition"]> | undefined,
	or?: Array<GraphQLTypes["IntCondition"]> | undefined,
	not?: GraphQLTypes["IntCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["ProductPackingWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	volumeMl?: GraphQLTypes["IntCondition"] | undefined,
	icon?: GraphQLTypes["ImageWhere"] | undefined,
	shortName?: GraphQLTypes["StringCondition"] | undefined,
	locales?: GraphQLTypes["ProductPackingLocaleWhere"] | undefined,
	preparedPackingQuantities?: GraphQLTypes["PreparedPackingQuantityWhere"] | undefined,
	isPackedInCardboard?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductPackingWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductPackingWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductPackingWhere"] | undefined
};
	["ImageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	url?: GraphQLTypes["StringCondition"] | undefined,
	width?: GraphQLTypes["IntCondition"] | undefined,
	height?: GraphQLTypes["IntCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	alt?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ImageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageWhere"] | undefined
};
	["ProductPackingLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	shortName?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductPackingWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductPackingLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductPackingLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductPackingLocaleWhere"] | undefined
};
	["LocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	businessCategories?: GraphQLTypes["BusinessCategoryLocaleWhere"] | undefined,
	productCategoryLists?: GraphQLTypes["ProductCategoryListLocaleWhere"] | undefined,
	productIngredients?: GraphQLTypes["ProductIngredientLocaleWhere"] | undefined,
	orderRecurrences?: GraphQLTypes["OrderRecurrenceLocaleWhere"] | undefined,
	blogs?: GraphQLTypes["BlogLocaleWhere"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostLocaleWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined,
	pages?: GraphQLTypes["PageLocaleWhere"] | undefined,
	productIngredientCategories?: GraphQLTypes["ProductIngredientCategoryLocaleWhere"] | undefined,
	allergens?: GraphQLTypes["AllergenLocaleWhere"] | undefined,
	productPackings?: GraphQLTypes["ProductPackingLocaleWhere"] | undefined,
	products?: GraphQLTypes["ProductLocaleWhere"] | undefined,
	productRecipes?: GraphQLTypes["ProductRecipeLocaleWhere"] | undefined,
	deliveryMethods?: GraphQLTypes["DeliveryMethodLocaleWhere"] | undefined,
	ingredientSuppliers?: GraphQLTypes["IngredientSupplierLocaleWhere"] | undefined,
	identificationCodes?: GraphQLTypes["IdentificationCodeLocaleWhere"] | undefined,
	subscriptionBoxes?: GraphQLTypes["SubscriptionBoxLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["LocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LocaleWhere"] | undefined
};
	["BusinessCategoryLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["BusinessCategoryLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BusinessCategoryLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BusinessCategoryLocaleWhere"] | undefined
};
	["BusinessCategoryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["BooleanCondition"] | undefined,
	locales?: GraphQLTypes["BusinessCategoryLocaleWhere"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	recurrences?: GraphQLTypes["OrderRecurrenceWhere"] | undefined,
	specificProducts?: GraphQLTypes["ProductListWhere"] | undefined,
	seasonalProductCategories?: GraphQLTypes["ProductCategoryListWhere"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["ProductCategoryListWhere"] | undefined,
	slug?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["BusinessCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BusinessCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BusinessCategoryWhere"] | undefined
};
	["OrderRecurrenceWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	locales?: GraphQLTypes["OrderRecurrenceLocaleWhere"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["OrderRecurrenceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderRecurrenceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderRecurrenceWhere"] | undefined
};
	["OrderRecurrenceLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["OrderRecurrenceWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["OrderRecurrenceLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderRecurrenceLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderRecurrenceLocaleWhere"] | undefined
};
	["ProductListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ProductListItemWhere"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductListWhere"] | undefined
};
	["ProductListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ProductListWhere"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductListItemWhere"] | undefined
};
	["ProductCategoryListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ProductCategoryListItemWhere"] | undefined,
	locales?: GraphQLTypes["ProductCategoryListLocaleWhere"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryListWhere"] | undefined
};
	["ProductCategoryListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ProductCategoryListWhere"] | undefined,
	productCategory?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	showRecipeImages?: GraphQLTypes["BooleanCondition"] | undefined,
	showPackingImages?: GraphQLTypes["BooleanCondition"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryListItemWhere"] | undefined
};
	["ProductCategoryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	products?: GraphQLTypes["ProductWhere"] | undefined,
	tags?: GraphQLTypes["TagWhere"] | undefined,
	parent?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	children?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	color?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	order?: GraphQLTypes["FloatCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryWhere"] | undefined
};
	["FloatCondition"]: {
		and?: Array<GraphQLTypes["FloatCondition"]> | undefined,
	or?: Array<GraphQLTypes["FloatCondition"]> | undefined,
	not?: GraphQLTypes["FloatCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["ProductCategoryListLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductCategoryListWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryListLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryListLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryListLocaleWhere"] | undefined
};
	["ProductIngredientLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductIngredientWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientLocaleWhere"] | undefined
};
	["ProductIngredientWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	locales?: GraphQLTypes["ProductIngredientLocaleWhere"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	categories?: GraphQLTypes["ProductIngredientCategoryWhere"] | undefined,
	allergens?: GraphQLTypes["AllergenWhere"] | undefined,
	needsPrepping?: GraphQLTypes["BooleanCondition"] | undefined,
	allowCustomerRating?: GraphQLTypes["BooleanCondition"] | undefined,
	preferredQuantityUnit?: GraphQLTypes["ProductIngredientItemQuantityUnitWhere"] | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainerWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	suppliers?: GraphQLTypes["IngredientSupplierListWhere"] | undefined,
	incrementQuantityNumber?: GraphQLTypes["FloatCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientWhere"] | undefined
};
	["ProductIngredientCategoryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	ingredients?: GraphQLTypes["ProductIngredientWhere"] | undefined,
	locales?: GraphQLTypes["ProductIngredientCategoryLocaleWhere"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientCategoryWhere"] | undefined
};
	["ProductIngredientCategoryLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductIngredientCategoryWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientCategoryLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientCategoryLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientCategoryLocaleWhere"] | undefined
};
	["AllergenWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	locales?: GraphQLTypes["AllergenLocaleWhere"] | undefined,
	ingredients?: GraphQLTypes["ProductIngredientWhere"] | undefined,
	code?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["AllergenWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["AllergenWhere"] | undefined> | undefined,
	not?: GraphQLTypes["AllergenWhere"] | undefined
};
	["AllergenLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["AllergenWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["AllergenLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["AllergenLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["AllergenLocaleWhere"] | undefined
};
	["ProductIngredientItemQuantityUnitWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientItemQuantityUnitWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientItemQuantityUnitWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientItemQuantityUnitWhere"] | undefined
};
	["FreshingContainerWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	volumeMl?: GraphQLTypes["IntCondition"] | undefined,
	workspace?: GraphQLTypes["WorkspaceWhere"] | undefined,
	and?: Array<GraphQLTypes["FreshingContainerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FreshingContainerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FreshingContainerWhere"] | undefined
};
	["WorkspaceWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	orderDeadlineTime?: GraphQLTypes["StringCondition"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["IntCondition"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["IntCondition"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["IntCondition"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	orderUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	messengerUrl?: GraphQLTypes["StringCondition"] | undefined,
	messengerIosUrl?: GraphQLTypes["StringCondition"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["StringCondition"] | undefined,
	freshingContainers?: GraphQLTypes["FreshingContainerWhere"] | undefined,
	openingHours?: GraphQLTypes["WeekHoursWhere"] | undefined,
	workingHours?: GraphQLTypes["WeekHoursWhere"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FreshingContainerWhere"] | undefined,
	creditsProduct?: GraphQLTypes["VirtualProductWhere"] | undefined,
	discountVatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	businessAddress?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["WorkspaceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["WorkspaceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["WorkspaceWhere"] | undefined
};
	["OneCondition"]: {
		and?: Array<GraphQLTypes["OneCondition"]> | undefined,
	or?: Array<GraphQLTypes["OneCondition"]> | undefined,
	not?: GraphQLTypes["OneCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["One"] | undefined,
	notEq?: GraphQLTypes["One"] | undefined,
	in?: Array<GraphQLTypes["One"]> | undefined,
	notIn?: Array<GraphQLTypes["One"]> | undefined,
	lt?: GraphQLTypes["One"] | undefined,
	lte?: GraphQLTypes["One"] | undefined,
	gt?: GraphQLTypes["One"] | undefined,
	gte?: GraphQLTypes["One"] | undefined
};
	["One"]: One;
	["WeekHoursWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	mon?: GraphQLTypes["StringCondition"] | undefined,
	tue?: GraphQLTypes["StringCondition"] | undefined,
	wed?: GraphQLTypes["StringCondition"] | undefined,
	thu?: GraphQLTypes["StringCondition"] | undefined,
	fri?: GraphQLTypes["StringCondition"] | undefined,
	sat?: GraphQLTypes["StringCondition"] | undefined,
	sun?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["WeekHoursWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["WeekHoursWhere"] | undefined> | undefined,
	not?: GraphQLTypes["WeekHoursWhere"] | undefined
};
	["VirtualProductWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["VirtualProductTypeCondition"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	voucherCreditCents?: GraphQLTypes["IntCondition"] | undefined,
	physicalRepresentation?: GraphQLTypes["VirtualProductPhysicalRepresentationTypeCondition"] | undefined,
	recipientEmail?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["VirtualProductWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VirtualProductWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VirtualProductWhere"] | undefined
};
	["VirtualProductTypeCondition"]: {
		and?: Array<GraphQLTypes["VirtualProductTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["VirtualProductTypeCondition"]> | undefined,
	not?: GraphQLTypes["VirtualProductTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["VirtualProductType"] | undefined,
	notEq?: GraphQLTypes["VirtualProductType"] | undefined,
	in?: Array<GraphQLTypes["VirtualProductType"]> | undefined,
	notIn?: Array<GraphQLTypes["VirtualProductType"]> | undefined,
	lt?: GraphQLTypes["VirtualProductType"] | undefined,
	lte?: GraphQLTypes["VirtualProductType"] | undefined,
	gt?: GraphQLTypes["VirtualProductType"] | undefined,
	gte?: GraphQLTypes["VirtualProductType"] | undefined
};
	["VirtualProductType"]: VirtualProductType;
	["VirtualProductPhysicalRepresentationTypeCondition"]: {
		and?: Array<GraphQLTypes["VirtualProductPhysicalRepresentationTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["VirtualProductPhysicalRepresentationTypeCondition"]> | undefined,
	not?: GraphQLTypes["VirtualProductPhysicalRepresentationTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined,
	notEq?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined,
	in?: Array<GraphQLTypes["VirtualProductPhysicalRepresentationType"]> | undefined,
	notIn?: Array<GraphQLTypes["VirtualProductPhysicalRepresentationType"]> | undefined,
	lt?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined,
	lte?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined,
	gt?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined,
	gte?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined
};
	["VirtualProductPhysicalRepresentationType"]: VirtualProductPhysicalRepresentationType;
	["VatRateWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	ratePermille?: GraphQLTypes["IntCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	preset?: GraphQLTypes["VatRatePresetCondition"] | undefined,
	and?: Array<GraphQLTypes["VatRateWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VatRateWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VatRateWhere"] | undefined
};
	["VatRatePresetCondition"]: {
		and?: Array<GraphQLTypes["VatRatePresetCondition"]> | undefined,
	or?: Array<GraphQLTypes["VatRatePresetCondition"]> | undefined,
	not?: GraphQLTypes["VatRatePresetCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["VatRatePreset"] | undefined,
	notEq?: GraphQLTypes["VatRatePreset"] | undefined,
	in?: Array<GraphQLTypes["VatRatePreset"]> | undefined,
	notIn?: Array<GraphQLTypes["VatRatePreset"]> | undefined,
	lt?: GraphQLTypes["VatRatePreset"] | undefined,
	lte?: GraphQLTypes["VatRatePreset"] | undefined,
	gt?: GraphQLTypes["VatRatePreset"] | undefined,
	gte?: GraphQLTypes["VatRatePreset"] | undefined
};
	["VatRatePreset"]: VatRatePreset;
	["IngredientSupplierListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["IngredientSupplierItemWhere"] | undefined,
	and?: Array<GraphQLTypes["IngredientSupplierListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["IngredientSupplierListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["IngredientSupplierListWhere"] | undefined
};
	["IngredientSupplierItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["IngredientSupplierListWhere"] | undefined,
	supplier?: GraphQLTypes["IngredientSupplierWhere"] | undefined,
	and?: Array<GraphQLTypes["IngredientSupplierItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["IngredientSupplierItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["IngredientSupplierItemWhere"] | undefined
};
	["IngredientSupplierWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	locales?: GraphQLTypes["IngredientSupplierLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["IngredientSupplierWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["IngredientSupplierWhere"] | undefined> | undefined,
	not?: GraphQLTypes["IngredientSupplierWhere"] | undefined
};
	["IngredientSupplierLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["IngredientSupplierWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["IngredientSupplierLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["IngredientSupplierLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["IngredientSupplierLocaleWhere"] | undefined
};
	["BlogLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	pageName?: GraphQLTypes["StringCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	lead?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["BlogWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogLocaleWhere"] | undefined
};
	["BlogWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["BlogLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogWhere"] | undefined
};
	["BlogPostLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	availableForWeb?: GraphQLTypes["BooleanCondition"] | undefined,
	availableForMobile?: GraphQLTypes["BooleanCondition"] | undefined,
	root?: GraphQLTypes["BlogPostWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	cover?: GraphQLTypes["CoverWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	products?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogPostLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostLocaleWhere"] | undefined
};
	["BlogPostWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	locales?: GraphQLTypes["BlogPostLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogPostWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostWhere"] | undefined
};
	["CoverWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	primaryTitle?: GraphQLTypes["StringCondition"] | undefined,
	secondaryTitle?: GraphQLTypes["StringCondition"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	buttonColors?: GraphQLTypes["ColorPalleteWhere"] | undefined,
	and?: Array<GraphQLTypes["CoverWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CoverWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CoverWhere"] | undefined
};
	["MediumWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["MediumTypeCondition"] | undefined,
	colorTheme?: GraphQLTypes["ColorThemeCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	video?: GraphQLTypes["VideoWhere"] | undefined,
	and?: Array<GraphQLTypes["MediumWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["MediumWhere"] | undefined> | undefined,
	not?: GraphQLTypes["MediumWhere"] | undefined
};
	["MediumTypeCondition"]: {
		and?: Array<GraphQLTypes["MediumTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["MediumTypeCondition"]> | undefined,
	not?: GraphQLTypes["MediumTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["MediumType"] | undefined,
	notEq?: GraphQLTypes["MediumType"] | undefined,
	in?: Array<GraphQLTypes["MediumType"]> | undefined,
	notIn?: Array<GraphQLTypes["MediumType"]> | undefined,
	lt?: GraphQLTypes["MediumType"] | undefined,
	lte?: GraphQLTypes["MediumType"] | undefined,
	gt?: GraphQLTypes["MediumType"] | undefined,
	gte?: GraphQLTypes["MediumType"] | undefined
};
	["MediumType"]: MediumType;
	["ColorThemeCondition"]: {
		and?: Array<GraphQLTypes["ColorThemeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ColorThemeCondition"]> | undefined,
	not?: GraphQLTypes["ColorThemeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ColorTheme"] | undefined,
	notEq?: GraphQLTypes["ColorTheme"] | undefined,
	in?: Array<GraphQLTypes["ColorTheme"]> | undefined,
	notIn?: Array<GraphQLTypes["ColorTheme"]> | undefined,
	lt?: GraphQLTypes["ColorTheme"] | undefined,
	lte?: GraphQLTypes["ColorTheme"] | undefined,
	gt?: GraphQLTypes["ColorTheme"] | undefined,
	gte?: GraphQLTypes["ColorTheme"] | undefined
};
	["ColorTheme"]: ColorTheme;
	["VideoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	src?: GraphQLTypes["StringCondition"] | undefined,
	width?: GraphQLTypes["IntCondition"] | undefined,
	height?: GraphQLTypes["IntCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	duration?: GraphQLTypes["FloatCondition"] | undefined,
	poster?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["VideoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VideoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VideoWhere"] | undefined
};
	["ColorPalleteWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	background?: GraphQLTypes["StringCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ColorPalleteWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ColorPalleteWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ColorPalleteWhere"] | undefined
};
	["ContentWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	blocks?: GraphQLTypes["ContentBlockWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentWhere"] | undefined
};
	["ContentBlockWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	json?: GraphQLTypes["StringCondition"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	references?: GraphQLTypes["ContentReferenceWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentBlockWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentBlockWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentBlockWhere"] | undefined
};
	["ContentReferenceWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["ContentReferenceTypeCondition"] | undefined,
	primaryText?: GraphQLTypes["StringCondition"] | undefined,
	secondaryText?: GraphQLTypes["StringCondition"] | undefined,
	jsonContent?: GraphQLTypes["StringCondition"] | undefined,
	block?: GraphQLTypes["ContentBlockWhere"] | undefined,
	colorPallete?: GraphQLTypes["ColorPalleteWhere"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostListWhere"] | undefined,
	links?: GraphQLTypes["LinkListWhere"] | undefined,
	products?: GraphQLTypes["ProductListWhere"] | undefined,
	contentSize?: GraphQLTypes["ContentSizeCondition"] | undefined,
	misc?: GraphQLTypes["StringCondition"] | undefined,
	hero?: GraphQLTypes["HeroWhere"] | undefined,
	gallery?: GraphQLTypes["GalleryWhere"] | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndAppsWhere"] | undefined,
	productGrid?: GraphQLTypes["ProductGridWhere"] | undefined,
	productBanners?: GraphQLTypes["ProductBannerListWhere"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegionsWhere"] | undefined,
	textList?: GraphQLTypes["TextListWhere"] | undefined,
	gridTiles?: GraphQLTypes["GridTilesWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentReferenceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentReferenceWhere"] | undefined
};
	["ContentReferenceTypeCondition"]: {
		and?: Array<GraphQLTypes["ContentReferenceTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceTypeCondition"]> | undefined,
	not?: GraphQLTypes["ContentReferenceTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ContentReferenceType"] | undefined,
	notEq?: GraphQLTypes["ContentReferenceType"] | undefined,
	in?: Array<GraphQLTypes["ContentReferenceType"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentReferenceType"]> | undefined,
	lt?: GraphQLTypes["ContentReferenceType"] | undefined,
	lte?: GraphQLTypes["ContentReferenceType"] | undefined,
	gt?: GraphQLTypes["ContentReferenceType"] | undefined,
	gte?: GraphQLTypes["ContentReferenceType"] | undefined
};
	["ContentReferenceType"]: ContentReferenceType;
	["LinkWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["LinkTypeCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	externalLink?: GraphQLTypes["StringCondition"] | undefined,
	internalLink?: GraphQLTypes["LinkableWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkWhere"] | undefined
};
	["LinkTypeCondition"]: {
		and?: Array<GraphQLTypes["LinkTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["LinkTypeCondition"]> | undefined,
	not?: GraphQLTypes["LinkTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["LinkType"] | undefined,
	notEq?: GraphQLTypes["LinkType"] | undefined,
	in?: Array<GraphQLTypes["LinkType"]> | undefined,
	notIn?: Array<GraphQLTypes["LinkType"]> | undefined,
	lt?: GraphQLTypes["LinkType"] | undefined,
	lte?: GraphQLTypes["LinkType"] | undefined,
	gt?: GraphQLTypes["LinkType"] | undefined,
	gte?: GraphQLTypes["LinkType"] | undefined
};
	["LinkType"]: LinkType;
	["LinkableWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	url?: GraphQLTypes["StringCondition"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleWhere"] | undefined,
	page?: GraphQLTypes["PageLocaleWhere"] | undefined,
	redirect?: GraphQLTypes["RedirectWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkableWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkableWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkableWhere"] | undefined
};
	["PageLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	root?: GraphQLTypes["PageWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	cover?: GraphQLTypes["CoverWhere"] | undefined,
	theme?: GraphQLTypes["PageThemeCondition"] | undefined,
	and?: Array<GraphQLTypes["PageLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PageLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PageLocaleWhere"] | undefined
};
	["PageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["PageLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["PageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PageWhere"] | undefined
};
	["SeoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	ogTitle?: GraphQLTypes["StringCondition"] | undefined,
	ogDescription?: GraphQLTypes["StringCondition"] | undefined,
	ogImage?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["SeoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SeoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SeoWhere"] | undefined
};
	["PageThemeCondition"]: {
		and?: Array<GraphQLTypes["PageThemeCondition"]> | undefined,
	or?: Array<GraphQLTypes["PageThemeCondition"]> | undefined,
	not?: GraphQLTypes["PageThemeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["PageTheme"] | undefined,
	notEq?: GraphQLTypes["PageTheme"] | undefined,
	in?: Array<GraphQLTypes["PageTheme"]> | undefined,
	notIn?: Array<GraphQLTypes["PageTheme"]> | undefined,
	lt?: GraphQLTypes["PageTheme"] | undefined,
	lte?: GraphQLTypes["PageTheme"] | undefined,
	gt?: GraphQLTypes["PageTheme"] | undefined,
	gte?: GraphQLTypes["PageTheme"] | undefined
};
	["PageTheme"]: PageTheme;
	["RedirectWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	target?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["RedirectWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["RedirectWhere"] | undefined> | undefined,
	not?: GraphQLTypes["RedirectWhere"] | undefined
};
	["BlogPostListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["BlogPostListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogPostListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostListWhere"] | undefined
};
	["BlogPostListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	caption?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["BlogPostListWhere"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogPostListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostListItemWhere"] | undefined
};
	["LinkListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["LinkListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkListWhere"] | undefined
};
	["LinkListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	isPrimary?: GraphQLTypes["BooleanCondition"] | undefined,
	list?: GraphQLTypes["LinkListWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkListItemWhere"] | undefined
};
	["ContentSizeCondition"]: {
		and?: Array<GraphQLTypes["ContentSizeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ContentSizeCondition"]> | undefined,
	not?: GraphQLTypes["ContentSizeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ContentSize"] | undefined,
	notEq?: GraphQLTypes["ContentSize"] | undefined,
	in?: Array<GraphQLTypes["ContentSize"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentSize"]> | undefined,
	lt?: GraphQLTypes["ContentSize"] | undefined,
	lte?: GraphQLTypes["ContentSize"] | undefined,
	gt?: GraphQLTypes["ContentSize"] | undefined,
	gte?: GraphQLTypes["ContentSize"] | undefined
};
	["ContentSize"]: ContentSize;
	["HeroWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	showLogo?: GraphQLTypes["BooleanCondition"] | undefined,
	middleContent?: GraphQLTypes["StringCondition"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	and?: Array<GraphQLTypes["HeroWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["HeroWhere"] | undefined> | undefined,
	not?: GraphQLTypes["HeroWhere"] | undefined
};
	["GalleryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["GalleryItemWhere"] | undefined,
	and?: Array<GraphQLTypes["GalleryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GalleryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GalleryWhere"] | undefined
};
	["GalleryItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	caption?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["GalleryWhere"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	and?: Array<GraphQLTypes["GalleryItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GalleryItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GalleryItemWhere"] | undefined
};
	["SocialsAndAppsWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	instagram?: GraphQLTypes["BooleanCondition"] | undefined,
	appStore?: GraphQLTypes["BooleanCondition"] | undefined,
	googlePlay?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["SocialsAndAppsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SocialsAndAppsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SocialsAndAppsWhere"] | undefined
};
	["ProductGridWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	isCarousel?: GraphQLTypes["BooleanCondition"] | undefined,
	categories?: GraphQLTypes["ProductGridCategoryWhere"] | undefined,
	type?: GraphQLTypes["ProductGridTypeCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductGridWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductGridWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductGridWhere"] | undefined
};
	["ProductGridCategoryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductGridWhere"] | undefined,
	items?: GraphQLTypes["ProductGridItemWhere"] | undefined,
	images?: GraphQLTypes["ImageListWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductGridCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductGridCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductGridCategoryWhere"] | undefined
};
	["ProductGridItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	lead?: GraphQLTypes["StringCondition"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	category?: GraphQLTypes["ProductGridCategoryWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductGridItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductGridItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductGridItemWhere"] | undefined
};
	["ImageListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ImageListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["ImageListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageListWhere"] | undefined
};
	["ImageListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ImageListWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["ImageListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageListItemWhere"] | undefined
};
	["ProductGridTypeCondition"]: {
		and?: Array<GraphQLTypes["ProductGridTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ProductGridTypeCondition"]> | undefined,
	not?: GraphQLTypes["ProductGridTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ProductGridType"] | undefined,
	notEq?: GraphQLTypes["ProductGridType"] | undefined,
	in?: Array<GraphQLTypes["ProductGridType"]> | undefined,
	notIn?: Array<GraphQLTypes["ProductGridType"]> | undefined,
	lt?: GraphQLTypes["ProductGridType"] | undefined,
	lte?: GraphQLTypes["ProductGridType"] | undefined,
	gt?: GraphQLTypes["ProductGridType"] | undefined,
	gte?: GraphQLTypes["ProductGridType"] | undefined
};
	["ProductGridType"]: ProductGridType;
	["ProductBannerListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ProductBannerItemWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductBannerListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductBannerListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductBannerListWhere"] | undefined
};
	["ProductBannerItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ProductBannerListWhere"] | undefined,
	banner?: GraphQLTypes["ProductBannerWhere"] | undefined,
	size?: GraphQLTypes["ProductBannerItemSizeCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductBannerItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductBannerItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductBannerItemWhere"] | undefined
};
	["ProductBannerWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	beforeTitle?: GraphQLTypes["StringCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	label?: GraphQLTypes["StringCondition"] | undefined,
	button?: GraphQLTypes["LinkWhere"] | undefined,
	backgroundImage?: GraphQLTypes["ImageWhere"] | undefined,
	productImage?: GraphQLTypes["ImageWhere"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductBannerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductBannerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductBannerWhere"] | undefined
};
	["ProductBannerItemSizeCondition"]: {
		and?: Array<GraphQLTypes["ProductBannerItemSizeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ProductBannerItemSizeCondition"]> | undefined,
	not?: GraphQLTypes["ProductBannerItemSizeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ProductBannerItemSize"] | undefined,
	notEq?: GraphQLTypes["ProductBannerItemSize"] | undefined,
	in?: Array<GraphQLTypes["ProductBannerItemSize"]> | undefined,
	notIn?: Array<GraphQLTypes["ProductBannerItemSize"]> | undefined,
	lt?: GraphQLTypes["ProductBannerItemSize"] | undefined,
	lte?: GraphQLTypes["ProductBannerItemSize"] | undefined,
	gt?: GraphQLTypes["ProductBannerItemSize"] | undefined,
	gte?: GraphQLTypes["ProductBannerItemSize"] | undefined
};
	["ProductBannerItemSize"]: ProductBannerItemSize;
	["DeliveryRegionsWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["DeliveryRegionsItemWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryRegionsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryRegionsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryRegionsWhere"] | undefined
};
	["DeliveryRegionsItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	offerLabel?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["DeliveryRegionsWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	textList?: GraphQLTypes["TextListWhere"] | undefined,
	gallery?: GraphQLTypes["ImageListWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryRegionsItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryRegionsItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryRegionsItemWhere"] | undefined
};
	["TextListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["TextItemWhere"] | undefined,
	and?: Array<GraphQLTypes["TextListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TextListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TextListWhere"] | undefined
};
	["TextItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["TextListWhere"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["TextItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TextItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TextItemWhere"] | undefined
};
	["GridTilesWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["GridTileItemWhere"] | undefined,
	and?: Array<GraphQLTypes["GridTilesWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GridTilesWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GridTilesWhere"] | undefined
};
	["GridTileItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["GridTilesWhere"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["GridTileItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GridTileItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GridTileItemWhere"] | undefined
};
	["TranslationCataloguesIdentifierWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined
};
	["TranslationCatalogueWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	domain?: GraphQLTypes["TranslationDomainWhere"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	values?: GraphQLTypes["TranslationValueWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationCatalogueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationCatalogueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationCatalogueWhere"] | undefined
};
	["TranslationDomainWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	identifier?: GraphQLTypes["StringCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	catalogues?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	keys?: GraphQLTypes["TranslationKeyWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationDomainWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationDomainWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationDomainWhere"] | undefined
};
	["TranslationKeyWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	identifier?: GraphQLTypes["StringCondition"] | undefined,
	contentType?: GraphQLTypes["TranslationContentTypeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	domain?: GraphQLTypes["TranslationDomainWhere"] | undefined,
	values?: GraphQLTypes["TranslationValueWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationKeyWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationKeyWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationKeyWhere"] | undefined
};
	["TranslationContentTypeCondition"]: {
		and?: Array<GraphQLTypes["TranslationContentTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["TranslationContentTypeCondition"]> | undefined,
	not?: GraphQLTypes["TranslationContentTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["TranslationContentType"] | undefined,
	notEq?: GraphQLTypes["TranslationContentType"] | undefined,
	in?: Array<GraphQLTypes["TranslationContentType"]> | undefined,
	notIn?: Array<GraphQLTypes["TranslationContentType"]> | undefined,
	lt?: GraphQLTypes["TranslationContentType"] | undefined,
	lte?: GraphQLTypes["TranslationContentType"] | undefined,
	gt?: GraphQLTypes["TranslationContentType"] | undefined,
	gte?: GraphQLTypes["TranslationContentType"] | undefined
};
	["TranslationContentType"]: TranslationContentType;
	["TranslationValueWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	value?: GraphQLTypes["StringCondition"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	key?: GraphQLTypes["TranslationKeyWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationValueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationValueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationValueWhere"] | undefined
};
	["ProductLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	featureList?: GraphQLTypes["TextListWhere"] | undefined,
	printableName?: GraphQLTypes["StringCondition"] | undefined,
	firstSticker?: GraphQLTypes["StringCondition"] | undefined,
	secondSticker?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductLocaleWhere"] | undefined
};
	["ProductRecipeLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductRecipeWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductRecipeLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductRecipeLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductRecipeLocaleWhere"] | undefined
};
	["ProductRecipeWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	products?: GraphQLTypes["ProductWhere"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	color?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	icon?: GraphQLTypes["ImageWhere"] | undefined,
	ingredients?: GraphQLTypes["ProductIngredientItemWhere"] | undefined,
	needsPrepping?: GraphQLTypes["BooleanCondition"] | undefined,
	locales?: GraphQLTypes["ProductRecipeLocaleWhere"] | undefined,
	gutTuning?: GraphQLTypes["FloatCondition"] | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainerWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductRecipeWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductRecipeWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductRecipeWhere"] | undefined
};
	["ProductIngredientItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	internalOrder?: GraphQLTypes["IntCondition"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeWhere"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredientWhere"] | undefined,
	quantity?: GraphQLTypes["ProductIngredientItemQuantityWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientItemWhere"] | undefined
};
	["ProductIngredientItemQuantityWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unit?: GraphQLTypes["ProductIngredientItemQuantityUnitWhere"] | undefined,
	value?: GraphQLTypes["FloatCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientItemQuantityWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientItemQuantityWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientItemQuantityWhere"] | undefined
};
	["DeliveryMethodLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	processingTitle?: GraphQLTypes["StringCondition"] | undefined,
	processingText?: GraphQLTypes["StringCondition"] | undefined,
	processingIconUrl?: GraphQLTypes["StringCondition"] | undefined,
	dispatchedTitle?: GraphQLTypes["StringCondition"] | undefined,
	dispatchedText?: GraphQLTypes["StringCondition"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["StringCondition"] | undefined,
	fulfilledTitle?: GraphQLTypes["StringCondition"] | undefined,
	fulfilledText?: GraphQLTypes["StringCondition"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["StringCondition"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	root?: GraphQLTypes["DeliveryMethodWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryMethodLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryMethodLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryMethodLocaleWhere"] | undefined
};
	["DeliveryMethodWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	enabled?: GraphQLTypes["BooleanCondition"] | undefined,
	paymentMethods?: GraphQLTypes["PaymentMethodWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["BooleanCondition"] | undefined,
	suitableForDeliveryZones?: GraphQLTypes["DeliveryZoneWhere"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["BooleanCondition"] | undefined,
	locales?: GraphQLTypes["DeliveryMethodLocaleWhere"] | undefined,
	timelinePreset?: GraphQLTypes["DeliveryTimelinePresetWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryMethodWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryMethodWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryMethodWhere"] | undefined
};
	["PaymentMethodWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	enabled?: GraphQLTypes["BooleanCondition"] | undefined,
	deliveryMethods?: GraphQLTypes["DeliveryMethodWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	blockingDispatch?: GraphQLTypes["BooleanCondition"] | undefined,
	type?: GraphQLTypes["PaymentMethodTypeCondition"] | undefined,
	reduceGratuityByPercentage?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["PaymentMethodWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PaymentMethodWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PaymentMethodWhere"] | undefined
};
	["PaymentMethodTypeCondition"]: {
		and?: Array<GraphQLTypes["PaymentMethodTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["PaymentMethodTypeCondition"]> | undefined,
	not?: GraphQLTypes["PaymentMethodTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["PaymentMethodType"] | undefined,
	notEq?: GraphQLTypes["PaymentMethodType"] | undefined,
	in?: Array<GraphQLTypes["PaymentMethodType"]> | undefined,
	notIn?: Array<GraphQLTypes["PaymentMethodType"]> | undefined,
	lt?: GraphQLTypes["PaymentMethodType"] | undefined,
	lte?: GraphQLTypes["PaymentMethodType"] | undefined,
	gt?: GraphQLTypes["PaymentMethodType"] | undefined,
	gte?: GraphQLTypes["PaymentMethodType"] | undefined
};
	["PaymentMethodType"]: PaymentMethodType;
	["DeliveryZoneWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	type?: GraphQLTypes["DeliveryZoneTypeCondition"] | undefined,
	addresses?: GraphQLTypes["AddressWhere"] | undefined,
	suitableDeliveryMethods?: GraphQLTypes["DeliveryMethodWhere"] | undefined,
	deliveryZonesOfTags?: GraphQLTypes["TagWhere"] | undefined,
	deliveryZonesOfProducts?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryZoneWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryZoneWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryZoneWhere"] | undefined
};
	["DeliveryZoneTypeCondition"]: {
		and?: Array<GraphQLTypes["DeliveryZoneTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["DeliveryZoneTypeCondition"]> | undefined,
	not?: GraphQLTypes["DeliveryZoneTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["DeliveryZoneType"] | undefined,
	notEq?: GraphQLTypes["DeliveryZoneType"] | undefined,
	in?: Array<GraphQLTypes["DeliveryZoneType"]> | undefined,
	notIn?: Array<GraphQLTypes["DeliveryZoneType"]> | undefined,
	lt?: GraphQLTypes["DeliveryZoneType"] | undefined,
	lte?: GraphQLTypes["DeliveryZoneType"] | undefined,
	gt?: GraphQLTypes["DeliveryZoneType"] | undefined,
	gte?: GraphQLTypes["DeliveryZoneType"] | undefined
};
	["DeliveryZoneType"]: DeliveryZoneType;
	["DeliveryTimelinePresetWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["DeliveryTimelineTypeCondition"] | undefined,
	duration?: GraphQLTypes["StringCondition"] | undefined,
	window?: GraphQLTypes["StringCondition"] | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethodWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryTimelinePresetWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryTimelinePresetWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryTimelinePresetWhere"] | undefined
};
	["DeliveryTimelineTypeCondition"]: {
		and?: Array<GraphQLTypes["DeliveryTimelineTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["DeliveryTimelineTypeCondition"]> | undefined,
	not?: GraphQLTypes["DeliveryTimelineTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["DeliveryTimelineType"] | undefined,
	notEq?: GraphQLTypes["DeliveryTimelineType"] | undefined,
	in?: Array<GraphQLTypes["DeliveryTimelineType"]> | undefined,
	notIn?: Array<GraphQLTypes["DeliveryTimelineType"]> | undefined,
	lt?: GraphQLTypes["DeliveryTimelineType"] | undefined,
	lte?: GraphQLTypes["DeliveryTimelineType"] | undefined,
	gt?: GraphQLTypes["DeliveryTimelineType"] | undefined,
	gte?: GraphQLTypes["DeliveryTimelineType"] | undefined
};
	["DeliveryTimelineType"]: DeliveryTimelineType;
	["IdentificationCodeLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	successMessage?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["IdentificationCodeWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["IdentificationCodeLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["IdentificationCodeLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["IdentificationCodeLocaleWhere"] | undefined
};
	["IdentificationCodeWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	attempts?: GraphQLTypes["IdentificationAttemptWhere"] | undefined,
	locales?: GraphQLTypes["IdentificationCodeLocaleWhere"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["IdentificationCodeWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["IdentificationCodeWhere"] | undefined> | undefined,
	not?: GraphQLTypes["IdentificationCodeWhere"] | undefined
};
	["DateTimeCondition"]: {
		and?: Array<GraphQLTypes["DateTimeCondition"]> | undefined,
	or?: Array<GraphQLTypes["DateTimeCondition"]> | undefined,
	not?: GraphQLTypes["DateTimeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["DateTime"] | undefined,
	notEq?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined
};
	["DateTime"]: string;
	["IdentificationAttemptWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	code?: GraphQLTypes["IdentificationCodeWhere"] | undefined,
	and?: Array<GraphQLTypes["IdentificationAttemptWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["IdentificationAttemptWhere"] | undefined> | undefined,
	not?: GraphQLTypes["IdentificationAttemptWhere"] | undefined
};
	["SubscriptionBoxLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	stickerText?: GraphQLTypes["StringCondition"] | undefined,
	descriptionText?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["SubscriptionBoxWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	secondaryTitle?: GraphQLTypes["StringCondition"] | undefined,
	texts?: GraphQLTypes["TextListWhere"] | undefined,
	secondaryTexts?: GraphQLTypes["TextListWhere"] | undefined,
	and?: Array<GraphQLTypes["SubscriptionBoxLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SubscriptionBoxLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SubscriptionBoxLocaleWhere"] | undefined
};
	["SubscriptionBoxWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["SubscriptionBoxLocaleWhere"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	and?: Array<GraphQLTypes["SubscriptionBoxWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SubscriptionBoxWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SubscriptionBoxWhere"] | undefined
};
	["PreparedPackingQuantityWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	date?: GraphQLTypes["DateCondition"] | undefined,
	quantity?: GraphQLTypes["FloatCondition"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeWhere"] | undefined,
	packing?: GraphQLTypes["ProductPackingWhere"] | undefined,
	packedInCardboard?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["PreparedPackingQuantityWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PreparedPackingQuantityWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PreparedPackingQuantityWhere"] | undefined
};
	["DateCondition"]: {
		and?: Array<GraphQLTypes["DateCondition"]> | undefined,
	or?: Array<GraphQLTypes["DateCondition"]> | undefined,
	not?: GraphQLTypes["DateCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Date"] | undefined,
	notEq?: GraphQLTypes["Date"] | undefined,
	in?: Array<GraphQLTypes["Date"]> | undefined,
	notIn?: Array<GraphQLTypes["Date"]> | undefined,
	lt?: GraphQLTypes["Date"] | undefined,
	lte?: GraphQLTypes["Date"] | undefined,
	gt?: GraphQLTypes["Date"] | undefined,
	gte?: GraphQLTypes["Date"] | undefined
};
	["Date"]: any;
	["ProductStockWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	quantity?: GraphQLTypes["IntCondition"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	store?: GraphQLTypes["StoreWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductStockWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductStockWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductStockWhere"] | undefined
};
	["StoreWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["StoreWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["StoreWhere"] | undefined> | undefined,
	not?: GraphQLTypes["StoreWhere"] | undefined
};
	["ProductAvailabilityCondition"]: {
		and?: Array<GraphQLTypes["ProductAvailabilityCondition"]> | undefined,
	or?: Array<GraphQLTypes["ProductAvailabilityCondition"]> | undefined,
	not?: GraphQLTypes["ProductAvailabilityCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ProductAvailability"] | undefined,
	notEq?: GraphQLTypes["ProductAvailability"] | undefined,
	in?: Array<GraphQLTypes["ProductAvailability"]> | undefined,
	notIn?: Array<GraphQLTypes["ProductAvailability"]> | undefined,
	lt?: GraphQLTypes["ProductAvailability"] | undefined,
	lte?: GraphQLTypes["ProductAvailability"] | undefined,
	gt?: GraphQLTypes["ProductAvailability"] | undefined,
	gte?: GraphQLTypes["ProductAvailability"] | undefined
};
	["ProductAvailability"]: ProductAvailability;
	["OrderItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unitPriceCents?: GraphQLTypes["IntCondition"] | undefined,
	quantity?: GraphQLTypes["IntCondition"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	order?: GraphQLTypes["OrderWhere"] | undefined,
	pickedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FulfillmentNoteWhere"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["OrderItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderItemWhere"] | undefined
};
	["OrderWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	state?: GraphQLTypes["OrderStateCondition"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	canceledAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	channel?: GraphQLTypes["ChannelWhere"] | undefined,
	delivery?: GraphQLTypes["OrderDeliveryWhere"] | undefined,
	items?: GraphQLTypes["OrderItemWhere"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	billingAddress?: GraphQLTypes["AddressWhere"] | undefined,
	payments?: GraphQLTypes["OrderPaymentWhere"] | undefined,
	seq?: GraphQLTypes["IntCondition"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["StringCondition"] | undefined,
	venue?: GraphQLTypes["VenueWhere"] | undefined,
	fulfillmentNote?: GraphQLTypes["StringCondition"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FulfillmentNoteWhere"] | undefined,
	doubleshotTotalWeightGrams?: GraphQLTypes["IntCondition"] | undefined,
	doubleShotLegacyNumber?: GraphQLTypes["StringCondition"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	doubleshotAdminUrl?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["OrderWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderWhere"] | undefined
};
	["OrderStateCondition"]: {
		and?: Array<GraphQLTypes["OrderStateCondition"]> | undefined,
	or?: Array<GraphQLTypes["OrderStateCondition"]> | undefined,
	not?: GraphQLTypes["OrderStateCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["OrderState"] | undefined,
	notEq?: GraphQLTypes["OrderState"] | undefined,
	in?: Array<GraphQLTypes["OrderState"]> | undefined,
	notIn?: Array<GraphQLTypes["OrderState"]> | undefined,
	lt?: GraphQLTypes["OrderState"] | undefined,
	lte?: GraphQLTypes["OrderState"] | undefined,
	gt?: GraphQLTypes["OrderState"] | undefined,
	gte?: GraphQLTypes["OrderState"] | undefined
};
	["OrderState"]: OrderState;
	["ChannelWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	disabled?: GraphQLTypes["BooleanCondition"] | undefined,
	customers?: GraphQLTypes["CustomerWhere"] | undefined,
	code?: GraphQLTypes["ChannelCodeCondition"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["TagWhere"] | undefined,
	publicTags?: GraphQLTypes["TagWhere"] | undefined,
	venue?: GraphQLTypes["VenueWhere"] | undefined,
	and?: Array<GraphQLTypes["ChannelWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ChannelWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ChannelWhere"] | undefined
};
	["ChannelCodeCondition"]: {
		and?: Array<GraphQLTypes["ChannelCodeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ChannelCodeCondition"]> | undefined,
	not?: GraphQLTypes["ChannelCodeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ChannelCode"] | undefined,
	notEq?: GraphQLTypes["ChannelCode"] | undefined,
	in?: Array<GraphQLTypes["ChannelCode"]> | undefined,
	notIn?: Array<GraphQLTypes["ChannelCode"]> | undefined,
	lt?: GraphQLTypes["ChannelCode"] | undefined,
	lte?: GraphQLTypes["ChannelCode"] | undefined,
	gt?: GraphQLTypes["ChannelCode"] | undefined,
	gte?: GraphQLTypes["ChannelCode"] | undefined
};
	["ChannelCode"]: ChannelCode;
	["VenueWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	deadlineTemplate?: GraphQLTypes["DeadlineTemplateWhere"] | undefined,
	timetableDays?: GraphQLTypes["TimetableDayWhere"] | undefined,
	timetableTemplates?: GraphQLTypes["TimetableTemplateWhere"] | undefined,
	staffMembers?: GraphQLTypes["StaffWhere"] | undefined,
	employeeRoles?: GraphQLTypes["VenueEmployeeRoleWhere"] | undefined,
	orders?: GraphQLTypes["OrderWhere"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FulfillmentNoteWhere"] | undefined,
	enabledUnassignReasons?: GraphQLTypes["UnassignReasonWhere"] | undefined,
	channels?: GraphQLTypes["ChannelWhere"] | undefined,
	address?: GraphQLTypes["StringCondition"] | undefined,
	pointOfSalePngBase64ReceiptLogo?: GraphQLTypes["StringCondition"] | undefined,
	pointOfSaleInitialView?: GraphQLTypes["PointOfSaleInitialViewCondition"] | undefined,
	pointOfSalePreferPrintReceipt?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["VenueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VenueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VenueWhere"] | undefined
};
	["DeadlineTemplateWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	startDate?: GraphQLTypes["DateCondition"] | undefined,
	period?: GraphQLTypes["IntCondition"] | undefined,
	cutoff?: GraphQLTypes["IntCondition"] | undefined,
	closed?: GraphQLTypes["BooleanCondition"] | undefined,
	venue?: GraphQLTypes["VenueWhere"] | undefined,
	and?: Array<GraphQLTypes["DeadlineTemplateWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeadlineTemplateWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeadlineTemplateWhere"] | undefined
};
	["TimetableDayWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	date?: GraphQLTypes["DateCondition"] | undefined,
	type?: GraphQLTypes["TimetableDayTypeCondition"] | undefined,
	shiftGroups?: GraphQLTypes["TimetableShiftGroupWhere"] | undefined,
	tipsCents?: GraphQLTypes["IntCondition"] | undefined,
	notes?: GraphQLTypes["TimetableDayNoteWhere"] | undefined,
	venue?: GraphQLTypes["VenueWhere"] | undefined,
	meta?: GraphQLTypes["TimetableDayMetadataWhere"] | undefined,
	and?: Array<GraphQLTypes["TimetableDayWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TimetableDayWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TimetableDayWhere"] | undefined
};
	["TimetableDayTypeCondition"]: {
		and?: Array<GraphQLTypes["TimetableDayTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["TimetableDayTypeCondition"]> | undefined,
	not?: GraphQLTypes["TimetableDayTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["TimetableDayType"] | undefined,
	notEq?: GraphQLTypes["TimetableDayType"] | undefined,
	in?: Array<GraphQLTypes["TimetableDayType"]> | undefined,
	notIn?: Array<GraphQLTypes["TimetableDayType"]> | undefined,
	lt?: GraphQLTypes["TimetableDayType"] | undefined,
	lte?: GraphQLTypes["TimetableDayType"] | undefined,
	gt?: GraphQLTypes["TimetableDayType"] | undefined,
	gte?: GraphQLTypes["TimetableDayType"] | undefined
};
	["TimetableDayType"]: TimetableDayType;
	["TimetableShiftGroupWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	shifts?: GraphQLTypes["TimetableShiftWhere"] | undefined,
	day?: GraphQLTypes["TimetableDayWhere"] | undefined,
	type?: GraphQLTypes["TimetableEmployeeRoleWhere"] | undefined,
	position?: GraphQLTypes["TimetableShiftPositionWhere"] | undefined,
	queue?: GraphQLTypes["EmployeeQueueWhere"] | undefined,
	generatedByTemplate?: GraphQLTypes["TimetableTemplateWhere"] | undefined,
	and?: Array<GraphQLTypes["TimetableShiftGroupWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TimetableShiftGroupWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TimetableShiftGroupWhere"] | undefined
};
	["TimetableShiftWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locked?: GraphQLTypes["BooleanCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	assigned?: GraphQLTypes["DateCondition"] | undefined,
	group?: GraphQLTypes["TimetableShiftGroupWhere"] | undefined,
	shiftsProfile?: GraphQLTypes["ShiftsProfileWhere"] | undefined,
	queueItem?: GraphQLTypes["EmployeeQueueItemWhere"] | undefined,
	isOvertime?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["TimetableShiftWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TimetableShiftWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TimetableShiftWhere"] | undefined
};
	["ShiftsProfileWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	queueItems?: GraphQLTypes["EmployeeQueueItemWhere"] | undefined,
	staff?: GraphQLTypes["StaffWhere"] | undefined,
	roles?: GraphQLTypes["TimetableEmployeeRoleWhere"] | undefined,
	shifts?: GraphQLTypes["TimetableShiftWhere"] | undefined,
	allowSelfUnassigning?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["ShiftsProfileWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ShiftsProfileWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ShiftsProfileWhere"] | undefined
};
	["EmployeeQueueItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	start?: GraphQLTypes["StringCondition"] | undefined,
	end?: GraphQLTypes["StringCondition"] | undefined,
	queue?: GraphQLTypes["EmployeeQueueWhere"] | undefined,
	shiftsProfile?: GraphQLTypes["ShiftsProfileWhere"] | undefined,
	shift?: GraphQLTypes["TimetableShiftWhere"] | undefined,
	unassignInfo?: GraphQLTypes["UnassignedShiftInfoWhere"] | undefined,
	and?: Array<GraphQLTypes["EmployeeQueueItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["EmployeeQueueItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["EmployeeQueueItemWhere"] | undefined
};
	["EmployeeQueueWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	shiftGroup?: GraphQLTypes["TimetableShiftGroupWhere"] | undefined,
	items?: GraphQLTypes["EmployeeQueueItemWhere"] | undefined,
	contemberBugFix?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["EmployeeQueueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["EmployeeQueueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["EmployeeQueueWhere"] | undefined
};
	["UnassignedShiftInfoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	hoursMissed?: GraphQLTypes["IntCondition"] | undefined,
	queueItem?: GraphQLTypes["EmployeeQueueItemWhere"] | undefined,
	legacyReason?: GraphQLTypes["UnassignedInfoReasonCondition"] | undefined,
	reason?: GraphQLTypes["UnassignReasonWhere"] | undefined,
	and?: Array<GraphQLTypes["UnassignedShiftInfoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["UnassignedShiftInfoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["UnassignedShiftInfoWhere"] | undefined
};
	["UnassignedInfoReasonCondition"]: {
		and?: Array<GraphQLTypes["UnassignedInfoReasonCondition"]> | undefined,
	or?: Array<GraphQLTypes["UnassignedInfoReasonCondition"]> | undefined,
	not?: GraphQLTypes["UnassignedInfoReasonCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["UnassignedInfoReason"] | undefined,
	notEq?: GraphQLTypes["UnassignedInfoReason"] | undefined,
	in?: Array<GraphQLTypes["UnassignedInfoReason"]> | undefined,
	notIn?: Array<GraphQLTypes["UnassignedInfoReason"]> | undefined,
	lt?: GraphQLTypes["UnassignedInfoReason"] | undefined,
	lte?: GraphQLTypes["UnassignedInfoReason"] | undefined,
	gt?: GraphQLTypes["UnassignedInfoReason"] | undefined,
	gte?: GraphQLTypes["UnassignedInfoReason"] | undefined
};
	["UnassignedInfoReason"]: UnassignedInfoReason;
	["UnassignReasonWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	venues?: GraphQLTypes["VenueWhere"] | undefined,
	and?: Array<GraphQLTypes["UnassignReasonWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["UnassignReasonWhere"] | undefined> | undefined,
	not?: GraphQLTypes["UnassignReasonWhere"] | undefined
};
	["StaffWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	shiftsProfile?: GraphQLTypes["ShiftsProfileWhere"] | undefined,
	photo?: GraphQLTypes["ImageWhere"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	venues?: GraphQLTypes["VenueWhere"] | undefined,
	and?: Array<GraphQLTypes["StaffWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["StaffWhere"] | undefined> | undefined,
	not?: GraphQLTypes["StaffWhere"] | undefined
};
	["TimetableEmployeeRoleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	shiftGroups?: GraphQLTypes["TimetableShiftGroupWhere"] | undefined,
	shiftsProfiles?: GraphQLTypes["ShiftsProfileWhere"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	timetableTemplates?: GraphQLTypes["TimetableTemplateWhere"] | undefined,
	color?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["TimetableEmployeeRoleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TimetableEmployeeRoleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TimetableEmployeeRoleWhere"] | undefined
};
	["TimetableTemplateWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	startDate?: GraphQLTypes["DateCondition"] | undefined,
	period?: GraphQLTypes["IntCondition"] | undefined,
	slots?: GraphQLTypes["IntCondition"] | undefined,
	position?: GraphQLTypes["TimetableShiftPositionWhere"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	venue?: GraphQLTypes["VenueWhere"] | undefined,
	employeeRole?: GraphQLTypes["TimetableEmployeeRoleWhere"] | undefined,
	workdaysOnly?: GraphQLTypes["BooleanCondition"] | undefined,
	monday?: GraphQLTypes["BooleanCondition"] | undefined,
	tuesday?: GraphQLTypes["BooleanCondition"] | undefined,
	wednesday?: GraphQLTypes["BooleanCondition"] | undefined,
	thursday?: GraphQLTypes["BooleanCondition"] | undefined,
	friday?: GraphQLTypes["BooleanCondition"] | undefined,
	saturday?: GraphQLTypes["BooleanCondition"] | undefined,
	sunday?: GraphQLTypes["BooleanCondition"] | undefined,
	type?: GraphQLTypes["TemplateTypeCondition"] | undefined,
	and?: Array<GraphQLTypes["TimetableTemplateWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TimetableTemplateWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TimetableTemplateWhere"] | undefined
};
	["TimetableShiftPositionWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	start?: GraphQLTypes["StringCondition"] | undefined,
	end?: GraphQLTypes["StringCondition"] | undefined,
	shiftGroups?: GraphQLTypes["TimetableShiftGroupWhere"] | undefined,
	timetableTemplates?: GraphQLTypes["TimetableTemplateWhere"] | undefined,
	isDynamic?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["TimetableShiftPositionWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TimetableShiftPositionWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TimetableShiftPositionWhere"] | undefined
};
	["TemplateTypeCondition"]: {
		and?: Array<GraphQLTypes["TemplateTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["TemplateTypeCondition"]> | undefined,
	not?: GraphQLTypes["TemplateTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["TemplateType"] | undefined,
	notEq?: GraphQLTypes["TemplateType"] | undefined,
	in?: Array<GraphQLTypes["TemplateType"]> | undefined,
	notIn?: Array<GraphQLTypes["TemplateType"]> | undefined,
	lt?: GraphQLTypes["TemplateType"] | undefined,
	lte?: GraphQLTypes["TemplateType"] | undefined,
	gt?: GraphQLTypes["TemplateType"] | undefined,
	gte?: GraphQLTypes["TemplateType"] | undefined
};
	["TemplateType"]: TemplateType;
	["TimetableDayNoteWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	day?: GraphQLTypes["TimetableDayWhere"] | undefined,
	role?: GraphQLTypes["TimetableEmployeeRoleWhere"] | undefined,
	and?: Array<GraphQLTypes["TimetableDayNoteWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TimetableDayNoteWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TimetableDayNoteWhere"] | undefined
};
	["TimetableDayMetadataWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	numberOfWorkers?: GraphQLTypes["IntCondition"] | undefined,
	tipsTotalCents?: GraphQLTypes["IntCondition"] | undefined,
	totalMinutes?: GraphQLTypes["IntCondition"] | undefined,
	tipsCentsPerMinute?: GraphQLTypes["FloatCondition"] | undefined,
	day?: GraphQLTypes["TimetableDayWhere"] | undefined,
	and?: Array<GraphQLTypes["TimetableDayMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TimetableDayMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TimetableDayMetadataWhere"] | undefined
};
	["VenueEmployeeRoleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	employeePrivileges?: GraphQLTypes["TimetablesEmployeePrivilegesWhere"] | undefined,
	venue?: GraphQLTypes["VenueWhere"] | undefined,
	role?: GraphQLTypes["TimetableEmployeeRoleWhere"] | undefined,
	getsTipsShare?: GraphQLTypes["BooleanCondition"] | undefined,
	notesEnabled?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["VenueEmployeeRoleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VenueEmployeeRoleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VenueEmployeeRoleWhere"] | undefined
};
	["TimetablesEmployeePrivilegesWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	venueEmployeeRole?: GraphQLTypes["VenueEmployeeRoleWhere"] | undefined,
	read?: GraphQLTypes["BooleanCondition"] | undefined,
	unassign?: GraphQLTypes["BooleanCondition"] | undefined,
	assign?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["TimetablesEmployeePrivilegesWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TimetablesEmployeePrivilegesWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TimetablesEmployeePrivilegesWhere"] | undefined
};
	["FulfillmentNoteWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	products?: GraphQLTypes["ProductWhere"] | undefined,
	tags?: GraphQLTypes["TagWhere"] | undefined,
	venues?: GraphQLTypes["VenueWhere"] | undefined,
	orderItems?: GraphQLTypes["OrderItemWhere"] | undefined,
	orders?: GraphQLTypes["OrderWhere"] | undefined,
	highlight?: GraphQLTypes["BooleanCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	emoji?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["FulfillmentNoteWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FulfillmentNoteWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FulfillmentNoteWhere"] | undefined
};
	["PointOfSaleInitialViewCondition"]: {
		and?: Array<GraphQLTypes["PointOfSaleInitialViewCondition"]> | undefined,
	or?: Array<GraphQLTypes["PointOfSaleInitialViewCondition"]> | undefined,
	not?: GraphQLTypes["PointOfSaleInitialViewCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["PointOfSaleInitialView"] | undefined,
	notEq?: GraphQLTypes["PointOfSaleInitialView"] | undefined,
	in?: Array<GraphQLTypes["PointOfSaleInitialView"]> | undefined,
	notIn?: Array<GraphQLTypes["PointOfSaleInitialView"]> | undefined,
	lt?: GraphQLTypes["PointOfSaleInitialView"] | undefined,
	lte?: GraphQLTypes["PointOfSaleInitialView"] | undefined,
	gt?: GraphQLTypes["PointOfSaleInitialView"] | undefined,
	gte?: GraphQLTypes["PointOfSaleInitialView"] | undefined
};
	["PointOfSaleInitialView"]: PointOfSaleInitialView;
	["OrderDeliveryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	priceCents?: GraphQLTypes["IntCondition"] | undefined,
	deliveredAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	method?: GraphQLTypes["DeliveryMethodWhere"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	order?: GraphQLTypes["OrderWhere"] | undefined,
	expectedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	state?: GraphQLTypes["OrderDeliveryStateCondition"] | undefined,
	loadingDurationHours?: GraphQLTypes["IntCondition"] | undefined,
	dispatchAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	duration?: GraphQLTypes["StringCondition"] | undefined,
	window?: GraphQLTypes["StringCondition"] | undefined,
	expectedEndAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	processingAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	deliveringAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	failedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	deliveryWaypoint?: GraphQLTypes["DeliveryWaypointWhere"] | undefined,
	prepareAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	pickedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	address?: GraphQLTypes["AddressWhere"] | undefined,
	trackingCode?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["OrderDeliveryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderDeliveryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderDeliveryWhere"] | undefined
};
	["OrderDeliveryStateCondition"]: {
		and?: Array<GraphQLTypes["OrderDeliveryStateCondition"]> | undefined,
	or?: Array<GraphQLTypes["OrderDeliveryStateCondition"]> | undefined,
	not?: GraphQLTypes["OrderDeliveryStateCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["OrderDeliveryState"] | undefined,
	notEq?: GraphQLTypes["OrderDeliveryState"] | undefined,
	in?: Array<GraphQLTypes["OrderDeliveryState"]> | undefined,
	notIn?: Array<GraphQLTypes["OrderDeliveryState"]> | undefined,
	lt?: GraphQLTypes["OrderDeliveryState"] | undefined,
	lte?: GraphQLTypes["OrderDeliveryState"] | undefined,
	gt?: GraphQLTypes["OrderDeliveryState"] | undefined,
	gte?: GraphQLTypes["OrderDeliveryState"] | undefined
};
	["OrderDeliveryState"]: OrderDeliveryState;
	["DeliveryWaypointWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	estimatedArrival?: GraphQLTypes["DateTimeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	route?: GraphQLTypes["DeliveryRouteWhere"] | undefined,
	sortingOrder?: GraphQLTypes["IntCondition"] | undefined,
	delivery?: GraphQLTypes["OrderDeliveryWhere"] | undefined,
	estimatedDistance?: GraphQLTypes["IntCondition"] | undefined,
	estimatedDuration?: GraphQLTypes["IntCondition"] | undefined,
	originDeparture?: GraphQLTypes["DateTimeCondition"] | undefined,
	originAddress?: GraphQLTypes["StringCondition"] | undefined,
	transitMode?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["DeliveryWaypointWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryWaypointWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryWaypointWhere"] | undefined
};
	["DeliveryRouteWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	waypoints?: GraphQLTypes["DeliveryWaypointWhere"] | undefined,
	dispatchAt?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["DeliveryRouteWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryRouteWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryRouteWhere"] | undefined
};
	["OrderPaymentWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	data?: GraphQLTypes["JsonCondition"] | undefined,
	paymentCents?: GraphQLTypes["IntCondition"] | undefined,
	method?: GraphQLTypes["PaymentMethodWhere"] | undefined,
	failedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	state?: GraphQLTypes["OrderPaymentStateCondition"] | undefined,
	approvedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	order?: GraphQLTypes["OrderWhere"] | undefined,
	walletType?: GraphQLTypes["StringCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	publicReceiptUrl?: GraphQLTypes["StringCondition"] | undefined,
	priceCents?: GraphQLTypes["IntCondition"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	otherCurrencyPriceCents?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["OrderPaymentWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderPaymentWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderPaymentWhere"] | undefined
};
	["JsonCondition"]: {
		and?: Array<GraphQLTypes["JsonCondition"]> | undefined,
	or?: Array<GraphQLTypes["JsonCondition"]> | undefined,
	not?: GraphQLTypes["JsonCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined
};
	["OrderPaymentStateCondition"]: {
		and?: Array<GraphQLTypes["OrderPaymentStateCondition"]> | undefined,
	or?: Array<GraphQLTypes["OrderPaymentStateCondition"]> | undefined,
	not?: GraphQLTypes["OrderPaymentStateCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["OrderPaymentState"] | undefined,
	notEq?: GraphQLTypes["OrderPaymentState"] | undefined,
	in?: Array<GraphQLTypes["OrderPaymentState"]> | undefined,
	notIn?: Array<GraphQLTypes["OrderPaymentState"]> | undefined,
	lt?: GraphQLTypes["OrderPaymentState"] | undefined,
	lte?: GraphQLTypes["OrderPaymentState"] | undefined,
	gt?: GraphQLTypes["OrderPaymentState"] | undefined,
	gte?: GraphQLTypes["OrderPaymentState"] | undefined
};
	["OrderPaymentState"]: OrderPaymentState;
	["ProductMetadataWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	archived?: GraphQLTypes["BooleanCondition"] | undefined,
	available?: GraphQLTypes["BooleanCondition"] | undefined,
	availableQuantity?: GraphQLTypes["IntCondition"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductMetadataWhere"] | undefined
};
	["CustomerNameWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	nominative?: GraphQLTypes["StringCondition"] | undefined,
	vocative?: GraphQLTypes["StringCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	and?: Array<GraphQLTypes["CustomerNameWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CustomerNameWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CustomerNameWhere"] | undefined
};
	["CustomerFamiliarityCondition"]: {
		and?: Array<GraphQLTypes["CustomerFamiliarityCondition"]> | undefined,
	or?: Array<GraphQLTypes["CustomerFamiliarityCondition"]> | undefined,
	not?: GraphQLTypes["CustomerFamiliarityCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["CustomerFamiliarity"] | undefined,
	notEq?: GraphQLTypes["CustomerFamiliarity"] | undefined,
	in?: Array<GraphQLTypes["CustomerFamiliarity"]> | undefined,
	notIn?: Array<GraphQLTypes["CustomerFamiliarity"]> | undefined,
	lt?: GraphQLTypes["CustomerFamiliarity"] | undefined,
	lte?: GraphQLTypes["CustomerFamiliarity"] | undefined,
	gt?: GraphQLTypes["CustomerFamiliarity"] | undefined,
	gte?: GraphQLTypes["CustomerFamiliarity"] | undefined
};
	["CustomerFamiliarity"]: CustomerFamiliarity;
	["CustomerMetadataWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	isBusiness?: GraphQLTypes["BooleanCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	fallbackName?: GraphQLTypes["StringCondition"] | undefined,
	remainingCreditsCents?: GraphQLTypes["IntCondition"] | undefined,
	ordersTotalCents?: GraphQLTypes["IntCondition"] | undefined,
	customerProfileUrl?: GraphQLTypes["StringCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	and?: Array<GraphQLTypes["CustomerMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CustomerMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CustomerMetadataWhere"] | undefined
};
	["CountryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["CountryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CountryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CountryWhere"] | undefined
};
	["AddressOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	firstName?: GraphQLTypes["OrderDirection"] | undefined,
	lastName?: GraphQLTypes["OrderDirection"] | undefined,
	addressLine2?: GraphQLTypes["OrderDirection"] | undefined,
	email?: GraphQLTypes["OrderDirection"] | undefined,
	phone?: GraphQLTypes["OrderDirection"] | undefined,
	companyName?: GraphQLTypes["OrderDirection"] | undefined,
	companyIdentifier?: GraphQLTypes["OrderDirection"] | undefined,
	vatIdentifier?: GraphQLTypes["OrderDirection"] | undefined,
	externalIdentifier?: GraphQLTypes["OrderDirection"] | undefined,
	hidden?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	country?: GraphQLTypes["CountryOrderBy"] | undefined,
	deletedAt?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	googlePlaceId?: GraphQLTypes["OrderDirection"] | undefined,
	raw?: GraphQLTypes["OrderDirection"] | undefined,
	formatted?: GraphQLTypes["OrderDirection"] | undefined,
	geocodeGeneratedAt?: GraphQLTypes["OrderDirection"] | undefined,
	geocodeResponse?: GraphQLTypes["OrderDirection"] | undefined,
	deliveryZone?: GraphQLTypes["DeliveryZoneOrderBy"] | undefined,
	geocodeValid?: GraphQLTypes["OrderDirection"] | undefined,
	fullName?: GraphQLTypes["OrderDirection"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["OrderDirection"] | undefined,
	addressLine1?: GraphQLTypes["OrderDirection"] | undefined,
	city?: GraphQLTypes["OrderDirection"] | undefined,
	postalCode?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["CustomerOrderBy"] | undefined
};
	["OrderDirection"]: OrderDirection;
	["CustomerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	defaultBillingAddress?: GraphQLTypes["AddressOrderBy"] | undefined,
	photo?: GraphQLTypes["ImageOrderBy"] | undefined,
	phone?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["CustomerNameOrderBy"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	welcomeMessage?: GraphQLTypes["OrderDirection"] | undefined,
	familiarity?: GraphQLTypes["OrderDirection"] | undefined,
	disabledAt?: GraphQLTypes["OrderDirection"] | undefined,
	email?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["AddressOrderBy"] | undefined,
	prefersPackingsWithoutCardboard?: GraphQLTypes["OrderDirection"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["OrderDirection"] | undefined,
	meta?: GraphQLTypes["CustomerMetadataOrderBy"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["OrderDirection"] | undefined
};
	["ImageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	url?: GraphQLTypes["OrderDirection"] | undefined,
	width?: GraphQLTypes["OrderDirection"] | undefined,
	height?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	alt?: GraphQLTypes["OrderDirection"] | undefined
};
	["CustomerNameOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	nominative?: GraphQLTypes["OrderDirection"] | undefined,
	vocative?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined
};
	["CustomerMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	isBusiness?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	fallbackName?: GraphQLTypes["OrderDirection"] | undefined,
	remainingCreditsCents?: GraphQLTypes["OrderDirection"] | undefined,
	ordersTotalCents?: GraphQLTypes["OrderDirection"] | undefined,
	customerProfileUrl?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined
};
	["CountryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["DeliveryZoneOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined
};
	["Tag"]: {
	__typename: "Tag",
	_meta?: GraphQLTypes["TagMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	customers: Array<GraphQLTypes["Customer"]>,
	products: Array<GraphQLTypes["Product"]>,
	categories: Array<GraphQLTypes["ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Channel"]>,
	deliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	publicInChannels: Array<GraphQLTypes["Channel"]>,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	paginateCustomers: GraphQLTypes["CustomerConnection"],
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateCategories: GraphQLTypes["ProductCategoryConnection"],
	paginateAddTagsOnRegistration: GraphQLTypes["ChannelConnection"],
	paginateDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginatePublicInChannels: GraphQLTypes["ChannelConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["TagMeta"]: {
	__typename: "TagMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	customers?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	publicInChannels?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined
};
	["Product"]: {
	__typename: "Product",
	_meta?: GraphQLTypes["ProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	priceCents: number,
	notDiscountable: boolean,
	packing?: GraphQLTypes["ProductPacking"] | undefined,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	tags: Array<GraphQLTypes["Tag"]>,
	categories: Array<GraphQLTypes["ProductCategory"]>,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	stocks: Array<GraphQLTypes["ProductStock"]>,
	image?: GraphQLTypes["Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	availability: GraphQLTypes["ProductAvailability"],
	order: number,
	orderItems: Array<GraphQLTypes["OrderItem"]>,
	maximumQuantityPerOrder?: number | undefined,
	deliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	locales: Array<GraphQLTypes["ProductLocale"]>,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	virtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	meta?: GraphQLTypes["ProductMetadata"] | undefined,
	page?: GraphQLTypes["Page"] | undefined,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	hasSubscription: boolean,
	pointOfSaleTileColor?: string | undefined,
	stocksByStore?: GraphQLTypes["ProductStock"] | undefined,
	localesByLocale?: GraphQLTypes["ProductLocale"] | undefined,
	localesByFeatureList?: GraphQLTypes["ProductLocale"] | undefined,
	paginateTags: GraphQLTypes["TagConnection"],
	paginateCategories: GraphQLTypes["ProductCategoryConnection"],
	paginateStocks: GraphQLTypes["ProductStockConnection"],
	paginateOrderItems: GraphQLTypes["OrderItemConnection"],
	paginateDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginateLocales: GraphQLTypes["ProductLocaleConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["ProductMeta"]: {
	__typename: "ProductMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	notDiscountable?: GraphQLTypes["FieldMeta"] | undefined,
	packing?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	stocks?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	availability?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	orderItems?: GraphQLTypes["FieldMeta"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	archivedAt?: GraphQLTypes["FieldMeta"] | undefined,
	virtualProduct?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	page?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined,
	hasSubscription?: GraphQLTypes["FieldMeta"] | undefined,
	pointOfSaleTileColor?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductPacking"]: {
	__typename: "ProductPacking",
	_meta?: GraphQLTypes["ProductPackingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	image?: GraphQLTypes["Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	volumeMl?: number | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	shortName?: string | undefined,
	locales: Array<GraphQLTypes["ProductPackingLocale"]>,
	preparedPackingQuantities: Array<GraphQLTypes["PreparedPackingQuantity"]>,
	isPackedInCardboard: boolean,
	localesByLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	paginateLocales: GraphQLTypes["ProductPackingLocaleConnection"],
	paginatePreparedPackingQuantities: GraphQLTypes["PreparedPackingQuantityConnection"]
};
	["ProductPackingMeta"]: {
	__typename: "ProductPackingMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	shortName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	preparedPackingQuantities?: GraphQLTypes["FieldMeta"] | undefined,
	isPackedInCardboard?: GraphQLTypes["FieldMeta"] | undefined
};
	["Image"]: {
	__typename: "Image",
	_meta?: GraphQLTypes["ImageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined
};
	["ImageMeta"]: {
	__typename: "ImageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	alt?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductPackingLocale"]: {
	__typename: "ProductPackingLocale",
	_meta?: GraphQLTypes["ProductPackingLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	shortName?: string | undefined,
	description?: string | undefined,
	root?: GraphQLTypes["ProductPacking"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductPackingLocaleMeta"]: {
	__typename: "ProductPackingLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	shortName?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["Locale"]: {
	__typename: "Locale",
	_meta?: GraphQLTypes["LocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	businessCategories: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	productCategoryLists: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	productIngredients: Array<GraphQLTypes["ProductIngredientLocale"]>,
	orderRecurrences: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	blogs: Array<GraphQLTypes["BlogLocale"]>,
	blogPosts: Array<GraphQLTypes["BlogPostLocale"]>,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	pages: Array<GraphQLTypes["PageLocale"]>,
	productIngredientCategories: Array<GraphQLTypes["ProductIngredientCategoryLocale"]>,
	allergens: Array<GraphQLTypes["AllergenLocale"]>,
	productPackings: Array<GraphQLTypes["ProductPackingLocale"]>,
	products: Array<GraphQLTypes["ProductLocale"]>,
	productRecipes: Array<GraphQLTypes["ProductRecipeLocale"]>,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	ingredientSuppliers: Array<GraphQLTypes["IngredientSupplierLocale"]>,
	identificationCodes: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	subscriptionBoxes: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	businessCategoriesByRoot?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	productCategoryListsByRoot?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	productIngredientsByRoot?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	orderRecurrencesByRoot?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	blogsByRoot?: GraphQLTypes["BlogLocale"] | undefined,
	blogPostsByRoot?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByCover?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByContent?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByLink?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsBySeo?: GraphQLTypes["BlogPostLocale"] | undefined,
	pagesByRoot?: GraphQLTypes["PageLocale"] | undefined,
	pagesByLink?: GraphQLTypes["PageLocale"] | undefined,
	pagesBySeo?: GraphQLTypes["PageLocale"] | undefined,
	pagesByContent?: GraphQLTypes["PageLocale"] | undefined,
	pagesByCover?: GraphQLTypes["PageLocale"] | undefined,
	productIngredientCategoriesByRoot?: GraphQLTypes["ProductIngredientCategoryLocale"] | undefined,
	allergensByRoot?: GraphQLTypes["AllergenLocale"] | undefined,
	productPackingsByRoot?: GraphQLTypes["ProductPackingLocale"] | undefined,
	productsByRoot?: GraphQLTypes["ProductLocale"] | undefined,
	productsByFeatureList?: GraphQLTypes["ProductLocale"] | undefined,
	productRecipesByRoot?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	deliveryMethodsByRoot?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	ingredientSuppliersByRoot?: GraphQLTypes["IngredientSupplierLocale"] | undefined,
	identificationCodesByRoot?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	subscriptionBoxesByRoot?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	subscriptionBoxesByTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	subscriptionBoxesBySecondaryTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	paginateBusinessCategories: GraphQLTypes["BusinessCategoryLocaleConnection"],
	paginateProductCategoryLists: GraphQLTypes["ProductCategoryListLocaleConnection"],
	paginateProductIngredients: GraphQLTypes["ProductIngredientLocaleConnection"],
	paginateOrderRecurrences: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	paginateBlogs: GraphQLTypes["BlogLocaleConnection"],
	paginateBlogPosts: GraphQLTypes["BlogPostLocaleConnection"],
	paginatePages: GraphQLTypes["PageLocaleConnection"],
	paginateProductIngredientCategories: GraphQLTypes["ProductIngredientCategoryLocaleConnection"],
	paginateAllergens: GraphQLTypes["AllergenLocaleConnection"],
	paginateProductPackings: GraphQLTypes["ProductPackingLocaleConnection"],
	paginateProducts: GraphQLTypes["ProductLocaleConnection"],
	paginateProductRecipes: GraphQLTypes["ProductRecipeLocaleConnection"],
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodLocaleConnection"],
	paginateIngredientSuppliers: GraphQLTypes["IngredientSupplierLocaleConnection"],
	paginateIdentificationCodes: GraphQLTypes["IdentificationCodeLocaleConnection"],
	paginateSubscriptionBoxes: GraphQLTypes["SubscriptionBoxLocaleConnection"]
};
	["LocaleMeta"]: {
	__typename: "LocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategories?: GraphQLTypes["FieldMeta"] | undefined,
	productCategoryLists?: GraphQLTypes["FieldMeta"] | undefined,
	productIngredients?: GraphQLTypes["FieldMeta"] | undefined,
	orderRecurrences?: GraphQLTypes["FieldMeta"] | undefined,
	blogs?: GraphQLTypes["FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	pages?: GraphQLTypes["FieldMeta"] | undefined,
	productIngredientCategories?: GraphQLTypes["FieldMeta"] | undefined,
	allergens?: GraphQLTypes["FieldMeta"] | undefined,
	productPackings?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	productRecipes?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	ingredientSuppliers?: GraphQLTypes["FieldMeta"] | undefined,
	identificationCodes?: GraphQLTypes["FieldMeta"] | undefined,
	subscriptionBoxes?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategoryLocale"]: {
	__typename: "BusinessCategoryLocale",
	_meta?: GraphQLTypes["BusinessCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["BusinessCategory"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	title?: string | undefined
};
	["BusinessCategoryLocaleMeta"]: {
	__typename: "BusinessCategoryLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategory"]: {
	__typename: "BusinessCategory",
	_meta?: GraphQLTypes["BusinessCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	visibleToNewCustomers: boolean,
	locales: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	internalName: string,
	recurrences: Array<GraphQLTypes["OrderRecurrence"]>,
	specificProducts?: GraphQLTypes["ProductList"] | undefined,
	seasonalProductCategories?: GraphQLTypes["ProductCategoryList"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["ProductCategoryList"] | undefined,
	slug: string,
	localesByLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	paginateLocales: GraphQLTypes["BusinessCategoryLocaleConnection"],
	paginateRecurrences: GraphQLTypes["OrderRecurrenceConnection"]
};
	["BusinessCategoryMeta"]: {
	__typename: "BusinessCategoryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	recurrences?: GraphQLTypes["FieldMeta"] | undefined,
	specificProducts?: GraphQLTypes["FieldMeta"] | undefined,
	seasonalProductCategories?: GraphQLTypes["FieldMeta"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["FieldMeta"] | undefined,
	slug?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategoryLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["BusinessCategoryOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined
};
	["BusinessCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	specificProducts?: GraphQLTypes["ProductListOrderBy"] | undefined,
	seasonalProductCategories?: GraphQLTypes["ProductCategoryListOrderBy"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["ProductCategoryListOrderBy"] | undefined,
	slug?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryOrderBy"] | undefined
};
	["ProductCategoryListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryOrderBy"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryOrderBy"] | undefined
};
	["LocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierOrderBy"] | undefined
};
	["TranslationCataloguesIdentifierOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderRecurrence"]: {
	__typename: "OrderRecurrence",
	_meta?: GraphQLTypes["OrderRecurrenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	order: number,
	businessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	localesByLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	paginateLocales: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"]
};
	["OrderRecurrenceMeta"]: {
	__typename: "OrderRecurrenceMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderRecurrenceLocale"]: {
	__typename: "OrderRecurrenceLocale",
	_meta?: GraphQLTypes["OrderRecurrenceLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["OrderRecurrence"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["OrderRecurrenceLocaleMeta"]: {
	__typename: "OrderRecurrenceLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderRecurrenceLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["OrderRecurrenceOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["OrderRecurrenceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderRecurrenceLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["LocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: GraphQLTypes["BusinessCategoryLocaleUniqueWhere"] | undefined,
	productCategoryLists?: GraphQLTypes["ProductCategoryListLocaleUniqueWhere"] | undefined,
	productIngredients?: GraphQLTypes["ProductIngredientLocaleUniqueWhere"] | undefined,
	orderRecurrences?: GraphQLTypes["OrderRecurrenceLocaleUniqueWhere"] | undefined,
	blogs?: GraphQLTypes["BlogLocaleUniqueWhere"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostLocaleUniqueWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	pages?: GraphQLTypes["PageLocaleUniqueWhere"] | undefined,
	productIngredientCategories?: GraphQLTypes["ProductIngredientCategoryLocaleUniqueWhere"] | undefined,
	allergens?: GraphQLTypes["AllergenLocaleUniqueWhere"] | undefined,
	productPackings?: GraphQLTypes["ProductPackingLocaleUniqueWhere"] | undefined,
	products?: GraphQLTypes["ProductLocaleUniqueWhere"] | undefined,
	productRecipes?: GraphQLTypes["ProductRecipeLocaleUniqueWhere"] | undefined,
	deliveryMethods?: GraphQLTypes["DeliveryMethodLocaleUniqueWhere"] | undefined,
	ingredientSuppliers?: GraphQLTypes["IngredientSupplierLocaleUniqueWhere"] | undefined,
	identificationCodes?: GraphQLTypes["IdentificationCodeLocaleUniqueWhere"] | undefined,
	subscriptionBoxes?: GraphQLTypes["SubscriptionBoxLocaleUniqueWhere"] | undefined
};
	["BusinessCategoryLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["BusinessCategoryUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BusinessCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	slug?: string | undefined,
	locales?: GraphQLTypes["BusinessCategoryLocaleUniqueWhere"] | undefined,
	specificProducts?: GraphQLTypes["ProductListUniqueWhere"] | undefined,
	seasonalProductCategories?: GraphQLTypes["ProductCategoryListUniqueWhere"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["ProductCategoryListUniqueWhere"] | undefined
};
	["ProductListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ProductListItemUniqueWhere"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUniqueWhere"] | undefined
};
	["ProductListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProductCategoryListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ProductCategoryListItemUniqueWhere"] | undefined,
	locales?: GraphQLTypes["ProductCategoryListLocaleUniqueWhere"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryUniqueWhere"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryUniqueWhere"] | undefined
};
	["ProductCategoryListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProductCategoryListLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductCategoryListUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductIngredientLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductIngredientUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductIngredientUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["ProductIngredientLocaleUniqueWhere"] | undefined,
	suppliers?: GraphQLTypes["IngredientSupplierListUniqueWhere"] | undefined
};
	["IngredientSupplierListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["IngredientSupplierItemUniqueWhere"] | undefined
};
	["IngredientSupplierItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["OrderRecurrenceLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["OrderRecurrenceUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["OrderRecurrenceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["OrderRecurrenceLocaleUniqueWhere"] | undefined
};
	["BlogLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["BlogUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BlogUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["BlogLocaleUniqueWhere"] | undefined
};
	["BlogPostLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["BlogPostUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	cover?: GraphQLTypes["CoverUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["BlogPostUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["BlogPostLocaleUniqueWhere"] | undefined
};
	["CoverUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ContentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	blocks?: GraphQLTypes["ContentBlockUniqueWhere"] | undefined
};
	["ContentBlockUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	references?: GraphQLTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentReferenceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	products?: GraphQLTypes["ProductListUniqueWhere"] | undefined,
	productBanners?: GraphQLTypes["ProductBannerListUniqueWhere"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegionsUniqueWhere"] | undefined
};
	["ProductBannerListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ProductBannerItemUniqueWhere"] | undefined
};
	["ProductBannerItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["DeliveryRegionsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["DeliveryRegionsItemUniqueWhere"] | undefined
};
	["DeliveryRegionsItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined,
	textList?: GraphQLTypes["TextListUniqueWhere"] | undefined,
	gallery?: GraphQLTypes["ImageListUniqueWhere"] | undefined
};
	["ImageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["TextListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["TextItemUniqueWhere"] | undefined
};
	["TextItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ImageListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ImageListItemUniqueWhere"] | undefined
};
	["ImageListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LinkableUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	url?: string | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleUniqueWhere"] | undefined,
	page?: GraphQLTypes["PageLocaleUniqueWhere"] | undefined,
	redirect?: GraphQLTypes["RedirectUniqueWhere"] | undefined
};
	["PageLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["PageUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined,
	cover?: GraphQLTypes["CoverUniqueWhere"] | undefined
};
	["PageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["PageLocaleUniqueWhere"] | undefined
};
	["SeoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	ogImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["RedirectUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined
};
	["TranslationCatalogueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	domain?: GraphQLTypes["TranslationDomainUniqueWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationDomainUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	identifier?: string | undefined,
	catalogues?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined,
	keys?: GraphQLTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationKeyUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	domain?: GraphQLTypes["TranslationDomainUniqueWhere"] | undefined,
	identifier?: string | undefined,
	values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationValueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined,
	key?: GraphQLTypes["TranslationKeyUniqueWhere"] | undefined
};
	["ProductIngredientCategoryLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductIngredientCategoryUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductIngredientCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["ProductIngredientCategoryLocaleUniqueWhere"] | undefined
};
	["AllergenLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["AllergenUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["AllergenUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: number | undefined,
	locales?: GraphQLTypes["AllergenLocaleUniqueWhere"] | undefined
};
	["ProductPackingLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductPackingUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductPackingUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["ProductPackingLocaleUniqueWhere"] | undefined,
	preparedPackingQuantities?: GraphQLTypes["PreparedPackingQuantityUniqueWhere"] | undefined
};
	["PreparedPackingQuantityUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	date?: GraphQLTypes["Date"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeUniqueWhere"] | undefined,
	packing?: GraphQLTypes["ProductPackingUniqueWhere"] | undefined,
	packedInCardboard?: boolean | undefined
};
	["ProductRecipeUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	products?: GraphQLTypes["ProductUniqueWhere"] | undefined,
	ingredients?: GraphQLTypes["ProductIngredientItemUniqueWhere"] | undefined,
	locales?: GraphQLTypes["ProductRecipeLocaleUniqueWhere"] | undefined
};
	["ProductUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	stocks?: GraphQLTypes["ProductStockUniqueWhere"] | undefined,
	orderItems?: GraphQLTypes["OrderItemUniqueWhere"] | undefined,
	locales?: GraphQLTypes["ProductLocaleUniqueWhere"] | undefined,
	virtualProduct?: GraphQLTypes["VirtualProductUniqueWhere"] | undefined,
	meta?: GraphQLTypes["ProductMetadataUniqueWhere"] | undefined,
	page?: GraphQLTypes["PageUniqueWhere"] | undefined
};
	["ProductStockUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	product?: GraphQLTypes["ProductUniqueWhere"] | undefined,
	store?: GraphQLTypes["StoreUniqueWhere"] | undefined
};
	["StoreUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined
};
	["OrderItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProductLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	featureList?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["VirtualProductUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	product?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["ProductMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	product?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["ProductIngredientItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	quantity?: GraphQLTypes["ProductIngredientItemQuantityUniqueWhere"] | undefined
};
	["ProductIngredientItemQuantityUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProductRecipeLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductRecipeUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["DeliveryMethodLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	root?: GraphQLTypes["DeliveryMethodUniqueWhere"] | undefined
};
	["DeliveryMethodUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	locales?: GraphQLTypes["DeliveryMethodLocaleUniqueWhere"] | undefined,
	timelinePreset?: GraphQLTypes["DeliveryTimelinePresetUniqueWhere"] | undefined
};
	["DeliveryTimelinePresetUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethodUniqueWhere"] | undefined
};
	["IngredientSupplierLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["IngredientSupplierUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["IngredientSupplierUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["IngredientSupplierLocaleUniqueWhere"] | undefined
};
	["IdentificationCodeLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["IdentificationCodeUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["IdentificationCodeUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	attempts?: GraphQLTypes["IdentificationAttemptUniqueWhere"] | undefined,
	locales?: GraphQLTypes["IdentificationCodeLocaleUniqueWhere"] | undefined
};
	["IdentificationAttemptUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["SubscriptionBoxLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["SubscriptionBoxUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	texts?: GraphQLTypes["TextListUniqueWhere"] | undefined,
	secondaryTexts?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["SubscriptionBoxUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["SubscriptionBoxLocaleUniqueWhere"] | undefined
};
	["OrderRecurrenceLocaleConnection"]: {
	__typename: "OrderRecurrenceLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderRecurrenceLocaleEdge"]>
};
	["PageInfo"]: {
	__typename: "PageInfo",
	totalCount: number
};
	["OrderRecurrenceLocaleEdge"]: {
	__typename: "OrderRecurrenceLocaleEdge",
	node: GraphQLTypes["OrderRecurrenceLocale"]
};
	["BusinessCategoryConnection"]: {
	__typename: "BusinessCategoryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCategoryEdge"]>
};
	["BusinessCategoryEdge"]: {
	__typename: "BusinessCategoryEdge",
	node: GraphQLTypes["BusinessCategory"]
};
	["ProductList"]: {
	__typename: "ProductList",
	_meta?: GraphQLTypes["ProductListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductListItem"]>,
	businessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	paginateItems: GraphQLTypes["ProductListItemConnection"]
};
	["ProductListMeta"]: {
	__typename: "ProductListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductListItem"]: {
	__typename: "ProductListItem",
	_meta?: GraphQLTypes["ProductListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductList"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	defaultQuantityForOrder?: number | undefined
};
	["ProductListItemMeta"]: {
	__typename: "ProductListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ProductListOrderBy"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["OrderDirection"] | undefined,
	notDiscountable?: GraphQLTypes["OrderDirection"] | undefined,
	packing?: GraphQLTypes["ProductPackingOrderBy"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeOrderBy"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	availability?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["OrderDirection"] | undefined,
	archivedAt?: GraphQLTypes["OrderDirection"] | undefined,
	virtualProduct?: GraphQLTypes["VirtualProductOrderBy"] | undefined,
	meta?: GraphQLTypes["ProductMetadataOrderBy"] | undefined,
	page?: GraphQLTypes["PageOrderBy"] | undefined,
	hasSubscription?: GraphQLTypes["OrderDirection"] | undefined,
	pointOfSaleTileColor?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductPackingOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	volumeMl?: GraphQLTypes["OrderDirection"] | undefined,
	icon?: GraphQLTypes["ImageOrderBy"] | undefined,
	shortName?: GraphQLTypes["OrderDirection"] | undefined,
	isPackedInCardboard?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductRecipeOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	color?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	icon?: GraphQLTypes["ImageOrderBy"] | undefined,
	needsPrepping?: GraphQLTypes["OrderDirection"] | undefined,
	gutTuning?: GraphQLTypes["OrderDirection"] | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainerOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined
};
	["FreshingContainerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	volumeMl?: GraphQLTypes["OrderDirection"] | undefined,
	workspace?: GraphQLTypes["WorkspaceOrderBy"] | undefined
};
	["WorkspaceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined,
	orderDeadlineTime?: GraphQLTypes["OrderDirection"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["OrderDirection"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["OrderDirection"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["OrderDirection"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	orderUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	messengerUrl?: GraphQLTypes["OrderDirection"] | undefined,
	messengerIosUrl?: GraphQLTypes["OrderDirection"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["OrderDirection"] | undefined,
	openingHours?: GraphQLTypes["WeekHoursOrderBy"] | undefined,
	workingHours?: GraphQLTypes["WeekHoursOrderBy"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FreshingContainerOrderBy"] | undefined,
	creditsProduct?: GraphQLTypes["VirtualProductOrderBy"] | undefined,
	discountVatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	businessAddress?: GraphQLTypes["OrderDirection"] | undefined
};
	["WeekHoursOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	mon?: GraphQLTypes["OrderDirection"] | undefined,
	tue?: GraphQLTypes["OrderDirection"] | undefined,
	wed?: GraphQLTypes["OrderDirection"] | undefined,
	thu?: GraphQLTypes["OrderDirection"] | undefined,
	fri?: GraphQLTypes["OrderDirection"] | undefined,
	sat?: GraphQLTypes["OrderDirection"] | undefined,
	sun?: GraphQLTypes["OrderDirection"] | undefined
};
	["VirtualProductOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined,
	voucherCreditCents?: GraphQLTypes["OrderDirection"] | undefined,
	physicalRepresentation?: GraphQLTypes["OrderDirection"] | undefined,
	recipientEmail?: GraphQLTypes["OrderDirection"] | undefined
};
	["VatRateOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	ratePermille?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	preset?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	archived?: GraphQLTypes["OrderDirection"] | undefined,
	available?: GraphQLTypes["OrderDirection"] | undefined,
	availableQuantity?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined
};
	["PageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductListItemConnection"]: {
	__typename: "ProductListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductListItemEdge"]>
};
	["ProductListItemEdge"]: {
	__typename: "ProductListItemEdge",
	node: GraphQLTypes["ProductListItem"]
};
	["ProductCategoryList"]: {
	__typename: "ProductCategoryList",
	_meta?: GraphQLTypes["ProductCategoryListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductCategoryListItem"]>,
	locales: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	localesByLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	paginateItems: GraphQLTypes["ProductCategoryListItemConnection"],
	paginateLocales: GraphQLTypes["ProductCategoryListLocaleConnection"]
};
	["ProductCategoryListMeta"]: {
	__typename: "ProductCategoryListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["FieldMeta"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategoryListItem"]: {
	__typename: "ProductCategoryListItem",
	_meta?: GraphQLTypes["ProductCategoryListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductCategoryList"] | undefined,
	productCategory?: GraphQLTypes["ProductCategory"] | undefined,
	showRecipeImages: boolean,
	showPackingImages: boolean,
	showAllAvailableProducts: boolean
};
	["ProductCategoryListItemMeta"]: {
	__typename: "ProductCategoryListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["FieldMeta"] | undefined,
	showRecipeImages?: GraphQLTypes["FieldMeta"] | undefined,
	showPackingImages?: GraphQLTypes["FieldMeta"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategory"]: {
	__typename: "ProductCategory",
	_meta?: GraphQLTypes["ProductCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	products: Array<GraphQLTypes["Product"]>,
	tags: Array<GraphQLTypes["Tag"]>,
	parent?: GraphQLTypes["ProductCategory"] | undefined,
	children: Array<GraphQLTypes["ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	order: number,
	childrenByCode?: GraphQLTypes["ProductCategory"] | undefined,
	childrenByChildren?: GraphQLTypes["ProductCategory"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateTags: GraphQLTypes["TagConnection"],
	paginateChildren: GraphQLTypes["ProductCategoryConnection"]
};
	["ProductCategoryMeta"]: {
	__typename: "ProductCategoryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	parent?: GraphQLTypes["FieldMeta"] | undefined,
	children?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	color?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["TagOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	parent?: GraphQLTypes["ProductCategoryOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	color?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductCategoryChildrenByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["ProductCategoryChildrenByChildrenUniqueWhere"]: {
		children?: GraphQLTypes["ProductCategoryUniqueWhere"] | undefined
};
	["ProductCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	children?: GraphQLTypes["ProductCategoryUniqueWhere"] | undefined
};
	["ProductConnection"]: {
	__typename: "ProductConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductEdge"]>
};
	["ProductEdge"]: {
	__typename: "ProductEdge",
	node: GraphQLTypes["Product"]
};
	["TagConnection"]: {
	__typename: "TagConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TagEdge"]>
};
	["TagEdge"]: {
	__typename: "TagEdge",
	node: GraphQLTypes["Tag"]
};
	["ProductCategoryConnection"]: {
	__typename: "ProductCategoryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryEdge"]>
};
	["ProductCategoryEdge"]: {
	__typename: "ProductCategoryEdge",
	node: GraphQLTypes["ProductCategory"]
};
	["ProductCategoryListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ProductCategoryListOrderBy"] | undefined,
	productCategory?: GraphQLTypes["ProductCategoryOrderBy"] | undefined,
	showRecipeImages?: GraphQLTypes["OrderDirection"] | undefined,
	showPackingImages?: GraphQLTypes["OrderDirection"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductCategoryListLocale"]: {
	__typename: "ProductCategoryListLocale",
	_meta?: GraphQLTypes["ProductCategoryListLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductCategoryList"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductCategoryListLocaleMeta"]: {
	__typename: "ProductCategoryListLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategoryListLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductCategoryListOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["ProductCategoryListLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductCategoryListItemConnection"]: {
	__typename: "ProductCategoryListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListItemEdge"]>
};
	["ProductCategoryListItemEdge"]: {
	__typename: "ProductCategoryListItemEdge",
	node: GraphQLTypes["ProductCategoryListItem"]
};
	["ProductCategoryListLocaleConnection"]: {
	__typename: "ProductCategoryListLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListLocaleEdge"]>
};
	["ProductCategoryListLocaleEdge"]: {
	__typename: "ProductCategoryListLocaleEdge",
	node: GraphQLTypes["ProductCategoryListLocale"]
};
	["BusinessCategoryLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BusinessCategoryLocaleConnection"]: {
	__typename: "BusinessCategoryLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCategoryLocaleEdge"]>
};
	["BusinessCategoryLocaleEdge"]: {
	__typename: "BusinessCategoryLocaleEdge",
	node: GraphQLTypes["BusinessCategoryLocale"]
};
	["OrderRecurrenceConnection"]: {
	__typename: "OrderRecurrenceConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderRecurrenceEdge"]>
};
	["OrderRecurrenceEdge"]: {
	__typename: "OrderRecurrenceEdge",
	node: GraphQLTypes["OrderRecurrence"]
};
	["ProductIngredientLocale"]: {
	__typename: "ProductIngredientLocale",
	_meta?: GraphQLTypes["ProductIngredientLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductIngredient"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductIngredientLocaleMeta"]: {
	__typename: "ProductIngredientLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredient"]: {
	__typename: "ProductIngredient",
	_meta?: GraphQLTypes["ProductIngredientMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalNote?: string | undefined,
	locales: Array<GraphQLTypes["ProductIngredientLocale"]>,
	internalName: string,
	categories: Array<GraphQLTypes["ProductIngredientCategory"]>,
	allergens: Array<GraphQLTypes["Allergen"]>,
	needsPrepping: boolean,
	allowCustomerRating?: boolean | undefined,
	preferredQuantityUnit?: GraphQLTypes["ProductIngredientItemQuantityUnit"] | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	suppliers?: GraphQLTypes["IngredientSupplierList"] | undefined,
	incrementQuantityNumber: number,
	localesByLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	paginateLocales: GraphQLTypes["ProductIngredientLocaleConnection"],
	paginateCategories: GraphQLTypes["ProductIngredientCategoryConnection"],
	paginateAllergens: GraphQLTypes["AllergenConnection"]
};
	["ProductIngredientMeta"]: {
	__typename: "ProductIngredientMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	allergens?: GraphQLTypes["FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["FieldMeta"] | undefined,
	allowCustomerRating?: GraphQLTypes["FieldMeta"] | undefined,
	preferredQuantityUnit?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	suppliers?: GraphQLTypes["FieldMeta"] | undefined,
	incrementQuantityNumber?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductIngredientOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["ProductIngredientOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	needsPrepping?: GraphQLTypes["OrderDirection"] | undefined,
	allowCustomerRating?: GraphQLTypes["OrderDirection"] | undefined,
	preferredQuantityUnit?: GraphQLTypes["ProductIngredientItemQuantityUnitOrderBy"] | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainerOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	suppliers?: GraphQLTypes["IngredientSupplierListOrderBy"] | undefined,
	incrementQuantityNumber?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductIngredientItemQuantityUnitOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined
};
	["IngredientSupplierListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductIngredientCategory"]: {
	__typename: "ProductIngredientCategory",
	_meta?: GraphQLTypes["ProductIngredientCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	internalNote?: string | undefined,
	ingredients: Array<GraphQLTypes["ProductIngredient"]>,
	locales: Array<GraphQLTypes["ProductIngredientCategoryLocale"]>,
	order: number,
	localesByLocale?: GraphQLTypes["ProductIngredientCategoryLocale"] | undefined,
	paginateIngredients: GraphQLTypes["ProductIngredientConnection"],
	paginateLocales: GraphQLTypes["ProductIngredientCategoryLocaleConnection"]
};
	["ProductIngredientCategoryMeta"]: {
	__typename: "ProductIngredientCategoryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientCategoryLocale"]: {
	__typename: "ProductIngredientCategoryLocale",
	_meta?: GraphQLTypes["ProductIngredientCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductIngredientCategory"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductIngredientCategoryLocaleMeta"]: {
	__typename: "ProductIngredientCategoryLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientCategoryLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductIngredientCategoryOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["ProductIngredientCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductIngredientCategoryLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductIngredientConnection"]: {
	__typename: "ProductIngredientConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientEdge"]>
};
	["ProductIngredientEdge"]: {
	__typename: "ProductIngredientEdge",
	node: GraphQLTypes["ProductIngredient"]
};
	["ProductIngredientCategoryLocaleConnection"]: {
	__typename: "ProductIngredientCategoryLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientCategoryLocaleEdge"]>
};
	["ProductIngredientCategoryLocaleEdge"]: {
	__typename: "ProductIngredientCategoryLocaleEdge",
	node: GraphQLTypes["ProductIngredientCategoryLocale"]
};
	["Allergen"]: {
	__typename: "Allergen",
	_meta?: GraphQLTypes["AllergenMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["AllergenLocale"]>,
	ingredients: Array<GraphQLTypes["ProductIngredient"]>,
	code: number,
	localesByLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	paginateLocales: GraphQLTypes["AllergenLocaleConnection"],
	paginateIngredients: GraphQLTypes["ProductIngredientConnection"]
};
	["AllergenMeta"]: {
	__typename: "AllergenMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined
};
	["AllergenLocale"]: {
	__typename: "AllergenLocale",
	_meta?: GraphQLTypes["AllergenLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	root?: GraphQLTypes["Allergen"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["AllergenLocaleMeta"]: {
	__typename: "AllergenLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["AllergenLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["AllergenOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["AllergenOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined
};
	["AllergenLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["AllergenLocaleConnection"]: {
	__typename: "AllergenLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AllergenLocaleEdge"]>
};
	["AllergenLocaleEdge"]: {
	__typename: "AllergenLocaleEdge",
	node: GraphQLTypes["AllergenLocale"]
};
	["ProductIngredientItemQuantityUnit"]: {
	__typename: "ProductIngredientItemQuantityUnit",
	_meta?: GraphQLTypes["ProductIngredientItemQuantityUnitMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string
};
	["ProductIngredientItemQuantityUnitMeta"]: {
	__typename: "ProductIngredientItemQuantityUnitMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined
};
	["FreshingContainer"]: {
	__typename: "FreshingContainer",
	_meta?: GraphQLTypes["FreshingContainerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	volumeMl: number,
	workspace?: GraphQLTypes["Workspace"] | undefined
};
	["FreshingContainerMeta"]: {
	__typename: "FreshingContainerMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	workspace?: GraphQLTypes["FieldMeta"] | undefined
};
	["Workspace"]: {
	__typename: "Workspace",
	_meta?: GraphQLTypes["WorkspaceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	orderDeadlineTime?: string | undefined,
	ordersLoadingDurationHours: number,
	freshingContainerVolumeMl: number,
	freshingPrepareOrderByOffsetMinutes: number,
	checkoutUrlTemplate?: string | undefined,
	orderUrlTemplate?: string | undefined,
	paymentUrlTemplate?: string | undefined,
	customerProfileUrlTemplate?: string | undefined,
	userProfileUrlTemplate?: string | undefined,
	messengerUrl?: string | undefined,
	messengerIosUrl?: string | undefined,
	messengerAndroidUrl?: string | undefined,
	freshingContainers: Array<GraphQLTypes["FreshingContainer"]>,
	openingHours?: GraphQLTypes["WeekHours"] | undefined,
	workingHours?: GraphQLTypes["WeekHours"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	creditsProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	discountVatRate?: GraphQLTypes["VatRate"] | undefined,
	name: string,
	businessAddress?: string | undefined,
	paginateFreshingContainers: GraphQLTypes["FreshingContainerConnection"]
};
	["WorkspaceMeta"]: {
	__typename: "WorkspaceMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	orderDeadlineTime?: GraphQLTypes["FieldMeta"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["FieldMeta"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	orderUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	messengerUrl?: GraphQLTypes["FieldMeta"] | undefined,
	messengerIosUrl?: GraphQLTypes["FieldMeta"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainers?: GraphQLTypes["FieldMeta"] | undefined,
	openingHours?: GraphQLTypes["FieldMeta"] | undefined,
	workingHours?: GraphQLTypes["FieldMeta"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	creditsProduct?: GraphQLTypes["FieldMeta"] | undefined,
	discountVatRate?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	businessAddress?: GraphQLTypes["FieldMeta"] | undefined
};
	["WeekHours"]: {
	__typename: "WeekHours",
	_meta?: GraphQLTypes["WeekHoursMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	mon?: string | undefined,
	tue?: string | undefined,
	wed?: string | undefined,
	thu?: string | undefined,
	fri?: string | undefined,
	sat?: string | undefined,
	sun?: string | undefined
};
	["WeekHoursMeta"]: {
	__typename: "WeekHoursMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	mon?: GraphQLTypes["FieldMeta"] | undefined,
	tue?: GraphQLTypes["FieldMeta"] | undefined,
	wed?: GraphQLTypes["FieldMeta"] | undefined,
	thu?: GraphQLTypes["FieldMeta"] | undefined,
	fri?: GraphQLTypes["FieldMeta"] | undefined,
	sat?: GraphQLTypes["FieldMeta"] | undefined,
	sun?: GraphQLTypes["FieldMeta"] | undefined
};
	["VirtualProduct"]: {
	__typename: "VirtualProduct",
	_meta?: GraphQLTypes["VirtualProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["VirtualProductType"],
	product?: GraphQLTypes["Product"] | undefined,
	voucherCreditCents?: number | undefined,
	physicalRepresentation: GraphQLTypes["VirtualProductPhysicalRepresentationType"],
	recipientEmail?: boolean | undefined
};
	["VirtualProductMeta"]: {
	__typename: "VirtualProductMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	voucherCreditCents?: GraphQLTypes["FieldMeta"] | undefined,
	physicalRepresentation?: GraphQLTypes["FieldMeta"] | undefined,
	recipientEmail?: GraphQLTypes["FieldMeta"] | undefined
};
	["VatRate"]: {
	__typename: "VatRate",
	_meta?: GraphQLTypes["VatRateMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	ratePermille: number,
	description?: string | undefined,
	internalNote?: string | undefined,
	preset?: GraphQLTypes["VatRatePreset"] | undefined
};
	["VatRateMeta"]: {
	__typename: "VatRateMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	ratePermille?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	preset?: GraphQLTypes["FieldMeta"] | undefined
};
	["FreshingContainerConnection"]: {
	__typename: "FreshingContainerConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FreshingContainerEdge"]>
};
	["FreshingContainerEdge"]: {
	__typename: "FreshingContainerEdge",
	node: GraphQLTypes["FreshingContainer"]
};
	["IngredientSupplierList"]: {
	__typename: "IngredientSupplierList",
	_meta?: GraphQLTypes["IngredientSupplierListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["IngredientSupplierItem"]>,
	paginateItems: GraphQLTypes["IngredientSupplierItemConnection"]
};
	["IngredientSupplierListMeta"]: {
	__typename: "IngredientSupplierListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["IngredientSupplierItem"]: {
	__typename: "IngredientSupplierItem",
	_meta?: GraphQLTypes["IngredientSupplierItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["IngredientSupplierList"] | undefined,
	supplier?: GraphQLTypes["IngredientSupplier"] | undefined
};
	["IngredientSupplierItemMeta"]: {
	__typename: "IngredientSupplierItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	supplier?: GraphQLTypes["FieldMeta"] | undefined
};
	["IngredientSupplier"]: {
	__typename: "IngredientSupplier",
	_meta?: GraphQLTypes["IngredientSupplierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	internalNote?: string | undefined,
	locales: Array<GraphQLTypes["IngredientSupplierLocale"]>,
	localesByLocale?: GraphQLTypes["IngredientSupplierLocale"] | undefined,
	paginateLocales: GraphQLTypes["IngredientSupplierLocaleConnection"]
};
	["IngredientSupplierMeta"]: {
	__typename: "IngredientSupplierMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["IngredientSupplierLocale"]: {
	__typename: "IngredientSupplierLocale",
	_meta?: GraphQLTypes["IngredientSupplierLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["IngredientSupplier"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["IngredientSupplierLocaleMeta"]: {
	__typename: "IngredientSupplierLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["IngredientSupplierLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["IngredientSupplierOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["IngredientSupplierOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined
};
	["IngredientSupplierLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["IngredientSupplierLocaleConnection"]: {
	__typename: "IngredientSupplierLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IngredientSupplierLocaleEdge"]>
};
	["IngredientSupplierLocaleEdge"]: {
	__typename: "IngredientSupplierLocaleEdge",
	node: GraphQLTypes["IngredientSupplierLocale"]
};
	["IngredientSupplierItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["IngredientSupplierListOrderBy"] | undefined,
	supplier?: GraphQLTypes["IngredientSupplierOrderBy"] | undefined
};
	["IngredientSupplierItemConnection"]: {
	__typename: "IngredientSupplierItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IngredientSupplierItemEdge"]>
};
	["IngredientSupplierItemEdge"]: {
	__typename: "IngredientSupplierItemEdge",
	node: GraphQLTypes["IngredientSupplierItem"]
};
	["ProductIngredientLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductIngredientLocaleConnection"]: {
	__typename: "ProductIngredientLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientLocaleEdge"]>
};
	["ProductIngredientLocaleEdge"]: {
	__typename: "ProductIngredientLocaleEdge",
	node: GraphQLTypes["ProductIngredientLocale"]
};
	["ProductIngredientCategoryConnection"]: {
	__typename: "ProductIngredientCategoryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientCategoryEdge"]>
};
	["ProductIngredientCategoryEdge"]: {
	__typename: "ProductIngredientCategoryEdge",
	node: GraphQLTypes["ProductIngredientCategory"]
};
	["AllergenConnection"]: {
	__typename: "AllergenConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AllergenEdge"]>
};
	["AllergenEdge"]: {
	__typename: "AllergenEdge",
	node: GraphQLTypes["Allergen"]
};
	["BlogLocale"]: {
	__typename: "BlogLocale",
	_meta?: GraphQLTypes["BlogLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	pageName?: string | undefined,
	title?: string | undefined,
	lead?: string | undefined,
	root?: GraphQLTypes["Blog"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["BlogLocaleMeta"]: {
	__typename: "BlogLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	pageName?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	lead?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["Blog"]: {
	__typename: "Blog",
	_meta?: GraphQLTypes["BlogMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["BlogLocale"]>,
	localesByLocale?: GraphQLTypes["BlogLocale"] | undefined,
	paginateLocales: GraphQLTypes["BlogLocaleConnection"]
};
	["BlogMeta"]: {
	__typename: "BlogMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	pageName?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	lead?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["BlogOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["BlogOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined
};
	["BlogLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BlogLocaleConnection"]: {
	__typename: "BlogLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogLocaleEdge"]>
};
	["BlogLocaleEdge"]: {
	__typename: "BlogLocaleEdge",
	node: GraphQLTypes["BlogLocale"]
};
	["BlogPostLocale"]: {
	__typename: "BlogPostLocale",
	_meta?: GraphQLTypes["BlogPostLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	availableForWeb?: boolean | undefined,
	availableForMobile?: boolean | undefined,
	root?: GraphQLTypes["BlogPost"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	cover?: GraphQLTypes["Cover"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	products: Array<GraphQLTypes["Product"]>,
	paginateProducts: GraphQLTypes["ProductConnection"]
};
	["BlogPostLocaleMeta"]: {
	__typename: "BlogPostLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	availableForWeb?: GraphQLTypes["FieldMeta"] | undefined,
	availableForMobile?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	cover?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPost"]: {
	__typename: "BlogPost",
	_meta?: GraphQLTypes["BlogPostMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	locales: Array<GraphQLTypes["BlogPostLocale"]>,
	localesByLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByCover?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByContent?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByLink?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesBySeo?: GraphQLTypes["BlogPostLocale"] | undefined,
	paginateLocales: GraphQLTypes["BlogPostLocaleConnection"]
};
	["BlogPostMeta"]: {
	__typename: "BlogPostMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	availableForWeb?: GraphQLTypes["OrderDirection"] | undefined,
	availableForMobile?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["BlogPostOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	cover?: GraphQLTypes["CoverOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined
};
	["BlogPostOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["CoverOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	primaryTitle?: GraphQLTypes["OrderDirection"] | undefined,
	secondaryTitle?: GraphQLTypes["OrderDirection"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined,
	buttonColors?: GraphQLTypes["ColorPalleteOrderBy"] | undefined
};
	["MediumOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	colorTheme?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	video?: GraphQLTypes["VideoOrderBy"] | undefined
};
	["VideoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	src?: GraphQLTypes["OrderDirection"] | undefined,
	width?: GraphQLTypes["OrderDirection"] | undefined,
	height?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	duration?: GraphQLTypes["OrderDirection"] | undefined,
	poster?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ColorPalleteOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	background?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined
};
	["ContentOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["LinkableOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	url?: GraphQLTypes["OrderDirection"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleOrderBy"] | undefined,
	page?: GraphQLTypes["PageLocaleOrderBy"] | undefined,
	redirect?: GraphQLTypes["RedirectOrderBy"] | undefined
};
	["PageLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	root?: GraphQLTypes["PageOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined,
	cover?: GraphQLTypes["CoverOrderBy"] | undefined,
	theme?: GraphQLTypes["OrderDirection"] | undefined
};
	["SeoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	ogTitle?: GraphQLTypes["OrderDirection"] | undefined,
	ogDescription?: GraphQLTypes["OrderDirection"] | undefined,
	ogImage?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["RedirectOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	target?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["LinkOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	externalLink?: GraphQLTypes["OrderDirection"] | undefined,
	internalLink?: GraphQLTypes["LinkableOrderBy"] | undefined
};
	["BlogPostLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BlogPostLocalesByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["CoverUniqueWhere"] | undefined
};
	["BlogPostLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["BlogPostLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["BlogPostLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["BlogPostLocaleConnection"]: {
	__typename: "BlogPostLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostLocaleEdge"]>
};
	["BlogPostLocaleEdge"]: {
	__typename: "BlogPostLocaleEdge",
	node: GraphQLTypes["BlogPostLocale"]
};
	["Cover"]: {
	__typename: "Cover",
	_meta?: GraphQLTypes["CoverMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	primaryTitle?: string | undefined,
	secondaryTitle?: string | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	buttonColors?: GraphQLTypes["ColorPallete"] | undefined
};
	["CoverMeta"]: {
	__typename: "CoverMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	primaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	buttonColors?: GraphQLTypes["FieldMeta"] | undefined
};
	["Medium"]: {
	__typename: "Medium",
	_meta?: GraphQLTypes["MediumMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["MediumType"] | undefined,
	colorTheme?: GraphQLTypes["ColorTheme"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	video?: GraphQLTypes["Video"] | undefined
};
	["MediumMeta"]: {
	__typename: "MediumMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	colorTheme?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	video?: GraphQLTypes["FieldMeta"] | undefined
};
	["Video"]: {
	__typename: "Video",
	_meta?: GraphQLTypes["VideoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	src?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	duration?: number | undefined,
	poster?: GraphQLTypes["Image"] | undefined
};
	["VideoMeta"]: {
	__typename: "VideoMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	src?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	poster?: GraphQLTypes["FieldMeta"] | undefined
};
	["ColorPallete"]: {
	__typename: "ColorPallete",
	_meta?: GraphQLTypes["ColorPalleteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	background?: string | undefined,
	text?: string | undefined
};
	["ColorPalleteMeta"]: {
	__typename: "ColorPalleteMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	background?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined
};
	["Content"]: {
	__typename: "Content",
	_meta?: GraphQLTypes["ContentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	blocks: Array<GraphQLTypes["ContentBlock"]>,
	blocksByReferences?: GraphQLTypes["ContentBlock"] | undefined,
	paginateBlocks: GraphQLTypes["ContentBlockConnection"]
};
	["ContentMeta"]: {
	__typename: "ContentMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	blocks?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentBlock"]: {
	__typename: "ContentBlock",
	_meta?: GraphQLTypes["ContentBlockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	json: string,
	content?: GraphQLTypes["Content"] | undefined,
	references: Array<GraphQLTypes["ContentReference"]>,
	referencesByProducts?: GraphQLTypes["ContentReference"] | undefined,
	referencesByProductBanners?: GraphQLTypes["ContentReference"] | undefined,
	referencesByDeliveryRegions?: GraphQLTypes["ContentReference"] | undefined,
	paginateReferences: GraphQLTypes["ContentReferenceConnection"]
};
	["ContentBlockMeta"]: {
	__typename: "ContentBlockMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	json?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	references?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReference"]: {
	__typename: "ContentReference",
	_meta?: GraphQLTypes["ContentReferenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["ContentReferenceType"],
	primaryText?: string | undefined,
	secondaryText?: string | undefined,
	jsonContent?: string | undefined,
	block?: GraphQLTypes["ContentBlock"] | undefined,
	colorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostList"] | undefined,
	links?: GraphQLTypes["LinkList"] | undefined,
	products?: GraphQLTypes["ProductList"] | undefined,
	contentSize?: GraphQLTypes["ContentSize"] | undefined,
	misc?: string | undefined,
	hero?: GraphQLTypes["Hero"] | undefined,
	gallery?: GraphQLTypes["Gallery"] | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	productGrid?: GraphQLTypes["ProductGrid"] | undefined,
	productBanners?: GraphQLTypes["ProductBannerList"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	textList?: GraphQLTypes["TextList"] | undefined,
	gridTiles?: GraphQLTypes["GridTiles"] | undefined
};
	["ContentReferenceMeta"]: {
	__typename: "ContentReferenceMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	primaryText?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryText?: GraphQLTypes["FieldMeta"] | undefined,
	jsonContent?: GraphQLTypes["FieldMeta"] | undefined,
	block?: GraphQLTypes["FieldMeta"] | undefined,
	colorPallete?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["FieldMeta"] | undefined,
	links?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	contentSize?: GraphQLTypes["FieldMeta"] | undefined,
	misc?: GraphQLTypes["FieldMeta"] | undefined,
	hero?: GraphQLTypes["FieldMeta"] | undefined,
	gallery?: GraphQLTypes["FieldMeta"] | undefined,
	socialsAndApps?: GraphQLTypes["FieldMeta"] | undefined,
	productGrid?: GraphQLTypes["FieldMeta"] | undefined,
	productBanners?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryRegions?: GraphQLTypes["FieldMeta"] | undefined,
	textList?: GraphQLTypes["FieldMeta"] | undefined,
	gridTiles?: GraphQLTypes["FieldMeta"] | undefined
};
	["Link"]: {
	__typename: "Link",
	_meta?: GraphQLTypes["LinkMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["LinkType"],
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: GraphQLTypes["Linkable"] | undefined
};
	["LinkMeta"]: {
	__typename: "LinkMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	externalLink?: GraphQLTypes["FieldMeta"] | undefined,
	internalLink?: GraphQLTypes["FieldMeta"] | undefined
};
	["Linkable"]: {
	__typename: "Linkable",
	_meta?: GraphQLTypes["LinkableMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	blogPost?: GraphQLTypes["BlogPostLocale"] | undefined,
	page?: GraphQLTypes["PageLocale"] | undefined,
	redirect?: GraphQLTypes["Redirect"] | undefined
};
	["LinkableMeta"]: {
	__typename: "LinkableMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["FieldMeta"] | undefined,
	page?: GraphQLTypes["FieldMeta"] | undefined,
	redirect?: GraphQLTypes["FieldMeta"] | undefined
};
	["PageLocale"]: {
	__typename: "PageLocale",
	_meta?: GraphQLTypes["PageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	root?: GraphQLTypes["Page"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	cover?: GraphQLTypes["Cover"] | undefined,
	theme?: GraphQLTypes["PageTheme"] | undefined
};
	["PageLocaleMeta"]: {
	__typename: "PageLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	cover?: GraphQLTypes["FieldMeta"] | undefined,
	theme?: GraphQLTypes["FieldMeta"] | undefined
};
	["Page"]: {
	__typename: "Page",
	_meta?: GraphQLTypes["PageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["PageLocale"]>,
	localesByLocale?: GraphQLTypes["PageLocale"] | undefined,
	localesByLink?: GraphQLTypes["PageLocale"] | undefined,
	localesBySeo?: GraphQLTypes["PageLocale"] | undefined,
	localesByContent?: GraphQLTypes["PageLocale"] | undefined,
	localesByCover?: GraphQLTypes["PageLocale"] | undefined,
	paginateLocales: GraphQLTypes["PageLocaleConnection"]
};
	["PageMeta"]: {
	__typename: "PageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["PageLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["PageLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["PageLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["PageLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["PageLocalesByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["CoverUniqueWhere"] | undefined
};
	["PageLocaleConnection"]: {
	__typename: "PageLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PageLocaleEdge"]>
};
	["PageLocaleEdge"]: {
	__typename: "PageLocaleEdge",
	node: GraphQLTypes["PageLocale"]
};
	["Seo"]: {
	__typename: "Seo",
	_meta?: GraphQLTypes["SeoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	ogImage?: GraphQLTypes["Image"] | undefined
};
	["SeoMeta"]: {
	__typename: "SeoMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	ogTitle?: GraphQLTypes["FieldMeta"] | undefined,
	ogDescription?: GraphQLTypes["FieldMeta"] | undefined,
	ogImage?: GraphQLTypes["FieldMeta"] | undefined
};
	["Redirect"]: {
	__typename: "Redirect",
	_meta?: GraphQLTypes["RedirectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	link?: GraphQLTypes["Linkable"] | undefined,
	target?: GraphQLTypes["Link"] | undefined
};
	["RedirectMeta"]: {
	__typename: "RedirectMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	target?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostList"]: {
	__typename: "BlogPostList",
	_meta?: GraphQLTypes["BlogPostListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateItems: GraphQLTypes["BlogPostListItemConnection"]
};
	["BlogPostListMeta"]: {
	__typename: "BlogPostListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostListItem"]: {
	__typename: "BlogPostListItem",
	_meta?: GraphQLTypes["BlogPostListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["BlogPostList"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocale"] | undefined
};
	["BlogPostListItemMeta"]: {
	__typename: "BlogPostListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	caption?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	caption?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["BlogPostListOrderBy"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleOrderBy"] | undefined
};
	["BlogPostListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["BlogPostListItemConnection"]: {
	__typename: "BlogPostListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostListItemEdge"]>
};
	["BlogPostListItemEdge"]: {
	__typename: "BlogPostListItemEdge",
	node: GraphQLTypes["BlogPostListItem"]
};
	["LinkList"]: {
	__typename: "LinkList",
	_meta?: GraphQLTypes["LinkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["LinkListItem"]>,
	paginateItems: GraphQLTypes["LinkListItemConnection"]
};
	["LinkListMeta"]: {
	__typename: "LinkListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkListItem"]: {
	__typename: "LinkListItem",
	_meta?: GraphQLTypes["LinkListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	isPrimary?: boolean | undefined,
	list?: GraphQLTypes["LinkList"] | undefined,
	link?: GraphQLTypes["Link"] | undefined
};
	["LinkListItemMeta"]: {
	__typename: "LinkListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	isPrimary?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	isPrimary?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["LinkListOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["LinkListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["LinkListItemConnection"]: {
	__typename: "LinkListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkListItemEdge"]>
};
	["LinkListItemEdge"]: {
	__typename: "LinkListItemEdge",
	node: GraphQLTypes["LinkListItem"]
};
	["Hero"]: {
	__typename: "Hero",
	_meta?: GraphQLTypes["HeroMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	showLogo?: boolean | undefined,
	middleContent?: string | undefined,
	medium?: GraphQLTypes["Medium"] | undefined
};
	["HeroMeta"]: {
	__typename: "HeroMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	showLogo?: GraphQLTypes["FieldMeta"] | undefined,
	middleContent?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined
};
	["Gallery"]: {
	__typename: "Gallery",
	_meta?: GraphQLTypes["GalleryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["GalleryItem"]>,
	paginateItems: GraphQLTypes["GalleryItemConnection"]
};
	["GalleryMeta"]: {
	__typename: "GalleryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["GalleryItem"]: {
	__typename: "GalleryItem",
	_meta?: GraphQLTypes["GalleryItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["Gallery"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined
};
	["GalleryItemMeta"]: {
	__typename: "GalleryItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	caption?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined
};
	["GalleryItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	caption?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["GalleryOrderBy"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined
};
	["GalleryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["GalleryItemConnection"]: {
	__typename: "GalleryItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GalleryItemEdge"]>
};
	["GalleryItemEdge"]: {
	__typename: "GalleryItemEdge",
	node: GraphQLTypes["GalleryItem"]
};
	["SocialsAndApps"]: {
	__typename: "SocialsAndApps",
	_meta?: GraphQLTypes["SocialsAndAppsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	instagram?: boolean | undefined,
	appStore?: boolean | undefined,
	googlePlay?: boolean | undefined
};
	["SocialsAndAppsMeta"]: {
	__typename: "SocialsAndAppsMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	instagram?: GraphQLTypes["FieldMeta"] | undefined,
	appStore?: GraphQLTypes["FieldMeta"] | undefined,
	googlePlay?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGrid"]: {
	__typename: "ProductGrid",
	_meta?: GraphQLTypes["ProductGridMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isCarousel?: boolean | undefined,
	categories: Array<GraphQLTypes["ProductGridCategory"]>,
	type?: GraphQLTypes["ProductGridType"] | undefined,
	categoriesByItems?: GraphQLTypes["ProductGridCategory"] | undefined,
	categoriesByImages?: GraphQLTypes["ProductGridCategory"] | undefined,
	paginateCategories: GraphQLTypes["ProductGridCategoryConnection"]
};
	["ProductGridMeta"]: {
	__typename: "ProductGridMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	isCarousel?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridCategory"]: {
	__typename: "ProductGridCategory",
	_meta?: GraphQLTypes["ProductGridCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	root?: GraphQLTypes["ProductGrid"] | undefined,
	items: Array<GraphQLTypes["ProductGridItem"]>,
	images?: GraphQLTypes["ImageList"] | undefined,
	paginateItems: GraphQLTypes["ProductGridItemConnection"]
};
	["ProductGridCategoryMeta"]: {
	__typename: "ProductGridCategoryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	images?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridItem"]: {
	__typename: "ProductGridItem",
	_meta?: GraphQLTypes["ProductGridItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	lead?: string | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	category?: GraphQLTypes["ProductGridCategory"] | undefined,
	description?: string | undefined
};
	["ProductGridItemMeta"]: {
	__typename: "ProductGridItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	lead?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	category?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	lead?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined,
	category?: GraphQLTypes["ProductGridCategoryOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductGridCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductGridOrderBy"] | undefined,
	images?: GraphQLTypes["ImageListOrderBy"] | undefined
};
	["ProductGridOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	isCarousel?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined
};
	["ImageListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ImageList"]: {
	__typename: "ImageList",
	_meta?: GraphQLTypes["ImageListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ImageListItem"]>,
	itemsByImage?: GraphQLTypes["ImageListItem"] | undefined,
	paginateItems: GraphQLTypes["ImageListItemConnection"]
};
	["ImageListMeta"]: {
	__typename: "ImageListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItem"]: {
	__typename: "ImageListItem",
	_meta?: GraphQLTypes["ImageListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ImageList"] | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["ImageListItemMeta"]: {
	__typename: "ImageListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ImageListOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ImageListItemsByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["ImageListItemConnection"]: {
	__typename: "ImageListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListItemEdge"]>
};
	["ImageListItemEdge"]: {
	__typename: "ImageListItemEdge",
	node: GraphQLTypes["ImageListItem"]
};
	["ProductGridItemConnection"]: {
	__typename: "ProductGridItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridItemEdge"]>
};
	["ProductGridItemEdge"]: {
	__typename: "ProductGridItemEdge",
	node: GraphQLTypes["ProductGridItem"]
};
	["ProductGridCategoriesByItemsUniqueWhere"]: {
		items?: GraphQLTypes["ProductGridItemUniqueWhere"] | undefined
};
	["ProductGridItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProductGridCategoriesByImagesUniqueWhere"]: {
		images?: GraphQLTypes["ImageListUniqueWhere"] | undefined
};
	["ProductGridCategoryConnection"]: {
	__typename: "ProductGridCategoryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridCategoryEdge"]>
};
	["ProductGridCategoryEdge"]: {
	__typename: "ProductGridCategoryEdge",
	node: GraphQLTypes["ProductGridCategory"]
};
	["ProductBannerList"]: {
	__typename: "ProductBannerList",
	_meta?: GraphQLTypes["ProductBannerListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateItems: GraphQLTypes["ProductBannerItemConnection"]
};
	["ProductBannerListMeta"]: {
	__typename: "ProductBannerListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBannerItem"]: {
	__typename: "ProductBannerItem",
	_meta?: GraphQLTypes["ProductBannerItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductBannerList"] | undefined,
	banner?: GraphQLTypes["ProductBanner"] | undefined,
	size?: GraphQLTypes["ProductBannerItemSize"] | undefined
};
	["ProductBannerItemMeta"]: {
	__typename: "ProductBannerItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	banner?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBanner"]: {
	__typename: "ProductBanner",
	_meta?: GraphQLTypes["ProductBannerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	beforeTitle?: string | undefined,
	title?: string | undefined,
	label?: string | undefined,
	button?: GraphQLTypes["Link"] | undefined,
	backgroundImage?: GraphQLTypes["Image"] | undefined,
	productImage?: GraphQLTypes["Image"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductBannerMeta"]: {
	__typename: "ProductBannerMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	beforeTitle?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	label?: GraphQLTypes["FieldMeta"] | undefined,
	button?: GraphQLTypes["FieldMeta"] | undefined,
	backgroundImage?: GraphQLTypes["FieldMeta"] | undefined,
	productImage?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBannerItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ProductBannerListOrderBy"] | undefined,
	banner?: GraphQLTypes["ProductBannerOrderBy"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductBannerListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductBannerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	beforeTitle?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	label?: GraphQLTypes["OrderDirection"] | undefined,
	button?: GraphQLTypes["LinkOrderBy"] | undefined,
	backgroundImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	productImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined
};
	["ProductBannerItemConnection"]: {
	__typename: "ProductBannerItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerItemEdge"]>
};
	["ProductBannerItemEdge"]: {
	__typename: "ProductBannerItemEdge",
	node: GraphQLTypes["ProductBannerItem"]
};
	["DeliveryRegions"]: {
	__typename: "DeliveryRegions",
	_meta?: GraphQLTypes["DeliveryRegionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	itemsByImage?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	itemsByTextList?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	itemsByGallery?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	paginateItems: GraphQLTypes["DeliveryRegionsItemConnection"]
};
	["DeliveryRegionsMeta"]: {
	__typename: "DeliveryRegionsMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryRegionsItem"]: {
	__typename: "DeliveryRegionsItem",
	_meta?: GraphQLTypes["DeliveryRegionsItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	offerLabel?: string | undefined,
	list?: GraphQLTypes["DeliveryRegions"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	textList?: GraphQLTypes["TextList"] | undefined,
	gallery?: GraphQLTypes["ImageList"] | undefined
};
	["DeliveryRegionsItemMeta"]: {
	__typename: "DeliveryRegionsItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	offerLabel?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	textList?: GraphQLTypes["FieldMeta"] | undefined,
	gallery?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextList"]: {
	__typename: "TextList",
	_meta?: GraphQLTypes["TextListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["TextItem"]>,
	paginateItems: GraphQLTypes["TextItemConnection"]
};
	["TextListMeta"]: {
	__typename: "TextListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextItem"]: {
	__typename: "TextItem",
	_meta?: GraphQLTypes["TextItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	text?: string | undefined,
	list?: GraphQLTypes["TextList"] | undefined,
	title?: string | undefined
};
	["TextItemMeta"]: {
	__typename: "TextItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["TextListOrderBy"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined
};
	["TextListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["TextItemConnection"]: {
	__typename: "TextItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TextItemEdge"]>
};
	["TextItemEdge"]: {
	__typename: "TextItemEdge",
	node: GraphQLTypes["TextItem"]
};
	["DeliveryRegionsItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	offerLabel?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["DeliveryRegionsOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	textList?: GraphQLTypes["TextListOrderBy"] | undefined,
	gallery?: GraphQLTypes["ImageListOrderBy"] | undefined
};
	["DeliveryRegionsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["DeliveryRegionsItemsByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["DeliveryRegionsItemsByTextListUniqueWhere"]: {
		textList?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["DeliveryRegionsItemsByGalleryUniqueWhere"]: {
		gallery?: GraphQLTypes["ImageListUniqueWhere"] | undefined
};
	["DeliveryRegionsItemConnection"]: {
	__typename: "DeliveryRegionsItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryRegionsItemEdge"]>
};
	["DeliveryRegionsItemEdge"]: {
	__typename: "DeliveryRegionsItemEdge",
	node: GraphQLTypes["DeliveryRegionsItem"]
};
	["GridTiles"]: {
	__typename: "GridTiles",
	_meta?: GraphQLTypes["GridTilesMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["GridTileItem"]>,
	itemsByLink?: GraphQLTypes["GridTileItem"] | undefined,
	paginateItems: GraphQLTypes["GridTileItemConnection"]
};
	["GridTilesMeta"]: {
	__typename: "GridTilesMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["GridTileItem"]: {
	__typename: "GridTileItem",
	_meta?: GraphQLTypes["GridTileItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	text?: string | undefined,
	list?: GraphQLTypes["GridTiles"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined
};
	["GridTileItemMeta"]: {
	__typename: "GridTileItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["GridTileItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["GridTilesOrderBy"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["GridTilesOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["GridTilesItemsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["LinkUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["GridTileItemConnection"]: {
	__typename: "GridTileItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GridTileItemEdge"]>
};
	["GridTileItemEdge"]: {
	__typename: "GridTileItemEdge",
	node: GraphQLTypes["GridTileItem"]
};
	["ContentReferenceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	primaryText?: GraphQLTypes["OrderDirection"] | undefined,
	secondaryText?: GraphQLTypes["OrderDirection"] | undefined,
	jsonContent?: GraphQLTypes["OrderDirection"] | undefined,
	block?: GraphQLTypes["ContentBlockOrderBy"] | undefined,
	colorPallete?: GraphQLTypes["ColorPalleteOrderBy"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostListOrderBy"] | undefined,
	links?: GraphQLTypes["LinkListOrderBy"] | undefined,
	products?: GraphQLTypes["ProductListOrderBy"] | undefined,
	contentSize?: GraphQLTypes["OrderDirection"] | undefined,
	misc?: GraphQLTypes["OrderDirection"] | undefined,
	hero?: GraphQLTypes["HeroOrderBy"] | undefined,
	gallery?: GraphQLTypes["GalleryOrderBy"] | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndAppsOrderBy"] | undefined,
	productGrid?: GraphQLTypes["ProductGridOrderBy"] | undefined,
	productBanners?: GraphQLTypes["ProductBannerListOrderBy"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegionsOrderBy"] | undefined,
	textList?: GraphQLTypes["TextListOrderBy"] | undefined,
	gridTiles?: GraphQLTypes["GridTilesOrderBy"] | undefined
};
	["ContentBlockOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	json?: GraphQLTypes["OrderDirection"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined
};
	["HeroOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	showLogo?: GraphQLTypes["OrderDirection"] | undefined,
	middleContent?: GraphQLTypes["OrderDirection"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined
};
	["SocialsAndAppsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	instagram?: GraphQLTypes["OrderDirection"] | undefined,
	appStore?: GraphQLTypes["OrderDirection"] | undefined,
	googlePlay?: GraphQLTypes["OrderDirection"] | undefined
};
	["ContentBlockReferencesByProductsUniqueWhere"]: {
		products?: GraphQLTypes["ProductListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByProductBannersUniqueWhere"]: {
		productBanners?: GraphQLTypes["ProductBannerListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByDeliveryRegionsUniqueWhere"]: {
		deliveryRegions?: GraphQLTypes["DeliveryRegionsUniqueWhere"] | undefined
};
	["ContentReferenceConnection"]: {
	__typename: "ContentReferenceConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentReferenceEdge"]>
};
	["ContentReferenceEdge"]: {
	__typename: "ContentReferenceEdge",
	node: GraphQLTypes["ContentReference"]
};
	["ContentBlocksByReferencesUniqueWhere"]: {
		references?: GraphQLTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentBlockConnection"]: {
	__typename: "ContentBlockConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentBlockEdge"]>
};
	["ContentBlockEdge"]: {
	__typename: "ContentBlockEdge",
	node: GraphQLTypes["ContentBlock"]
};
	["TranslationCataloguesIdentifier"]: {
	__typename: "TranslationCataloguesIdentifier",
	_meta?: GraphQLTypes["TranslationCataloguesIdentifierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	catalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	catalogueByDomain?: GraphQLTypes["TranslationCatalogue"] | undefined,
	catalogueByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	paginateCatalogue: GraphQLTypes["TranslationCatalogueConnection"]
};
	["TranslationCataloguesIdentifierMeta"]: {
	__typename: "TranslationCataloguesIdentifierMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCatalogue"]: {
	__typename: "TranslationCatalogue",
	_meta?: GraphQLTypes["TranslationCatalogueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogue"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	valuesByKey?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationCatalogueMeta"]: {
	__typename: "TranslationCatalogueMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	fallback?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationDomain"]: {
	__typename: "TranslationDomain",
	_meta?: GraphQLTypes["TranslationDomainMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	name: string,
	catalogues: Array<GraphQLTypes["TranslationCatalogue"]>,
	keys: Array<GraphQLTypes["TranslationKey"]>,
	cataloguesByIdentifier?: GraphQLTypes["TranslationCatalogue"] | undefined,
	cataloguesByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	keysByIdentifier?: GraphQLTypes["TranslationKey"] | undefined,
	keysByValues?: GraphQLTypes["TranslationKey"] | undefined,
	paginateCatalogues: GraphQLTypes["TranslationCatalogueConnection"],
	paginateKeys: GraphQLTypes["TranslationKeyConnection"]
};
	["TranslationDomainMeta"]: {
	__typename: "TranslationDomainMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	catalogues?: GraphQLTypes["FieldMeta"] | undefined,
	keys?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCatalogueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	domain?: GraphQLTypes["TranslationDomainOrderBy"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogueOrderBy"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierOrderBy"] | undefined
};
	["TranslationDomainOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	identifier?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined
};
	["TranslationKey"]: {
	__typename: "TranslationKey",
	_meta?: GraphQLTypes["TranslationKeyMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	contentType: GraphQLTypes["TranslationContentType"],
	note?: string | undefined,
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	valuesByCatalogue?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationKeyMeta"]: {
	__typename: "TranslationKeyMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	contentType?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValue"]: {
	__typename: "TranslationValue",
	_meta?: GraphQLTypes["TranslationValueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	value: string,
	catalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	key?: GraphQLTypes["TranslationKey"] | undefined
};
	["TranslationValueMeta"]: {
	__typename: "TranslationValueMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	value?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined,
	key?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	value?: GraphQLTypes["OrderDirection"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueOrderBy"] | undefined,
	key?: GraphQLTypes["TranslationKeyOrderBy"] | undefined
};
	["TranslationKeyOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	identifier?: GraphQLTypes["OrderDirection"] | undefined,
	contentType?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	domain?: GraphQLTypes["TranslationDomainOrderBy"] | undefined
};
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
		catalogue?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined
};
	["TranslationValueConnection"]: {
	__typename: "TranslationValueConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationValueEdge"]>
};
	["TranslationValueEdge"]: {
	__typename: "TranslationValueEdge",
	node: GraphQLTypes["TranslationValue"]
};
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
		identifier?: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined
};
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
		values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
		identifier?: string | undefined
};
	["TranslationDomainKeysByValuesUniqueWhere"]: {
		values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationCatalogueConnection"]: {
	__typename: "TranslationCatalogueConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCatalogueEdge"]>
};
	["TranslationCatalogueEdge"]: {
	__typename: "TranslationCatalogueEdge",
	node: GraphQLTypes["TranslationCatalogue"]
};
	["TranslationKeyConnection"]: {
	__typename: "TranslationKeyConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationKeyEdge"]>
};
	["TranslationKeyEdge"]: {
	__typename: "TranslationKeyEdge",
	node: GraphQLTypes["TranslationKey"]
};
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
		key?: GraphQLTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
		domain?: GraphQLTypes["TranslationDomainUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
		values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["ProductPackingLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	shortName?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductPackingOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["ProductLocale"]: {
	__typename: "ProductLocale",
	_meta?: GraphQLTypes["ProductLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	root?: GraphQLTypes["Product"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	featureList?: GraphQLTypes["TextList"] | undefined,
	printableName?: string | undefined,
	firstSticker?: string | undefined,
	secondSticker?: string | undefined
};
	["ProductLocaleMeta"]: {
	__typename: "ProductLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	featureList?: GraphQLTypes["FieldMeta"] | undefined,
	printableName?: GraphQLTypes["FieldMeta"] | undefined,
	firstSticker?: GraphQLTypes["FieldMeta"] | undefined,
	secondSticker?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	featureList?: GraphQLTypes["TextListOrderBy"] | undefined,
	printableName?: GraphQLTypes["OrderDirection"] | undefined,
	firstSticker?: GraphQLTypes["OrderDirection"] | undefined,
	secondSticker?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductRecipeLocale"]: {
	__typename: "ProductRecipeLocale",
	_meta?: GraphQLTypes["ProductRecipeLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductRecipe"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	description?: string | undefined
};
	["ProductRecipeLocaleMeta"]: {
	__typename: "ProductRecipeLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductRecipe"]: {
	__typename: "ProductRecipe",
	_meta?: GraphQLTypes["ProductRecipeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	products: Array<GraphQLTypes["Product"]>,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	ingredients: Array<GraphQLTypes["ProductIngredientItem"]>,
	needsPrepping: boolean,
	locales: Array<GraphQLTypes["ProductRecipeLocale"]>,
	gutTuning?: number | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	description?: string | undefined,
	productsByCode?: GraphQLTypes["Product"] | undefined,
	productsByStocks?: GraphQLTypes["Product"] | undefined,
	productsByOrderItems?: GraphQLTypes["Product"] | undefined,
	productsByLocales?: GraphQLTypes["Product"] | undefined,
	productsByVirtualProduct?: GraphQLTypes["Product"] | undefined,
	productsByMeta?: GraphQLTypes["Product"] | undefined,
	productsByPage?: GraphQLTypes["Product"] | undefined,
	ingredientsByQuantity?: GraphQLTypes["ProductIngredientItem"] | undefined,
	localesByLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateIngredients: GraphQLTypes["ProductIngredientItemConnection"],
	paginateLocales: GraphQLTypes["ProductRecipeLocaleConnection"]
};
	["ProductRecipeMeta"]: {
	__typename: "ProductRecipeMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	color?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	gutTuning?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientItem"]: {
	__typename: "ProductIngredientItem",
	_meta?: GraphQLTypes["ProductIngredientItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	internalOrder: number,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredient"] | undefined,
	quantity?: GraphQLTypes["ProductIngredientItemQuantity"] | undefined
};
	["ProductIngredientItemMeta"]: {
	__typename: "ProductIngredientItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	internalOrder?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientItemQuantity"]: {
	__typename: "ProductIngredientItemQuantity",
	_meta?: GraphQLTypes["ProductIngredientItemQuantityMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unit?: GraphQLTypes["ProductIngredientItemQuantityUnit"] | undefined,
	value: number
};
	["ProductIngredientItemQuantityMeta"]: {
	__typename: "ProductIngredientItemQuantityMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unit?: GraphQLTypes["FieldMeta"] | undefined,
	value?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	internalOrder?: GraphQLTypes["OrderDirection"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeOrderBy"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredientOrderBy"] | undefined,
	quantity?: GraphQLTypes["ProductIngredientItemQuantityOrderBy"] | undefined
};
	["ProductIngredientItemQuantityOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unit?: GraphQLTypes["ProductIngredientItemQuantityUnitOrderBy"] | undefined,
	value?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductRecipeLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductRecipeOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductRecipeProductsByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["ProductRecipeProductsByStocksUniqueWhere"]: {
		stocks?: GraphQLTypes["ProductStockUniqueWhere"] | undefined
};
	["ProductRecipeProductsByOrderItemsUniqueWhere"]: {
		orderItems?: GraphQLTypes["OrderItemUniqueWhere"] | undefined
};
	["ProductRecipeProductsByLocalesUniqueWhere"]: {
		locales?: GraphQLTypes["ProductLocaleUniqueWhere"] | undefined
};
	["ProductRecipeProductsByVirtualProductUniqueWhere"]: {
		virtualProduct?: GraphQLTypes["VirtualProductUniqueWhere"] | undefined
};
	["ProductRecipeProductsByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["ProductMetadataUniqueWhere"] | undefined
};
	["ProductRecipeProductsByPageUniqueWhere"]: {
		page?: GraphQLTypes["PageUniqueWhere"] | undefined
};
	["ProductRecipeIngredientsByQuantityUniqueWhere"]: {
		quantity?: GraphQLTypes["ProductIngredientItemQuantityUniqueWhere"] | undefined
};
	["ProductRecipeLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductIngredientItemConnection"]: {
	__typename: "ProductIngredientItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientItemEdge"]>
};
	["ProductIngredientItemEdge"]: {
	__typename: "ProductIngredientItemEdge",
	node: GraphQLTypes["ProductIngredientItem"]
};
	["ProductRecipeLocaleConnection"]: {
	__typename: "ProductRecipeLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductRecipeLocaleEdge"]>
};
	["ProductRecipeLocaleEdge"]: {
	__typename: "ProductRecipeLocaleEdge",
	node: GraphQLTypes["ProductRecipeLocale"]
};
	["DeliveryMethodLocale"]: {
	__typename: "DeliveryMethodLocale",
	_meta?: GraphQLTypes["DeliveryMethodLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	trackingCodeUrlTemplate?: string | undefined,
	processingTitle?: string | undefined,
	processingText?: string | undefined,
	processingIconUrl?: string | undefined,
	dispatchedTitle?: string | undefined,
	dispatchedText?: string | undefined,
	dispatchedIconUrl?: string | undefined,
	fulfilledTitle?: string | undefined,
	fulfilledText?: string | undefined,
	fulfilledIconUrl?: string | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	root?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["DeliveryMethodLocaleMeta"]: {
	__typename: "DeliveryMethodLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	processingTitle?: GraphQLTypes["FieldMeta"] | undefined,
	processingText?: GraphQLTypes["FieldMeta"] | undefined,
	processingIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedTitle?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedText?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledTitle?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledText?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethod"]: {
	__typename: "DeliveryMethod",
	_meta?: GraphQLTypes["DeliveryMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	paymentMethods: Array<GraphQLTypes["PaymentMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress: boolean,
	suitableForDeliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	enableDeliveryTracking: boolean,
	locales: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	timelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	localesByLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	paginatePaymentMethods: GraphQLTypes["PaymentMethodConnection"],
	paginateSuitableForDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginateLocales: GraphQLTypes["DeliveryMethodLocaleConnection"]
};
	["DeliveryMethodMeta"]: {
	__typename: "DeliveryMethodMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	paymentMethods?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["FieldMeta"] | undefined,
	suitableForDeliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	timelinePreset?: GraphQLTypes["FieldMeta"] | undefined
};
	["PaymentMethod"]: {
	__typename: "PaymentMethod",
	_meta?: GraphQLTypes["PaymentMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	blockingDispatch: boolean,
	type?: GraphQLTypes["PaymentMethodType"] | undefined,
	reduceGratuityByPercentage: number,
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"]
};
	["PaymentMethodMeta"]: {
	__typename: "PaymentMethodMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	blockingDispatch?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	reduceGratuityByPercentage?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethodOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	enabled?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["OrderDirection"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["OrderDirection"] | undefined,
	timelinePreset?: GraphQLTypes["DeliveryTimelinePresetOrderBy"] | undefined
};
	["DeliveryTimelinePresetOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	duration?: GraphQLTypes["OrderDirection"] | undefined,
	window?: GraphQLTypes["OrderDirection"] | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethodOrderBy"] | undefined
};
	["DeliveryMethodConnection"]: {
	__typename: "DeliveryMethodConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryMethodEdge"]>
};
	["DeliveryMethodEdge"]: {
	__typename: "DeliveryMethodEdge",
	node: GraphQLTypes["DeliveryMethod"]
};
	["PaymentMethodOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	enabled?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	blockingDispatch?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	reduceGratuityByPercentage?: GraphQLTypes["OrderDirection"] | undefined
};
	["DeliveryZone"]: {
	__typename: "DeliveryZone",
	_meta?: GraphQLTypes["DeliveryZoneMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type: GraphQLTypes["DeliveryZoneType"],
	addresses: Array<GraphQLTypes["Address"]>,
	suitableDeliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	deliveryZonesOfTags: Array<GraphQLTypes["Tag"]>,
	deliveryZonesOfProducts: Array<GraphQLTypes["Product"]>,
	addressesByBillingAddressOfOrders?: GraphQLTypes["Address"] | undefined,
	addressesByDeliveryAddressOfOrderDeliveries?: GraphQLTypes["Address"] | undefined,
	addressesByDefaultDeliveryAddressOfCustomer?: GraphQLTypes["Address"] | undefined,
	paginateAddresses: GraphQLTypes["AddressConnection"],
	paginateSuitableDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"],
	paginateDeliveryZonesOfTags: GraphQLTypes["TagConnection"],
	paginateDeliveryZonesOfProducts: GraphQLTypes["ProductConnection"]
};
	["DeliveryZoneMeta"]: {
	__typename: "DeliveryZoneMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	addresses?: GraphQLTypes["FieldMeta"] | undefined,
	suitableDeliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZonesOfTags?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZonesOfProducts?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere"]: {
		billingAddressOfOrders?: GraphQLTypes["OrderUniqueWhere"] | undefined
};
	["OrderUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	seq?: number | undefined,
	doubleshotLegacyId?: string | undefined,
	doubleShotLegacyNumber?: string | undefined,
	delivery?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined,
	items?: GraphQLTypes["OrderItemUniqueWhere"] | undefined,
	payments?: GraphQLTypes["OrderPaymentUniqueWhere"] | undefined
};
	["OrderDeliveryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	trackingCode?: string | undefined,
	order?: GraphQLTypes["OrderUniqueWhere"] | undefined,
	deliveryWaypoint?: GraphQLTypes["DeliveryWaypointUniqueWhere"] | undefined
};
	["DeliveryWaypointUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	delivery?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined
};
	["OrderPaymentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: {
		deliveryAddressOfOrderDeliveries?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined
};
	["DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: {
		defaultDeliveryAddressOfCustomer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["CustomerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	doubleshotLegacyId?: string | undefined,
	addresses?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	defaultBillingAddress?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	orders?: GraphQLTypes["OrderUniqueWhere"] | undefined,
	name?: GraphQLTypes["CustomerNameUniqueWhere"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	meta?: GraphQLTypes["CustomerMetadataUniqueWhere"] | undefined
};
	["AddressUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	billingAddressOfOrders?: GraphQLTypes["OrderUniqueWhere"] | undefined,
	deliveryAddressOfOrderDeliveries?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["CustomerNameUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["CustomerMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["AddressConnection"]: {
	__typename: "AddressConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AddressEdge"]>
};
	["AddressEdge"]: {
	__typename: "AddressEdge",
	node: GraphQLTypes["Address"]
};
	["DeliveryMethodLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	processingTitle?: GraphQLTypes["OrderDirection"] | undefined,
	processingText?: GraphQLTypes["OrderDirection"] | undefined,
	processingIconUrl?: GraphQLTypes["OrderDirection"] | undefined,
	dispatchedTitle?: GraphQLTypes["OrderDirection"] | undefined,
	dispatchedText?: GraphQLTypes["OrderDirection"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["OrderDirection"] | undefined,
	fulfilledTitle?: GraphQLTypes["OrderDirection"] | undefined,
	fulfilledText?: GraphQLTypes["OrderDirection"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["OrderDirection"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	root?: GraphQLTypes["DeliveryMethodOrderBy"] | undefined
};
	["DeliveryTimelinePreset"]: {
	__typename: "DeliveryTimelinePreset",
	_meta?: GraphQLTypes["DeliveryTimelinePresetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["DeliveryTimelineType"],
	duration?: string | undefined,
	window?: string | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["DeliveryTimelinePresetMeta"]: {
	__typename: "DeliveryTimelinePresetMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	window?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethod?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethodLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["PaymentMethodConnection"]: {
	__typename: "PaymentMethodConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PaymentMethodEdge"]>
};
	["PaymentMethodEdge"]: {
	__typename: "PaymentMethodEdge",
	node: GraphQLTypes["PaymentMethod"]
};
	["DeliveryZoneConnection"]: {
	__typename: "DeliveryZoneConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryZoneEdge"]>
};
	["DeliveryZoneEdge"]: {
	__typename: "DeliveryZoneEdge",
	node: GraphQLTypes["DeliveryZone"]
};
	["DeliveryMethodLocaleConnection"]: {
	__typename: "DeliveryMethodLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryMethodLocaleEdge"]>
};
	["DeliveryMethodLocaleEdge"]: {
	__typename: "DeliveryMethodLocaleEdge",
	node: GraphQLTypes["DeliveryMethodLocale"]
};
	["IdentificationCodeLocale"]: {
	__typename: "IdentificationCodeLocale",
	_meta?: GraphQLTypes["IdentificationCodeLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	successMessage?: string | undefined,
	root?: GraphQLTypes["IdentificationCode"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["IdentificationCodeLocaleMeta"]: {
	__typename: "IdentificationCodeLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	successMessage?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["IdentificationCode"]: {
	__typename: "IdentificationCode",
	_meta?: GraphQLTypes["IdentificationCodeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	createdAt: GraphQLTypes["DateTime"],
	attempts: Array<GraphQLTypes["IdentificationAttempt"]>,
	locales: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	internalName?: string | undefined,
	localesByLocale?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	paginateAttempts: GraphQLTypes["IdentificationAttemptConnection"],
	paginateLocales: GraphQLTypes["IdentificationCodeLocaleConnection"]
};
	["IdentificationCodeMeta"]: {
	__typename: "IdentificationCodeMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	attempts?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined
};
	["IdentificationAttempt"]: {
	__typename: "IdentificationAttempt",
	_meta?: GraphQLTypes["IdentificationAttemptMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	code?: GraphQLTypes["IdentificationCode"] | undefined
};
	["IdentificationAttemptMeta"]: {
	__typename: "IdentificationAttemptMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined
};
	["IdentificationAttemptOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["IdentificationCodeOrderBy"] | undefined
};
	["IdentificationCodeOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined
};
	["IdentificationCodeLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	successMessage?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["IdentificationCodeOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["IdentificationCodeLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["IdentificationAttemptConnection"]: {
	__typename: "IdentificationAttemptConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IdentificationAttemptEdge"]>
};
	["IdentificationAttemptEdge"]: {
	__typename: "IdentificationAttemptEdge",
	node: GraphQLTypes["IdentificationAttempt"]
};
	["IdentificationCodeLocaleConnection"]: {
	__typename: "IdentificationCodeLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IdentificationCodeLocaleEdge"]>
};
	["IdentificationCodeLocaleEdge"]: {
	__typename: "IdentificationCodeLocaleEdge",
	node: GraphQLTypes["IdentificationCodeLocale"]
};
	["SubscriptionBoxLocale"]: {
	__typename: "SubscriptionBoxLocale",
	_meta?: GraphQLTypes["SubscriptionBoxLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	stickerText?: string | undefined,
	descriptionText?: string | undefined,
	root?: GraphQLTypes["SubscriptionBox"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	secondaryTitle?: string | undefined,
	texts?: GraphQLTypes["TextList"] | undefined,
	secondaryTexts?: GraphQLTypes["TextList"] | undefined
};
	["SubscriptionBoxLocaleMeta"]: {
	__typename: "SubscriptionBoxLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	stickerText?: GraphQLTypes["FieldMeta"] | undefined,
	descriptionText?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	texts?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryTexts?: GraphQLTypes["FieldMeta"] | undefined
};
	["SubscriptionBox"]: {
	__typename: "SubscriptionBox",
	_meta?: GraphQLTypes["SubscriptionBoxMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	unique: GraphQLTypes["One"],
	localesByLocale?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	localesByTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	localesBySecondaryTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	paginateLocales: GraphQLTypes["SubscriptionBoxLocaleConnection"]
};
	["SubscriptionBoxMeta"]: {
	__typename: "SubscriptionBoxMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined
};
	["SubscriptionBoxLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	stickerText?: GraphQLTypes["OrderDirection"] | undefined,
	descriptionText?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["SubscriptionBoxOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	secondaryTitle?: GraphQLTypes["OrderDirection"] | undefined,
	texts?: GraphQLTypes["TextListOrderBy"] | undefined,
	secondaryTexts?: GraphQLTypes["TextListOrderBy"] | undefined
};
	["SubscriptionBoxOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined
};
	["SubscriptionBoxLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["SubscriptionBoxLocalesByTextsUniqueWhere"]: {
		texts?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["SubscriptionBoxLocalesBySecondaryTextsUniqueWhere"]: {
		secondaryTexts?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["SubscriptionBoxLocaleConnection"]: {
	__typename: "SubscriptionBoxLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SubscriptionBoxLocaleEdge"]>
};
	["SubscriptionBoxLocaleEdge"]: {
	__typename: "SubscriptionBoxLocaleEdge",
	node: GraphQLTypes["SubscriptionBoxLocale"]
};
	["LocaleBusinessCategoriesByRootUniqueWhere"]: {
		root?: GraphQLTypes["BusinessCategoryUniqueWhere"] | undefined
};
	["LocaleProductCategoryListsByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductCategoryListUniqueWhere"] | undefined
};
	["LocaleProductIngredientsByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductIngredientUniqueWhere"] | undefined
};
	["LocaleOrderRecurrencesByRootUniqueWhere"]: {
		root?: GraphQLTypes["OrderRecurrenceUniqueWhere"] | undefined
};
	["LocaleBlogsByRootUniqueWhere"]: {
		root?: GraphQLTypes["BlogUniqueWhere"] | undefined
};
	["LocaleBlogPostsByRootUniqueWhere"]: {
		root?: GraphQLTypes["BlogPostUniqueWhere"] | undefined
};
	["LocaleBlogPostsByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["CoverUniqueWhere"] | undefined
};
	["LocaleBlogPostsByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocaleBlogPostsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleBlogPostsBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocalePagesByRootUniqueWhere"]: {
		root?: GraphQLTypes["PageUniqueWhere"] | undefined
};
	["LocalePagesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocalePagesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocalePagesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocalePagesByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["CoverUniqueWhere"] | undefined
};
	["LocaleProductIngredientCategoriesByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductIngredientCategoryUniqueWhere"] | undefined
};
	["LocaleAllergensByRootUniqueWhere"]: {
		root?: GraphQLTypes["AllergenUniqueWhere"] | undefined
};
	["LocaleProductPackingsByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductPackingUniqueWhere"] | undefined
};
	["LocaleProductsByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["LocaleProductsByFeatureListUniqueWhere"]: {
		featureList?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["LocaleProductRecipesByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductRecipeUniqueWhere"] | undefined
};
	["LocaleDeliveryMethodsByRootUniqueWhere"]: {
		root?: GraphQLTypes["DeliveryMethodUniqueWhere"] | undefined
};
	["LocaleIngredientSuppliersByRootUniqueWhere"]: {
		root?: GraphQLTypes["IngredientSupplierUniqueWhere"] | undefined
};
	["LocaleIdentificationCodesByRootUniqueWhere"]: {
		root?: GraphQLTypes["IdentificationCodeUniqueWhere"] | undefined
};
	["LocaleSubscriptionBoxesByRootUniqueWhere"]: {
		root?: GraphQLTypes["SubscriptionBoxUniqueWhere"] | undefined
};
	["LocaleSubscriptionBoxesByTextsUniqueWhere"]: {
		texts?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere"]: {
		secondaryTexts?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["ProductPackingLocaleConnection"]: {
	__typename: "ProductPackingLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductPackingLocaleEdge"]>
};
	["ProductPackingLocaleEdge"]: {
	__typename: "ProductPackingLocaleEdge",
	node: GraphQLTypes["ProductPackingLocale"]
};
	["ProductLocaleConnection"]: {
	__typename: "ProductLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductLocaleEdge"]>
};
	["ProductLocaleEdge"]: {
	__typename: "ProductLocaleEdge",
	node: GraphQLTypes["ProductLocale"]
};
	["PreparedPackingQuantity"]: {
	__typename: "PreparedPackingQuantity",
	_meta?: GraphQLTypes["PreparedPackingQuantityMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	date: GraphQLTypes["Date"],
	quantity?: number | undefined,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	packing?: GraphQLTypes["ProductPacking"] | undefined,
	packedInCardboard: boolean
};
	["PreparedPackingQuantityMeta"]: {
	__typename: "PreparedPackingQuantityMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	date?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	packing?: GraphQLTypes["FieldMeta"] | undefined,
	packedInCardboard?: GraphQLTypes["FieldMeta"] | undefined
};
	["PreparedPackingQuantityOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	date?: GraphQLTypes["OrderDirection"] | undefined,
	quantity?: GraphQLTypes["OrderDirection"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeOrderBy"] | undefined,
	packing?: GraphQLTypes["ProductPackingOrderBy"] | undefined,
	packedInCardboard?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductPackingLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["PreparedPackingQuantityConnection"]: {
	__typename: "PreparedPackingQuantityConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PreparedPackingQuantityEdge"]>
};
	["PreparedPackingQuantityEdge"]: {
	__typename: "PreparedPackingQuantityEdge",
	node: GraphQLTypes["PreparedPackingQuantity"]
};
	["ProductStock"]: {
	__typename: "ProductStock",
	_meta?: GraphQLTypes["ProductStockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	quantity: number,
	product?: GraphQLTypes["Product"] | undefined,
	store?: GraphQLTypes["Store"] | undefined
};
	["ProductStockMeta"]: {
	__typename: "ProductStockMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	store?: GraphQLTypes["FieldMeta"] | undefined
};
	["Store"]: {
	__typename: "Store",
	_meta?: GraphQLTypes["StoreMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	description?: string | undefined,
	internalNote?: string | undefined
};
	["StoreMeta"]: {
	__typename: "StoreMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductStockOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	quantity?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined,
	store?: GraphQLTypes["StoreOrderBy"] | undefined
};
	["StoreOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderItem"]: {
	__typename: "OrderItem",
	_meta?: GraphQLTypes["OrderItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unitPriceCents: number,
	quantity: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	product?: GraphQLTypes["Product"] | undefined,
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["OrderItemMeta"]: {
	__typename: "OrderItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unitPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	pickedAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["Order"]: {
	__typename: "Order",
	_meta?: GraphQLTypes["OrderMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	state: GraphQLTypes["OrderState"],
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Customer"] | undefined,
	channel?: GraphQLTypes["Channel"] | undefined,
	delivery?: GraphQLTypes["OrderDelivery"] | undefined,
	items: Array<GraphQLTypes["OrderItem"]>,
	internalNote?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	billingAddress?: GraphQLTypes["Address"] | undefined,
	payments: Array<GraphQLTypes["OrderPayment"]>,
	seq: number,
	doubleshotLegacyId?: string | undefined,
	venue?: GraphQLTypes["Venue"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	paginateItems: GraphQLTypes["OrderItemConnection"],
	paginatePayments: GraphQLTypes["OrderPaymentConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["OrderMeta"]: {
	__typename: "OrderMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	state?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledAt?: GraphQLTypes["FieldMeta"] | undefined,
	canceledAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	channel?: GraphQLTypes["FieldMeta"] | undefined,
	delivery?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	billingAddress?: GraphQLTypes["FieldMeta"] | undefined,
	payments?: GraphQLTypes["FieldMeta"] | undefined,
	seq?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["FieldMeta"] | undefined,
	venue?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNote?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotTotalWeightGrams?: GraphQLTypes["FieldMeta"] | undefined,
	doubleShotLegacyNumber?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotAdminUrl?: GraphQLTypes["FieldMeta"] | undefined
};
	["Channel"]: {
	__typename: "Channel",
	_meta?: GraphQLTypes["ChannelMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	disabled: boolean,
	customers: Array<GraphQLTypes["Customer"]>,
	code?: GraphQLTypes["ChannelCode"] | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Tag"]>,
	publicTags: Array<GraphQLTypes["Tag"]>,
	venue?: GraphQLTypes["Venue"] | undefined,
	paginateCustomers: GraphQLTypes["CustomerConnection"],
	paginateAddTagsOnRegistration: GraphQLTypes["TagConnection"],
	paginatePublicTags: GraphQLTypes["TagConnection"]
};
	["ChannelMeta"]: {
	__typename: "ChannelMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	disabled?: GraphQLTypes["FieldMeta"] | undefined,
	customers?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["FieldMeta"] | undefined,
	publicTags?: GraphQLTypes["FieldMeta"] | undefined,
	venue?: GraphQLTypes["FieldMeta"] | undefined
};
	["Venue"]: {
	__typename: "Venue",
	_meta?: GraphQLTypes["VenueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	deadlineTemplate?: GraphQLTypes["DeadlineTemplate"] | undefined,
	timetableDays: Array<GraphQLTypes["TimetableDay"]>,
	timetableTemplates: Array<GraphQLTypes["TimetableTemplate"]>,
	staffMembers: Array<GraphQLTypes["Staff"]>,
	employeeRoles: Array<GraphQLTypes["VenueEmployeeRole"]>,
	orders: Array<GraphQLTypes["Order"]>,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	enabledUnassignReasons: Array<GraphQLTypes["UnassignReason"]>,
	channels: Array<GraphQLTypes["Channel"]>,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView: GraphQLTypes["PointOfSaleInitialView"],
	pointOfSalePreferPrintReceipt: boolean,
	timetableDaysByDate?: GraphQLTypes["TimetableDay"] | undefined,
	timetableDaysByShiftGroups?: GraphQLTypes["TimetableDay"] | undefined,
	timetableDaysByNotes?: GraphQLTypes["TimetableDay"] | undefined,
	timetableDaysByMeta?: GraphQLTypes["TimetableDay"] | undefined,
	employeeRolesByRole?: GraphQLTypes["VenueEmployeeRole"] | undefined,
	employeeRolesByEmployeePrivileges?: GraphQLTypes["VenueEmployeeRole"] | undefined,
	ordersBySeq?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	ordersByDelivery?: GraphQLTypes["Order"] | undefined,
	ordersByItems?: GraphQLTypes["Order"] | undefined,
	ordersByPayments?: GraphQLTypes["Order"] | undefined,
	channelsByCode?: GraphQLTypes["Channel"] | undefined,
	paginateTimetableDays: GraphQLTypes["TimetableDayConnection"],
	paginateTimetableTemplates: GraphQLTypes["TimetableTemplateConnection"],
	paginateStaffMembers: GraphQLTypes["StaffConnection"],
	paginateEmployeeRoles: GraphQLTypes["VenueEmployeeRoleConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"],
	paginateEnabledUnassignReasons: GraphQLTypes["UnassignReasonConnection"],
	paginateChannels: GraphQLTypes["ChannelConnection"]
};
	["VenueMeta"]: {
	__typename: "VenueMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	deadlineTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	timetableDays?: GraphQLTypes["FieldMeta"] | undefined,
	timetableTemplates?: GraphQLTypes["FieldMeta"] | undefined,
	staffMembers?: GraphQLTypes["FieldMeta"] | undefined,
	employeeRoles?: GraphQLTypes["FieldMeta"] | undefined,
	orders?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined,
	enabledUnassignReasons?: GraphQLTypes["FieldMeta"] | undefined,
	channels?: GraphQLTypes["FieldMeta"] | undefined,
	address?: GraphQLTypes["FieldMeta"] | undefined,
	pointOfSalePngBase64ReceiptLogo?: GraphQLTypes["FieldMeta"] | undefined,
	pointOfSaleInitialView?: GraphQLTypes["FieldMeta"] | undefined,
	pointOfSalePreferPrintReceipt?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeadlineTemplate"]: {
	__typename: "DeadlineTemplate",
	_meta?: GraphQLTypes["DeadlineTemplateMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	startDate: GraphQLTypes["Date"],
	period: number,
	cutoff?: number | undefined,
	closed?: boolean | undefined,
	venue?: GraphQLTypes["Venue"] | undefined
};
	["DeadlineTemplateMeta"]: {
	__typename: "DeadlineTemplateMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	startDate?: GraphQLTypes["FieldMeta"] | undefined,
	period?: GraphQLTypes["FieldMeta"] | undefined,
	cutoff?: GraphQLTypes["FieldMeta"] | undefined,
	closed?: GraphQLTypes["FieldMeta"] | undefined,
	venue?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableDay"]: {
	__typename: "TimetableDay",
	_meta?: GraphQLTypes["TimetableDayMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	date: GraphQLTypes["Date"],
	type?: GraphQLTypes["TimetableDayType"] | undefined,
	shiftGroups: Array<GraphQLTypes["TimetableShiftGroup"]>,
	tipsCents?: number | undefined,
	notes: Array<GraphQLTypes["TimetableDayNote"]>,
	venue?: GraphQLTypes["Venue"] | undefined,
	meta?: GraphQLTypes["TimetableDayMetadata"] | undefined,
	shiftGroupsByShifts?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	shiftGroupsByQueue?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	paginateShiftGroups: GraphQLTypes["TimetableShiftGroupConnection"],
	paginateNotes: GraphQLTypes["TimetableDayNoteConnection"]
};
	["TimetableDayMeta"]: {
	__typename: "TimetableDayMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	date?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	shiftGroups?: GraphQLTypes["FieldMeta"] | undefined,
	tipsCents?: GraphQLTypes["FieldMeta"] | undefined,
	notes?: GraphQLTypes["FieldMeta"] | undefined,
	venue?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableShiftGroup"]: {
	__typename: "TimetableShiftGroup",
	_meta?: GraphQLTypes["TimetableShiftGroupMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	shifts: Array<GraphQLTypes["TimetableShift"]>,
	day?: GraphQLTypes["TimetableDay"] | undefined,
	type?: GraphQLTypes["TimetableEmployeeRole"] | undefined,
	position?: GraphQLTypes["TimetableShiftPosition"] | undefined,
	queue?: GraphQLTypes["EmployeeQueue"] | undefined,
	generatedByTemplate?: GraphQLTypes["TimetableTemplate"] | undefined,
	shiftsByQueueItem?: GraphQLTypes["TimetableShift"] | undefined,
	paginateShifts: GraphQLTypes["TimetableShiftConnection"]
};
	["TimetableShiftGroupMeta"]: {
	__typename: "TimetableShiftGroupMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	shifts?: GraphQLTypes["FieldMeta"] | undefined,
	day?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	position?: GraphQLTypes["FieldMeta"] | undefined,
	queue?: GraphQLTypes["FieldMeta"] | undefined,
	generatedByTemplate?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableShift"]: {
	__typename: "TimetableShift",
	_meta?: GraphQLTypes["TimetableShiftMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locked: boolean,
	order: number,
	assigned?: GraphQLTypes["Date"] | undefined,
	group?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	shiftsProfile?: GraphQLTypes["ShiftsProfile"] | undefined,
	queueItem?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	isOvertime: boolean
};
	["TimetableShiftMeta"]: {
	__typename: "TimetableShiftMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locked?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	assigned?: GraphQLTypes["FieldMeta"] | undefined,
	group?: GraphQLTypes["FieldMeta"] | undefined,
	shiftsProfile?: GraphQLTypes["FieldMeta"] | undefined,
	queueItem?: GraphQLTypes["FieldMeta"] | undefined,
	isOvertime?: GraphQLTypes["FieldMeta"] | undefined
};
	["ShiftsProfile"]: {
	__typename: "ShiftsProfile",
	_meta?: GraphQLTypes["ShiftsProfileMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	queueItems: Array<GraphQLTypes["EmployeeQueueItem"]>,
	staff?: GraphQLTypes["Staff"] | undefined,
	roles: Array<GraphQLTypes["TimetableEmployeeRole"]>,
	shifts: Array<GraphQLTypes["TimetableShift"]>,
	allowSelfUnassigning?: boolean | undefined,
	queueItemsByQueue?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	queueItemsByShift?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	queueItemsByUnassignInfo?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	shiftsByQueueItem?: GraphQLTypes["TimetableShift"] | undefined,
	paginateQueueItems: GraphQLTypes["EmployeeQueueItemConnection"],
	paginateRoles: GraphQLTypes["TimetableEmployeeRoleConnection"],
	paginateShifts: GraphQLTypes["TimetableShiftConnection"]
};
	["ShiftsProfileMeta"]: {
	__typename: "ShiftsProfileMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	queueItems?: GraphQLTypes["FieldMeta"] | undefined,
	staff?: GraphQLTypes["FieldMeta"] | undefined,
	roles?: GraphQLTypes["FieldMeta"] | undefined,
	shifts?: GraphQLTypes["FieldMeta"] | undefined,
	allowSelfUnassigning?: GraphQLTypes["FieldMeta"] | undefined
};
	["EmployeeQueueItem"]: {
	__typename: "EmployeeQueueItem",
	_meta?: GraphQLTypes["EmployeeQueueItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["EmployeeQueue"] | undefined,
	shiftsProfile?: GraphQLTypes["ShiftsProfile"] | undefined,
	shift?: GraphQLTypes["TimetableShift"] | undefined,
	unassignInfo?: GraphQLTypes["UnassignedShiftInfo"] | undefined
};
	["EmployeeQueueItemMeta"]: {
	__typename: "EmployeeQueueItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	start?: GraphQLTypes["FieldMeta"] | undefined,
	end?: GraphQLTypes["FieldMeta"] | undefined,
	queue?: GraphQLTypes["FieldMeta"] | undefined,
	shiftsProfile?: GraphQLTypes["FieldMeta"] | undefined,
	shift?: GraphQLTypes["FieldMeta"] | undefined,
	unassignInfo?: GraphQLTypes["FieldMeta"] | undefined
};
	["EmployeeQueue"]: {
	__typename: "EmployeeQueue",
	_meta?: GraphQLTypes["EmployeeQueueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	shiftGroup?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	items: Array<GraphQLTypes["EmployeeQueueItem"]>,
	contemberBugFix?: boolean | undefined,
	itemsByShiftsProfile?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	itemsByShift?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	itemsByUnassignInfo?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	paginateItems: GraphQLTypes["EmployeeQueueItemConnection"]
};
	["EmployeeQueueMeta"]: {
	__typename: "EmployeeQueueMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	shiftGroup?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	contemberBugFix?: GraphQLTypes["FieldMeta"] | undefined
};
	["EmployeeQueueItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	start?: GraphQLTypes["OrderDirection"] | undefined,
	end?: GraphQLTypes["OrderDirection"] | undefined,
	queue?: GraphQLTypes["EmployeeQueueOrderBy"] | undefined,
	shiftsProfile?: GraphQLTypes["ShiftsProfileOrderBy"] | undefined,
	shift?: GraphQLTypes["TimetableShiftOrderBy"] | undefined,
	unassignInfo?: GraphQLTypes["UnassignedShiftInfoOrderBy"] | undefined
};
	["EmployeeQueueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	shiftGroup?: GraphQLTypes["TimetableShiftGroupOrderBy"] | undefined,
	contemberBugFix?: GraphQLTypes["OrderDirection"] | undefined
};
	["TimetableShiftGroupOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	day?: GraphQLTypes["TimetableDayOrderBy"] | undefined,
	type?: GraphQLTypes["TimetableEmployeeRoleOrderBy"] | undefined,
	position?: GraphQLTypes["TimetableShiftPositionOrderBy"] | undefined,
	queue?: GraphQLTypes["EmployeeQueueOrderBy"] | undefined,
	generatedByTemplate?: GraphQLTypes["TimetableTemplateOrderBy"] | undefined
};
	["TimetableDayOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	date?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	tipsCents?: GraphQLTypes["OrderDirection"] | undefined,
	venue?: GraphQLTypes["VenueOrderBy"] | undefined,
	meta?: GraphQLTypes["TimetableDayMetadataOrderBy"] | undefined
};
	["VenueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	deadlineTemplate?: GraphQLTypes["DeadlineTemplateOrderBy"] | undefined,
	address?: GraphQLTypes["OrderDirection"] | undefined,
	pointOfSalePngBase64ReceiptLogo?: GraphQLTypes["OrderDirection"] | undefined,
	pointOfSaleInitialView?: GraphQLTypes["OrderDirection"] | undefined,
	pointOfSalePreferPrintReceipt?: GraphQLTypes["OrderDirection"] | undefined
};
	["DeadlineTemplateOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	startDate?: GraphQLTypes["OrderDirection"] | undefined,
	period?: GraphQLTypes["OrderDirection"] | undefined,
	cutoff?: GraphQLTypes["OrderDirection"] | undefined,
	closed?: GraphQLTypes["OrderDirection"] | undefined,
	venue?: GraphQLTypes["VenueOrderBy"] | undefined
};
	["TimetableDayMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	numberOfWorkers?: GraphQLTypes["OrderDirection"] | undefined,
	tipsTotalCents?: GraphQLTypes["OrderDirection"] | undefined,
	totalMinutes?: GraphQLTypes["OrderDirection"] | undefined,
	tipsCentsPerMinute?: GraphQLTypes["OrderDirection"] | undefined,
	day?: GraphQLTypes["TimetableDayOrderBy"] | undefined
};
	["TimetableEmployeeRoleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	color?: GraphQLTypes["OrderDirection"] | undefined
};
	["TimetableShiftPositionOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	start?: GraphQLTypes["OrderDirection"] | undefined,
	end?: GraphQLTypes["OrderDirection"] | undefined,
	isDynamic?: GraphQLTypes["OrderDirection"] | undefined
};
	["TimetableTemplateOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	startDate?: GraphQLTypes["OrderDirection"] | undefined,
	period?: GraphQLTypes["OrderDirection"] | undefined,
	slots?: GraphQLTypes["OrderDirection"] | undefined,
	position?: GraphQLTypes["TimetableShiftPositionOrderBy"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	venue?: GraphQLTypes["VenueOrderBy"] | undefined,
	employeeRole?: GraphQLTypes["TimetableEmployeeRoleOrderBy"] | undefined,
	workdaysOnly?: GraphQLTypes["OrderDirection"] | undefined,
	monday?: GraphQLTypes["OrderDirection"] | undefined,
	tuesday?: GraphQLTypes["OrderDirection"] | undefined,
	wednesday?: GraphQLTypes["OrderDirection"] | undefined,
	thursday?: GraphQLTypes["OrderDirection"] | undefined,
	friday?: GraphQLTypes["OrderDirection"] | undefined,
	saturday?: GraphQLTypes["OrderDirection"] | undefined,
	sunday?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined
};
	["ShiftsProfileOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	staff?: GraphQLTypes["StaffOrderBy"] | undefined,
	allowSelfUnassigning?: GraphQLTypes["OrderDirection"] | undefined
};
	["StaffOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	shiftsProfile?: GraphQLTypes["ShiftsProfileOrderBy"] | undefined,
	photo?: GraphQLTypes["ImageOrderBy"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined
};
	["TimetableShiftOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	locked?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	assigned?: GraphQLTypes["OrderDirection"] | undefined,
	group?: GraphQLTypes["TimetableShiftGroupOrderBy"] | undefined,
	shiftsProfile?: GraphQLTypes["ShiftsProfileOrderBy"] | undefined,
	queueItem?: GraphQLTypes["EmployeeQueueItemOrderBy"] | undefined,
	isOvertime?: GraphQLTypes["OrderDirection"] | undefined
};
	["UnassignedShiftInfoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	hoursMissed?: GraphQLTypes["OrderDirection"] | undefined,
	queueItem?: GraphQLTypes["EmployeeQueueItemOrderBy"] | undefined,
	legacyReason?: GraphQLTypes["OrderDirection"] | undefined,
	reason?: GraphQLTypes["UnassignReasonOrderBy"] | undefined
};
	["UnassignReasonOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined
};
	["EmployeeQueueItemsByShiftsProfileUniqueWhere"]: {
		shiftsProfile?: GraphQLTypes["ShiftsProfileUniqueWhere"] | undefined
};
	["ShiftsProfileUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	queueItems?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined,
	staff?: GraphQLTypes["StaffUniqueWhere"] | undefined,
	shifts?: GraphQLTypes["TimetableShiftUniqueWhere"] | undefined
};
	["EmployeeQueueItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shiftsProfile?: GraphQLTypes["ShiftsProfileUniqueWhere"] | undefined,
	queue?: GraphQLTypes["EmployeeQueueUniqueWhere"] | undefined,
	shift?: GraphQLTypes["TimetableShiftUniqueWhere"] | undefined,
	unassignInfo?: GraphQLTypes["UnassignedShiftInfoUniqueWhere"] | undefined
};
	["EmployeeQueueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shiftGroup?: GraphQLTypes["TimetableShiftGroupUniqueWhere"] | undefined,
	items?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined
};
	["TimetableShiftGroupUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shifts?: GraphQLTypes["TimetableShiftUniqueWhere"] | undefined,
	queue?: GraphQLTypes["EmployeeQueueUniqueWhere"] | undefined
};
	["TimetableShiftUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	queueItem?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined
};
	["UnassignedShiftInfoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	queueItem?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined
};
	["StaffUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shiftsProfile?: GraphQLTypes["ShiftsProfileUniqueWhere"] | undefined
};
	["EmployeeQueueItemsByShiftUniqueWhere"]: {
		shift?: GraphQLTypes["TimetableShiftUniqueWhere"] | undefined
};
	["EmployeeQueueItemsByUnassignInfoUniqueWhere"]: {
		unassignInfo?: GraphQLTypes["UnassignedShiftInfoUniqueWhere"] | undefined
};
	["EmployeeQueueItemConnection"]: {
	__typename: "EmployeeQueueItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["EmployeeQueueItemEdge"]>
};
	["EmployeeQueueItemEdge"]: {
	__typename: "EmployeeQueueItemEdge",
	node: GraphQLTypes["EmployeeQueueItem"]
};
	["UnassignedShiftInfo"]: {
	__typename: "UnassignedShiftInfo",
	_meta?: GraphQLTypes["UnassignedShiftInfoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	hoursMissed?: number | undefined,
	queueItem?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	legacyReason?: GraphQLTypes["UnassignedInfoReason"] | undefined,
	reason?: GraphQLTypes["UnassignReason"] | undefined
};
	["UnassignedShiftInfoMeta"]: {
	__typename: "UnassignedShiftInfoMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	hoursMissed?: GraphQLTypes["FieldMeta"] | undefined,
	queueItem?: GraphQLTypes["FieldMeta"] | undefined,
	legacyReason?: GraphQLTypes["FieldMeta"] | undefined,
	reason?: GraphQLTypes["FieldMeta"] | undefined
};
	["UnassignReason"]: {
	__typename: "UnassignReason",
	_meta?: GraphQLTypes["UnassignReasonMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	name: string,
	venues: Array<GraphQLTypes["Venue"]>,
	paginateVenues: GraphQLTypes["VenueConnection"]
};
	["UnassignReasonMeta"]: {
	__typename: "UnassignReasonMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	venues?: GraphQLTypes["FieldMeta"] | undefined
};
	["VenueConnection"]: {
	__typename: "VenueConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VenueEdge"]>
};
	["VenueEdge"]: {
	__typename: "VenueEdge",
	node: GraphQLTypes["Venue"]
};
	["Staff"]: {
	__typename: "Staff",
	_meta?: GraphQLTypes["StaffMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	shiftsProfile?: GraphQLTypes["ShiftsProfile"] | undefined,
	photo?: GraphQLTypes["Image"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	venues: Array<GraphQLTypes["Venue"]>,
	paginateVenues: GraphQLTypes["VenueConnection"]
};
	["StaffMeta"]: {
	__typename: "StaffMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	shiftsProfile?: GraphQLTypes["FieldMeta"] | undefined,
	photo?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	venues?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableEmployeeRole"]: {
	__typename: "TimetableEmployeeRole",
	_meta?: GraphQLTypes["TimetableEmployeeRoleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	shiftGroups: Array<GraphQLTypes["TimetableShiftGroup"]>,
	shiftsProfiles: Array<GraphQLTypes["ShiftsProfile"]>,
	name: string,
	timetableTemplates: Array<GraphQLTypes["TimetableTemplate"]>,
	color: string,
	shiftGroupsByShifts?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	shiftGroupsByQueue?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	paginateShiftGroups: GraphQLTypes["TimetableShiftGroupConnection"],
	paginateShiftsProfiles: GraphQLTypes["ShiftsProfileConnection"],
	paginateTimetableTemplates: GraphQLTypes["TimetableTemplateConnection"]
};
	["TimetableEmployeeRoleMeta"]: {
	__typename: "TimetableEmployeeRoleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	shiftGroups?: GraphQLTypes["FieldMeta"] | undefined,
	shiftsProfiles?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	timetableTemplates?: GraphQLTypes["FieldMeta"] | undefined,
	color?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableTemplate"]: {
	__typename: "TimetableTemplate",
	_meta?: GraphQLTypes["TimetableTemplateMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	startDate: GraphQLTypes["Date"],
	period?: number | undefined,
	slots?: number | undefined,
	position?: GraphQLTypes["TimetableShiftPosition"] | undefined,
	internalName?: string | undefined,
	venue?: GraphQLTypes["Venue"] | undefined,
	employeeRole?: GraphQLTypes["TimetableEmployeeRole"] | undefined,
	workdaysOnly?: boolean | undefined,
	monday: boolean,
	tuesday: boolean,
	wednesday: boolean,
	thursday: boolean,
	friday: boolean,
	saturday: boolean,
	sunday: boolean,
	type: GraphQLTypes["TemplateType"]
};
	["TimetableTemplateMeta"]: {
	__typename: "TimetableTemplateMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	startDate?: GraphQLTypes["FieldMeta"] | undefined,
	period?: GraphQLTypes["FieldMeta"] | undefined,
	slots?: GraphQLTypes["FieldMeta"] | undefined,
	position?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	venue?: GraphQLTypes["FieldMeta"] | undefined,
	employeeRole?: GraphQLTypes["FieldMeta"] | undefined,
	workdaysOnly?: GraphQLTypes["FieldMeta"] | undefined,
	monday?: GraphQLTypes["FieldMeta"] | undefined,
	tuesday?: GraphQLTypes["FieldMeta"] | undefined,
	wednesday?: GraphQLTypes["FieldMeta"] | undefined,
	thursday?: GraphQLTypes["FieldMeta"] | undefined,
	friday?: GraphQLTypes["FieldMeta"] | undefined,
	saturday?: GraphQLTypes["FieldMeta"] | undefined,
	sunday?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableShiftPosition"]: {
	__typename: "TimetableShiftPosition",
	_meta?: GraphQLTypes["TimetableShiftPositionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	start?: string | undefined,
	end?: string | undefined,
	shiftGroups: Array<GraphQLTypes["TimetableShiftGroup"]>,
	timetableTemplates: Array<GraphQLTypes["TimetableTemplate"]>,
	isDynamic: boolean,
	shiftGroupsByShifts?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	shiftGroupsByQueue?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	paginateShiftGroups: GraphQLTypes["TimetableShiftGroupConnection"],
	paginateTimetableTemplates: GraphQLTypes["TimetableTemplateConnection"]
};
	["TimetableShiftPositionMeta"]: {
	__typename: "TimetableShiftPositionMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	start?: GraphQLTypes["FieldMeta"] | undefined,
	end?: GraphQLTypes["FieldMeta"] | undefined,
	shiftGroups?: GraphQLTypes["FieldMeta"] | undefined,
	timetableTemplates?: GraphQLTypes["FieldMeta"] | undefined,
	isDynamic?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableShiftPositionShiftGroupsByShiftsUniqueWhere"]: {
		shifts?: GraphQLTypes["TimetableShiftUniqueWhere"] | undefined
};
	["TimetableShiftPositionShiftGroupsByQueueUniqueWhere"]: {
		queue?: GraphQLTypes["EmployeeQueueUniqueWhere"] | undefined
};
	["TimetableShiftGroupConnection"]: {
	__typename: "TimetableShiftGroupConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableShiftGroupEdge"]>
};
	["TimetableShiftGroupEdge"]: {
	__typename: "TimetableShiftGroupEdge",
	node: GraphQLTypes["TimetableShiftGroup"]
};
	["TimetableTemplateConnection"]: {
	__typename: "TimetableTemplateConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableTemplateEdge"]>
};
	["TimetableTemplateEdge"]: {
	__typename: "TimetableTemplateEdge",
	node: GraphQLTypes["TimetableTemplate"]
};
	["TimetableEmployeeRoleShiftGroupsByShiftsUniqueWhere"]: {
		shifts?: GraphQLTypes["TimetableShiftUniqueWhere"] | undefined
};
	["TimetableEmployeeRoleShiftGroupsByQueueUniqueWhere"]: {
		queue?: GraphQLTypes["EmployeeQueueUniqueWhere"] | undefined
};
	["ShiftsProfileConnection"]: {
	__typename: "ShiftsProfileConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ShiftsProfileEdge"]>
};
	["ShiftsProfileEdge"]: {
	__typename: "ShiftsProfileEdge",
	node: GraphQLTypes["ShiftsProfile"]
};
	["ShiftsProfileQueueItemsByQueueUniqueWhere"]: {
		queue?: GraphQLTypes["EmployeeQueueUniqueWhere"] | undefined
};
	["ShiftsProfileQueueItemsByShiftUniqueWhere"]: {
		shift?: GraphQLTypes["TimetableShiftUniqueWhere"] | undefined
};
	["ShiftsProfileQueueItemsByUnassignInfoUniqueWhere"]: {
		unassignInfo?: GraphQLTypes["UnassignedShiftInfoUniqueWhere"] | undefined
};
	["ShiftsProfileShiftsByQueueItemUniqueWhere"]: {
		queueItem?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined
};
	["TimetableEmployeeRoleConnection"]: {
	__typename: "TimetableEmployeeRoleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableEmployeeRoleEdge"]>
};
	["TimetableEmployeeRoleEdge"]: {
	__typename: "TimetableEmployeeRoleEdge",
	node: GraphQLTypes["TimetableEmployeeRole"]
};
	["TimetableShiftConnection"]: {
	__typename: "TimetableShiftConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableShiftEdge"]>
};
	["TimetableShiftEdge"]: {
	__typename: "TimetableShiftEdge",
	node: GraphQLTypes["TimetableShift"]
};
	["TimetableShiftGroupShiftsByQueueItemUniqueWhere"]: {
		queueItem?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined
};
	["TimetableDayNote"]: {
	__typename: "TimetableDayNote",
	_meta?: GraphQLTypes["TimetableDayNoteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	note: string,
	day?: GraphQLTypes["TimetableDay"] | undefined,
	role?: GraphQLTypes["TimetableEmployeeRole"] | undefined
};
	["TimetableDayNoteMeta"]: {
	__typename: "TimetableDayNoteMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	day?: GraphQLTypes["FieldMeta"] | undefined,
	role?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableDayNoteOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	day?: GraphQLTypes["TimetableDayOrderBy"] | undefined,
	role?: GraphQLTypes["TimetableEmployeeRoleOrderBy"] | undefined
};
	["TimetableDayMetadata"]: {
	__typename: "TimetableDayMetadata",
	_meta?: GraphQLTypes["TimetableDayMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	numberOfWorkers: number,
	tipsTotalCents: number,
	totalMinutes: number,
	tipsCentsPerMinute: number,
	day?: GraphQLTypes["TimetableDay"] | undefined
};
	["TimetableDayMetadataMeta"]: {
	__typename: "TimetableDayMetadataMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	numberOfWorkers?: GraphQLTypes["FieldMeta"] | undefined,
	tipsTotalCents?: GraphQLTypes["FieldMeta"] | undefined,
	totalMinutes?: GraphQLTypes["FieldMeta"] | undefined,
	tipsCentsPerMinute?: GraphQLTypes["FieldMeta"] | undefined,
	day?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetableDayShiftGroupsByShiftsUniqueWhere"]: {
		shifts?: GraphQLTypes["TimetableShiftUniqueWhere"] | undefined
};
	["TimetableDayShiftGroupsByQueueUniqueWhere"]: {
		queue?: GraphQLTypes["EmployeeQueueUniqueWhere"] | undefined
};
	["TimetableDayNoteConnection"]: {
	__typename: "TimetableDayNoteConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableDayNoteEdge"]>
};
	["TimetableDayNoteEdge"]: {
	__typename: "TimetableDayNoteEdge",
	node: GraphQLTypes["TimetableDayNote"]
};
	["VenueEmployeeRole"]: {
	__typename: "VenueEmployeeRole",
	_meta?: GraphQLTypes["VenueEmployeeRoleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	employeePrivileges?: GraphQLTypes["TimetablesEmployeePrivileges"] | undefined,
	venue?: GraphQLTypes["Venue"] | undefined,
	role?: GraphQLTypes["TimetableEmployeeRole"] | undefined,
	getsTipsShare: boolean,
	notesEnabled: boolean
};
	["VenueEmployeeRoleMeta"]: {
	__typename: "VenueEmployeeRoleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	employeePrivileges?: GraphQLTypes["FieldMeta"] | undefined,
	venue?: GraphQLTypes["FieldMeta"] | undefined,
	role?: GraphQLTypes["FieldMeta"] | undefined,
	getsTipsShare?: GraphQLTypes["FieldMeta"] | undefined,
	notesEnabled?: GraphQLTypes["FieldMeta"] | undefined
};
	["TimetablesEmployeePrivileges"]: {
	__typename: "TimetablesEmployeePrivileges",
	_meta?: GraphQLTypes["TimetablesEmployeePrivilegesMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	venueEmployeeRole?: GraphQLTypes["VenueEmployeeRole"] | undefined,
	read: boolean,
	unassign: boolean,
	assign: boolean
};
	["TimetablesEmployeePrivilegesMeta"]: {
	__typename: "TimetablesEmployeePrivilegesMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	venueEmployeeRole?: GraphQLTypes["FieldMeta"] | undefined,
	read?: GraphQLTypes["FieldMeta"] | undefined,
	unassign?: GraphQLTypes["FieldMeta"] | undefined,
	assign?: GraphQLTypes["FieldMeta"] | undefined
};
	["VenueEmployeeRoleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	employeePrivileges?: GraphQLTypes["TimetablesEmployeePrivilegesOrderBy"] | undefined,
	venue?: GraphQLTypes["VenueOrderBy"] | undefined,
	role?: GraphQLTypes["TimetableEmployeeRoleOrderBy"] | undefined,
	getsTipsShare?: GraphQLTypes["OrderDirection"] | undefined,
	notesEnabled?: GraphQLTypes["OrderDirection"] | undefined
};
	["TimetablesEmployeePrivilegesOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	venueEmployeeRole?: GraphQLTypes["VenueEmployeeRoleOrderBy"] | undefined,
	read?: GraphQLTypes["OrderDirection"] | undefined,
	unassign?: GraphQLTypes["OrderDirection"] | undefined,
	assign?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	state?: GraphQLTypes["OrderDirection"] | undefined,
	fulfilledAt?: GraphQLTypes["OrderDirection"] | undefined,
	canceledAt?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined,
	channel?: GraphQLTypes["ChannelOrderBy"] | undefined,
	delivery?: GraphQLTypes["OrderDeliveryOrderBy"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	billingAddress?: GraphQLTypes["AddressOrderBy"] | undefined,
	seq?: GraphQLTypes["OrderDirection"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["OrderDirection"] | undefined,
	venue?: GraphQLTypes["VenueOrderBy"] | undefined,
	fulfillmentNote?: GraphQLTypes["OrderDirection"] | undefined,
	doubleshotTotalWeightGrams?: GraphQLTypes["OrderDirection"] | undefined,
	doubleShotLegacyNumber?: GraphQLTypes["OrderDirection"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["OrderDirection"] | undefined,
	doubleshotAdminUrl?: GraphQLTypes["OrderDirection"] | undefined
};
	["ChannelOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	disabled?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	venue?: GraphQLTypes["VenueOrderBy"] | undefined
};
	["OrderDeliveryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["OrderDirection"] | undefined,
	deliveredAt?: GraphQLTypes["OrderDirection"] | undefined,
	method?: GraphQLTypes["DeliveryMethodOrderBy"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	order?: GraphQLTypes["OrderOrderBy"] | undefined,
	expectedAt?: GraphQLTypes["OrderDirection"] | undefined,
	state?: GraphQLTypes["OrderDirection"] | undefined,
	loadingDurationHours?: GraphQLTypes["OrderDirection"] | undefined,
	dispatchAt?: GraphQLTypes["OrderDirection"] | undefined,
	duration?: GraphQLTypes["OrderDirection"] | undefined,
	window?: GraphQLTypes["OrderDirection"] | undefined,
	expectedEndAt?: GraphQLTypes["OrderDirection"] | undefined,
	processingAt?: GraphQLTypes["OrderDirection"] | undefined,
	deliveringAt?: GraphQLTypes["OrderDirection"] | undefined,
	failedAt?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	deliveryWaypoint?: GraphQLTypes["DeliveryWaypointOrderBy"] | undefined,
	prepareAt?: GraphQLTypes["OrderDirection"] | undefined,
	pickedAt?: GraphQLTypes["OrderDirection"] | undefined,
	address?: GraphQLTypes["AddressOrderBy"] | undefined,
	trackingCode?: GraphQLTypes["OrderDirection"] | undefined
};
	["DeliveryWaypointOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	estimatedArrival?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	route?: GraphQLTypes["DeliveryRouteOrderBy"] | undefined,
	sortingOrder?: GraphQLTypes["OrderDirection"] | undefined,
	delivery?: GraphQLTypes["OrderDeliveryOrderBy"] | undefined,
	estimatedDistance?: GraphQLTypes["OrderDirection"] | undefined,
	estimatedDuration?: GraphQLTypes["OrderDirection"] | undefined,
	originDeparture?: GraphQLTypes["OrderDirection"] | undefined,
	originAddress?: GraphQLTypes["OrderDirection"] | undefined,
	transitMode?: GraphQLTypes["OrderDirection"] | undefined
};
	["DeliveryRouteOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	dispatchAt?: GraphQLTypes["OrderDirection"] | undefined
};
	["FulfillmentNote"]: {
	__typename: "FulfillmentNote",
	_meta?: GraphQLTypes["FulfillmentNoteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	note: string,
	products: Array<GraphQLTypes["Product"]>,
	tags: Array<GraphQLTypes["Tag"]>,
	venues: Array<GraphQLTypes["Venue"]>,
	orderItems: Array<GraphQLTypes["OrderItem"]>,
	orders: Array<GraphQLTypes["Order"]>,
	highlight: boolean,
	createdAt: GraphQLTypes["DateTime"],
	emoji?: string | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateTags: GraphQLTypes["TagConnection"],
	paginateVenues: GraphQLTypes["VenueConnection"],
	paginateOrderItems: GraphQLTypes["OrderItemConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"]
};
	["FulfillmentNoteMeta"]: {
	__typename: "FulfillmentNoteMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	venues?: GraphQLTypes["FieldMeta"] | undefined,
	orderItems?: GraphQLTypes["FieldMeta"] | undefined,
	orders?: GraphQLTypes["FieldMeta"] | undefined,
	highlight?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	emoji?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unitPriceCents?: GraphQLTypes["OrderDirection"] | undefined,
	quantity?: GraphQLTypes["OrderDirection"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	order?: GraphQLTypes["OrderOrderBy"] | undefined,
	pickedAt?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined
};
	["OrderItemConnection"]: {
	__typename: "OrderItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderItemEdge"]>
};
	["OrderItemEdge"]: {
	__typename: "OrderItemEdge",
	node: GraphQLTypes["OrderItem"]
};
	["OrderConnection"]: {
	__typename: "OrderConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderEdge"]>
};
	["OrderEdge"]: {
	__typename: "OrderEdge",
	node: GraphQLTypes["Order"]
};
	["FulfillmentNoteOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	highlight?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	emoji?: GraphQLTypes["OrderDirection"] | undefined
};
	["VenueTimetableDaysByDateUniqueWhere"]: {
		date?: GraphQLTypes["Date"] | undefined
};
	["VenueTimetableDaysByShiftGroupsUniqueWhere"]: {
		shiftGroups?: GraphQLTypes["TimetableShiftGroupUniqueWhere"] | undefined
};
	["VenueTimetableDaysByNotesUniqueWhere"]: {
		notes?: GraphQLTypes["TimetableDayNoteUniqueWhere"] | undefined
};
	["TimetableDayNoteUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["VenueTimetableDaysByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["TimetableDayMetadataUniqueWhere"] | undefined
};
	["TimetableDayMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	day?: GraphQLTypes["TimetableDayUniqueWhere"] | undefined
};
	["TimetableDayUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	venue?: GraphQLTypes["VenueUniqueWhere"] | undefined,
	date?: GraphQLTypes["Date"] | undefined,
	shiftGroups?: GraphQLTypes["TimetableShiftGroupUniqueWhere"] | undefined,
	notes?: GraphQLTypes["TimetableDayNoteUniqueWhere"] | undefined,
	meta?: GraphQLTypes["TimetableDayMetadataUniqueWhere"] | undefined
};
	["VenueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	deadlineTemplate?: GraphQLTypes["DeadlineTemplateUniqueWhere"] | undefined,
	timetableDays?: GraphQLTypes["TimetableDayUniqueWhere"] | undefined,
	timetableTemplates?: GraphQLTypes["TimetableTemplateUniqueWhere"] | undefined,
	employeeRoles?: GraphQLTypes["VenueEmployeeRoleUniqueWhere"] | undefined,
	orders?: GraphQLTypes["OrderUniqueWhere"] | undefined,
	channels?: GraphQLTypes["ChannelUniqueWhere"] | undefined
};
	["DeadlineTemplateUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	venue?: GraphQLTypes["VenueUniqueWhere"] | undefined
};
	["TimetableTemplateUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["VenueEmployeeRoleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	venue?: GraphQLTypes["VenueUniqueWhere"] | undefined,
	role?: GraphQLTypes["TimetableEmployeeRoleUniqueWhere"] | undefined,
	employeePrivileges?: GraphQLTypes["TimetablesEmployeePrivilegesUniqueWhere"] | undefined
};
	["TimetableEmployeeRoleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shiftGroups?: GraphQLTypes["TimetableShiftGroupUniqueWhere"] | undefined,
	timetableTemplates?: GraphQLTypes["TimetableTemplateUniqueWhere"] | undefined
};
	["TimetablesEmployeePrivilegesUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	venueEmployeeRole?: GraphQLTypes["VenueEmployeeRoleUniqueWhere"] | undefined
};
	["ChannelUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: GraphQLTypes["ChannelCode"] | undefined
};
	["VenueEmployeeRolesByRoleUniqueWhere"]: {
		role?: GraphQLTypes["TimetableEmployeeRoleUniqueWhere"] | undefined
};
	["VenueEmployeeRolesByEmployeePrivilegesUniqueWhere"]: {
		employeePrivileges?: GraphQLTypes["TimetablesEmployeePrivilegesUniqueWhere"] | undefined
};
	["VenueOrdersBySeqUniqueWhere"]: {
		seq?: number | undefined
};
	["VenueOrdersByDoubleshotLegacyIdUniqueWhere"]: {
		doubleshotLegacyId?: string | undefined
};
	["VenueOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
		doubleShotLegacyNumber?: string | undefined
};
	["VenueOrdersByDeliveryUniqueWhere"]: {
		delivery?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined
};
	["VenueOrdersByItemsUniqueWhere"]: {
		items?: GraphQLTypes["OrderItemUniqueWhere"] | undefined
};
	["VenueOrdersByPaymentsUniqueWhere"]: {
		payments?: GraphQLTypes["OrderPaymentUniqueWhere"] | undefined
};
	["VenueChannelsByCodeUniqueWhere"]: {
		code?: GraphQLTypes["ChannelCode"] | undefined
};
	["TimetableDayConnection"]: {
	__typename: "TimetableDayConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableDayEdge"]>
};
	["TimetableDayEdge"]: {
	__typename: "TimetableDayEdge",
	node: GraphQLTypes["TimetableDay"]
};
	["StaffConnection"]: {
	__typename: "StaffConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StaffEdge"]>
};
	["StaffEdge"]: {
	__typename: "StaffEdge",
	node: GraphQLTypes["Staff"]
};
	["VenueEmployeeRoleConnection"]: {
	__typename: "VenueEmployeeRoleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VenueEmployeeRoleEdge"]>
};
	["VenueEmployeeRoleEdge"]: {
	__typename: "VenueEmployeeRoleEdge",
	node: GraphQLTypes["VenueEmployeeRole"]
};
	["FulfillmentNoteConnection"]: {
	__typename: "FulfillmentNoteConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FulfillmentNoteEdge"]>
};
	["FulfillmentNoteEdge"]: {
	__typename: "FulfillmentNoteEdge",
	node: GraphQLTypes["FulfillmentNote"]
};
	["UnassignReasonConnection"]: {
	__typename: "UnassignReasonConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["UnassignReasonEdge"]>
};
	["UnassignReasonEdge"]: {
	__typename: "UnassignReasonEdge",
	node: GraphQLTypes["UnassignReason"]
};
	["ChannelConnection"]: {
	__typename: "ChannelConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ChannelEdge"]>
};
	["ChannelEdge"]: {
	__typename: "ChannelEdge",
	node: GraphQLTypes["Channel"]
};
	["CustomerConnection"]: {
	__typename: "CustomerConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerEdge"]>
};
	["CustomerEdge"]: {
	__typename: "CustomerEdge",
	node: GraphQLTypes["Customer"]
};
	["OrderDelivery"]: {
	__typename: "OrderDelivery",
	_meta?: GraphQLTypes["OrderDeliveryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	priceCents: number,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["DeliveryMethod"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state: GraphQLTypes["OrderDeliveryState"],
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	deliveryWaypoint?: GraphQLTypes["DeliveryWaypoint"] | undefined,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	address?: GraphQLTypes["Address"] | undefined,
	trackingCode?: string | undefined
};
	["OrderDeliveryMeta"]: {
	__typename: "OrderDeliveryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	deliveredAt?: GraphQLTypes["FieldMeta"] | undefined,
	method?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	expectedAt?: GraphQLTypes["FieldMeta"] | undefined,
	state?: GraphQLTypes["FieldMeta"] | undefined,
	loadingDurationHours?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchAt?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	window?: GraphQLTypes["FieldMeta"] | undefined,
	expectedEndAt?: GraphQLTypes["FieldMeta"] | undefined,
	processingAt?: GraphQLTypes["FieldMeta"] | undefined,
	deliveringAt?: GraphQLTypes["FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryWaypoint?: GraphQLTypes["FieldMeta"] | undefined,
	prepareAt?: GraphQLTypes["FieldMeta"] | undefined,
	pickedAt?: GraphQLTypes["FieldMeta"] | undefined,
	address?: GraphQLTypes["FieldMeta"] | undefined,
	trackingCode?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryWaypoint"]: {
	__typename: "DeliveryWaypoint",
	_meta?: GraphQLTypes["DeliveryWaypointMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	estimatedArrival?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	route?: GraphQLTypes["DeliveryRoute"] | undefined,
	sortingOrder: number,
	delivery?: GraphQLTypes["OrderDelivery"] | undefined,
	estimatedDistance?: number | undefined,
	estimatedDuration?: number | undefined,
	originDeparture?: GraphQLTypes["DateTime"] | undefined,
	originAddress?: string | undefined,
	transitMode?: string | undefined
};
	["DeliveryWaypointMeta"]: {
	__typename: "DeliveryWaypointMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	estimatedArrival?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	route?: GraphQLTypes["FieldMeta"] | undefined,
	sortingOrder?: GraphQLTypes["FieldMeta"] | undefined,
	delivery?: GraphQLTypes["FieldMeta"] | undefined,
	estimatedDistance?: GraphQLTypes["FieldMeta"] | undefined,
	estimatedDuration?: GraphQLTypes["FieldMeta"] | undefined,
	originDeparture?: GraphQLTypes["FieldMeta"] | undefined,
	originAddress?: GraphQLTypes["FieldMeta"] | undefined,
	transitMode?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryRoute"]: {
	__typename: "DeliveryRoute",
	_meta?: GraphQLTypes["DeliveryRouteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	waypoints: Array<GraphQLTypes["DeliveryWaypoint"]>,
	dispatchAt: string,
	waypointsByDelivery?: GraphQLTypes["DeliveryWaypoint"] | undefined,
	paginateWaypoints: GraphQLTypes["DeliveryWaypointConnection"]
};
	["DeliveryRouteMeta"]: {
	__typename: "DeliveryRouteMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	waypoints?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchAt?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryRouteWaypointsByDeliveryUniqueWhere"]: {
		delivery?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined
};
	["DeliveryWaypointConnection"]: {
	__typename: "DeliveryWaypointConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryWaypointEdge"]>
};
	["DeliveryWaypointEdge"]: {
	__typename: "DeliveryWaypointEdge",
	node: GraphQLTypes["DeliveryWaypoint"]
};
	["OrderPayment"]: {
	__typename: "OrderPayment",
	_meta?: GraphQLTypes["OrderPaymentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	data?: GraphQLTypes["Json"] | undefined,
	paymentCents: number,
	method?: GraphQLTypes["PaymentMethod"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	state: GraphQLTypes["OrderPaymentState"],
	approvedAt?: GraphQLTypes["DateTime"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	walletType?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicReceiptUrl?: string | undefined,
	priceCents?: number | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	otherCurrencyPriceCents?: string | undefined
};
	["OrderPaymentMeta"]: {
	__typename: "OrderPaymentMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	data?: GraphQLTypes["FieldMeta"] | undefined,
	paymentCents?: GraphQLTypes["FieldMeta"] | undefined,
	method?: GraphQLTypes["FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["FieldMeta"] | undefined,
	state?: GraphQLTypes["FieldMeta"] | undefined,
	approvedAt?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	walletType?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	publicReceiptUrl?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	otherCurrencyPriceCents?: GraphQLTypes["FieldMeta"] | undefined
};
	["Json"]: any;
	["OrderPaymentOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	data?: GraphQLTypes["OrderDirection"] | undefined,
	paymentCents?: GraphQLTypes["OrderDirection"] | undefined,
	method?: GraphQLTypes["PaymentMethodOrderBy"] | undefined,
	failedAt?: GraphQLTypes["OrderDirection"] | undefined,
	state?: GraphQLTypes["OrderDirection"] | undefined,
	approvedAt?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderOrderBy"] | undefined,
	walletType?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	publicReceiptUrl?: GraphQLTypes["OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["OrderDirection"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	otherCurrencyPriceCents?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderPaymentConnection"]: {
	__typename: "OrderPaymentConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderPaymentEdge"]>
};
	["OrderPaymentEdge"]: {
	__typename: "OrderPaymentEdge",
	node: GraphQLTypes["OrderPayment"]
};
	["ProductMetadata"]: {
	__typename: "ProductMetadata",
	_meta?: GraphQLTypes["ProductMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	archived: boolean,
	available: boolean,
	availableQuantity: number,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductMetadataMeta"]: {
	__typename: "ProductMetadataMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	archived?: GraphQLTypes["FieldMeta"] | undefined,
	available?: GraphQLTypes["FieldMeta"] | undefined,
	availableQuantity?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductStocksByStoreUniqueWhere"]: {
		store?: GraphQLTypes["StoreUniqueWhere"] | undefined
};
	["ProductLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductLocalesByFeatureListUniqueWhere"]: {
		featureList?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["ProductStockConnection"]: {
	__typename: "ProductStockConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductStockEdge"]>
};
	["ProductStockEdge"]: {
	__typename: "ProductStockEdge",
	node: GraphQLTypes["ProductStock"]
};
	["CustomerName"]: {
	__typename: "CustomerName",
	_meta?: GraphQLTypes["CustomerNameMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	nominative: string,
	vocative?: string | undefined,
	customer?: GraphQLTypes["Customer"] | undefined
};
	["CustomerNameMeta"]: {
	__typename: "CustomerNameMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	nominative?: GraphQLTypes["FieldMeta"] | undefined,
	vocative?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerMetadata"]: {
	__typename: "CustomerMetadata",
	_meta?: GraphQLTypes["CustomerMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isBusiness: boolean,
	name?: string | undefined,
	fallbackName: string,
	remainingCreditsCents: number,
	ordersTotalCents: number,
	customerProfileUrl: string,
	customer?: GraphQLTypes["Customer"] | undefined
};
	["CustomerMetadataMeta"]: {
	__typename: "CustomerMetadataMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	isBusiness?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	fallbackName?: GraphQLTypes["FieldMeta"] | undefined,
	remainingCreditsCents?: GraphQLTypes["FieldMeta"] | undefined,
	ordersTotalCents?: GraphQLTypes["FieldMeta"] | undefined,
	customerProfileUrl?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerAddressesByBillingAddressOfOrdersUniqueWhere"]: {
		billingAddressOfOrders?: GraphQLTypes["OrderUniqueWhere"] | undefined
};
	["CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: {
		deliveryAddressOfOrderDeliveries?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined
};
	["CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: {
		defaultDeliveryAddressOfCustomer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["CustomerOrdersBySeqUniqueWhere"]: {
		seq?: number | undefined
};
	["CustomerOrdersByDoubleshotLegacyIdUniqueWhere"]: {
		doubleshotLegacyId?: string | undefined
};
	["CustomerOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
		doubleShotLegacyNumber?: string | undefined
};
	["CustomerOrdersByDeliveryUniqueWhere"]: {
		delivery?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined
};
	["CustomerOrdersByItemsUniqueWhere"]: {
		items?: GraphQLTypes["OrderItemUniqueWhere"] | undefined
};
	["CustomerOrdersByPaymentsUniqueWhere"]: {
		payments?: GraphQLTypes["OrderPaymentUniqueWhere"] | undefined
};
	["Country"]: {
	__typename: "Country",
	_meta?: GraphQLTypes["CountryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	order: number
};
	["CountryMeta"]: {
	__typename: "CountryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["AddressBillingAddressOfOrdersBySeqUniqueWhere"]: {
		seq?: number | undefined
};
	["AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere"]: {
		doubleshotLegacyId?: string | undefined
};
	["AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
		doubleShotLegacyNumber?: string | undefined
};
	["AddressBillingAddressOfOrdersByDeliveryUniqueWhere"]: {
		delivery?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined
};
	["AddressBillingAddressOfOrdersByItemsUniqueWhere"]: {
		items?: GraphQLTypes["OrderItemUniqueWhere"] | undefined
};
	["AddressBillingAddressOfOrdersByPaymentsUniqueWhere"]: {
		payments?: GraphQLTypes["OrderPaymentUniqueWhere"] | undefined
};
	["AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere"]: {
		trackingCode?: string | undefined
};
	["AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere"]: {
		order?: GraphQLTypes["OrderUniqueWhere"] | undefined
};
	["AddressDeliveryAddressOfOrderDeliveriesByDeliveryWaypointUniqueWhere"]: {
		deliveryWaypoint?: GraphQLTypes["DeliveryWaypointUniqueWhere"] | undefined
};
	["OrderDeliveryConnection"]: {
	__typename: "OrderDeliveryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderDeliveryEdge"]>
};
	["OrderDeliveryEdge"]: {
	__typename: "OrderDeliveryEdge",
	node: GraphQLTypes["OrderDelivery"]
};
	["CountryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined
};
	["CountryConnection"]: {
	__typename: "CountryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CountryEdge"]>
};
	["CountryEdge"]: {
	__typename: "CountryEdge",
	node: GraphQLTypes["Country"]
};
	["PaymentMethodUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined
};
	["VatRateUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	preset?: GraphQLTypes["VatRatePreset"] | undefined
};
	["VatRateConnection"]: {
	__typename: "VatRateConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VatRateEdge"]>
};
	["VatRateEdge"]: {
	__typename: "VatRateEdge",
	node: GraphQLTypes["VatRate"]
};
	["ProductPackingConnection"]: {
	__typename: "ProductPackingConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductPackingEdge"]>
};
	["ProductPackingEdge"]: {
	__typename: "ProductPackingEdge",
	node: GraphQLTypes["ProductPacking"]
};
	["ProductRecipeConnection"]: {
	__typename: "ProductRecipeConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductRecipeEdge"]>
};
	["ProductRecipeEdge"]: {
	__typename: "ProductRecipeEdge",
	node: GraphQLTypes["ProductRecipe"]
};
	["StoreConnection"]: {
	__typename: "StoreConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StoreEdge"]>
};
	["StoreEdge"]: {
	__typename: "StoreEdge",
	node: GraphQLTypes["Store"]
};
	["TagUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined
};
	["ImageConnection"]: {
	__typename: "ImageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageEdge"]>
};
	["ImageEdge"]: {
	__typename: "ImageEdge",
	node: GraphQLTypes["Image"]
};
	["ProductListConnection"]: {
	__typename: "ProductListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductListEdge"]>
};
	["ProductListEdge"]: {
	__typename: "ProductListEdge",
	node: GraphQLTypes["ProductList"]
};
	["ProductCategoryListConnection"]: {
	__typename: "ProductCategoryListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListEdge"]>
};
	["ProductCategoryListEdge"]: {
	__typename: "ProductCategoryListEdge",
	node: GraphQLTypes["ProductCategoryList"]
};
	["LocaleConnection"]: {
	__typename: "LocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LocaleEdge"]>
};
	["LocaleEdge"]: {
	__typename: "LocaleEdge",
	node: GraphQLTypes["Locale"]
};
	["CustomerNameConnection"]: {
	__typename: "CustomerNameConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerNameEdge"]>
};
	["CustomerNameEdge"]: {
	__typename: "CustomerNameEdge",
	node: GraphQLTypes["CustomerName"]
};
	["ProductIngredientItemQuantityConnection"]: {
	__typename: "ProductIngredientItemQuantityConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientItemQuantityEdge"]>
};
	["ProductIngredientItemQuantityEdge"]: {
	__typename: "ProductIngredientItemQuantityEdge",
	node: GraphQLTypes["ProductIngredientItemQuantity"]
};
	["ProductIngredientItemQuantityUnitUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined
};
	["ProductIngredientItemQuantityUnitConnection"]: {
	__typename: "ProductIngredientItemQuantityUnitConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientItemQuantityUnitEdge"]>
};
	["ProductIngredientItemQuantityUnitEdge"]: {
	__typename: "ProductIngredientItemQuantityUnitEdge",
	node: GraphQLTypes["ProductIngredientItemQuantityUnit"]
};
	["ProductIngredientRating"]: {
	__typename: "ProductIngredientRating",
	_meta?: GraphQLTypes["ProductIngredientRatingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	name: string,
	icon?: GraphQLTypes["Image"] | undefined
};
	["ProductIngredientRatingMeta"]: {
	__typename: "ProductIngredientRatingMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientRatingUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProductIngredientRatingWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	icon?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientRatingWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientRatingWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientRatingWhere"] | undefined
};
	["ProductIngredientRatingOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	icon?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ProductIngredientRatingConnection"]: {
	__typename: "ProductIngredientRatingConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientRatingEdge"]>
};
	["ProductIngredientRatingEdge"]: {
	__typename: "ProductIngredientRatingEdge",
	node: GraphQLTypes["ProductIngredientRating"]
};
	["BlogConnection"]: {
	__typename: "BlogConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogEdge"]>
};
	["BlogEdge"]: {
	__typename: "BlogEdge",
	node: GraphQLTypes["Blog"]
};
	["BlogPostConnection"]: {
	__typename: "BlogPostConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostEdge"]>
};
	["BlogPostEdge"]: {
	__typename: "BlogPostEdge",
	node: GraphQLTypes["BlogPost"]
};
	["ColorPalleteUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ColorPalleteConnection"]: {
	__typename: "ColorPalleteConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ColorPalleteEdge"]>
};
	["ColorPalleteEdge"]: {
	__typename: "ColorPalleteEdge",
	node: GraphQLTypes["ColorPallete"]
};
	["ContentConnection"]: {
	__typename: "ContentConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentEdge"]>
};
	["ContentEdge"]: {
	__typename: "ContentEdge",
	node: GraphQLTypes["Content"]
};
	["BlogPostListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["BlogPostListItemUniqueWhere"] | undefined
};
	["BlogPostListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["BlogPostListConnection"]: {
	__typename: "BlogPostListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostListEdge"]>
};
	["BlogPostListEdge"]: {
	__typename: "BlogPostListEdge",
	node: GraphQLTypes["BlogPostList"]
};
	["LinkListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["LinkListItemUniqueWhere"] | undefined
};
	["LinkListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["LinkListConnection"]: {
	__typename: "LinkListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkListEdge"]>
};
	["LinkListEdge"]: {
	__typename: "LinkListEdge",
	node: GraphQLTypes["LinkList"]
};
	["CoverConnection"]: {
	__typename: "CoverConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CoverEdge"]>
};
	["CoverEdge"]: {
	__typename: "CoverEdge",
	node: GraphQLTypes["Cover"]
};
	["LinkConnection"]: {
	__typename: "LinkConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkEdge"]>
};
	["LinkEdge"]: {
	__typename: "LinkEdge",
	node: GraphQLTypes["Link"]
};
	["LinkableConnection"]: {
	__typename: "LinkableConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkableEdge"]>
};
	["LinkableEdge"]: {
	__typename: "LinkableEdge",
	node: GraphQLTypes["Linkable"]
};
	["MediumUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["MediumConnection"]: {
	__typename: "MediumConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MediumEdge"]>
};
	["MediumEdge"]: {
	__typename: "MediumEdge",
	node: GraphQLTypes["Medium"]
};
	["VideoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["VideoConnection"]: {
	__typename: "VideoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VideoEdge"]>
};
	["VideoEdge"]: {
	__typename: "VideoEdge",
	node: GraphQLTypes["Video"]
};
	["SeoConnection"]: {
	__typename: "SeoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SeoEdge"]>
};
	["SeoEdge"]: {
	__typename: "SeoEdge",
	node: GraphQLTypes["Seo"]
};
	["TranslationDomainConnection"]: {
	__typename: "TranslationDomainConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationDomainEdge"]>
};
	["TranslationDomainEdge"]: {
	__typename: "TranslationDomainEdge",
	node: GraphQLTypes["TranslationDomain"]
};
	["TranslationCataloguesIdentifierConnection"]: {
	__typename: "TranslationCataloguesIdentifierConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCataloguesIdentifierEdge"]>
};
	["TranslationCataloguesIdentifierEdge"]: {
	__typename: "TranslationCataloguesIdentifierEdge",
	node: GraphQLTypes["TranslationCataloguesIdentifier"]
};
	["SocialsAndAppsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["SocialsAndAppsConnection"]: {
	__typename: "SocialsAndAppsConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SocialsAndAppsEdge"]>
};
	["SocialsAndAppsEdge"]: {
	__typename: "SocialsAndAppsEdge",
	node: GraphQLTypes["SocialsAndApps"]
};
	["ProductGridUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	categories?: GraphQLTypes["ProductGridCategoryUniqueWhere"] | undefined
};
	["ProductGridCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ProductGridItemUniqueWhere"] | undefined,
	images?: GraphQLTypes["ImageListUniqueWhere"] | undefined
};
	["ProductGridConnection"]: {
	__typename: "ProductGridConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridEdge"]>
};
	["ProductGridEdge"]: {
	__typename: "ProductGridEdge",
	node: GraphQLTypes["ProductGrid"]
};
	["GalleryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["GalleryItemUniqueWhere"] | undefined
};
	["GalleryItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["GalleryConnection"]: {
	__typename: "GalleryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GalleryEdge"]>
};
	["GalleryEdge"]: {
	__typename: "GalleryEdge",
	node: GraphQLTypes["Gallery"]
};
	["HeroUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["HeroConnection"]: {
	__typename: "HeroConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HeroEdge"]>
};
	["HeroEdge"]: {
	__typename: "HeroEdge",
	node: GraphQLTypes["Hero"]
};
	["PageConnection"]: {
	__typename: "PageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PageEdge"]>
};
	["PageEdge"]: {
	__typename: "PageEdge",
	node: GraphQLTypes["Page"]
};
	["DeadlineTemplateConnection"]: {
	__typename: "DeadlineTemplateConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeadlineTemplateEdge"]>
};
	["DeadlineTemplateEdge"]: {
	__typename: "DeadlineTemplateEdge",
	node: GraphQLTypes["DeadlineTemplate"]
};
	["EmployeeQueueConnection"]: {
	__typename: "EmployeeQueueConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["EmployeeQueueEdge"]>
};
	["EmployeeQueueEdge"]: {
	__typename: "EmployeeQueueEdge",
	node: GraphQLTypes["EmployeeQueue"]
};
	["_ValidationResult"]: {
	__typename: "_ValidationResult",
	valid: boolean,
	errors: Array<GraphQLTypes["_ValidationError"]>
};
	["_ValidationError"]: {
	__typename: "_ValidationError",
	path: Array<GraphQLTypes["_PathFragment"]>,
	message: GraphQLTypes["_ValidationMessage"]
};
	["_PathFragment"]:{
        	__typename:"_FieldPathFragment" | "_IndexPathFragment"
        	['...on _FieldPathFragment']: '__union' & GraphQLTypes["_FieldPathFragment"];
	['...on _IndexPathFragment']: '__union' & GraphQLTypes["_IndexPathFragment"];
};
	["_FieldPathFragment"]: {
	__typename: "_FieldPathFragment",
	field: string
};
	["_IndexPathFragment"]: {
	__typename: "_IndexPathFragment",
	index: number,
	alias?: string | undefined
};
	["_ValidationMessage"]: {
	__typename: "_ValidationMessage",
	text: string
};
	["EmployeeQueueCreateInput"]: {
		items?: Array<GraphQLTypes["EmployeeQueueCreateItemsEntityRelationInput"]> | undefined,
	contemberBugFix?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["EmployeeQueueCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["EmployeeQueueItemWithoutQueueCreateInput"] | undefined,
	alias?: string | undefined
};
	["EmployeeQueueItemWithoutQueueCreateInput"]: {
		start?: string | undefined,
	end?: string | undefined,
	shiftsProfile?: GraphQLTypes["EmployeeQueueItemCreateShiftsProfileEntityRelationInput"] | undefined,
	unassignInfo?: GraphQLTypes["EmployeeQueueItemCreateUnassignInfoEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["EmployeeQueueItemCreateShiftsProfileEntityRelationInput"]: {
		connect?: GraphQLTypes["ShiftsProfileUniqueWhere"] | undefined
};
	["EmployeeQueueItemCreateUnassignInfoEntityRelationInput"]: {
		connect?: GraphQLTypes["UnassignedShiftInfoUniqueWhere"] | undefined,
	create?: GraphQLTypes["UnassignedShiftInfoWithoutQueueItemCreateInput"] | undefined
};
	["UnassignedShiftInfoWithoutQueueItemCreateInput"]: {
		hoursMissed?: number | undefined,
	legacyReason?: GraphQLTypes["UnassignedInfoReason"] | undefined,
	reason?: GraphQLTypes["UnassignedShiftInfoCreateReasonEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["UnassignedShiftInfoCreateReasonEntityRelationInput"]: {
		connect?: GraphQLTypes["UnassignReasonUniqueWhere"] | undefined
};
	["UnassignReasonUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["EmployeeQueueUpdateInput"]: {
		items?: Array<GraphQLTypes["EmployeeQueueUpdateItemsEntityRelationInput"]> | undefined,
	contemberBugFix?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["EmployeeQueueUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["EmployeeQueueItemWithoutQueueCreateInput"] | undefined,
	update?: GraphQLTypes["EmployeeQueueUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["EmployeeQueueUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["EmployeeQueueUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["EmployeeQueueItemWithoutQueueUpdateInput"] | undefined
};
	["EmployeeQueueItemWithoutQueueUpdateInput"]: {
		start?: string | undefined,
	end?: string | undefined,
	shiftsProfile?: GraphQLTypes["EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"] | undefined,
	shift?: GraphQLTypes["EmployeeQueueItemUpdateShiftEntityRelationInput"] | undefined,
	unassignInfo?: GraphQLTypes["EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"]: {
		update?: GraphQLTypes["ShiftsProfileWithoutQueueItemsUpdateInput"] | undefined,
	connect?: GraphQLTypes["ShiftsProfileUniqueWhere"] | undefined
};
	["ShiftsProfileWithoutQueueItemsUpdateInput"]: {
		shifts?: Array<GraphQLTypes["ShiftsProfileUpdateShiftsEntityRelationInput"]> | undefined,
	allowSelfUnassigning?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["ShiftsProfileUpdateShiftsEntityRelationInput"]: {
		update?: GraphQLTypes["ShiftsProfileUpdateShiftsRelationInput"] | undefined,
	connect?: GraphQLTypes["TimetableShiftUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["TimetableShiftUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["ShiftsProfileUpdateShiftsRelationInput"]: {
		by?: GraphQLTypes["TimetableShiftUniqueWhere"] | undefined,
	data?: GraphQLTypes["TimetableShiftWithoutShiftsProfileUpdateInput"] | undefined
};
	["TimetableShiftWithoutShiftsProfileUpdateInput"]: {
		locked?: boolean | undefined,
	order?: number | undefined,
	assigned?: GraphQLTypes["Date"] | undefined,
	group?: GraphQLTypes["TimetableShiftUpdateGroupEntityRelationInput"] | undefined,
	queueItem?: GraphQLTypes["TimetableShiftUpdateQueueItemEntityRelationInput"] | undefined,
	isOvertime?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["TimetableShiftUpdateGroupEntityRelationInput"]: {
		connect?: GraphQLTypes["TimetableShiftGroupUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["TimetableShiftUpdateQueueItemEntityRelationInput"]: {
		create?: GraphQLTypes["EmployeeQueueItemWithoutShiftCreateInput"] | undefined,
	update?: GraphQLTypes["EmployeeQueueItemWithoutShiftUpdateInput"] | undefined,
	upsert?: GraphQLTypes["TimetableShiftUpsertQueueItemRelationInput"] | undefined,
	connect?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["EmployeeQueueItemWithoutShiftCreateInput"]: {
		start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["EmployeeQueueItemCreateQueueEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["EmployeeQueueItemCreateShiftsProfileEntityRelationInput"] | undefined,
	unassignInfo?: GraphQLTypes["EmployeeQueueItemCreateUnassignInfoEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["EmployeeQueueItemCreateQueueEntityRelationInput"]: {
		connect?: GraphQLTypes["EmployeeQueueUniqueWhere"] | undefined,
	create?: GraphQLTypes["EmployeeQueueWithoutItemsCreateInput"] | undefined
};
	["EmployeeQueueWithoutItemsCreateInput"]: {
		contemberBugFix?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["EmployeeQueueItemWithoutShiftUpdateInput"]: {
		start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["EmployeeQueueItemUpdateQueueEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"] | undefined,
	unassignInfo?: GraphQLTypes["EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["EmployeeQueueItemUpdateQueueEntityRelationInput"]: {
		create?: GraphQLTypes["EmployeeQueueWithoutItemsCreateInput"] | undefined,
	update?: GraphQLTypes["EmployeeQueueWithoutItemsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["EmployeeQueueItemUpsertQueueRelationInput"] | undefined,
	connect?: GraphQLTypes["EmployeeQueueUniqueWhere"] | undefined
};
	["EmployeeQueueWithoutItemsUpdateInput"]: {
		contemberBugFix?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["EmployeeQueueItemUpsertQueueRelationInput"]: {
		update?: GraphQLTypes["EmployeeQueueWithoutItemsUpdateInput"] | undefined,
	create?: GraphQLTypes["EmployeeQueueWithoutItemsCreateInput"] | undefined
};
	["EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"]: {
		create?: GraphQLTypes["UnassignedShiftInfoWithoutQueueItemCreateInput"] | undefined,
	update?: GraphQLTypes["UnassignedShiftInfoWithoutQueueItemUpdateInput"] | undefined,
	upsert?: GraphQLTypes["EmployeeQueueItemUpsertUnassignInfoRelationInput"] | undefined,
	connect?: GraphQLTypes["UnassignedShiftInfoUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["UnassignedShiftInfoWithoutQueueItemUpdateInput"]: {
		hoursMissed?: number | undefined,
	legacyReason?: GraphQLTypes["UnassignedInfoReason"] | undefined,
	reason?: GraphQLTypes["UnassignedShiftInfoUpdateReasonEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["UnassignedShiftInfoUpdateReasonEntityRelationInput"]: {
		connect?: GraphQLTypes["UnassignReasonUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["EmployeeQueueItemUpsertUnassignInfoRelationInput"]: {
		update?: GraphQLTypes["UnassignedShiftInfoWithoutQueueItemUpdateInput"] | undefined,
	create?: GraphQLTypes["UnassignedShiftInfoWithoutQueueItemCreateInput"] | undefined
};
	["TimetableShiftUpsertQueueItemRelationInput"]: {
		update?: GraphQLTypes["EmployeeQueueItemWithoutShiftUpdateInput"] | undefined,
	create?: GraphQLTypes["EmployeeQueueItemWithoutShiftCreateInput"] | undefined
};
	["EmployeeQueueItemUpdateShiftEntityRelationInput"]: {
		update?: GraphQLTypes["TimetableShiftWithoutQueueItemUpdateInput"] | undefined,
	connect?: GraphQLTypes["TimetableShiftUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["TimetableShiftWithoutQueueItemUpdateInput"]: {
		locked?: boolean | undefined,
	order?: number | undefined,
	assigned?: GraphQLTypes["Date"] | undefined,
	group?: GraphQLTypes["TimetableShiftUpdateGroupEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["TimetableShiftUpdateShiftsProfileEntityRelationInput"] | undefined,
	isOvertime?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["TimetableShiftUpdateShiftsProfileEntityRelationInput"]: {
		update?: GraphQLTypes["ShiftsProfileWithoutShiftsUpdateInput"] | undefined,
	connect?: GraphQLTypes["ShiftsProfileUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["ShiftsProfileWithoutShiftsUpdateInput"]: {
		queueItems?: Array<GraphQLTypes["ShiftsProfileUpdateQueueItemsEntityRelationInput"]> | undefined,
	allowSelfUnassigning?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["ShiftsProfileUpdateQueueItemsEntityRelationInput"]: {
		create?: GraphQLTypes["EmployeeQueueItemWithoutShiftsProfileCreateInput"] | undefined,
	update?: GraphQLTypes["ShiftsProfileUpdateQueueItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["ShiftsProfileUpsertQueueItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["EmployeeQueueItemWithoutShiftsProfileCreateInput"]: {
		start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["EmployeeQueueItemCreateQueueEntityRelationInput"] | undefined,
	unassignInfo?: GraphQLTypes["EmployeeQueueItemCreateUnassignInfoEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["ShiftsProfileUpdateQueueItemsRelationInput"]: {
		by?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["EmployeeQueueItemWithoutShiftsProfileUpdateInput"] | undefined
};
	["EmployeeQueueItemWithoutShiftsProfileUpdateInput"]: {
		start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["EmployeeQueueItemUpdateQueueEntityRelationInput"] | undefined,
	shift?: GraphQLTypes["EmployeeQueueItemUpdateShiftEntityRelationInput"] | undefined,
	unassignInfo?: GraphQLTypes["EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["ShiftsProfileUpsertQueueItemsRelationInput"]: {
		by?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["EmployeeQueueItemWithoutShiftsProfileUpdateInput"] | undefined,
	create?: GraphQLTypes["EmployeeQueueItemWithoutShiftsProfileCreateInput"] | undefined
};
	["EmployeeQueueUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["EmployeeQueueItemWithoutQueueUpdateInput"] | undefined,
	create?: GraphQLTypes["EmployeeQueueItemWithoutQueueCreateInput"] | undefined
};
	["TimetableShiftUpdateInput"]: {
		locked?: boolean | undefined,
	order?: number | undefined,
	assigned?: GraphQLTypes["Date"] | undefined,
	group?: GraphQLTypes["TimetableShiftUpdateGroupEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["TimetableShiftUpdateShiftsProfileEntityRelationInput"] | undefined,
	queueItem?: GraphQLTypes["TimetableShiftUpdateQueueItemEntityRelationInput"] | undefined,
	isOvertime?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["TimetableShiftPositionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shiftGroups?: GraphQLTypes["TimetableShiftGroupUniqueWhere"] | undefined,
	timetableTemplates?: GraphQLTypes["TimetableTemplateUniqueWhere"] | undefined
};
	["TimetableShiftPositionConnection"]: {
	__typename: "TimetableShiftPositionConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableShiftPositionEdge"]>
};
	["TimetableShiftPositionEdge"]: {
	__typename: "TimetableShiftPositionEdge",
	node: GraphQLTypes["TimetableShiftPosition"]
};
	["EmployeeQueueItemCreateInput"]: {
		start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["EmployeeQueueItemCreateQueueEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["EmployeeQueueItemCreateShiftsProfileEntityRelationInput"] | undefined,
	unassignInfo?: GraphQLTypes["EmployeeQueueItemCreateUnassignInfoEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["EmployeeQueueItemUpdateInput"]: {
		start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["EmployeeQueueItemUpdateQueueEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"] | undefined,
	shift?: GraphQLTypes["EmployeeQueueItemUpdateShiftEntityRelationInput"] | undefined,
	unassignInfo?: GraphQLTypes["EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["ProductBannerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	button?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["ProductBannerConnection"]: {
	__typename: "ProductBannerConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerEdge"]>
};
	["ProductBannerEdge"]: {
	__typename: "ProductBannerEdge",
	node: GraphQLTypes["ProductBanner"]
};
	["ProductBannerListConnection"]: {
	__typename: "ProductBannerListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerListEdge"]>
};
	["ProductBannerListEdge"]: {
	__typename: "ProductBannerListEdge",
	node: GraphQLTypes["ProductBannerList"]
};
	["ShiftsProfileUpdateInput"]: {
		queueItems?: Array<GraphQLTypes["ShiftsProfileUpdateQueueItemsEntityRelationInput"]> | undefined,
	shifts?: Array<GraphQLTypes["ShiftsProfileUpdateShiftsEntityRelationInput"]> | undefined,
	allowSelfUnassigning?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["DeliveryRouteUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	waypoints?: GraphQLTypes["DeliveryWaypointUniqueWhere"] | undefined
};
	["DeliveryRouteConnection"]: {
	__typename: "DeliveryRouteConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryRouteEdge"]>
};
	["DeliveryRouteEdge"]: {
	__typename: "DeliveryRouteEdge",
	node: GraphQLTypes["DeliveryRoute"]
};
	["WeekHoursUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["WeekHoursConnection"]: {
	__typename: "WeekHoursConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["WeekHoursEdge"]>
};
	["WeekHoursEdge"]: {
	__typename: "WeekHoursEdge",
	node: GraphQLTypes["WeekHours"]
};
	["PreparedIngredientQuantity"]: {
	__typename: "PreparedIngredientQuantity",
	_meta?: GraphQLTypes["PreparedIngredientQuantityMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	date: GraphQLTypes["Date"],
	quantity?: number | undefined,
	usedQuantity?: number | undefined,
	ingredient?: GraphQLTypes["ProductIngredient"] | undefined,
	unit?: GraphQLTypes["ProductIngredientItemQuantityUnit"] | undefined
};
	["PreparedIngredientQuantityMeta"]: {
	__typename: "PreparedIngredientQuantityMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	date?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	usedQuantity?: GraphQLTypes["FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["FieldMeta"] | undefined,
	unit?: GraphQLTypes["FieldMeta"] | undefined
};
	["PreparedIngredientQuantityUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	date?: GraphQLTypes["Date"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredientUniqueWhere"] | undefined,
	unit?: GraphQLTypes["ProductIngredientItemQuantityUnitUniqueWhere"] | undefined
};
	["PreparedIngredientQuantityWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	date?: GraphQLTypes["DateCondition"] | undefined,
	quantity?: GraphQLTypes["FloatCondition"] | undefined,
	usedQuantity?: GraphQLTypes["FloatCondition"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredientWhere"] | undefined,
	unit?: GraphQLTypes["ProductIngredientItemQuantityUnitWhere"] | undefined,
	and?: Array<GraphQLTypes["PreparedIngredientQuantityWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PreparedIngredientQuantityWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PreparedIngredientQuantityWhere"] | undefined
};
	["PreparedIngredientQuantityOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	date?: GraphQLTypes["OrderDirection"] | undefined,
	quantity?: GraphQLTypes["OrderDirection"] | undefined,
	usedQuantity?: GraphQLTypes["OrderDirection"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredientOrderBy"] | undefined,
	unit?: GraphQLTypes["ProductIngredientItemQuantityUnitOrderBy"] | undefined
};
	["PreparedIngredientQuantityConnection"]: {
	__typename: "PreparedIngredientQuantityConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PreparedIngredientQuantityEdge"]>
};
	["PreparedIngredientQuantityEdge"]: {
	__typename: "PreparedIngredientQuantityEdge",
	node: GraphQLTypes["PreparedIngredientQuantity"]
};
	["PreparedIngredientQuantityCreateInput"]: {
		date?: GraphQLTypes["Date"] | undefined,
	quantity?: number | undefined,
	usedQuantity?: number | undefined,
	ingredient?: GraphQLTypes["PreparedIngredientQuantityCreateIngredientEntityRelationInput"] | undefined,
	unit?: GraphQLTypes["PreparedIngredientQuantityCreateUnitEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["PreparedIngredientQuantityCreateIngredientEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductIngredientUniqueWhere"] | undefined
};
	["PreparedIngredientQuantityCreateUnitEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductIngredientItemQuantityUnitUniqueWhere"] | undefined
};
	["PreparedIngredientQuantityUpdateInput"]: {
		date?: GraphQLTypes["Date"] | undefined,
	quantity?: number | undefined,
	usedQuantity?: number | undefined,
	ingredient?: GraphQLTypes["PreparedIngredientQuantityUpdateIngredientEntityRelationInput"] | undefined,
	unit?: GraphQLTypes["PreparedIngredientQuantityUpdateUnitEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["PreparedIngredientQuantityUpdateIngredientEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductIngredientUniqueWhere"] | undefined
};
	["PreparedIngredientQuantityUpdateUnitEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductIngredientItemQuantityUnitUniqueWhere"] | undefined
};
	["PreparedRecipeQuantity"]: {
	__typename: "PreparedRecipeQuantity",
	_meta?: GraphQLTypes["PreparedRecipeQuantityMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	date: GraphQLTypes["Date"],
	quantity?: number | undefined,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	usedQuantity?: number | undefined
};
	["PreparedRecipeQuantityMeta"]: {
	__typename: "PreparedRecipeQuantityMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	date?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	usedQuantity?: GraphQLTypes["FieldMeta"] | undefined
};
	["PreparedRecipeQuantityUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	date?: GraphQLTypes["Date"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeUniqueWhere"] | undefined
};
	["PreparedRecipeQuantityWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	date?: GraphQLTypes["DateCondition"] | undefined,
	quantity?: GraphQLTypes["FloatCondition"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeWhere"] | undefined,
	usedQuantity?: GraphQLTypes["FloatCondition"] | undefined,
	and?: Array<GraphQLTypes["PreparedRecipeQuantityWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PreparedRecipeQuantityWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PreparedRecipeQuantityWhere"] | undefined
};
	["PreparedRecipeQuantityOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	date?: GraphQLTypes["OrderDirection"] | undefined,
	quantity?: GraphQLTypes["OrderDirection"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeOrderBy"] | undefined,
	usedQuantity?: GraphQLTypes["OrderDirection"] | undefined
};
	["PreparedRecipeQuantityConnection"]: {
	__typename: "PreparedRecipeQuantityConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PreparedRecipeQuantityEdge"]>
};
	["PreparedRecipeQuantityEdge"]: {
	__typename: "PreparedRecipeQuantityEdge",
	node: GraphQLTypes["PreparedRecipeQuantity"]
};
	["PreparedRecipeQuantityCreateInput"]: {
		date?: GraphQLTypes["Date"] | undefined,
	quantity?: number | undefined,
	recipe?: GraphQLTypes["PreparedRecipeQuantityCreateRecipeEntityRelationInput"] | undefined,
	usedQuantity?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["PreparedRecipeQuantityCreateRecipeEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductRecipeUniqueWhere"] | undefined
};
	["PreparedRecipeQuantityUpdateInput"]: {
		date?: GraphQLTypes["Date"] | undefined,
	quantity?: number | undefined,
	recipe?: GraphQLTypes["PreparedRecipeQuantityUpdateRecipeEntityRelationInput"] | undefined,
	usedQuantity?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["PreparedRecipeQuantityUpdateRecipeEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductRecipeUniqueWhere"] | undefined
};
	["PreparedPackingQuantityCreateInput"]: {
		date?: GraphQLTypes["Date"] | undefined,
	quantity?: number | undefined,
	recipe?: GraphQLTypes["PreparedPackingQuantityCreateRecipeEntityRelationInput"] | undefined,
	packing?: GraphQLTypes["PreparedPackingQuantityCreatePackingEntityRelationInput"] | undefined,
	packedInCardboard?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["PreparedPackingQuantityCreateRecipeEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductRecipeUniqueWhere"] | undefined
};
	["PreparedPackingQuantityCreatePackingEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductPackingUniqueWhere"] | undefined
};
	["PreparedPackingQuantityUpdateInput"]: {
		date?: GraphQLTypes["Date"] | undefined,
	quantity?: number | undefined,
	recipe?: GraphQLTypes["PreparedPackingQuantityUpdateRecipeEntityRelationInput"] | undefined,
	packing?: GraphQLTypes["PreparedPackingQuantityUpdatePackingEntityRelationInput"] | undefined,
	packedInCardboard?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["PreparedPackingQuantityUpdateRecipeEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductRecipeUniqueWhere"] | undefined
};
	["PreparedPackingQuantityUpdatePackingEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductPackingUniqueWhere"] | undefined
};
	["DeliveryZoneUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	addresses?: GraphQLTypes["AddressUniqueWhere"] | undefined
};
	["DeliveryTimelinePresetConnection"]: {
	__typename: "DeliveryTimelinePresetConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryTimelinePresetEdge"]>
};
	["DeliveryTimelinePresetEdge"]: {
	__typename: "DeliveryTimelinePresetEdge",
	node: GraphQLTypes["DeliveryTimelinePreset"]
};
	["SuppliesPurchase"]: {
	__typename: "SuppliesPurchase",
	_meta?: GraphQLTypes["SuppliesPurchaseMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	items: Array<GraphQLTypes["SuppliesPurchaseItem"]>,
	supplier?: GraphQLTypes["IngredientSupplier"] | undefined,
	requestedDeliveryDate?: GraphQLTypes["Date"] | undefined,
	paginateItems: GraphQLTypes["SuppliesPurchaseItemConnection"]
};
	["SuppliesPurchaseMeta"]: {
	__typename: "SuppliesPurchaseMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	supplier?: GraphQLTypes["FieldMeta"] | undefined,
	requestedDeliveryDate?: GraphQLTypes["FieldMeta"] | undefined
};
	["SuppliesPurchaseItem"]: {
	__typename: "SuppliesPurchaseItem",
	_meta?: GraphQLTypes["SuppliesPurchaseItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	amount?: number | undefined,
	list?: GraphQLTypes["SuppliesPurchase"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredient"] | undefined,
	pricePaidCents?: number | undefined,
	preferredUnit?: GraphQLTypes["ProductIngredientItemQuantityUnit"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	purchasedAt?: GraphQLTypes["DateTime"] | undefined,
	purchasedBy?: GraphQLTypes["Staff"] | undefined
};
	["SuppliesPurchaseItemMeta"]: {
	__typename: "SuppliesPurchaseItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	amount?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["FieldMeta"] | undefined,
	pricePaidCents?: GraphQLTypes["FieldMeta"] | undefined,
	preferredUnit?: GraphQLTypes["FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["FieldMeta"] | undefined,
	purchasedAt?: GraphQLTypes["FieldMeta"] | undefined,
	purchasedBy?: GraphQLTypes["FieldMeta"] | undefined
};
	["SuppliesPurchaseWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	items?: GraphQLTypes["SuppliesPurchaseItemWhere"] | undefined,
	supplier?: GraphQLTypes["IngredientSupplierWhere"] | undefined,
	requestedDeliveryDate?: GraphQLTypes["DateCondition"] | undefined,
	and?: Array<GraphQLTypes["SuppliesPurchaseWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SuppliesPurchaseWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SuppliesPurchaseWhere"] | undefined
};
	["SuppliesPurchaseItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	amount?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["SuppliesPurchaseWhere"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredientWhere"] | undefined,
	pricePaidCents?: GraphQLTypes["IntCondition"] | undefined,
	preferredUnit?: GraphQLTypes["ProductIngredientItemQuantityUnitWhere"] | undefined,
	deletedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	purchasedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	purchasedBy?: GraphQLTypes["StaffWhere"] | undefined,
	and?: Array<GraphQLTypes["SuppliesPurchaseItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SuppliesPurchaseItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SuppliesPurchaseItemWhere"] | undefined
};
	["SuppliesPurchaseItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	amount?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["SuppliesPurchaseOrderBy"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredientOrderBy"] | undefined,
	pricePaidCents?: GraphQLTypes["OrderDirection"] | undefined,
	preferredUnit?: GraphQLTypes["ProductIngredientItemQuantityUnitOrderBy"] | undefined,
	deletedAt?: GraphQLTypes["OrderDirection"] | undefined,
	purchasedAt?: GraphQLTypes["OrderDirection"] | undefined,
	purchasedBy?: GraphQLTypes["StaffOrderBy"] | undefined
};
	["SuppliesPurchaseOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	supplier?: GraphQLTypes["IngredientSupplierOrderBy"] | undefined,
	requestedDeliveryDate?: GraphQLTypes["OrderDirection"] | undefined
};
	["SuppliesPurchaseItemConnection"]: {
	__typename: "SuppliesPurchaseItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SuppliesPurchaseItemEdge"]>
};
	["SuppliesPurchaseItemEdge"]: {
	__typename: "SuppliesPurchaseItemEdge",
	node: GraphQLTypes["SuppliesPurchaseItem"]
};
	["SuppliesPurchaseUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	supplier?: GraphQLTypes["IngredientSupplierUniqueWhere"] | undefined,
	items?: GraphQLTypes["SuppliesPurchaseItemUniqueWhere"] | undefined
};
	["SuppliesPurchaseItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["SuppliesPurchaseConnection"]: {
	__typename: "SuppliesPurchaseConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SuppliesPurchaseEdge"]>
};
	["SuppliesPurchaseEdge"]: {
	__typename: "SuppliesPurchaseEdge",
	node: GraphQLTypes["SuppliesPurchase"]
};
	["SuppliesPurchaseCreateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	items?: Array<GraphQLTypes["SuppliesPurchaseCreateItemsEntityRelationInput"]> | undefined,
	supplier?: GraphQLTypes["SuppliesPurchaseCreateSupplierEntityRelationInput"] | undefined,
	requestedDeliveryDate?: GraphQLTypes["Date"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["SuppliesPurchaseCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["SuppliesPurchaseItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["SuppliesPurchaseItemWithoutListCreateInput"] | undefined,
	alias?: string | undefined
};
	["SuppliesPurchaseItemWithoutListCreateInput"]: {
		amount?: number | undefined,
	ingredient?: GraphQLTypes["SuppliesPurchaseItemCreateIngredientEntityRelationInput"] | undefined,
	pricePaidCents?: number | undefined,
	preferredUnit?: GraphQLTypes["SuppliesPurchaseItemCreatePreferredUnitEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	purchasedAt?: GraphQLTypes["DateTime"] | undefined,
	purchasedBy?: GraphQLTypes["SuppliesPurchaseItemCreatePurchasedByEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["SuppliesPurchaseItemCreateIngredientEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductIngredientUniqueWhere"] | undefined
};
	["SuppliesPurchaseItemCreatePreferredUnitEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductIngredientItemQuantityUnitUniqueWhere"] | undefined
};
	["SuppliesPurchaseItemCreatePurchasedByEntityRelationInput"]: {
		connect?: GraphQLTypes["StaffUniqueWhere"] | undefined
};
	["SuppliesPurchaseCreateSupplierEntityRelationInput"]: {
		connect?: GraphQLTypes["IngredientSupplierUniqueWhere"] | undefined
};
	["SuppliesPurchaseUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	items?: Array<GraphQLTypes["SuppliesPurchaseUpdateItemsEntityRelationInput"]> | undefined,
	supplier?: GraphQLTypes["SuppliesPurchaseUpdateSupplierEntityRelationInput"] | undefined,
	requestedDeliveryDate?: GraphQLTypes["Date"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["SuppliesPurchaseUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["SuppliesPurchaseItemWithoutListCreateInput"] | undefined,
	update?: GraphQLTypes["SuppliesPurchaseUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["SuppliesPurchaseUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["SuppliesPurchaseItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["SuppliesPurchaseItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["SuppliesPurchaseUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["SuppliesPurchaseItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["SuppliesPurchaseItemWithoutListUpdateInput"] | undefined
};
	["SuppliesPurchaseItemWithoutListUpdateInput"]: {
		amount?: number | undefined,
	ingredient?: GraphQLTypes["SuppliesPurchaseItemUpdateIngredientEntityRelationInput"] | undefined,
	pricePaidCents?: number | undefined,
	preferredUnit?: GraphQLTypes["SuppliesPurchaseItemUpdatePreferredUnitEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	purchasedAt?: GraphQLTypes["DateTime"] | undefined,
	purchasedBy?: GraphQLTypes["SuppliesPurchaseItemUpdatePurchasedByEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["SuppliesPurchaseItemUpdateIngredientEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductIngredientUniqueWhere"] | undefined
};
	["SuppliesPurchaseItemUpdatePreferredUnitEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductIngredientItemQuantityUnitUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["SuppliesPurchaseItemUpdatePurchasedByEntityRelationInput"]: {
		connect?: GraphQLTypes["StaffUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["SuppliesPurchaseUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["SuppliesPurchaseItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["SuppliesPurchaseItemWithoutListUpdateInput"] | undefined,
	create?: GraphQLTypes["SuppliesPurchaseItemWithoutListCreateInput"] | undefined
};
	["SuppliesPurchaseUpdateSupplierEntityRelationInput"]: {
		connect?: GraphQLTypes["IngredientSupplierUniqueWhere"] | undefined
};
	["LogRecord"]: {
	__typename: "LogRecord",
	_meta?: GraphQLTypes["LogRecordMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	level: number,
	type: string,
	message?: string | undefined,
	data?: GraphQLTypes["Json"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	shouldBeResolved?: boolean | undefined,
	resolvedAt?: GraphQLTypes["DateTime"] | undefined
};
	["LogRecordMeta"]: {
	__typename: "LogRecordMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	level?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	message?: GraphQLTypes["FieldMeta"] | undefined,
	data?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	shouldBeResolved?: GraphQLTypes["FieldMeta"] | undefined,
	resolvedAt?: GraphQLTypes["FieldMeta"] | undefined
};
	["LogRecordUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["LogRecordWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	level?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	message?: GraphQLTypes["StringCondition"] | undefined,
	data?: GraphQLTypes["JsonCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	shouldBeResolved?: GraphQLTypes["BooleanCondition"] | undefined,
	resolvedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["LogRecordWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LogRecordWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LogRecordWhere"] | undefined
};
	["LogRecordOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	level?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	message?: GraphQLTypes["OrderDirection"] | undefined,
	data?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	shouldBeResolved?: GraphQLTypes["OrderDirection"] | undefined,
	resolvedAt?: GraphQLTypes["OrderDirection"] | undefined
};
	["LogRecordConnection"]: {
	__typename: "LogRecordConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LogRecordEdge"]>
};
	["LogRecordEdge"]: {
	__typename: "LogRecordEdge",
	node: GraphQLTypes["LogRecord"]
};
	["SuppliesPurchaseItemCreateInput"]: {
		amount?: number | undefined,
	list?: GraphQLTypes["SuppliesPurchaseItemCreateListEntityRelationInput"] | undefined,
	ingredient?: GraphQLTypes["SuppliesPurchaseItemCreateIngredientEntityRelationInput"] | undefined,
	pricePaidCents?: number | undefined,
	preferredUnit?: GraphQLTypes["SuppliesPurchaseItemCreatePreferredUnitEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	purchasedAt?: GraphQLTypes["DateTime"] | undefined,
	purchasedBy?: GraphQLTypes["SuppliesPurchaseItemCreatePurchasedByEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["SuppliesPurchaseItemCreateListEntityRelationInput"]: {
		connect?: GraphQLTypes["SuppliesPurchaseUniqueWhere"] | undefined,
	create?: GraphQLTypes["SuppliesPurchaseWithoutItemsCreateInput"] | undefined
};
	["SuppliesPurchaseWithoutItemsCreateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	supplier?: GraphQLTypes["SuppliesPurchaseCreateSupplierEntityRelationInput"] | undefined,
	requestedDeliveryDate?: GraphQLTypes["Date"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["SuppliesPurchaseItemUpdateInput"]: {
		amount?: number | undefined,
	list?: GraphQLTypes["SuppliesPurchaseItemUpdateListEntityRelationInput"] | undefined,
	ingredient?: GraphQLTypes["SuppliesPurchaseItemUpdateIngredientEntityRelationInput"] | undefined,
	pricePaidCents?: number | undefined,
	preferredUnit?: GraphQLTypes["SuppliesPurchaseItemUpdatePreferredUnitEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	purchasedAt?: GraphQLTypes["DateTime"] | undefined,
	purchasedBy?: GraphQLTypes["SuppliesPurchaseItemUpdatePurchasedByEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["SuppliesPurchaseItemUpdateListEntityRelationInput"]: {
		create?: GraphQLTypes["SuppliesPurchaseWithoutItemsCreateInput"] | undefined,
	update?: GraphQLTypes["SuppliesPurchaseWithoutItemsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["SuppliesPurchaseItemUpsertListRelationInput"] | undefined,
	connect?: GraphQLTypes["SuppliesPurchaseUniqueWhere"] | undefined
};
	["SuppliesPurchaseWithoutItemsUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	supplier?: GraphQLTypes["SuppliesPurchaseUpdateSupplierEntityRelationInput"] | undefined,
	requestedDeliveryDate?: GraphQLTypes["Date"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["SuppliesPurchaseItemUpsertListRelationInput"]: {
		update?: GraphQLTypes["SuppliesPurchaseWithoutItemsUpdateInput"] | undefined,
	create?: GraphQLTypes["SuppliesPurchaseWithoutItemsCreateInput"] | undefined
};
	["VirtualProductConnection"]: {
	__typename: "VirtualProductConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VirtualProductEdge"]>
};
	["VirtualProductEdge"]: {
	__typename: "VirtualProductEdge",
	node: GraphQLTypes["VirtualProduct"]
};
	["ProductMetadataConnection"]: {
	__typename: "ProductMetadataConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductMetadataEdge"]>
};
	["ProductMetadataEdge"]: {
	__typename: "ProductMetadataEdge",
	node: GraphQLTypes["ProductMetadata"]
};
	["UnassignedShiftInfoConnection"]: {
	__typename: "UnassignedShiftInfoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["UnassignedShiftInfoEdge"]>
};
	["UnassignedShiftInfoEdge"]: {
	__typename: "UnassignedShiftInfoEdge",
	node: GraphQLTypes["UnassignedShiftInfo"]
};
	["UnassignedShiftInfoCreateInput"]: {
		hoursMissed?: number | undefined,
	queueItem?: GraphQLTypes["UnassignedShiftInfoCreateQueueItemEntityRelationInput"] | undefined,
	legacyReason?: GraphQLTypes["UnassignedInfoReason"] | undefined,
	reason?: GraphQLTypes["UnassignedShiftInfoCreateReasonEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["UnassignedShiftInfoCreateQueueItemEntityRelationInput"]: {
		connect?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["EmployeeQueueItemWithoutUnassignInfoCreateInput"] | undefined
};
	["EmployeeQueueItemWithoutUnassignInfoCreateInput"]: {
		start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["EmployeeQueueItemCreateQueueEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["EmployeeQueueItemCreateShiftsProfileEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["UnassignedShiftInfoUpdateInput"]: {
		hoursMissed?: number | undefined,
	queueItem?: GraphQLTypes["UnassignedShiftInfoUpdateQueueItemEntityRelationInput"] | undefined,
	legacyReason?: GraphQLTypes["UnassignedInfoReason"] | undefined,
	reason?: GraphQLTypes["UnassignedShiftInfoUpdateReasonEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["UnassignedShiftInfoUpdateQueueItemEntityRelationInput"]: {
		create?: GraphQLTypes["EmployeeQueueItemWithoutUnassignInfoCreateInput"] | undefined,
	update?: GraphQLTypes["EmployeeQueueItemWithoutUnassignInfoUpdateInput"] | undefined,
	upsert?: GraphQLTypes["UnassignedShiftInfoUpsertQueueItemRelationInput"] | undefined,
	connect?: GraphQLTypes["EmployeeQueueItemUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["EmployeeQueueItemWithoutUnassignInfoUpdateInput"]: {
		start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["EmployeeQueueItemUpdateQueueEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"] | undefined,
	shift?: GraphQLTypes["EmployeeQueueItemUpdateShiftEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["UnassignedShiftInfoUpsertQueueItemRelationInput"]: {
		update?: GraphQLTypes["EmployeeQueueItemWithoutUnassignInfoUpdateInput"] | undefined,
	create?: GraphQLTypes["EmployeeQueueItemWithoutUnassignInfoCreateInput"] | undefined
};
	["FreshingContainerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["TextListConnection"]: {
	__typename: "TextListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TextListEdge"]>
};
	["TextListEdge"]: {
	__typename: "TextListEdge",
	node: GraphQLTypes["TextList"]
};
	["DeliveryRegionsConnection"]: {
	__typename: "DeliveryRegionsConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryRegionsEdge"]>
};
	["DeliveryRegionsEdge"]: {
	__typename: "DeliveryRegionsEdge",
	node: GraphQLTypes["DeliveryRegions"]
};
	["ImageListConnection"]: {
	__typename: "ImageListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListEdge"]>
};
	["ImageListEdge"]: {
	__typename: "ImageListEdge",
	node: GraphQLTypes["ImageList"]
};
	["TimetablesEmployeePrivilegesConnection"]: {
	__typename: "TimetablesEmployeePrivilegesConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetablesEmployeePrivilegesEdge"]>
};
	["TimetablesEmployeePrivilegesEdge"]: {
	__typename: "TimetablesEmployeePrivilegesEdge",
	node: GraphQLTypes["TimetablesEmployeePrivileges"]
};
	["IngredientSupplierConnection"]: {
	__typename: "IngredientSupplierConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IngredientSupplierEdge"]>
};
	["IngredientSupplierEdge"]: {
	__typename: "IngredientSupplierEdge",
	node: GraphQLTypes["IngredientSupplier"]
};
	["IngredientSupplierListConnection"]: {
	__typename: "IngredientSupplierListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IngredientSupplierListEdge"]>
};
	["IngredientSupplierListEdge"]: {
	__typename: "IngredientSupplierListEdge",
	node: GraphQLTypes["IngredientSupplierList"]
};
	["RedirectConnection"]: {
	__typename: "RedirectConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["RedirectEdge"]>
};
	["RedirectEdge"]: {
	__typename: "RedirectEdge",
	node: GraphQLTypes["Redirect"]
};
	["WorkspaceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	freshingContainers?: GraphQLTypes["FreshingContainerUniqueWhere"] | undefined,
	openingHours?: GraphQLTypes["WeekHoursUniqueWhere"] | undefined,
	workingHours?: GraphQLTypes["WeekHoursUniqueWhere"] | undefined,
	creditsProduct?: GraphQLTypes["VirtualProductUniqueWhere"] | undefined,
	discountVatRate?: GraphQLTypes["VatRateUniqueWhere"] | undefined
};
	["WorkspaceConnection"]: {
	__typename: "WorkspaceConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["WorkspaceEdge"]>
};
	["WorkspaceEdge"]: {
	__typename: "WorkspaceEdge",
	node: GraphQLTypes["Workspace"]
};
	["IdentificationCodeConnection"]: {
	__typename: "IdentificationCodeConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IdentificationCodeEdge"]>
};
	["IdentificationCodeEdge"]: {
	__typename: "IdentificationCodeEdge",
	node: GraphQLTypes["IdentificationCode"]
};
	["DeviceIdentifier"]: {
	__typename: "DeviceIdentifier",
	_meta?: GraphQLTypes["DeviceIdentifierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName?: string | undefined,
	updatedAt: GraphQLTypes["DateTime"],
	platform?: string | undefined,
	resolution?: string | undefined,
	devicePixelRatio?: number | undefined,
	identifier: string,
	meta?: GraphQLTypes["DeviceIdentifierMetadata"] | undefined,
	applicationVersionIdentifier?: string | undefined,
	lastPointOfSaleSynchronizationErrorRawDetails?: string | undefined
};
	["DeviceIdentifierMeta"]: {
	__typename: "DeviceIdentifierMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	updatedAt?: GraphQLTypes["FieldMeta"] | undefined,
	platform?: GraphQLTypes["FieldMeta"] | undefined,
	resolution?: GraphQLTypes["FieldMeta"] | undefined,
	devicePixelRatio?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	applicationVersionIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	lastPointOfSaleSynchronizationErrorRawDetails?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeviceIdentifierMetadata"]: {
	__typename: "DeviceIdentifierMetadata",
	_meta?: GraphQLTypes["DeviceIdentifierMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	prettyIdentifier: string,
	deviceIdentifier?: GraphQLTypes["DeviceIdentifier"] | undefined
};
	["DeviceIdentifierMetadataMeta"]: {
	__typename: "DeviceIdentifierMetadataMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	prettyIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	deviceIdentifier?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeviceIdentifierWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	platform?: GraphQLTypes["StringCondition"] | undefined,
	resolution?: GraphQLTypes["StringCondition"] | undefined,
	devicePixelRatio?: GraphQLTypes["FloatCondition"] | undefined,
	identifier?: GraphQLTypes["StringCondition"] | undefined,
	meta?: GraphQLTypes["DeviceIdentifierMetadataWhere"] | undefined,
	applicationVersionIdentifier?: GraphQLTypes["StringCondition"] | undefined,
	lastPointOfSaleSynchronizationErrorRawDetails?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["DeviceIdentifierWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeviceIdentifierWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeviceIdentifierWhere"] | undefined
};
	["DeviceIdentifierMetadataWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	prettyIdentifier?: GraphQLTypes["StringCondition"] | undefined,
	deviceIdentifier?: GraphQLTypes["DeviceIdentifierWhere"] | undefined,
	and?: Array<GraphQLTypes["DeviceIdentifierMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeviceIdentifierMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeviceIdentifierMetadataWhere"] | undefined
};
	["DeviceIdentifierUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	identifier?: string | undefined,
	meta?: GraphQLTypes["DeviceIdentifierMetadataUniqueWhere"] | undefined
};
	["DeviceIdentifierMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	deviceIdentifier?: GraphQLTypes["DeviceIdentifierUniqueWhere"] | undefined
};
	["DeviceIdentifierOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	updatedAt?: GraphQLTypes["OrderDirection"] | undefined,
	platform?: GraphQLTypes["OrderDirection"] | undefined,
	resolution?: GraphQLTypes["OrderDirection"] | undefined,
	devicePixelRatio?: GraphQLTypes["OrderDirection"] | undefined,
	identifier?: GraphQLTypes["OrderDirection"] | undefined,
	meta?: GraphQLTypes["DeviceIdentifierMetadataOrderBy"] | undefined,
	applicationVersionIdentifier?: GraphQLTypes["OrderDirection"] | undefined,
	lastPointOfSaleSynchronizationErrorRawDetails?: GraphQLTypes["OrderDirection"] | undefined
};
	["DeviceIdentifierMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	prettyIdentifier?: GraphQLTypes["OrderDirection"] | undefined,
	deviceIdentifier?: GraphQLTypes["DeviceIdentifierOrderBy"] | undefined
};
	["DeviceIdentifierConnection"]: {
	__typename: "DeviceIdentifierConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeviceIdentifierEdge"]>
};
	["DeviceIdentifierEdge"]: {
	__typename: "DeviceIdentifierEdge",
	node: GraphQLTypes["DeviceIdentifier"]
};
	["DeviceIdentifierCreateInput"]: {
		internalName?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	platform?: string | undefined,
	resolution?: string | undefined,
	devicePixelRatio?: number | undefined,
	identifier?: string | undefined,
	applicationVersionIdentifier?: string | undefined,
	lastPointOfSaleSynchronizationErrorRawDetails?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["DeviceIdentifierUpdateInput"]: {
		internalName?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	platform?: string | undefined,
	resolution?: string | undefined,
	devicePixelRatio?: number | undefined,
	identifier?: string | undefined,
	applicationVersionIdentifier?: string | undefined,
	lastPointOfSaleSynchronizationErrorRawDetails?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["DeviceIdentifierMetadataConnection"]: {
	__typename: "DeviceIdentifierMetadataConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeviceIdentifierMetadataEdge"]>
};
	["DeviceIdentifierMetadataEdge"]: {
	__typename: "DeviceIdentifierMetadataEdge",
	node: GraphQLTypes["DeviceIdentifierMetadata"]
};
	["FulfillmentNoteUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["CustomerMetadataConnection"]: {
	__typename: "CustomerMetadataConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerMetadataEdge"]>
};
	["CustomerMetadataEdge"]: {
	__typename: "CustomerMetadataEdge",
	node: GraphQLTypes["CustomerMetadata"]
};
	["SubscriptionBoxConnection"]: {
	__typename: "SubscriptionBoxConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SubscriptionBoxEdge"]>
};
	["SubscriptionBoxEdge"]: {
	__typename: "SubscriptionBoxEdge",
	node: GraphQLTypes["SubscriptionBox"]
};
	["TimetableDayMetadataConnection"]: {
	__typename: "TimetableDayMetadataConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TimetableDayMetadataEdge"]>
};
	["TimetableDayMetadataEdge"]: {
	__typename: "TimetableDayMetadataEdge",
	node: GraphQLTypes["TimetableDayMetadata"]
};
	["GridTileItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["GridTilesUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["GridTileItemUniqueWhere"] | undefined
};
	["GridTilesConnection"]: {
	__typename: "GridTilesConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GridTilesEdge"]>
};
	["GridTilesEdge"]: {
	__typename: "GridTilesEdge",
	node: GraphQLTypes["GridTiles"]
};
	["Position"]: {
	__typename: "Position",
	_meta?: GraphQLTypes["PositionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	x: number,
	y: number
};
	["PositionMeta"]: {
	__typename: "PositionMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	x?: GraphQLTypes["FieldMeta"] | undefined,
	y?: GraphQLTypes["FieldMeta"] | undefined
};
	["PositionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["PositionWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	x?: GraphQLTypes["IntCondition"] | undefined,
	y?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["PositionWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PositionWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PositionWhere"] | undefined
};
	["PositionOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	x?: GraphQLTypes["OrderDirection"] | undefined,
	y?: GraphQLTypes["OrderDirection"] | undefined
};
	["PositionConnection"]: {
	__typename: "PositionConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PositionEdge"]>
};
	["PositionEdge"]: {
	__typename: "PositionEdge",
	node: GraphQLTypes["Position"]
};
	["StatsWidget"]: {
	__typename: "StatsWidget",
	_meta?: GraphQLTypes["StatsWidgetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	size: number,
	type: GraphQLTypes["StatsEnabledWidgetTypeType"],
	link?: string | undefined,
	title?: string | undefined,
	timeFrame: GraphQLTypes["StatsWidgetTimeFrame"],
	layout?: GraphQLTypes["StatsWidgetsLayout"] | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	channels: Array<GraphQLTypes["Channel"]>,
	productCategory?: GraphQLTypes["ProductCategory"] | undefined,
	paymentMethods: Array<GraphQLTypes["PaymentMethod"]>,
	hoursFrom?: string | undefined,
	hoursTo?: string | undefined,
	paginateChannels: GraphQLTypes["ChannelConnection"],
	paginatePaymentMethods: GraphQLTypes["PaymentMethodConnection"]
};
	["StatsWidgetMeta"]: {
	__typename: "StatsWidgetMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	timeFrame?: GraphQLTypes["FieldMeta"] | undefined,
	layout?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	channels?: GraphQLTypes["FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["FieldMeta"] | undefined,
	paymentMethods?: GraphQLTypes["FieldMeta"] | undefined,
	hoursFrom?: GraphQLTypes["FieldMeta"] | undefined,
	hoursTo?: GraphQLTypes["FieldMeta"] | undefined
};
	["StatsEnabledWidgetTypeType"]: StatsEnabledWidgetTypeType;
	["StatsWidgetTimeFrame"]: StatsWidgetTimeFrame;
	["StatsWidgetsLayout"]: {
	__typename: "StatsWidgetsLayout",
	_meta?: GraphQLTypes["StatsWidgetsLayoutMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["StatsWidget"]>,
	paginateItems: GraphQLTypes["StatsWidgetConnection"]
};
	["StatsWidgetsLayoutMeta"]: {
	__typename: "StatsWidgetsLayoutMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["StatsWidgetWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StatsEnabledWidgetTypeTypeCondition"] | undefined,
	link?: GraphQLTypes["StringCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	timeFrame?: GraphQLTypes["StatsWidgetTimeFrameCondition"] | undefined,
	layout?: GraphQLTypes["StatsWidgetsLayoutWhere"] | undefined,
	icon?: GraphQLTypes["ImageWhere"] | undefined,
	channels?: GraphQLTypes["ChannelWhere"] | undefined,
	productCategory?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	paymentMethods?: GraphQLTypes["PaymentMethodWhere"] | undefined,
	hoursFrom?: GraphQLTypes["StringCondition"] | undefined,
	hoursTo?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["StatsWidgetWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["StatsWidgetWhere"] | undefined> | undefined,
	not?: GraphQLTypes["StatsWidgetWhere"] | undefined
};
	["StatsEnabledWidgetTypeTypeCondition"]: {
		and?: Array<GraphQLTypes["StatsEnabledWidgetTypeTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["StatsEnabledWidgetTypeTypeCondition"]> | undefined,
	not?: GraphQLTypes["StatsEnabledWidgetTypeTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["StatsEnabledWidgetTypeType"] | undefined,
	notEq?: GraphQLTypes["StatsEnabledWidgetTypeType"] | undefined,
	in?: Array<GraphQLTypes["StatsEnabledWidgetTypeType"]> | undefined,
	notIn?: Array<GraphQLTypes["StatsEnabledWidgetTypeType"]> | undefined,
	lt?: GraphQLTypes["StatsEnabledWidgetTypeType"] | undefined,
	lte?: GraphQLTypes["StatsEnabledWidgetTypeType"] | undefined,
	gt?: GraphQLTypes["StatsEnabledWidgetTypeType"] | undefined,
	gte?: GraphQLTypes["StatsEnabledWidgetTypeType"] | undefined
};
	["StatsWidgetTimeFrameCondition"]: {
		and?: Array<GraphQLTypes["StatsWidgetTimeFrameCondition"]> | undefined,
	or?: Array<GraphQLTypes["StatsWidgetTimeFrameCondition"]> | undefined,
	not?: GraphQLTypes["StatsWidgetTimeFrameCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["StatsWidgetTimeFrame"] | undefined,
	notEq?: GraphQLTypes["StatsWidgetTimeFrame"] | undefined,
	in?: Array<GraphQLTypes["StatsWidgetTimeFrame"]> | undefined,
	notIn?: Array<GraphQLTypes["StatsWidgetTimeFrame"]> | undefined,
	lt?: GraphQLTypes["StatsWidgetTimeFrame"] | undefined,
	lte?: GraphQLTypes["StatsWidgetTimeFrame"] | undefined,
	gt?: GraphQLTypes["StatsWidgetTimeFrame"] | undefined,
	gte?: GraphQLTypes["StatsWidgetTimeFrame"] | undefined
};
	["StatsWidgetsLayoutWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["StatsWidgetWhere"] | undefined,
	and?: Array<GraphQLTypes["StatsWidgetsLayoutWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["StatsWidgetsLayoutWhere"] | undefined> | undefined,
	not?: GraphQLTypes["StatsWidgetsLayoutWhere"] | undefined
};
	["StatsWidgetOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	timeFrame?: GraphQLTypes["OrderDirection"] | undefined,
	layout?: GraphQLTypes["StatsWidgetsLayoutOrderBy"] | undefined,
	icon?: GraphQLTypes["ImageOrderBy"] | undefined,
	productCategory?: GraphQLTypes["ProductCategoryOrderBy"] | undefined,
	hoursFrom?: GraphQLTypes["OrderDirection"] | undefined,
	hoursTo?: GraphQLTypes["OrderDirection"] | undefined
};
	["StatsWidgetsLayoutOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["StatsWidgetConnection"]: {
	__typename: "StatsWidgetConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StatsWidgetEdge"]>
};
	["StatsWidgetEdge"]: {
	__typename: "StatsWidgetEdge",
	node: GraphQLTypes["StatsWidget"]
};
	["StatsWidgetUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["StatsWidgetsLayoutUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["StatsWidgetUniqueWhere"] | undefined
};
	["StatsWidgetsLayoutConnection"]: {
	__typename: "StatsWidgetsLayoutConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StatsWidgetsLayoutEdge"]>
};
	["StatsWidgetsLayoutEdge"]: {
	__typename: "StatsWidgetsLayoutEdge",
	node: GraphQLTypes["StatsWidgetsLayout"]
};
	["StatsPage"]: {
	__typename: "StatsPage",
	_meta?: GraphQLTypes["StatsPageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["StatsPageType"],
	enabled: boolean,
	widgets?: GraphQLTypes["StatsWidgetsLayout"] | undefined
};
	["StatsPageMeta"]: {
	__typename: "StatsPageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	widgets?: GraphQLTypes["FieldMeta"] | undefined
};
	["StatsPageType"]: StatsPageType;
	["StatsPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	type?: GraphQLTypes["StatsPageType"] | undefined,
	widgets?: GraphQLTypes["StatsWidgetsLayoutUniqueWhere"] | undefined
};
	["StatsPageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["StatsPageTypeCondition"] | undefined,
	enabled?: GraphQLTypes["BooleanCondition"] | undefined,
	widgets?: GraphQLTypes["StatsWidgetsLayoutWhere"] | undefined,
	and?: Array<GraphQLTypes["StatsPageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["StatsPageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["StatsPageWhere"] | undefined
};
	["StatsPageTypeCondition"]: {
		and?: Array<GraphQLTypes["StatsPageTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["StatsPageTypeCondition"]> | undefined,
	not?: GraphQLTypes["StatsPageTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["StatsPageType"] | undefined,
	notEq?: GraphQLTypes["StatsPageType"] | undefined,
	in?: Array<GraphQLTypes["StatsPageType"]> | undefined,
	notIn?: Array<GraphQLTypes["StatsPageType"]> | undefined,
	lt?: GraphQLTypes["StatsPageType"] | undefined,
	lte?: GraphQLTypes["StatsPageType"] | undefined,
	gt?: GraphQLTypes["StatsPageType"] | undefined,
	gte?: GraphQLTypes["StatsPageType"] | undefined
};
	["StatsPageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	enabled?: GraphQLTypes["OrderDirection"] | undefined,
	widgets?: GraphQLTypes["StatsWidgetsLayoutOrderBy"] | undefined
};
	["StatsPageConnection"]: {
	__typename: "StatsPageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StatsPageEdge"]>
};
	["StatsPageEdge"]: {
	__typename: "StatsPageEdge",
	node: GraphQLTypes["StatsPage"]
};
	["OrderPaymentStatsByDay"]: {
	__typename: "OrderPaymentStatsByDay",
	_meta?: GraphQLTypes["OrderPaymentStatsByDayMeta"] | undefined,
	id: string,
	day: GraphQLTypes["Date"],
	totalGratuityCents: number,
	reducedGratuityCents: number,
	channel?: GraphQLTypes["Channel"] | undefined,
	paymentMethod?: GraphQLTypes["PaymentMethod"] | undefined
};
	["OrderPaymentStatsByDayMeta"]: {
	__typename: "OrderPaymentStatsByDayMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	day?: GraphQLTypes["FieldMeta"] | undefined,
	totalGratuityCents?: GraphQLTypes["FieldMeta"] | undefined,
	reducedGratuityCents?: GraphQLTypes["FieldMeta"] | undefined,
	channel?: GraphQLTypes["FieldMeta"] | undefined,
	paymentMethod?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderPaymentStatsByDayUniqueWhere"]: {
		id?: string | undefined
};
	["OrderPaymentStatsByDayWhere"]: {
		id?: GraphQLTypes["StringCondition"] | undefined,
	day?: GraphQLTypes["DateCondition"] | undefined,
	totalGratuityCents?: GraphQLTypes["IntCondition"] | undefined,
	reducedGratuityCents?: GraphQLTypes["IntCondition"] | undefined,
	channel?: GraphQLTypes["ChannelWhere"] | undefined,
	paymentMethod?: GraphQLTypes["PaymentMethodWhere"] | undefined,
	and?: Array<GraphQLTypes["OrderPaymentStatsByDayWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderPaymentStatsByDayWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderPaymentStatsByDayWhere"] | undefined
};
	["OrderPaymentStatsByDayOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	day?: GraphQLTypes["OrderDirection"] | undefined,
	totalGratuityCents?: GraphQLTypes["OrderDirection"] | undefined,
	reducedGratuityCents?: GraphQLTypes["OrderDirection"] | undefined,
	channel?: GraphQLTypes["ChannelOrderBy"] | undefined,
	paymentMethod?: GraphQLTypes["PaymentMethodOrderBy"] | undefined
};
	["OrderPaymentStatsByDayConnection"]: {
	__typename: "OrderPaymentStatsByDayConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderPaymentStatsByDayEdge"]>
};
	["OrderPaymentStatsByDayEdge"]: {
	__typename: "OrderPaymentStatsByDayEdge",
	node: GraphQLTypes["OrderPaymentStatsByDay"]
};
	["QueryTransaction"]: {
	__typename: "QueryTransaction",
	getAddress?: GraphQLTypes["Address"] | undefined,
	listAddress: Array<GraphQLTypes["Address"]>,
	paginateAddress: GraphQLTypes["AddressConnection"],
	getCountry?: GraphQLTypes["Country"] | undefined,
	listCountry: Array<GraphQLTypes["Country"]>,
	paginateCountry: GraphQLTypes["CountryConnection"],
	getChannel?: GraphQLTypes["Channel"] | undefined,
	listChannel: Array<GraphQLTypes["Channel"]>,
	paginateChannel: GraphQLTypes["ChannelConnection"],
	getCustomer?: GraphQLTypes["Customer"] | undefined,
	listCustomer: Array<GraphQLTypes["Customer"]>,
	paginateCustomer: GraphQLTypes["CustomerConnection"],
	getDeliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined,
	listDeliveryMethod: Array<GraphQLTypes["DeliveryMethod"]>,
	paginateDeliveryMethod: GraphQLTypes["DeliveryMethodConnection"],
	getOrder?: GraphQLTypes["Order"] | undefined,
	listOrder: Array<GraphQLTypes["Order"]>,
	paginateOrder: GraphQLTypes["OrderConnection"],
	getOrderPayment?: GraphQLTypes["OrderPayment"] | undefined,
	listOrderPayment: Array<GraphQLTypes["OrderPayment"]>,
	paginateOrderPayment: GraphQLTypes["OrderPaymentConnection"],
	getOrderDelivery?: GraphQLTypes["OrderDelivery"] | undefined,
	listOrderDelivery: Array<GraphQLTypes["OrderDelivery"]>,
	paginateOrderDelivery: GraphQLTypes["OrderDeliveryConnection"],
	getOrderItem?: GraphQLTypes["OrderItem"] | undefined,
	listOrderItem: Array<GraphQLTypes["OrderItem"]>,
	paginateOrderItem: GraphQLTypes["OrderItemConnection"],
	getPaymentMethod?: GraphQLTypes["PaymentMethod"] | undefined,
	listPaymentMethod: Array<GraphQLTypes["PaymentMethod"]>,
	paginatePaymentMethod: GraphQLTypes["PaymentMethodConnection"],
	getVatRate?: GraphQLTypes["VatRate"] | undefined,
	listVatRate: Array<GraphQLTypes["VatRate"]>,
	paginateVatRate: GraphQLTypes["VatRateConnection"],
	getProduct?: GraphQLTypes["Product"] | undefined,
	listProduct: Array<GraphQLTypes["Product"]>,
	paginateProduct: GraphQLTypes["ProductConnection"],
	getProductStock?: GraphQLTypes["ProductStock"] | undefined,
	listProductStock: Array<GraphQLTypes["ProductStock"]>,
	paginateProductStock: GraphQLTypes["ProductStockConnection"],
	getProductPacking?: GraphQLTypes["ProductPacking"] | undefined,
	listProductPacking: Array<GraphQLTypes["ProductPacking"]>,
	paginateProductPacking: GraphQLTypes["ProductPackingConnection"],
	getProductRecipe?: GraphQLTypes["ProductRecipe"] | undefined,
	listProductRecipe: Array<GraphQLTypes["ProductRecipe"]>,
	paginateProductRecipe: GraphQLTypes["ProductRecipeConnection"],
	getProductCategory?: GraphQLTypes["ProductCategory"] | undefined,
	listProductCategory: Array<GraphQLTypes["ProductCategory"]>,
	paginateProductCategory: GraphQLTypes["ProductCategoryConnection"],
	getStore?: GraphQLTypes["Store"] | undefined,
	listStore: Array<GraphQLTypes["Store"]>,
	paginateStore: GraphQLTypes["StoreConnection"],
	getTag?: GraphQLTypes["Tag"] | undefined,
	listTag: Array<GraphQLTypes["Tag"]>,
	paginateTag: GraphQLTypes["TagConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	listBusinessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"],
	getProductList?: GraphQLTypes["ProductList"] | undefined,
	listProductList: Array<GraphQLTypes["ProductList"]>,
	paginateProductList: GraphQLTypes["ProductListConnection"],
	getProductListItem?: GraphQLTypes["ProductListItem"] | undefined,
	listProductListItem: Array<GraphQLTypes["ProductListItem"]>,
	paginateProductListItem: GraphQLTypes["ProductListItemConnection"],
	getProductCategoryList?: GraphQLTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<GraphQLTypes["ProductCategoryList"]>,
	paginateProductCategoryList: GraphQLTypes["ProductCategoryListConnection"],
	getProductCategoryListItem?: GraphQLTypes["ProductCategoryListItem"] | undefined,
	listProductCategoryListItem: Array<GraphQLTypes["ProductCategoryListItem"]>,
	paginateProductCategoryListItem: GraphQLTypes["ProductCategoryListItemConnection"],
	getBusinessCategoryLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	listBusinessCategoryLocale: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	paginateBusinessCategoryLocale: GraphQLTypes["BusinessCategoryLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getProductCategoryListLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	listProductCategoryListLocale: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	paginateProductCategoryListLocale: GraphQLTypes["ProductCategoryListLocaleConnection"],
	getCustomerName?: GraphQLTypes["CustomerName"] | undefined,
	listCustomerName: Array<GraphQLTypes["CustomerName"]>,
	paginateCustomerName: GraphQLTypes["CustomerNameConnection"],
	getProductIngredientItem?: GraphQLTypes["ProductIngredientItem"] | undefined,
	listProductIngredientItem: Array<GraphQLTypes["ProductIngredientItem"]>,
	paginateProductIngredientItem: GraphQLTypes["ProductIngredientItemConnection"],
	getProductIngredientItemQuantity?: GraphQLTypes["ProductIngredientItemQuantity"] | undefined,
	listProductIngredientItemQuantity: Array<GraphQLTypes["ProductIngredientItemQuantity"]>,
	paginateProductIngredientItemQuantity: GraphQLTypes["ProductIngredientItemQuantityConnection"],
	getProductIngredientItemQuantityUnit?: GraphQLTypes["ProductIngredientItemQuantityUnit"] | undefined,
	listProductIngredientItemQuantityUnit: Array<GraphQLTypes["ProductIngredientItemQuantityUnit"]>,
	paginateProductIngredientItemQuantityUnit: GraphQLTypes["ProductIngredientItemQuantityUnitConnection"],
	getProductIngredient?: GraphQLTypes["ProductIngredient"] | undefined,
	listProductIngredient: Array<GraphQLTypes["ProductIngredient"]>,
	paginateProductIngredient: GraphQLTypes["ProductIngredientConnection"],
	getProductIngredientRating?: GraphQLTypes["ProductIngredientRating"] | undefined,
	listProductIngredientRating: Array<GraphQLTypes["ProductIngredientRating"]>,
	paginateProductIngredientRating: GraphQLTypes["ProductIngredientRatingConnection"],
	getProductIngredientLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	listProductIngredientLocale: Array<GraphQLTypes["ProductIngredientLocale"]>,
	paginateProductIngredientLocale: GraphQLTypes["ProductIngredientLocaleConnection"],
	getOrderRecurrence?: GraphQLTypes["OrderRecurrence"] | undefined,
	listOrderRecurrence: Array<GraphQLTypes["OrderRecurrence"]>,
	paginateOrderRecurrence: GraphQLTypes["OrderRecurrenceConnection"],
	getOrderRecurrenceLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	listOrderRecurrenceLocale: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	paginateOrderRecurrenceLocale: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	getStaff?: GraphQLTypes["Staff"] | undefined,
	listStaff: Array<GraphQLTypes["Staff"]>,
	paginateStaff: GraphQLTypes["StaffConnection"],
	getBlog?: GraphQLTypes["Blog"] | undefined,
	listBlog: Array<GraphQLTypes["Blog"]>,
	paginateBlog: GraphQLTypes["BlogConnection"],
	getBlogLocale?: GraphQLTypes["BlogLocale"] | undefined,
	listBlogLocale: Array<GraphQLTypes["BlogLocale"]>,
	paginateBlogLocale: GraphQLTypes["BlogLocaleConnection"],
	getBlogPost?: GraphQLTypes["BlogPost"] | undefined,
	listBlogPost: Array<GraphQLTypes["BlogPost"]>,
	paginateBlogPost: GraphQLTypes["BlogPostConnection"],
	getBlogPostLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<GraphQLTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: GraphQLTypes["BlogPostLocaleConnection"],
	getColorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	listColorPallete: Array<GraphQLTypes["ColorPallete"]>,
	paginateColorPallete: GraphQLTypes["ColorPalleteConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getBlogPostList?: GraphQLTypes["BlogPostList"] | undefined,
	listBlogPostList: Array<GraphQLTypes["BlogPostList"]>,
	paginateBlogPostList: GraphQLTypes["BlogPostListConnection"],
	getBlogPostListItem?: GraphQLTypes["BlogPostListItem"] | undefined,
	listBlogPostListItem: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateBlogPostListItem: GraphQLTypes["BlogPostListItemConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getLinkListItem?: GraphQLTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<GraphQLTypes["LinkListItem"]>,
	paginateLinkListItem: GraphQLTypes["LinkListItemConnection"],
	getCover?: GraphQLTypes["Cover"] | undefined,
	listCover: Array<GraphQLTypes["Cover"]>,
	paginateCover: GraphQLTypes["CoverConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getMedium?: GraphQLTypes["Medium"] | undefined,
	listMedium: Array<GraphQLTypes["Medium"]>,
	paginateMedium: GraphQLTypes["MediumConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getSocialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	listSocialsAndApps: Array<GraphQLTypes["SocialsAndApps"]>,
	paginateSocialsAndApps: GraphQLTypes["SocialsAndAppsConnection"],
	getProductGrid?: GraphQLTypes["ProductGrid"] | undefined,
	listProductGrid: Array<GraphQLTypes["ProductGrid"]>,
	paginateProductGrid: GraphQLTypes["ProductGridConnection"],
	getProductGridItem?: GraphQLTypes["ProductGridItem"] | undefined,
	listProductGridItem: Array<GraphQLTypes["ProductGridItem"]>,
	paginateProductGridItem: GraphQLTypes["ProductGridItemConnection"],
	getGallery?: GraphQLTypes["Gallery"] | undefined,
	listGallery: Array<GraphQLTypes["Gallery"]>,
	paginateGallery: GraphQLTypes["GalleryConnection"],
	getGalleryItem?: GraphQLTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<GraphQLTypes["GalleryItem"]>,
	paginateGalleryItem: GraphQLTypes["GalleryItemConnection"],
	getHero?: GraphQLTypes["Hero"] | undefined,
	listHero: Array<GraphQLTypes["Hero"]>,
	paginateHero: GraphQLTypes["HeroConnection"],
	getPage?: GraphQLTypes["Page"] | undefined,
	listPage: Array<GraphQLTypes["Page"]>,
	paginatePage: GraphQLTypes["PageConnection"],
	getPageLocale?: GraphQLTypes["PageLocale"] | undefined,
	listPageLocale: Array<GraphQLTypes["PageLocale"]>,
	paginatePageLocale: GraphQLTypes["PageLocaleConnection"],
	getDeadlineTemplate?: GraphQLTypes["DeadlineTemplate"] | undefined,
	listDeadlineTemplate: Array<GraphQLTypes["DeadlineTemplate"]>,
	paginateDeadlineTemplate: GraphQLTypes["DeadlineTemplateConnection"],
	getEmployeeQueue?: GraphQLTypes["EmployeeQueue"] | undefined,
	listEmployeeQueue: Array<GraphQLTypes["EmployeeQueue"]>,
	paginateEmployeeQueue: GraphQLTypes["EmployeeQueueConnection"],
	validateCreateEmployeeQueue: GraphQLTypes["_ValidationResult"],
	validateUpdateEmployeeQueue: GraphQLTypes["_ValidationResult"],
	getTimetableDay?: GraphQLTypes["TimetableDay"] | undefined,
	listTimetableDay: Array<GraphQLTypes["TimetableDay"]>,
	paginateTimetableDay: GraphQLTypes["TimetableDayConnection"],
	getTimetableEmployeeRole?: GraphQLTypes["TimetableEmployeeRole"] | undefined,
	listTimetableEmployeeRole: Array<GraphQLTypes["TimetableEmployeeRole"]>,
	paginateTimetableEmployeeRole: GraphQLTypes["TimetableEmployeeRoleConnection"],
	getTimetableShift?: GraphQLTypes["TimetableShift"] | undefined,
	listTimetableShift: Array<GraphQLTypes["TimetableShift"]>,
	paginateTimetableShift: GraphQLTypes["TimetableShiftConnection"],
	validateUpdateTimetableShift: GraphQLTypes["_ValidationResult"],
	getTimetableShiftGroup?: GraphQLTypes["TimetableShiftGroup"] | undefined,
	listTimetableShiftGroup: Array<GraphQLTypes["TimetableShiftGroup"]>,
	paginateTimetableShiftGroup: GraphQLTypes["TimetableShiftGroupConnection"],
	getTimetableShiftPosition?: GraphQLTypes["TimetableShiftPosition"] | undefined,
	listTimetableShiftPosition: Array<GraphQLTypes["TimetableShiftPosition"]>,
	paginateTimetableShiftPosition: GraphQLTypes["TimetableShiftPositionConnection"],
	getTimetableTemplate?: GraphQLTypes["TimetableTemplate"] | undefined,
	listTimetableTemplate: Array<GraphQLTypes["TimetableTemplate"]>,
	paginateTimetableTemplate: GraphQLTypes["TimetableTemplateConnection"],
	getEmployeeQueueItem?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	listEmployeeQueueItem: Array<GraphQLTypes["EmployeeQueueItem"]>,
	paginateEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemConnection"],
	validateCreateEmployeeQueueItem: GraphQLTypes["_ValidationResult"],
	validateUpdateEmployeeQueueItem: GraphQLTypes["_ValidationResult"],
	getProductBanner?: GraphQLTypes["ProductBanner"] | undefined,
	listProductBanner: Array<GraphQLTypes["ProductBanner"]>,
	paginateProductBanner: GraphQLTypes["ProductBannerConnection"],
	getProductBannerItem?: GraphQLTypes["ProductBannerItem"] | undefined,
	listProductBannerItem: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateProductBannerItem: GraphQLTypes["ProductBannerItemConnection"],
	getProductBannerList?: GraphQLTypes["ProductBannerList"] | undefined,
	listProductBannerList: Array<GraphQLTypes["ProductBannerList"]>,
	paginateProductBannerList: GraphQLTypes["ProductBannerListConnection"],
	getShiftsProfile?: GraphQLTypes["ShiftsProfile"] | undefined,
	listShiftsProfile: Array<GraphQLTypes["ShiftsProfile"]>,
	paginateShiftsProfile: GraphQLTypes["ShiftsProfileConnection"],
	validateUpdateShiftsProfile: GraphQLTypes["_ValidationResult"],
	getProductIngredientCategory?: GraphQLTypes["ProductIngredientCategory"] | undefined,
	listProductIngredientCategory: Array<GraphQLTypes["ProductIngredientCategory"]>,
	paginateProductIngredientCategory: GraphQLTypes["ProductIngredientCategoryConnection"],
	getProductIngredientCategoryLocale?: GraphQLTypes["ProductIngredientCategoryLocale"] | undefined,
	listProductIngredientCategoryLocale: Array<GraphQLTypes["ProductIngredientCategoryLocale"]>,
	paginateProductIngredientCategoryLocale: GraphQLTypes["ProductIngredientCategoryLocaleConnection"],
	getDeliveryRoute?: GraphQLTypes["DeliveryRoute"] | undefined,
	listDeliveryRoute: Array<GraphQLTypes["DeliveryRoute"]>,
	paginateDeliveryRoute: GraphQLTypes["DeliveryRouteConnection"],
	getDeliveryWaypoint?: GraphQLTypes["DeliveryWaypoint"] | undefined,
	listDeliveryWaypoint: Array<GraphQLTypes["DeliveryWaypoint"]>,
	paginateDeliveryWaypoint: GraphQLTypes["DeliveryWaypointConnection"],
	getAllergen?: GraphQLTypes["Allergen"] | undefined,
	listAllergen: Array<GraphQLTypes["Allergen"]>,
	paginateAllergen: GraphQLTypes["AllergenConnection"],
	getAllergenLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	listAllergenLocale: Array<GraphQLTypes["AllergenLocale"]>,
	paginateAllergenLocale: GraphQLTypes["AllergenLocaleConnection"],
	getWeekHours?: GraphQLTypes["WeekHours"] | undefined,
	listWeekHours: Array<GraphQLTypes["WeekHours"]>,
	paginateWeekHours: GraphQLTypes["WeekHoursConnection"],
	getPreparedIngredientQuantity?: GraphQLTypes["PreparedIngredientQuantity"] | undefined,
	listPreparedIngredientQuantity: Array<GraphQLTypes["PreparedIngredientQuantity"]>,
	paginatePreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityConnection"],
	validateCreatePreparedIngredientQuantity: GraphQLTypes["_ValidationResult"],
	validateUpdatePreparedIngredientQuantity: GraphQLTypes["_ValidationResult"],
	getPreparedRecipeQuantity?: GraphQLTypes["PreparedRecipeQuantity"] | undefined,
	listPreparedRecipeQuantity: Array<GraphQLTypes["PreparedRecipeQuantity"]>,
	paginatePreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityConnection"],
	validateCreatePreparedRecipeQuantity: GraphQLTypes["_ValidationResult"],
	validateUpdatePreparedRecipeQuantity: GraphQLTypes["_ValidationResult"],
	getPreparedPackingQuantity?: GraphQLTypes["PreparedPackingQuantity"] | undefined,
	listPreparedPackingQuantity: Array<GraphQLTypes["PreparedPackingQuantity"]>,
	paginatePreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityConnection"],
	validateCreatePreparedPackingQuantity: GraphQLTypes["_ValidationResult"],
	validateUpdatePreparedPackingQuantity: GraphQLTypes["_ValidationResult"],
	getDeliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	listDeliveryZone: Array<GraphQLTypes["DeliveryZone"]>,
	paginateDeliveryZone: GraphQLTypes["DeliveryZoneConnection"],
	getDeliveryTimelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	listDeliveryTimelinePreset: Array<GraphQLTypes["DeliveryTimelinePreset"]>,
	paginateDeliveryTimelinePreset: GraphQLTypes["DeliveryTimelinePresetConnection"],
	getProductPackingLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	listProductPackingLocale: Array<GraphQLTypes["ProductPackingLocale"]>,
	paginateProductPackingLocale: GraphQLTypes["ProductPackingLocaleConnection"],
	getProductLocale?: GraphQLTypes["ProductLocale"] | undefined,
	listProductLocale: Array<GraphQLTypes["ProductLocale"]>,
	paginateProductLocale: GraphQLTypes["ProductLocaleConnection"],
	getProductRecipeLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	listProductRecipeLocale: Array<GraphQLTypes["ProductRecipeLocale"]>,
	paginateProductRecipeLocale: GraphQLTypes["ProductRecipeLocaleConnection"],
	getSuppliesPurchase?: GraphQLTypes["SuppliesPurchase"] | undefined,
	listSuppliesPurchase: Array<GraphQLTypes["SuppliesPurchase"]>,
	paginateSuppliesPurchase: GraphQLTypes["SuppliesPurchaseConnection"],
	validateCreateSuppliesPurchase: GraphQLTypes["_ValidationResult"],
	validateUpdateSuppliesPurchase: GraphQLTypes["_ValidationResult"],
	getLogRecord?: GraphQLTypes["LogRecord"] | undefined,
	listLogRecord: Array<GraphQLTypes["LogRecord"]>,
	paginateLogRecord: GraphQLTypes["LogRecordConnection"],
	getSuppliesPurchaseItem?: GraphQLTypes["SuppliesPurchaseItem"] | undefined,
	listSuppliesPurchaseItem: Array<GraphQLTypes["SuppliesPurchaseItem"]>,
	paginateSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemConnection"],
	validateCreateSuppliesPurchaseItem: GraphQLTypes["_ValidationResult"],
	validateUpdateSuppliesPurchaseItem: GraphQLTypes["_ValidationResult"],
	getVirtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	listVirtualProduct: Array<GraphQLTypes["VirtualProduct"]>,
	paginateVirtualProduct: GraphQLTypes["VirtualProductConnection"],
	getProductMetadata?: GraphQLTypes["ProductMetadata"] | undefined,
	listProductMetadata: Array<GraphQLTypes["ProductMetadata"]>,
	paginateProductMetadata: GraphQLTypes["ProductMetadataConnection"],
	getProductGridCategory?: GraphQLTypes["ProductGridCategory"] | undefined,
	listProductGridCategory: Array<GraphQLTypes["ProductGridCategory"]>,
	paginateProductGridCategory: GraphQLTypes["ProductGridCategoryConnection"],
	getUnassignedShiftInfo?: GraphQLTypes["UnassignedShiftInfo"] | undefined,
	listUnassignedShiftInfo: Array<GraphQLTypes["UnassignedShiftInfo"]>,
	paginateUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoConnection"],
	validateCreateUnassignedShiftInfo: GraphQLTypes["_ValidationResult"],
	validateUpdateUnassignedShiftInfo: GraphQLTypes["_ValidationResult"],
	getFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	listFreshingContainer: Array<GraphQLTypes["FreshingContainer"]>,
	paginateFreshingContainer: GraphQLTypes["FreshingContainerConnection"],
	getTextItem?: GraphQLTypes["TextItem"] | undefined,
	listTextItem: Array<GraphQLTypes["TextItem"]>,
	paginateTextItem: GraphQLTypes["TextItemConnection"],
	getTextList?: GraphQLTypes["TextList"] | undefined,
	listTextList: Array<GraphQLTypes["TextList"]>,
	paginateTextList: GraphQLTypes["TextListConnection"],
	getDeliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	listDeliveryRegions: Array<GraphQLTypes["DeliveryRegions"]>,
	paginateDeliveryRegions: GraphQLTypes["DeliveryRegionsConnection"],
	getDeliveryRegionsItem?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	listDeliveryRegionsItem: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	paginateDeliveryRegionsItem: GraphQLTypes["DeliveryRegionsItemConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getTimetablesEmployeePrivileges?: GraphQLTypes["TimetablesEmployeePrivileges"] | undefined,
	listTimetablesEmployeePrivileges: Array<GraphQLTypes["TimetablesEmployeePrivileges"]>,
	paginateTimetablesEmployeePrivileges: GraphQLTypes["TimetablesEmployeePrivilegesConnection"],
	getTimetableDayNote?: GraphQLTypes["TimetableDayNote"] | undefined,
	listTimetableDayNote: Array<GraphQLTypes["TimetableDayNote"]>,
	paginateTimetableDayNote: GraphQLTypes["TimetableDayNoteConnection"],
	getDeliveryMethodLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	listDeliveryMethodLocale: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	paginateDeliveryMethodLocale: GraphQLTypes["DeliveryMethodLocaleConnection"],
	getIngredientSupplier?: GraphQLTypes["IngredientSupplier"] | undefined,
	listIngredientSupplier: Array<GraphQLTypes["IngredientSupplier"]>,
	paginateIngredientSupplier: GraphQLTypes["IngredientSupplierConnection"],
	getIngredientSupplierItem?: GraphQLTypes["IngredientSupplierItem"] | undefined,
	listIngredientSupplierItem: Array<GraphQLTypes["IngredientSupplierItem"]>,
	paginateIngredientSupplierItem: GraphQLTypes["IngredientSupplierItemConnection"],
	getIngredientSupplierList?: GraphQLTypes["IngredientSupplierList"] | undefined,
	listIngredientSupplierList: Array<GraphQLTypes["IngredientSupplierList"]>,
	paginateIngredientSupplierList: GraphQLTypes["IngredientSupplierListConnection"],
	getIngredientSupplierLocale?: GraphQLTypes["IngredientSupplierLocale"] | undefined,
	listIngredientSupplierLocale: Array<GraphQLTypes["IngredientSupplierLocale"]>,
	paginateIngredientSupplierLocale: GraphQLTypes["IngredientSupplierLocaleConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getVenue?: GraphQLTypes["Venue"] | undefined,
	listVenue: Array<GraphQLTypes["Venue"]>,
	paginateVenue: GraphQLTypes["VenueConnection"],
	getWorkspace?: GraphQLTypes["Workspace"] | undefined,
	listWorkspace: Array<GraphQLTypes["Workspace"]>,
	paginateWorkspace: GraphQLTypes["WorkspaceConnection"],
	getVenueEmployeeRole?: GraphQLTypes["VenueEmployeeRole"] | undefined,
	listVenueEmployeeRole: Array<GraphQLTypes["VenueEmployeeRole"]>,
	paginateVenueEmployeeRole: GraphQLTypes["VenueEmployeeRoleConnection"],
	getIdentificationAttempt?: GraphQLTypes["IdentificationAttempt"] | undefined,
	listIdentificationAttempt: Array<GraphQLTypes["IdentificationAttempt"]>,
	paginateIdentificationAttempt: GraphQLTypes["IdentificationAttemptConnection"],
	getIdentificationCode?: GraphQLTypes["IdentificationCode"] | undefined,
	listIdentificationCode: Array<GraphQLTypes["IdentificationCode"]>,
	paginateIdentificationCode: GraphQLTypes["IdentificationCodeConnection"],
	getIdentificationCodeLocale?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	listIdentificationCodeLocale: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	paginateIdentificationCodeLocale: GraphQLTypes["IdentificationCodeLocaleConnection"],
	getDeviceIdentifier?: GraphQLTypes["DeviceIdentifier"] | undefined,
	listDeviceIdentifier: Array<GraphQLTypes["DeviceIdentifier"]>,
	paginateDeviceIdentifier: GraphQLTypes["DeviceIdentifierConnection"],
	validateCreateDeviceIdentifier: GraphQLTypes["_ValidationResult"],
	validateUpdateDeviceIdentifier: GraphQLTypes["_ValidationResult"],
	getDeviceIdentifierMetadata?: GraphQLTypes["DeviceIdentifierMetadata"] | undefined,
	listDeviceIdentifierMetadata: Array<GraphQLTypes["DeviceIdentifierMetadata"]>,
	paginateDeviceIdentifierMetadata: GraphQLTypes["DeviceIdentifierMetadataConnection"],
	getFulfillmentNote?: GraphQLTypes["FulfillmentNote"] | undefined,
	listFulfillmentNote: Array<GraphQLTypes["FulfillmentNote"]>,
	paginateFulfillmentNote: GraphQLTypes["FulfillmentNoteConnection"],
	getCustomerMetadata?: GraphQLTypes["CustomerMetadata"] | undefined,
	listCustomerMetadata: Array<GraphQLTypes["CustomerMetadata"]>,
	paginateCustomerMetadata: GraphQLTypes["CustomerMetadataConnection"],
	getSubscriptionBox?: GraphQLTypes["SubscriptionBox"] | undefined,
	listSubscriptionBox: Array<GraphQLTypes["SubscriptionBox"]>,
	paginateSubscriptionBox: GraphQLTypes["SubscriptionBoxConnection"],
	getSubscriptionBoxLocale?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	listSubscriptionBoxLocale: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	paginateSubscriptionBoxLocale: GraphQLTypes["SubscriptionBoxLocaleConnection"],
	getTimetableDayMetadata?: GraphQLTypes["TimetableDayMetadata"] | undefined,
	listTimetableDayMetadata: Array<GraphQLTypes["TimetableDayMetadata"]>,
	paginateTimetableDayMetadata: GraphQLTypes["TimetableDayMetadataConnection"],
	getGridTileItem?: GraphQLTypes["GridTileItem"] | undefined,
	listGridTileItem: Array<GraphQLTypes["GridTileItem"]>,
	paginateGridTileItem: GraphQLTypes["GridTileItemConnection"],
	getGridTiles?: GraphQLTypes["GridTiles"] | undefined,
	listGridTiles: Array<GraphQLTypes["GridTiles"]>,
	paginateGridTiles: GraphQLTypes["GridTilesConnection"],
	getPosition?: GraphQLTypes["Position"] | undefined,
	listPosition: Array<GraphQLTypes["Position"]>,
	paginatePosition: GraphQLTypes["PositionConnection"],
	getStatsWidget?: GraphQLTypes["StatsWidget"] | undefined,
	listStatsWidget: Array<GraphQLTypes["StatsWidget"]>,
	paginateStatsWidget: GraphQLTypes["StatsWidgetConnection"],
	getStatsWidgetsLayout?: GraphQLTypes["StatsWidgetsLayout"] | undefined,
	listStatsWidgetsLayout: Array<GraphQLTypes["StatsWidgetsLayout"]>,
	paginateStatsWidgetsLayout: GraphQLTypes["StatsWidgetsLayoutConnection"],
	getStatsPage?: GraphQLTypes["StatsPage"] | undefined,
	listStatsPage: Array<GraphQLTypes["StatsPage"]>,
	paginateStatsPage: GraphQLTypes["StatsPageConnection"],
	getUnassignReason?: GraphQLTypes["UnassignReason"] | undefined,
	listUnassignReason: Array<GraphQLTypes["UnassignReason"]>,
	paginateUnassignReason: GraphQLTypes["UnassignReasonConnection"],
	getOrderPaymentStatsByDay?: GraphQLTypes["OrderPaymentStatsByDay"] | undefined,
	listOrderPaymentStatsByDay: Array<GraphQLTypes["OrderPaymentStatsByDay"]>,
	paginateOrderPaymentStatsByDay: GraphQLTypes["OrderPaymentStatsByDayConnection"]
};
	["Info"]: {
	__typename: "Info",
	description?: string | undefined
};
	["Mutation"]: {
	__typename: "Mutation",
	createEmployeeQueue: GraphQLTypes["EmployeeQueueCreateResult"],
	updateEmployeeQueue: GraphQLTypes["EmployeeQueueUpdateResult"],
	upsertEmployeeQueue: GraphQLTypes["EmployeeQueueUpsertResult"],
	updateTimetableShift: GraphQLTypes["TimetableShiftUpdateResult"],
	createEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemCreateResult"],
	deleteEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemDeleteResult"],
	updateEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemUpdateResult"],
	upsertEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemUpsertResult"],
	updateShiftsProfile: GraphQLTypes["ShiftsProfileUpdateResult"],
	createPreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityCreateResult"],
	deletePreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityDeleteResult"],
	updatePreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityUpdateResult"],
	upsertPreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityUpsertResult"],
	createPreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityCreateResult"],
	deletePreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityDeleteResult"],
	updatePreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityUpdateResult"],
	upsertPreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityUpsertResult"],
	createPreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityCreateResult"],
	deletePreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityDeleteResult"],
	updatePreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityUpdateResult"],
	upsertPreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityUpsertResult"],
	createSuppliesPurchase: GraphQLTypes["SuppliesPurchaseCreateResult"],
	updateSuppliesPurchase: GraphQLTypes["SuppliesPurchaseUpdateResult"],
	upsertSuppliesPurchase: GraphQLTypes["SuppliesPurchaseUpsertResult"],
	createSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemCreateResult"],
	updateSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemUpdateResult"],
	upsertSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemUpsertResult"],
	createUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoCreateResult"],
	deleteUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoDeleteResult"],
	updateUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoUpdateResult"],
	upsertUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoUpsertResult"],
	createDeviceIdentifier: GraphQLTypes["DeviceIdentifierCreateResult"],
	updateDeviceIdentifier: GraphQLTypes["DeviceIdentifierUpdateResult"],
	upsertDeviceIdentifier: GraphQLTypes["DeviceIdentifierUpsertResult"],
	transaction: GraphQLTypes["MutationTransaction"],
	query: GraphQLTypes["Query"]
};
	["EmployeeQueueCreateResult"]: {
	__typename: "EmployeeQueueCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["EmployeeQueue"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["MutationResult"]: {
	__typename:"EmployeeQueueCreateResult" | "EmployeeQueueUpdateResult" | "EmployeeQueueUpsertResult" | "TimetableShiftUpdateResult" | "EmployeeQueueItemCreateResult" | "EmployeeQueueItemDeleteResult" | "EmployeeQueueItemUpdateResult" | "EmployeeQueueItemUpsertResult" | "ShiftsProfileUpdateResult" | "PreparedIngredientQuantityCreateResult" | "PreparedIngredientQuantityDeleteResult" | "PreparedIngredientQuantityUpdateResult" | "PreparedIngredientQuantityUpsertResult" | "PreparedRecipeQuantityCreateResult" | "PreparedRecipeQuantityDeleteResult" | "PreparedRecipeQuantityUpdateResult" | "PreparedRecipeQuantityUpsertResult" | "PreparedPackingQuantityCreateResult" | "PreparedPackingQuantityDeleteResult" | "PreparedPackingQuantityUpdateResult" | "PreparedPackingQuantityUpsertResult" | "SuppliesPurchaseCreateResult" | "SuppliesPurchaseUpdateResult" | "SuppliesPurchaseUpsertResult" | "SuppliesPurchaseItemCreateResult" | "SuppliesPurchaseItemUpdateResult" | "SuppliesPurchaseItemUpsertResult" | "UnassignedShiftInfoCreateResult" | "UnassignedShiftInfoDeleteResult" | "UnassignedShiftInfoUpdateResult" | "UnassignedShiftInfoUpsertResult" | "DeviceIdentifierCreateResult" | "DeviceIdentifierUpdateResult" | "DeviceIdentifierUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>
	['...on EmployeeQueueCreateResult']: '__union' & GraphQLTypes["EmployeeQueueCreateResult"];
	['...on EmployeeQueueUpdateResult']: '__union' & GraphQLTypes["EmployeeQueueUpdateResult"];
	['...on EmployeeQueueUpsertResult']: '__union' & GraphQLTypes["EmployeeQueueUpsertResult"];
	['...on TimetableShiftUpdateResult']: '__union' & GraphQLTypes["TimetableShiftUpdateResult"];
	['...on EmployeeQueueItemCreateResult']: '__union' & GraphQLTypes["EmployeeQueueItemCreateResult"];
	['...on EmployeeQueueItemDeleteResult']: '__union' & GraphQLTypes["EmployeeQueueItemDeleteResult"];
	['...on EmployeeQueueItemUpdateResult']: '__union' & GraphQLTypes["EmployeeQueueItemUpdateResult"];
	['...on EmployeeQueueItemUpsertResult']: '__union' & GraphQLTypes["EmployeeQueueItemUpsertResult"];
	['...on ShiftsProfileUpdateResult']: '__union' & GraphQLTypes["ShiftsProfileUpdateResult"];
	['...on PreparedIngredientQuantityCreateResult']: '__union' & GraphQLTypes["PreparedIngredientQuantityCreateResult"];
	['...on PreparedIngredientQuantityDeleteResult']: '__union' & GraphQLTypes["PreparedIngredientQuantityDeleteResult"];
	['...on PreparedIngredientQuantityUpdateResult']: '__union' & GraphQLTypes["PreparedIngredientQuantityUpdateResult"];
	['...on PreparedIngredientQuantityUpsertResult']: '__union' & GraphQLTypes["PreparedIngredientQuantityUpsertResult"];
	['...on PreparedRecipeQuantityCreateResult']: '__union' & GraphQLTypes["PreparedRecipeQuantityCreateResult"];
	['...on PreparedRecipeQuantityDeleteResult']: '__union' & GraphQLTypes["PreparedRecipeQuantityDeleteResult"];
	['...on PreparedRecipeQuantityUpdateResult']: '__union' & GraphQLTypes["PreparedRecipeQuantityUpdateResult"];
	['...on PreparedRecipeQuantityUpsertResult']: '__union' & GraphQLTypes["PreparedRecipeQuantityUpsertResult"];
	['...on PreparedPackingQuantityCreateResult']: '__union' & GraphQLTypes["PreparedPackingQuantityCreateResult"];
	['...on PreparedPackingQuantityDeleteResult']: '__union' & GraphQLTypes["PreparedPackingQuantityDeleteResult"];
	['...on PreparedPackingQuantityUpdateResult']: '__union' & GraphQLTypes["PreparedPackingQuantityUpdateResult"];
	['...on PreparedPackingQuantityUpsertResult']: '__union' & GraphQLTypes["PreparedPackingQuantityUpsertResult"];
	['...on SuppliesPurchaseCreateResult']: '__union' & GraphQLTypes["SuppliesPurchaseCreateResult"];
	['...on SuppliesPurchaseUpdateResult']: '__union' & GraphQLTypes["SuppliesPurchaseUpdateResult"];
	['...on SuppliesPurchaseUpsertResult']: '__union' & GraphQLTypes["SuppliesPurchaseUpsertResult"];
	['...on SuppliesPurchaseItemCreateResult']: '__union' & GraphQLTypes["SuppliesPurchaseItemCreateResult"];
	['...on SuppliesPurchaseItemUpdateResult']: '__union' & GraphQLTypes["SuppliesPurchaseItemUpdateResult"];
	['...on SuppliesPurchaseItemUpsertResult']: '__union' & GraphQLTypes["SuppliesPurchaseItemUpsertResult"];
	['...on UnassignedShiftInfoCreateResult']: '__union' & GraphQLTypes["UnassignedShiftInfoCreateResult"];
	['...on UnassignedShiftInfoDeleteResult']: '__union' & GraphQLTypes["UnassignedShiftInfoDeleteResult"];
	['...on UnassignedShiftInfoUpdateResult']: '__union' & GraphQLTypes["UnassignedShiftInfoUpdateResult"];
	['...on UnassignedShiftInfoUpsertResult']: '__union' & GraphQLTypes["UnassignedShiftInfoUpsertResult"];
	['...on DeviceIdentifierCreateResult']: '__union' & GraphQLTypes["DeviceIdentifierCreateResult"];
	['...on DeviceIdentifierUpdateResult']: '__union' & GraphQLTypes["DeviceIdentifierUpdateResult"];
	['...on DeviceIdentifierUpsertResult']: '__union' & GraphQLTypes["DeviceIdentifierUpsertResult"];
};
	["_MutationError"]: {
	__typename: "_MutationError",
	path: Array<GraphQLTypes["_PathFragment"]>,
	paths: Array<Array<GraphQLTypes["_PathFragment"]>>,
	type: GraphQLTypes["_MutationErrorType"],
	message?: string | undefined
};
	["_MutationErrorType"]: _MutationErrorType;
	["EmployeeQueueUpdateResult"]: {
	__typename: "EmployeeQueueUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["EmployeeQueue"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["EmployeeQueueUpsertResult"]: {
	__typename: "EmployeeQueueUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["EmployeeQueue"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["TimetableShiftUpdateResult"]: {
	__typename: "TimetableShiftUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["TimetableShift"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["EmployeeQueueItemCreateResult"]: {
	__typename: "EmployeeQueueItemCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["EmployeeQueueItemDeleteResult"]: {
	__typename: "EmployeeQueueItemDeleteResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["EmployeeQueueItem"] | undefined
};
	["EmployeeQueueItemUpdateResult"]: {
	__typename: "EmployeeQueueItemUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["EmployeeQueueItemUpsertResult"]: {
	__typename: "EmployeeQueueItemUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["EmployeeQueueItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["ShiftsProfileUpdateResult"]: {
	__typename: "ShiftsProfileUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["ShiftsProfile"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedIngredientQuantityCreateResult"]: {
	__typename: "PreparedIngredientQuantityCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedIngredientQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedIngredientQuantityDeleteResult"]: {
	__typename: "PreparedIngredientQuantityDeleteResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedIngredientQuantity"] | undefined
};
	["PreparedIngredientQuantityUpdateResult"]: {
	__typename: "PreparedIngredientQuantityUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedIngredientQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedIngredientQuantityUpsertResult"]: {
	__typename: "PreparedIngredientQuantityUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedIngredientQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedRecipeQuantityCreateResult"]: {
	__typename: "PreparedRecipeQuantityCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedRecipeQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedRecipeQuantityDeleteResult"]: {
	__typename: "PreparedRecipeQuantityDeleteResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedRecipeQuantity"] | undefined
};
	["PreparedRecipeQuantityUpdateResult"]: {
	__typename: "PreparedRecipeQuantityUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedRecipeQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedRecipeQuantityUpsertResult"]: {
	__typename: "PreparedRecipeQuantityUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedRecipeQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedPackingQuantityCreateResult"]: {
	__typename: "PreparedPackingQuantityCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedPackingQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedPackingQuantityDeleteResult"]: {
	__typename: "PreparedPackingQuantityDeleteResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedPackingQuantity"] | undefined
};
	["PreparedPackingQuantityUpdateResult"]: {
	__typename: "PreparedPackingQuantityUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedPackingQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["PreparedPackingQuantityUpsertResult"]: {
	__typename: "PreparedPackingQuantityUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["PreparedPackingQuantity"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["SuppliesPurchaseCreateResult"]: {
	__typename: "SuppliesPurchaseCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["SuppliesPurchase"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["SuppliesPurchaseUpdateResult"]: {
	__typename: "SuppliesPurchaseUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["SuppliesPurchase"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["SuppliesPurchaseUpsertResult"]: {
	__typename: "SuppliesPurchaseUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["SuppliesPurchase"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["SuppliesPurchaseItemCreateResult"]: {
	__typename: "SuppliesPurchaseItemCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["SuppliesPurchaseItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["SuppliesPurchaseItemUpdateResult"]: {
	__typename: "SuppliesPurchaseItemUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["SuppliesPurchaseItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["SuppliesPurchaseItemUpsertResult"]: {
	__typename: "SuppliesPurchaseItemUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["SuppliesPurchaseItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["UnassignedShiftInfoCreateResult"]: {
	__typename: "UnassignedShiftInfoCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["UnassignedShiftInfo"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["UnassignedShiftInfoDeleteResult"]: {
	__typename: "UnassignedShiftInfoDeleteResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["UnassignedShiftInfo"] | undefined
};
	["UnassignedShiftInfoUpdateResult"]: {
	__typename: "UnassignedShiftInfoUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["UnassignedShiftInfo"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["UnassignedShiftInfoUpsertResult"]: {
	__typename: "UnassignedShiftInfoUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["UnassignedShiftInfo"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["DeviceIdentifierCreateResult"]: {
	__typename: "DeviceIdentifierCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["DeviceIdentifier"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["DeviceIdentifierUpdateResult"]: {
	__typename: "DeviceIdentifierUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["DeviceIdentifier"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["DeviceIdentifierUpsertResult"]: {
	__typename: "DeviceIdentifierUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["DeviceIdentifier"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["MutationTransaction"]: {
	__typename: "MutationTransaction",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	validation: GraphQLTypes["_ValidationResult"],
	createEmployeeQueue: GraphQLTypes["EmployeeQueueCreateResult"],
	updateEmployeeQueue: GraphQLTypes["EmployeeQueueUpdateResult"],
	upsertEmployeeQueue: GraphQLTypes["EmployeeQueueUpsertResult"],
	updateTimetableShift: GraphQLTypes["TimetableShiftUpdateResult"],
	createEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemCreateResult"],
	deleteEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemDeleteResult"],
	updateEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemUpdateResult"],
	upsertEmployeeQueueItem: GraphQLTypes["EmployeeQueueItemUpsertResult"],
	updateShiftsProfile: GraphQLTypes["ShiftsProfileUpdateResult"],
	createPreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityCreateResult"],
	deletePreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityDeleteResult"],
	updatePreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityUpdateResult"],
	upsertPreparedIngredientQuantity: GraphQLTypes["PreparedIngredientQuantityUpsertResult"],
	createPreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityCreateResult"],
	deletePreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityDeleteResult"],
	updatePreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityUpdateResult"],
	upsertPreparedRecipeQuantity: GraphQLTypes["PreparedRecipeQuantityUpsertResult"],
	createPreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityCreateResult"],
	deletePreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityDeleteResult"],
	updatePreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityUpdateResult"],
	upsertPreparedPackingQuantity: GraphQLTypes["PreparedPackingQuantityUpsertResult"],
	createSuppliesPurchase: GraphQLTypes["SuppliesPurchaseCreateResult"],
	updateSuppliesPurchase: GraphQLTypes["SuppliesPurchaseUpdateResult"],
	upsertSuppliesPurchase: GraphQLTypes["SuppliesPurchaseUpsertResult"],
	createSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemCreateResult"],
	updateSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemUpdateResult"],
	upsertSuppliesPurchaseItem: GraphQLTypes["SuppliesPurchaseItemUpsertResult"],
	createUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoCreateResult"],
	deleteUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoDeleteResult"],
	updateUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoUpdateResult"],
	upsertUnassignedShiftInfo: GraphQLTypes["UnassignedShiftInfoUpsertResult"],
	createDeviceIdentifier: GraphQLTypes["DeviceIdentifierCreateResult"],
	updateDeviceIdentifier: GraphQLTypes["DeviceIdentifierUpdateResult"],
	upsertDeviceIdentifier: GraphQLTypes["DeviceIdentifierUpsertResult"],
	query?: GraphQLTypes["Query"] | undefined
};
	["MutationTransactionOptions"]: {
		deferForeignKeyConstraints?: boolean | undefined
};
	["_Schema"]: {
	__typename: "_Schema",
	enums: Array<GraphQLTypes["_Enum"]>,
	entities: Array<GraphQLTypes["_Entity"]>
};
	["_Entity"]: {
	__typename: "_Entity",
	name: string,
	customPrimaryAllowed: boolean,
	fields: Array<GraphQLTypes["_Field"]>,
	unique: Array<GraphQLTypes["_UniqueConstraint"]>
};
	["_UniqueConstraint"]: {
	__typename: "_UniqueConstraint",
	fields: Array<string>
};
	["_Field"]: {
	__typename:"_Column" | "_Relation",
	name: string,
	type: string,
	nullable?: boolean | undefined,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
	['...on _Column']: '__union' & GraphQLTypes["_Column"];
	['...on _Relation']: '__union' & GraphQLTypes["_Relation"];
};
	["_Column"]: {
	__typename: "_Column",
	name: string,
	type: string,
	enumName?: string | undefined,
	defaultValue?: GraphQLTypes["Json"] | undefined,
	nullable: boolean,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_OnDeleteBehaviour"]: _OnDeleteBehaviour;
	["_RelationSide"]: _RelationSide;
	["_OrderByDirection"]: _OrderByDirection;
	["_OrderBy"]: {
	__typename: "_OrderBy",
	path: Array<string>,
	direction: GraphQLTypes["_OrderByDirection"]
};
	["_Relation"]: {
	__typename: "_Relation",
	name: string,
	type: string,
	side: GraphQLTypes["_RelationSide"],
	targetEntity: string,
	ownedBy?: string | undefined,
	inversedBy?: string | undefined,
	nullable?: boolean | undefined,
	onDelete?: GraphQLTypes["_OnDeleteBehaviour"] | undefined,
	orphanRemoval?: boolean | undefined,
	orderBy?: Array<GraphQLTypes["_OrderBy"]> | undefined,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_Rule"]: {
	__typename: "_Rule",
	message?: GraphQLTypes["_RuleMessage"] | undefined,
	validator: number
};
	["_Validator"]: {
	__typename: "_Validator",
	operation: string,
	arguments: Array<GraphQLTypes["_Argument"]>
};
	["_Argument"]:{
        	__typename:"_ValidatorArgument" | "_PathArgument" | "_LiteralArgument"
        	['...on _ValidatorArgument']: '__union' & GraphQLTypes["_ValidatorArgument"];
	['...on _PathArgument']: '__union' & GraphQLTypes["_PathArgument"];
	['...on _LiteralArgument']: '__union' & GraphQLTypes["_LiteralArgument"];
};
	["_ValidatorArgument"]: {
	__typename: "_ValidatorArgument",
	validator: number
};
	["_PathArgument"]: {
	__typename: "_PathArgument",
	path: Array<string>
};
	["_LiteralArgument"]: {
	__typename: "_LiteralArgument",
	value?: GraphQLTypes["Json"] | undefined
};
	["_RuleMessage"]: {
	__typename: "_RuleMessage",
	text?: string | undefined
};
	["_Enum"]: {
	__typename: "_Enum",
	name: string,
	values: Array<string>
}
    }
export type One = "One";

export type VirtualProductType = "Credits" | "Voucher";

export type VirtualProductPhysicalRepresentationType = "None" | "Optional" | "Required";

export type VatRatePreset = "zero" | "default";

export type MediumType = "image" | "video";

export type ColorTheme = "light" | "dark";

export type ContentReferenceType = "button" | "medium" | "products" | "productBanners" | "links" | "link" | "blogPosts" | "content" | "gallery" | "hero" | "hr" | "marquee" | "productGrid" | "relatedBlogPosts" | "socialsAndApps" | "titleWithImage" | "deliveryRegions" | "collapsibleTexts" | "gridTiles";

export type LinkType = "internal" | "external" | "download";

export type PageTheme = "goodlok" | "childish";

export type ContentSize = "small" | "medium" | "large";

export type ProductGridType = "default" | "carousel" | "smallGrid";

export type ProductBannerItemSize = "small" | "medium" | "large" | "fullWidth";

export type TranslationContentType = "textPlain" | "textHtml";

export type PaymentMethodType = "credit" | "stripe" | "cash" | "invoice" | "import";

export type DeliveryZoneType = "Shop" | "Prague" | "CZ" | "Abroad" | "Virtual";

export type DeliveryTimelineType = "asap" | "day" | "halfAnHour";

export type ProductAvailability = "stockDependent" | "available" | "unavailable";

export type OrderState = "created" | "waitingForPayment" | "processing" | "dispatched" | "fulfilled" | "canceled";

export type ChannelCode = "b2b" | "eshop" | "app" | "pos" | "web" | "all" | "legacy";

export type TimetableDayType = "open" | "closed";

export type UnassignedInfoReason = "sickness" | "vacation" | "other";

export type TemplateType = "inInterval" | "selectedDays";

export type PointOfSaleInitialView = "categories" | "areas";

export type OrderDeliveryState = "processing" | "picked" | "delivering" | "delivered" | "failed";

export type OrderPaymentState = "created" | "approved" | "failed";

export type CustomerFamiliarity = "cizi" | "pratelskeVykani" | "slusneTykani" | "tykani" | "sikana";

export type OrderDirection = "asc" | "desc" | "ascNullsFirst" | "descNullsLast";

export type StatsEnabledWidgetTypeType = "revenue" | "numberOfCustomers" | "numberOfCustomersHourly" | "averageCustomerSpending" | "topProduct" | "dailyGratuity" | "salesByProductCategories" | "productSales" | "cashRegisterBalance" | "revenueByHours" | "revenueWeeklyComparison" | "dailyRevenue";

export type StatsWidgetTimeFrame = "day" | "week" | "month" | "year" | "allTime";

export type StatsPageType = "dashboard" | "sales" | "b2b" | "employees";

export type _MutationErrorType = "NotNullConstraintViolation" | "UniqueConstraintViolation" | "ForeignKeyConstraintViolation" | "NotFoundOrDenied" | "NonUniqueWhereInput" | "InvalidDataInput" | "SqlError";

export type _OnDeleteBehaviour = "restrict" | "cascade" | "setNull";

export type _RelationSide = "owning" | "inverse";

export type _OrderByDirection = "asc" | "desc";

export const createZeusVariables = useZeusVariables

export type FragmentResult<K extends keyof GraphQLTypes, Q extends unknown | ((...args: any[]) => unknown)> = InputType<
	GraphQLTypes[K],
	Q extends (...args: any[]) => unknown ? ReturnType<Q> : Q
>
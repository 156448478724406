import { RichTextRenderer } from '@contember/react-client'
import { FragmentResult, Selector } from '@goodlok/sdk/generated/orders'
import { FormattedCents, Input } from '@goodlok/ui'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { FunctionComponent, useCallback, useState } from 'react'
import { Breaklines, Button, Card, Icon, useGoodlokAuth, useGoodlokCartQueryParams } from '..'
import style from './DeliveryOptionPicker.module.sass'
import { PillPicker } from './PillPicker'

const CartDeliveryMethod = Selector('DeliveryMethodOption')({
	id: true,
	selected: true,
	delivery: {
		id: true,
		order: true,
		method: [{}, { code: true, name: true, description: true }],
	},
	priceCents: true,
	options: {
		id: true,
		selected: true,
		priceCents: true,
		timelineDescription: true,
	},
})

type CartDeliveryMethod = FragmentResult<'DeliveryMethodOption', typeof CartDeliveryMethod>

function useCartDeliveryOptions(params: { time?: string }) {
	const g = useGoodlokAuth()

	const cartQueryParams = useGoodlokCartQueryParams()

	return useQuery(['cart', 'useCartDeliveryOptions', cartQueryParams, params?.time], async () => {
		if (cartQueryParams) {
			return g.zeus
				.orders('query')({
					getCartDeliveryOptions: [
						{ ...cartQueryParams, time: params?.time || undefined },
						{
							addresses: {
								id: true,
								selected: true,
								address: { id: true, name: true, formatted: true, raw: true, note: true },
								methods: CartDeliveryMethod,
							},
							methods: CartDeliveryMethod,
							selected: {
								id: true,
								address: { id: true },
								delivery: { id: true, method: [{}, { id: true }] },

								timelineDescription: true,
							},
						},
					],
				})
				.then(data => data.getCartDeliveryOptions)
		}
	})
}

export function useDeliveryOptionPicker(params: { time?: string }) {
	const options = useCartDeliveryOptions(params)

	const queryClient = useQueryClient()

	const g = useGoodlokAuth()
	const cartQueryParams = useGoodlokCartQueryParams()

	const ordersZeus = g.zeus.orders

	const setDeliveryOptionMutation = useMutation(async (params: { deliveryOptionId: string }) => {
		if (cartQueryParams) {
			return ordersZeus('mutation')({
				setCartDeliveryOption: [
					{
						...cartQueryParams,
						deliveryOptionId: params.deliveryOptionId,
					},
					{ errorCode: true, ok: true },
				],
			}).then(() => {
				queryClient.invalidateQueries(['cart'])
			})
		}
	})

	const bases = [...(options.data?.methods ?? []), ...(options.data?.addresses ?? [])]

	return { bases, setDeliveryOptionMutation, selected: options.data?.selected }
}

export const DeliveryBasePicker: FunctionComponent<{
	autoPick?: boolean
	time: string
	filter?: 'pickup' | 'address' | 'all'
	onAddAddressClick?: () => void
}> = props => {
	const filter = props.filter ?? 'all'
	const { bases, setDeliveryOptionMutation } = useDeliveryOptionPicker({ time: props.time })

	const selected = bases.find(base => base.selected)

	const setDeliveryOption = setDeliveryOptionMutation.mutate

	const options = bases.filter(base => {
		if (filter === 'address') {
			return 'address' in base
		} else if (filter === 'pickup') {
			return !('address' in base)
		}
		return true
	})

	return (
		<PillPicker<typeof selected>
			tailAction={
				props.onAddAddressClick
					? {
							caption: (
								<div>
									<span
										style={{ lineHeight: '1', fontSize: '1.2em', display: 'inline-block', verticalAlign: 'middle' }}
									>
										<Icon name="circlePlus" />
									</span>{' '}
									<strong>Přidat adresu</strong>
								</div>
							),
							onClick: props.onAddAddressClick,
					  }
					: undefined
			}
			autoPick
			value={selected}
			options={options.map(base => {
				const disabled = base && 'address' in base && base.methods.length === 0
				return {
					value: base,
					disabled,
					label: (
						<div className={clsx(style.base, base.selected && style.selected)}>
							{'address' in base && base.address ? (
								<div>
									<div className={style.part}>
										<div className={style.baseName}>{base.address.name}</div>
										<div className={style.address}>
											<Breaklines text={base.address.raw ? cityLineBreak(base.address.raw) : base.address.formatted} />
										</div>
									</div>
								</div>
							) : 'delivery' in base && base.delivery ? (
								<div className={style.deliveryMethodBase}>
									<div className={style.part}>
										<div className={style.baseName}>{base.delivery.method?.name}</div>
										<div className={style.address}>
											{base.delivery.method?.description && (
												<RichTextRenderer source={base.delivery.method.description} />
											)}
										</div>
									</div>
								</div>
							) : null}
						</div>
					),
				}
			})}
			onChange={useCallback(
				value => {
					const method = value ? ('methods' in value ? value.methods[0] : value) : null
					if (method?.options[0].id) {
						setDeliveryOption({ deliveryOptionId: method?.options[0].id })
					}
				},
				[setDeliveryOption],
			)}
		/>
	)
}
export const DeliveryMethodPicker: FunctionComponent<{ methods: CartDeliveryMethod[]; time: string }> = ({
	methods,
	time,
}) => {
	const { setDeliveryOptionMutation } = useDeliveryOptionPicker({ time })
	const selected = methods.find(method => method.selected)

	const setDeliveryOption = setDeliveryOptionMutation.mutate

	return (
		<PillPicker<typeof selected>
			value={selected}
			options={methods.map(method => ({
				value: method,
				label: (
					<div className={clsx(style.base, method.selected && style.selected)}>
						<strong>{method.delivery?.method?.name}</strong>
						{method?.options[0]?.priceCents > 0 && (
							<small>
								{' '}
								za <FormattedCents cents={method?.options[0]?.priceCents} />
							</small>
						)}
					</div>
				),
			}))}
			onChange={useCallback(
				method => {
					if (method?.options[0].id) {
						setDeliveryOption({ deliveryOptionId: method.options[0].id })
					}
				},
				[setDeliveryOption],
			)}
		/>
	)
}
export const DeliveryOptionPicker: FunctionComponent<{ options: CartDeliveryMethod['options']; time: string }> = ({
	options,
	time,
}) => {
	const { setDeliveryOptionMutation } = useDeliveryOptionPicker({ time })

	const selected = options.find(option => option.selected)

	const setDeliveryOption = setDeliveryOptionMutation.mutate

	return (
		<PillPicker<typeof selected>
			value={selected}
			options={options.map(option => ({
				value: option,
				label: (
					<div className={clsx(style.base, option.selected && style.selected)}>
						<strong>{option.timelineDescription}</strong>{' '}
					</div>
				),
			}))}
			onChange={useCallback(
				option => {
					if (option?.id) {
						setDeliveryOption({ deliveryOptionId: option.id })
					}
				},
				[setDeliveryOption],
			)}
		/>
	)
}

export const omit = <Original extends Record<string, unknown>, Blacklist extends keyof Original>(
	original: Original,
	blacklist: Blacklist[],
) => {
	return Object.fromEntries(
		Object.entries(original).filter(([key]) => !blacklist.includes(key as Blacklist)),
	) as Exclude<Original, Blacklist>
}

export const AddAddressForm: FunctionComponent<{
	minimal?: boolean
	customerId: string
	cartId?: string
	onCancel?: () => void
	onSuccess?: (info: { addressId: string }) => void
}> = props => {
	const [count, setCount] = useState(1)
	const { customerId, minimal, cartId } = props

	const queryClient = useQueryClient()

	const g = useGoodlokAuth()

	const mutation = useMutation(
		async (params: { raw: string; name: string | null; fullName: string | null; phone: string | null }) => {
			if (customerId) {
				return g.zeus
					.orders('mutation')({
						addAddress: [
							{
								customerId,
								cartId,
								data: params,
							},
							{ ok: true, errorMessage: true, node: { id: true } },
						],
					})
					.then(data => {
						queryClient.invalidateQueries(['cart'])
						setCount(c => c + 1)
						if (data.addAddress.node?.id) {
							return { addressId: data.addAddress.node.id as string }
						}
					})
			}
		},
	)

	const formActions = (
		<>
			{props.onCancel && (
				<>
					<Button
						size="small"
						type="button"
						variant="dark"
						round
						outline
						uppercase={false}
						onClick={() => props.onCancel?.()}
					>
						Zrušit
					</Button>{' '}
				</>
			)}
			<Button size="small" type="submit" variant="dark" round uppercase={false}>
				Přidat adresu
			</Button>
		</>
	)

	const formContent = (
		<>
			{!minimal && (
				<div style={{ marginBottom: '.25em' }}>
					<Input
						labelPosition="inside"
						size="compact"
						type="text"
						label="Pojmenování adresy"
						name="name"
						placeholder="doma, kancl, babička"
					/>
				</div>
			)}
			{!minimal && (
				<div style={{ marginBottom: '.25em' }}>
					<Input labelPosition="inside" size="compact" type="text" label="Jméno příjemce" name="fullName" />
				</div>
			)}
			{!minimal && (
				<div style={{ marginBottom: '.25em' }}>
					<Input labelPosition="inside" size="compact" type="text" label="Kontaktní telefon" name="phone" required />
				</div>
			)}
			<div style={{ marginBottom: '.25em' }}>
				<Input
					labelPosition="inside"
					size="compact"
					textarea
					label="Adresa"
					name="raw"
					placeholder="Napříkladová 123, Praha"
					required
				/>
			</div>
		</>
	)
	return (
		<form
			key={count}
			onSubmit={e => {
				e.preventDefault()
				const data = new FormData(e.currentTarget)
				mutation
					.mutateAsync({
						raw: String(data.get('raw') ?? ''),
						name: String(data.get('name') ?? '') || null,
						fullName: String(data.get('fullName') ?? '') || null,
						phone: String(data.get('phone') ?? '') || null,
					})
					.then(data => {
						if (data) {
							props.onSuccess?.(data)
						}
					})
			}}
		>
			{minimal ? (
				<>
					{formContent}
					<div style={{ textAlign: 'right' }}>{formActions}</div>
				</>
			) : (
				<Card unwrapped footerEnd={formActions}>
					{formContent}
				</Card>
			)}
		</form>
	)
}

function cityLineBreak(address: string) {
	return address.split(/,? Praha/).join('\nPraha')
}

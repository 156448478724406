import { AppFrame, GoodlokAuthRoot, registerServiceWorker } from '@goodlok/react-sdk'
import '@goodlok/react-sdk/src/theme.sass'
import {
	Notifications,
	SharedLoadingIndicator,
	SharedLoadingIndicatorContextProvider,
} from '@goodlok/ui'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'collapsible-react-component/dist/index.css'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import '../app/styles/globals.sass'
import { api } from '../utilities/api'
import { description, shortName } from './api/app.webmanifest'

registerServiceWorker()

const queryClient = new QueryClient()

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<QueryClientProvider client={queryClient}>
			<SharedLoadingIndicatorContextProvider>
				<GoodlokAuthRoot options={{ backendUrl: process.env.BACKEND_URL as string }}>
					<AppFrame appHandle="timetables" supportNotch>
						<SharedLoadingIndicator />
						<Notifications />
						<Head>
							<title>{shortName}</title>
							<meta name="description" content={description} />
						</Head>
						<Component {...pageProps} />
					</AppFrame>
				</GoodlokAuthRoot>
			</SharedLoadingIndicatorContextProvider>
		</QueryClientProvider>
	)
}
export default api.withTRPC(MyApp)

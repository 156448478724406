import clsx from 'clsx'
import {
	DetailedHTMLProps,
	FunctionComponent,
	InputHTMLAttributes,
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useMemo,
} from 'react'
import { useStorageBackedState } from 'use-storage-backed-state'
import { DatePickerInput } from './DatePickerInput'
import styles from './GlobalDateRange.module.sass'

const globalDateRangeContext = createContext<{
	range: {
		start: null | Date
		end: null | Date
	}
	setRange: (start: null | Date, end: null | Date) => void
}>({
	range: {
		start: null,
		end: null,
	},
	setRange: () => {
		throw new Error('Missing context provider.')
	},
})

/**
 * @param date Is expected to be ****-**-**T00:00:00.000Z
 */
const getDateEnd = (date: Date) => {
	const dateEnd = new Date(date)
	dateEnd.setDate(dateEnd.getDate() + 1) // Plus one day
	dateEnd.setTime(dateEnd.getTime() - 1) // One ms before midnight
	return dateEnd
}

const useRange = (startDate?: Date, endDate?: Date) => {
	const [rangeTimestamps, setRangeTimestamps] = useStorageBackedState(
		() => {
			const start = (() => {
				if (startDate) {
					const date = new Date(startDate)
					const dateFormatted = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
					return dateFormatted
				}
				return null
			})()
			const end = (() => {
				if (endDate) {
					const date = new Date(endDate)
					const dateFormatted = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
					return dateFormatted
				} else if (startDate) {
					const date = new Date()
					const dateFormatted = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
					return getDateEnd(dateFormatted)
				}
				return null
			})()
			return { start: start && start.getTime(), end: end && end.getTime() }
		},
		'date-range',
		'sessionStorage' in globalThis ? sessionStorage : undefined,
	)

	const range = useMemo(
		() => ({
			start: rangeTimestamps.start === null ? null : new Date(rangeTimestamps.start),
			end: rangeTimestamps.end === null ? null : new Date(rangeTimestamps.end),
		}),
		[rangeTimestamps.end, rangeTimestamps.start],
	)
	const setRange = useCallback(
		(start: null | Date, end: null | Date) => {
			setRangeTimestamps({
				start: start && start.getTime(),
				end: end && end.getTime(),
			})
		},
		[setRangeTimestamps],
	)
	return [range, setRange] as const
}

export const GlobalDateRangeProvider: FunctionComponent<{
	//startToday?: boolean
	children: ReactNode
	start?: Date
	end?: Date
}> = ({ start, end, children }) => {
	const [range, setRange] = useRange(start, end)

	const setRangeCallback = useCallback(
		(start: null | Date, end: null | Date) => {
			setRange(start, end)
		},
		[setRange],
	)

	const value = useMemo(
		() => ({
			range,
			setRange: setRangeCallback,
		}),
		[range, setRangeCallback],
	)
	return <globalDateRangeContext.Provider value={value}>{children}</globalDateRangeContext.Provider>
}

export const useGlobalDateRangeStart = () => useContext(globalDateRangeContext).range.start
export const useGlobalDateRangeEnd = () => useContext(globalDateRangeContext).range.end
export const useGlobalDateRange = () => useContext(globalDateRangeContext).range
const useSetRange = () => useContext(globalDateRangeContext).setRange

export type GlobalDateRangePickerProps = {
	oneDayOnly?: boolean
	min?: string
	max?: string
	justifyContent?: 'start' | 'center' | 'end'
} & Pick<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'min' | 'max'>

export const GlobalDateRangePicker: FunctionComponent<GlobalDateRangePickerProps> = ({
	oneDayOnly = false,
	min,
	max,
	justifyContent = 'end',
}) => {
	const startDate = useGlobalDateRangeStart()
	const endDate = useGlobalDateRangeEnd()
	const setRange = useSetRange()

	return (
		<div className={clsx(styles.wrapper, styles[`is_${justifyContent}`])}>
			{oneDayOnly ? (
				<DatePickerInput
					onChange={value => setRange(value ? new Date(value) : null, value ? getDateEnd(new Date(value)) : null)}
					value={startDate ?? null}
					min={min ? new Date(min) : undefined}
					max={max ? new Date(max) : undefined}
					mode="single"
				/>
			) : (
				<DatePickerInput
					onChange={value =>
						setRange(
							value ? new Date(value.start.toString()) : null,
							value ? getDateEnd(new Date(value.end.toString())) : null,
						)
					}
					value={
						startDate && endDate
							? {
									start: startDate,
									end: endDate,
							  }
							: null
					}
					min={min ? new Date(min) : undefined}
					max={max ? new Date(max) : undefined}
					mode="range"
				/>
			)}
		</div>
	)
}

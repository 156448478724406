import { getStage } from '../../../../meta/getStage'
import type { App } from './appsConfiguration'

export function getAppUrl(app: App, slug: string, domain: string) {
	const stage = getStage()

	if (stage === 'development' && app.port) {
		return `http://localhost:${app.port}/`
	}

	if (/^https?:\/\//.test(slug)) {
		return slug
	}

	return `https://${slug}.${domain}/`
}

import { workspaceConfig } from '@goodlok/meta/workspaceConfig'
import { createWebmanifestHandler as createWebmanifestHandlerLibrary } from 'nextjs-webmanifest'

export const createWebmanifestHandler = (short_name: string, description: string) =>
	createWebmanifestHandlerLibrary({
		name: `${workspaceConfig.name} ${short_name}`,
		short_name,
		description,
		start_url: '/',
		display: 'standalone',
		theme_color: workspaceConfig.themeColor,
		background_color: workspaceConfig.themeColor,
		lang: 'cs',
		id: 'cs',
		icons: [
			{
				src: '/api/app-icon?type=png&size=192',
				sizes: '192x192',
				type: 'image/png',
				purpose: 'any',
			},
			{
				src: '/api/app-icon?type=png&size=512',
				sizes: '512x512',
				type: 'image/png',
				purpose: 'any',
			},
			{
				src: '/api/app-icon?type=svg',
				sizes: '256x256',
				type: 'image/svg+xml',
				purpose: 'any',
			},
			{
				src: '/api/app-icon?type=svg',
				sizes: '256x256',
				type: 'image/svg+xml',
				purpose: 'maskable',
			},
		],
	})

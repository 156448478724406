import type { OrderPaymentState } from '@goodlok/sdk/generated/content_role_staff'
import { FormattedCents } from '@goodlok/ui'
import { FunctionComponent, useMemo } from 'react'
import { Address } from './Address'
import { CardColumns } from './CardColumns'
import { EntityLink } from './EntityLink'
import { OrderItemsOverview } from './OrderItemsOverview'
import { OrderPaymentInfo } from './OrderPaymentInfo'
import styles from './OrderSummary.module.sass'
import { StaffBadge } from './StaffBadge'
import { StateLabel } from './StateLabel'

export interface OrderSummaryProps {
	order: {
		id: string
		seq: number
		items: Array<{
			id: string
			quantity: number
			product?: {
				id: string
				name: string
				image?: {
					url: string
					width?: number
					height?: number
					alt?: string
				}
			}
		}>
		delivery?: {
			trackingCode?: string
			deliveryWaypoint?: {
				route?: {
					courier?: {
						staff?: {
							user?: {
								name?: string
							}
							photo?: {
								id: string
								url: string
								width?: number
								height?: number
							}
						}
					}
				}
			}
			address?: {
				id: string
				note?: string
				fullName?: string
				firstName?: string
				lastName?: string
				addressLine1?: string
				addressLine2?: string
				postalCode?: string
				city?: string
				phone?: string
			}
			method?: {
				name?: string
				requireDeliveryAddress?: boolean
				enableDeliveryTracking?: boolean
			}
			window?: string
		}
		customer?: {
			name?: {
				nominative?: string
			}
			business?: {
				accountManager?: {
					staff?: {
						user?: {
							name?: string
							email?: string
						}
						photo?: {
							id: string
							url: string
							width?: number
							height?: number
						}
					}
				}
			}
			prefersPackingsWithoutCardboard?: boolean
		}
		channel?: {
			name?: string
		}
		summary?: {
			totalPriceCents?: number
		}
		payments: Array<{
			id: string
			state: OrderPaymentState
			method?: {
				name: string
			}
			walletType?: string
			paymentCents: number
			publicReceiptUrl?: string
			invoice?: {
				createdAt: string
				fakturoidId?: string
				publicUrl?: string
				legacyUrl?: string
			}
		}>
	}
}

export const OrderSummary: FunctionComponent<OrderSummaryProps> = ({ order }) => {
	const totalItemsCount = useMemo(() => order.items.reduce((sum, { quantity }) => sum + quantity, 0), [order.items])
	const courierName = order.delivery?.deliveryWaypoint?.route?.courier?.staff?.user?.name
	const accountManagerName =
		order.customer?.business?.accountManager?.staff?.user?.name ||
		order.customer?.business?.accountManager?.staff?.user?.email ||
		'Nemá manažera účtu'

	return (
		<CardColumns
			columns={[
				{
					id: 'products',
					title: `Produkty, ${totalItemsCount} kusů`,
					content: <OrderItemsOverview items={order.items} />,
				},
				{
					id: 'delivery',
					title: 'Doručení',
					content: (
						<div className={styles.deliveryDetails}>
							{order.delivery?.address && order.delivery?.method?.requireDeliveryAddress && (
								<Address address={order.delivery.address} />
							)}
							<div className={styles.labels}>
								<StateLabel variant="neutral" highlighted>
									{order.delivery?.method?.name}
								</StateLabel>
								<StateLabel variant="neutral" highlighted>
									{order.delivery?.window}
								</StateLabel>
								{order.delivery?.trackingCode && order.delivery.method?.enableDeliveryTracking && (
									<div className={styles.trackingCode}>TN: {order.delivery?.trackingCode}</div>
								)}
								{order.customer?.prefersPackingsWithoutCardboard && (
									<StateLabel variant="warning" highlighted>
										Bez kartonu
									</StateLabel>
								)}
							</div>
							{courierName && (
								<div className={styles.courier}>
									<StaffBadge
										name={courierName}
										title={`Kurýr: ${courierName}`}
										image={order.delivery?.deliveryWaypoint?.route?.courier?.staff?.photo}
									/>
								</div>
							)}
						</div>
					),
				},
				{
					id: 'details',
					title: 'Detaily',
					content: (
						<div className={styles.details}>
							<div className={styles.detailsItem}>
								<EntityLink entity="Order" view="detail" id={order} stopPropagation>
									<strong>#{order.seq}</strong>
								</EntityLink>
							</div>
							<div className={styles.detailsItem}>
								{order.customer?.name?.nominative}
								{order.customer?.business?.accountManager && (
									<div className={styles.detailsItem_note}>
										{order.customer.business && (
											<>
												{order.customer.business.accountManager ? (
													<StaffBadge
														name={accountManagerName}
														title={`Manažer zákazníka: ${accountManagerName}`}
														image={order.customer.business.accountManager?.staff?.photo}
													/>
												) : (
													<i>Bez manažera účtu</i>
												)}
											</>
										)}
									</div>
								)}
							</div>
							<div className={styles.detailsItem}>{order.channel?.name}</div>
							<div className={styles.detailsItem}>
								{order.summary?.totalPriceCents && <FormattedCents cents={order.summary?.totalPriceCents} />}
								{order.payments.map(payment => (
									<OrderPaymentInfo key={payment.id} payment={payment} />
								))}
							</div>
						</div>
					),
				},
			]}
		/>
	)
}

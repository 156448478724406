import clsx from 'clsx'
import { useEffect } from 'react'
import style from './PillPicker.module.sass'

export const PillPicker = <Value,>({
	autoPick,
	value,
	onChange,
	disabled,
	options,
	tailAction,
}: {
	autoPick?: boolean
	value: null | Value
	disabled?: boolean
	onChange: (value: null | Value) => void
	options: { value: Value; label: React.ReactNode; disabled?: boolean }[]
	tailAction?: {
		caption: React.ReactNode
		onClick?: React.MouseEventHandler<HTMLLabelElement>
	}
}) => {
	const isValidValue = !!options.find(item => item.value === value)

	const firstValue = options[0]?.value

	useEffect(() => {
		if (autoPick) {
			if (!isValidValue) {
				onChange(firstValue)
			}
		}
	}, [autoPick, firstValue, isValidValue, onChange])

	return (
		<div className={style.wrapper}>
			<fieldset className={style.fieldset} disabled={disabled}>
				<div className={style.items}>
					{options.map((option, o) => (
						<label
							className={clsx(style.item, option.value === value && style.selected, option.disabled && style.disabled)}
							key={o}
							onClick={e => {
								if (!option.disabled) {
									onChange(option.value)
									e.currentTarget.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' })
								}
							}}
						>
							<div className={clsx(style.pill, option.value === value && style.selected)}>{option.label}</div>
						</label>
					))}

					{tailAction && (
						<label
							className={clsx(style.item, style.tailAction)}
							onClick={e => {
								console.log('onClick', tailAction.onClick)
								tailAction.onClick?.(e)
							}}
						>
							<div className={clsx(style.pill)}>{tailAction.caption}</div>
						</label>
					)}
				</div>
			</fieldset>
		</div>
	)
}
